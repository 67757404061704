import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Form, Input, Select, Button, Icon, Upload, message } from 'antd';
import PropTypes from 'prop-types';
import build from 'redux-object';

import { getHubs } from '@/src/store/modules/hubs-data/slice';

import { formatDDDPhone } from '@/src/utils/formatPhone';
import getBase64 from '@/src/utils/getImageBase64';

import styles from '../Users.module.scss';

const { Option } = Select;

const UserForm = ({ form, permissions, newUser }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const dispatch = useDispatch();

  const [userKind, setUserKind] = useState('');
  const [hubs, setHubs] = useState([]);
  const [imageUrl, setImageUrl] = useState('');

  const [permissionsToAdd, setPermissionsToAdd] = useState([]);

  const { user } = useSelector(state => state.users);
  const hubsList = useSelector(state => state.hubsData.list);
  const { permissions: userPermissions, account } = useSelector(
    state => state.account
  );

  useEffect(() => {
    dispatch(
      getHubs({
        query: {
          fields: [{ key: 'hubs', value: 'id,name,internal_id' }],
          perPage: 500,
          sort: 'name'
        }
      })
    );

    if (newUser) {
      setUserKind('');
      setImageUrl('');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!newUser) {
      setUserKind(user.kind);
      const image = user.image || '';
      setImageUrl(image);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const usersManageAuth = userPermissions.filter(
      userPermission => userPermission.name.indexOf('auth') !== -1
    );
    let authKinds = [];

    // const permissionTest = [
    //   {name: 'auth.users.manage', kind: 'none'},
    //   {name: 'auth.users.manage_admin', kind: 'edit'},
    //   {name: 'auth.users.manage_stockist', kind: 'edit'},
    //   {name: 'auth.users.manage_deliverer', kind: 'none'},
    //   {name: 'admin_storefront.gateway_stores.manage', kind: 'edit'}
    // ]

    if (account.kind !== 'office') {
      usersManageAuth.forEach((auth, index) => {
        if (auth.kind === 'edit' && auth.name.indexOf('auth.users') !== -1) {
          let kind = '';
          let label = '';

          if (auth.name.indexOf('deliverer') !== -1) {
            kind = 'deliverer';
            label = 'Entregador';
          }
          if (auth.name.indexOf('admin') !== -1) {
            kind = 'office';
            label = 'Administrador';
          }
          if (auth.name.indexOf('stockist') !== -1) {
            kind = 'stockist';
            label = 'Estoquista';
          }
          if (auth.name.indexOf('tv') !== -1) {
            kind = 'tv';
            label = 'TV';
          }

          if (kind !== '') {
            authKinds.push({ kind, label, id: index });
          }
        }
      });
    } else {
      authKinds = [
        { kind: 'office', label: 'Administrador', id: 0 },
        { kind: 'stockist', label: 'Estoquista', id: 1 },
        { kind: 'deliverer', label: 'Entregador', id: 2 },
        { kind: 'tv', label: 'TV', id: 3 }
      ];
    }
    setPermissionsToAdd(authKinds);
  }, [userPermissions, account]);

  useEffect(() => {
    const hubsObj = (
      (hubsList.meta && hubsList.meta['/hubs'].data) ||
      []
    ).map(object => build(hubsList, 'hubs', object.id, { ignoreLinks: true }));
    setHubs(hubsObj);
  }, [hubsList]);

  useEffect(() => {
    const dispatchPermission = permissions.find(item =>
      item.includes('dispatch.orders.manage')
    );
    const hubsValue = getFieldValue('hubs');
    if (!dispatchPermission && hubsValue) {
      setFieldsValue({
        hubs: []
      });
    }
    // eslint-disable-next-line
  }, [permissions]);

  const handlePermissionsKind = value => {
    setUserKind(value);
    setFieldsValue({
      hubs: []
    });
  };

  const handleSelectHub = value => {
    if (userKind !== 'office' || value === 'all') {
      setFieldsValue({
        hubs: [value]
      });
    } else {
      const currentValue = getFieldValue('hubs');
      const newValue = [...currentValue.filter(item => item !== 'all')];
      setFieldsValue({
        hubs: newValue
      });
    }
  };

  const handlePhoneNumber = evt => {
    const formatedPhone = evt.target.value
      ? formatDDDPhone(evt.target.value)
      : '';
    // eslint-disable-next-line no-param-reassign
    evt.target.value = formatedPhone;
  };

  const getFile = async file => {
    const image = file;
    if (!image.url && !image.preview) {
      image.preview = await getBase64(image);
    }
    return image;
  };

  const beforeUpload = file => {
    const { type, size } = file;
    if (type !== 'image/jpeg' && type !== 'image/png') {
      message.error('Apenas arquivos JPG/PNG são aceitos');
      return false;
    }
    if (size / 1024 / 1024 > 2) {
      message.error('Imagem deve ser menor que 2MB');
      return false;
    }
    return false;
  };

  const removeData = dataString =>
    dataString.replace(/data:image\/[\w]+;base64,/g, '');

  const handleChangeImage = async file => {
    const { type, size } = file.file;
    if (
      (type === 'image/jpeg' || type === 'image/png') &&
      size / 1024 / 1024 <= 2
    ) {
      const image = await getFile(file.file);
      const b64 = image.preview;
      setImageUrl(b64);
      setFieldsValue({
        image: removeData(b64)
      });
    }
    return false;
  };

  const handleRemoveImage = () => {
    setImageUrl('');
    setFieldsValue({
      image: ''
    });
  };

  return (
    <Form layout="vertical">
      <Row>
        <Form.Item className={styles.formItem}>
          {getFieldDecorator('image', {
            initialValue: newUser ? '' : user.image
          })(
            <>
              <div className={styles.imageWrapper}>
                {imageUrl ? (
                  <img src={imageUrl} alt="user" />
                ) : (
                  <Icon type="camera" theme="filled" />
                )}
              </div>
              <div className={styles.imageUpload}>
                <Upload
                  listType="picture-card"
                  className="avatar-upload"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
                >
                  <Button>
                    <Icon type="upload" />
                    Carregar
                  </Button>
                </Upload>
                <Button
                  type="secondary"
                  onClick={handleRemoveImage}
                  disabled={!imageUrl}
                >
                  <Icon type="delete" />
                  Remover
                </Button>
              </div>
              <span className={styles.imageUpload__size}>
                Tamanho recomendado: 400 x 400 px
              </span>
            </>
          )}
        </Form.Item>
        <Form.Item label="Nome" className={styles.formItem}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Deve ter no mínimo 2 caracteres',
                min: 2
              }
            ],
            initialValue: newUser ? '' : user.name
          })(
            <Input
              placeholder="Fábio Almeida"
              className={styles.formItem__input}
            />
          )}
        </Form.Item>
        <Form.Item label="E-mail" className={styles.formItem}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Por favor, preencha este campo'
              },
              {
                type: 'email',
                message: 'E-mail deve ser válido'
              }
            ],
            initialValue: newUser ? '' : user.email
          })(
            <Input
              placeholder="nome@zee-dog.com"
              className={styles.formItem__input}
            />
          )}
        </Form.Item>
        <Form.Item label="Tipo de Permissão" className={styles.formItem}>
          {getFieldDecorator('kind', {
            rules: [
              {
                required: true,
                message: 'Por favor, preencha este campo'
              }
            ],
            initialValue: newUser ? undefined : user.kind
          })(
            <Select
              className={styles.formItem__select}
              onChange={handlePermissionsKind}
              placeholder="Selecione um tipo"
            >
              {permissionsToAdd.map(kinds => {
                return (
                  <Option key={kinds.id} value={kinds.kind}>
                    {kinds.label}
                  </Option>
                );
              })}
              {/* <Option value="office">Administrador</Option>
              <Option value="stockist">Estoquista</Option>
              <Option value="deliverer">Entregador</Option> */}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Função" className={styles.formItem}>
          {getFieldDecorator('occupation', {
            initialValue: newUser ? '' : user.occupation
          })(<Input placeholder="CX" className={styles.formItem__input} />)}
        </Form.Item>
        <Form.Item label="Documento" className={styles.formItem}>
          {getFieldDecorator('document', {
            initialValue: newUser ? '' : user.document
          })(
            <Input
              placeholder="Digite apenas números"
              className={styles.formItem__input}
            />
          )}
        </Form.Item>
        {(userKind === 'deliverer' ||
          permissions.find(item =>
            item.includes('dispatch.orders.manage')
          )) && (
          <Form.Item label="Hubs para a Expedição" className={styles.formItem}>
            {getFieldDecorator('hubs', {
              rules: [
                {
                  required:
                    userKind === 'deliverer' ||
                    permissions.find(item =>
                      item.includes('dispatch.orders.manage')
                    ),
                  message: 'Por favor, preencha este campo'
                }
              ],
              initialValue: user.hubs && !newUser ? user.hubs.split(',') : []
            })(
              <Select
                mode="multiple"
                showArrow
                placeholder={
                  userKind === 'office'
                    ? 'Selecione um ou mais hubs'
                    : 'Selecione um hub'
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onSelect={handleSelectHub}
              >
                {userKind === 'office' && <Option key="all">Todos</Option>}
                {hubs.map(item => (
                  <Option key={item.internalId}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {userKind === 'deliverer' && (
          <>
            <Form.Item label="Telefone" className={styles.formItem}>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: userKind === 'deliverer',
                    message: 'Por favor, preencha este campo'
                  }
                ],
                initialValue: newUser ? '' : formatDDDPhone(user.phone)
              })(
                <Input
                  placeholder="(21) 99999-9999"
                  className={styles.formItem__input}
                  onChange={evt => handlePhoneNumber(evt)}
                  maxLength={15}
                />
              )}
            </Form.Item>
            <Form.Item label="Veículo" className={styles.formItem}>
              {getFieldDecorator('vehicle', {
                rules: [
                  {
                    required: userKind === 'deliverer',
                    message: 'Por favor, preencha este campo'
                  }
                ],
                initialValue: newUser ? '' : user.vehicle
              })(
                <Input
                  placeholder="Bicicleta"
                  className={styles.formItem__input}
                />
              )}
            </Form.Item>
            <Form.Item label="Placa" className={styles.formItem}>
              {getFieldDecorator('plate', {
                rules: [
                  {
                    required: userKind === 'deliverer',
                    message: 'Por favor, preencha este campo'
                  }
                ],
                initialValue: newUser ? '' : user.plate
              })(
                <Input
                  placeholder="XXX0000"
                  className={styles.formItem__input}
                />
              )}
            </Form.Item>
          </>
        )}
      </Row>
    </Form>
  );
};

UserForm.defaultProps = {
  newUser: false
};

UserForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  newUser: PropTypes.bool
};

export default UserForm;
