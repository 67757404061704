import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  order: {
    data: {
      attributes: {},
      relationships: {},
      id: '',
      type: ''
    },
    included: []
  },
  customer: {
    id: '',
    type: '',
    attributes: {
      cpf: '',
      email: '',
      gender: null,
      lifetime_average: '',
      lifetime_orders_count: '',
      lifetime_total: '',
      name: '',
      nickname: null,
      phone_number: ''
    },
    relationships: {}
  },
  // delivery: {
  //   id: '',
  //   type: '',
  //   attributes: {
  //     address_id: 1,
  //     calculated_route: {},
  //     cancelation_justification: null,
  //     cancelation_motive: null,
  //     created_at: '',
  //     customer_id: 1,
  //     deliverer_id: 1,
  //     id: 1,
  //     messages: null,
  //     order_id: 1,
  //     received_by: '',
  //     status: '',
  //     updated_at: ''
  //   }
  // },
  // deliverer: {
  //   attributes: {
  //     deliveries_in_progress: { data: [] }
  //   }
  // },
  order_item: [
    {
      id: '',
      type: '',
      attributes: {
        code: '',
        description: '',
        id: 1,
        list_price: '',
        order_id: 1,
        price: '',
        quantity: 1,
        returned_quantity: 1,
        sku_name: '',
        status: '',
        thumb: '',
        title: '',
        weight: ''
      }
    }
  ],
  order_alert: [],
  parcel: [
    {
      id: '',
      type: 'parcel',
      attributes: {
        code: null,
        created_at: '',
        erp_id: 1,
        id: 1,
        order_id: 1,
        updated_at: ''
      }
    }
  ],
  order_item_parcel: [
    {
      id: '',
      type: '',
      attributes: {
        created_at: '',
        id: 1,
        order_item_id: 1,
        parcel_id: 1,
        quantity: 1,
        updated_at: ''
      }
    }
  ],
  pet: [
    {
      id: '',
      type: '',
      attributes: {
        birthday: '',
        breed: '',
        gender: '',
        name: '',
        species: ''
      }
    }
  ],
  orderStatus: {
    // confirmed: [false, ''],
    waiting: [false, ''],
    started: [false, ''],
    picked: [false, ''],
    packed: [false, ''],
    billed: [false, ''],
    // nfe_issued: [false, ''],
    // label_generated: [false, ''],
    separated: [false, ''],
    dispatched: [false, ''],
    delivering: [false, ''],
    // transporting: [false, ''],
    delivered: [false, ''],
    canceled: [false, ''],
    currentStatus: ''
  },
  orderTimeline: [],
  orderTrip: [],
  ETA: null,
  ordersETA: [],
  orderLoading: false,
  orderResumeLoading: false,
  orderDetailsLoading: false,
  fraud: false,
  fraud_type: ''
};

const orderSlice = createSlice({
  name: '@order',
  initialState,
  reducers: {
    setOrder(prevState, action) {
      const state = prevState;
      state.order = action.payload.order;
      state.fraud = action.payload.order.data.attributes.fraudulent;
      state.customer =
        action.payload.order.included.filter(el => el.type === 'customer')[0] ||
        {};
      // state.delivery =
      //   action.payload.order.included.filter(el => el.type === 'delivery')[0] ||
      //   {};
      // state.deliverer =
      //   action.payload.order.included.filter(
      //     el => el.type === 'deliverer'
      //   )[0] || {};
      state.order_item =
        action.payload.order.included.filter(el => el.type === 'order_items') ||
        [];
      state.order_alert =
        action.payload.order.included.filter(el => el.type === 'order_alert') ||
        [];
      state.parcel =
        action.payload.order.included.filter(el => el.type === 'parcel') || [];
      state.order_item_parcel =
        action.payload.order.included.filter(
          el => el.type === 'order_item_parcel'
        ) || [];
      state.pet =
        action.payload.order.included.filter(el => el.type === 'pet') || [];
      // state.isLoading = false;
      return state;
    },
    resetOrder() {
      return initialState;
    },
    setOrderTrip(prevState, action) {
      const state = prevState;
      state.orderTrip = action.payload;
      return state;
    },
    setOrdersETA(prevState, action) {
      const state = prevState;
      state.ordersETA = action.payload;
      return state;
    },
    setETA(prevState, action) {
      const state = prevState;
      state.ETA = action.payload;
      return state;
    },
    setOrderLoading(prevState, action) {
      const state = prevState;
      state.orderLoading = action.payload;
      return state;
    },
    setOrderResumeLoading(prevState, action) {
      const state = prevState;
      state.orderResumeLoading = action.payload;
      return state;
    },
    setOrderDetailsLoading(prevState, action) {
      const state = prevState;
      state.orderDetailsLoading = action.payload;
      return state;
    },
    setOrderTimeline(prevState, action) {
      const state = prevState;
      state.orderTimeline = action.payload.orderTimeline;
      state.orderStatus = action.payload.orderStatus;
      return state;
    },
    setFraudOrder(prevState, action) {
      const state = prevState;
      state.fraud = action.payload.fraud;
      state.fraud_type = action.payload.fraud_type;
      return state;
    },
    getOrder(state) {
      return state;
    },
    cancelOrder(state) {
      return state;
    },
    returnItens(state) {
      return state;
    },
    getOrderTimeline(state) {
      return state;
    },
    getPushNotification(state) {
      return state;
    },
    fraudOrder(state) {
      return state;
    },
    chargeBackFraudOrder(state) {
      return state;
    }
  }
});

export const {
  getOrderTimeline,
  returnItens,
  cancelOrder,
  getOrder,
  setOrderTimeline,
  setOrderLoading,
  setOrderResumeLoading,
  setOrderDetailsLoading,
  setETA,
  setOrdersETA,
  setOrderTrip,
  resetOrder,
  setOrder,
  setFraudOrder,
  getPushNotification,
  fraudOrder,
  chargeBackFraudOrder
} = orderSlice.actions;

export default orderSlice.reducer;
