import React from 'react';
import PropTypes from 'prop-types';

import styles from '../CustomDrawer.module.scss';

const CustomDrawerBox = ({ children }) => {
  return <div className={styles.CustomDrawer__box}>{children}</div>;
};

CustomDrawerBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CustomDrawerBox;
