import { notification } from 'antd';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { errorAlert } from '@/src/utils/notifications';

import {
  // deactivateUser as deactivateUserRequest,
  // dispatchResetPassword as dispatchResetPasswordRequest,
  // activateUser as activateUserRequest,
  createUser as createNewUser,
  getPermissions as getPermissionsRequest,
  getUser as getUserRequest,
  getUsers as getUsersRequest,
  updateUser as updateUserRequest
} from '@/src/api/users';
import {
  createUser,
  getPermissionsList,
  // setUserFailed,
  getUser,
  // setUsersFailed,
  getUsers,
  setCount,
  setEditUserIsLoading,
  setPermissionsList,
  setUser,
  // deactivateUser,
  // activateUser,
  setUserIsLoading,
  // dispatchResetUserPassword,
  setUserPermissions,
  setUsers,
  setUsersIsLoading,
  updateUser,
  updateUserBan
  // setUserResetPasswordSuccess
} from '@/src/store/modules/users/slice';

export function* getUserSaga(action) {
  try {
    yield put(setUserIsLoading(true));
    const userDataResponse = yield call(getUserRequest, action.payload);
    const userInfo = userDataResponse.data.data.attributes;
    const userPermissions = userDataResponse.data.included || [];
    // yield put(setUserFailed(false));
    yield put(setUserIsLoading(false));
    yield put(setUserPermissions(userPermissions));
    yield put(setUser(userInfo));
  } catch (err) {
    yield put(setUserIsLoading(false));
    errorAlert(err);
  }
}

// export function* deactivateUserSaga(action) {
//   try {
//     yield put(setUserIsLoading(true));
//     const userDataResponse = yield call(deactivateUserRequest, action.payload);
//     const userInfo = userDataResponse.data.data.attributes;
//     yield put(setUserIsLoading(false));
//     yield put(setUserFailed(false));
//     return yield put(setUser(userInfo));
//   } catch (err) {
//     bugsnag.notify(err);
//     if (err.response.status === 401) {
//       notification.error({
//         message: `Ação não permitida`,
//         duration: 4,
//         className: 'error',
//         description: 'Desativar usuários "Super-Admin" não é permitido'
//       });
//     } else {
//       notification.error({
//         message: `Erro ${err.response.status}`,
//         duration: 4,
//         className: 'error',
//         description: err.response.data.errors[0].detail
//       });
//     }

//     yield put(setUserIsLoading(false));
//     return yield put(setUserFailed(true));
//   }
// }

// export function* dispatchUserPasswordSaga(action) {
//   try {
//     yield put(setUserIsLoading(true));
//     yield call(dispatchResetPasswordRequest, action.payload);

//     yield put(setUserResetPasswordSuccess(true));
//     yield put(setUserIsLoading(false));
//     yield put(setUserFailed(false));
//     yield delay(3000);
//     return yield put(setUserResetPasswordSuccess(false));
//   } catch (err) {
//     bugsnag.notify(err);
//     if (err.response.status === 401) {
//       notification.error({
//         message: `Ação não permitida`,
//         duration: 4,
//         className: 'error',
//         description: 'Desativar usuários "Super-Admin" não é permitido'
//       });
//     } else {
//       notification.error({
//         message: `Erro ${err.response.status}`,
//         duration: 4,
//         className: 'error',
//         description: err.response.data.errors[0].detail
//       });
//     }

//     yield put(setUserIsLoading(false));
//     return yield put(setUserFailed(true));
//   }
// }

// export function* activateUserSaga(action) {
//   try {
//     yield put(setUserIsLoading(true));
//     const userDataResponse = yield call(activateUserRequest, action.payload);
//     const userInfo = userDataResponse.data.data.attributes;
//     yield put(setUserIsLoading(false));
//     yield put(setUserFailed(false));
//     return yield put(setUser(userInfo));
//   } catch (err) {
//     bugsnag.notify(err);
//     notification.error({
//       message: `Erro ${err.response.status}`,
//       duration: 4,
//       className: 'error',
//       description: err.response.data.errors[0].detail
//     });
//     yield put(setUserIsLoading(false));
//     return yield put(setUserFailed(true));
//   }
// }

export function* getUsersSaga(action) {
  try {
    yield put(setUsersIsLoading(true));
    const userDataResponse = yield call(getUsersRequest, action.payload);
    yield put(setUserIsLoading(false));
    // yield put(setUsersFailed(false));
    yield put(
      setUsers({
        users: userDataResponse.data.data,
        query: action.payload.query,
        count: userDataResponse.data.meta.count
      })
    );
    if (userDataResponse.data.meta.stats) {
      const { count } = userDataResponse.data.meta.stats.total;
      yield put(setCount(count));
    }
  } catch (err) {
    yield put(setUsersIsLoading(false));
    errorAlert(err);
  }
}

function* createUserSaga({ payload }) {
  yield put(setEditUserIsLoading(true));
  try {
    const response = yield call(createNewUser, payload);
    if (response.status === 200) {
      yield put(push(`/usuarios`));
      notification.success({
        message: 'Sucesso!',
        duration: 4,
        className: 'success',
        description: 'Novo usuário criado'
      });
      yield put(setEditUserIsLoading(false));
    }
  } catch (err) {
    yield put(setEditUserIsLoading(false));
    errorAlert(err);
  }
}

function* updateUserSaga({ payload }) {
  try {
    // yield put(setEditUserIsLoading(true));
    const userDataResponse = yield call(updateUserRequest, payload);
    // yield put(setEditUserIsLoading(false));
    const userInfo = userDataResponse.data.data.attributes;
    const userPermissions = userDataResponse.data.included;
    // yield put(setUserFailed(false));
    yield put(setUserIsLoading(false));
    yield put(setUserPermissions(userPermissions));
    yield put(setUser(userInfo));
    notification.success({
      message: `Sucesso!`,
      duration: 4,
      className: 'success',
      description: 'Os dados do usuário foram atualizados'
    });
  } catch (err) {
    yield put(setUserIsLoading(false));
    errorAlert(err);
  }
}

export function* updateUserBanSaga({ payload }) {
  const { banned } = payload.data.attributes;
  try {
    yield put(setUserIsLoading(true));
    const userDataResponse = yield call(updateUserRequest, payload);
    const userInfo = userDataResponse.data.data.attributes;
    yield put(setUserIsLoading(false));
    // yield put(setUserFailed(false));
    yield put(setUser(userInfo));
    if (!banned) {
      notification.success({
        message: `Sucesso!`,
        duration: 4,
        className: 'success',
        description: 'Usuário reativado'
      });
    }
  } catch (err) {
    yield put(setUserIsLoading(false));
    if (err.response?.status === 401 && banned) {
      errorAlert(err, 'Não é possível desativar usuários Super-Admin');
    } else {
      errorAlert(err);
    }
  }
}

export function* getPermissionsListSaga({ payload }) {
  try {
    const response = yield call(getPermissionsRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/permissions' });
    const parsed = (
      (normalized.meta && normalized.meta['/permissions'].data) ||
      []
    ).map(object =>
      build(normalized, 'permissions', object.id, { ignoreLinks: true })
    );
    yield put(setPermissionsList(parsed));
  } catch (err) {
    errorAlert(err);
  }
}

function* userSaga() {
  yield takeEvery(getUser, getUserSaga);
  yield takeEvery(getUsers, getUsersSaga);
  // yield takeEvery(deactivateUser, deactivateUserSaga);
  // yield takeEvery(activateUser, activateUserSaga);
  yield takeEvery(createUser, createUserSaga);
  // yield takeEvery(dispatchResetUserPassword, dispatchUserPasswordSaga);
  yield takeEvery(updateUser, updateUserSaga);
  yield takeEvery(updateUserBan, updateUserBanSaga);
  yield takeEvery(getPermissionsList, getPermissionsListSaga);
}

export default userSaga;
