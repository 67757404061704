import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  setAllProducts,
  getAllProducts,
  setLoading,
  exportCSV,
  exportCSVProductsSort,
  importCSVProductsSort,
  importBuyAndEarnPromotions
} from '@/src/store/modules/products-all/slice';
import getAllProductsRequest from '@/src/api/products-all';

import {
  getProductsQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import {
  exportCSVProductsList as exportCSVProductsListRequest,
  exportCSVProductsSort as exportCSVProductsSortRequest
} from '@/src/api/export-file';

import {
  importCSVProductsSort as importCSVProductsSortRequest,
  importBuyAndEarnPromotions as importBuyAndEarnPromotionsRequest
} from '@/src/api/import-file';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getAllProductsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAllProductsRequest, payload);
    const { products, meta } = response.data;
    const count = meta.count > 0 ? meta.count : 0;

    const arrayProducts = products.map(product => {
      const normalized = normalize(product, { endpoint: '/product' });
      const parsed = (
        (normalized.meta && normalized.meta[`/product`].data) ||
        []
      ).map(object =>
        build(normalized, 'product', object.id, { ignoreLinks: true })
      );
      return parsed[0];
    });

    yield put(
      setAllProducts({
        productsList: arrayProducts,
        query: payload.query,
        count
      })
    );

    const queryUrl = yield select(getProductsQueryUrlState);
    const routerState = yield select(getRouterState);
    if (
      routerState.location.search !== queryUrl &&
      routerState.location.pathname.includes('/produtos')
    ) {
      yield put(push(queryUrl));
    }

    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(exportCSVProductsListRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setLoading(false));
}

export function* exportCSVProductsSortSaga(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(exportCSVProductsSortRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setLoading(false));
}

function* importCSVProductsSortSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const data = new FormData();
    data.append('file', payload.csv[0]);
    yield call(importCSVProductsSortRequest, data);
    yield put(setLoading(false));
    successAlert(
      'Sua planilha está sendo processada. Notificaremos por e-mail quando estiver completa!'
    );
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* importBuyAndEarnFileSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const data = new FormData();
    data.append('file', payload.file[0]);
    data.append('starts_at', payload.starts_at);
    data.append('ends_at', payload.ends_at);

    yield call(importBuyAndEarnPromotionsRequest, data);
    yield put(setLoading(false));
    successAlert(
      'Sua planilha está sendo processada. Notificaremos por e-mail quando estiver completa ou se houver algum erro!'
    );
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* allProductsSaga() {
  yield takeEvery(getAllProducts, getAllProductsSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
  yield takeEvery(exportCSVProductsSort, exportCSVProductsSortSaga);
  yield takeEvery(importCSVProductsSort, importCSVProductsSortSaga);
  yield takeEvery(importBuyAndEarnPromotions, importBuyAndEarnFileSaga);
}

export default allProductsSaga;
