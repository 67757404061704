import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  // Select,
  Row,
  Form,
  Col,
  InputNumber,
  Icon,
  Checkbox,
  DatePicker
} from 'antd';

import moment from '@/src/services/moment';

import formatCurrency from '@/src/utils/formatCurrency';
import getNumberFromString from '@/src/utils/getNumberFromString';
import { translateQuickFilter } from '@/src/utils/localeData';
import { elapsedTime } from '@/src/utils/formatTime';
// import buildJsonData from '@/src/utils/buildJsonData';

const { Panel } = Collapse;
// const { Option } = Select;

const ExpeditionFilters = ({ expeditionFilters, setExpeditionFilters }) => {
  const [panelDisabled, setPanelDisabled] = useState({
    date: false,
    minutes: false
  });
  // const [hubs, setHubs] = useState([]);

  const { query } = useSelector(state => state.expedition);
  // const hubsList = useSelector(state => state.hubsData.list);

  const statusList = [
    'waiting',
    'started',
    'picked',
    'packed',
    'billed',
    'separated',
    'dispatched',
    'delivering',
    'delivered',
    'canceled'
  ];

  useEffect(() => {
    setExpeditionFilters(query.filters);
    // eslint-disable-next-line
  }, [query.filters]);

  // useEffect(() => {
  //   const hubsArr = buildJsonData(hubsList, 'hubs');
  //   setHubs(hubsArr);
  // }, [hubsList]);

  const handleFilters = (value, key, type, tag = false) => {
    setExpeditionFilters(prev => {
      const prevFilters = prev.filter(item => {
        if (!item.value || item.value === '') return false;
        if (item.key === key) {
          return item.type !== type;
        }
        return true;
      });

      let filters;
      if (!value || value === '' || value.length === 0) {
        filters = [...prevFilters];
      } else {
        filters = [
          ...prevFilters,
          {
            key,
            type,
            value: typeof value === 'object' ? value.join() : value,
            tag
          }
        ];
      }

      return filters;
    });
  };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      // case 'BY_HUB':
      //   handleFilters(value, 'hub_internal_id', 'match', true);
      //   break;
      case 'BY_MIN_VALUE':
        handleFilters(value > 0 ? value : '', 'total', 'gte');
        break;
      case 'BY_MAX_VALUE':
        handleFilters(value > 0 ? value : '', 'total', 'lte');
        break;
      case 'FROM_ORDER_TIME':
        handleFilters(
          value > 0 ? elapsedTime(value) : '',
          'created_at',
          'lte',
          value
        );
        break;
      case 'TO_ORDER_TIME':
        handleFilters(
          value > 0 ? elapsedTime(value) : '',
          'created_at',
          'gte',
          value
        );
        break;
      case 'FROM_TABLE_TIME':
        handleFilters(value > 0 ? value : '', 'eta_max', 'gte');
        break;
      case 'TO_TABLE_TIME':
        handleFilters(value > 0 ? value : '', 'eta_max', 'lte');
        break;
      case 'START_DATE_CREATED':
        handleFilters(
          value > 0
            ? moment(value)
                .startOf('date')
                .format()
            : '',
          'created_at',
          'gte'
        );
        break;
      case 'END_DATE_CREATED':
        handleFilters(
          value > 0
            ? moment(value)
                .endOf('date')
                .format()
            : '',
          'created_at',
          'lte'
        );
        break;
      case 'START_DATE_UPDATED':
        handleFilters(
          value > 0
            ? moment(value)
                .startOf('date')
                .format()
            : '',
          'updated_at',
          'gte',
          true
        );
        break;
      case 'END_DATE_UPDATED':
        handleFilters(
          value > 0
            ? moment(value)
                .endOf('date')
                .format()
            : '',
          'updated_at',
          'lte',
          true
        );
        break;
      case 'BY_STATUS':
        handleFilters(value, 'status', 'eq');
        break;
      default:
        console.log('Type is wrong/not found');
    }
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
        onChange={e => {
          setPanelDisabled({ date: false, minutes: false });
          if (e.includes('5')) {
            setPanelDisabled({ minutes: true });
          } else if (e.includes('7')) {
            setPanelDisabled({ date: true });
          }
        }}
      >
        {/* <Panel header="Hub" key="1">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Pesquisar por hubs"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'BY_HUB')}
              value={
                expeditionFilters
                  .find(item => item.key === 'hub_internal_id')
                  ?.value.toString()
                  .split(',') || []
              }
            >
              {hubs.map(hub => {
                return <Option key={hub.internalId}>{hub.name}</Option>;
              })}
            </Select>
          </Row>
        </Panel> */}
        {/* <Panel header="Região" key="2">
          <Select
            mode="multiple"
            showSearch
            placeholder="Pesquisar por região"
            // onSearch={handleDeliverers}
            onChange={() => {}}
            notFoundContent="Nenhuma região encontrada"
            filterOption={false}
          >
            {deliverers.map(item => {
              return <Option key={item.id}>{item.attributes.name}</Option>;
            })}
          </Select>
        </Panel>
        <Panel header="Estoquistas" key="3">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Pesquisar por estoquistas"
              //   onFocus={populateHubs}
              //   defaultValue={ordersFilters.by_hub}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={() => {}}
            >
              {hubs.map(hub => {
                return <Option key={hub.id}>{hub.attributes.name}</Option>;
              })}
            </Select>
          </Row>
        </Panel> */}
        <Panel header="Valor" key="4">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Mínimo"
                  min={0}
                  step={1}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'BY_MIN_VALUE')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'total' && item.type === 'gte'
                    )?.value || ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Máximo"
                  min={0}
                  step={1}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'BY_MAX_VALUE')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'total' && item.type === 'lte'
                    )?.value || ''
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel header="Data de criação" key="5" disabled={panelDisabled.date}>
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  expeditionFilters.find(
                    item =>
                      item.key === 'created_at' &&
                      item.type === 'gte' &&
                      item.tag === false
                  )
                    ? moment(
                        expeditionFilters.find(
                          item =>
                            item.key === 'created_at' && item.type === 'gte'
                        )?.value
                      )
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'START_DATE_CREATED')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  expeditionFilters.find(
                    item =>
                      item.key === 'created_at' &&
                      item.type === 'lte' &&
                      item.tag === false
                  )
                    ? moment(
                        expeditionFilters.find(
                          item =>
                            item.key === 'created_at' && item.type === 'lte'
                        )?.value
                      )
                    : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'END_DATE_CREATED')}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Data de atualização" key="6">
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  expeditionFilters.find(
                    item => item.key === 'updated_at' && item.type === 'gte'
                    // item.tag === false
                  )
                    ? moment(
                        expeditionFilters.find(
                          item =>
                            item.key === 'updated_at' && item.type === 'gte'
                        )?.value
                      )
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'START_DATE_UPDATED')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  expeditionFilters.find(
                    item => item.key === 'updated_at' && item.type === 'lte'
                    // item.tag === false
                  )
                    ? moment(
                        expeditionFilters.find(
                          item =>
                            item.key === 'updated_at' && item.type === 'lte'
                        )?.value
                      )
                    : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'END_DATE_UPDATED')}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          header="Tempo do pedido (minutos)"
          key="7"
          disabled={panelDisabled.minutes}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Mínimo"
                  min={0}
                  step={1}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'FROM_ORDER_TIME')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'created_at' && item.type === 'lte'
                    )?.tag || ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Máximo"
                  min={0}
                  step={1}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'TO_ORDER_TIME')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'created_at' && item.type === 'gte'
                    )?.tag || ''
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel header="Tempo meta de mesa (ETA)" key="8">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Mínimo"
                  min={0}
                  step={1}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'FROM_TABLE_TIME')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'eta_max' && item.type === 'gte'
                    )?.value || ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Máximo"
                  min={0}
                  step={1}
                  parser={value => getNumberFromString(value)}
                  onChange={evt => handleFilterSelect(evt, 'TO_TABLE_TIME')}
                  value={
                    expeditionFilters.find(
                      item => item.key === 'eta_max' && item.type === 'lte'
                    )?.value || ''
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel header="Status" key="9">
          <Row>
            <Checkbox.Group
              onChange={evt => handleFilterSelect(evt, 'BY_STATUS')}
              style={{ width: '100%' }}
              value={
                expeditionFilters
                  .find(item => item.key === 'status')
                  ?.value.split(',') || []
              }
            >
              <Row type="flex">
                {statusList.map(option => (
                  <Col span={12} key={option}>
                    <Checkbox value={option}>
                      {translateQuickFilter[option]}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

ExpeditionFilters.defaultProps = {
  expeditionFilters: [],
  setExpeditionFilters: () => {}
};

ExpeditionFilters.propTypes = {
  expeditionFilters: PropTypes.arrayOf(PropTypes.shape({})),
  setExpeditionFilters: PropTypes.func
};

export default ExpeditionFilters;
