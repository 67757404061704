import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: [],
  userOrders: [],
  waitingOrders: [],
  waitingOrdersCount: 0,
  // selectedFilters: false,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: [],
    fields: [],
    include: ''
  },
  pageLoading: false,
  contentLoading: false,
  deliveredLoading: false,
  scanLoading: false,
  scanError: false,
  scanInput: '',
  collectedItems: {
    orderId: '',
    orderItems: []
  },
  forceCollectEan: '',
  packedItems: {},
  volumeKinds: [],
  returnedItems: {
    requestId: '',
    orderItems: []
  },
  hubsFilter: '',
  count: 0,
  // dispatchedCount: 0,
  deliveredCount: 0,
  viewer: '',
  reloadOrders: false,
  drawerOrder: {},
  modalData: {
    visibility: false,
    order: {},
    step: ''
  },
  polling: 'running',
  userPolling: 'running',
  reshipments: []
};

const expeditionSlice = createSlice({
  name: '@expedition',
  initialState,
  reducers: {
    resetState(prevState) {
      const state = {
        ...initialState,
        viewer: prevState.viewer
      };
      return state;
    },
    setOrders(prevState, action) {
      const state = prevState;
      state.orders = action.payload;
      return state;
    },
    setUserOrders(prevState, action) {
      const state = prevState;
      state.userOrders = action.payload;
      return state;
    },
    setFilteredOrdersData(prevState, action) {
      const state = prevState;
      state.orders = action.payload.orders;
      if (action.payload.userOrders)
        state.userOrders = action.payload.userOrders;
      return state;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = action.payload;
      return state;
    },
    setCount(prevState, action) {
      const state = prevState;
      state.count = action.payload;
      return state;
    },
    // setDispatchedCount(prevState, action) {
    //   const state = prevState;
    //   state.dispatchedCount = action.payload;
    //   return state;
    // },
    setDeliveredCount(prevState, action) {
      const state = prevState;
      state.deliveredCount = action.payload;
      return state;
    },
    setWaitingOrders(prevState, action) {
      const state = prevState;
      state.waitingOrders = action.payload;
      return state;
    },
    setWaitingOrdersCount(prevState, action) {
      const state = prevState;
      state.waitingOrdersCount = action.payload;
      return state;
    },
    addToCollectedItems: (prevState, action) => {
      const state = prevState;
      state.collectedItems.orderId = action.payload.orderId;
      if (state.collectedItems.orderItems <= 0) {
        state.collectedItems.orderItems.push(action.payload.orderItem);
      }
      const hasOrderItemId = state.collectedItems.orderItems?.find(
        item => item.itemId === action.payload.orderItem.itemId
      );
      if (!hasOrderItemId) {
        state.collectedItems.orderItems.push(action.payload.orderItem);
      } else {
        hasOrderItemId.scanned = action.payload.orderItem.scanned;
        hasOrderItemId.done = action.payload.orderItem.done;
        hasOrderItemId.rupture = action.payload.orderItem.rupture;
      }
      return state;
    },
    removeFromCollectedItems: (prevState, action) => {
      const state = prevState;
      state.collectedItems.orderItems = state.collectedItems.orderItems?.filter(
        item => Number(item.itemId) !== Number(action.payload)
      );
    },
    setCollectedItems(prevState, action) {
      const state = prevState;
      state.collectedItems = action.payload;
      return state;
    },
    setForceCollectEan(prevState, action) {
      const state = prevState;
      state.forceCollectEan = action.payload;
      return state;
    },
    addToReturnedItems(prevState, action) {
      const state = prevState;
      state.returnedItems.requestId = action.payload.requestId;
      if (state.returnedItems.orderItems <= 0) {
        state.returnedItems.orderItems.push(action.payload.orderItem);
      }
      const hasOrderItemId = state.returnedItems.orderItems?.find(
        item => item.itemId === action.payload.orderItem.itemId
      );
      if (!hasOrderItemId) {
        state.returnedItems.orderItems.push(action.payload.orderItem);
      } else {
        hasOrderItemId.quantityLoss = action.payload.orderItem.quantityLoss;
        hasOrderItemId.quantityStock = action.payload.orderItem.quantityStock;
      }
      return state;
    },
    removeFromReturnedItems(prevState, action) {
      const state = prevState;
      state.returnedItems.orderItems = state.returnedItems.orderItems?.filter(
        item => item.itemId !== action.payload
      );
    },
    setReturnedItems(prevState, action) {
      const state = prevState;
      state.returnedItems = action.payload;
      return state;
    },
    setVolumeKinds(prevState, action) {
      const state = prevState;
      state.volumeKinds = action.payload;
      return state;
    },
    setPackedItems(prevState, action) {
      const state = prevState;
      state.packedItems = action.payload;
      return state;
    },
    addToPackedItems(prevState, action) {
      const { volumes } = action.payload;
      const state = prevState;
      volumes.forEach(item => {
        const hasVolume =
          state.packedItems.volumes.find(vol => vol.id === item.id) || false;
        if (hasVolume) {
          const hasProduct =
            hasVolume.volumeItems.find(
              product =>
                product.packedItemId === item.volumeItems[0].packedItemId
            ) || false;
          if (hasProduct) {
            hasProduct.productQty += item.volumeItems[0].productQty;
          } else {
            hasVolume.volumeItems = [
              ...hasVolume.volumeItems,
              item.volumeItems[0]
            ];
          }
        } else {
          state.packedItems.volumes = [...state.packedItems.volumes, item];
        }
      });
      return state;
    },
    removeFromPackedItems(prevState, action) {
      const { volumes } = action.payload;
      const state = prevState;
      const product = state.packedItems.volumes
        .find(vol => vol.id === volumes.id)
        .volumeItems.find(
          productItem =>
            productItem.packedItemId === volumes.volumeItems.packedItemId
        );
      product.productQty -= volumes.volumeItems.productQty;
      return state;
    },
    setPageLoading(prevState, action) {
      const state = prevState;
      state.pageLoading = action.payload;
      return state;
    },
    setContentLoading(prevState, action) {
      const state = prevState;
      state.contentLoading = action.payload;
      return state;
    },
    setDeliveredLoading(prevState, action) {
      const state = prevState;
      state.deliveredLoading = action.payload;
      return state;
    },
    setScanLoading(prevState, action) {
      const state = prevState;
      state.scanLoading = action.payload;
      return state;
    },
    setScanError(prevState, action) {
      const state = prevState;
      state.scanError = action.payload;
      return state;
    },
    setScanInput(prevState, action) {
      const state = prevState;
      state.scanInput = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setViewer(prevState, action) {
      const state = prevState;
      state.viewer = action.payload;
      return state;
    },
    setReloadOrders(prevState, action) {
      const state = prevState;
      state.reloadOrders = action.payload;
      return state;
    },
    setDrawerOrder(prevState, action) {
      const state = prevState;
      state.drawerOrder = action.payload;
      return state;
    },
    setModalVisibility(prevState, action) {
      const state = prevState;
      state.modalData.visibility = action.payload;
      return state;
    },
    setModalOrder(prevState, action) {
      // console.log('setModalOrder', action.payload);
      const state = prevState;
      state.modalData.order = action.payload;
      return state;
    },
    setModalStep(prevState, action) {
      const state = prevState;
      state.modalData.step = action.payload;
      return state;
    },
    setOrderReturn(state) {
      return state;
    },
    setHubsFilter(prevState, action) {
      const state = prevState;
      state.hubsFilter = action.payload;
      return state;
    },
    // setSelectedFilters(prevState, action) {
    //   const state = prevState;
    //   state.selectedFilters = action.payload;
    //   return state;
    // },
    setPolling(prevState, action) {
      const state = prevState;
      state.polling = action.payload;
      return state;
    },
    setUserPolling(prevState, action) {
      const state = prevState;
      state.userPolling = action.payload;
      return state;
    },
    setOrderReshipments(prevState, action) {
      const state = prevState;
      state.reshipments = action.payload;
      return state;
    },
    getOrdersData(state) {
      return state;
    },
    getUserOrdersData(state) {
      return state;
    },
    getAllOrdersData(state) {
      return state;
    },
    getDeliveredOrdersData(state) {
      return state;
    },
    getOrderById(state) {
      return state;
    },
    getDrawerOrder(state) {
      return state;
    },
    getFilteredOrdersData(state) {
      return state;
    },
    getOrderStatus(state) {
      return state;
    },
    getVolumeKinds(state) {
      return state;
    },
    getHubs(state) {
      return state;
    },
    getCreateNotification(state) {
      return state;
    },
    getProductScan(state) {
      return state;
    },
    getUndoProductScan(state) {
      return state;
    },
    getOrderReshipments(state) {
      return state;
    }
  }
});

export const {
  resetState,
  setOrders,
  setUserOrders,
  setFilteredOrdersData,
  setQuery,
  setCount,
  // setDispatchedCount,
  setDeliveredCount,
  setWaitingOrders,
  setWaitingOrdersCount,
  addToCollectedItems,
  removeFromCollectedItems,
  setCollectedItems,
  setForceCollectEan,
  addToReturnedItems,
  removeFromReturnedItems,
  setReturnedItems,
  setVolumeKinds,
  setPackedItems,
  addToPackedItems,
  removeFromPackedItems,
  setPageLoading,
  setContentLoading,
  setDeliveredLoading,
  setScanLoading,
  setScanError,
  setScanInput,
  setPagination,
  setSort,
  setViewer,
  setReloadOrders,
  setDrawerOrder,
  setModalVisibility,
  setModalOrder,
  setModalStep,
  setOrderReturn,
  setHubsFilter,
  // setSelectedFilters,
  setPolling,
  setUserPolling,
  getOrdersData,
  getUserOrdersData,
  getAllOrdersData,
  getDeliveredOrdersData,
  getOrderById,
  getDrawerOrder,
  getFilteredOrdersData,
  getOrderStatus,
  getVolumeKinds,
  getHubs,
  getCreateNotification,
  getProductScan,
  getUndoProductScan,
  setOrderReshipments,
  getOrderReshipments
} = expeditionSlice.actions;

export default expeditionSlice.reducer;
