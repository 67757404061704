import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gatewayStores: [],
  gatewayStore: {},
  query: {
    page: 1,
    perPage: 20,
    // search: '',
    url: ''
  },
  count: 0,
  indexLoading: false,
  showLoading: false
};

const gatewayStoresSlice = createSlice({
  name: '@gatewayStores',
  initialState,
  reducers: {
    setGatewayStores(prevState, action) {
      const state = prevState;
      state.gatewayStores = action.payload.data;
      state.count = action.payload.count;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        // search: action.payload.query.search || '',
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}`
      };
      return state;
    },
    setGatewayStore(prevState, action) {
      const state = prevState;
      state.gatewayStore = action.payload;
      return state;
    },
    setIndexLoading(prevState, action) {
      const state = prevState;
      state.indexLoading = action.payload;
      return state;
    },
    setShowLoading(prevState, action) {
      const state = prevState;
      state.showLoading = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getGatewayStores(state) {
      return state;
    },
    getGatewayStore(state) {
      return state;
    },
    createGatewayStore(state) {
      return state;
    },
    updateGatewayStore(state) {
      return state;
    }
  }
});

export const {
  setGatewayStores,
  setGatewayStore,
  setIndexLoading,
  setShowLoading,
  setPagination,
  setSort,
  getGatewayStores,
  getGatewayStore,
  createGatewayStore,
  updateGatewayStore
} = gatewayStoresSlice.actions;

export default gatewayStoresSlice.reducer;
