import React from 'react';
import { Link } from 'react-router-dom';

const orders = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    render: (text, record) => (
      <Link
        to={`/pedidos/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {text}
      </Link>
    )
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf'
  },
  {
    title: 'Cidade/Estado',
    dataIndex: 'city',
    key: 'city'
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Cadastro em',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true
  },
  {
    title: 'Qnt Pedidos',
    dataIndex: 'orderQuantity',
    key: 'orderQuantity',
    sorter: true
  },
  {
    title: 'Valor Gasto',
    dataIndex: 'total',
    key: 'total',
    sorter: true
  }
];

export default orders;
