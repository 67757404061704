/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Row, Button, Col, Switch, Avatar } from 'antd';

import Tables from '@/src/components/Tables/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import {
  getManufacturers,
  updateManufacturer,
  setManufacturers,
  orderByDragNDrop
} from '@/src/store/modules/manufacturers/slice';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

export const ManufacturersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { manufacturers, query, count, isLoading } = useSelector(
    state => state.manufacturers
  );

  const getManufacturersHandler = useCallback(
    urlQuery => {
      dispatch(getManufacturers(urlQuery));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getManufacturersHandler({ query: { ...params } });
    return () => {
      dispatch(
        setManufacturers({
          data: [],
          query: {
            page: 1,
            perPage: 20,
            search: '',
            url: ''
          },
          count: 0
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = manufacturers[dragIndex];
      const data = update(manufacturers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      });
      dispatch(setManufacturers({ data, query, count }));
      dispatch(orderByDragNDrop(data.map(item => Number(item.id))));
    },
    // eslint-disable-next-line
    [manufacturers]
  );

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
    },
    {
      title: 'Imagem',
      align: 'center',
      width: '15%',
      key: 'image',
      render: (text, record) => (
        <Avatar
          shape="square"
          size={60}
          src={record.attributes.image}
          alt={record.attributes.name}
          icon="camera"
          className="img-placeholder"
        />
      )
    },
    {
      title: 'Nome',
      align: 'left',
      width: '70%',
      key: 'name',
      render: (text, record) => (
        <Link
          to={`fabricantes/${record.attributes.id}`}
          style={{
            textDecoration: 'underline',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {record.attributes.name}
        </Link>
      )
    },
    {
      title: 'Exibir',
      align: 'center',
      width: '15%',
      key: 'status',
      render: (text, record) => (
        <Switch
          defaultChecked={record.attributes.highlight}
          onChange={e => {
            const payload = {
              id: record.id,
              data: {
                highlight: e
              }
            };
            dispatch(updateManufacturer(payload));
          }}
        />
      )
    }
  ];

  return (
    <>
      <Row type="flex" justify="space-between" className="mb-20">
        <h1 className="m-0">Fabricantes</h1>
        <Button
          type="primary"
          onClick={() => history.push('/fabricantes/novo')}
        >
          Novo Fabricante
        </Button>
      </Row>
      <div className="section-wrapper">
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginBottom: 20 }}
        >
          <Col span={16}>
            <Searchbar
              placeholder="Buscar nome ou palavra-chave do fabricante"
              populateResult={getManufacturersHandler}
              query={query}
              value={query.search}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <DndProvider manager={manager.current.dragDropManager}>
              <Tables
                contentToShow="manufacturers"
                columnsDefaults={columns}
                visiblePaginationUp
                visiblePaginationDown
                isLoading={isLoading}
                data={manufacturers}
                history={history}
                count={count}
                query={query}
                populateTables={getManufacturersHandler}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow
                })}
              />
            </DndProvider>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ManufacturersPage;
