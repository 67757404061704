import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Icon, Card, List, Spin, Avatar, Select } from 'antd';

import ActionModal from '@/src/components/Sizes/Modals/ActionModal';

import styles from './ListItems.module.scss';

const { Option } = Select;

const ListItems = ({ data, title, kind, select, handleSizes }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { id } = useParams();
  // console.log(data);

  const { loading: breedsLoading } = useSelector(state => state.breeds);
  const { loading: accessoriesLoading } = useSelector(
    state => state.accessories
  );

  const handleSelectSize = (value, option, obj) => {
    let breedAccessoryType;
    if (!value) {
      breedAccessoryType =
        kind === 'racas'
          ? obj.breeds_accessory_types.find(
              size => size.accessory_type_id === Number(id)
            )?.id
          : obj.breeds_accessory_types.find(
              size => size.breed_id === Number(id)
            )?.id;
    } else if (option?.key?.includes('breed_accessory_type')) {
      breedAccessoryType = option?.key?.split('-')[1];
    }
    const payload = breedAccessoryType
      ? {
          id: breedAccessoryType,
          pt_size: value || '',
          en_size: ''
        }
      : {
          accessory_type_id: kind === 'racas' ? id : option.key.split('-')[1],
          breed_id: kind === 'acessorios' ? id : option.key.split('-')[1],
          pt_size: value,
          en_size: ''
        };
    handleSizes(prev => {
      if (payload.id) {
        const arr = prev.update.filter(
          item => item.breed_accessory_type_id !== payload.id
        );
        return {
          create: prev.create,
          update: [...arr, payload]
        };
      }
      const arr =
        kind === 'racas'
          ? prev.create.filter(item => item.breed_id !== payload.breed_id)
          : prev.create.filter(
              item => item.accessory_type_id !== payload.accessory_type_id
            );
      return {
        create: [...arr, payload],
        update: prev.update
      };
    });
  };

  const handleAccessoriesSizeKey = item => {
    const sizeId = item.breeds_accessory_types?.find(
      obj => obj.breed_id === Number(id)
    )?.id;
    const key = sizeId
      ? `breed_accessory_type-${sizeId}`
      : `acessory_type-${item.id}`;
    return key;
  };

  const handleBreedsSizeKey = item => {
    const sizeId = item.breeds_accessory_types?.find(
      obj => obj.accessory_type_id === Number(id)
    )?.id;
    const key = sizeId ? `breed_accessory_type-${sizeId}` : `breed-${item.id}`;
    return key;
  };

  const handleSelectDefaultValue = item => {
    let size;
    if (kind === 'acessorios') {
      size = item.breeds_accessory_types?.find(
        obj => obj.breed_id === Number(id)
      );
    } else {
      size = item.breeds_accessory_types?.find(
        obj => obj.accessory_type_id === Number(id)
      );
    }
    if (!size || !size.pt_size) return undefined;
    // console.log(size);
    // const arr = item.breeds_accessory_types?.filter(
    //   obj => obj.breed_id === Number(id)
    // );
    // console.log(arr);
    return size.pt_size;
  };

  return (
    <>
      {title && (
        <ActionModal
          visible={showAddModal}
          setVisible={setShowAddModal}
          type={title === 'RAÇAS' ? 'breeds' : 'accessories'}
          action="add"
        />
      )}
      <Col span={title ? 12 : 24} className="list-sizes">
        <Card title={title} className={`no-shadow ${styles.listCard}`}>
          {title && (
            <button
              type="button"
              className={styles.buttonBox}
              onClick={() => setShowAddModal(true)}
            >
              <Icon
                type="plus"
                style={{
                  fontSize: '9px',
                  marginRight: '5px'
                }}
              />
              <span>ADICIONAR</span>
            </button>
          )}
          <Spin
            spinning={kind === 'racas' ? breedsLoading : accessoriesLoading}
          >
            <List className={`${styles.listCard__wrap} sizes-list`}>
              {data.map(item => (
                <List.Item
                  className={`${styles.listCard__item}`}
                  key={`${item.en_name}-${item.id}`}
                >
                  <Link to={`/tamanhos/${kind}/${item.id}`}>
                    <List.Item.Meta
                      avatar={
                        <Avatar shape="square" size={32} src={item.image} />
                      }
                      title={
                        <span
                          style={{
                            fontSize: '13px',
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: id ? 900 : 450,
                            display: 'block'
                          }}
                        >
                          {item.pt_name}
                        </span>
                      }
                      description={
                        <span
                          style={{
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: id ? 900 : 450,
                            display: 'block',
                            lineHeight: 1
                          }}
                        >
                          {item.en_name}
                        </span>
                      }
                    />
                  </Link>
                  <div>
                    {title ? (
                      <Icon
                        type="right"
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#000'
                        }}
                      />
                    ) : (
                      <Select
                        placeholder="Tamanho"
                        style={{ width: '110px' }}
                        onChange={(value, option) =>
                          handleSelectSize(value, option, item)
                        }
                        defaultValue={handleSelectDefaultValue(item)}
                        allowClear
                      >
                        {kind === 'racas' || !item.tags
                          ? select.map(size => (
                              <Option
                                key={handleBreedsSizeKey(item)}
                                value={size}
                              >
                                {size}
                              </Option>
                            ))
                          : item.tags.map(tag => (
                              <Option
                                key={handleAccessoriesSizeKey(item)}
                                value={tag.name}
                              >
                                {tag.name}
                              </Option>
                            ))}
                      </Select>
                    )}
                  </div>
                </List.Item>
              ))}
            </List>
          </Spin>
        </Card>
      </Col>
    </>
  );
};

ListItems.defaultProps = {
  title: '',
  select: [],
  handleSizes: () => {}
};

ListItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  kind: PropTypes.string.isRequired,
  select: PropTypes.arrayOf(PropTypes.string),
  handleSizes: PropTypes.func
};

export default ListItems;
