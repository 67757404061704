import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Col, Row, Badge, Avatar, Icon } from 'antd';

import Counter from '@/src/components/Expedition/Modal/Counter';

import {
  addToReturnedItems,
  removeFromReturnedItems
} from '@/src/store/modules/expedition/slice';

import { useBreakpoint } from '@/src/context/BreakpointContext';

const DevolutionItem = ({
  itemId,
  sku,
  ean,
  productName,
  productImg,
  totalProductQuantity,
  requestId,
  isGift
}) => {
  const [quantityLoss, setQuantityLoss] = useState(0);
  const [quantityStock, setQuantityStock] = useState(0);

  const breakpoint = useBreakpoint();

  const dispatch = useDispatch();

  useEffect(() => {
    if (quantityStock === 0 && quantityLoss === 0) {
      dispatch(removeFromReturnedItems(itemId));
    } else {
      const payload = {
        requestId: Number(requestId),
        orderItem: {
          itemId,
          quantityStock,
          quantityLoss
        }
      };
      dispatch(addToReturnedItems(payload));
    }
  }, [dispatch, quantityLoss, quantityStock, requestId, itemId]);

  const handleStockChange = value => setQuantityStock(value);

  const handleLossChange = value => setQuantityLoss(value);

  return (
    <div className="modal-table__row devolution-table">
      <Row justify="center" align="middle">
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Col span={4} lg={2}>
            <Badge
              count={
                isGift ? (
                  <Icon type="gift" theme="filled" className="dispatch-gift" />
                ) : (
                  0
                )
              }
            >
              <Avatar
                shape="square"
                size={breakpoint.mobile ? 40 : 55}
                src={productImg}
                icon="camera"
                className="img-placeholder"
              />
            </Badge>
          </Col>
          {breakpoint.lg ? (
            <>
              <Col span={7} style={{ textAlign: 'left' }}>
                <span>{`${sku} - ${productName}`}</span>
              </Col>
              <Col span={4}>
                <span>{ean}</span>
              </Col>
              <Col span={3}>
                <span>{totalProductQuantity}</span>
              </Col>
              <Col span={4}>
                <Counter
                  counterTitle=""
                  handleCounterChange={handleStockChange}
                  maxQty={totalProductQuantity - quantityLoss}
                />
              </Col>
              <Col span={4}>
                <Counter
                  counterTitle=""
                  handleCounterChange={handleLossChange}
                  maxQty={totalProductQuantity - quantityStock}
                />
              </Col>
            </>
          ) : (
            <Col span={20} style={{ marginLeft: 4 }}>
              <div className="modal-table__product-text">
                <span>{`${sku} - ${productName}`}</span>
                <span>EAN: {ean}</span>
                <span>Quantidade devolvida: {totalProductQuantity}</span>
              </div>
            </Col>
          )}
        </div>
      </Row>
      {breakpoint.mobile && (
        <Row className="modal-table__counter">
          <Col span={10}>
            <span>Devolver ao estoque</span>
            <Counter
              counterTitle=""
              // totalValue={totalQuantity}
              handleCounterChange={handleStockChange}
              maxQty={totalProductQuantity - quantityLoss}
              // btnAction={setActionType}
            />
          </Col>
          <Col span={4}>
            <span style={{ fontWeight: 'normal' }}>e/ou</span>
          </Col>
          <Col span={10}>
            <span>Declarar perda</span>
            <Counter
              counterTitle=""
              handleCounterChange={handleLossChange}
              maxQty={totalProductQuantity - quantityStock}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

DevolutionItem.defaultProps = {
  itemId: '',
  sku: '',
  ean: '',
  productName: '',
  productImg: '',
  totalProductQuantity: 0,
  requestId: '',
  isGift: false
};

DevolutionItem.propTypes = {
  itemId: PropTypes.string,
  sku: PropTypes.string,
  ean: PropTypes.string,
  productName: PropTypes.string,
  productImg: PropTypes.string,
  totalProductQuantity: PropTypes.number,
  requestId: PropTypes.string,
  isGift: PropTypes.bool
};

export default DevolutionItem;
