import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getAccessoriesList = payload => {
  const getUrl = queryBuilder(payload, '/accessory_types');
  return apiPortal.get(getUrl);
};

export const createAccessory = payload =>
  apiPortal.post('/accessory_types', payload);

export const getAccessoryData = id => apiPortal.get(`/accessory_types/${id}`);

export const updateAccessory = payload =>
  apiPortal.put(`/accessory_types/${payload.id}`, payload);
