import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Tooltip,
  Icon,
  Form,
  Input,
  Breadcrumb,
  Button,
  Card
} from 'antd';

import PropTypes from 'prop-types';

import {
  getDepartmentAttributes,
  getCreateDepartment
} from '@/src/store/modules/catalog-departments/slice';

const NewDepartment = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const dispatch = useDispatch();

  const { editDepartment } = useSelector(state => state.catalogDepartments);

  const handleSubmitWithAllValues = values => {
    const payload = {
      name: values.name,
      search_metadata: values.search_metadata || '',
      title: values.title || '',
      meta_title: values.meta_title || '',
      meta_description: values.meta_description || ''
    };
    dispatch(getCreateDepartment(payload));
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  useEffect(() => {
    dispatch(getDepartmentAttributes(editDepartment?.data?.attributes?.id));
  }, [dispatch, editDepartment]);

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Novo Departamento</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/departamentos">Departamentos</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo Departamento</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card className="no-shadow" title="DETALHES DO DEPARTAMENTO">
          <Col span={12}>
            <Form style={{ marginTop: 10 }}>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Nome do departamento
                    </p>
                    <Tooltip
                      placement="right"
                      title="Nome do departamento que irá aparecer no app"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Campo obrigatório'
                        }
                      ]
                    })(<Input placeholder="Nome do departamento" />)}
                  </Form.Item>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Palavras-chave
                    </p>
                    <Tooltip
                      placement="right"
                      title="Palavras que facilitam encontrar produtos pelo cliente através da busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('search_metadata')(
                      <Input.TextArea placeholder="Escreva as palavras-chave" />
                    )}
                  </Form.Item>
                </Col>
                <h2 style={{ marginBottom: 16 }}>SEO (WEB)</h2>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Title</p>
                    <Tooltip
                      placement="right"
                      title="Título exibido na página do departamento"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 2 }}>
                    {getFieldDecorator('title')(
                      <Input placeholder="Título da vitrine" />
                    )}
                  </Form.Item>
                  <span style={{ display: 'block', marginBottom: 24 }}>
                    *Caso não preenchido, será o nome do departamento
                  </span>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da página exibido em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_title')(
                      <Input placeholder="Exemplo: Zee.Now - Nome do departamento" />
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Description
                    </p>
                    <Tooltip
                      placement="right"
                      title="Descrição da página exibida em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_description')(
                      <Input.TextArea placeholder="Breve descrição do departamento" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Button
                className="ant-btn ant-btn-primary"
                style={{ marginTop: 25 }}
                onClick={onSubmit}
              >
                Salvar
              </Button>
            </Form>
          </Col>
        </Card>
      </div>
    </>
  );
};

NewDepartment.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const NewDepartmentForm = Form.create({ name: 'NewDepartment' })(NewDepartment);

export default NewDepartmentForm;
