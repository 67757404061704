import React, { useState, useCallback } from 'react';
import { Form, Input, Button, Modal, Tooltip } from 'antd';
import PropTypes from 'prop-types';

let id = 1;

const NewCoupon = props => {
  const { form, promoId, createNewCoupon, disabled, isLoadingCupons } = props;
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
    resetFields
  } = form;

  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = useState('');

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const codes = Object.keys(values)
        .filter(key => key.includes('code'))
        .reduce((arr, key) => {
          const codeValue = values[key];
          arr.push(codeValue);
          return arr;
        }, []);
      const payload = {
        codes,
        promotion_id: promoId,
        image: ''
      };
      createNewCoupon(payload);
      resetFields();
    });
    setTimeout(() => setIsVisible(false), 500);
  };

  const removeInput = k => {
    const keys = getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }
    setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  const addInput = () => {
    const keys = getFieldValue('keys');
    const nextKeys = keys.concat((id += 1));
    setFieldsValue({
      keys: nextKeys
    });
  };

  getFieldDecorator('keys', { initialValue: [1] });
  const keys = getFieldValue('keys');

  const formItems = useCallback(
    keys.map(
      (k, index) => (
        <Form.Item
          key={k}
          label={index === 0 ? 'Nome/Código' : ''}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`code${k}`, {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Nome/Código é obrigatório'
              }
            ]
          })(
            <Input
              placeholder="Ex.: Lenus"
              style={{ width: '60%', marginRight: 8 }}
              onChange={e => setValue(e.target.value)}
            />
          )}
          {index > 0 ? (
            <Tooltip title="Remover campo">
              <Button
                className="base bigger-icon"
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                icon="minus-circle"
                type="link"
                onClick={() => removeInput(k)}
              />
            </Tooltip>
          ) : null}
        </Form.Item>
      ),
      [removeInput]
    )
  );

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsVisible(true)}
        disabled={disabled}
      >
        Novo cupom
      </Button>
      <Modal
        visible={isVisible}
        title="Novo Cupom"
        okText="Criar Cupom"
        onCancel={() => setIsVisible(false)}
        onOk={onSubmit}
        okButtonProps={{
          disabled: value.length === 0,
          loading: isLoadingCupons
        }}
      >
        <Form layout="vertical">
          {formItems}
          <Button
            className="base bigger-icon"
            icon="plus-circle"
            type="link"
            onClick={addInput}
          >
            Adicionar cupom
          </Button>
        </Form>
      </Modal>
    </>
  );
};

NewCoupon.propTypes = {
  createNewCoupon: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  promoId: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isLoadingCupons: PropTypes.bool.isRequired
};

const NewCouponForm = Form.create({ name: 'new_coupon_form' })(NewCoupon);

export default NewCouponForm;
