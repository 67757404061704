import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hubsPricesList: [],
  priceIndexingQuery: {
    page: 1,
    perPage: 20,
    count: 0,
    url: '',
    hub_ids: '',
    sku_price_lte: '',
    sku_price_gte: '',
    price_table_ids: ''
  },
  priceIndexingLoading: false
};

const priceIndexingSlice = createSlice({
  name: '@priceIndexing',
  initialState,
  reducers: {
    setPriceIndexing(prevState, action) {
      const state = prevState;

      const { priceIndexingQuery: query, priceIndexing } = action.payload;

      state.priceIndexingQuery = {
        page: Number(query?.page) || 1,
        perPage: query?.perPage || 20,
        hub_ids: query?.hub_ids || '',
        count: query?.count,
        sku_price_lte: query?.sku_price_lte || '',
        sku_price_gte: query?.sku_price_gte || '',
        price_table_ids: query?.price_table_ids || '',
        url: `?page=${query?.page || '1'}&per_page=${query?.perPage || 15}${
          query?.price_table_ids
            ? `&price_table_ids=${query.price_table_ids}`
            : ''
        }${query?.hub_ids ? `&hub_ids=${query.hub_ids}` : ''}${
          query?.sku_price_lte ? `&sku_price_lte=${query.sku_price_lte}` : ''
        }${query?.sku_price_gte ? `&sku_price_gte=${query.sku_price_gte}` : ''}`
      };

      state.hubsPricesList = priceIndexing;
      // console.log(state.hubsPricesList);
      return state;
    },
    setPriceIndexingLoading(prevState, action) {
      const state = prevState;
      state.priceIndexingLoading = action.payload;
      return state;
    },
    getPriceIndexing(state) {
      return state;
    },
    getPriceIndexingLoading(state) {
      return state;
    }
  }
});

export const {
  setPriceIndexing,
  getPriceIndexing,
  getPriceIndexingLoading,
  setPriceIndexingLoading
} = priceIndexingSlice.actions;

export default priceIndexingSlice.reducer;
