import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timelineEvents: [],
  isLoading: false,
  commentCreated: false,
  orderTimeline: []
};

const timelineEventsSlice = createSlice({
  name: '@timeline-events',
  initialState,
  reducers: {
    setTimelineEvents(prevState, action) {
      const state = prevState;
      state.timelineEvents = action.payload;
      return state;
    },
    setOrderTimeline(prevState, action) {
      const state = prevState;
      state.orderTimeline = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setCommentCreated(prevState, action) {
      const state = prevState;
      state.commentCreated = action.payload;
      return state;
    },
    getTimeline(state) {
      return state;
    },
    getOrderTimeline(state) {
      return state;
    },
    commentTimeline(state) {
      return state;
    },
    deleteCommentTimeline(state) {
      return state;
    },
    updatesTimeline(state) {
      return state;
    },
    commentOrderTimeline(state) {
      return state;
    }
  }
});

export const {
  setTimelineEvents,
  setIsLoading,
  getTimeline,
  setCommentCreated,
  commentTimeline,
  deleteCommentTimeline,
  updatesTimeline,
  setOrderTimeline,
  commentOrderTimeline,
  getOrderTimeline
} = timelineEventsSlice.actions;

export default timelineEventsSlice.reducer;
