import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Tooltip, Icon, Input } from 'antd';

const SeoForm = props => {
  const { content, getFieldDecorator, setFieldsValue, editPermission } = props;

  useEffect(() => {
    if (content?.id) {
      setFieldsValue({
        meta_title: content?.metaTitle || '',
        meta_description: content?.metaDescription || ''
      });
    }
    // eslint-disable-next-line
  }, [content.id]);

  return (
    <>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Meta Title</p>
            <Tooltip
              placement="right"
              title="Título da página do produto exibido em ferramentas de busca"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator(
            'meta_title',
            {}
          )(
            <Input
              placeholder="Exemplo: Zee.Now - Nome do produto"
              disabled={!editPermission}
            />
          )}
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
              Meta Description
            </p>
            <Tooltip
              placement="right"
              title="Descrição do produto exibida em ferramentas de busca"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator(
            'meta_description',
            {}
          )(
            <Input.TextArea
              placeholder="Breve descrição do produto"
              disabled={!editPermission}
            />
          )}
        </Row>
      </Form.Item>
    </>
  );
};

SeoForm.defaultProps = {
  content: {
    active: false,
    externalTitle: ''
  },
  getFieldDecorator: () => {},
  setFieldsValue: () => {}
};

SeoForm.propTypes = {
  content: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  setFieldsValue: PropTypes.func,
  editPermission: PropTypes.bool.isRequired
};

export default SeoForm;
