import React from 'react';
import { Avatar, Tooltip } from 'antd';

const newAisleModal = [
  {
    dataIndex: 'attributes',
    key: 'attributes.image',
    render: record =>
      record.present_on_aisle ? (
        <Tooltip title="Esse produto já foi adicionado">
          <Avatar
            shape="square"
            size={60}
            src={record.image}
            icon="camera"
            className="img-placeholder"
          />
        </Tooltip>
      ) : (
        <Avatar
          shape="square"
          size={60}
          src={record.image}
          icon="camera"
          className="img-placeholder"
        />
      ),
    width: '20%'
  },
  {
    title: 'Produto',
    dataIndex: 'attributes',
    key: 'attributes.name',
    render: record =>
      record.present_on_aisle ? (
        <Tooltip title="Esse produto já foi adicionado">
          <p>{record?.name}</p>
        </Tooltip>
      ) : (
        <p>{record?.name}</p>
      )
  }
];

export default newAisleModal;
