export const formatDDIPhone = v => {
  const cleanString = v.replace(/\D/g, '');
  const formatDDI = cleanString.replace(/^(\d\d)(\d\d)/g, '+$1 ($2)');
  return formatDDI.replace(/(\d{5})(\d)/, ' $1-$2');
};

export const formatDDDPhone = v => {
  const cleanString = v.replace(/\D/g, '');
  const formatDDD = cleanString.replace(/^(\d{2})(\d)/g, '($1) $2');
  return formatDDD.replace(/(\d)(\d{4})$/, '$1-$2');
};
