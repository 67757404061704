import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';

import styles from '../CustomDrawer.module.scss';

import handleTableColumns from './columns';

const CustomDrawerTable = ({ data, columns, className, loading }) => {
  return (
    <Table
      loading={loading}
      pagination={false}
      dataSource={data}
      columns={handleTableColumns(columns)}
      rowKey={record => `${columns}-${record.id || record.number}`}
      className={`${styles.CustomDrawer__table} ${
        className ? styles[`CustomDrawer__table--${className}`] : ''
      }`}
      // tooltip da tabela de devoluções, no drawer da expedição
      components={
        columns === 'returnedItems'
          ? {
              body: {
                row: props => (
                  <Tooltip
                    overlayClassName="returned-items"
                    title={
                      <Table
                        rowKey={record => `returned-items-${record.id}`}
                        columns={[
                          {
                            title: 'Qtd. em avaria',
                            width: '50%',
                            align: 'center',
                            render: record => record.lostQuantity
                          },
                          {
                            title: 'Qtd. devolvida ao estoque',
                            width: '50%',
                            align: 'center',
                            render: record => record.restockQuantity
                          }
                        ]}
                        dataSource={data}
                        pagination={false}
                      />
                    }
                  >
                    <tr {...props} />
                  </Tooltip>
                )
              }
            }
          : {}
      }
    />
  );
};

CustomDrawerTable.defaultProps = {
  data: [],
  className: '',
  loading: false
};

CustomDrawerTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default CustomDrawerTable;
