import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getShippingsData = payload => {
  const queryObj = payload;
  const manualFilter = payload?.query?.filters.find(
    item => item.key === 'manual'
  );
  if (!manualFilter) {
    queryObj.query.filters = [
      ...queryObj.query.filters,
      { key: 'manual', type: 'eq', value: 'true' }
    ];
  }
  const url = dispatchQueryBuilder(queryObj, '/embarks');
  return apiDispatch.get(url);
};

export const getShippingById = payload => {
  // console.log(payload);
  const url = dispatchQueryBuilder(payload, `/embarks/${payload.id}`);
  return apiDispatch.get(url);
};

export const updateShipping = payload =>
  apiDispatch.patch(`/embarks/${payload.data.id}`, payload);

export const createShipping = payload => apiDispatch.post('/embarks', payload);

export const deleteShipping = payload =>
  apiDispatch.delete(`/embarks/${payload}`);

export const getDeliveryCompaniesData = () =>
  apiDispatch.get('/delivery_companies?page[size]=50&sort=name');

export const addNewDeliveryCompany = payload =>
  apiDispatch.post('/delivery_companies', payload);

export const getDeliverersData = () => apiDispatch.get('/deliverers');

export const getDeliveryKindsData = () =>
  apiDispatch.get('/delivery_kinds?page[size]=50&sort=name');
