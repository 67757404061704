/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Icon,
  Form,
  Modal,
  Breadcrumb,
  Button,
  Card,
  Select,
  Switch,
  Menu,
  Dropdown,
  Spin,
  Avatar
} from 'antd';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { debounce } from 'throttle-debounce';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Audit from '@/src/components/Audit/index';

import { getAllProducts } from '@/src/store/modules/products-all/slice';
import {
  getCreateRecommendation,
  getUpdateRecommendation,
  getRecommendation,
  getSpotlightSelect,
  setSpotlightSelect,
  setSpotlightLoading,
  setRecommendations
} from '@/src/store/modules/recommendations/slice';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';
import style from '../../Recom.module.scss';

export const RecommendationPage = () => {
  const dispatch = useDispatch();

  const RNDContext = createDndContext(HTML5Backend);

  const { id } = useParams();
  const { Option } = Select;

  const newRecommendation = !id;

  const [showAudit, setShowAudit] = useState(false);

  const [isSectionEnabled, setIsSectionEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // form
  const [selectedRecomType, setSelectedRecomType] = useState('');
  const [selectedSLProduct, setSelectedSLProduct] = useState('');
  const [slProductId, setSlProductId] = useState('');
  const [recomStatus, setRecomStatus] = useState(false);

  // products
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [productsToRemove, setProductsToRemove] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const {
    recommendation,
    spotlightSelect,
    spotlightLoading,
    isLoading
  } = useSelector(state => state.recommendations);
  const {
    productsList,
    isLoading: isLoadingProducts,
    query,
    count
  } = useSelector(state => state.allProducts);

  // Handle data
  const cleanStates = () => {
    setSelectedRecomType('');
    setSelectedSLProduct('');
    setSlProductId('');
    setRecomStatus(false);
  };

  const handleSpotlightSelect = useRef(
    debounce(500, value => {
      const spotlightQuery = {
        query: {
          filters: {},
          page: 1,
          perPage: 100,
          search: value,
          sort: '',
          url: ''
        }
      };
      dispatch(getSpotlightSelect(spotlightQuery));
    })
  ).current;

  useEffect(() => {
    if (!newRecommendation) {
      dispatch(getRecommendation(Number(id)));
    } else {
      cleanStates();
    }
    dispatch(
      getAllProducts({
        query: {
          filters: recommendation?.spotlight_product?.category_id
            ? {
                by_category: recommendation.spotlight_product.category_id
              }
            : {},
          perPage: 15,
          url: ''
        }
      })
    );
    dispatch(
      setRecommendations({
        recommendations: [],
        query: {},
        count: 0
      })
    );
    dispatch(setSpotlightSelect([]));
    // eslint-disable-next-line
  }, []);

  // Handle products lists
  useEffect(() => {
    // const products = productsList.map(item => item.data);
    setAllProducts(productsList);
  }, [productsList]);

  const getProductsList = useCallback(
    urlQuery => {
      dispatch(getAllProducts(urlQuery));
    },
    [dispatch]
  );

  // Get recommendation by id
  useEffect(() => {
    if (
      !newRecommendation &&
      recommendation !== undefined &&
      recommendation?.spotlight_product !== undefined
    ) {
      // form
      setSelectedRecomType(recommendation.group_id);
      setSelectedSLProduct(recommendation.spotlight_product);
      setRecomStatus(recommendation.status);

      const productsIds = [];
      recommendation.products.forEach(product => {
        productsIds.push(product.id);
      });
      setProductsToAdd(productsIds);
      setRecommendedProducts(recommendation.products);
      setProductsData(recommendation.products);
    }
  }, [recommendation, newRecommendation]);

  useEffect(() => {
    if (selectedSLProduct !== undefined) {
      setSlProductId(selectedSLProduct.id);
    }
  }, [selectedSLProduct]);

  // Handle "recommended products" section visibility
  useEffect(() => {
    if (selectedRecomType && selectedSLProduct) {
      setIsSectionEnabled(true);
    }
  }, [selectedSLProduct, selectedRecomType]);

  // Handle save button
  const handleSaveButton = () => {
    const products = recommendedProducts.map(item => Number(item.id));
    let payload = {
      type: Number(selectedRecomType.key) || selectedRecomType,
      spotlight_product: Number(selectedSLProduct.id),
      active: recomStatus ? 1 : 0,
      recommendations: products
    };

    if (newRecommendation) {
      dispatch(getCreateRecommendation(payload));
    } else {
      payload = {
        updateData: { ...payload, id: Number(id) },
        updateSort: { id: Number(id), products: recommendedProducts }
      };
      dispatch(getUpdateRecommendation(payload));
    }
  };

  // Drag n Drop
  const type = 'DragableBodyRow';

  const DragableBodyRow = ({
    index,
    moveRow,
    className,
    styles,
    ...restProps
  }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: item => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      item: { type, index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...styles }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    );
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = recommendedProducts[dragIndex];
      setRecommendedProducts(
        update(recommendedProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [recommendedProducts]
  );

  const manager = useRef(RNDContext);
  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  // Select products to add (add products modal)
  const onSelectToAdd = (selectedRowsKeys, selectedRowsData) => {
    setProductsToAdd(selectedRowsKeys);
    setProductsData(prev => {
      const current = prev.filter(
        item => !selectedRowsData.find(product => product.id === item.id)
      );
      return [...current, ...selectedRowsData];
    });
  };

  // Select products to remove (recommended products table)
  const onSelectToRemove = selectedRowKeys => {
    setProductsToRemove(selectedRowKeys);
  };

  // Products functions
  const handleAddProducts = () => {
    setIsModalVisible(false);
    setRecommendedProducts(
      productsData.filter(item => productsToAdd.includes(item.id))
    );
  };

  const handleRemoveProducts = () => {
    setRecommendedProducts(prev =>
      prev.filter(item => !productsToRemove.includes(item.id))
    );
    setProductsToAdd(prev =>
      prev.filter(item => !productsToRemove.includes(item))
    );
    setProductsToRemove([]);
  };

  // Handle recommended products search
  const handleArraySearch = e => {
    const toSearch = e.query.search;
    const newDataToShow = [];
    if (toSearch !== '') {
      productsData.forEach(product => {
        const productTitle =
          product.title?.toLowerCase() || product.name?.toLowerCase();
        if (productTitle.includes(toSearch.toLowerCase())) {
          newDataToShow.push(product);
        }
      });
      setRecommendedProducts(newDataToShow);
    } else {
      setRecommendedProducts(productsData);
    }
  };

  // Tables
  const tableColumnsRem = [
    {
      title: '',
      key: 'image',
      width: 90,
      align: 'center',
      render: record => {
        return (
          <Avatar
            shape="square"
            size={60}
            alt={record.name || record.title}
            src={record.image}
            icon="camera"
            className="img-placeholder"
          />
        );
      }
    },
    {
      title: '',
      key: 'name',
      render: record => record.name || record.title
    },
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'ant-drag-visible',
      render: () => (
        <img src={dragIcon} alt="drag icon" style={{ fontSize: 20 }} />
      )
    }
  ];
  const tableColumnsAdd = [
    {
      title: '',
      key: 'image',
      width: 90,
      align: 'center',
      render: record => {
        return (
          <Avatar
            shape="square"
            size={60}
            alt={record.name || record.title}
            src={record.image}
            icon="camera"
            className="img-placeholder"
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'name'
    }
  ];

  // Recommendation actions
  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );
  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <div style={isSectionEnabled ? { paddingBottom: 100 } : {}}>
      {!newRecommendation && (
        <Audit
          id={id}
          title="RECOMENDAÇÃO"
          type="Recommendation"
          visible={showAudit}
          setVisible={setShowAudit}
        />
      )}
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>
            {newRecommendation
              ? 'Nova Recomendação'
              : recommendation?.spotlight_product?.title}
          </h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/recomendacoes" style={{ textDecoration: 'underline' }}>
            Recomendações
          </Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {newRecommendation
            ? 'Nova Recomendação'
            : recommendation?.spotlight_product?.title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          backgroundColor: '#ebebeb',
          minHeight: '48px',
          padding: '8px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DA RECOMENDAÇÃO"
          extra={!newRecommendation ? actionsExtra : ''}
          style={{ marginBottom: '8px', padding: '10px 0 0' }}
        >
          <Row span={11}>
            <Form style={{ marginTop: 10 }}>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col span={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Tipo de recomendação
                    </p>
                  </div>
                  <Form.Item style={{ marginBottom: 10 }}>
                    <Select
                      mode="lucy"
                      labelInValue
                      placeholder="Todos os tipos"
                      onFocus={() => {}}
                      size="default"
                      style={{ marginBottom: '20px' }}
                      onSelect={e => {
                        setSelectedRecomType(e.key);
                      }}
                      onChange={e => setSelectedRecomType(e.key)}
                      value={
                        selectedRecomType
                          ? {
                              key: selectedRecomType,
                              label: recommendation.group_name
                            }
                          : undefined
                      }
                    >
                      <Option value={1} key={1}>
                        Conjunto
                      </Option>
                      <Option value={2} key={2}>
                        Upsell
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col span={10}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 3
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: 'bold' }}>
                      Produto em destaque
                    </p>
                  </div>
                  <Form.Item>
                    <Select
                      showSearch
                      labelInValue
                      style={{ width: '100%' }}
                      placeholder="Selecione o produto em destaque"
                      showArrow={false}
                      size="default"
                      notFoundContent={
                        spotlightLoading ? <Spin size="small" /> : null
                      }
                      filterOption={false}
                      value={
                        slProductId
                          ? {
                              key: slProductId,
                              label: selectedSLProduct?.title
                            }
                          : undefined
                      }
                      onSelect={e =>
                        setSelectedSLProduct(
                          spotlightSelect.find(product => product.id === e.key)
                        )
                      }
                      onSearch={value => {
                        dispatch(setSpotlightLoading(true));
                        handleSpotlightSelect(value);
                      }}
                    >
                      {!spotlightLoading &&
                        spotlightSelect.map(product => {
                          if (
                            slProductId !== '' &&
                            product.id !== slProductId
                          ) {
                            return (
                              <Option value={product.id} key={product.id}>
                                {product.attributes.name}
                              </Option>
                            );
                          }
                          return (
                            <Option value={product.id} key={product.id}>
                              {product.attributes.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Status
                    </p>
                  </div>
                  <Form.Item>
                    <Switch
                      checked={recomStatus}
                      onChange={e => {
                        setRecomStatus(e);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card>

        <Card
          className="no-shadow"
          title="PRODUTOS RECOMENDADOS"
          style={{ padding: '10px 0 0' }}
          extra={
            <Button
              style={{ height: 36 }}
              onClick={() => setIsModalVisible(true)}
              className="ant-btn ant-btn-primary"
              disabled={productsToRemove.length > 0}
            >
              Adicionar Produto
            </Button>
          }
        >
          {!isSectionEnabled ? (
            <div className={style.disabledSection}>
              <Icon
                type="info-circle"
                theme="filled"
                className={style.disabledSection__icon}
              />
              <p className={style.disabledSection__text}>
                Escolha um produto em destaque para adicionar produtos.
              </p>
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'column'
            }}
          >
            <Row style={{ marginBottom: '30px' }}>
              <div style={{ marginTop: '-20px' }}>
                <p>
                  Selecione o(s) produtos(s) para adicionar na vitrine do
                  produto em destaque.
                </p>
              </div>
            </Row>
            <Row>
              <Searchbar
                placeholder="Nome do produto"
                populateResult={e => handleArraySearch(e)}
                query={query}
              />

              {productsToRemove?.length > 0 ? (
                <div className={style.removeBox}>
                  <div className={style.removeBox__text}>
                    <p>
                      {productsToRemove?.length === 1
                        ? '1 produto selecionado'
                        : `${productsToRemove?.length} produtos selecionados`}
                    </p>
                  </div>
                  <Button
                    className="ant-btn"
                    onClick={() => handleRemoveProducts()}
                  >
                    Remover
                  </Button>
                </div>
              ) : null}

              {/* Drag and drop table */}
              {recommendedProducts.length > 0 ? (
                <DndProvider manager={manager.current.dragDropManager}>
                  <Table
                    style={{ marginTop: 30 }}
                    columns={tableColumnsRem}
                    rowKey={record => record.id}
                    dataSource={recommendedProducts}
                    pagination={false}
                    className="ant-table-select-drag"
                    rowSelection={{
                      selectedRowKeys: productsToRemove,
                      onChange: (selectedId, data) =>
                        onSelectToRemove(selectedId, data),
                      columnWidth: 50
                    }}
                    components={components}
                    onRow={(record, index) => ({
                      index,
                      moveRow
                    })}
                  />
                </DndProvider>
              ) : (
                <div className={style.emptyTable}>
                  <span>Nenhum produto cadastrado.</span>
                </div>
              )}
            </Row>

            {/* Add products modal */}
            <Modal
              className="ant-modal-recom-addProducts"
              visible={isModalVisible}
              title="ADICIONAR PRODUTOS"
              centered
              width={800}
              onCancel={() => setIsModalVisible(false)}
              footer={
                <>
                  <Button
                    disabled={productsToAdd.length < 1}
                    style={{ height: 36 }}
                    className="ant-btn ant-btn-primary"
                    onClick={() => handleAddProducts()}
                  >
                    Adicionar
                  </Button>
                </>
              }
            >
              <Searchbar
                placeholder="Nome produto"
                populateResult={getProductsList}
                query={query}
                value={query.search}
              />
              <p
                style={{ margin: '16px 0', fontWeight: 'bold', color: '#000' }}
              >
                {productsToAdd.length === 1
                  ? '1 produto selecionado'
                  : `${productsToAdd.length} produtos selecionados`}
              </p>
              {allProducts.length > 0 ? (
                <Table
                  columns={tableColumnsAdd}
                  rowKey={record => record.id}
                  dataSource={allProducts}
                  loading={isLoadingProducts}
                  defaultSortOrder="ascend"
                  className="ant-table-select-drag"
                  rowSelection={{
                    selectedRowKeys: productsToAdd,
                    onChange: (selectedId, data) =>
                      onSelectToAdd(selectedId, data),
                    columnWidth: 50
                  }}
                  pagination={{
                    defaultPageSize: 15,
                    total: count,
                    onChange: page =>
                      getProductsList({ query: { ...query, page } })
                  }}
                />
              ) : (
                <div style={{ padding: '24px 0' }}>
                  <span>
                    Nenhum produto encontrado na categoria desta recomendação.
                  </span>
                </div>
              )}
            </Modal>
          </div>
        </Card>
      </div>

      {isSectionEnabled ? (
        <footer className="footer-form-save">
          <Button
            disabled={productsToRemove.length > 0}
            style={{ height: 36, left: '85%' }}
            className="ant-btn ant-btn-primary"
            onClick={() => handleSaveButton()}
            loading={isLoading}
          >
            Salvar no App
          </Button>
        </footer>
      ) : null}
    </div>
  );
};

export default RecommendationPage;
