import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  isLoading: false
};

const countriesSlice = createSlice({
  name: '@countries',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setCountries(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    getCountries(state) {
      return state;
    }
  }
});

export const {
  setIsLoading,
  setCountries,
  getCountries
} = countriesSlice.actions;

export default countriesSlice.reducer;
