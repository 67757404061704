import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Select, Button, Spin } from 'antd';
import { debounce } from 'throttle-debounce';

import {
  getPriceTablesSelect,
  setPriceTablesSelect,
  exportCSVPriceTable
} from '@/src/store/modules/price-tables/slice';

const { Option } = Select;

const PriceTableExportModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [priceTable, setPriceTable] = useState({});

  const { priceTablesSelect, priceTablesSelectLoading } = useSelector(
    state => state.priceTables
  );

  const delayedSearchQuery = useRef(
    debounce(300, value => {
      if (!value) {
        dispatch(setPriceTablesSelect([]));
      } else {
        const params = {
          query: {
            page: 1,
            perPage: 100,
            search: value
          }
        };
        dispatch(getPriceTablesSelect(params));
      }
    })
  ).current;

  const handleConfirm = () => {
    dispatch(exportCSVPriceTable(priceTable));
    setShowModal(false);
  };

  return (
    <Modal
      visible={showModal}
      className="centered-footer"
      title="EXPORTAR TABELA DE PREÇOS"
      centered
      destroyOnClose
      onCancel={() => setShowModal(false)}
      footer={
        <div>
          <Button
            className="ant-btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button
            className="ant-btn-primary"
            onClick={handleConfirm}
            disabled={!priceTable.key}
          >
            Confirmar
          </Button>
        </div>
      }
    >
      <p style={{ marginTop: 16 }}>
        Escolha a tabela de preços que deseja exportar
      </p>
      <Select
        placeholder="Digite o nome da tabela"
        style={{ width: '100%', marginBottom: 16 }}
        labelInValue
        onChange={value => setPriceTable(value)}
        onSearch={value => delayedSearchQuery(value)}
        filterOption={false}
        showSearch
        allowClear
        dropdownMatchSelectWidth={false}
        dropdownRender={menu =>
          priceTablesSelectLoading ? (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                padding: '24px 0'
              }}
            >
              <Spin size="small" />
            </div>
          ) : (
            menu
          )
        }
      >
        {priceTablesSelect.map(item => (
          <Option key={`price-table-${item.id}`} value={item.id}>
            {item.attributes.name}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

PriceTableExportModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default PriceTableExportModal;
