import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Card,
  Select,
  Switch,
  Tooltip,
  Icon,
  Upload,
  message,
  Modal
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import {
  getAisles,
  // setPagination,
  setSort,
  sendCSVOrderBy,
  exportCSVAisle,
  inactiveAisle,
  activeAisle,
  orderAllAisles
} from '@/src/store/modules/aisles/slice';
import { getDepartments } from '@/src/store/modules/departments/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import ModalCSV from '@/src/components/ModalCSV';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

import './listAisles.scss';

const { Option, OptGroup } = Select;
const { confirm } = Modal;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

function ListAisles() {
  // const [selectedTableModal, setSelectedTableModal] = useState([]);
  const [data, setData] = useState([]);
  const [editDrag, setEditDrag] = useState(false);
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [disabledButtonsOrder, setDisabledButtonsOrder] = useState(false);
  const [category, setCategory] = useState('all');
  const [categoryActive, setCategoryActive] = useState('all');
  const [CSVFile, setCSVFile] = useState([]);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [CSVFileFail, setCSVFileFail] = useState(true);
  const [categoryById, setCategoryById] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const { aisles, count, query, isLoading } = useSelector(
    state => state.aisles
  );

  const { categories } = useSelector(state => state.categories);
  const { departments } = useSelector(state => state.departments);

  const typeTable = 'ListAisles';

  const generalQuery = {
    query: {
      sort: '',
      page: 1,
      perPage: 1000,
      search: ''
    }
  };

  let columns = [
    {
      title: 'Nome',
      dataIndex: 'attributes.name',
      key: '1',
      render: (text, record) => (
        <Link
          to={`/corredores/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: 'Categoria',
      align: 'center',
      key: '2',
      dataIndex: 'attributes',
      render: record => (
        <p>
          {record.category_name} para {record.department_name?.toLowerCase()}
        </p>
      )
    },
    {
      title: 'Nº de Produtos',
      align: 'center',
      key: '3',
      dataIndex: 'attributes.products_count'
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Visibilidade</span>
          <Tooltip
            placement="top"
            overlayClassName="tooltip-table"
            title="Este controle torna o corredor visível no aplicativo."
          >
            <Icon type="info-circle" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      align: 'center',
      dataIndex: 'attributes',
      key: '4',
      render: (text, record) => (
        <>
          {record.attributes.archived_at !== null ? (
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="É preciso desarquivar o corredor para tornalo visível no app."
            >
              <Switch
                disabled={
                  record.attributes.archived_at !== null || !catalogPermission
                }
              />
            </Tooltip>
          ) : (
            <Switch
              defaultChecked={record.attributes.active === true}
              onChange={() =>
                record.attributes.active === false
                  ? dispatch(activeAisle(record.id))
                  : dispatch(inactiveAisle(record.id))
              }
              disabled={!catalogPermission}
            />
          )}
        </>
      )
    }
  ];
  if (editDrag) {
    columns = [
      {
        dataIndex: 'sort',
        width: 30,
        render: () =>
          editDrag ? (
            <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
          ) : null
      },
      {
        title: 'Nome',
        dataIndex: 'attributes.name',
        key: '1',
        render: (text, record) => (
          <Link
            to={`/corredores/${record.id}`}
            style={{
              border: '0',
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            {text}
          </Link>
        )
      },
      {
        title: 'Categoria',
        align: 'center',
        key: '2',
        dataIndex: 'attributes',
        render: record => (
          <p>
            {record.category_name} para {record.department_name?.toLowerCase()}
          </p>
        )
      },
      {
        title: 'Nº de Produtos',
        align: 'center',
        key: '3',
        dataIndex: 'attributes.products_count'
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Visibilidade</span>
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="Este controle torna o corredor visível no aplicativo."
            >
              <Icon type="info-circle" style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        ),
        align: 'center',
        dataIndex: 'attributes',
        key: '4',
        render: (text, record) => (
          <>
            {record.attributes.archived_at !== null ? (
              <Tooltip
                placement="top"
                overlayClassName="tooltip-table"
                title="É preciso desarquivar o corredor para tornalo visível no app."
              >
                <Switch
                  disabled={
                    record.attributes.archived_at !== null || !catalogPermission
                  }
                />
              </Tooltip>
            ) : (
              <Switch
                defaultChecked={record.attributes.active === true}
                onChange={() =>
                  record.attributes.active === false
                    ? dispatch(activeAisle(record.id))
                    : dispatch(inactiveAisle(record.id))
                }
                disabled={!catalogPermission}
              />
            )}
          </>
        )
      }
    ];
  }

  // const rowSelectionModal = {
  //   selectedRowKeys: selectedTableModal,
  //   onChange: selectedRowKeys => {
  //     setSelectedTableModal(selectedRowKeys);
  //   },
  //   getCheckboxProps: record => ({
  //     // console.log(record, 'record');
  //     disabled: record.attributes.present_on_aisle
  //   })
  // };

  const findCategoryById = id => {
    const result = categories.find(categoryId => Number(categoryId.id) === id);
    setCategoryById(result);
  };

  const changeCategory = option => {
    setDisabledButtonsOrder(true);
    setCategory(option);
    findCategoryById(option);
  };

  const changeActive = option => {
    setCategoryActive(option);
  };

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setCSVFile([file]);
      setCSVFileFail(true);
      return false;
    }
    setCSVFileFail(false);
    setCSVFile([file]);
    return false;
  };

  const removeFile = () => {
    setUploadButtonDisabled(false);
    setTimeout(() => {
      setCSVFile([]);
    }, 400);
    setCSVFileFail(true);
  };

  const sendCSVOrderByHandle = useCallback(() => {
    dispatch(sendCSVOrderBy({ type: 'all', category, csv: CSVFile }));
    setShowModalCSV(false);
  }, [dispatch, CSVFile, category]);

  const exportCSVHandle = useCallback(() => {
    dispatch(exportCSVAisle({ type: 'all', category }));
  }, [dispatch, category]);

  const contentModalCSV = (
    <>
      <Row
        style={{
          borderBottom: '3px ##E2E2E2 solid',
          borderTop: '3px ##E2E2E2 solid'
        }}
      >
        <Col style={{ marginBottom: 10 }}>
          <p>
            Você selecionou ordenar categoria
            <strong style={{ textDecoration: 'underline', marginLeft: 5 }}>
              {categoryById?.attributes?.name} para{' '}
              {categoryById?.attributes?.department_name.toLowerCase()}.
            </strong>
          </p>
        </Col>
        <Col style={{ marginBottom: 10 }}>
          <p>
            Para começar a importação de ordenação de corredores na categoria,
            sugerimos exportar a planilha modelo para editar e subir o arquivo
            novamente.
          </p>
        </Col>
        <Col style={{ marginBottom: 10 }}>
          <strong>
            Importante: sempre que uma planilha for atualizada, ela irá
            sobrescrever todas as informações existentes.
          </strong>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', marginRight: 5, color: '#2595D1' }}
            type="vertical-align-bottom"
          />
          <Button
            onClick={exportCSVHandle}
            type="link"
            className="order-timeline"
          >
            <strong style={{ textDecoration: 'underline', color: '#2595D1' }}>
              Exportar planilha corredores
            </strong>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 25 }}>
          <p style={{ marginBottom: 0 }}>
            Caso já possua o arquivo de produtos que deseja atualizar, no
            corredor para a categoria selecionada, anexe a planilha abaixo.
          </p>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeFile}
          >
            <Button
              disabled={uploadButtonDisabled}
              className="ant-btn-secondary-copy"
            >
              Escolher Arquivo
            </Button>
          </Upload>
          {CSVFile.length === 0 ? (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          ) : null}
        </Col>
        {/* {CSVFileFail && (
          <p style={{ marginTop: 5, textAlign: 'center', color: '#d03232' }}>
            Apenas arquivos do tipo CSV são aceitos
          </p>
        )} */}
        {/* <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', color: '#000' }}
            type="question-circle"
          />
          <p style={{ marginBottom: 0, marginLeft: 8 }}>
            Não conhece as regras de importação?{' '}
            <Button type="link" className="order-timeline">
              <strong style={{ textDecoration: 'underline' }}>
                Clique aqui
              </strong>
            </Button>
          </p>
        </Col> */}
      </Row>
    </>
  );

  const queryBuilderWithFilter = useCallback(
    queryBuilder => {
      const queryHandle = {
        ...queryBuilder.query,
        filters: {
          by_category: category,
          active: categoryActive,
          disabled: categoryActive
        }
      };

      if (queryHandle.filters.by_category === 'all') {
        delete queryHandle.filters.by_category;
      }
      if (queryHandle.filters.active === 'all') {
        delete queryHandle.filters.active;
      }
      if (queryHandle.filters.disabled === 'all') {
        delete queryHandle.filters.disabled;
      }
      if (queryHandle.filters.active === 'false') {
        delete queryHandle.filters.active;
      }
      if (queryHandle.filters.disabled === 'true') {
        delete queryHandle.filters.disabled;
      }
      if (queryHandle.filters.disabled === 'false') {
        queryHandle.filters.disabled = 'true';
      }
      if (Object.keys(queryHandle.filters).length === 0) {
        delete queryHandle.filters;
      }
      return queryHandle;
    },
    [category, categoryActive]
  );

  const getAislesHandler = useCallback(
    urlQuery => {
      const queryHandle = queryBuilderWithFilter(urlQuery);
      dispatch(
        getAisles({
          query: {
            ...queryHandle
          }
        })
      );
    },
    [dispatch, queryBuilderWithFilter]
  );

  // const setPaginationHandler = useCallback(
  //   payload => {
  //     dispatch(setPagination({ ...payload }));
  //   },
  //   [dispatch]
  // );
  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [data]
  );

  const saveAllConfirm = () => {
    confirm({
      title: 'SALVAR ALTERAÇÕES NO APP',
      okText: 'Salvar',
      centered: true,
      content:
        'Tem certeza que deseja salvar as alterações? Todas as mudanças feitas serão espelhadas no app.',
      onOk() {
        dispatch(orderAllAisles({ data, category }));
        setEditDrag(false);
      },
      onCancel() {}
    });
  };

  const OrderOnApp = () => {
    if (aisles.length > 1) {
      setEditDrag(true);
    } else {
      setEditDrag(false);
    }
  };

  const OrderOnAppCancel = useCallback(() => {
    if (aisles !== data) {
      setData(aisles);
    }
    setEditDrag(false);
  }, [aisles, data]);

  useEffect(() => {
    if (CSVFile.length === 1) {
      setUploadButtonDisabled(true);
    } else {
      setUploadButtonDisabled(false);
    }
  }, [CSVFile.length]);

  useEffect(() => {
    if (category === 'all') {
      setDisabledButtonsOrder(false);
    }
  }, [setDisabledButtonsOrder, category]);

  useEffect(() => {
    setData(aisles);
    // eslint-disable-next-line
  }, [dispatch, aisles]);

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getAislesHandler({ query: params });
    dispatch(getCategories(generalQuery));
    dispatch(getDepartments(generalQuery));
    // eslint-disable-next-line
  }, [getAislesHandler, history]);

  return (
    <>
      <ModalCSV
        titleModal="CONFIGURAR PRODUTOS POR PLANILHA"
        isVisible={showModalCSV}
        setIsVisibleModalCSV={() => setShowModalCSV(false)}
        components={contentModalCSV}
        buttonDisabled={CSVFileFail}
        CSVFileHandle={sendCSVOrderByHandle}
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <h1 style={{ marginBottom: 5 }}>Corredores</h1>
          <span>Subdivisões de cada categoria</span>
        </div>
        {catalogPermission && (
          <div>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={() => history.push('/corredores/novo')}
            >
              Novo corredor
            </Button>
          </div>
        )}
      </Row>
      <section
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px'
        }}
      >
        <Card
          className="no-shadow"
          style={{ paddingTop: 16, minHeight: '48px' }}
        >
          <Searchbar
            placeholder={
              categoryById !== undefined
                ? `Buscar em ${categoryById.attributes.name.toLowerCase()} para ${categoryById.attributes.department_name.toLowerCase()}`
                : 'Buscar por nome corredor / nome da categoria'
            }
            populateResult={getAislesHandler}
            query={query}
            value={query.search}
          />
          <Row
            type="flex"
            align="middle"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col style={{ width: '20%', marginRight: 20 }}>
              <Select
                size="large"
                placeholder="Todas as categorias"
                onChange={changeCategory}
                style={{ width: '100%' }}
                defaultValue={category}
              >
                <Option value="all">Todas as categorias</Option>
                {departments.map(item => {
                  const categoriesArr = categories.filter(
                    obj => obj.attributes.department_id === Number(item.id)
                  );
                  return (
                    <OptGroup
                      label={item.attributes.name}
                      key={`department-${item.id}`}
                    >
                      {categoriesArr?.map(obj => {
                        return (
                          <Option
                            key={obj.attributes.id}
                            value={obj.attributes.id}
                          >
                            {obj.attributes.name} para {item.attributes.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Col>
            <Col style={{ width: '18%', marginRight: 20 }}>
              <Select
                size="large"
                defaultValue={categoryActive}
                onChange={changeActive}
                style={{ width: '100%' }}
              >
                <Option value="all">Todos</Option>
                <Option value="true">Ativos</Option>
                <Option value="false">Inativos</Option>
              </Select>
            </Col>
            {disabledButtonsOrder && catalogPermission && (
              <>
                {editDrag ? (
                  <>
                    <Col style={{ marginRight: 20 }}>
                      <Button onClick={() => saveAllConfirm()}>
                        Salvar no app
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="ant-btn-secondary-copy"
                        onClick={OrderOnAppCancel}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col style={{ marginRight: 20 }}>
                      {aisles.length < 2 && aisles.length !== 0 ? (
                        <Tooltip
                          placement="bottom"
                          title="Deve haver mais de um corredor para fazer a ordenação"
                          overlayClassName="tooltip-buttons"
                        >
                          <Button
                            disabled={aisles.length < 2 && aisles.length !== 0}
                            onClick={OrderOnApp}
                          >
                            Ordenar no app
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button onClick={OrderOnApp}>Ordenar no app</Button>
                      )}
                    </Col>
                    <Col>
                      {aisles.length < 2 && aisles.length !== 0 ? (
                        <Tooltip
                          placement="bottom"
                          title="Deve haver mais de um corredor para fazer a ordenação"
                          overlayClassName="tooltip-buttons"
                        >
                          <Button
                            className="ant-btn-secondary-copy"
                            disabled={aisles.length < 2 && aisles.length !== 0}
                            onClick={() => setShowModalCSV(true)}
                          >
                            Ordenar por planilha
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          className="ant-btn-secondary-copy"
                          onClick={() => setShowModalCSV(true)}
                        >
                          Ordenar por planilha
                        </Button>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
            {!disabledButtonsOrder && catalogPermission && (
              <>
                <Col style={{ marginRight: 20 }}>
                  <Tooltip
                    placement="bottom"
                    title="Selecione uma categoria para ordenar os corredores"
                    overlayClassName="tooltip-buttons"
                  >
                    <Button
                      className="ant-btn-secondary-copy"
                      disabled={!disabledButtonsOrder}
                    >
                      Ordenar no app
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip
                    placement="bottom"
                    title="Selecione uma categoria para ordenar os corredores"
                    overlayClassName="tooltip-buttons"
                  >
                    <Button
                      className="ant-btn-secondary-copy"
                      onClick={() => setShowModalCSV(true)}
                      disabled={!disabledButtonsOrder}
                    >
                      Ordenar por planilha
                    </Button>
                  </Tooltip>
                </Col>
              </>
            )}
          </Row>

          <DndProvider manager={manager.current.dragDropManager}>
            <Tables
              data={data}
              className="even-gray"
              columnsDefaults={columns}
              count={count}
              visiblePaginationUp
              visiblePaginationDown
              rowKey={record => record.id}
              contentToShow={typeTable}
              history={history}
              query={query}
              isLoading={isLoading}
              // rowSelection={rowSelectionModal}
              setSortState={setSortHandler}
              populateTables={getAislesHandler}
              components={editDrag ? components : {}}
              onRow={
                editDrag
                  ? (record, index) => ({
                      index,
                      moveRow
                    })
                  : null
              }
            />
          </DndProvider>
        </Card>
      </section>
    </>
  );
}

export default ListAisles;
