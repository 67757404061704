import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const TablePriceStock = ({ data, type }) => {
  const [columns, setColumns] = useState();

  const priceColumns = [
    {
      title: 'Preço',
      key: 'price',
      width: 190,
      render: record => `De ${record.listprice} | Por ${record.saleprice}`
    },
    {
      title: 'Hub',
      dataIndex: 'hubname',
      key: 'hubname',
      width: 190
    }
  ];

  const stockColumns = [
    {
      title: 'Hub',
      dataIndex: 'hubname',
      key: 'hubname'
    },
    {
      title: 'Disponíveis',
      dataIndex: 'availablequantity',
      key: 'availablequantity',
      align: 'center'
    },
    {
      title: 'Reservados',
      dataIndex: 'reservedquantity',
      key: 'reservedquantity',
      align: 'center'
    },
    {
      title: 'Estoque Total',
      dataIndex: 'stockquantity',
      key: 'stockquantity',
      align: 'center'
    }
  ];

  useEffect(() => {
    if (type === 'price') setColumns(priceColumns);
    if (type === 'stock') setColumns(stockColumns);
    // eslint-disable-next-line
  }, [type]);

  return (
    <Table
      className="hideTable"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
    />
  );
};

TablePriceStock.defaultProps = {
  data: [],
  type: ''
};

TablePriceStock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string
};

export default TablePriceStock;
