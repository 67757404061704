import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import style from './style.module.scss';

const InvoicePill = ({
  orderId,
  orderNumber,
  alert,
  cancelationRequest,
  returnRequest,
  resolved,
  cardsRef,
  slideGoTo
}) => {
  const linesMap = {
    waiting: 0,
    started: 1,
    picked: 2,
    packed: 3,
    billed: 4,
    separated: 5,
    dispatched: 5,
    delivering: 5,
    delivered: 5
  };
  const [iconType, setIconType] = useState('');

  useEffect(() => {
    setIconType('');
    if (resolved) setIconType('check-circle');
    if (alert) setIconType('warning');
    if (returnRequest) setIconType('import');
    if (cancelationRequest) setIconType('close-square');
  }, [alert, cancelationRequest, returnRequest, resolved]);

  const focusCard = elem => {
    elem.classList.toggle('focused-card');
    setTimeout(() => {
      elem.classList.toggle('focused-card');
    }, 1500);
  };

  const scrollToElement = id => {
    const elem = cardsRef.current.find(item => item.id === id);
    slideGoTo(linesMap[elem.attributes['data-line'].value]);
    setTimeout(() => {
      window.scroll({
        top: elem.offsetTop,
        behavior: 'smooth'
      });
      focusCard(elem);
    }, 500);
  };

  return (
    <button
      type="submit"
      className={`${style.invoicePill}
        ${resolved ? style.resolved : ''}
        ${alert ? style.alert : ''}
        ${cancelationRequest ? style.cancel : ''}
        ${returnRequest ? style.return : ''}`}
      onClick={() => scrollToElement(orderId)}
    >
      {iconType && (
        <Icon
          className={style.invoicePill__icon}
          type={iconType}
          theme={iconType === 'import' ? '' : 'filled'}
        />
      )}
      <span className={style.invoicePill__text}>{orderNumber}</span>
    </button>
  );
};

InvoicePill.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  alert: PropTypes.bool.isRequired,
  cancelationRequest: PropTypes.bool.isRequired,
  returnRequest: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  cardsRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  slideGoTo: PropTypes.func.isRequired
};

export default InvoicePill;
