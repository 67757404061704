import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Input,
  Button,
  Modal,
  Card,
  Form,
  List,
  Icon,
  Table,
  Menu,
  Dropdown,
  Skeleton
} from 'antd';

import {
  getPolicy,
  getPolicyPriceTables,
  getCreateNewPolicy,
  getUpdatePolicy
} from '@/src/store/modules/policies/slice';

import {
  getPriceTables,
  getPriceTableProducts
} from '@/src/store/modules/price-tables/slice';

import { getHubs } from '@/src/store/modules/hubs/slice';

import Audit from '@/src/components/Audit/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';
import PriceTableModal from '@/src/pages/Policies/containers/PriceTableModal/index';
import PriceTableEditModal from '@/src/pages/Policies/containers/PriceTableEditModal/index';
import PriceTableExportModal from '@/src/pages/Policies/containers/PriceTableExportModal/index';
import { ReactComponent as EditIcon } from '@/src/assets/images/edit.svg';
import style from '../../Policy.module.scss';

export const PolicyPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const isNewPolicy = id === 'nova';

  const [isTableTabVisible, setIsTableTabVisible] = useState(false);

  const [policyHubsData, setPolicyHubsData] = useState([]);
  const [policyTablesData, setPolicyTablesData] = useState([]);

  const [showPriceTableModal, setShowPriceTableModal] = useState(false);
  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [showEditTableModal, setShowEditTableModal] = useState({});
  const [showExportModal, setShowExportModal] = useState(false);

  const [tableData, setTableData] = useState({});

  const [tablesToAdd, setTablesToAdd] = useState([]);
  const [hubsToAdd, setHubsToAdd] = useState([]);

  const [policyName, setPolicyName] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [showAudit, setShowAudit] = useState(false);

  const { policy, policyPriceTables, isLoading } = useSelector(
    state => state.policies
  );
  const {
    priceTables,
    priceTablesCount,
    priceTablesQuery,
    priceTablesLoading
  } = useSelector(state => state.priceTables);
  const { hubs, isLoading: hubsLoading } = useSelector(state => state.hubs);

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const changeTabs = e => {
    if (e === 'tables') {
      setIsTableTabVisible(true);
    } else if (e === 'hubs') {
      setIsTableTabVisible(false);
    }
  };

  const getPriceTablesHandler = useCallback(
    urlQuery => {
      dispatch(getPriceTables({ ...urlQuery }));
    },
    [dispatch]
  );

  const getPolicyTablesHandler = useCallback(
    urlQuery => {
      dispatch(getPolicyPriceTables({ ...urlQuery, id: Number(id) }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(
      getHubs({
        query: {
          page: 1,
          perPage: 500
        }
      })
    );
    getPriceTablesHandler({
      query: {
        page: 1,
        perPage: 20
      }
    });
    if (isNewPolicy) {
      setPolicyName('');
      setTablesToAdd([]);
      setHubsToAdd([]);
    } else {
      dispatch(getPolicy(id));
      getPolicyTablesHandler({
        query: {
          page: 1,
          perPage: 100
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (policy?.attributes && !isNewPolicy) {
      const { name, hub, price_tables } = policy.attributes;
      if (name) setPolicyName(name);
      if (hub) {
        const policyHubsIds = hub.map(item => item.id.toString());
        setHubsToAdd(policyHubsIds);
      }
      if (price_tables) {
        const priceTablesIds = price_tables.map(item => item.id.toString());
        setTablesToAdd(priceTablesIds);
      }
    }
    // eslint-disable-next-line
  }, [policy]);

  useEffect(() => {
    if (hubs.length) {
      const policyHubs = hubs.map(item => {
        return {
          ...item,
          isAssociated: !!hubsToAdd.includes(item.id.toString())
        };
      });
      setPolicyHubsData(policyHubs);
    }
    // eslint-disable-next-line
  }, [hubs, hubsToAdd]);

  useEffect(() => {
    if (policyPriceTables.length && !showAddTableModal) {
      const priceTablesArr = tablesToAdd.map(item => {
        const newItem = policyPriceTables.find(
          table => table.id.toString() === item
        );
        if (newItem) return newItem;
        const currentItem = policyTablesData.find(table => table.id === item);
        return currentItem;
      });
      setPolicyTablesData(priceTablesArr);
    }
    // eslint-disable-next-line
  }, [policyPriceTables, tablesToAdd]);

  // Open table products modal
  const handleTableSelect = table => {
    setShowPriceTableModal(true);
    dispatch(
      getPriceTableProducts({
        query: { price_table_id: table.id, page: 1, perPage: 10 }
      })
    );
    setTableData(table.attributes);
  };

  const policyTablesColumns = [
    {
      title: 'ID',
      align: 'left',
      dataIndex: 'id'
    },
    {
      title: 'Nome',
      align: 'left',
      dataIndex: 'attributes.name',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {record.attributes.active_app && (
              <Icon
                type="check-circle"
                key="check-circle"
                className="ant-icon-check-circle"
                style={{ position: 'unset', marginRight: 4 }}
              />
            )}
            <button
              type="button"
              key={record.id}
              onClick={() => handleTableSelect(record)}
              style={{
                border: 'none',
                textDecoration: 'underline',
                cursor: 'pointer',
                background: 'transparent',
                color: 'rgba(0, 0, 0, 0.65)',
                outline: 'none',
                textAlign: 'left'
              }}
            >
              {text}
            </button>
          </div>
        );
      }
    },
    {
      title: 'Vigência',
      align: 'left',
      key: 'validity',
      render: (text, record) => {
        const { start_date, end_date } = record.attributes;
        const startDate = start_date
          ? moment(start_date).format('DD/MM/YYYY (HH:mm)')
          : '';
        const endDate = end_date
          ? moment(end_date).format('DD/MM/YYYY (HH:mm)')
          : 'Tabela Padrão';
        const validity = startDate ? `${startDate} - ${endDate}` : endDate;
        return validity;
      }
    },
    {
      title: 'Data de Associação',
      align: 'left',
      dataIndex: 'attributes.associated_since',
      render: data => (data ? moment(data).format('DD/MM/YYYY') : '')
    },
    {
      title: 'Editar',
      key: 'action-edit',
      width: '60px',
      render: (text, record) => {
        return (
          <div className={style.tableEdit__button}>
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                setShowEditTableModal(record.attributes);
              }}
              aria-label="Editar tabela de preço"
            >
              <EditIcon />
            </button>
          </div>
        );
      }
    },
    {
      key: 'action-disassociate',
      width: '124px',
      align: 'center',
      render: table => (
        <Button
          className="ant-btn-unassociate ant-btn-link"
          onClick={() => {
            setPolicyTablesData(prev =>
              prev.filter(item => item.id !== table.id)
            );
            setTablesToAdd(prev => prev.filter(item => item !== table.id));
          }}
        >
          Desassociar
        </Button>
      )
    }
  ];

  const allTablesColumns = [
    {
      title: 'Nome',
      align: 'left',
      dataIndex: 'attributes.name'
    },
    {
      title: 'Vigência',
      align: 'left',
      key: 'validity',
      render: (text, record) => {
        const { start_date, end_date } = record.attributes;
        const startDate = start_date
          ? moment(start_date).format('DD/MM/YYYY (HH:mm)')
          : '';
        const endDate = end_date
          ? moment(end_date).format('DD/MM/YYYY (HH:mm)')
          : 'Tabela Padrão';
        const validity = startDate ? `${startDate} - ${endDate}` : endDate;
        return validity;
      }
    },
    {
      title: 'Políticas Associadas',
      align: 'left',
      dataIndex: 'attributes',
      width: '40%',
      render: table => {
        const rules =
          table.pricing_rules?.map(item => item.pricing_rule.name) || '';
        return rules.join(', ');
      }
    }
  ];

  const handleHubsUpdate = ({ hub, select }) => {
    if (select && !hubsToAdd.includes(hub.id)) {
      setHubsToAdd(prev => [...prev, hub.id]);
    } else {
      setHubsToAdd(prev => prev.filter(item => item !== hub.id));
    }
    setPolicyHubsData(prev => {
      const hubsData = prev.map(item => {
        if (item.id === hub.id) {
          return {
            ...item,
            isAssociated: select
          };
        }
        return item;
      });
      return hubsData;
    });
  };

  const handleSubmit = () => {
    const payload = {
      name: policyName,
      hub_ids: hubsToAdd.map(item => Number(item)),
      price_table_ids: tablesToAdd.map(item => Number(item))
    };
    if (isNewPolicy) {
      dispatch(getCreateNewPolicy(payload));
    } else {
      payload.id = id;
      dispatch(getUpdatePolicy(payload));
    }
  };

  const hubsListActions = hub => {
    const pricingRules =
      hub.attributes?.pricing_rules?.filter(item => {
        if (item.id === Number(id)) return hub.isAssociated;
        return true;
      }) || [];
    if (
      hub.isAssociated &&
      pricingRules.filter(item => item.id === Number(id)).length === 0
    ) {
      pricingRules.push({
        id: policy.id,
        name: policy.attributes.name
      });
    }
    const rulesNames =
      pricingRules?.length > 0
        ? pricingRules.map((item, index) => (
            <Link
              key={item.id}
              to={`/politicas/${item.id}`}
              style={{ color: '#333' }}
            >
              {item.name}
              {index === pricingRules.length - 1 ? '' : ', '}
            </Link>
          ))
        : '';

    return [
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          fontWeight: 'bold',
          fontSize: 13,
          cursor: 'default'
        }}
      >
        {pricingRules?.length > 0 && (
          <>
            <Icon
              type="check"
              key="check"
              style={{ fontSize: 13, marginRight: 6 }}
            />
            <span>Associado a </span>
            <span style={{ textDecoration: 'underline', marginLeft: 4 }}>
              {rulesNames}
            </span>
          </>
        )}
        {hub.isAssociated ? (
          <Button
            className="ant-btn-unassociate ant-btn-link"
            onClick={() => handleHubsUpdate({ hub, select: false })}
          >
            Desassociar
          </Button>
        ) : (
          <Button
            disabled={
              // policyHubsData.filter(item => item.isAssociated).length >= 1 ||
              pricingRules.length > 1
            }
            className="ant-btn-associate"
            onClick={() => handleHubsUpdate({ hub, select: true })}
          >
            Associar
          </Button>
        )}
      </div>
    ];
  };

  const checkFieldsUpdate = () => {
    if (!policy?.attributes) return true;
    const { name, hub, price_tables } = policy.attributes;
    if (
      policyName !== name ||
      hub.filter(item => !hubsToAdd.includes(item.id.toString())).length ||
      hubsToAdd.filter(
        item => !hub.find(hubItem => hubItem.id.toString() === item)
      ).length ||
      price_tables.filter(item => !tablesToAdd.includes(item.id.toString()))
        .length ||
      tablesToAdd.filter(
        item => !price_tables.find(table => table.id.toString() === item)
      ).length
    ) {
      return false;
    }
    return true;
  };

  // check for required fields
  useEffect(() => {
    if (
      (policyName && hubsToAdd.length && tablesToAdd.length) ||
      (policyName && !hubsToAdd.length && !tablesToAdd.length) ||
      (policyName && !hubsToAdd.length && tablesToAdd.length)
    ) {
      const disabled = isNewPolicy ? false : checkFieldsUpdate();
      setSubmitDisabled(disabled);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [policyName, hubsToAdd, tablesToAdd]);

  return (
    <>
      <PriceTableEditModal
        showModal={showEditTableModal}
        setShowModal={setShowEditTableModal}
      />
      <PriceTableExportModal
        showModal={showExportModal}
        setShowModal={setShowExportModal}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>{isNewPolicy ? 'Nova política' : policy?.attributes?.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/politicas" style={{ textDecoration: 'underline' }}>
            Políticas Comerciais
          </Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {isNewPolicy ? 'Nova política' : policy?.attributes?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Audit
        id={id}
        title="POLÍTICA COMERCIAL"
        type="PricingRule"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <div
        style={{
          borderRadius: '16px',
          backgroundColor: '#ebebeb',
          minHeight: '48px',
          padding: '8px',
          marginBottom: '90px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DA POLÍTICA"
          style={{ marginBottom: '8px', padding: '10px 0 0' }}
          extra={!isNewPolicy && actionsExtra}
        >
          <Row span={11}>
            <Form style={{ marginTop: 10 }}>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col span={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>Nome</p>
                  </div>
                  <Skeleton
                    active
                    loading={isLoading}
                    paragraph={false}
                    className={style.inputLoading}
                  >
                    <Form.Item style={{ marginBottom: 10 }}>
                      <Input
                        value={policyName}
                        onChange={e => setPolicyName(e.target.value)}
                        placeholder="Escreva o nome da política"
                      />
                    </Form.Item>
                  </Skeleton>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card>

        <Card className="no-shadow" style={{ padding: '10px 0 0' }}>
          <Tabs
            type="card"
            onChange={changeTabs}
            style={{
              marginBottom: 20,
              lineHeight: '36px',
              borderBottom: '1px solid #ccc'
            }}
            defaultActiveKey="hubs"
          >
            <Tabs.TabPane tab="Hubs" key="hubs" />
            <Tabs.TabPane tab="Tabelas" key="tables" />
          </Tabs>

          {!isTableTabVisible ? (
            <>
              <Row>
                <p style={{ lineHeight: '36px' }}>
                  Caso haja um hub associado à política comercial, é obrigatório
                  adicionar uma tabela.
                </p>
              </Row>
              <List
                className="even-gray ant-list-flex"
                loading={hubsLoading}
                dataSource={policyHubsData}
                bordered
                size="large"
                renderItem={item => {
                  const currentPolicy = item.attributes?.pricing_rules?.find(
                    rule => rule.id === Number(id)
                  );
                  const hasPolicies = item.attributes?.pricing_rules?.length;
                  return (
                    <List.Item
                      actions={hubsListActions(item)}
                      className={
                        currentPolicy || !hasPolicies
                          ? ''
                          : 'ant-list-item__green-bg'
                      }
                      style={{ border: 'none', minHeight: 65 }}
                    >
                      {item?.isAssociated ? (
                        <>
                          <Icon
                            type="check-circle"
                            key="check-circle"
                            className="ant-icon-check-circle"
                          />
                          <span style={{ paddingLeft: 40, color: '#333' }}>
                            {item?.attributes?.name}
                          </span>
                        </>
                      ) : (
                        <span style={{ color: '#333' }}>
                          {item?.attributes?.name}
                        </span>
                      )}
                    </List.Item>
                  );
                }}
              />
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column'
              }}
            >
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <span>
                    Caso haja um hub associado à política comercial, é
                    obrigatório adicionar uma tabela.
                  </span>
                </Col>
                <Col>
                  <Button
                    style={{ height: 36 }}
                    onClick={() => setShowAddTableModal(true)}
                    className="ant-btn-primary"
                  >
                    Adicionar Tabela
                  </Button>
                </Col>
              </Row>

              {policyTablesData.length > 0 ? (
                <Table
                  columns={policyTablesColumns}
                  dataSource={policyTablesData}
                  className="even-gray"
                  rowKey={record => record.id}
                  history={history}
                  // query={query}
                  pagination={false}
                  isLoading={isLoading}
                  style={{ marginTop: '30px' }}
                />
              ) : (
                <div className={style.emptyTable}>
                  <p>Nenhuma tabela selecionada.</p>
                </div>
              )}
            </div>
          )}
        </Card>

        <Modal
          className="ant-modal-recom-addProducts"
          visible={showAddTableModal}
          title="ADICIONAR TABELAS"
          centered
          width={900}
          bodyStyle={{ height: 600 }}
          onCancel={() => setShowAddTableModal(false)}
          footer={
            <>
              <Button
                style={{ height: 36 }}
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  const priceTablesArr = tablesToAdd.map(item => {
                    const currentItem = policyTablesData.find(
                      table => table.id === item
                    );
                    if (currentItem) return currentItem;
                    const newItem = priceTables.find(
                      table => table.id.toString() === item
                    );
                    return newItem;
                  });
                  setPolicyTablesData(priceTablesArr);
                  setShowAddTableModal(false);
                }}
              >
                Adicionar
              </Button>
            </>
          }
        >
          <Row type="flex" align="middle">
            <Col style={{ flexGrow: 1 }}>
              <Searchbar
                placeholder="Buscar por nome"
                populateResult={getPriceTablesHandler}
                query={priceTablesQuery}
                value={priceTablesQuery.search}
              />
            </Col>
            <Col>
              <Button
                onClick={() => setShowExportModal(true)}
                className="ant-btn-secondary"
                style={{ height: 36, marginLeft: 16 }}
              >
                Exportar CSV
              </Button>
            </Col>
          </Row>
          <Tables
            data={priceTables}
            className="even-gray"
            columnsDefaults={allTablesColumns}
            count={priceTablesCount}
            query={priceTablesQuery}
            visiblePaginationDown
            rowKey={record => record.id}
            history={history}
            isLoading={priceTablesLoading}
            populateTables={getPriceTablesHandler}
            scroll={{ y: 400 }}
            width={900}
            rowSelection={{
              selectedRowKeys: tablesToAdd,
              onChange: selected => setTablesToAdd(selected)
            }}
          />
        </Modal>

        <PriceTableModal
          showModal={showPriceTableModal}
          setShowModal={setShowPriceTableModal}
          tableData={tableData}
          setTableData={setTableData}
        />
      </div>

      <div className={style.saveBar}>
        <div className={style.saveBar__container}>
          <Button
            disabled={submitDisabled}
            style={{ height: 36 }}
            className="ant-btn ant-btn-primary"
            onClick={handleSubmit}
          >
            {isNewPolicy ? 'Criar política' : 'Salvar'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PolicyPage;
