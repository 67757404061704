export default (currency, formatZero = true) => {
  if (
    (!currency && Number(currency) !== 0) ||
    (!formatZero && Number(currency) === 0)
  )
    return '';
  let currencyString = `${currency}`;
  currencyString = currencyString.replace(/([0-9]{2})$/g, '.$1');
  if (currencyString.length === 1) currencyString = `0.0${currencyString}`;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(currencyString);
};
