import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getZipcodes = payload => {
  const url = payload
    ? dispatchQueryBuilder(payload, '/zipcodes')
    : '/zipcodes';
  return apiDispatch.get(url);
};

export const deleteZipcode = payload =>
  apiDispatch.delete(`/zipcodes/${payload}`);
