import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// import bugsnag from '@/src/services/bugsnag';

import {
  setManufacturers,
  setManufacturer,
  setIsLoading,
  getManufacturers,
  getManufacturer,
  createManufacturer,
  updateManufacturer,
  orderByDragNDrop
} from '@/src/store/modules/manufacturers/slice';

import {
  getManufacturers as getManufacturersRequest,
  getManufacturer as getManufacturerRequest,
  createManufacturer as createManufacturerRequest,
  updateManufacturer as updateManufacturerRequest,
  orderByDragNDrop as orderByDragNDropRequest
} from '@/src/api/manufacturers';

import {
  getManufacturersQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getManufacturersSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getManufacturersRequest, payload);
    const count = response.data?.meta?.count || 0;
    const routerState = yield select(getRouterState);
    yield put(
      setManufacturers({
        data: response.data.data,
        query: routerState.location.pathname.includes('/fabricantes')
          ? payload.query
          : null,
        count
      })
    );
    const queryUrl = yield select(getManufacturersQueryUrlState);
    if (
      routerState.location.search !== queryUrl &&
      routerState.location.pathname.includes('/fabricantes')
    ) {
      yield put(push(queryUrl));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getManufacturerSaga({ payload }) {
  try {
    const response = yield call(getManufacturerRequest, payload);
    yield put(setManufacturer(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do fabricante');
  }
}

function* createManufacturerSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(createManufacturerRequest, payload);
    yield put(setIsLoading(false));
    yield put(push('/fabricantes'));
    successAlert('Novo fabricante criado');
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível criar o fabricante');
  }
}

function* updateManufacturerSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(updateManufacturerRequest, payload);
    yield put(setIsLoading(false));
    yield put(push('/fabricantes'));
    successAlert('Os dados do fabricante foram atualizados');
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

function* orderByDragNDropSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(orderByDragNDropRequest, payload);
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    // bugsnag.notify(err);
    errorAlert(err, 'Não foi possível ordernar os fabricantes');
  }
}

function* manufacturersSaga() {
  yield takeEvery(getManufacturers, getManufacturersSaga);
  yield takeEvery(getManufacturer, getManufacturerSaga);
  yield takeEvery(createManufacturer, createManufacturerSaga);
  yield takeEvery(updateManufacturer, updateManufacturerSaga);
  yield takeEvery(orderByDragNDrop, orderByDragNDropSaga);
}

export default manufacturersSaga;
