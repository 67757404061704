import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import ListItems from '@/src/components/Sizes/ListItems/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';

import { getBreeds } from '@/src/store/modules/breeds/slice';
import { getAccessories } from '@/src/store/modules/accessories/slice';

import buildJsonData from '@/src/utils/buildJsonData';

const { Option } = Select;

function Sizes() {
  const dispatch = useDispatch();
  const [typeSearch, setTypeSearch] = useState('breeds');
  const [accessories, setAccessories] = useState([]);
  const [breeds, setBreeds] = useState([]);

  const { breeds: breedsData } = useSelector(state => state.breeds);
  const { accessories: accessoriesData } = useSelector(
    state => state.accessories
  );
  const breedsQuery = useSelector(state => state.breeds.query);
  const accessoriesQuery = useSelector(state => state.accessories.query);

  const query = {
    // filters: {},
    page: 1,
    perPage: 1000,
    search: ''
    // sort: '',
    // url: ''
  };

  useEffect(() => {
    dispatch(getBreeds({ query }));
    dispatch(getAccessories({ query }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const arr = buildJsonData(accessoriesData, 'accessory_type');
    setAccessories(arr);
  }, [accessoriesData]);

  useEffect(() => {
    const arr = buildJsonData(breedsData, 'breed');
    setBreeds(arr);
  }, [breedsData]);

  const getDataHandler = useCallback(
    urlQuery => {
      if (typeSearch === 'breeds') {
        dispatch(
          getBreeds({
            query: {
              ...urlQuery.query
            }
          })
        );
      } else {
        dispatch(
          getAccessories({
            query: {
              ...urlQuery.query
            }
          })
        );
      }
    },
    [dispatch, typeSearch]
  );

  return (
    <>
      <Row>
        <Col span={20}>
          <h1 className="page-title mb-20">Tamanhos</h1>
        </Col>
        <Col span={24}>
          <div
            className="sizes"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Col span={18}>
              <Input.Group compact>
                <Select
                  size="large"
                  placeholder="Escolha o tipo"
                  // className="select-search"
                  value={typeSearch}
                  onChange={value => setTypeSearch(value)}
                >
                  <Option key={1} value="breeds">
                    Raças
                  </Option>
                  <Option key={2} value="accessories">
                    Produtos
                  </Option>
                </Select>
                <Searchbar
                  placeholder="Buscar por raça ou produto"
                  populateResult={getDataHandler}
                  query={
                    typeSearch === 'breeds' ? breedsQuery : accessoriesQuery
                  }
                  value={
                    typeSearch === 'breeds'
                      ? breedsQuery.search
                      : accessoriesQuery.search
                  }
                />
              </Input.Group>
            </Col>
            {/* <Button
              // onClick={exportCSVHandler}
              size="large"
              className="ant-btn-secondary"
            >
              exportar csv
            </Button> */}
          </div>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '0px solid #ebebeb',
          background: '#ebebeb',
          padding: '16px 10px',
          minHeight: '75vh',
          maxHeight: '75vh',
          marginTop: '30px'
        }}
      >
        <ListItems data={breeds} title="RAÇAS" kind="racas" />
        <ListItems data={accessories} title="PRODUTOS" kind="acessorios" />
      </div>
    </>
  );
}

export default Sizes;
