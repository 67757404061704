import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Select,
  Input,
  Icon,
  Button,
  // Divider,
  Spin
} from 'antd';
import { debounce } from 'throttle-debounce';

import { getOrdersData } from '@/src/store/modules/expedition/slice';
import {
  getDeliveryCompanies,
  getDeliveryKinds,
  createShipping
} from '@/src/store/modules/shipping/slice';
import { getDeliverers } from '@/src/store/modules/deliverers/slice';
import { getHubsSelect } from '@/src/store/modules/hubs-data/slice';

import Tables from '@/src/components/Tables';
// import ShippingModal from '@/src/components/Shipping/Modal';

import { ordersQuery } from '@/src/globals/shippingQueries';
import { buildQueryByHub } from '@/src/utils/dispatchUtils';

import styles from '../Shipping.module.scss';

const { Option } = Select;

const NewShipping = ({ form }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const typeTable = 'newAndEditShipping';
  // const [visible, setVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showOrders, setShowOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [znDelivery, setZnDelivery] = useState(false);
  const [hubId, setHubId] = useState('');

  const { orders: ordersData, pageLoading: ordersLoading } = useSelector(
    state => state.expedition
  );
  const { deliveryCompaniesList, deliveryKindsList } = useSelector(
    state => state.shipping
  );
  const hubs = useSelector(state => state.hubsData.select);
  const { deliverers } = useSelector(state => state.deliverers);
  const { account } = useSelector(state => state.account);

  const delayedOrdersQuery = useRef(
    debounce(300, value => {
      // console.log('search', value);
      const ordersQueryByHub = buildQueryByHub(ordersQuery, account.hubs);
      if (value) {
        ordersQueryByHub.query.filters = [
          ...ordersQueryByHub.query.filters,
          {
            key: 'search',
            type: 'match',
            value
          }
        ];
      }
      dispatch(getOrdersData(ordersQueryByHub));
    })
  ).current;

  useEffect(() => {
    if (!account.hubs) return;
    dispatch(getDeliveryCompanies());
    dispatch(getDeliveryKinds());
    if (hubs.length === 0) {
      dispatch(
        getHubsSelect({
          query: {
            fields: [{ key: 'hubs', value: 'id,internal_id,name' }],
            sort: 'name',
            perPage: 500
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    const notDelivered = ordersData.filter(order => !order.hasDelivery);
    setOrders(notDelivered);
  }, [ordersData]);

  useEffect(() => {
    if (hubId) {
      const ordersQueryByHub = buildQueryByHub(ordersQuery, hubId);
      dispatch(getOrdersData(ordersQueryByHub));
    }
    // eslint-disable-next-line
  }, [hubId]);

  const onSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        const payload = {
          data: {
            type: 'embarks',
            relationships: {
              delivery_company: {
                data: {
                  id: Number(values.company),
                  method: 'update',
                  type: 'delivery_companies'
                }
              },
              delivery_kind: {
                data: {
                  id: Number(values.kind),
                  method: 'update',
                  type: 'delivery_kinds'
                }
              },
              deliveries: {
                data: values.orders.map(item => ({
                  'temp-id': `order-${item}`,
                  method: 'create',
                  type: 'deliveries'
                }))
              }
            }
          },
          included: values.orders.map(item => ({
            'temp-id': `order-${item}`,
            type: 'deliveries',
            relationships: {
              order: {
                data: {
                  id: Number(item),
                  method: 'update',
                  type: 'orders'
                }
              }
            }
          }))
        };
        if (values.deliverer) {
          payload.data.relationships.deliverer = {
            data: {
              id: Number(values.deliverer),
              method: 'update',
              type: 'deliverer'
            }
          };
        }
        if (values.external_id) {
          payload.data.attributes = {
            external_id: values.external_id
          };
        }
        dispatch(createShipping(payload));
        // dispatch(setCompanyId(''));
        setIsDisabled(false);
      }
    });
  };

  // const handleCancel = () => setVisible(false);

  // const showModal = () => setVisible(true);

  const filledFields = form.getFieldsValue([
    'orders',
    'company',
    'kind',
    'deliverer',
    'external_id'
  ]);

  useEffect(() => {
    const externalIdError = form.getFieldsError().external_id?.length > 0;
    const allFilled = znDelivery
      ? !!(filledFields.company && filledFields.kind && filledFields.deliverer)
      : !!(
          filledFields.company &&
          filledFields.kind &&
          filledFields.external_id &&
          !externalIdError
        );
    setShowOrders(allFilled);
    setIsDisabled(!(allFilled && filledFields.orders?.length > 0));
    // eslint-disable-next-line
  }, [
    filledFields.company,
    filledFields.kind,
    filledFields.deliverer,
    filledFields.orders,
    filledFields.external_id
  ]);

  const handleTableData = values => {
    // console.log('change', values);
    const filteredOrders = values?.map(id => {
      let order = orders.find(item => item.id === id);
      if (!order) order = tableData.find(item => item.id === id);
      return order;
    });
    setTableData(filteredOrders);
  };

  const renderDropdownItens = items => {
    return items?.map(item => {
      const { id, attributes } = item;
      return (
        <Option key={id} value={id} data-hub-id={attributes?.hub?.id}>
          {attributes.name}
        </Option>
      );
    });
  };

  const checkZnDelivery = id => {
    const company = deliveryCompaniesList.find(item => item.id === id);
    if (company) {
      const { name } = company.attributes;
      if (name.toLowerCase().replace(/[\s.]/g, '') === 'zeenow') return true;
    }
    return false;
  };

  const handleCompanySelect = value => {
    const isZnDelivery = checkZnDelivery(value);
    setZnDelivery(isZnDelivery);
    if (isZnDelivery) {
      form.setFieldsValue({
        external_id: undefined
      });
      const params = {
        sort: 'name',
        perPage: 1000
      };
      if (account.hubs && account.hubs !== 'all') {
        const userHubsIds = account.hubs.split(',');
        const userHubsArr = hubs.filter(item =>
          userHubsIds.includes(item.attributes.internal_id)
        );
        const hubsFilter = userHubsArr.map(item => item.id).join(',');
        params.filters = [{ key: 'by_hub', type: 'match', value: hubsFilter }];
      }
      dispatch(getDeliverers({ query: params }));
    } else {
      setHubId(account.hubs);
      form.setFieldsValue({
        deliverer: undefined
      });
    }
  };

  const handleDelivererSelect = (value, option) => {
    if (account.hubs === 'all') setHubId(option.props['data-hub-id']);
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Novo Embarque</h1>
        </Col>
      </Row>
      <Breadcrumb separator=">" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/embarques">Embarque</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo Embarque</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Form>
          <div className={styles.box}>
            <div className={styles.section}>
              <Row gutter={20} type="flex" justify="space-between">
                <h1 className={styles.title}>Detalhes do Embarque</h1>
                <Input.Group>
                  <Col span={5}>
                    <span className={styles.input__label}>Transportadora</span>
                    <Form.Item>
                      {form.getFieldDecorator('company', {
                        rules: [
                          {
                            required: true,
                            message: 'Selecione uma opção'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione"
                          style={{ width: '100%' }}
                          className={styles.input}
                          onChange={handleCompanySelect}
                          // dropdownRender={menu => (
                          //   <div>
                          //     {menu}
                          //     {account.kind === 'office' && (
                          //       <div>
                          //         <Divider style={{ margin: '0' }} />
                          //         <button
                          //           className={styles.resetButton}
                          //           type="button"
                          //           onMouseDown={e => e.preventDefault()}
                          //           onClick={showModal}
                          //         >
                          //           <Icon type="plus-circle" /> Nova
                          //           Transportadora
                          //         </button>
                          //       </div>
                          //     )}
                          //   </div>
                          // )}
                        >
                          {renderDropdownItens(deliveryCompaniesList)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <span className={styles.input__label}>Tipo de Frete</span>
                    <Form.Item>
                      {form.getFieldDecorator('kind', {
                        rules: [
                          {
                            required: true,
                            message: 'Selecione uma opção'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione"
                          style={{ width: '100%' }}
                          className={styles.input}
                        >
                          {renderDropdownItens(deliveryKindsList)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {form.getFieldValue('company') && (
                    <Col span={5}>
                      {znDelivery ? (
                        <>
                          <span className={styles.input__label}>
                            Entregador
                          </span>
                          <Form.Item>
                            {form.getFieldDecorator('deliverer', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Selecione uma opção'
                                }
                              ]
                            })(
                              <Select
                                placeholder="Selecione"
                                style={{ width: '100%' }}
                                className={styles.input}
                                onChange={handleDelivererSelect}
                              >
                                {renderDropdownItens(deliverers)}
                              </Select>
                            )}
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <span className={styles.input__label}>
                            ID de Embarque
                          </span>
                          <Form.Item>
                            {form.getFieldDecorator('external_id', {
                              rules: [
                                {
                                  validator: (rule, value, cb) =>
                                    !value.includes(' '),
                                  message: 'Não é permitido espaço'
                                },
                                {
                                  required: true,
                                  message: 'Campo obrigatório'
                                }
                              ]
                            })(
                              <Input
                                placeholder="Digite o ID do embarque"
                                className={styles.input}
                              />
                            )}
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  )}
                </Input.Group>
              </Row>
            </div>
            <div className={styles.section} style={{ borderTop: 'none' }}>
              <h1 className={styles.title} style={{ margin: '0' }}>
                Pedidos Prontos
              </h1>
              <span>Selecione pedidos aptos para entrega.</span>
              <Form.Item style={{ marginBottom: '0' }}>
                {form.getFieldDecorator('orders', {
                  rules: [
                    { required: true, message: 'Escolha um ou mais pedidos' }
                  ]
                })(
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    placeholder="Selecione os pedidos ou digite o número do pedido que deseja encontrar"
                    className={styles.input__select}
                    onChange={values => handleTableData(values)}
                    onSearch={value => delayedOrdersQuery(value)}
                    filterOption={false}
                    dropdownRender={menu =>
                      ordersLoading ? (
                        <div
                          style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            padding: '24px 0'
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      ) : (
                        menu
                      )
                    }
                  >
                    {orders.map(order => {
                      const { id, number, customerName } = order;
                      // const { name } = order.customer;
                      return (
                        <Option key={id} value={id}>
                          {number} - {customerName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              {showOrders ? (
                <Tables
                  data={tableData}
                  className="even-gray"
                  rowKey={record => record.id}
                  contentToShow={typeTable}
                  history={history}
                />
              ) : (
                <div className={styles.section__mask}>
                  <Icon type="info-circle" theme="filled" />
                  <h2>
                    Preencha todos os campos acima para visualizar os pedidos
                  </h2>
                </div>
              )}
            </div>
          </div>
          <footer className="footer-form-save">
            <Button
              className="ant-btn ant-btn-primary"
              style={{ left: '80%' }}
              onClick={onSubmit}
              disabled={isDisabled}
              // loading={isLoading}
            >
              Salvar novo embarque
            </Button>
          </footer>
        </Form>
        {/* <ShippingModal
          visible={visible}
          loading={isLoading}
          handleCancel={handleCancel}
          // handleCompanyId={handleCompanyId}
          deliveryKindsList={deliveryKindsList}
          renderDropdownItens={renderDropdownItens}
        /> */}
      </div>
    </>
  );
};

NewShipping.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldsError: PropTypes.func
  }).isRequired
};

const NewShippingForm = Form.create({ name: 'NewShipping' })(NewShipping);

export default NewShippingForm;
