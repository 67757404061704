import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getReferrals = payload => {
  const getUrl = queryBuilder(payload, '/referrals');
  return apiPortal.get(getUrl);
};

export const getReferralsMetadata = () => apiPortal.get('/referrals_metadata');

export const invalidateReferral = payload =>
  apiPortal.post('/referrals_invalidation', { id: payload });

export const banishReferral = payload =>
  apiPortal.post('/referrals_customer_ban', { id: payload });

export const getReferralsGraphs = () => apiPortal.get('/referrals_graph');
