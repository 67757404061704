import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Spin } from 'antd';
import PropTypes from 'prop-types';

import PackHeader from '@/src/components/Expedition/Modal/PackTable/PackHeader';
import PackItem from '@/src/components/Expedition/Modal/PackTable/PackItem';
import PackagedItem from '@/src/components/Expedition/Modal/PackTable/PackagedItem';

import {
  getVolumeKinds,
  removeFromPackedItems
} from '@/src/store/modules/expedition/slice';

import titleCase from '@/src/utils/titleCase';
import { useBreakpoint } from '@/src/context/BreakpointContext';

const PackTable = ({ setPackBtnDisabled }) => {
  const [orderItems, setOrderItems] = useState([]);
  const [volumesData, setVolumesData] = useState({});
  const [volumeKindsData, setVolumeKindsData] = useState([]);
  const [existingVol, setExistingVol] = useState(0);

  const {
    volumeKinds,
    packedItems,
    contentLoading,
    modalData: { order }
  } = useSelector(state => state.expedition);

  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVolumeKinds());
  }, [dispatch]);

  useEffect(() => {
    setVolumeKindsData(volumeKinds);
  }, [volumeKinds]);

  useEffect(() => {
    const hasVolumes = packedItems?.volumes?.filter(
      vol => vol.volumeItems.filter(item => item.productQty > 0).length > 0
    );

    setExistingVol(hasVolumes?.length);
    setVolumesData(packedItems);
  }, [packedItems]);

  const handleChange = (itemId, inputQty) => {
    setOrderItems(prevState => {
      const newState = prevState.map(item => {
        const newItem = { ...item };
        if (item.id === itemId) {
          newItem.quantity -= inputQty;
        }
        return newItem;
      });
      return newState;
    });
  };

  useEffect(() => {
    const itemsToPack = orderItems?.filter(
      item => item.quantity - item.returnedQuantity > 0
    );
    if (itemsToPack?.length > 0) {
      setPackBtnDisabled(true);
    } else {
      setPackBtnDisabled(false);
    }
  }, [orderItems, setPackBtnDisabled]);

  useEffect(() => {
    setOrderItems(order?.orderItems?.length > 0 ? order.orderItems : []);
  }, [order]);

  const handleRemoveItem = (productId, volumeId, inputQty) => {
    const payload = {
      volumes: {
        id: volumeId,
        volumeItems: {
          packedItemId: `${volumeId}_product-${productId}`,
          productId: Number(productId),
          productQty: Number(inputQty)
        }
      }
    };
    dispatch(removeFromPackedItems(payload));

    setOrderItems(prevState => {
      const newState = prevState.map(item => {
        const newItem = item;
        if (item.id === productId.toString()) {
          newItem.quantity += inputQty;
        }
        return newItem;
      });
      return newState;
    });
  };

  const packTableEl =
    orderItems?.filter(
      product => product.quantity - product.returnedQuantity > 0
    ).length > 0 ? (
      <>
        {breakpoint.lg && <PackHeader />}
        {orderItems
          ?.filter(product => product.quantity - product.returnedQuantity > 0)
          .map(product => {
            return (
              <PackItem
                productId={product.id}
                productSku={product.skuExternalId || product.skuCode}
                productEan={product.skuEan}
                productName={product.skuName}
                productTotalQty={product.quantity - product.returnedQuantity}
                productImg={product.skuImage || ''}
                key={product.id}
                handleChange={handleChange}
                volumesData={volumesData}
                volumeKinds={volumeKindsData}
                orderId={order.id}
                existingVol={existingVol}
                isGift={product.gift}
              />
            );
          })}
      </>
    ) : (
      <span style={{ margin: '12px 16px', display: 'block' }}>
        Não há produtos para empacotar.
      </span>
    );

  return (
    <div style={{ margin: '32px 0' }}>
      <div>
        <div
          style={{
            margin: '0 16px'
          }}
        >
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            Produtos para empacotar no volume
          </span>
        </div>
        <Spin spinning={contentLoading}>
          <div className="modal-table__wrapper">
            {contentLoading ? (
              <>
                {breakpoint.lg && <PackHeader />}
                <PackItem />
              </>
            ) : (
              packTableEl
            )}
          </div>
        </Spin>
      </div>
      {breakpoint.lg && (
        <Divider style={{ marginTop: '24px', height: '2px' }} />
      )}
      <div
        style={{
          margin: '24px 16px 0',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <span style={{ color: '#333', fontWeight: 'bold' }}>
          Volumes empacotados ({existingVol})
        </span>
        {existingVol === 0 && (
          <span style={{ marginTop: '12px' }}>
            Não há volumes com produtos empacotados.
          </span>
        )}
      </div>
      {volumesData?.volumes?.map(vol => {
        const hasItems = vol.volumeItems?.filter(
          product => product.productQty > 0
        );
        if (hasItems.length > 0) {
          return (
            <div key={vol.id}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '16px',
                  color: '#0A0A0A'
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {titleCase(vol.id.replace('_', ' ').replace('-', ' '))}
                </span>
              </div>
              {vol.volumeItems
                ?.filter(product => product.productQty > 0)
                .map(product => {
                  const orderItem = orderItems.find(
                    item => item.id === product.productId?.toString()
                  );
                  return (
                    <PackagedItem
                      key={`${vol.id}_${product.packedItemId}`}
                      productId={product.productId}
                      productSku={
                        orderItem?.skuExternalId || orderItem?.skuCode || ''
                      }
                      productName={orderItem?.skuName || ''}
                      productImg={orderItem?.skuImage || ''}
                      productTotalQty={product.productQty}
                      handleRemoveItem={handleRemoveItem}
                      volume={vol.id}
                      isGift={orderItem?.gift}
                    />
                  );
                })}
            </div>
          );
        }
        return '';
      })}
    </div>
  );
};

PackTable.propTypes = {
  setPackBtnDisabled: PropTypes.func.isRequired
};

export default PackTable;
