import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getAttributes = payload => {
  const getUrl = queryBuilder(payload, '/properties', true);
  return apiPortal.get(getUrl);
};

export const getCreateAttribute = payload =>
  apiPortal.post(`/properties`, payload);

export const getSingleAttribute = payload =>
  apiPortal.get(`/properties/${Number(payload)}`);

export const getEditSingleAttribute = payload =>
  apiPortal.patch(`/properties/${Number(payload.id)}`, payload.data);

export const archiveAndUnarchive = payload =>
  payload.type === 'archive'
    ? apiPortal.post(`/properties_archivation`, { ids: [payload.id] })
    : apiPortal.delete(`/properties_archivation`, {
        data: { ids: [payload.id] }
      });
