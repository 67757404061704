import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import bugsnag from '@/src/services/bugsnag';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  getUser as getAccountRequest
  // getUserBookmarks as getAccountBookmarks,
  // saveUserBookmark as saveAccountBookmarkRequest,
  // removeUserBookmark as removeAccountBookmarkRequest
} from '@/src/api/users';
import { logoutAccount } from '@/src/store/modules/login/slice';
import {
  getAccount,
  setAccount
  // setAccountBookmarks,
  // saveAccountBookmark,
  // removeAccountBookmark,
  // loadingAccountBookmark
} from '@/src/store/modules/account/slice';

import { errorAlert } from '@/src/utils/notifications';

// export function* setAccountBookmarksSaga(accountId) {
//   try {
//     const accountBookmarksDataResponse = yield call(
//       getAccountBookmarks,
//       accountId
//     );
//     const accountBookmarksInfo = accountBookmarksDataResponse.data.data;
//     yield put(setAccountBookmarks(accountBookmarksInfo));
//   } catch (err) {
//     errorAlert(err);
//   }
// }

export function* getAccountSaga(action) {
  try {
    const response = yield call(getAccountRequest, action.payload);
    const normalized = normalize(response.data, { endpoint: '/account' });
    const parsed = (
      (normalized.meta && normalized.meta['/account'].data) ||
      []
    ).map(object =>
      build(normalized, 'users', object.id, { ignoreLinks: true })
    );
    const account = parsed[0];
    // yield setAccountBookmarksSaga(action.payload);
    bugsnag.user = {
      id: account.id,
      name: account.name,
      email: account.email
    };
    yield put(setAccount(account));
  } catch (err) {
    if (!axios.isCancel(err)) {
      errorAlert(err);
      yield put(logoutAccount());
    } else {
      bugsnag.notify(err);
    }
  }
}

// export function* saveAccountBookmarkSaga(action) {
//   try {
//     yield put(loadingAccountBookmark(true));
//     yield call(saveAccountBookmarkRequest, action.payload);
//     yield setAccountBookmarksSaga(action.payload.user_id);
//     yield put(loadingAccountBookmark(false));
//   } catch (err) {
//     yield put(loadingAccountBookmark(false));
//     errorAlert(err);
//   }
// }

// export function* removeAccountBookmarkSaga(action) {
//   try {
//     yield call(removeAccountBookmarkRequest, action.payload);
//     yield setAccountBookmarksSaga(action.payload.user_id);
//   } catch (err) {
//     errorAlert(err);
//   }
// }

function* accountSaga() {
  yield takeLatest(getAccount, getAccountSaga);
  // yield takeEvery(saveAccountBookmark, saveAccountBookmarkSaga);
  // yield takeEvery(removeAccountBookmark, removeAccountBookmarkSaga);
}

export default accountSaga;
