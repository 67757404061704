import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dogBreeds: [],
  catBreeds: [],
  isLoading: false
};

const customersSlice = createSlice({
  name: '@pet-breeds',
  initialState,
  reducers: {
    setDogBreeds(prevState, action) {
      const state = prevState;
      state.dogBreeds = action.payload;
      return state;
    },
    setCatBreeds(prevState, action) {
      const state = prevState;
      state.catBreeds = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    getPetBreeds(state) {
      return state;
    }
  }
});

export const {
  setDogBreeds,
  setCatBreeds,
  setIsLoading,
  getPetBreeds
} = customersSlice.actions;

export default customersSlice.reducer;
