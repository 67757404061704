import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentValues: [{}]
};

const attributeValuesSlice = createSlice({
  name: '@attributes',
  initialState,
  reducers: {
    setCurrentValues(prevState, action) {
      const state = prevState;
      state.currentValues = action.payload;
    }
  }
});

export const { setCurrentValues } = attributeValuesSlice.actions;

export default attributeValuesSlice.reducer;
