import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shippingsList: [],
  shipping: {},
  readyOrdersList: [],
  deliveryCompaniesList: [],
  companyId: '',
  deliveryKindsList: [],
  isLoading: false,
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    sort: '',
    url: '',
    filters: [],
    fields: [],
    include: ''
  }
};

const shippingSlice = createSlice({
  name: '@shipping',
  initialState,
  reducers: {
    setShippings(prevState, action) {
      const state = prevState;
      state.shippingsList = action.payload;
      return state;
    },
    setShipping(prevState, action) {
      const state = prevState;
      state.shipping = action.payload;
      return state;
    },
    setReadyOrders(prevState, action) {
      const state = prevState;
      state.readyOrdersList = action.payload;
      return state;
    },
    setDeliveryCompanies(prevState, action) {
      const state = prevState;
      state.deliveryCompaniesList = action.payload;
      return state;
    },
    setDeliveryKinds(prevState, action) {
      const state = prevState;
      state.deliveryKindsList = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setCount(prevState, action) {
      const state = prevState;
      state.count = action.payload;
      return state;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = action.payload;
      return state;
    },
    setCompanyId(prevState, action) {
      const state = prevState;
      state.companyId = action.payload;
      return state;
    },
    setNewDeliveryCompany(state) {
      return state;
    },
    getShippings(state) {
      return state;
    },
    getShippingById(state) {
      return state;
    },
    getReadyOrders(state) {
      return state;
    },
    updateShipping(state) {
      return state;
    },
    createShipping(state) {
      return state;
    },
    deleteShipping(state) {
      return state;
    },
    getDeliveryCompanies(state) {
      return state;
    },
    getDeliverers(state) {
      return state;
    },
    getDeliveryKinds(state) {
      return state;
    }
  }
});

export const {
  setShippings,
  setShipping,
  setReadyOrders,
  setDeliveryCompanies,
  setNewDeliveryCompany,
  setDeliveryKinds,
  setIsLoading,
  setPagination,
  setSort,
  setCount,
  setQuery,
  setCompanyId,
  getShippings,
  updateShipping,
  createShipping,
  deleteShipping,
  getShippingById,
  getReadyOrders,
  getDeliveryCompanies,
  getDeliverers,
  getDeliveryKinds
} = shippingSlice.actions;

export default shippingSlice.reducer;
