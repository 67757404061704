import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input, Select, Modal, Button, Icon } from 'antd';
import build from 'redux-object';
import moment from 'moment';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import NewHub from '@/src/components/Hubs/NewHub';

import {
  getHubs,
  getCreateHub,
  getHubsSelect
} from '@/src/store/modules/hubs-data/slice';
import { getStates } from '@/src/store/modules/states/slice';
import { getCities } from '@/src/store/modules/cities/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import { handleSelectSearch } from '@/src/utils/searchUtils';

const { Option } = Select;

function Hubs() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { editPermission } = usePermissions();

  const hubsPermission = editPermission(modules.DISPATCH_HUBS);

  const [hubs, setHubs] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [stateSelect, setStateSelect] = useState();
  const [citySelect, setCitySelect] = useState();
  const [showModal, setShowModal] = useState(false);

  const statesList = useSelector(state => state.states.list);
  const citiesList = useSelector(state => state.cities.list);
  const hubsList = useSelector(state => state.hubsData.list);
  const { query, count, isLoading } = useSelector(state => state.hubsData);

  const formRef = useRef();

  useEffect(() => {
    dispatch(
      getHubs({
        query: {
          // include: 'regions',
          // fields: [{ key: 'regions', value: 'id' }],
          filters: [],
          perPage: 20,
          page: 1,
          sort: 'name',
          stats: [{ key: 'total', value: 'count' }]
        }
      })
    );
    dispatch(
      getHubsSelect({
        query: {
          fields: [{ key: 'hubs', value: 'id,name' }],
          sort: 'name',
          perPage: 100
        }
      })
    );
    dispatch(
      getStates({
        query: {
          perPage: 50,
          fields: [{ key: 'states', value: 'code,id' }],
          sort: 'name'
        }
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hubsObj = (
      (hubsList.meta && hubsList.meta['/hubs'].data) ||
      []
    ).map(object => build(hubsList, 'hubs', object.id, { ignoreLinks: true }));
    setHubs(hubsObj);
  }, [hubsList]);

  useEffect(() => {
    const statesObj = (
      (statesList.meta && statesList.meta['/states'].data) ||
      []
    ).map(object =>
      build(statesList, 'states', object.id, { ignoreLinks: true })
    );
    setStates(statesObj);
  }, [statesList]);

  useEffect(() => {
    const citiesArr = (
      (citiesList.meta && citiesList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesList, 'cities', object.id, { ignoreLinks: true })
    );
    setCities(citiesArr);
  }, [citiesList]);

  const getHubsHandler = useCallback(
    urlQuery => {
      dispatch(getHubs(urlQuery));
    },
    [dispatch]
  );

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const handleOk = () => {
    formRef.current.validateFields((err, values) => {
      if (err) return;
      const {
        external_name,
        external_id,
        street,
        number,
        opens_at,
        closes_at,
        zipcode,
        workflow
      } = values;
      const payload = {
        data: {
          type: 'hubs',
          attributes: {
            name: external_name,
            external_name,
            external_id,
            street,
            number,
            opens_at: moment(opens_at).format('HH:mm'),
            closes_at: moment(closes_at).format('HH:mm'),
            zipcode,
            workflow
          },
          relationships: {
            country: {
              data: {
                type: 'countries',
                id: Number(values.country),
                method: 'update'
              }
            },
            state: {
              data: {
                type: 'states',
                id: Number(values.state),
                method: 'update'
              }
            },
            city: {
              data: {
                type: 'cities',
                id: Number(values.city),
                method: 'update'
              }
            }
          }
        }
      };
      if (values.district)
        payload.data.relationships.district = {
          data: {
            type: 'districts',
            id: Number(values.district),
            method: 'update'
          }
        };
      // console.log(payload);
      dispatch(getCreateHub(payload));
      handleCloseModal();
    });
  };

  const handleFilters = (value, type) => {
    if (value !== undefined || Object.values(query.filters).length > 0) {
      const filters = query.filters.filter(
        filterItem => filterItem.key !== type
      );
      getHubsHandler({
        query: {
          ...query,
          filters:
            value !== undefined
              ? [...filters, { key: type, type: 'eq', value }]
              : [...filters]
        }
      });
    }

    if (value === undefined && Object.values(query.filters).length < 1) {
      const params = buildQueryFromUrl(history);
      getHubsHandler({ query: params });
    }

    if (type === 'city_id') {
      const city = cities.filter(cityInfo => cityInfo.id === value)[0];
      setCitySelect(city?.name);
    }
  };

  const handleSelectState = value => {
    setStateSelect(value);

    if (value) {
      handleFilters(value, 'state_id');
      dispatch(
        getCities({
          query: {
            page: 1,
            perPage: 200,
            search: '',
            fields: [{ key: 'cities', value: 'name,id' }],
            filters: [
              {
                key: 'state_id',
                type: 'eq',
                value
              }
            ],
            sort: 'name'
          }
        })
      );
    } else {
      handleFilters(value, 'state_id');
      handleFilters(value, 'city_id');
    }
  };

  return (
    <>
      <Modal
        title={<h2>Novo Hub</h2>}
        okText="Salvar Hub"
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCloseModal}
        className="ant-modal-hubs centered-footer"
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
        destroyOnClose
        // bodyStyle={{ height: 365 }}
        width={700}
      >
        <NewHub ref={formRef} />
      </Modal>
      <Row>
        <Col span={20}>
          <h1 className="page-title mb-20">Hubs</h1>
        </Col>
      </Row>
      <div
        className="hubs"
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginBottom: 20 }}
        >
          <Col span={20}>
            <Input.Group compact>
              <Searchbar
                placeholder="Buscar por nome"
                populateResult={getHubsHandler}
                query={query}
                dispatchModule
                value={query.search}
              />
            </Input.Group>
          </Col>

          {hubsPermission && (
            <Col>
              <Button
                onClick={handleShowModal}
                size="large"
                className="ant-btn-secondary"
              >
                <Icon type="plus" />
                Novo Hub
              </Button>
            </Col>
          )}
        </Row>

        <Row type="flex" style={{ marginBottom: '16px' }} gutter={8}>
          <Col>
            <Select
              allowClear
              size="large"
              onChange={value => handleFilters(value, 'workflow')}
              placeholder="Modelo"
              style={{ width: 200 }}
            >
              <Option key="conventional" value="conventional">
                Convencional
              </Option>
              <Option key="petz" value="petz">
                Petz
              </Option>
            </Select>
          </Col>
          <Col>
            <Select
              allowClear
              size="large"
              onChange={value => handleFilters(value, 'active')}
              placeholder="Situação"
              style={{ width: 200 }}
            >
              <Option key="active" value="true">
                Ativo
              </Option>
              <Option key="inactive" value="false">
                Inativo
              </Option>
            </Select>
          </Col>
          <Col>
            <Select
              allowClear
              size="large"
              placeholder="UF"
              style={{ width: 200 }}
              onChange={handleSelectState}
              showSearch
              filterOption={handleSelectSearch}
            >
              {states.map(item => (
                <Option key={`${item.code}-${item.id}`} value={item.id}>
                  {item.code}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              allowClear
              size="large"
              placeholder="Cidade"
              style={{ width: 200 }}
              onChange={value => handleFilters(value, 'city_id')}
              disabled={!stateSelect}
              value={citySelect}
              showSearch
              filterOption={handleSelectSearch}
            >
              {cities.map(item => (
                <Option key={`${item.name}-${item.id}`} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Tables
          data={hubs}
          className="even-gray"
          contentToShow="hubs"
          history={history}
          isLoading={isLoading}
          query={query}
          populateTables={getHubsHandler}
          visiblePaginationUp
          visiblePaginationDown
          count={count}
        />
      </div>
    </>
  );
}

export default Hubs;
