import axios from 'axios';
import moment from '@/src/services/moment';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import bugsnag from '@/src/services/bugsnag';

import { setAccount } from '@/src/store/modules/account/slice';
import {
  logoutAccount,
  loginAccountFailed
} from '@/src/store/modules/login/slice';

import {
  getToken,
  getTokenExpiration,
  getRefreshToken,
  setToken,
  setTokenExpiration,
  setRefreshToken,
  decodeTokenExpiration
} from '@/src/services/auth';

const useInterceptors = store => {
  const { dispatch } = store;

  const normalizeAccountData = data => {
    const normalized = normalize(data, { endpoint: '/account' });
    const parsed = (
      (normalized.meta && normalized.meta['/account'].data) ||
      []
    ).map(object =>
      build(normalized, 'users', object.id, { ignoreLinks: true })
    );
    const account = parsed[0];
    return account;
  };

  const handleRefreshToken = async () => {
    let newToken;
    await fetch(`${process.env.REACT_APP_AUTH_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({
        data: {
          type: 'users',
          attributes: {
            token: getToken(),
            refresh_token: getRefreshToken()
          }
        }
      })
    })
      .then(res => {
        if (!res.ok) throw Error(res);
        return res.json();
      })
      .then(res => {
        const account = normalizeAccountData(res);
        const hasToken = getToken();
        if (hasToken) dispatch(setAccount(account));
        newToken = res.meta.auth_token;
        const newRefreshToken = res.meta.refresh_token;
        const decodeTokenExp = decodeTokenExpiration().exp;
        const newTokenExp = moment(decodeTokenExp * 1000).format();
        setToken(newToken);
        setRefreshToken(newRefreshToken);
        setTokenExpiration(newTokenExp);
      })
      .catch(err => {
        bugsnag.notify(err);
      });
    return newToken;
  };

  const handleAuthToken = async (api, config) => {
    const token = getToken();
    const tokenExp = getTokenExpiration();
    const isExpired = moment().isSameOrAfter(tokenExp);
    const configuration = config;
    if (tokenExp && isExpired) {
      const newToken = await handleRefreshToken();
      switch (api) {
        case 'auth':
          configuration.headers.Authentication = `Bearer ${newToken}`;
          break;
        case 'dispatch':
          configuration.headers['access-token'] = `Bearer ${newToken}`;
          break;
        default:
          configuration.headers.Authorization = `Bearer ${newToken}`;
      }
    } else if (token) {
      switch (api) {
        case 'auth':
          configuration.headers.Authentication = `Bearer ${token}`;
          break;
        case 'dispatch':
          configuration.headers['access-token'] = `Bearer ${token}`;
          break;
        default:
          configuration.headers.Authorization = `Bearer ${token}`;
      }
    }
    return configuration;
  };

  const handleAuthError = (api, error) => {
    const status = error.response?.status;
    const message = error.response?.data?.errors
      ? error.response.data.errors[0]?.detail ||
        error.response.data.errors[0]?.title
      : '';
    const description = message ? message.toLowerCase() : '';
    const { pathname } = window.location;

    // conditions
    const unauthorized = api !== 'auth' && status === 401;
    const banned =
      api !== 'auth' && status === 403 && description.includes('banned');
    const unauthorizedAuth =
      api === 'auth' && pathname !== '/' && status === 401;
    const bannedAuth =
      api === 'auth' &&
      pathname !== '/' &&
      status === 403 &&
      description.includes('banned');

    if (unauthorized || unauthorizedAuth || banned || bannedAuth) {
      dispatch(
        loginAccountFailed({
          error: status === 401 ? 'unauthorized' : 'banned'
        })
      );
      dispatch(logoutAccount());
      throw new axios.Cancel('Operation canceled by the user.');
    } else {
      return Promise.reject(error);
    }
  };

  return { handleAuthToken, handleAuthError };
};

export default useInterceptors;
