import React, { useEffect, useState, useCallback } from 'react';
import { Table, Row, Col, Menu, Dropdown, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import {
  getReferrals,
  getReferralsMetadata,
  invalidateReferral,
  banishReferral,
  getReferralsGraphs
} from '@/src/store/modules/referrals/slice';

import Audit from '@/src/components/Audit/index';

import moment from '@/src/services/moment';
import formatCurrency from '@/src/utils/formatCurrency';
import referralStatusInfo from '@/src/globals/referralStatusInfo';
import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import SearchBar from '@/src/components/Searchbar/Searchbar';
import ReferralDetails from '@/src/components/Referral/Details';
import ReferralMetrics from '@/src/components/Referral/Metrics';

const Referral = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showAudit, setShowAudit] = useState(false);
  const [auditId, setAuditId] = useState('');

  const {
    total,
    count,
    query,
    referrals,
    isLoading,
    metadata,
    graphs,
    isLoadingGraphs
  } = useSelector(state => state.referrals);

  const handleShowAudit = id => {
    setAuditId(id);
    setShowAudit(true);
  };

  const actionMenu = referral => {
    return (
      <Menu>
        <Menu.Item
          key="invalidate"
          onClick={() => dispatch(invalidateReferral(referral.id))}
          disabled={referral.attributes.reward_status !== 'pending'}
        >
          Invalidar indicação
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          disabled={
            referral.attributes.reward_status !== 'pending' &&
            referral.attributes['customer_banned?']
          }
          key="banish"
          onClick={() => dispatch(banishReferral(referral.id))}
        >
          Banir indicador
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() => handleShowAudit(referral.attributes.credit_id)}
        >
          Histórico de atualizações
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '# de Pedido',
      dataIndex: 'attributes.number',
      key: 'number',
      render: (number, record) => {
        return (
          <Link
            to={`/pedidos/${record.attributes.order_id}`}
            style={{
              border: '0',
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            {number}
          </Link>
        );
      }
    },
    {
      title: 'Dia',
      dataIndex: 'attributes.created_at',
      key: 'created_at',
      sorter: true,
      render: date => moment(date).format('DD/MM/YYYY'),
      width: 100
    },
    {
      title: 'Cupom',
      dataIndex: 'attributes.coupon',
      key: 'coupon',
      width: 100
    },
    {
      title: 'Compra Feita Por',
      dataIndex: 'attributes.customer_name',
      key: 'customer_name',
      render: (name, record) => {
        return (
          <div>
            <strong>{name}</strong>
            <br />
            {record.attributes.customer_email}
          </div>
        );
      }
    },

    {
      title: 'Indicador',
      dataIndex: 'attributes.referrer_name',
      key: 'referrer_name',
      render: (name, record) => {
        return (
          <div>
            <strong>{name}</strong>
            <br />
            {record.attributes.referrer_email}
          </div>
        );
      }
    },
    {
      title: 'Valor',
      dataIndex: 'attributes.total',
      key: 'total',
      sorter: true,
      render: value => formatCurrency(value),
      width: 150
    },
    {
      title: 'Status',
      dataIndex: 'attributes.reward_status',
      key: 'reward_status',
      render: (status, record) => {
        const diffDate = moment(record.attributes.due_date).diff(
          moment(),
          'days'
        );
        let text;
        switch (diffDate) {
          case 0:
            text = 'Faltam menos de 24 horas para a validação';
            break;
          case 1:
            text = `Falta ${diffDate} dia para a validação`;
            break;
          default:
            text = `Faltam ${diffDate} dias para a validação`;
            break;
        }
        return status && status.length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <Tag key={status} className={`status ${status}`}>
              {referralStatusInfo[status]
                ? referralStatusInfo[status].translation
                : status}
            </Tag>
            {record.attributes.due_date && status === 'pending' ? (
              <p style={{ marginBottom: 0, marginTop: 10, lineHeight: 1 }}>
                {text}
              </p>
            ) : null}
            {record.attributes['customer_banned?'] ? (
              <p style={{ marginBottom: 0, marginTop: 10, lineHeight: 1 }}>
                Usuário banido
              </p>
            ) : null}
            {status === 'broken_contract' ? (
              <p style={{ marginBottom: 0, marginTop: 10, lineHeight: 1 }}>
                Pedido com devolução
              </p>
            ) : null}
          </div>
        ) : null;
      },
      width: 200
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={() => actionMenu(record)} trigger={['click']}>
          <p
            style={{
              fontSize: '2.5em',
              textAlign: 'center',
              fontWeight: 'bold',
              cursor: 'pointer',
              lineHeight: 0,
              margin: 0
            }}
          >
            ...
          </p>
        </Dropdown>
      ),
      width: 100
    }
  ];

  const getReferralsHandler = useCallback(
    urlQuery => {
      dispatch(getReferrals({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getReferralsHandler({ query: params });
    dispatch(getReferralsMetadata());
    dispatch(getReferralsGraphs());
  }, [history, dispatch, getReferralsHandler]);

  return (
    <>
      <Audit
        id={auditId}
        title="INDICAÇÃO"
        type="Credit"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle" className="mb-20">
        <Col>
          <h1 className="m-0">Programa de Indicações</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px'
        }}
      >
        <Row gutter={16} type="flex" align="bottom">
          <Col span={12}>
            <ReferralDetails metadata={metadata} />
          </Col>

          <Col span={12}>
            <ReferralMetrics graphs={graphs} isLoading={isLoadingGraphs} />
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-between" align="middle">
          <Col span={16}>
            <SearchBar
              placeholder="Nome do Cliente / E-mail / Cupom / CPF"
              populateResult={getReferralsHandler}
              query={query}
              value={query.search}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {count !== 0 ? (
              <div
                className="table-results table-results__promos"
                style={{ position: 'relative', bottom: '-45px' }}
              >
                <p>
                  <span>{count}</span> Referral{count <= 1 ? '' : 's'}{' '}
                  <span>({formatCurrency(total)})</span>
                </p>
              </div>
            ) : (
              <br />
            )}
            <Table
              columns={columns}
              dataSource={referrals}
              size="medium"
              rowKey={record => record.id}
              loading={isLoading}
              pagination={{
                position: 'both',
                pageSize: query.perPage,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                total: count,
                current: query.page
              }}
              onChange={(pagination, filter, sorter) => {
                const { current, pageSize } = pagination;
                const hasSortOrder = () => {
                  return `[${sorter.columnKey}]=${sorter.order.replace(
                    'end',
                    ''
                  )}`;
                };
                const onChangeQuery = {
                  query: {
                    ...query,
                    page: query.perPage !== pageSize ? 1 : current,
                    perPage: pageSize,
                    sort: sorter.order ? hasSortOrder() : ''
                  }
                };
                dispatch(getReferralsHandler({ ...onChangeQuery }));
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Referral;
