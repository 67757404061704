import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  promotions: [],
  total: 0,
  selectedFilters: false,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    url: '',
    filters: {
      not_archived: true
    }
  },
  promotionsTagsIds: [],
  tagsPromotions: [],
  isLoading: false
};

const promotionsSlice = createSlice({
  name: '@promotions',
  initialState,
  reducers: {
    setPromotions(prevState, action) {
      const state = prevState;
      state.promotions = action.payload.promotions;
      state.total = action.payload.total;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.promotionsTagsIds = action.payload.promotionsTagsIds;
      state.tagsPromotions = action.payload.tagsPromotions;
      state.isLoading = false;
      return state;
    },
    setPromoIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    changePromotionInState(prevState, action) {
      const state = prevState;
      state.promotions = action.payload.promotions;
      return state;
    },
    setPromotionsSelectedFilters(prevState, action) {
      const state = prevState;
      state.selectedFilters = action.payload;
      return state;
    },
    createPromotion(state) {
      return state;
    },
    duplicatePromotion(state) {
      return state;
    },
    unarchivePromotion(state) {
      return state;
    },
    archivePromotion(state) {
      return state;
    },
    getPromotions(state) {
      return state;
    },
    setPagination(state) {
      return state;
    },
    setSort(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    }
  }
});

export const {
  getPromotions,
  archivePromotion,
  unarchivePromotion,
  duplicatePromotion,
  createPromotion,
  setPromotionsSelectedFilters,
  changePromotionInState,
  setPromoIsLoading,
  setPromotions,
  setSort,
  setPagination,
  exportCSV
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
