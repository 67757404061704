import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  getProductData as getProductDataRequest,
  setProductData as setProductDataRequest,
  getProductSkuVisibility as getProductSkuVisibilityRequest
} from '@/src/api/products';
import {
  createGift as createGiftRequest,
  updateGift as updateGiftRequest,
  deleteGift as deleteGiftRequest
} from '@/src/api/gifts';

import {
  getProduct,
  getSelectProduct,
  setProduct,
  setSelectProduct,
  setIsLoading,
  setIsLoadingGifts,
  getUpdateProduct,
  setUpdated,
  getProductSkuVisibility,
  createGift,
  updateGift,
  deleteGift
} from '@/src/store/modules/product-details/slice';

import {
  getRouterState,
  getProductIdState,
  getProductsQueryUrlState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

const handleProductResponse = response => {
  const normalized = normalize(response.data, { endpoint: '/product' });
  const productObj = (
    (normalized?.meta && normalized?.meta['/product'].data) ||
    []
  ).map(object =>
    build(normalized, 'product', object.id, { ignoreLinks: true })
  );
  const product = productObj[0];
  return product;
};

function* getProductSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getProductDataRequest, payload);
    const product = handleProductResponse(response);
    yield put(setProduct(product));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getSelectProductSaga({ payload }) {
  try {
    // yield put(setIsLoading(true));
    const response = yield call(getProductDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/product' });
    const productObj = (
      (normalized?.meta && normalized?.meta['/product'].data) ||
      []
    ).map(object =>
      build(normalized, 'product', object.id, { ignoreLinks: true })
    );
    const product = productObj[0];
    yield put(setSelectProduct(product));
    // yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getUpdateProductSaga({ payload }) {
  try {
    yield put(setUpdated(true));
    yield call(setProductDataRequest, payload);
    const routerState = yield select(getRouterState);
    const productsUrl = yield select(getProductsQueryUrlState);
    if (routerState.location.pathname.includes('/produtos/')) {
      yield put(push(`/produtos${productsUrl}`));
    }
    successAlert('Os dados do produto foram atualizados');
    yield put(setUpdated(false));
  } catch (err) {
    yield put(setUpdated(false));
    errorAlert(err);
  }
}

function* getProductSkuVisibilitySaga({ payload }) {
  try {
    yield call(getProductSkuVisibilityRequest, payload);
    // const routerState = yield select(getRouterState);
    // const productsUrl = yield select(getProductsQueryUrlState);
    // if (routerState.location.pathname.includes('/produtos/')) {
    //   yield put(push(`/produtos${productsUrl}`));
    // }
    const message = payload.data.visible
      ? 'A visibilidade do Sku no App da Zee.Now foi alterada'
      : 'A visibilidade do Sku no iFood foi alterada';
    successAlert(message);
  } catch (err) {
    errorAlert(err);
  }
}

function* createGiftSaga({ payload }) {
  try {
    yield put(setIsLoadingGifts(true));
    yield call(createGiftRequest, payload);
    const productId = yield select(getProductIdState);
    const response = yield call(getProductDataRequest, productId);
    const product = handleProductResponse(response);
    yield put(setProduct(product));
    yield put(setIsLoadingGifts(false));
    successAlert('Novo item associado ao produto');
  } catch (err) {
    yield put(setIsLoadingGifts(false));
    errorAlert(err);
  }
}

function* updateGiftSaga({ payload }) {
  try {
    yield put(setIsLoadingGifts(true));
    yield call(updateGiftRequest, payload);
    const productId = yield select(getProductIdState);
    const response = yield call(getProductDataRequest, productId);
    const product = handleProductResponse(response);
    yield put(setProduct(product));
    yield put(setIsLoadingGifts(false));
    successAlert('Os dados do item foram atualizados');
  } catch (err) {
    yield put(setIsLoadingGifts(false));
    errorAlert(err);
  }
}

function* deleteGiftSaga({ payload }) {
  try {
    yield put(setIsLoadingGifts(true));
    yield call(deleteGiftRequest, payload);
    const productId = yield select(getProductIdState);
    const response = yield call(getProductDataRequest, productId);
    const product = handleProductResponse(response);
    yield put(setProduct(product));
    yield put(setIsLoadingGifts(false));
    successAlert('Item removido');
  } catch (err) {
    yield put(setIsLoadingGifts(false));
    errorAlert(err);
  }
}

function* productSaga() {
  yield takeEvery(getProduct, getProductSaga);
  yield takeEvery(getSelectProduct, getSelectProductSaga);
  yield takeEvery(getUpdateProduct, getUpdateProductSaga);
  yield takeEvery(getProductSkuVisibility, getProductSkuVisibilitySaga);
  yield takeEvery(createGift, createGiftSaga);
  yield takeEvery(updateGift, updateGiftSaga);
  yield takeEvery(deleteGift, deleteGiftSaga);
}

export default productSaga;
