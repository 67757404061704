import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getSuspensionsData = payload => {
  const url = dispatchQueryBuilder(payload, '/suspensions');
  return apiDispatch.get(url);
};

export const getSuspensionSingleData = id =>
  apiDispatch.get(`/suspensions/${id}`);

export const setUpdateSuspensionData = payload =>
  apiDispatch.patch(`/suspensions/${payload.data.id}`, payload);

export const setCreateSuspensionData = payload =>
  apiDispatch.post('/suspensions', payload);

export const setDeleteSuspensionData = id =>
  apiDispatch.delete(`/suspensions/${id}`);
