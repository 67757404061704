import { callCustomer } from '@/src/api/customers';
import { notification } from 'antd';

const makeCall = payload => {
  callCustomer(payload)
    .then(res => {
      if (res.status === 204) {
        notification.success({
          message: 'Ligação feita com sucesso!',
          description: 'Continue a ligação via Atende Simples',
          className: 'success'
        });
      }
    })
    .catch(err => {
      throw new Error(err);
    });
};

export default makeCall;
