import React from 'react';
import { Icon } from 'antd';

const priceTables = [
  {
    title: 'Foto',
    align: 'left',
    dataIndex: 'image',
    render: data => {
      if (data?.data?.image) {
        return (
          <img
            src={data.data.image}
            alt={data.title}
            style={{ width: '80px' }}
          />
        );
      }
      return (
        <Icon
          type="camera"
          style={{ color: '#B3B3B3', fontSize: '25px' }}
          theme="filled"
        />
      );
    }
  },
  {
    title: 'Nome',
    align: 'left',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Preço Base',
    align: 'left'
  },
  {
    title: 'Preço Promocional',
    align: 'left'
  },
  {
    title: 'ID SKU',
    align: 'left'
  }
];

export default priceTables;
