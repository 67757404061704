import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Row, Col } from 'antd';

import { getResetPassword } from '@/src/store/modules/forgot-password/slice';

const ResetPasswordForm = ({ form, token }) => {
  const [confirmDirty, setConfirmDirty] = useState(false);

  const { errMessage, isLoading } = useSelector(state => state.forgotPassword);

  const regex = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/;

  const dispatch = useDispatch();

  const handleSubmit = evt => {
    evt.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const payload = {
          data: {
            type: 'users',
            attributes: {
              reset_token: token,
              password: values.password,
              password_confirmation: values.confirm
            }
          }
        };
        dispatch(getResetPassword(payload));
      }
      return false;
    });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Senha diferente da anterior');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {errMessage && (
        <>
          <Alert
            message="Dados incorretos. Tente novamente"
            type="error"
            showIcon
          />
          <br />
        </>
      )}
      <Row type="flex" justify="center" align="middle">
        <Col span={24} lg={18}>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: regex,
                  message: 'Faltam requisitos'
                },
                {
                  validator: validateToNextPassword
                }
              ]
            })(
              <Input.Password
                placeholder="Nova senha"
                autoComplete="new-password"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={24} lg={18}>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('confirm', {
              rules: [
                { required: true, message: 'Campo obrigatório' },
                { validator: compareToFirstPassword }
              ]
            })(
              <Input.Password
                placeholder="Confirmar nova senha"
                onBlur={handleConfirmBlur}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              type="primary"
              loading={isLoading}
              htmlType="submit"
              data-test="login-form-submit"
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired,
  token: PropTypes.string.isRequired
};

const WrappedResetPasswordForm = Form.create({
  name: 'forgot-password-form'
})(ResetPasswordForm);

export default WrappedResetPasswordForm;
