import React from 'react';
import { Link } from 'react-router-dom';

import moment from '@/src/services/moment';

const users = [
  {
    title: 'Nome Usuário',
    dataIndex: 'attributes.name',
    key: 'name',
    sorter: true,
    render: (text, data) => (
      <Link
        to={`/usuarios/${data.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)',
          textTransform: 'capitalize'
        }}
      >
        {text}
      </Link>
    )
  },
  {
    title: 'Função',
    dataIndex: 'attributes.occupation',
    key: 'occupation',
    sorter: true,
    render: text => {
      return text || '-';
    }
  },
  {
    title: 'E-mail',
    dataIndex: 'attributes.email',
    key: 'email',
    sorter: true
  },
  {
    title: 'Último Acesso',
    dataIndex: 'attributes.current_sign_in_at',
    key: 'current_sign_in_at',
    sorter: true,
    render: date => {
      return date ? moment(date).format('DD/MM/YYYY - HH:mm:ss') : '-';
    }
  }
];

export default users;
