import build from 'redux-object';

const buildJsonData = (response, endpoint) => {
  const data = (
    (response.meta && response.meta[`/${endpoint}`].data) ||
    []
  ).map(item => build(response, endpoint, item.id, { ignoreLinks: true }));
  return data;
};

export default buildJsonData;
