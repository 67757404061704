import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Input,
  Icon,
  Card,
  List,
  Button,
  Menu,
  Dropdown,
  Spin,
  Tooltip,
  Collapse,
  Empty,
  Col
} from 'antd';
import build from 'redux-object';
// import { debounce } from 'throttle-debounce';

// import ModalSwitchZipcode from '@/src/components/Hubs/Modals/SwitchZipcode';
import ModalZipcodesData from '@/src/components/Hubs/Modals/ZipcodesData';
import ModalConfirm from '@/src/components/Hubs/Modals/Confirm';
import ModalNewRegion from '@/src/components/Hubs/Modals/NewRegion';

import { getRegions, deleteZipcode } from '@/src/store/modules/regions/slice';
import { getZipcodes } from '@/src/store/modules/zipcodes/slice';
// import { getUpdateHub } from '@/src/store/modules/hubs-data/slice';
// import ArrowUnAssing from '@/src/assets/images/arrow-unassign.svg';
import styles from '../Regions.module.scss';

const { Search } = Input;
const { Panel } = Collapse;

const Zipcodes = ({ hubData, editPermission }) => {
  const { id: hubId } = useParams();

  const dispatch = useDispatch();

  const statesList = useSelector(state => state.states.list);
  const zipcodesList = useSelector(state => state.zipcodes.list);
  const loadingZipcodes = useSelector(state => state.zipcodes.isLoading);
  const { list: regionsList, isLoading: loadingRegions } = useSelector(
    state => state.regions
  );

  const [states, setStates] = useState([]);
  const [showNewRegionModal, setShowNewRegionModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showZipcodesModal, setShowZipcodesModal] = useState('');
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodesSearch, setZipcodesSearch] = useState(false);
  const [regionsByCity, setRegionsByCity] = useState([]);

  const searchRef = useRef();

  useEffect(() => {
    dispatch(
      getRegions({
        query: {
          perPage: 1000,
          include: 'city',
          fields: [{ key: 'cities', value: 'name,id' }],
          sort: 'name',
          filters: [
            {
              key: 'hub_id',
              type: 'eq',
              value: hubId
            }
          ]
        }
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const statesObj = (
      (statesList.meta && statesList.meta['/states'].data) ||
      []
    ).map(object =>
      build(statesList, 'states', object.id, { ignoreLinks: true })
    );
    setStates(statesObj);
  }, [statesList]);

  useEffect(() => {
    if (regionsList.filter(item => item.city).length > 0) {
      const citiesArr = regionsList.map(item => item.city.name).sort();
      const noDuplicates = [...new Set(citiesArr)];
      const arr = noDuplicates.map(item => {
        const cityRegions = regionsList.filter(
          region => region.city.name === item
        );
        return {
          name: item,
          regions: cityRegions
        };
      });
      setRegionsByCity(arr);
    } else {
      setRegionsByCity([]);
    }
  }, [regionsList]);

  const handleSearchZipcode = value => {
    setZipcodesSearch(!!value);
    if (value) {
      dispatch(
        getZipcodes({
          query: {
            filters: [{ key: 'code', type: 'match', value }],
            include: 'region,region.city,region.hub',
            fields: [
              { key: 'hubs', value: 'name,id' },
              { key: 'regions', value: 'name,id' },
              { key: 'cities', value: 'name' }
            ]
          }
        })
      );
    } else {
      setZipcodes([]);
    }
  };

  useEffect(() => {
    const zipcodesArr = (
      (zipcodesList?.meta && zipcodesList?.meta['/zipcodes'].data) ||
      []
    ).map(object =>
      build(zipcodesList, 'zipcodes', object.id, { ignoreLinks: true })
    );
    setZipcodes(zipcodesArr);
  }, [zipcodesList]);

  const handleDeleteZipcodeBtn = zipcode => {
    setConfirmModalData({
      title: 'Deletar CEP',
      body: `Tem certeza que deseja deletar o CEP ${zipcode.code}?`,
      onOk: () => {
        dispatch(deleteZipcode({ code: zipcode.id, hub: hubId }));
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const handleZipcodeDropdown = (zipcode, region) => (
    <Menu>
      <Menu.Item onClick={() => handleDeleteZipcodeBtn(zipcode)}>
        Remover
      </Menu.Item>
    </Menu>
  );

  const handleZipcodesByRegion = value => {
    if (value) {
      dispatch(
        getZipcodes({
          query: {
            filters: [{ key: 'region_id', type: 'eq', value }],
            sort: 'code',
            perPage: 1000
          }
        })
      );
    } else {
      setZipcodes([]);
    }
  };

  const renderRegion = region => {
    return (
      <Panel
        className="ant-collapse-header__hubs"
        header={region.name}
        key={region.id}
        style={{ backgroundColor: 'white' }}
      >
        {loadingZipcodes || zipcodes.length === 0 ? (
          <div className={styles.listCard__loading}>
            <Spin spinning={loadingZipcodes}>
              <span>
                {loadingZipcodes ? '' : 'Nenhum CEP cadastrado nesta região'}
              </span>
            </Spin>
          </div>
        ) : (
          zipcodes.map(item => (
            <List.Item
              className={styles.listCard__item}
              style={{ paddingLeft: 40 }}
              key={item.code}
            >
              <span className={styles.item__label}>{item.code}</span>
              {editPermission && (
                <Dropdown
                  overlay={handleZipcodeDropdown(item, region.id)}
                  trigger={['click']}
                >
                  <Button className="ant-btn-link p-0">
                    <Icon type="ellipsis" />
                  </Button>
                </Dropdown>
              )}
            </List.Item>
          ))
        )}
      </Panel>
    );
  };

  const renderCity = city => {
    return (
      <Panel
        className="ant-collapse-header__hubs"
        header={city.name}
        key={city.name}
      >
        <Collapse
          accordion
          className={`${styles.listCard__wrap} ${styles['listCard__wrap--collapse']}`}
          onChange={value => handleZipcodesByRegion(value)}
          // destroyInactivePanel
          style={{ minHeight: 50, height: 'auto' }}
        >
          {city.regions.map(region => renderRegion(region))}
        </Collapse>
      </Panel>
    );
  };

  return (
    <>
      <ModalNewRegion
        visible={showNewRegionModal}
        setVisible={setShowNewRegionModal}
        states={states}
        hubData={hubData}
      />
      {showZipcodesModal && (
        <ModalZipcodesData
          content={showZipcodesModal}
          setContent={setShowZipcodesModal}
          cities={regionsByCity}
        />
      )}
      <ModalConfirm
        data={confirmModalData}
        visible={showConfirmModal}
        setVisible={setShowConfirmModal}
      />
      <div className={`${styles.coverageWrapper} ${styles.region__wrapper}`}>
        <Col span={24}>
          <Card
            title={`Regiões e CEPs associados ao hub ${hubData.name}`}
            className={`no-shadow small-title ${styles.listCard}`}
          >
            {editPermission && (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className={`${styles.buttonModalCreate} ${styles.buttonNoBorder}`}
              >
                <Button
                  className="ant-btn-secondary"
                  onClick={() => setShowNewRegionModal(true)}
                >
                  Nova Região
                </Button>
                <Tooltip title="Importar CEPs">
                  <Button
                    className="ant-btn-secondary"
                    style={{ padding: '0 4px', margin: '0 12px' }}
                    onClick={() => setShowZipcodesModal('upload')}
                  >
                    <Icon type="upload" style={{ lineHeight: 0 }} />
                  </Button>
                </Tooltip>
                <Tooltip title="Exportar CEPs">
                  <Button
                    className="ant-btn-secondary"
                    style={{ padding: '0 4px' }}
                    onClick={() => setShowZipcodesModal('download')}
                  >
                    <Icon type="download" style={{ lineHeight: 0 }} />
                  </Button>
                </Tooltip>
              </div>
            )}
            <Search
              placeholder="Buscar número do CEP"
              size="large"
              className="ant-searchbar ant-searchbar-list"
              onSearch={handleSearchZipcode}
              ref={searchRef}
              defaultValue=""
              allowClear
            />
            {zipcodesSearch ? (
              <Spin spinning={loadingZipcodes}>
                <List
                  className={`${styles.listCard__wrap} ${zipcodes.length ===
                    0 &&
                    !loadingZipcodes &&
                    styles['listCard__wrap--empty']}`}
                >
                  {zipcodes.length > 0 || loadingZipcodes ? (
                    zipcodes
                      .filter(zipcode => zipcode.region)
                      .map(zipcode => (
                        <List.Item
                          className={`${styles.listCard__item} ${styles.item__associated}`}
                          key={zipcode.id}
                        >
                          <div className={styles.item__associated}>
                            <p>{zipcode.code}</p>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {zipcode.region.hub.id === hubId ? (
                                <strong style={{ textTransform: 'uppercase' }}>
                                  associado ao hub {zipcode.region.hub.name} -
                                  região {zipcode.region.city.name}/
                                  {zipcode.region.name}
                                </strong>
                              ) : (
                                <strong style={{ textTransform: 'uppercase' }}>
                                  associado ao{' '}
                                  <a
                                    href={`/hubs/${zipcode.region.hub.id}`}
                                    style={{ color: '#0a0a0a' }}
                                  >
                                    hub {zipcode.region.hub.name}
                                  </a>{' '}
                                  - região {zipcode.region.city.name}/
                                  {zipcode.region.name}
                                </strong>
                              )}
                              {editPermission && (
                                <Dropdown
                                  overlay={handleZipcodeDropdown(
                                    zipcode,
                                    zipcode.region?.id
                                  )}
                                  trigger={['click']}
                                >
                                  <Button className="ant-btn-link p-0">
                                    <Icon
                                      type="ellipsis"
                                      style={{ height: 24, marginLeft: 12 }}
                                    />
                                  </Button>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </List.Item>
                      ))
                  ) : (
                    <Empty
                      description="Nenhum CEP encontrado"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </List>
              </Spin>
            ) : (
              <Spin spinning={loadingRegions}>
                <Collapse
                  accordion
                  className={`${styles.listCard__wrap} ${styles['listCard__wrap--collapse']}`}
                  // destroyInactivePanel
                  style={{ minHeight: 50, height: 'auto' }}
                >
                  {regionsByCity.map(city => renderCity(city))}
                </Collapse>
              </Spin>
            )}
          </Card>
        </Col>
      </div>
    </>
  );
};

Zipcodes.defaultProps = {
  hubData: {}
};

Zipcodes.propTypes = {
  hubData: PropTypes.objectOf(PropTypes.any),
  editPermission: PropTypes.bool.isRequired
};

export default Zipcodes;
