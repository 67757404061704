import React from 'react';
import { Avatar, Badge, Icon } from 'antd';
import moment from '@/src/services/moment';

import formatCurrency from '@/src/utils/formatCurrency';
import { dispatchStatus } from '@/src/utils/localeData';

const shippingOrders = [
  {
    title: 'Número',
    align: 'left',
    dataIndex: 'order.number'
  },
  {
    title: 'Cliente',
    align: 'left',
    dataIndex: 'order.customer.name'
  },
  {
    title: 'Volumes',
    align: 'left',
    dataIndex: 'order.volumes.length'
  },
  {
    title: 'Preço',
    align: 'left',
    dataIndex: 'order.total',
    className: 'text-bold',
    render: record => {
      return record ? formatCurrency(record, true) : '-';
    }
  }
];

const orderItems = [
  {
    title: 'Foto',
    align: 'center',
    key: 'product-img',
    width: '10%',
    render: record => (
      <Badge
        count={
          record.gift ? (
            <Icon type="gift" theme="filled" className="dispatch-gift" />
          ) : (
            0
          )
        }
      >
        <Avatar
          shape="square"
          size={48}
          src={record.skuImage}
          icon="camera"
          className="img-placeholder"
        />
      </Badge>
    )
  },
  {
    title: 'SKU',
    align: 'center',
    key: 'sku-code',
    width: '20%',
    render: record => {
      return record.skuExternalId || record.skuCode;
    }
  },
  {
    title: 'EAN',
    align: 'center',
    dataIndex: 'skuEan',
    width: '20%'
  },
  {
    title: 'Nome Produto',
    align: 'left',
    key: 'sku_name',
    width: '25%',
    render: record => {
      if (record.missingQuantity > 0) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{record.skuName}</span>
            <strong>
              <Icon
                type={record.status === 'canceled' ? 'close-square' : 'warning'}
                style={{
                  color: `${
                    record.status === 'canceled' ? '#ff365c' : '#fdab02'
                  }`,
                  marginRight: 6
                }}
              />
              {record.status === 'canceled'
                ? 'Pedido cancelado'
                : 'Item com ruptura'}
            </strong>
          </div>
        );
      }
      return record.skuName;
    }
  },
  {
    title: 'Qnt',
    align: 'center',
    key: 'quantity',
    width: '12.5%',
    render: record => {
      if (record.missingQuantity > 0) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{record.quantity}</span>
            {record.status !== 'canceled' && (
              <strong style={{ marginTop: 4 }}>
                Ruptura: {record.missingQuantity}
              </strong>
            )}
          </div>
        );
      }
      return record.quantity;
    }
  },
  {
    title: 'Preço',
    align: 'center',
    className: 'text-bold',
    key: 'price',
    width: '12.5%',
    render: record => {
      const basePrice = Number(record.basePrice);
      const listPrice = Number(record.listPrice);
      return (
        <>
          <p style={{ marginBottom: 0 }}>{formatCurrency(basePrice, true)}</p>
          {listPrice < basePrice && (
            <p
              style={{
                fontWeight: 'normal',
                color: '#ff365c',
                margin: '8px 0 0'
              }}
            >
              - {formatCurrency(basePrice - listPrice, true)}
            </p>
          )}
        </>
      );
    }
  }
];

const returnedItems = [
  {
    title: 'Foto',
    align: 'center',
    key: 'product-img',
    width: '10%',
    render: record => (
      <Badge
        count={
          record.gift ? (
            <Icon type="gift" theme="filled" className="dispatch-gift" />
          ) : (
            0
          )
        }
      >
        <Avatar
          shape="square"
          size={48}
          src={record.skuImage}
          icon="camera"
          className="img-placeholder"
        />
      </Badge>
    )
  },
  {
    title: 'SKU',
    align: 'center',
    key: 'sku-code',
    width: '20%',
    render: record => {
      return record.skuExternalId || record.skuCode;
    }
  },
  {
    title: 'Nome Produto',
    align: 'left',
    key: 'sku_name',
    width: '40%',
    render: record => record.skuName
  },
  {
    title: 'Quantidade',
    align: 'center',
    key: 'quantity',
    width: '15%',
    render: record => record.returnedQuantity
  },
  {
    title: 'Preço',
    align: 'center',
    className: 'text-bold',
    key: 'price',
    width: '12.5%',
    render: record => formatCurrency(record.basePrice, true)
  }
];

const orderReshipments = [
  {
    title: 'ID Petz',
    align: 'left',
    dataIndex: 'attributes.external_id'
  },
  {
    title: 'Número',
    align: 'left',
    dataIndex: 'attributes.order_number'
  },
  {
    title: 'Data de Criação',
    dataIndex: 'attributes.created_at',
    render: data => (data ? moment(data).format('DD/MM/YYYY HH:mm') : '-')
  },
  {
    title: 'Total',
    align: 'left',
    dataIndex: 'attributes.total',
    // className: 'text-bold',
    render: record => (record ? formatCurrency(record, true) : 'R$ -')
  },
  {
    title: 'Status',
    dataIndex: 'attributes.status',
    render: data => dispatchStatus[data] || data || '-'
  }
];

const handleTableColumns = content => {
  let columns;

  switch (content) {
    case 'shippingOrders':
      columns = shippingOrders;
      break;
    case 'orderItems':
      columns = orderItems;
      break;
    case 'returnedItems':
      columns = returnedItems;
      break;
    case 'orderReshipments':
      columns = orderReshipments;
      break;
    default:
      columns = [];
      break;
  }

  return columns;
};

export default handleTableColumns;
