import { apiDispatch, apiDispatchFile } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getRegionsData = payload => {
  const url = dispatchQueryBuilder(payload, '/regions');
  return apiDispatch.get(url);
};

export const getRegionSingleData = id => apiDispatch.get(`/regions/${id}`);

export const updateRegion = payload =>
  apiDispatch.patch(
    `/regions/${payload.data.id}?include=zipcodes,hub,city`,
    payload
  );

export const getCreateRegion = payload => apiDispatch.post('/regions', payload);

export const downloadZipcodes = payload =>
  apiDispatch.get(`/regions/${payload}/coverages`);

export const uploadZipcodes = ({ id, data }) => {
  apiDispatchFile.post(`/regions/${id}/coverages`, data);
};
