import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Button, Modal, Switch, Input } from 'antd';

import Tables from '@/src/components/Tables/index';
import StoreModal from '@/src/components/GatewayStores/StoreModal';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import buildJsonData from '@/src/utils/buildJsonData';

import {
  getGatewayStores,
  setGatewayStores,
  updateGatewayStore,
  createGatewayStore
} from '@/src/store/modules/gateway-stores/slice';

const GatewayStores = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    gatewayStores,
    gatewayStore,
    query,
    count,
    indexLoading
  } = useSelector(state => state.gatewayStores);

  const [stores, setStores] = useState([]);
  const [storeData, setStoreData] = useState({});
  const [storeModal, setStoreModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({});
  const [updateDisabled, setUpdateDisables] = useState(true);

  const storeFormRef = useRef();

  const getGatewayStoresHandler = useCallback(
    urlQuery => dispatch(getGatewayStores(urlQuery)),
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getGatewayStoresHandler({ query: { ...params } });
    return () => {
      dispatch(
        setGatewayStores({
          data: [],
          query: {
            page: 1,
            perPage: 20,
            url: ''
          },
          count: 0
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const arr = buildJsonData(gatewayStores, 'gateway_store');
    setStores(arr);
  }, [gatewayStores]);

  useEffect(() => {
    const arr = buildJsonData(gatewayStore, 'gateway_store');
    setStoreData(arr[0]);
  }, [gatewayStore]);

  const handleShowStoreModal = content => {
    setModalContent(content);
    setStoreModal(true);
  };

  const handleStoreModalOk = () => {
    storeFormRef.current.validateFields((err, values) => {
      if (err) return;
      const { name, hub_id, secret_key, public_key, recipient_id } = values;
      const data = {
        name,
        hub_id: hub_id.toString(),
        secret_key,
        public_key,
        test_secret_key: secret_key,
        test_public_key: public_key,
        recipient_id
      };
      if (modalContent === 'new') {
        dispatch(createGatewayStore(data));
        setStoreModal(false);
      } else {
        const payload = {
          id: modalContent,
          data
        };
        setUpdatePayload(payload);
        setStoreModal(false);
        setConfirmationModal(true);
      }
    });
  };

  const handleConfirmationModalOk = () => {
    dispatch(updateGatewayStore(updatePayload));
    setConfirmationModal(false);
    setStoreData({});
  };

  const handleModalCancel = type => {
    if (type === 'store') {
      setStoreModal(false);
    } else {
      setConfirmationModal(false);
    }
    setStoreData({});
  };

  const handleConfirmationField = evt => {
    const { value } = evt.target;
    setUpdateDisables(value !== storeData?.name);
  };

  const columns = [
    {
      title: '#',
      align: 'center',
      dataIndex: 'id',
      sorter: true,
      width: '80px'
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Hub atrelado',
      dataIndex: 'hubs.name',
      sorter: true,
      render: (text, record) => <span>{record.hub.name}</span>
    },
    {
      title: 'Endereço do Hub',
      dataIndex: 'hubs.street',
      sorter: true,
      render: (text, record) => <span>{record.hub.full_address}</span>
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'active',
      render: (status, data) => (
        <Switch
          defaultChecked={status}
          onChange={(bool, evt) => {
            evt.stopPropagation();
            const payload = {
              id: data.id,
              data: {
                active: bool
              }
            };
            dispatch(updateGatewayStore(payload));
          }}
        />
      ),
      sorter: true
    }
  ];

  return (
    <>
      <Modal
        title={<h2>Edição de Chave</h2>}
        okText="Ok"
        visible={confirmationModal}
        onOk={handleConfirmationModalOk}
        onCancel={() => handleModalCancel('confirmation')}
        okButtonProps={{ disabled: updateDisabled }}
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
        className="ant-modal-stores centered-footer"
        destroyOnClose
      >
        <p>
          Esta ação interfere no direcionamento de receitas do sistema, para
          confirmar a ação, digite abaixo o nome {storeData?.name}.
        </p>
        <Row>
          <Input onChange={evt => handleConfirmationField(evt)} />
        </Row>
      </Modal>
      <Modal
        title={<h2>{modalContent === 'new' ? 'Nova Loja' : 'Dados Loja'}</h2>}
        okText={modalContent === 'new' ? 'Salvar' : 'Atualizar'}
        visible={storeModal}
        onOk={handleStoreModalOk}
        onCancel={() => handleModalCancel('store')}
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
        className="ant-modal-stores centered-footer"
        destroyOnClose
      >
        <StoreModal
          ref={storeFormRef}
          content={modalContent}
          data={storeData}
        />
      </Modal>
      <Row type="flex" justify="space-between" className="mb-20">
        <h1 className="m-0">Lojas com Gateway de Pagamento</h1>
        <Button type="primary" onClick={() => handleShowStoreModal('new')}>
          Nova Loja
        </Button>
      </Row>
      <div className="section-wrapper">
        <Row>
          <Tables
            data={stores}
            contentToShow="gatewayStores"
            columnsDefaults={columns}
            rowKey={record => record.id}
            visiblePaginationUp
            visiblePaginationDown
            isLoading={indexLoading}
            history={history}
            count={count}
            query={query}
            populateTables={getGatewayStoresHandler}
            rowClick={id => handleShowStoreModal(id)}
            showCountFilters
            showCountText="lojas"
          />
        </Row>
      </div>
    </>
  );
};

export default GatewayStores;
