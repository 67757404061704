import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  setPolicies,
  setPolicy,
  setPolicyPriceTables,
  setLoading,
  getPolicies,
  getPolicy,
  getPolicyPriceTables,
  getCreateNewPolicy,
  getUpdatePolicy
} from '@/src/store/modules/policies/slice';

import {
  getPoliciesRequest,
  getPolicyRequest,
  getPolicyPriceTablesRequest,
  createPolicyRequest,
  updatePolicyRequest
} from '@/src/api/policies';

import {
  getPoliciesQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getPoliciesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getPoliciesRequest, payload);

    yield put(
      setPolicies({
        policies: response.data.data,
        query: payload.query,
        total: response.data.meta.count
      })
    );

    const queryUrl = yield select(getPoliciesQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(
      err,
      'Não foi possível carregar a lista de políticas comerciais'
    );
  }
}

function* getPolicySaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getPolicyRequest, payload);
    yield put(setPolicy(response.data.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados da política comercial');
  }
}

function* getPolicyPriceTableSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const priceTables = yield call(getPolicyPriceTablesRequest, payload);
    yield put(
      setPolicyPriceTables({
        policyPriceTables: priceTables.data.data,
        query: payload.query
      })
    );
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(
      err,
      'Não foi possível carregar as tabelas de preço associadas à política comercial'
    );
  }
}

function* getCreateNewPolicySaga({ payload }) {
  try {
    yield put(setLoading(true));

    const response = yield call(createPolicyRequest, payload);

    const { status } = response;

    if (status === 200) {
      yield put(setLoading(false));
      yield put(push('/politicas'));
      successAlert('Nova política comercial criada');
    }
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar a política comercial');
  }
}

function* getUpdatePolicySaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(updatePolicyRequest, payload);
    yield put(setLoading(false));
    yield put(push('/politicas'));
    successAlert('Os dados da política comercial foram atualizados');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível atualizar a política comercial');
  }
}

function* policiesSaga() {
  yield takeEvery(getPolicies, getPoliciesSaga);
  yield takeEvery(getPolicy, getPolicySaga);
  yield takeEvery(getPolicyPriceTables, getPolicyPriceTableSaga);
  yield takeEvery(getCreateNewPolicy, getCreateNewPolicySaga);
  yield takeEvery(getUpdatePolicy, getUpdatePolicySaga);
}

export default policiesSaga;
