import { apiPortal } from '@/src/api/config';

export const getDepartmentsAttributes = () => apiPortal.get(`/properties`);

export const getCreateDepartment = payload =>
  apiPortal.post(`/departments`, payload);

// export const placeholderVar = 33;

export const getEdit = payload =>
  apiPortal.get(`/departments/${Number(payload)}`);

export const updateDepartment = payload =>
  apiPortal.patch(`/departments/${payload.id}`, payload.data);

export const archiveAndUnarchive = payload =>
  payload.type === 'archive'
    ? apiPortal.post(`/departments_archivation`, { id: Number(payload.id) })
    : apiPortal.delete(`/categories_archivation`, {
        data: { ids: [payload.id] }
      });
