import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getCitiesData = payload => {
  const url = dispatchQueryBuilder(payload, '/cities');
  return apiDispatch.get(url);
};

// export const getCitySingleData = payload => {
//   const url = dispatchQueryBuilder(payload, `/cities/${payload.id}`);
//   return apiDispatch.get(url);
// };

export const getCreateCity = payload => apiDispatch.post('/cities', payload);
