import { Modal } from 'antd';

const { confirm } = Modal;

const handleCall = ({ name, phone_number, cb }) => {
  const callPayload = {
    attendant_email: 'fabio@zee-dog.com',
    customer_name: name,
    customer_number: phone_number.replace(/[^0-9]/g, '')
  };
  confirm({
    title: `Deseja realmente ligar para ${name}?`,
    okText: 'Confirmar',
    centered: true,
    content:
      'Ao clicar em OK, o sistema irá realizar a ligação via Atende Simples',
    onOk() {
      return cb(callPayload);
    },
    onCancel() {}
  });
};

export default handleCall;
