import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setDepartments,
  getDepartments,
  setLoading
} from '@/src/store/modules/departments/slice';
import {
  getDepartmentsQueryUrlState,
  getRouterState
} from '@/src/store/selectors';
import getDepartmentsRequest from '@/src/api/departments';

import { errorAlert } from '@/src/utils/notifications';

function* getDepartmentsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getDepartmentsRequest, payload);
    const routerState = yield select(getRouterState);
    yield put(
      setDepartments({
        departments: response.data.data,
        query: routerState.location.pathname.includes('/departamentos')
          ? payload.query
          : null
      })
    );
    const queryUrl = yield select(getDepartmentsQueryUrlState);
    if (
      routerState.location.search !== queryUrl &&
      routerState.location.pathname.includes('/departamentos')
    ) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* departmentsSaga() {
  yield takeEvery(getDepartments, getDepartmentsSaga);
}

export default departmentsSaga;
