import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Icon } from 'antd';

import { getFilteredOrdersData } from '@/src/store/modules/expedition/slice';

const FilterPillsRow = ({ count, loading, disabled, buttonsRef }) => {
  const [activeBtn, setActiveBtn] = useState({
    has_alert: false,
    has_return_request: false,
    has_return_resolved: false,
    has_cancelation_request: false,
    has_cancelation_resolved: false,
    has_invoice_error: false
  });
  const { query, hubsFilter } = useSelector(state => state.expedition);

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveBtn({
      has_alert: query.filters.find(item => item.key === 'has_alert') || false,
      has_return_request:
        query.filters.find(item => item.key === 'has_return_request') || false,
      has_return_resolved:
        query.filters.find(item => item.key === 'has_return_resolved') || false,
      has_cancelation_request:
        query.filters.find(item => item.key === 'has_cancelation_request') ||
        false,
      has_cancelation_resolved:
        query.filters.find(item => item.key === 'has_cancelation_resolved') ||
        false,
      has_invoice_error:
        query.filters.find(item => item.key === 'has_invoice_error') || false
    });
  }, [query.filters]);

  const filterButtons = [
    {
      key: 'has_return_request',
      text: 'Devolução Pendente',
      filters: [{ key: 'has_return_request', type: 'eq', value: true }]
    },
    {
      key: 'has_return_resolved',
      text: 'Devolução Concluída',
      filters: [{ key: 'has_return_resolved', type: 'eq', value: true }]
    },
    {
      key: 'has_cancelation_request',
      text: 'Cancelamento Pendente',
      filters: [{ key: 'has_cancelation_request', type: 'eq', value: true }]
    },
    {
      key: 'has_cancelation_resolved',
      text: 'Cancelamento Concluído',
      filters: [{ key: 'has_cancelation_resolved', type: 'eq', value: true }]
    },
    {
      key: 'has_alert',
      text: 'Pedido em Alerta',
      filters: [{ key: 'has_alert', type: 'eq', value: true }]
    },
    {
      key: 'has_invoice_error',
      text: 'Erro na emissão da NF',
      filters: [{ key: 'has_invoice_error', type: 'eq', value: true }]
    }
  ];

  const handleClickFilter = value => {
    const filters = value;
    const hasHubFilter = filters.find(item => item.key === 'hub_internal_id');
    if (!hasHubFilter && !hubsFilter.includes('all')) {
      filters.push({
        key: 'hub_internal_id',
        type: 'match',
        value: hubsFilter
      });
    }
    dispatch(
      getFilteredOrdersData({
        query: {
          ...query,
          filters
        }
      })
    );
  };

  const handleClearFilter = key => {
    const filters = [...query.filters.filter(item => item.key !== key)];
    dispatch(getFilteredOrdersData({ query: { ...query, filters } }));
  };

  const handleButtonsRef = (el, i) => {
    // eslint-disable-next-line no-param-reassign
    buttonsRef.current[i] = el;
  };

  const handleClick = (filters, key, i) => {
    const hasFilter = query.filters.find(item => item.key === key);
    if (hasFilter) {
      buttonsRef.current[i].classList.remove('active');
      handleClearFilter(key, i);
    } else {
      buttonsRef.current[i].classList.add('active');
      handleClickFilter(filters, i);
      const otherButtons = buttonsRef.current.filter(
        item => item !== buttonsRef.current[i]
      );
      otherButtons.forEach(item => item.classList.remove('active'));
    }
  };

  return (
    <Row type="flex" style={{ marginTop: 16 }}>
      {filterButtons?.map((item, i) => (
        <Col key={item.key} className="btn-expedition-filter">
          <button
            type="button"
            onClick={() => handleClick(item.filters, item.key, i)}
            ref={el => handleButtonsRef(el, i)}
            disabled={disabled}
          >
            {item.text}
            {activeBtn[item.key] && !loading && (
              <>
                <span> ({count})</span>
                <Icon
                  type="close"
                  style={{ marginLeft: '5px', color: '#fff' }}
                />
              </>
            )}
          </button>
        </Col>
      ))}
    </Row>
  );
};

FilterPillsRow.defaultProps = {
  disabled: false
};

FilterPillsRow.propTypes = {
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  buttonsRef: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FilterPillsRow;
