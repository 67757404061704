import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  tagsFilters: [],
  isLoadingTag: false
};

const tagsSlice = createSlice({
  name: '@tag',
  initialState,
  reducers: {
    setTags(prevState, action) {
      const state = prevState;
      state.tags = action.payload;
      return state;
    },
    setTagsToFilters(prevState, action) {
      const state = prevState;
      state.tagsFilters = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoadingTag = action.payload;
      return state;
    },
    getTags(state) {
      return state;
    },
    addTag(state) {
      return state;
    },
    removeTag(state) {
      return state;
    }
  }
});

export const {
  setTags,
  setIsLoading,
  getTags,
  addTag,
  removeTag,
  setTagsToFilters
} = tagsSlice.actions;

export default tagsSlice.reducer;
