import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  Row,
  Col,
  Button,
  Divider,
  Skeleton,
  Collapse,
  Select,
  Alert,
  notification
} from 'antd';
import Slider from 'react-slick';
import moment from '@/src/services/moment';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Line from '@/src/components/Expedition/Line';
import Card from '@/src/components/Expedition/Card';
import Drawer from '@/src/components/Expedition/Drawer';
import Tables from '@/src/components/Tables';
import InvoicePill from '@/src/components/Expedition/InvoicePill/index';
import FiltersDrawer from '@/src/pages/Filters/index';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
import OrderModal from '@/src/components/Expedition/Modal/Order';
import FilterPillsRow from '@/src/components/Expedition/FilterPillsRow';

import { ReactComponent as TableIcon } from '@/src/assets/images/dispatch/table-view.svg';
import { ReactComponent as LinesIcon } from '@/src/assets/images/dispatch/lines-view.svg';

import {
  waitingQuery,
  userQuery as buildUserQuery,
  tableQuery,
  linesQuery,
  linesHubQuery,
  drawerQuery,
  drawerQueryByNumber
} from '@/src/globals/dispatchQueries';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import {
  resetState,
  getOrdersData,
  getUserOrdersData,
  getAllOrdersData,
  getFilteredOrdersData,
  getDrawerOrder,
  getCreateNotification,
  setViewer as setViewerState,
  setReloadOrders,
  setDrawerOrder,
  setHubsFilter,
  setOrders,
  setUserOrders
} from '@/src/store/modules/expedition/slice';

import { getHubs } from '@/src/store/modules/hubs-data/slice';

import { useBreakpoint } from '@/src/context/BreakpointContext';

import usePrevState from '@/src/hooks/usePrevState';

import formatParityText from '@/src/utils/formatParityText';
import buildJsonData from '@/src/utils/buildJsonData';

import './Expedition.scss';

const { Panel } = Collapse;

const { Option } = Select;

window.ifoodOnly = false;

const filterOrdersByStatus = (status, list) => {
  let orders;
  if (status === 'dispatched') {
    orders = list.filter(
      object =>
        (object.status === status || object.status === 'delivering') &&
        !object.hasCancelationRequest &&
        !object.hasReturnRequest
    );
  } else if (status === 'delivered') {
    orders = list.filter(
      object =>
        object.status === status &&
        !object.hasCancelationRequest &&
        !object.hasReturnRequest &&
        object.orderRequests.filter(
          item =>
            item.kind === 'return' && item.requestResolved && !item.rejected
        ).length === 0
    );
  } else if (status === 'separated') {
    orders = list.filter(object => {
      if (object.status === status) return true;
      if (
        (object.status === 'dispatched' ||
          object.status === 'delivering' ||
          object.status === 'delivered' ||
          object.status === 'canceled') &&
        (object.hasCancelationRequest || object.hasReturnRequest)
      )
        return true;
      return false;
    });
  } else {
    orders = list.filter(object => object.status === status);
  }
  return orders;
};

window.OneSignal = window.OneSignal || [];

const ExpeditionPage = () => {
  const typeTable = 'expedition';

  const linesRef = useRef();
  const cardsRef = useRef([]);
  const filterPillsRef = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoint();

  const {
    orders,
    userOrders,
    query,
    waitingOrdersCount,
    pageLoading,
    count,
    hubsFilter,
    reloadOrders,
    drawerOrder,
    modalData,
    polling,
    userPolling
  } = useSelector(state => state.expedition);

  const hubsList = useSelector(state => state.hubsData.list);

  const { account } = useSelector(state => state.account);

  const { location } = useSelector(state => state.router);

  const url = new URLSearchParams(location.search);

  const prevPollingState = usePrevState(polling);
  const prevUserPollingState = usePrevState(userPolling);

  const [searchTerm, setSearchTerm] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [tableView, setTableView] = useState(location.query.view === 'table');
  const [viewer, setViewer] = useState('');
  const [ordersPills, setOrdersPills] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchHiddenResults, setSearchHiddenResults] = useState([]);
  const [userHubs, setUserHubs] = useState([]);
  const [hubsSelect, setHubsSelect] = useState([]);
  const [pollingToast, setPollingToast] = useState('none');
  const [retryCounter, setRetryCounter] = useState(60);
  const [retryCounterActive, setRetryCounterActive] = useState(false);

  // OneSignal - notifications
  useEffect(() => {
    window.OneSignal.push(function() {
      if (account.kind === 'stockist' && breakpoint.mobile) {
        window.OneSignal.init({
          appId: '3d531cd4-fd91-493f-9460-98d1ec99ab56'
        });
        window.OneSignal.on('subscriptionChange', function(isSubscribed) {
          if (isSubscribed === true) {
            window.OneSignal.sendTags({
              hub: account.hubs,
              kind: account.kind
            }).then(function(tagsSent) {
              return tagsSent;
            });
          }
        });
      }
    });
    // eslint-disable-next-line
  }, [account.kind, account.hubs]);

  useEffect(() => {
    let interval;
    if (retryCounterActive) {
      interval = setInterval(() => setRetryCounter(prev => prev - 1), 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [retryCounterActive]);

  useEffect(() => {
    if (retryCounterActive) {
      notification.warning({
        key: 'retry',
        message: `Erro de conexão`,
        duration: 0,
        className: 'warning',
        description: `Aguarde enquanto tentamos atualizar os pedidos. Tempo restante: ${moment
          .unix(retryCounter)
          .format('m:ss')}`
      });
    }
    // eslint-disable-next-line
  }, [retryCounter]);

  // polling toast
  useEffect(() => {
    if (polling === 'error' || userPolling === 'error') {
      setPollingToast('error');
    } else if (polling === 'retry' || userPolling === 'retry') {
      if (polling === userPolling) setRetryCounter(60);
      setPollingToast('retry');
    } else if (
      (polling === 'running' && prevPollingState === 'retry') ||
      (userPolling === 'running' && prevUserPollingState === 'retry')
    ) {
      setPollingToast('running');
    } else {
      setPollingToast('none');
    }
    // eslint-disable-next-line
  }, [polling, userPolling]);

  useEffect(() => {
    switch (pollingToast) {
      case 'error':
        setRetryCounterActive(false);
        setRetryCounter(60);
        notification.destroy();
        notification.error({
          message: `Erro de conexão`,
          duration: 0,
          className: 'error',
          description:
            'Não foi possível verificar atualizações nos pedidos. Por favor, recarregue a página'
        });
        break;
      case 'retry':
        notification.destroy();
        notification.warning({
          key: 'retry',
          message: `Erro de conexão`,
          duration: 0,
          className: 'warning',
          description: `Aguarde enquanto tentamos atualizar os pedidos. Tempo restante: ${moment
            .unix(retryCounter)
            .format('m:ss')}`
        });
        setRetryCounterActive(true);
        break;
      case 'running':
        setRetryCounterActive(false);
        setRetryCounter(60);
        notification.destroy();
        notification.success({
          message: `Conexão restabelecida`,
          duration: 4,
          className: 'success',
          description:
            'Novos pedidos e atualizações estão sendo monitorados normalmente'
        });
        break;
      default:
        setRetryCounterActive(false);
        setRetryCounter(60);
        notification.destroy();
        break;
    }
    // eslint-disable-next-line
  }, [pollingToast]);

  const lines = [
    {
      status: 'waiting',
      title: 'Aguardando Coleta',
      nextStep: 'Iniciar',
      orders: filterOrdersByStatus('waiting', orders)
    },
    {
      status: 'started',
      title: 'Iniciado',
      nextStep: 'Coletar',
      orders: filterOrdersByStatus(
        'started',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'picked',
      title: 'Coletado',
      nextStep: 'Empacotar',
      orders: filterOrdersByStatus(
        'picked',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'packed',
      title: 'Empacotado',
      nextStep: 'Gerar Nota',
      orders: filterOrdersByStatus(
        'packed',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'billed',
      title: 'Faturado',
      nextStep: 'Etiquetar',
      orders: filterOrdersByStatus(
        'billed',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'separated',
      title: 'Embarque',
      nextStep: '',
      orders: filterOrdersByStatus(
        'separated',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'dispatched',
      title: 'Em rota',
      nextStep: '',
      orders: filterOrdersByStatus(
        'dispatched',
        viewer === 'stockist' ? userOrders : orders
      )
    },
    {
      status: 'delivered',
      title: 'Entregue',
      nextStep: '',
      orders: filterOrdersByStatus(
        'delivered',
        viewer === 'stockist' ? userOrders : orders
      )
    }
  ];

  useEffect(() => {
    if (waitingOrdersCount > 0) {
      dispatch(
        getCreateNotification({
          hub: account.hubs,
          ifood: window.ifoodOnly
        })
      );
    }
  }, [dispatch, waitingOrdersCount, account.hubs]);

  const handleShowDrawer = (number, id) => {
    if (!location.query.order) {
      url.append('order', number);
      dispatch(push(`?${url.toString()}`));
    }
    const customQuery = id ? drawerQuery : drawerQueryByNumber(number);
    dispatch(getDrawerOrder({ ...customQuery, number, id }));
    if (id) setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    url.delete('order');
    dispatch(push(`?${url.toString()}`));
    setShowDrawer(false);
    dispatch(setDrawerOrder({}));
  };

  const buildQueryByHub = queryObj => {
    const payload = {
      query: {
        ...queryObj.query
      }
    };
    const hubs = hubsFilter === 'all' ? account.hubs : hubsFilter;
    if (hubs && hubs !== 'all') {
      payload.query.filters = [
        ...payload.query.filters,
        {
          key: 'hub_internal_id',
          type: 'match',
          value: hubs
        }
      ];
    }
    return payload;
  };

  const changeToTableView = () => {
    url.set('view', 'table');
    dispatch(push(`?${url.toString()}`));
    setTableView(true);
    setSearchTerm('');
    if (!hubsFilter) {
      dispatch(setOrders([]));
      dispatch(setUserOrders([]));
    } else if (hubsFilter !== 'all') {
      const payload = buildQueryByHub(tableQuery);
      dispatch(getOrdersData(payload));
    }
  };

  const changeToLinesView = () => {
    url.set('view', 'lines');
    dispatch(push(`?${url.toString()}`));
    setTableView(false);
    setSearchTerm('');
    if (!hubsFilter || hubsFilter === 'all') {
      dispatch(setOrders([]));
      dispatch(setUserOrders([]));
    } else if (viewer === 'hub') {
      const payload = buildQueryByHub(linesHubQuery);
      dispatch(getAllOrdersData(payload));
    } else if (viewer === 'stockist') {
      const payload = buildQueryByHub(waitingQuery);
      dispatch(getOrdersData(payload));
      const userQuery = buildUserQuery(account.id);
      const userPayload = buildQueryByHub(userQuery);
      dispatch(getUserOrdersData(userPayload));
    } else if (viewer === 'office') {
      const payload = buildQueryByHub(linesQuery);
      dispatch(getAllOrdersData(payload));
    }
  };

  const slideGoTo = line => linesRef.current.slickGoTo(line);

  useEffect(() => {
    dispatch(
      getHubs({
        query: {
          fields: [{ key: 'hubs', value: 'id,name,internal_id' }],
          perPage: 500,
          sort: 'name'
        }
      })
    );
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let viewerValue = '';
    if (breakpoint.fullhd) {
      viewerValue = 'hub';
    } else {
      viewerValue = account.kind;
    }
    setViewer(viewerValue);
    dispatch(setViewerState(viewerValue));
    // eslint-disable-next-line
  }, [account.kind]);

  useEffect(() => {
    const hubsArr = buildJsonData(hubsList, 'hubs');
    if (!hubsArr.length) return;
    if (!account.hubs || account.hubs === 'all') {
      setUserHubs(hubsArr);
      dispatch(setHubsFilter('all'));
    } else {
      const userHubsId = account.hubs.split(',');
      const userHubsArr = userHubsId.map(item => {
        const hubData = hubsArr.find(hub => hub.internalId === item);
        return hubData;
      });
      setUserHubs(userHubsArr);
      dispatch(setHubsFilter(account.hubs));
    }
    // eslint-disable-next-line
  }, [hubsList, account.hubs]);

  useEffect(() => {
    if (!hubsFilter) return;
    if (hubsFilter === 'all') {
      // const hubsArr = userHubs.map(item => item.internalId);
      setHubsSelect([]);
    } else {
      setHubsSelect(hubsFilter?.split(',') || []);
    }
    if (tableView) {
      if (breakpoint.mobile) {
        changeToLinesView();
      } else if (hubsFilter !== 'all') {
        const payload = buildQueryByHub(tableQuery);
        dispatch(getOrdersData(payload));
      }
    } else {
      if (!location.query.view) {
        url.append('view', 'lines');
        dispatch(push(`?${url.toString()}`));
      }
      if (viewer === 'stockist' && hubsFilter !== 'all') {
        const payload = buildQueryByHub(waitingQuery);
        dispatch(getOrdersData(payload));
        const userQuery = buildUserQuery(account.id);
        const userPayload = buildQueryByHub(userQuery);
        dispatch(getUserOrdersData(userPayload));
      } else if (hubsFilter !== 'all') {
        const payload = buildQueryByHub(
          viewer === 'hub' ? linesHubQuery : linesQuery
        );
        dispatch(getAllOrdersData(payload));
      }
    }
    if (location.query.order) {
      if (breakpoint.mobile) {
        url.delete('order');
        dispatch(push(`?${url.toString()}`));
      } else {
        handleShowDrawer(location.query.order);
      }
    }
    // eslint-disable-next-line
  }, [viewer, hubsFilter, account.hubs]);

  useEffect(() => {
    if (breakpoint.mobile) {
      const list =
        viewer === 'office'
          ? orders
          : orders.filter(item => item.status === 'waiting').concat(userOrders);
      const pills = list.filter(item => {
        if (
          item.hasCancelationRequest ||
          item.hasReturnRequest ||
          item.hasAlert
        )
          return true;
        if (
          item.status === 'canceled' ||
          item.status === 'waiting' ||
          item.status === 'separated' ||
          item.status === 'dispatched' ||
          item.status === 'delivering' ||
          item.status === 'delivered'
        )
          return false;
        return true;
      });
      setOrdersPills(pills);
    }

    // if (viewer === 'stockist') {
    //   const hasRequest = userOrders.filter(
    //     item => item.hasCancelationRequest || item.hasReturnRequest
    //   );
    //   if (hasRequest.length > 0) {
    //     cardsRef.current.forEach(card => {
    //       if (!card.classList.contains('has-request')) {
    //         card.classList.add('disabled');
    //       } else {
    //         card.classList.remove('disabled');
    //       }
    //     });
    //   } else {
    //     cardsRef.current.forEach(card => card.classList.remove('disabled'));
    //   }
    // }

    if (viewer !== 'hub') {
      const searchFilter = query.filters.find(item => item.key === 'search');
      if (searchFilter) {
        const allOrders =
          viewer === 'stockist' ? orders.concat(userOrders) : orders;
        const hiddenOrders = allOrders
          .filter(item => item.status === 'canceled')
          .map(item => item.number);
        setSearchHiddenResults(hiddenOrders);
      } else {
        setSearchHiddenResults([]);
      }
    }
    // eslint-disable-next-line
  }, [orders, userOrders, viewer]);

  useEffect(() => {
    const searchFilter = query.filters.find(item => item.key === 'search');
    setSearchTerm(searchFilter?.value || '');
    const quickFilters = query.filters.filter(
      item =>
        item.key === 'has_alert' ||
        item.key === 'has_return_request' ||
        item.key === 'has_cancelation_request'
    );
    setFiltersApplied(quickFilters.length || searchFilter);
    if (!tableView && !searchFilter) slideGoTo(0);
    // eslint-disable-next-line
  }, [query.filters]);

  useEffect(() => {
    if (drawerOrder === null) {
      url.delete('order');
      dispatch(push(`?${url.toString()}`));
      dispatch(setDrawerOrder({}));
    } else if (drawerOrder.id) {
      setShowDrawer(true);
    }
    // eslint-disable-next-line
  }, [drawerOrder]);

  const getOrdersListHandler = useCallback(
    urlQuery => {
      const payload = buildQueryByHub(urlQuery);
      dispatch(getFilteredOrdersData(payload));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const buildOrdersPills = () => {
    const ordersArr = ordersPills
      ?.map(item => {
        let priority = 5;
        if (item.hasCancelationRequest) priority = 1;
        if (item.hasReturnRequest) priority = 2;
        if (item.hasAlert) priority = 3;
        if (
          item.orderRequests.filter(request => request.requestResolved).length >
          0
        )
          priority = 4;
        return { ...item, priority };
      })
      .sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return 0;
      });
    if (ordersArr.length === 0) return <span>Não há pedidos ativos.</span>;
    return ordersArr.map(order => (
      <InvoicePill
        key={order.id}
        orderId={order.id}
        alert={order.hasAlert}
        cancelationRequest={order.hasCancelationRequest}
        returnRequest={order.hasReturnRequest}
        resolved={
          order.orderRequests.filter(item => item.requestResolved).length > 0
        }
        orderNumber={order.number}
        cardsRef={cardsRef}
        slideGoTo={slideGoTo}
      />
    ));
  };

  const sliderConfig = {
    draggable: true,
    swipe: breakpoint.lg || (breakpoint.mobile && !filtersApplied),
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: breakpoint.lg ? 1.5 : 1,
    // slidesToScroll: 1,
    dots: breakpoint.mobile && !filtersApplied,
    arrows: breakpoint.lg && viewer !== 'hub',
    // afterChange: index => console.log(index, linesRef.current),
    customPaging: i => {
      if (lines[i].status === 'dispatched' || lines[i].status === 'delivered')
        return <></>;
      return (
        <div className="tab-bar">
          <span className="tab-bar__label">{lines[i].title.split(' ', 1)}</span>
          <button type="button" className="tab-bar__button">
            {lines[i].orders.length}
            {waitingOrdersCount > 0 && lines[i].status === 'waiting' && (
              <i className="tab-bar__badge" />
            )}
          </button>
        </div>
      );
    },
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4.5
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const checkDesktopSlideScroll = status => {
    const { currentSlide } = linesRef.current.innerSlider.state;
    if (currentSlide === 0) {
      return (
        status === 'packed' || status === 'billed' || status === 'separated'
      );
    }
    if (currentSlide === 1.5) {
      return (
        status === 'waiting' || status === 'started' || status === 'separated'
      );
    }
    return status === 'waiting' || status === 'started';
  };

  const handleSlider = (status, undo = false) => {
    if (breakpoint.mobile) {
      const statusObj = lines.find(item => item.status === status);
      slideGoTo(lines.indexOf(statusObj));
      return;
    }
    const slideScroll = checkDesktopSlideScroll(status);
    if (breakpoint.xl && slideScroll) {
      if (undo) {
        linesRef.current.slickPrev();
      } else {
        linesRef.current.slickNext();
      }
    }
  };

  const handleCardRef = element => {
    if (element) {
      const hasEl = cardsRef.current.find(card => card.id === element.id);
      if (hasEl) {
        cardsRef.current = cardsRef.current.map(card => {
          if (card.id === hasEl.id) return element;
          return card;
        });
      } else {
        cardsRef.current.push(element);
      }
    }
  };

  const handleReloadOrders = () => {
    const payload = buildQueryByHub(linesQuery);
    dispatch(getAllOrdersData(payload));
    dispatch(setReloadOrders(false));
  };

  const handleSearchCount = () => {
    const allOrders =
      viewer === 'stockist' ? orders.concat(userOrders) : orders;
    const searchCount = allOrders.length;
    if (searchCount === 1) return '1 pedido encontrado';
    return `${tableView ? count : searchCount} pedidos encontrados`;
  };

  const handleShowLine = (status, content) => {
    if (filtersApplied && !tableView) {
      return content > 0;
    }
    if (status === 'dispatched' || status === 'delivered')
      return viewer !== 'hub';
    return true;
  };

  const handleSelectHub = value => {
    // if (value === 'all') {
    //   setHubsSelect(['all']);
    //   dispatch(setHubsFilter('all'));
    // } else {
    setHubsSelect(prev => {
      // const newValue = [...prev.filter(item => item !== 'all'), value];
      const newValue = [...prev, value];
      dispatch(setHubsFilter(newValue.join(',')));
      return newValue;
    });
    // }
  };

  const handleDeselectHub = value => {
    setHubsSelect(prev => {
      const newValue = prev.filter(item => item !== value);
      if (newValue.length) {
        dispatch(setHubsFilter(newValue.join(',')));
      } else {
        dispatch(resetState());
      }
      return newValue;
    });
  };

  // const handleSelectHubSearch = (input, option) =>
  //   option.props.children.toLowerCase().includes(input.toLowerCase());

  const handleSelectHubChange = value => {
    if (value.length === 0) {
      setHubsSelect([]);
      dispatch(resetState());
    }
    filterPillsRef.current.forEach(item => item?.classList.remove('active'));
  };

  return (
    <>
      {!tableView && viewer === 'office' && reloadOrders && !filtersApplied && (
        <Alert
          message={
            breakpoint.mobile
              ? 'Atualização pendente'
              : 'Atualização pendente em um ou mais pedidos'
          }
          type="warning"
          closeText="ATUALIZAR"
          showIcon
          afterClose={() => handleReloadOrders()}
          className="dispatch-alert"
        />
      )}
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className="expedition__header"
      >
        {breakpoint.lg && (
          <Col>
            <h1 className="page-title">Expedição</h1>
          </Col>
        )}
      </Row>
      <div className="expedition">
        {userHubs.length > 1 && (
          <div className="expedition__card expedition__hubs-filter">
            <Row>
              <h2>Visualizando Hubs</h2>
            </Row>
            <Row>
              <Select
                mode="multiple"
                showArrow
                placeholder="Clique para selecionar as opções"
                filterOption={handleSelectSearch}
                value={hubsSelect}
                onSelect={handleSelectHub}
                onDeselect={handleDeselectHub}
                onChange={handleSelectHubChange}
                allowClear
              >
                {/* <Option key="all">Todos</Option> */}
                {userHubs.map(item => (
                  <Option key={item.internalId}>{item.name}</Option>
                ))}
              </Select>
            </Row>
          </div>
        )}
        <div className="expedition__card">
          {viewer !== 'hub' && (
            <>
              {breakpoint.mobile && (
                <Row className="orders-assigned">
                  <div className="orders-assigned__header">
                    <h2 className="orders-assigned__title">Pedidos Ativos</h2>
                    <span>
                      {formatParityText(ordersPills.length || 0, 'pedido')}
                    </span>
                  </div>
                  <Divider className="orders-assigned__divider" />
                  <div className="orders-assigned__pills">
                    <Skeleton
                      active
                      title={false}
                      loading={pageLoading}
                      paragraph={{ rows: 1, width: '50%' }}
                    >
                      {buildOrdersPills()}
                    </Skeleton>
                  </div>
                  <Divider className="orders-assigned__divider" />
                </Row>
              )}
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className={breakpoint.lg && tableView ? 'mb-12' : ''}
              >
                <Col className="expedition__search">
                  <Searchbar
                    placeholder={
                      breakpoint.lg
                        ? 'Buscar # pedido, estoquista, produto, cliente ou endereço'
                        : 'Buscar # pedido'
                    }
                    populateResult={getOrdersListHandler}
                    query={query}
                    value={searchTerm}
                    dispatchModule
                    handleClearResults={handleReloadOrders}
                    disabled={!hubsFilter || hubsSelect.length === 0}
                  />
                  {tableView && (
                    <FiltersDrawer
                      filterContent="expedition"
                      disabled={!hubsFilter || hubsSelect.length === 0}
                    />
                  )}
                </Col>
                {breakpoint.lg && (
                  <Col className="expedition__view-selector">
                    <Button
                      onClick={changeToLinesView}
                      size="large"
                      className={`ant-btn-expedition left ${
                        tableView ? 'inactive' : ''
                      }`}
                    >
                      <LinesIcon />
                      <span>Raias</span>
                    </Button>
                    <Button
                      onClick={changeToTableView}
                      size="large"
                      className={`ant-btn-expedition right ${
                        tableView ? '' : 'inactive'
                      }`}
                    >
                      <TableIcon />
                      <span>Listagem</span>
                    </Button>
                  </Col>
                )}
              </Row>
              {!tableView && searchHiddenResults.length > 0 && !pageLoading && (
                <Collapse
                  defaultActiveKey={['1']}
                  className="expedition__search--hidden-results"
                >
                  <Panel
                    header={`Pedidos inativos (${searchHiddenResults.length})`}
                    key="1"
                  >
                    {searchHiddenResults.map(item => (
                      <p key={item}>{item} - Pedido cancelado</p>
                    ))}
                  </Panel>
                </Collapse>
              )}
              {breakpoint.lg && (
                <Row
                  type="flex"
                  align={tableView ? 'top' : 'middle'}
                  justify="space-between"
                >
                  {tableView && (
                    <Col span={20}>
                      <FilterPillsRow
                        loading={pageLoading}
                        buttonsRef={filterPillsRef}
                        count={count}
                        disabled={!hubsFilter || hubsSelect.length === 0}
                      />
                    </Col>
                  )}
                  {tableView && !pageLoading && (
                    <Col style={{ marginTop: `${tableView ? '10px' : '0'}` }}>
                      <span className="expedition__search--count">
                        {handleSearchCount()}
                      </span>
                    </Col>
                  )}
                  {!tableView &&
                    !pageLoading &&
                    query.filters.find(item => item.key === 'search') && (
                      <Col>
                        <span className="expedition__search--count">
                          {handleSearchCount()}
                        </span>
                      </Col>
                    )}
                </Row>
              )}
              {tableView && (
                <Col>
                  <div className="filtered-tags table-view">
                    <FilteredTags
                      query={query}
                      populateTable={getOrdersListHandler}
                      noMargin
                    />
                  </div>
                </Col>
              )}
              {breakpoint.mobile &&
                !tableView &&
                !pageLoading &&
                query.filters.find(item => item.key === 'search') && (
                  <span className="expedition__search--count">
                    {handleSearchCount()}
                  </span>
                )}
            </>
          )}
          {tableView ? (
            <div>
              <Tables
                data={orders}
                query={query}
                className="even-gray"
                visiblePaginationUp
                visiblePaginationDown
                contentToShow={typeTable}
                rowKey={record => `expedition-${record.id}`}
                populateTables={getOrdersListHandler}
                history={history}
                count={count}
                isLoading={pageLoading}
                rowClick={(number, id) => handleShowDrawer(number, id)}
              />
            </div>
          ) : (
            <Slider
              {...sliderConfig}
              ref={linesRef}
              className={`
              ${viewer === 'hub' ? 'no-margin ' : ''}
              ${breakpoint.mobile && filtersApplied ? 'no-slide ' : ''}
            `}
            >
              {lines
                .filter(line => handleShowLine(line.status, line.orders.length))
                .map(line => (
                  <Line
                    key={line.status}
                    title={line.title}
                    reload={line.status === 'waiting' && viewer === 'stockist'}
                    orders={line.orders}
                    loading={pageLoading}
                    filtersApplied={filtersApplied && !tableView}
                  >
                    {line.orders.map(item => (
                      <Card
                        key={`order-${item.id}`}
                        showHub={userHubs.length > 1}
                        nextStep={
                          item.hasReturnRequest ? 'Devolução' : line.nextStep
                        }
                        order={item}
                        handleSlider={handleSlider}
                        handleShowDrawer={handleShowDrawer}
                        ref={handleCardRef}
                      />
                    ))}
                  </Line>
                ))}
            </Slider>
          )}
        </div>
        {modalData.visibility && (
          <OrderModal
            handleSlider={handleSlider}
            tableView={tableView}
            // order={
            //   showDrawer && drawerOrder.id === modalData.order.id
            //     ? drawerOrder
            //     : userOrders.find(order => order.id === modalData.order.id) ||
            //       orders.find(order => order.id === modalData.order.id)
            // }
          />
        )}
        {showDrawer && (
          <Drawer
            visible={showDrawer}
            onClose={handleCloseDrawer}
            order={drawerOrder}
          />
        )}
      </div>
    </>
  );
};

export default ExpeditionPage;
