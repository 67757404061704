import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col, Button, Icon, Upload, message } from 'antd';
import PropTypes from 'prop-types';

import {
  exportCSVProductsSort,
  importCSVProductsSort
} from '@/src/store/modules/products-all/slice';

const ModalProductsSort = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const [CSVFile, setCSVFile] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setBtnDisabled(true);
      return false;
    }
    setCSVFile([file]);
    setBtnDisabled(false);
    return false;
  };

  const removeFile = () => {
    setCSVFile([]);
    setBtnDisabled(showModal);
  };

  const handleImportCSV = useCallback(() => {
    dispatch(importCSVProductsSort({ csv: CSVFile }));
    setShowModal(false);
    setBtnDisabled(false);
    setCSVFile([]);
    // eslint-disable-next-line
  }, [CSVFile]);

  const handleExportCSVSort = useCallback(() => {
    dispatch(exportCSVProductsSort());
  }, [dispatch]);

  const contentModalCSV = (
    <>
      <Row
        style={{
          borderBottom: '3px ##E2E2E2 solid',
          borderTop: '3px ##E2E2E2 solid'
        }}
      >
        <Col style={{ marginBottom: 10 }}>
          <p>
            Para começar a importação de ordenação de produtos, sugerimos
            exportar a planilha modelo para editar e subir o arquivo novamente.
          </p>
        </Col>
        <Col style={{ marginBottom: 10 }}>
          <strong>
            Importante: sempre que uma planilha for atualizada, ela irá
            sobrescrever todas as informações existentes.
          </strong>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', marginRight: 5, color: '#2595D1' }}
            type="vertical-align-bottom"
          />
          <Button
            onClick={handleExportCSVSort}
            type="link"
            className="order-timeline"
          >
            <strong style={{ textDecoration: 'underline', color: '#2595D1' }}>
              Exportar planilha de skus
            </strong>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 25 }}>
          <p style={{ marginBottom: 0 }}>
            Caso já possua o arquivo de produtos que deseja atualizar, anexe a
            planilha abaixo.
          </p>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeFile}
            fileList={CSVFile}
          >
            <Button className="ant-btn-secondary-copy">Escolher Arquivo</Button>
          </Upload>
          {CSVFile.length === 0 && (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          )}
        </Col>
        {/* <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', color: '#000' }}
            type="question-circle"
          />
          <p style={{ marginBottom: 0, marginLeft: 8 }}>
            Não conhece as regras de importação?{' '}
            <Button type="link" className="order-timeline">
              <strong style={{ textDecoration: 'underline' }}>
                Clique aqui
              </strong>
            </Button>
          </p>
        </Col> */}
      </Row>
    </>
  );

  return (
    <Modal
      title="ORDENAR PRODUTOS POR PLANILHA"
      visible={showModal}
      className="modal-csv-import-export "
      onCancel={() => {
        setShowModal(false);
        setBtnDisabled(false);
        setCSVFile([]);
      }}
      footer={[
        <Button
          key="submit"
          onClick={handleImportCSV}
          className="ant-btn ant-btn-primary"
          disabled={btnDisabled}
        >
          importar
        </Button>
      ]}
    >
      {contentModalCSV}
    </Modal>
  );
};

ModalProductsSort.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default ModalProductsSort;
