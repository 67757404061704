import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Divider,
  Button,
  Icon,
  Modal,
  Row,
  Col,
  Select,
  Form,
  Input
} from 'antd';
import build from 'redux-object';

import { getCities } from '@/src/store/modules/cities/slice';
import { getCreateRegion } from '@/src/store/modules/regions/slice';

import ModalNewCityOrDistrict from '@/src/components/Hubs/Modals/NewCityOrDistrict';

const { Option } = Select;

const ModalNewRegion = props => {
  const { states, hubData, visible, setVisible, form } = props;

  const dispatch = useDispatch();

  const citiesModalList = useSelector(state => state.cities.modalList);

  const [cities, setCities] = useState([]);
  const [modalContent, setModalContent] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (visible) {
      dispatch(
        getCities({
          query: {
            page: 1,
            perPage: 200,
            search: '',
            fields: [{ key: 'cities', value: 'name,id' }],
            filters: [
              {
                key: 'state_id',
                type: 'eq',
                value: hubData.state.id
              }
            ],
            sort: 'name'
          },
          modal: true
        })
      );
    }
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    const citiesArr = (
      (citiesModalList.meta && citiesModalList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesModalList, 'cities', object.id, { ignoreLinks: true })
    );
    setCities(citiesArr);
  }, [citiesModalList]);

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) return;
      const payload = {
        data: {
          type: 'regions',
          attributes: {
            name: values.name
          },
          relationships: {
            city: {
              data: {
                type: 'cities',
                id: values.city,
                method: 'update'
              }
            },
            hub: {
              data: {
                type: 'hubs',
                id: hubData.id,
                method: 'update'
              }
            }
          }
        }
      };
      dispatch(getCreateRegion(payload));
      setVisible(false);
    });
  };

  const handleSelectState = value => {
    form.setFieldsValue({
      city: undefined
    });
    dispatch(
      getCities({
        query: {
          page: 1,
          perPage: 100,
          search: '',
          fields: [{ key: 'cities', value: 'name,id' }],
          filters: [
            {
              key: 'state_id',
              type: 'eq',
              value
            }
          ],
          sort: 'name'
        },
        modal: true
      })
    );
  };

  const handleAddCity = () => {
    form.setFieldsValue({
      city: undefined
    });
    setModalContent('city');
  };

  return (
    <>
      {modalContent !== '' && (
        <ModalNewCityOrDistrict
          content={modalContent}
          setContent={setModalContent}
          formValues={{
            city: undefined,
            state: hubData?.state?.id
          }}
          statesList={states}
        />
      )}
      <Modal
        title={<h2>Nova Região</h2>}
        okText="Salvar"
        visible={visible}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        className="ant-modal-hubs centered-footer"
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
        destroyOnClose
        bodyStyle={{ height: 312 }}
      >
        <Form>
          <p style={{ marginBottom: 32 }}>Insira o nome da nova região</p>
          <Row>
            <Col span={24}>
              <h3>Região</h3>
              <Form.Item>
                {form.getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ]
                })(<Input placeholder="Escreva o nome" ref={inputRef} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <h3>Estado</h3>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ],
                  initialValue: hubData?.state?.id
                })(
                  <Select
                    placeholder="UF"
                    onChange={handleSelectState}
                    dropdownMatchSelectWidth={false}
                  >
                    {states.map(item => (
                      <Option key={item.code} value={item.id}>
                        {item.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <h3>Cidade</h3>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ]
                  // initialValue: hubData?.city?.id
                })(
                  <Select
                    placeholder="Cidade"
                    disabled={!form.getFieldValue('state')}
                    dropdownMatchSelectWidth={false}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '0' }} />
                        <Button
                          className="ant-btn-select"
                          onMouseDown={e => e.preventDefault()}
                          onClick={handleAddCity}
                        >
                          <Icon type="plus-circle" /> Cadastrar Cidade
                        </Button>
                      </div>
                    )}
                  >
                    {cities.map(item => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

ModalNewRegion.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.any).isRequired,
  hubData: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const ModalNewRegionForm = Form.create({ name: 'new-region' })(ModalNewRegion);

export default ModalNewRegionForm;
