import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getProducts = payload => {
  if (!payload) return apiPortal.get('/products');
  const getUrl = queryBuilder(payload, '/products');
  return apiPortal.get(getUrl);
};

export const getProductData = id => apiPortal.get(`/products/${id}`);

export const setProductData = payload =>
  apiPortal.patch(`/products/${payload.id}`, payload);

export const getProductSkuVisibility = payload => {
  return apiPortal.patch(`${payload.url}`, payload.data);
};

export const getSalechannels = payload => {
  if (!payload) return apiPortal.get('/sale_channels');
  const getUrl = queryBuilder(payload, '/sale_channels');
  return apiPortal.get(getUrl);
};
