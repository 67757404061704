import { takeEvery, put, call, select } from 'redux-saga/effects';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import normalize from 'json-api-normalizer';
// import build from 'redux-object';

import {
  setAccessories,
  setAccessory,
  setLoading,
  getAccessories,
  getAccessory,
  createAccessory,
  updateAccessory
} from '@/src/store/modules/accessories/slice';

import {
  getAccessoriesList as getAccessoriesListRequest,
  getAccessoryData as getAccessoryDataRequest,
  createAccessory as createAccessoryRequest,
  updateAccessory as updateAccessoryRequest
} from '@/src/api/accessories';

import { getAccessoriesQueryState } from '@/src/store/selectors';

function* getAccessoriesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAccessoriesListRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/accessory_type',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    const count = response.data.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(
      setAccessories({
        accessories: normalized,
        count,
        query: payload.query
      })
    );
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os acessórios');
  }
}

function* getAccessorySaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAccessoryDataRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/accessory_type',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    yield put(setAccessory(normalized));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do acessório');
  }
}

function* createAccessorySaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(createAccessoryRequest, payload);
    const query = yield select(getAccessoriesQueryState);
    const response = yield call(getAccessoriesListRequest, { query });
    const normalized = normalize(response.data, {
      endpoint: '/accessory_type',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    const count = response.data.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(
      setAccessories({
        accessories: normalized,
        count,
        query
      })
    );
    yield put(setLoading(false));
    successAlert('Novo acessório criado');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar o acessório');
  }
}

function* updateAccessorySaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(updateAccessoryRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/accessory_type',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    yield put(setAccessory(normalized));
    yield put(setLoading(false));
    successAlert('Os dados do produto foram atualizados');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi atualizar os dados do produto');
  }
}

function* accessoriesSaga() {
  yield takeEvery(getAccessories, getAccessoriesSaga);
  yield takeEvery(getAccessory, getAccessorySaga);
  yield takeEvery(createAccessory, createAccessorySaga);
  yield takeEvery(updateAccessory, updateAccessorySaga);
}

export default accessoriesSaga;
