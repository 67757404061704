import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { setOrderReturn } from '@/src/store/modules/expedition/slice';
import { linesQuery, tableQuery } from '@/src/globals/dispatchQueries';

const RejectReturnModal = ({ order, visible, setVisible }) => {
  const dispatch = useDispatch();

  const { location } = useSelector(state => state.router);

  const handleRejectReturn = () => {
    const defaultQuery =
      location.query.view === 'table' ? tableQuery : linesQuery;
    const { include, fields, extra_fields } = defaultQuery.query;
    const query = {
      include,
      fields,
      extra_fields
    };

    const payload = {
      id: order.id,
      query,
      action: 'reject',
      data: {
        data: {
          type: 'order_requests',
          id: order.orderRequests.find(item => !item.requestResolved)?.id,
          attributes: {
            rejected: true
          }
        }
      }
    };
    dispatch(setOrderReturn(payload));
    setVisible(false);
  };

  return (
    <Modal
      title="REJEITAR DEVOLUÇÃO"
      centered
      className="centered-footer"
      okText="Sim"
      cancelText="Não"
      onOk={handleRejectReturn}
      onCancel={() => setVisible(false)}
      visible={visible}
      bodyStyle={{ padding: '16px 24px 36px' }}
    >
      <p>
        Ao confirmar esta ação, o cliente não receberá estorno do pagamento e a
        nota fiscal de devolução não será gerada. O pedido seguirá no fluxo da
        Expedição.
      </p>
      <p>Deseja prosseguir?</p>
    </Modal>
  );
};

RejectReturnModal.defaultProps = {
  order: {}
};

RejectReturnModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any)
};

export default RejectReturnModal;
