import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import orderProcessingImg from '@/src/assets/images/order/order-status-processing.png';
import orderInTransitImg from '@/src/assets/images/order/order-status-handled-delivering.png';
import orderCanceledImg from '@/src/assets/images/order/order-status-canceled.png';
import orderDeliveredImg from '@/src/assets/images/order/order-status-delivered.png';
import selfOrderIcon from '@/src/assets/images/order/self-order-icon.png';
import { formatDDIPhone } from '@/src/utils/formatPhone';
import moment from '@/src/services/moment';
import styles from './StatusAndDeliverer.module.scss';

const { TabPane } = Tabs;

const OrderStatusInfo = props => {
  const { orderAttributes } = props;

  const ETA = useSelector(state => state.order.ETA);
  const ordersETA = useSelector(state => state.order.ordersETA);
  const orderStatus = useSelector(state => state.order.orderStatus);
  const orderTrip = useSelector(state => state.order.orderTrip);

  let statusInfo;

  if (orderAttributes.status === 'processing') {
    statusInfo = (
      <>
        <img src={orderProcessingImg} alt="Imagem de carrinho de compras" />
        <h2>
          PREPARANDO <br />
          PEDIDO NO HUB
        </h2>
      </>
    );
  } else if (
    orderAttributes.status === 'handled' ||
    orderAttributes.status === 'delivering'
  ) {
    statusInfo = (
      <>
        <img src={orderInTransitImg} alt="Imagem de Zee.Men na moto" />
        <h2>Em trânsito</h2>
        <p>
          {ETA ? (
            <>
              Chegada em: {orderTrip.length === 1 ? ETA : Number(ETA) + 5}{' '}
              minuto(s){' '}
            </>
          ) : null}
        </p>
      </>
    );
  } else if (orderAttributes.status === 'delivered') {
    statusInfo = (
      <>
        <img src={orderDeliveredImg} alt="Imagem do pedido entregue" />
        <h2>Pedido Entregue</h2>
        <p>Entregue às: {orderStatus.delivered[1]}</p>
      </>
    );
  } else if (orderAttributes.status === 'canceled') {
    statusInfo = (
      <>
        <img src={orderCanceledImg} alt="Imagem do pedido cancelado" />
        <h2>Pedido cancelado</h2>
        <p>
          Cancelado às:{' '}
          {orderStatus.canceled[1] ||
            orderStatus[orderStatus.currentStatus]?.[1] ||
            '-'}
        </p>
      </>
    );
  }

  const buildDeliveryEst = index => {
    const est =
      index === 0
        ? moment()
            .add(ordersETA[index], 'minutes')
            .format('LT')
        : moment()
            .add(ordersETA[index], 'minutes')
            .add(5, 'minutes')
            .format('LT');
    return est;
  };

  const buildSelfEst = () => {
    return orderTrip.length === 1
      ? moment()
          .add(ETA, 'minutes')
          .format('LT')
      : moment()
          .add(ETA, 'minutes')
          .add(5, 'minutes')
          .format('LT');
  };

  const handleOrderETAValue = etaInfo => {
    let value = '';
    if (etaInfo !== null && etaInfo !== undefined) {
      value = orderTrip.length === 1 ? etaInfo : Number(etaInfo) + 5;
    }
    return `${value} min`;
  };

  const buildDeliveries = () => {
    let listItems;
    if (orderTrip.deliveries) {
      const singleItemClass =
        orderTrip.deliveries.length === 1 ? 'delivery-box-single-item' : '';
      listItems = orderTrip.deliveries.map((delivery, index) => {
        const isSelf =
          delivery.order?.number === orderAttributes.number
            ? 'delivery-order__position--self'
            : null;
        return (
          <li key={delivery.id}>
            <div
              className={`${styles['zeeman-tab__delivery-box--order']} ${singleItemClass}`}
            >
              <div
                className={`${styles['delivery-order__position']} ${isSelf}`}
              >
                {isSelf ? (
                  <img src={selfOrderIcon} alt="Pedido do cliente" />
                ) : (
                  index + 1
                )}
              </div>
              <div className={styles['delivery-order__eta']}>
                {isSelf ? (
                  <>
                    <span className={styles['delivery-order__eta--hour']}>
                      {delivery.status === 'delivered' ? (
                        <Tooltip placement="top" title="Horário de entrega">
                          {' '}
                          {orderStatus.delivered[1]}
                        </Tooltip>
                      ) : (
                        buildSelfEst()
                      )}
                    </span>
                    <span className={styles['delivery-order__eta--time']}>
                      {delivery.status === 'delivered'
                        ? '✔'
                        : handleOrderETAValue(ETA)}
                    </span>
                  </>
                ) : (
                  <>
                    <span className={styles['delivery-order__eta--hour']}>
                      {delivery.status === 'delivered' ? (
                        <Tooltip placement="top" title="Horário de entrega">
                          {delivery.deliveredAt
                            ? moment(delivery.deliveredAt).format('LT')
                            : '-'}
                        </Tooltip>
                      ) : (
                        buildDeliveryEst(index)
                      )}
                    </span>
                    <span className={styles['delivery-order__eta--time']}>
                      {delivery.status === 'delivered'
                        ? '✔'
                        : `${
                            index === 0
                              ? ordersETA[index]
                              : ordersETA[index] + 5
                          } min`}
                    </span>
                  </>
                )}
              </div>
              <div className={styles['delivery-order__order-info']}>
                <h4>
                  {isSelf || !delivery.order?.storefrontId ? (
                    `#${delivery.order?.number}`
                  ) : (
                    <Link to={`/pedidos/${delivery.order?.storefrontId}`}>
                      #{delivery.order?.number}
                    </Link>
                  )}
                </h4>
                <p>{delivery.order?.customer?.name || '-'}</p>
                <p>{`${delivery.order?.address?.street}, ${
                  delivery.order?.address?.number
                }${
                  delivery.order?.address?.complement
                    ? ` / ${delivery.order?.address?.complement}`
                    : ' '
                }- ${delivery.order?.address?.district}, ${
                  delivery.order?.address?.city
                }`}</p>
              </div>
            </div>
          </li>
        );
      });
    }
    return listItems;
  };

  const cardContent = () => {
    return (
      <Tabs defaultActiveKey="1" className="dark-bg">
        <TabPane tab="Status" key="1">
          <main className={`${styles.Tabs__container}`}>
            <div className={styles['Tabs__container--status']}>
              {statusInfo}
            </div>
          </main>
        </TabPane>
        <TabPane tab="Entregador" key="2">
          {orderAttributes.deliverer_name ? (
            <main className={`${styles.Tabs__container}`}>
              <div className={styles['Tabs__container--zeeman']}>
                <section className={styles['zeeman-tab__header']}>
                  <div
                    className={styles['zeeman-tab__header--profile']}
                    style={{
                      backgroundImage: `url(${orderAttributes.deliverer_image_url})`
                    }}
                  />
                  <div className={styles['zeeman-tab__header--zeeman-info']}>
                    <p>{orderAttributes.deliverer_name}</p>
                    <h3>
                      {orderAttributes.deliverer_phone_number
                        ? formatDDIPhone(orderAttributes.deliverer_phone_number)
                        : ''}
                    </h3>
                  </div>
                  <div className={styles['zeeman-tab__header--hub-info']}>
                    <p>Hub {orderAttributes.hub_name || '-'}</p>
                    <h3>{orderAttributes.hub_phone || '-'}</h3>
                  </div>
                </section>
                <section
                  className={styles['zeeman-tab__header_boarding_number']}
                >
                  <div className={styles['zeeman-tab__header--hub-info']}>
                    <p>Número de embarque</p>
                    <h3>{orderAttributes.embark_id || '-'}</h3>
                  </div>
                </section>
                <section className={styles['zeeman-tab__delivery-box']}>
                  <ul>{buildDeliveries()}</ul>
                </section>
              </div>
            </main>
          ) : (
            <main className={`${styles.Tabs__container}`}>
              <div className={styles['Tabs__container--zeeman']}>
                <section
                  className={`${styles['zeeman-tab__header']} ${styles['zeeman-tab__header--no-border']}`}
                >
                  <div className={styles['zeeman-tab__header--hub-info']}>
                    <p>Hub {orderAttributes.hub_name || '-'}</p>
                    <h3>{orderAttributes.hub_phone || '-'}</h3>
                  </div>
                </section>
                <p className={styles['zeeman-tab__no-zeeman']}>
                  Ainda não há entregador selecionado para este pedido.
                </p>
              </div>
            </main>
          )}
        </TabPane>
      </Tabs>
    );
  };

  return <section className={styles.Tabs}>{cardContent()}</section>;
};

OrderStatusInfo.propTypes = {
  orderAttributes: PropTypes.shape({
    hub_name: PropTypes.string,
    address_street: PropTypes.string,
    address_number: PropTypes.string,
    address_complement: PropTypes.string,
    address_district: PropTypes.string,
    address_city: PropTypes.string,
    hub_phone: PropTypes.string,
    status: PropTypes.string,
    deliverer_name: PropTypes.string,
    deliverer_phone_number: PropTypes.string,
    deliverer_image_url: PropTypes.string,
    number: PropTypes.string,
    embark_id: PropTypes.number
  }).isRequired
};

export default OrderStatusInfo;
