import { useSelector } from 'react-redux';

const useLabelById = () => {
  const { manufacturers } = useSelector(state => state.manufacturers);
  const { hubs } = useSelector(state => state.hubs);
  const { categories } = useSelector(state => state.categories);
  const { attributes } = useSelector(state => state.attributes);

  const getElementData = (arrayCheck, id) =>
    arrayCheck.filter(item => item.id === id[0] || item.id === id.toString());

  const getLabelById = (type, id) => {
    let itemLabel = '';
    switch (type) {
      case 'manufacturer':
        itemLabel = getElementData(manufacturers, id);
        itemLabel = itemLabel[0].attributes.name;
        break;
      case 'hubs':
        itemLabel = getElementData(hubs, id);
        itemLabel = itemLabel[0]?.attributes?.name;
        break;
      case 'property':
        itemLabel = getElementData(attributes, id);
        itemLabel = itemLabel[0]?.attributes?.name;
        break;
      case 'category':
        itemLabel = getElementData(categories, id);
        itemLabel = `${itemLabel[0]?.attributes?.name} para ${itemLabel[0]?.attributes?.department_name}`;
        break;
      default:
        return false;
    }

    return itemLabel;
  };

  return { getLabelById };
};

export default useLabelById;
