import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal, Upload, Button, message, Select } from 'antd';
import PropTypes from 'prop-types';

import {
  uploadZipcodes,
  dowloadZipcodes
} from '@/src/store/modules/regions/slice';

import { handleSelectSearch } from '@/src/utils/searchUtils';

import styles from './ZipcodesData.module.scss';

const { Option } = Select;

const ModalZipcodesData = ({ content, setContent, cities }) => {
  const { id: hubId } = useParams();

  const dispatch = useDispatch();

  const [CSVFile, setCSVFile] = useState();
  const [regions, setRegions] = useState([]);
  const [regionSelect, setRegionSelect] = useState();
  const [citySelect, setCitySelect] = useState();

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const handleConfirmBtn = () => {
    if (content === 'upload') {
      dispatch(
        uploadZipcodes({ id: regionSelect.key, csv: CSVFile, hubid: hubId })
      );
      setCSVFile();
    } else {
      dispatch(dowloadZipcodes(regionSelect));
    }
    setContent('');
  };

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      return false;
    }
    setCSVFile(file);
    return false;
  };

  const removeFile = () => {
    setTimeout(() => {
      setCSVFile();
    }, 400);
  };

  const renderCitiesSelect = () => (
    <Select
      placeholder="Selecione uma cidade"
      className={styles.regionDropdown}
      // labelInValue
      onChange={value => {
        setCitySelect(value);
        setRegions(cities.find(item => item.name === value).regions);
      }}
      showSearch
      filterOption={handleSelectSearch}
      dropdownMatchSelectWidth={false}
    >
      {cities.map(item => (
        <Option key={`city-${item.name}`} value={item.name}>
          {item.name}
        </Option>
      ))}
    </Select>
  );

  const renderRegionsSelect = () => (
    <Select
      placeholder="Selecione uma região"
      className={styles.regionDropdown}
      labelInValue
      onChange={value => setRegionSelect(value)}
      showSearch
      filterOption={handleSelectSearch}
      dropdownMatchSelectWidth={false}
      disabled={!citySelect}
    >
      {regions.map(item => (
        <Option key={`region-${item.id}`} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );

  return (
    <Modal
      visible={content !== ''}
      onCancel={() => setContent('')}
      className="ant-modal-hubs centered-footer"
      footer={
        <div>
          <Button className="ant-btn-secondary" onClick={() => setContent('')}>
            Cancelar
          </Button>
          <Button
            className="ant-btn-primary"
            onClick={handleConfirmBtn}
            disabled={!regionSelect}
          >
            Confirmar
          </Button>
        </div>
      }
    >
      <div className={styles.modalUploadZip__Wrapper}>
        <h2 className={styles.modalUploadZip__Title}>
          {content === 'upload' ? 'Atualização de CEPs' : 'Exportar CEPs'}
        </h2>
        <p style={{ marginTop: content === 'upload' ? 0 : 16 }}>
          {content === 'upload'
            ? 'Escolha a cidade e a região para atualizar a lista de CEPs'
            : 'Escolha a cidade e a região para exportar a lista de CEPs'}
        </p>
        {renderCitiesSelect()}
        {renderRegionsSelect()}
        {content === 'upload' && (
          <>
            <p>
              Caso algum dos ceps informados não exista na base, o mesmo será
              cadastrado durante esse processo.
            </p>
            <p>
              Se algum cep já estiver associado a outra região, ele será
              desassociado da região anterior.
            </p>
            <Upload
              className={styles.modalUploadZip__UploadWrapper}
              beforeUpload={beforeUpload}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onRemove={removeFile}
            >
              <Button className="ant-btn-secondary-copy">
                Escolher Arquivo
              </Button>
            </Upload>
          </>
        )}
      </div>
    </Modal>
  );
};

ModalZipcodesData.propTypes = {
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ModalZipcodesData;
