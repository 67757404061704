import React from 'react';
import { Menu, Dropdown, Icon, Tag, Button } from 'antd';
import PropTypes from 'prop-types';

import Tags from '@/src/components/Tags/index';

import isImmutablePromo from '@/src/utils/isImmutablePromo';

import moment from '@/src/services/moment';
import styles from './PromotionCard.module.scss';

const statusObj = {
  expiring: 'A Vencer',
  expired: 'Expirada',
  active: 'Ativa',
  archived: 'Arquivada'
};

const PromotionCard = props => {
  const {
    promotion,
    goToPromo,
    archivePromotion,
    promoId,
    unarchivePromotion,
    duplicatePromotion,
    tag
  } = props;

  // Treat the array of tags associated with your particular promotion
  const arrayTags = [];
  tag.map(tags => arrayTags.push(tags[0]));
  const arrayObj = arrayTags
    .map(obj => Object.values(obj))
    .map(array => array[1]);

  // treate arraObj(tags) value for Tag component
  const tagSlice = arrayObj.slice(0, 3);
  const valueTagRest = arrayObj.length > 3 ? arrayObj.length - 3 : 0;
  const tagRest = arrayObj.slice(3);

  /**
   * Replace in tagRest. [Tags] > 3 dont appear on promotioncard, just your length
   * Replace to show tooltip of rest value of tags that doesnt show up on promotioncard
   */
  const tagsRestReplace = [];
  tagRest.map((rest, index) => {
    if (index !== 0) tagsRestReplace.push(rest.replace('', ', '));
    if (index === 0) tagsRestReplace.unshift(rest);
    return tagsRestReplace;
  });

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => archivePromotion({ promoId })}>
        Arquivar
      </Menu.Item>

      <Menu.Item key="1" onClick={() => duplicatePromotion({ promoId })}>
        Duplicar
      </Menu.Item>
    </Menu>
  );

  const menuArchived = (
    <Menu>
      <Menu.Item key="0" onClick={() => unarchivePromotion({ promoId })}>
        Desarquivar
      </Menu.Item>
    </Menu>
  );

  const archivedDropdown = (
    <Dropdown overlay={menuArchived}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const buildPromoAction = () => {
    return promotion.status === 'archived' ? (
      archivedDropdown
    ) : (
      <Dropdown overlay={menu}>
        <span>
          <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
        </span>
      </Dropdown>
    );
  };

  return (
    <>
      <div
        className={`${styles.PromotionCard} ${
          isImmutablePromo(promotion.promotion_type) ? styles.system : ''
        }`}
      >
        <div className={styles.PromotionCard__Header}>
          <Tag className={`squared ${promotion.status}`}>
            {statusObj[promotion.status]}
          </Tag>
          {isImmutablePromo(promotion.promotion_type)
            ? null
            : buildPromoAction()}
        </div>
        <div className={styles.PromotionCard__Content}>
          <h3 title={promotion.name}>
            <Button type="link" onClick={() => goToPromo(promotion.id)}>
              {promotion.name}
            </Button>
          </h3>
          <div style={{ display: 'flex' }}>
            <p>
              {promotion.coupons_count}{' '}
              {promotion.coupons_count === 1 ? 'cupom' : 'cupons'}
            </p>
            {promotion.coupons_found ? (
              <p className={styles.FoundPromotion}>
                {promotion.coupons_found > 1
                  ? `${promotion.coupons_found} cupons encontrados`
                  : `${promotion.coupons_found} cupom encontrado`}
              </p>
            ) : null}
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <Tags
            key={tag}
            tags={tagSlice}
            plusTag
            valueTagsPlus={valueTagRest}
            restValueTagsPlus={tagsRestReplace}
          />
        </div>
        <div className={styles.PromotionCard__Footer}>
          <div>
            <h4>Início</h4>
            <p>
              {promotion.start_at
                ? moment(promotion.start_at).format('DD/MM/YYYY - HH:mm')
                : 'Desde sempre'}
            </p>
          </div>
          <div>
            <h4>Fim</h4>
            <p>
              {promotion.end_at
                ? moment(promotion.end_at).format('DD/MM/YYYY - HH:mm')
                : 'Para sempre'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

PromotionCard.defaultProps = {
  promotion: {},
  goToPromo: () => {},
  archivePromotion: () => {},
  unarchivePromotion: () => {},
  duplicatePromotion: () => {},
  promoId: '',
  tag: []
};

PromotionCard.propTypes = {
  goToPromo: PropTypes.func,
  promoId: PropTypes.string,
  archivePromotion: PropTypes.func,
  unarchivePromotion: PropTypes.func,
  duplicatePromotion: PropTypes.func,
  promotion: PropTypes.shape({
    status: PropTypes.string,
    promotion_type: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    id: PropTypes.number,
    coupons_count: PropTypes.number,
    coupons_found: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  tag: PropTypes.arrayOf(PropTypes.any)
};

export default PromotionCard;
