import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import {
  setIsLoading,
  setDistricts,
  setCreateDistrict,
  getDistricts,
  getCreateDistrict
  // setDistrictSingle,
  // getDistrictSingle
} from '@/src/store/modules/districts/slice';

import {
  getDistrictsData,
  getCreateDistrictData
  // getDistrictSingleData
} from '@/src/api/districts';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* setDistrictsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getDistrictsData, payload);
    const normalized = normalize(response.data, { endpoint: '/districts' });
    yield put(setDistricts(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar a lista de bairros');
  }
}

// function* setDistrictSingleSaga({ payload }) {
//   try {
//     yield put(setIsLoading(true));
//     const response = yield call(getDistrictSingleData, payload);
//     yield put(setDistrictSingle(response.data.data));
//     yield put(setIsLoading(false));
//   } catch (err) {
//     errorAlert(err, 'Não foi possível carregar os dados do bairro');
//   }
// }

function* setCreateDistrictSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCreateDistrictData, payload);
    const normalized = normalize(response.data, { endpoint: '/new-district' });
    yield put(setCreateDistrict(normalized));
    yield put(setIsLoading(false));
    successAlert('Novo bairro cadastrado');
  } catch (err) {
    errorAlert(err);
  }
}

function* districtListSaga() {
  yield takeEvery(getDistricts, setDistrictsSaga);
  yield takeEvery(getCreateDistrict, setCreateDistrictSaga);
  // yield takeEvery(getDistrictSingle, setDistrictSingleSaga);
}

export default districtListSaga;
