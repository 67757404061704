import { createSlice } from '@reduxjs/toolkit';
// import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  products: [],
  query: {
    page: 1,
    perPage: 20,
    search: ''
  },
  count: 0,
  isLoading: false,
  salechannels: []
};

const productsSlice = createSlice({
  name: '@products',
  initialState,
  reducers: {
    setProducts(prevState, action) {
      const state = prevState;
      state.products = action.payload.data;
      state.count = action.payload.meta?.count || 0;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = {
        page: action.payload.page || state.query.page,
        perPage: action.payload.perPage || state.query.perPage,
        search: action.payload.search || ''
        // filters: action.payload.query?.filters || {}
        // url: `?page=${action.payload.query?.page || '1'}&per_page=${action
        //   .payload.query?.perPage || state.query.perPage}${
        //   action.payload.query?.filters
        //     ? buildQueryStringFromObj(action.payload.query?.filters)
        //     : ''
        // }`
      };
      return state;
    },
    setSalechannels(prevState, action) {
      const state = prevState;
      state.salechannels = action.payload;
      return state;
    },
    getProducts(state) {
      return state;
    },
    getSalechannels(state) {
      return state;
    }
  }
});

export const {
  setProducts,
  setIsLoading,
  setQuery,
  setSalechannels,
  getProducts,
  getSalechannels
} = productsSlice.actions;

export default productsSlice.reducer;
