import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

const getHubs = payload => {
  let url = '/hubs?page=1&per_page=1000';
  if (payload?.query) url = queryBuilder(payload, '/hubs');
  return apiPortal.get(url);
};

export default getHubs;
