import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

const initialState = {
  list: {
    meta: {
      '/districts': {
        data: []
      }
    }
  },
  isLoading: false
  // district: {},
};

const districtSlice = createSlice({
  name: '@districtData',
  initialState,
  reducers: {
    resetDistrictsState() {
      const state = {
        ...initialState
      };
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setDistricts(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    // setDistrictSingle(prevState, action) {
    //   const state = prevState;
    //   state.district = action.payload;
    //   return state;
    // },
    setCreateDistrict(prevState, action) {
      const state = prevState;
      state.list = merge(state.list, action.payload);
      state.list.meta['/districts'].data = [
        ...state.list.meta['/districts'].data,
        ...action.payload.meta['/new-district'].data
      ];
      return state;
    },
    getDistricts(state) {
      return state;
    },
    // getDistrictSingle(state) {
    //   return state;
    // },
    getCreateDistrict(state) {
      return state;
    }
  }
});

export const {
  resetDistrictsState,
  setIsLoading,
  setDistricts,
  setCreateDistrict,
  getDistricts,
  getCreateDistrict
  // setDistrictSingle,
  // getDistrictSingle
} = districtSlice.actions;

export default districtSlice.reducer;
