import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getCatalogAttributes = payload => {
  const getUrl = queryBuilder(payload, '/properties', true);
  return apiPortal.get(getUrl);
};
export const getDepartments = () => apiPortal.get(`/departments`);

export const getCreateCategory = payload => {
  return new Promise((resolve, reject) => {
    apiPortal
      .post(`/categories`, payload)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getAllCategories = payload => {
  const getUrl = queryBuilder(payload, '/categories');
  return apiPortal.get(getUrl);
};

export const inactiveOrActiveCategory = payload =>
  apiPortal.patch(`/categories/${payload.id}`, { status: payload.status });

export const getEdit = payload =>
  apiPortal.get(`/categories/${Number(payload)}`);

export const archiveandUnarchive = payload =>
  payload.type === 'archive'
    ? apiPortal.post(`/categories_archivation`, { ids: [payload.id] })
    : apiPortal.delete(`/categories_archivation`, {
        data: { ids: [payload.id] }
      });

export const orderByDragNDrop = payload => {
  return apiPortal.put(`/categories_sorting`, { ids: payload });
};

export const updateCategory = payload => {
  return apiPortal.patch(`/categories/${payload.categoryId}`, payload.data);
};
