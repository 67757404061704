import { takeEvery, put, call, select } from 'redux-saga/effects';
import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setReferrals,
  setReferralsIsLoading,
  setReferralsGraphsIsLoading,
  setReferralsMetadata,
  setReferralsGraphs,
  getReferrals,
  invalidateReferral,
  banishReferral,
  getReferralsGraphs,
  getReferralsMetadata
} from '@/src/store/modules/referrals/slice';

import {
  banishReferralCustomer,
  activateCustomerOnReferral,
  setCustomer
} from '@/src/store/modules/customers/slice';

import {
  getReferrals as getReferralsRequest,
  invalidateReferral as invalidateReferralRequest,
  getReferralsMetadata as getReferralsMetadataRequest,
  getReferralsGraphs as getReferralsGraphsRequest,
  banishReferral as banishReferralRequest
} from '@/src/api/referrals';
import {
  banishReferralCustomer as banishReferralCustomerRequest,
  activateCustomerOnReferral as activateCustomerOnReferralRequest
} from '@/src/api/customers';

import {
  getReferralsState,
  getReferralsTotal,
  getReferralsQuery
} from '@/src/store/selectors';

import findObjectAndReturnNewArray from '@/src/utils/findObjectInArrayAndChange';

function* getReferralsSaga(params) {
  const { payload } = params;
  try {
    yield put(setReferralsIsLoading(true));
    const response = yield call(getReferralsRequest, payload);
    yield put(
      setReferrals({
        referrals: response.data.data,
        query: payload.query,
        total: response.data.meta.total,
        count: response.data.meta.count
      })
    );
  } catch (err) {
    yield put(setReferralsIsLoading(false));
    errorAlert(err);
  }
}

function* getReferralsMetadataSaga() {
  try {
    const response = yield call(getReferralsMetadataRequest);
    yield put(
      setReferralsMetadata({
        metadata: response.data.meta
      })
    );
  } catch (err) {
    errorAlert(err);
  }
}

function* getReferralsGraphsSaga() {
  try {
    yield put(setReferralsGraphsIsLoading());
    const response = yield call(getReferralsGraphsRequest);
    yield put(
      setReferralsGraphs({
        graphs: response.data
      })
    );
  } catch (err) {
    errorAlert(err);
  }
}

function* invalidateReferralsSaga(params) {
  const { payload } = params;
  try {
    yield put(setReferralsIsLoading(true));
    const response = yield call(invalidateReferralRequest, payload);
    const responseData = yield response.data.data;
    const actualReferrals = yield select(getReferralsState);
    const referralsTotal = yield select(getReferralsTotal);
    const referralsQuery = yield select(getReferralsQuery);
    const newReferralsArr = yield findObjectAndReturnNewArray(
      actualReferrals,
      'id',
      responseData
    );
    yield put(
      setReferrals({
        referrals: newReferralsArr,
        query: referralsQuery,
        total: referralsTotal
      })
    );
    successAlert('Indicação invalidada com sucesso');
    yield put(setReferralsIsLoading(false));
  } catch (err) {
    yield put(setReferralsIsLoading(false));
    errorAlert(err);
  }
}

function* banishReferralsSaga(params) {
  const { payload } = params;
  const query = {
    query: {
      page: 1,
      perPage: 20,
      search: '',
      url: ''
    }
  };
  try {
    yield put(setReferralsIsLoading(true));
    yield call(banishReferralRequest, payload);
    yield put({ type: 'GET_REFERRALS', payload: query });
    successAlert('Usuário banido de indicações');
    yield put(setReferralsIsLoading(false));
  } catch (err) {
    yield put(setReferralsIsLoading(false));
    errorAlert(err);
  }
}

function* banishReferralCustomerSaga(action) {
  const { payload } = action;
  try {
    yield put(setReferralsIsLoading(true));
    const response = yield call(banishReferralCustomerRequest, payload);
    const metadata = response.data.meta;
    yield put(setCustomer({ customer: response.data.data, metadata }));
    yield put(setReferralsIsLoading(false));
    successAlert('O cliente foi banido de Indicações');
  } catch (err) {
    yield put(setReferralsIsLoading(false));
    errorAlert(err);
  }
}

function* activateCustomerOnReferralSaga(action) {
  const { payload } = action;
  try {
    yield put(setReferralsIsLoading(true));
    const response = yield call(activateCustomerOnReferralRequest, payload.id);
    const metadata = response.data.meta;
    yield put(setCustomer({ customer: response.data.data, metadata }));
    yield put(setReferralsIsLoading(false));
    successAlert('O cliente foi reativado para Indicações');
  } catch (err) {
    yield put(setReferralsIsLoading(false));
    errorAlert(err);
  }
}

function* referralsSaga() {
  yield takeEvery(getReferrals, getReferralsSaga);
  yield takeEvery(invalidateReferral, invalidateReferralsSaga);
  yield takeEvery(banishReferral, banishReferralsSaga);
  yield takeEvery(getReferralsMetadata, getReferralsMetadataSaga);
  yield takeEvery(getReferralsGraphs, getReferralsGraphsSaga);
  yield takeEvery(banishReferralCustomer, banishReferralCustomerSaga);
  yield takeEvery(activateCustomerOnReferral, activateCustomerOnReferralSaga);
  yield takeEvery('GET_REFERRALS', getReferralsSaga);
}

export default referralsSaga;
