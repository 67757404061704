import React from 'react';
// import { Link } from 'react-router-dom';
import moment from '@/src/services/moment';
// import Edit from '@/src/assets/images/edit.svg';
import Delete from '@/src/assets/images/delete.svg';

import StatusPill from '@/src/components/StatusPill';

import formatCurrency from '@/src/utils/formatCurrency';

export const newAndEditShipping = [
  {
    title: 'Número Pedido',
    key: 'number',
    align: 'left',
    dataIndex: 'number'
  },
  {
    title: 'Cliente',
    key: 'name',
    align: 'left',
    dataIndex: 'customerName'
  },
  {
    title: 'Volumes',
    key: 'volumes',
    dataIndex: 'volumeCount'
  },
  {
    title: 'Preço',
    key: 'price',
    className: 'text-bold',
    render: data => <span>{formatCurrency(data.total, true)}</span>
  }
];

const shipping = [
  {
    title: 'ID Embarque',
    align: 'left',
    key: 'id',
    render: record => (
      <span style={{ textDecoration: 'underline' }}>{record.id}</span>
    ),
    sorter: true
  },
  {
    title: 'Data',
    align: 'left',
    key: 'created_at',
    sorter: true,
    render: record => moment(record.createdAt).format('DD/MM/YYYY')
  },
  {
    title: 'Transportadora',
    align: 'left',
    dataIndex: 'deliveryCompany.name'
  },
  {
    title: 'Tipo de Frete',
    align: 'left',
    dataIndex: 'deliveryKind.name'
  },
  {
    title: 'Hub',
    align: 'left',
    dataIndex: 'deliveries[0].order.hub.name'
  },
  {
    title: 'Qnt de Pedidos',
    align: 'left',
    key: 'deliveries',
    render: record => <>{record.deliveries.length}</>,
    sorter: (a, b) => a.deliveries.length - b.deliveries.length
  },
  {
    title: 'Situação',
    align: 'left',
    key: 'status',
    // colSpan: 2,
    render: (text, record) => <StatusPill status={record.status} centered />
  },
  {
    key: 'action',
    // colSpan: 0,
    render: (text, record) => {
      return (
        <div className="tableEditDelete">
          {/* <Link to={`/embarques/${record.id}`}>
            <img src={Edit} alt="Edit Value" />
          </Link> */}
          <button
            type="button"
            className="tableEditDelete__delete"
            onClick={e => {
              e.stopPropagation();
              record.handleShowModal(record.id);
            }}
          >
            <img src={Delete} alt="Delete Value" />
          </button>
        </div>
      );
    }
  }
];

export default shipping;
