import { apiCatalog } from '@/src/api/config';
import queryBuilder from '../utils/queryBuilder';

export const getListPriceInfoRequest = payload => {
  const getUrl = queryBuilder(payload, '/hub/prices/info');

  return apiCatalog.get(getUrl);
};

export default getListPriceInfoRequest;
