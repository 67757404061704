import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

const getCategories = payload => {
  const getUrl = queryBuilder(payload, '/categories');
  return apiPortal.get(getUrl);
};

export const getCategoryData = id => apiPortal.get(`/categories/${id}`);

export default getCategories;
