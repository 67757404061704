import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import {
  Row,
  Col,
  Form,
  Input,
  Icon,
  Select,
  Button,
  Card,
  Dropdown,
  Menu,
  Modal,
  Tooltip,
  Spin,
  Avatar,
  message,
  Upload,
  Breadcrumb,
  Switch
} from 'antd';
import PropTypes from 'prop-types';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';
import ModalCSV from '@/src/components/ModalCSV';
import Audit from '@/src/components/Audit/index';
import UploadImage from '@/src/components/UploadImage';

import {
  getProductsByCategory,
  getAddProductsToSave,
  getDeleteProducts,
  getEditAisle,
  archiveAisle,
  unarchiveAisle,
  getUpdateAisle,
  sendCSVOrderBy,
  exportCSVAisle,
  setAisles
} from '@/src/store/modules/aisles/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getDepartments } from '@/src/store/modules/departments/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

import './editAisle.scss';

const { OptGroup, Option } = Select;
const { confirm } = Modal;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

const EditAisle = props => {
  const {
    form: { getFieldDecorator, validateFields, setFieldsValue }
  } = props;

  const [isSubcategory, setIsSubcategory] = useState(false);

  const [showAudit, setShowAudit] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);

  const [editAisleProducts, setEditAisleProducts] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [aisleProducts, setAisleProducts] = useState([]);

  const [selectedAisleProducts, setSelectedAisleProducts] = useState([]);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);
  const [
    selectedCategoryProductsDetails,
    setSelectedCategoryProductsDetails
  ] = useState([]);

  const [editDrag, setEditDrag] = useState(false);
  const [editDragGreaterThan25, setEditDragGreaterThan25] = useState(false);

  const [csvDisabled, setCsvDisabled] = useState(false);
  const [uploadCsvDisabled, setUploadCsvDisabled] = useState(false);
  const [csvFile, setCsvFile] = useState([]);
  const [csvFileFail, setCsvFileFail] = useState(true);

  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);

  const typeTableModal = 'newAisleModal';

  const dispatch = useDispatch();
  const { id } = useParams();

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const {
    aisle,
    products,
    productsCount,
    productsQuery,
    addedProducts,
    isLoading,
    isEditLoading
  } = useSelector(state => state.aisles);

  const { categories } = useSelector(state => state.categories);
  const { departments } = useSelector(state => state.departments);

  let column = [
    {
      dataIndex: 'attributes.image',
      render: record => (
        <Avatar
          shape="square"
          size={60}
          src={record}
          icon="camera"
          className="img-placeholder"
        />
      ),
      width: '1%'
    },
    {
      title: 'Produto',
      dataIndex: 'attributes.name'
    }
  ];
  if (editDrag && catalogPermission) {
    column = [
      {
        dataIndex: 'sort',
        width: 30,
        render: () => <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
      },
      {
        dataIndex: 'attributes.image',
        render: record => (
          <Avatar
            shape="square"
            size={60}
            src={record}
            icon="camera"
            className="img-placeholder"
          />
        ),
        width: '1%'
      },
      {
        title: 'Produto',
        dataIndex: 'attributes.name'
      }
    ];
  }

  const disabledProduct = (
    <div className="disabled-hall">
      <Icon style={{ marginLeft: 6 }} type="info-circle" />
      <p style={{ marginBottom: 0, marginLeft: 5 }}>
        Escolha uma categoria para adicionar produtos.
      </p>
    </div>
  );

  const rowSelectionModal = {
    selectedRowKeys: selectedCategoryProducts,
    onChange: (selectedRowKeys, selectedRowObj) => {
      setSelectedCategoryProducts(selectedRowKeys);
      setSelectedCategoryProductsDetails(prev => {
        const newArr = [...prev, ...selectedRowObj];
        const ids = newArr.map(o => o.id);
        const filtered = newArr.filter(
          (item, index) => !ids.includes(item.id, index + 1)
        );
        return filtered;
      });
    },

    getCheckboxProps: record => ({
      disabled: record.attributes.present_on_aisle
    })
  };

  const rowSelectionTable = {
    selectedRowKeys: selectedAisleProducts,
    onChange: selectedRowKeys => setSelectedAisleProducts(selectedRowKeys)
  };

  const saveAllConfirm = value => {
    confirm({
      title: 'SALVAR ALTERAÇÕES NO APP',
      okText: 'Confirmar',
      centered: true,
      content:
        'Tem certeza que deseja salvar as alterações? Todas as mudanças feitas serão espelhadas no app.',
      onOk() {
        const image = uploadImage?.includes('data:image')
          ? uploadImage.replace(/data:image\/[\w]+;base64,/g, '')
          : '';
        const productsAdded = aisleProducts.map(product => product.id);
        dispatch(
          getUpdateAisle({
            ...value,
            search_metadata: value.search_metadata || '',
            products_id: productsAdded,
            category_id: categoryId,
            image,
            id,
            title: value.title || '',
            meta_title: value.meta_title || '',
            meta_description: value.meta_description || ''
          })
        );
      },
      onCancel() {}
    });
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        saveAllConfirm(values);
      }
    });
  };

  const handleCloseProductsModal = () => {
    setShowProductsModal(false);
    setSelectedCategoryProducts([]);
    setSelectedCategoryProductsDetails([]);
  };

  const handleOkProductsModal = () => {
    const itemsToSave = selectedCategoryProductsDetails.filter(product =>
      selectedCategoryProducts.includes(product.id)
    );
    dispatch(getAddProductsToSave(itemsToSave));
    handleCloseProductsModal();
    setCsvDisabled(true);
  };

  const handleCategorySelect = value => {
    setCategoryId(value);
    setCsvDisabled(!value);
  };

  const handleDeleteAisleProducts = items => {
    const itemsToDelete = addedProducts.filter(tableProducts =>
      items.includes(tableProducts.id)
    );
    dispatch(getDeleteProducts(itemsToDelete));
    setSelectedAisleProducts([]);
    if (addedProducts.length === itemsToDelete.length) {
      setAisleProducts([]);
    }
  };

  const handleGetCategoryProducts = useCallback(
    urlQuery => {
      const category = categoryId || aisle?.attributes?.category_id;
      dispatch(getProductsByCategory({ ...urlQuery, category }));
    },
    [dispatch, categoryId, aisle]
  );

  const handleArchiveAisle = () => {
    dispatch(archiveAisle(id));
  };

  const handleUnarchiveAisle = () => {
    dispatch(unarchiveAisle(id));
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      {!aisle?.attributes?.archived_at ? (
        <Menu.Item key="1" onClick={handleArchiveAisle}>
          Arquivar
        </Menu.Item>
      ) : (
        <Menu.Item key="2" onClick={handleUnarchiveAisle}>
          Desarquivar
        </Menu.Item>
      )}
    </Menu>
  );

  const actionsAisle = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = aisleProducts[dragIndex];
      setAisleProducts(
        update(aisleProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [aisleProducts]
  );

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCsvType = file => conditions.some(el => file.name.includes(el));

  const beforeCsvUpload = file => {
    setCsvFile([file]);
    const check = checkCsvType(file);
    if (!check) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setCsvFileFail(true);
      return false;
    }
    setCsvFileFail(false);
    return false;
  };

  const removeCsvFile = () => {
    setUploadCsvDisabled(false);
    setTimeout(() => {
      setCsvFile([]);
    }, 400);
    setCsvFileFail(true);
  };

  const handleImportCsv = useCallback(() => {
    dispatch(sendCSVOrderBy({ type: 'edit', id, csv: csvFile }));
    setShowCsvModal(false);
  }, [dispatch, csvFile, id]);

  const handleExportCsv = useCallback(() => {
    dispatch(exportCSVAisle({ type: 'edit', id }));
  }, [dispatch, id]);

  const contentModalCSV = (
    <>
      <Row>
        <Col style={{ marginBottom: 25 }}>
          <p style={{ marginBottom: 0 }}>
            Caso já possua o arquivo de produtos que deseja atualizar, no
            corredor para a categoria selecionada, anexe a planilha abaixo.
          </p>
        </Col>
        <Col
          style={{ display: 'flex', alignItems: 'center', marginBottom: 25 }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeCsvUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeCsvFile}
          >
            <Button
              disabled={uploadCsvDisabled}
              className="ant-btn-secondary-copy"
            >
              Escolher Arquivo
            </Button>
          </Upload>
          {csvFile.length === 0 ? (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          ) : null}
        </Col>
        {/* {csvFileFail && (
          <p style={{ marginTop: 5, textAlign: 'center', color: '#d03232' }}>
            Apenas arquivos do tipo CSV são aceitos
          </p>
        )} */}
        {/* <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', color: '#000' }}
            type="question-circle"
          />
          <p style={{ marginBottom: 0, marginLeft: 8 }}>
            Não conhece as regras de importação?{' '}
            <Button type="link" className="order-timeline">
              <strong style={{ textDecoration: 'underline' }}>
                Clique aqui
              </strong>
            </Button>
          </p>
        </Col> */}
      </Row>
      <Row
        style={{
          borderBottom: '3px ##E2E2E2 solid',
          borderTop: '3px ##E2E2E2 solid'
        }}
      >
        {/* <Col style={{ marginBottom: 10 }}>
          <p>
            Você selecionou a categoria{' '}
            <strong style={{ textDecoration: 'underline' }}>
              {categoryData?.attributes?.name} para{' '}
              {categoryData?.attributes?.department_name.toLowerCase()}.
            </strong>
          </p>
        </Col> */}
        <Col style={{ marginBottom: 10 }}>
          <p>
            Para uma configuração inicial exporte todos produtos da categoria,
            ou para edição exporte produtos atuais do corredor para adição e/ou
            ordenação de novos produtos.
          </p>
        </Col>
        <Col
          style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
        >
          <Icon
            style={{ fontSize: '20px', marginRight: 5, color: '#2595D1' }}
            type="vertical-align-bottom"
          />
          <Button
            type="link"
            onClick={handleExportCsv}
            className="order-timeline"
          >
            <strong style={{ textDecoration: 'underline', color: '#2595D1' }}>
              Exportar produtos atuais do corredor
            </strong>
          </Button>
        </Col>
        <Col>
          <strong>
            *Importante: sempre que uma planilha for atualizada, ela irá
            sobrescrever todas as informações existentes.
          </strong>
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    if (csvFile.length === 1) {
      setUploadCsvDisabled(true);
    } else {
      setUploadCsvDisabled(false);
    }
  }, [csvFile.length]);

  useEffect(() => {
    if (addedProducts.length > 1) {
      setEditDrag(!(addedProducts.length > 25));
      setEditDragGreaterThan25(addedProducts.length > 25);
    } else {
      setEditDrag(false);
      setEditDragGreaterThan25(false);
    }
    setAisleProducts(addedProducts);
  }, [addedProducts]);

  useEffect(() => {
    setFieldsValue({
      name: aisle?.attributes?.name,
      search_metadata: aisle?.attributes?.search_metadata,
      search_slug: aisle?.attributes?.search_slug,
      title: aisle?.attributes?.title,
      meta_title: aisle?.attributes?.meta_title,
      meta_description: aisle?.attributes?.meta_description,
      subcategory: aisle?.attributes?.subcategory,
      category_id:
        `${aisle?.attributes?.category_name}` +
        ' para ' +
        `${aisle?.attributes?.department_name?.toLowerCase() || ''}`
    });
    setCategoryId(aisle?.attributes?.category_id);
    setIsSubcategory(aisle?.attributes?.subcategory);
    setUploadImage(aisle?.attributes?.image || []);
    // eslint-disable-next-line
  }, [aisle]);

  useEffect(() => {
    if (selectedAisleProducts.length > 0) {
      setEditAisleProducts(true);
    } else {
      setEditAisleProducts(false);
    }
  }, [selectedAisleProducts]);

  useEffect(() => {
    dispatch(getEditAisle({ id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(
      getCategories({
        query: {
          sort: '',
          page: 1,
          perPage: 200,
          search: ''
        }
      })
    );
    dispatch(
      setAisles({
        aisles: [],
        query: {},
        count: 0
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleAisleProductsSearch = value => {
    const search = value.query?.search?.toLowerCase();
    if (search) {
      setAisleProducts(() => {
        const filtered = addedProducts.filter(item => {
          const name = item.attributes.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          return name.includes(search.toLowerCase());
        });
        return filtered;
      });
    } else {
      setAisleProducts(addedProducts);
    }
  };

  return (
    <>
      <Audit
        id={id}
        title="CORREDOR"
        type="Aisle"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <ModalCSV
        titleModal="CONFIGURAR PRODUTOS POR PLANILHA"
        isVisible={showCsvModal}
        setIsVisibleModalCSV={() => setShowCsvModal(false)}
        components={contentModalCSV}
        buttonDisabled={csvFileFail}
        CSVFileHandle={handleImportCsv}
      />
      <Modal
        centered
        title="ADICIONAR PRODUTOS"
        visible={showProductsModal}
        className="user-logTable"
        width={900}
        onOk={() => {}}
        onCancel={() => handleCloseProductsModal()}
        footer={[
          <Button
            key="submit"
            onClick={handleOkProductsModal}
            className="ant-btn ant-btn-primary"
          >
            ADICIONAR
          </Button>
        ]}
      >
        <Searchbar
          placeholder="Buscar produto na categoria selecionada"
          populateResult={handleGetCategoryProducts}
          query={productsQuery}
          value={productsQuery.search}
        />
        <p style={{ marginTop: 20, marginBottom: 4, fontWeight: 'bold' }}>
          {selectedCategoryProducts.length} produtos selecionados
        </p>
        <span style={{ marginBottom: 12, display: 'block' }}>
          *Produtos já adicionados não podem ser selecionados novamente
        </span>
        <Tables
          data={products}
          contentToShow={typeTableModal}
          rowKey={record => record.id}
          rowSelection={rowSelectionModal}
          rowClassName={record =>
            record?.attributes.present_on_aisle && 'disabled'
          }
          isLoading={isLoading}
          hasScroll
          query={productsQuery}
          count={productsCount}
          visiblePaginationUp
          populateTables={handleGetCategoryProducts}
        />
      </Modal>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1 className="mb-20">Editar {aisle?.attributes?.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/corredores">Corredores</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar corredor</Breadcrumb.Item>
      </Breadcrumb>
      <section
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          marginBottom: 60
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES"
          extra={catalogPermission && actionsAisle}
          loading={isEditLoading}
        >
          <Form style={{ marginTop: 10 }}>
            <Row gutter={24}>
              <Col span={12}>
                <Row
                  style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Nome</p>
                    <Tooltip
                      placement="right"
                      title="Nome do corredor que irá aparecer no app"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o nome do corredor'
                        }
                      ]
                    })(
                      <Input
                        placeholder="Nome do corredor"
                        disabled={!catalogPermission}
                      />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Palavras-chave
                    </p>
                    <Tooltip
                      placement="right"
                      title="Palavras que facilitam encontrar produtos pelo cliente através da busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('search_metadata')(
                      <Input.TextArea
                        placeholder="Ex.: novo, corredor"
                        disabled={!catalogPermission}
                      />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Categoria
                    </p>
                    <Tooltip
                      placement="right"
                      title="Um corredor deve estar atrelado a apenas uma categoria de um departamento"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 2 }}>
                    {getFieldDecorator('category_id')(
                      <Select
                        placeholder="Selecione uma categoria"
                        disabled={
                          aisleProducts.length > 0 || !catalogPermission
                        }
                        onSelect={handleCategorySelect}
                      >
                        {departments.map(item => {
                          const categoriesArr = categories.filter(
                            obj =>
                              obj.attributes.department_id === Number(item.id)
                          );
                          return (
                            <OptGroup
                              label={item.attributes.name}
                              key={`department-${item.id}`}
                            >
                              {categoriesArr?.map(obj => {
                                return (
                                  <Option key={Number(obj.attributes.id)}>
                                    {obj.attributes.name} para{' '}
                                    {item.attributes.name}
                                  </Option>
                                );
                              })}
                            </OptGroup>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  {aisleProducts.length > 0 && (
                    <span style={{ marginBottom: 24 }}>
                      *Caso queira trocar de categoria, todos os produtos
                      adicionados abaixo devem ser excluídos
                    </span>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Slug</p>
                    <Tooltip
                      placement="right"
                      title="URL da página do corredor na web"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('search_slug')(
                      <Input placeholder="Slug do corredor" disabled />
                    )}
                  </Form.Item>
                </Row>
                <Row style={{ width: '80%', marginBottom: 16 }}>
                  <h2>SEO (WEB)</h2>
                </Row>
                <Row
                  style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 0
                      }}
                    >
                      Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido na página do corredor"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 2 }}>
                    {getFieldDecorator('title')(
                      <Input
                        placeholder="Título da vitrine"
                        disabled={!catalogPermission}
                      />
                    )}
                  </Form.Item>
                  <span style={{ marginBottom: 24 }}>
                    *Caso não preenchido, será o nome do corredor
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      Meta Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_title')(
                      <Input
                        placeholder="Exemplo: Zee.Now - Nome do corredor"
                        disabled={!catalogPermission}
                      />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      Meta Description
                    </p>
                    <Tooltip
                      placement="right"
                      title="Descrição da vitrine exibida em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_description')(
                      <Input.TextArea
                        placeholder="Breve descrição do corredor"
                        disabled={!catalogPermission}
                      />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      É subcategoria?
                    </p>
                    <Tooltip
                      placement="right"
                      title="Selecione caso corredor seja uma subcategoria exibida no menu do site"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                    <Form.Item style={{ marginBottom: 0, marginLeft: 16 }}>
                      {getFieldDecorator('subcategory')(
                        <Switch
                          checked={isSubcategory}
                          onChange={value => setIsSubcategory(value)}
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ width: '80%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 0
                      }}
                    >
                      Imagem
                    </p>
                    <Tooltip
                      placement="right"
                      title="Imagem exibida no menu do site e em ferramentas de pesquisa"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <UploadImage
                    setUploadImage={setUploadImage}
                    uploadImage={uploadImage}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    recommendedSize="400 x 400"
                  />
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
        <span style={{ margin: '8px 0', display: 'block' }} />
        <Row>
          <Spin spinning={!categoryId} indicator={disabledProduct}>
            <Card
              className="no-shadow"
              style={{ paddingTop: 16, minHeight: '48px' }}
            >
              <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 16 }}
              >
                <Col>
                  <h2>PRODUTOS</h2>
                  <span>Selecione o(s) produto(s) para o corredor.</span>
                </Col>
                {catalogPermission && (
                  <Col>
                    <Button
                      className="ant-btn-secondary-copy"
                      disabled={csvDisabled}
                      onClick={() => setShowCsvModal(true)}
                      style={{ marginRight: 20 }}
                    >
                      Produtos por planilha
                    </Button>
                    <Button
                      className="ant-btn-primary"
                      onClick={() => {
                        setShowProductsModal(true);
                        handleGetCategoryProducts({
                          query: { page: 1, perPage: 20 }
                        });
                      }}
                    >
                      Adicionar produto
                    </Button>
                  </Col>
                )}
              </Row>
              <Row style={{ marginBottom: 16 }}>
                <Col>
                  <Searchbar
                    placeholder="Buscar produto associado ao corredor"
                    populateResult={handleAisleProductsSearch}
                  />
                </Col>
              </Row>
              {editDragGreaterThan25 && (
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: editAisleProducts ? 10 : 0
                    }}
                  >
                    <Icon type="info-circle" />
                    <p
                      style={{
                        marginLeft: 5,
                        fontSize: 16,
                        marginBottom: 0,
                        fontWeight: 'bold'
                      }}
                    >
                      A ordenação dos produtos só é possível com máximo de 25
                      itens. Use a planilha para fazer a ordenação dos produtos
                      acima de 25 itens.
                    </p>
                  </Col>
                </Row>
              )}
              {editAisleProducts && catalogPermission && (
                <Row type="flex" justify="start">
                  <Col span={12} className="delete-products">
                    <Col span={11}>
                      <p style={{ marginBottom: 0 }}>
                        {selectedAisleProducts.length} produtos selecionados
                      </p>
                    </Col>
                    <Col span={5}>
                      <Button
                        onClick={() =>
                          handleDeleteAisleProducts(selectedAisleProducts)
                        }
                      >
                        Excluir
                      </Button>
                    </Col>
                  </Col>
                </Row>
              )}
              <DndProvider manager={manager.current.dragDropManager}>
                <Tables
                  data={aisleProducts}
                  columnsDefaults={column}
                  rowKey={record => record.id}
                  rowSelection={catalogPermission ? rowSelectionTable : null}
                  components={editDrag && catalogPermission ? components : {}}
                  isLoading={isLoading}
                  onRow={
                    editDrag && catalogPermission
                      ? (record, index) => ({
                          index,
                          moveRow
                        })
                      : null
                  }
                />
              </DndProvider>
            </Card>
          </Spin>
        </Row>
      </section>
      {catalogPermission && (
        <footer className="footer-form-save">
          <Button
            className="ant-btn ant-btn-primary"
            onClick={onSubmit}
            style={{ left: '90%' }}
            disabled={addedProducts <= 0}
          >
            Salvar
          </Button>
        </footer>
      )}
    </>
  );
};

EditAisle.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const EditAisleForm = Form.create({ name: 'EditAisle' })(EditAisle);

export default EditAisleForm;
