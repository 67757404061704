import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import login from '@/src/store/modules/login/slice';
import account from '@/src/store/modules/account/slice';
import users from '@/src/store/modules/users/slice';
import auditLog from '@/src/store/modules/audit-log/slice';
import categories from '@/src/store/modules/categories/slice';
import highlights from '@/src/store/modules/highlights/slice';
import manufacturers from '@/src/store/modules/manufacturers/slice';
import coupons from '@/src/store/modules/coupons/slice';
import order from '@/src/store/modules/order-details/slice';
import orders from '@/src/store/modules/orders/slice';
import products from '@/src/store/modules/products/slice';
import allProducts from '@/src/store/modules/products-all/slice';
import promotion from '@/src/store/modules/promotion-details/slice';
import promotions from '@/src/store/modules/promotions/slice';
import referrals from '@/src/store/modules/referrals/slice';
import customers from '@/src/store/modules/customers/slice';
import hubs from '@/src/store/modules/hubs/slice';
import wallet from '@/src/store/modules/wallet/slice';
import shipping from '@/src/store/modules/shipping/slice';
import changePassword from '@/src/store/modules/change-password/slice';
import forgotPassword from '@/src/store/modules/forgot-password/slice';
import petbreeds from '@/src/store/modules/pet-breeds/slice';
import tags from '@/src/store/modules/tags/slice';
import timeline from '@/src/store/modules/timeline-events/slice';
import aisles from '@/src/store/modules/aisles/slice';
import catalogCategories from '@/src/store/modules/catalog-categories/slice';
import attributes from '@/src/store/modules/attributes/slice';
import attributeValues from '@/src/store/modules/attribute-values/slice';
import departments from '@/src/store/modules/departments/slice';
import catalogDepartments from '@/src/store/modules/catalog-departments/slice';
import productDetails from '@/src/store/modules/product-details/slice';
import expedition from '@/src/store/modules/expedition/slice';
import hubsData from '@/src/store/modules/hubs-data/slice';
import districts from '@/src/store/modules/districts/slice';
import regions from '@/src/store/modules/regions/slice';
import zipcodes from '@/src/store/modules/zipcodes/slice';
import etas from '@/src/store/modules/etas/slice';
import suspensions from '@/src/store/modules/suspensions/slice';
import states from '@/src/store/modules/states/slice';
import cities from '@/src/store/modules/cities/slice';
import countries from '@/src/store/modules/countries/slice';
import policies from '@/src/store/modules/policies/slice';
import priceTables from '@/src/store/modules/price-tables/slice';
import priceIndexing from '@/src/store/modules/price-indexing/slice';
import recommendations from '@/src/store/modules/recommendations/slice';
import deliverers from '@/src/store/modules/deliverers/slice';
import breeds from '@/src/store/modules/breeds/slice';
import accessories from '@/src/store/modules/accessories/slice';
import gatewayStores from '@/src/store/modules/gateway-stores/slice';
import skusPetz from '@/src/store/modules/skus-petz/slice';
import assemblings from '@/src/store/modules/assemblings/slice';
// import categoryDetails from '@/src/store/modules/category-details/slice';

export default history =>
  combineReducers({
    router: connectRouter(history),
    orders,
    order,
    login,
    account,
    users,
    promotions,
    promotion,
    coupons,
    products,
    allProducts,
    productDetails,
    categories,
    highlights,
    manufacturers,
    referrals,
    customers,
    hubs,
    hubsData,
    wallet,
    auditLog,
    shipping,
    changePassword,
    forgotPassword,
    petbreeds,
    tags,
    timeline,
    aisles,
    catalogCategories,
    attributes,
    attributeValues,
    departments,
    catalogDepartments,
    expedition,
    districts,
    regions,
    zipcodes,
    etas,
    suspensions,
    states,
    cities,
    countries,
    policies,
    priceTables,
    priceIndexing,
    recommendations,
    deliverers,
    breeds,
    accessories,
    gatewayStores,
    skusPetz,
    assemblings
  });
