import { createSlice } from '@reduxjs/toolkit';

// import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  deliverers: [],
  mapDeliverers: [],
  deliverer: {},
  mapDeliverer: {},
  count: 0,
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    // search: '',
    sort: '',
    // url: '',
    filters: [],
    stats: []
  },
  isLoading: false
};

const deliverersSlice = createSlice({
  name: '@deliverers',
  initialState,
  reducers: {
    setDeliverers(prevState, action) {
      const state = prevState;
      state.deliverers = action.payload.deliverers;
      state.count = action.payload.count;
      // state.total = action.payload.total;
      state.query = {
        page: action.payload.query?.page || state.query.page,
        perPage: action.payload.query?.perPage || state.query.perPage,
        // search: action.payload.query.search || '',
        sort: action.payload.query?.sort || '',
        filters: action.payload.query?.filters || [],
        stats: action.payload.query?.stats || []
        // url: `?page=${action.payload.query.page || '1'}&per_page=${action
        //   .payload.query.perPage || state.query.perPage}${
        //   action.payload.query.search !== ''
        //     ? `&q=${action.payload.query.search}`
        //     : ''
        // }${
        //   action.payload.query.sort !== ''
        //     ? `&sort${action.payload.query.sort}`
        //     : ''
        // }${
        //   action.payload.query.filters
        //     ? buildQueryStringFromObj(action.payload.query.filters)
        //     : ''
        // }`
      };
      state.isLoading = false;
      return state;
    },
    setDeliverer(prevState, action) {
      const state = prevState;
      state.deliverer = action.payload;
      return state;
    },
    setMapDeliverers(prevState, action) {
      const state = prevState;
      state.mapDeliverers = action.payload;
      return state;
    },
    setMapDeliverer(prevState, action) {
      const state = prevState;
      state.mapDeliverer = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getDeliverers(state) {
      return state;
    },
    getMapDeliverers(state) {
      return state;
    },
    getMapDeliverer(state) {
      return state;
    },
    getDelivererById(state) {
      return state;
    },
    getBlankDeliverer(state) {
      return state;
    },
    getNewDeliverer(state) {
      return state;
    },
    getUpdateDeliverer(state) {
      return state;
    },
    getCreateDeliverer(state) {
      return state;
    }
  }
});

export const {
  setDeliverers,
  setPagination,
  setLoading,
  setSort,
  getDeliverers,
  getMapDeliverers,
  getMapDeliverer,
  getDelivererById,
  getBlankDeliverer,
  setDeliverer,
  setMapDeliverers,
  setMapDeliverer,
  getNewDeliverer,
  getUpdateDeliverer,
  getCreateDeliverer
} = deliverersSlice.actions;

export default deliverersSlice.reducer;
