import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getAssemblingsList = payload => {
  const getUrl = queryBuilder(payload, '/assemblings');
  return apiPortal.get(getUrl);
};

export const createAssembling = payload =>
  apiPortal.post('/assemblings', payload);

export const orderByDragNDrop = payload =>
  apiPortal.post('/assemblings/sorting', payload);

export const getAssemblingData = payload =>
  apiPortal.get(`/assemblings/${payload}`);

export const getAssemblingProducts = payload =>
  apiPortal.get(`/assemblings/${payload}/products`);

export const updateAssembling = payload =>
  apiPortal.patch(`/assemblings/${payload.id}`, payload);

export const archiveAssembling = payload =>
  apiPortal.post('/assemblings_archivations', payload);

export const unarchiveAssembling = payload =>
  apiPortal.delete('/assemblings_archivations', {
    data: { id: payload.id }
  });
