import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hubs: [],
  selectedHubs: {},
  selectedFilters: false,
  isLoading: false
};

const hubsSlice = createSlice({
  name: '@hubs',
  initialState,
  reducers: {
    setHubs(prevState, action) {
      const state = prevState;
      state.hubs = action.payload;
      state.selectedHubs = action.payload.hub_names || {};
      return state;
    },
    setSelectedFilters(prevState, action) {
      const state = prevState;
      state.selectedFilters = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    getHubs(state) {
      return state;
    },
    exportCSVHubSkus(state) {
      return state;
    }
  }
});

export const {
  setHubs,
  setIsLoading,
  getHubs,
  exportCSVHubSkus
} = hubsSlice.actions;

export default hubsSlice.reducer;
