import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  Divider,
  Button,
  Icon,
  Skeleton,
  Switch,
  TimePicker
} from 'antd';
import moment from 'moment';
import build from 'redux-object';
import { isValidHour } from '@/src/utils/formatTime';

import StatusPill from '@/src/components/StatusPill';
import styles from '@/src/pages/Hubs/Edit/EditHubs.module.scss';
import hubHoursStyles from '@/src/components/Hubs/HubHours/HubHours.module.scss';

import HubDistrict from '@/src/components/Hubs/HubDistrict';
// import HubHours from '@/src/components/Hubs/HubHours';
import ModalNewCityOrDistrict from '@/src/components/Hubs/Modals/NewCityOrDistrict';

import { getStates } from '@/src/store/modules/states/slice';
import { getCities, resetCitiesState } from '@/src/store/modules/cities/slice';
import {
  getDistricts,
  resetDistrictsState
} from '@/src/store/modules/districts/slice';
import { getUpdateHub } from '@/src/store/modules/hubs-data/slice';

import formatCNPJ from '@/src/utils/formatCNPJ';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const { Option } = Select;

const handleInputLabel = evt => {
  /* same function in HubDistrict, future refactor */
  const input = evt.target;
  const inputValue = evt.target.value;
  const label = input.previousElementSibling;
  if (inputValue === '') {
    label.classList.remove(styles['formInput__prefix--active']);
  } else {
    label.classList.add(styles['formInput__prefix--active']);
  }
};

const citiesQuery = {
  perPage: 1000,
  include: 'districts,districts.hub',
  fields: [
    { key: 'cities', value: 'name,id' },
    { key: 'districts', value: 'name,id,hub' },
    { key: 'hubs', value: 'name,id' }
  ],
  sort: 'name'
};

const districtsQuery = {
  perPage: 1000,
  include: 'city,hub',
  sort: 'name',
  fields: [
    { key: 'cities', value: 'name,id' },
    { key: 'districts', value: 'name,id' },
    { key: 'hubs', value: 'name,id' }
  ]
};

const format = 'HH:mm';
const invalid = 'Invalid date';

const HubMain = ({ form, hubData }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [saveBtn, setSaveBtn] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [opensAt, setOpensAt] = useState('');
  const [closesAt, setClosesAt] = useState('');

  const dispatch = useDispatch();

  const statesList = useSelector(state => state.states.list);
  const citiesList = useSelector(state => state.cities.list);
  const districtsList = useSelector(state => state.districts.list);
  const { isLoading } = useSelector(state => state.hubsData);

  const { editPermission } = usePermissions();
  const hubsPermission = editPermission(modules.DISPATCH_HUBS);
  const hubsInvoicePermission = editPermission(modules.DISPATCH_HUBS_INVOICE);

  useEffect(() => {
    if (!statesList.states) {
      dispatch(
        getStates({
          query: {
            perPage: 50,
            include: 'cities',
            fields: [
              { key: 'states', value: 'code,id' },
              { key: 'cities', value: 'id' }
            ],
            sort: 'name'
          }
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hubData.state?.id) {
      dispatch(
        getCities({
          query: {
            ...citiesQuery,
            filters: [
              {
                key: 'state_id',
                type: 'eq',
                value: hubData.state.id
              }
            ]
          }
        })
      );
    } else {
      dispatch(resetCitiesState());
    }
    // eslint-disable-next-line
  }, [hubData.state]);

  useEffect(() => {
    if (hubData.city?.id) {
      dispatch(
        getDistricts({
          query: {
            ...districtsQuery,
            filters: [
              {
                key: 'city_id',
                type: 'eq',
                value: hubData.city.id
              }
            ]
          }
        })
      );
    } else {
      dispatch(resetDistrictsState());
    }
    // eslint-disable-next-line
  }, [hubData.city]);

  useEffect(() => {
    setOpensAt(hubData?.opensAt);
    setClosesAt(hubData?.closesAt);
  }, [hubData]);

  useEffect(() => {
    const statesArr = (
      (statesList.meta && statesList.meta['/states'].data) ||
      []
    ).map(object =>
      build(statesList, 'states', object.id, { ignoreLinks: true })
    );
    setStates(statesArr);
  }, [statesList]);

  useEffect(() => {
    const citiesArr = (
      (citiesList.meta && citiesList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesList, 'cities', object.id, { ignoreLinks: true })
    );
    setCities(citiesArr);
  }, [citiesList]);

  useEffect(() => {
    const districtsArr = (
      (districtsList.meta && districtsList.meta['/districts'].data) ||
      []
    ).map(object =>
      build(districtsList, 'districts', object.id, { ignoreLinks: true })
    );
    setDistricts(districtsArr);
  }, [districtsList]);

  const handleFormUpdate = () => {
    const values = form.getFieldsValue();

    const formattedOpensAt = moment(values.opens_at)?.format('HH:mm');
    const formattedClosesAt = moment(values.closes_at)?.format('HH:mm');

    const activeCurrentValue = hubData.active ? 'active' : 'inactive';

    const opensAtIsValid =
      formattedOpensAt !== '' &&
      formattedOpensAt &&
      formattedOpensAt !== invalid;
    const closesAtIsValid =
      formattedClosesAt !== '' &&
      formattedClosesAt &&
      formattedClosesAt !== invalid;

    if (closesAtIsValid && opensAtIsValid) {
      setSaveBtn(
        values.active !== activeCurrentValue ||
          formattedOpensAt !== hubData.opensAt ||
          formattedClosesAt !== hubData.closesAt ||
          values.city !== hubData.city?.id ||
          values.country !== hubData.country?.id ||
          values.district !== hubData.district?.id ||
          values.latitude !== hubData.latitude ||
          values.max_weight !== hubData.maxWeight ||
          values.name !== hubData.name ||
          values.number !== hubData.number ||
          values.state !== hubData.state?.id ||
          values.street !== hubData.street ||
          values.zipcode !== hubData.zipcode ||
          values.embark_require_invoice !== hubData.embarkRequireInvoice ||
          values.workflow !== hubData.workflow ||
          (values.cnpj && values.cnpj !== hubData.cnpj) ||
          (values.external_name &&
            values.external_name !== hubData.externalName) ||
          (values.external_id && values.external_id !== hubData.externalId)
      );
    } else {
      setSaveBtn(false);
    }
  };

  const handleSelectState = value => {
    form.setFieldsValue({
      city: undefined,
      district: undefined
    });
    setDistricts([]);
    dispatch(
      getCities({
        query: {
          ...citiesQuery,
          filters: [
            {
              key: 'state_id',
              type: 'eq',
              value
            }
          ]
        }
      })
    );
  };

  const handleSelectCity = value => {
    setDistricts([]);
    form.setFieldsValue({
      district: undefined
    });
    dispatch(
      getDistricts({
        query: {
          ...districtsQuery,
          filters: [
            {
              key: 'city_id',
              type: 'eq',
              value
            }
          ]
        }
      })
    );
  };

  const renderERPData = (label, data) => {
    return (
      <Skeleton
        active
        loading={isLoading}
        paragraph={false}
        className={styles.loading__text}
      >
        {data && (
          <div>
            <h4>{label}</h4>
            <p>{data}</p>
          </div>
        )}
      </Skeleton>
    );
  };

  const handleAddCity = () => {
    form.setFieldsValue({
      city: undefined,
      district: undefined
    });
    setModalContent('city');
  };

  const handleAddDistrict = () => {
    form.setFieldsValue({
      district: undefined
    });
    setModalContent('district');
  };

  const handleSaveChanges = evt => {
    evt.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;

      const formattedOpensAt = moment(values.opens_at)?.format('HH:mm');
      const formattedClosesAt = moment(values.closes_at)?.format('HH:mm');
      const payload = {
        data: {
          type: 'hubs',
          id: hubData?.id,
          attributes: {
            active: values.active === 'active',
            street: values.street,
            latitude: values.latitude,
            longitude: values.longitude,
            max_weight: values.max_weight,
            name: values.name,
            number: values.number,
            zipcode: values.zipcode,
            external_name: values.external_name,
            external_id: values.external_id,
            cnpj: values.cnpj,
            embark_require_invoice: values.embark_require_invoice,
            workflow: values.workflow,
            opens_at: formattedOpensAt,
            closes_at: formattedClosesAt
          },
          relationships: {
            state: {
              data: {
                id: values.state,
                method: 'update',
                type: 'states'
              }
            },
            city: {
              data: {
                id: values.city,
                method: 'update',
                type: 'cities'
              }
            },
            // district: {
            //   data: {
            //     id: values.district || '',
            //     method: 'update',
            //     type: 'districts'
            //   }
            // },
            country: {
              data: {
                id: 1,
                method: 'update',
                type: 'countries'
              }
            }
          }
        }
      };
      if (values.district) {
        payload.data.relationships.district = {
          data: {
            id: values.district,
            method: 'update',
            type: 'districts'
          }
        };
      }
      dispatch(getUpdateHub(payload));
      setSaveBtn(false);
    });
  };

  useEffect(() => {
    handleFormUpdate();
    // eslint-disable-next-line
  }, [closesAt, opensAt]);

  const handleInputValue = (evt, type) => {
    switch (type) {
      case 'opensAt':
        form.setFieldsValue({
          opens_at: evt ? moment(evt, format) : ''
        });
        setOpensAt(evt ? moment(evt)?.format('HH:mm') : '');
        break;
      case 'closesAt':
        form.setFieldsValue({
          closes_at: evt ? moment(evt, format) : ''
        });
        setClosesAt(evt ? moment(evt)?.format('HH:mm') : '');
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ background: '#ebebeb' }}>
      <ModalNewCityOrDistrict
        content={modalContent}
        setContent={setModalContent}
        formValues={form.getFieldsValue()}
        statesList={states}
      />
      <Row
        style={{
          borderTop: '1px solid #EEE',
          background: '#FFFFFF'
        }}
      >
        <Form onChange={handleFormUpdate}>
          <Col span={5} className={styles.sectionColumnMain}>
            <h3>Dados ERP</h3>
            {renderERPData('Nome', hubData?.externalName)}
            {renderERPData('ID', hubData?.externalId)}
            {renderERPData('CNPJ', formatCNPJ(hubData?.cnpj))}
            <div>
              <h4 style={{ marginBottom: 4 }}>Situação ERP</h4>
              <StatusPill status={hubData?.externalStatus} />
            </div>
          </Col>
          <Col
            span={15}
            style={{
              borderLeft: '1px solid #ebebeb',
              borderRight: '1px solid #ebebeb'
            }}
            className={styles.sectionColumnMain}
          >
            <Row gutter={8} style={{ display: 'flex' }}>
              <Col span={8}>
                <h3>Dados ERP</h3>
                <Row>
                  {!hubData?.externalName && (
                    <Col>
                      <Skeleton
                        active
                        loading={isLoading}
                        paragraph={false}
                        className={styles.loading__select}
                      >
                        <Form.Item className={styles.formInput}>
                          <span className={styles.formInput__prefix}>
                            Nome ERP
                          </span>
                          {form.getFieldDecorator('external_name', {
                            initialValue: hubData?.externalName
                          })(
                            <Input
                              size="large"
                              onChange={e => handleInputLabel(e)}
                            />
                          )}
                        </Form.Item>
                      </Skeleton>
                    </Col>
                  )}
                  {!hubData?.externalId && (
                    <Col>
                      <Skeleton
                        active
                        loading={isLoading}
                        paragraph={false}
                        className={styles.loading__select}
                      >
                        <Form.Item className={styles.formInput}>
                          <span className={styles.formInput__prefix}>
                            ID ERP
                          </span>
                          {form.getFieldDecorator('external_id', {
                            initialValue: hubData?.externalId
                          })(
                            <Input
                              size="large"
                              onChange={e => handleInputLabel(e)}
                            />
                          )}
                        </Form.Item>
                      </Skeleton>
                    </Col>
                  )}
                  <Col>
                    <Skeleton
                      active
                      loading={isLoading}
                      paragraph={false}
                      className={styles.loading__select}
                    >
                      <Form.Item className={styles.formInput}>
                        <span
                          className={
                            hubData?.cnpj
                              ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                              : styles.formInput__prefix
                          }
                        >
                          CNPJ
                        </span>
                        {form.getFieldDecorator('erp_cnpj', {
                          initialValue: hubData?.cnpj
                        })(
                          <Input
                            disabled={!hubsPermission}
                            size="large"
                            onChange={e => handleInputLabel(e)}
                          />
                        )}
                      </Form.Item>
                    </Skeleton>
                  </Col>
                </Row>
              </Col>

              <Col span={16}>
                <h3>Horário de Funcionamento</h3>
                <Row type="flex" gutter={8}>
                  <Skeleton
                    active
                    loading={isLoading}
                    paragraph={false}
                    className={styles.loading__select}
                  >
                    <Col>
                      <Form.Item className={hubHoursStyles.formInput}>
                        <span
                          className={`${hubHoursStyles.formInput__label} ${
                            opensAt && opensAt !== '' && opensAt !== invalid
                              ? hubHoursStyles['formInput__label--active']
                              : ''
                          } hub-hours-label`}
                        >
                          Abre às
                        </span>

                        {form.getFieldDecorator('opens_at', {
                          rules: [
                            {
                              required: true,
                              message: 'Campo obrigatório'
                            },
                            {
                              validator: (rules, values) =>
                                isValidHour(
                                  values ? moment(values)?.format('HH:mm') : ''
                                ),
                              message: 'Entrada inválida'
                            }
                          ],
                          initialValue: hubData?.opensAt
                            ? moment(hubData?.opensAt || '', 'HH:mm')
                            : ''
                        })(
                          <TimePicker
                            onChange={e => handleInputValue(e, 'opensAt')}
                            // defaultOpenValue={moment(opensAt, format)}
                            format={format}
                            placeholder=""
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item className={hubHoursStyles.formInput}>
                        <span
                          className={`${hubHoursStyles.formInput__label} ${
                            closesAt && closesAt !== '' && closesAt !== invalid
                              ? hubHoursStyles['formInput__label--active']
                              : ''
                          } hub-hours-label`}
                        >
                          Fecha às
                        </span>
                        {form.getFieldDecorator('closes_at', {
                          rules: [
                            {
                              required: true,
                              message: 'Campo obrigatório'
                            },
                            {
                              validator: (rules, values) =>
                                isValidHour(
                                  values ? moment(values)?.format('HH:mm') : ''
                                ),
                              message: 'Entrada inválida'
                            }
                          ],
                          initialValue: hubData?.closesAt
                            ? moment(hubData?.closesAt, 'HH:mm')
                            : ''
                        })(
                          <TimePicker
                            onChange={e => handleInputValue(e, 'closesAt')}
                            // defaultOpenValue={moment(closesAt, format)}
                            format={format}
                            placeholder=""
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Skeleton>
                </Row>
              </Col>
            </Row>
            <h3 style={{ marginTop: 8 }}>Dados Gerais</h3>
            <Row gutter={8}>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.name
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Nome
                    </span>
                    {form.getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o nome do hub'
                        }
                      ],
                      initialValue: hubData?.name
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.maxWeight
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Peso Max
                    </span>
                    {form.getFieldDecorator('max_weight', {
                      initialValue: hubData?.maxWeight
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    {/* <span
                      className={
                        hubData?.country
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      País
                    </span> */}
                    {form.getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: 'Selecione o país'
                        }
                      ],
                      initialValue: hubData?.country?.id || '1'
                      // initialValue: hubData?.country?.name
                    })(
                      // <Input
                      //   disabled={!hubsPermission}
                      //   size="large"
                      //   onChange={e => handleInputLabel(e)}
                      // />
                      <Select
                        className={`ant-select-hub ${styles.formInput__select}`}
                        placeholder="País"
                        onChange={handleSelectState}
                        onSelect={handleFormUpdate}
                        disabled={!hubsPermission}
                      >
                        <Option key="Brasil" value="1">
                          Brasil
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    {form.getFieldDecorator('state', {
                      rules: [
                        {
                          required: true,
                          message: 'Selecione o estado'
                        }
                      ],
                      initialValue: hubData?.state?.id
                    })(
                      <Select
                        className={`ant-select-hub ${styles.formInput__select}`}
                        placeholder="UF"
                        onChange={handleSelectState}
                        onSelect={handleFormUpdate}
                        disabled={!hubsPermission}
                        showSearch
                        filterOption={handleSelectSearch}
                      >
                        {states.map(item => (
                          <Option key={item.code} value={item.id}>
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    {form.getFieldDecorator('city', {
                      rules: [
                        {
                          required: true,
                          message: 'Selecione a cidade'
                        }
                      ],
                      initialValue: hubData?.city?.id
                    })(
                      <Select
                        className={`ant-select-hub ${styles.formInput__select}`}
                        placeholder="Cidade"
                        onChange={handleSelectCity}
                        onSelect={handleFormUpdate}
                        showSearch
                        filterOption={handleSelectSearch}
                        disabled={
                          form.getFieldValue('state') === undefined ||
                          !hubsPermission
                        }
                        dropdownMatchSelectWidth={false}
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '0' }} />
                            <Button
                              className="ant-btn-select"
                              onMouseDown={e => e.preventDefault()}
                              onClick={handleAddCity}
                            >
                              <Icon type="plus-circle" /> Cadastrar Cidade
                            </Button>
                          </div>
                        )}
                      >
                        {cities.map(item => (
                          <Option key={item.name} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    {form.getFieldDecorator('district', {
                      initialValue: hubData?.district?.id
                    })(
                      <Select
                        className={`ant-select-hub ${styles.formInput__select}`}
                        placeholder="Bairro"
                        onSelect={handleFormUpdate}
                        showSearch
                        filterOption={handleSelectSearch}
                        disabled={
                          form.getFieldValue('city') === undefined ||
                          !hubsPermission
                        }
                        dropdownMatchSelectWidth={false}
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '0' }} />
                            <Button
                              className="ant-btn-select"
                              onMouseDown={e => e.preventDefault()}
                              onClick={handleAddDistrict}
                            >
                              <Icon type="plus-circle" /> Cadastrar Bairro
                            </Button>
                          </div>
                        )}
                      >
                        {districts.map(item => (
                          <Option key={item.name} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.street
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Endereço
                    </span>
                    {form.getFieldDecorator('street', {
                      initialValue: hubData?.street
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.number
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Número
                    </span>
                    {form.getFieldDecorator('number', {
                      initialValue: hubData?.number
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.zipcode
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      CEP
                    </span>
                    {form.getFieldDecorator('zipcode', {
                      initialValue: hubData?.zipcode
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.latitude
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Latitude
                    </span>
                    {form.getFieldDecorator('latitude', {
                      initialValue: hubData?.latitude
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
              <Col span={8}>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={false}
                  className={styles.loading__select}
                >
                  <Form.Item className={styles.formInput}>
                    <span
                      className={
                        hubData?.longitude
                          ? `${styles.formInput__prefix} ${styles['formInput__prefix--active']}`
                          : styles.formInput__prefix
                      }
                    >
                      Longitude
                    </span>
                    {form.getFieldDecorator('longitude', {
                      initialValue: hubData?.longitude
                    })(
                      <Input
                        disabled={!hubsPermission}
                        size="large"
                        onChange={e => handleInputLabel(e)}
                      />
                    )}
                  </Form.Item>
                </Skeleton>
              </Col>
            </Row>
          </Col>
          <Col span={4} className={styles.sectionColumnMain}>
            <Row>
              <h3>Situação Hub</h3>
              <Skeleton
                active
                loading={isLoading}
                paragraph={false}
                className={styles.loading__select}
              >
                <Form.Item className={styles.formInput}>
                  {form.getFieldDecorator('active', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione o status'
                      }
                    ],
                    initialValue: hubData?.active ? 'active' : 'inactive'
                  })(
                    <Select
                      className={`ant-select-hub ${styles.formInput__select}`}
                      onSelect={handleFormUpdate}
                      disabled={!hubsPermission}
                    >
                      <Option key="active" value="active">
                        Ativo
                      </Option>
                      <Option key="inactive" value="inactive">
                        Inativo
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Skeleton>
            </Row>
            <Row>
              <h3>Modelo de Hub</h3>
              <Skeleton
                active
                loading={isLoading}
                paragraph={false}
                className={styles.loading__select}
              >
                <Form.Item className={styles.formInput}>
                  {form.getFieldDecorator('workflow', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione o status'
                      }
                    ],
                    initialValue: hubData?.workflow
                  })(
                    <Select
                      className={`ant-select-hub ${styles.formInput__select}`}
                      onSelect={handleFormUpdate}
                      disabled={hubData?.active || !hubsPermission}
                    >
                      <Option key="petz" value="petz">
                        Loja Petz
                      </Option>
                      <Option key="conventional" value="conventional">
                        Convencional
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Skeleton>
            </Row>
            <Row>
              <h3 style={{ margin: '8px 0' }}>Restrição de embarque com NF</h3>
              <Skeleton
                active
                loading={isLoading}
                paragraph={false}
                className={styles.loading__select}
              >
                {form.getFieldDecorator('embark_require_invoice', {
                  valuePropName: 'defaultChecked',
                  initialValue: hubData?.embarkRequireInvoice
                })(
                  <Switch
                    onChange={handleFormUpdate}
                    disabled={!hubsInvoicePermission}
                  />
                )}
              </Skeleton>
            </Row>
          </Col>
        </Form>
        {hubsPermission && (
          <Button
            className={`${styles.buttonSaveChanges} ant-btn-primary`}
            onClick={handleSaveChanges}
            disabled={!saveBtn}
          >
            Salvar
          </Button>
        )}
      </Row>
      <Row>
        <HubDistrict
          hubData={hubData}
          editPermission={hubsPermission}
          handleModal={setModalContent}
        />
      </Row>
    </div>
  );
};

HubMain.defaultProps = {
  hubData: {}
};

HubMain.propTypes = {
  hubData: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired
};

const HubMainForm = Form.create({
  name: 'HubMain'
})(HubMain);

export default HubMainForm;
