import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promotion: {},
  isLoading: false
};

const promotionSlice = createSlice({
  name: '@promotion',
  initialState,
  reducers: {
    setPromotion(prevState, action) {
      const state = prevState;
      state.promotion = action.payload.promotion;
      state.isLoading = false;
      return state;
    },
    setIsPromotionLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    getPromotion(state) {
      return state;
    }
  }
});

export const {
  setPromotion,
  setIsPromotionLoading,
  getPromotion
} = promotionSlice.actions;

export default promotionSlice.reducer;
