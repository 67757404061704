import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Lato',
  src: 'https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHvxk.ttf'
});

Font.register({
  family: 'Lato-Bold',
  src: 'https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh50Xew8.ttf'
});

const styles = StyleSheet.create({
  tag: {
    width: '42.85%',
    height: '47.34%',
    border: '2 solid #000',
    margin: 8,
    padding: 16,
    display: 'flex'
  },
  ifoodTag: {
    width: '42.85%',
    height: '47.34%',
    border: '2 solid #ea1d2c',
    margin: 8,
    padding: '0 16px 16px',
    display: 'flex'
  },
  ifoodHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1 solid #000',
    fontSize: 10,
    letterSpacing: 0.5,
    fontFamily: 'Lato-Bold'
  },
  title: {
    borderBottom: '1 solid #000',
    fontSize: 10,
    lineHeight: 2,
    letterSpacing: 0.5,
    fontFamily: 'Lato-Bold'
  },
  content: {
    flexGrow: 1,
    padding: '16 0'
  },
  contentRow: {
    paddingTop: 10
  },
  subtitle: {
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: 1.5,
    fontFamily: 'Lato-Bold'
  },
  subtitleSm: {
    fontSize: 10,
    letterSpacing: 0.5,
    lineHeight: 1.5,
    fontFamily: 'Lato-Bold'
  },
  text: {
    fontSize: 12,
    fontFamily: 'Lato'
  },
  textSmall: {
    fontSize: 10,
    fontFamily: 'Lato',
    hyphens: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxHeight: 70
  },
  qrcode: {
    width: '40%',
    marginTop: 16
  },
  logo: {
    width: 40
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  },
  flexSpaceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default styles;
