import {
  takeEvery,
  takeLatest,
  put,
  call,
  delay,
  select
} from 'redux-saga/effects';
import { retry } from 'redux-saga-retry';
import normalize from 'json-api-normalizer';
import bugsnag from '@/src/services/bugsnag';
import axios from 'axios';
import { notification } from 'antd';

import { errorAlert, successAlert } from '@/src/utils/notifications';
import buildJsonData from '@/src/utils/buildJsonData';
import {
  checkOrdersUpdate,
  checkDeliveredOrders,
  hasSearchFilter
} from '@/src/utils/dispatchUtils';
import {
  waitingQuery,
  linesHubQuery,
  userQuery as handleUserQuery,
  drawerQuery,
  linesQuery
} from '@/src/globals/dispatchQueries';
import { presentDay } from '@/src/utils/dateUtils';

import {
  setOrders,
  setUserOrders,
  setFilteredOrdersData,
  setQuery,
  setCount,
  // setDispatchedCount,
  setDeliveredCount,
  setWaitingOrders,
  setWaitingOrdersCount,
  setVolumeKinds,
  setOrderReturn,
  setPageLoading,
  setReloadOrders,
  setDrawerOrder,
  setContentLoading,
  setDeliveredLoading,
  setScanLoading,
  setScanInput,
  setPolling,
  setUserPolling,
  getOrdersData,
  getUserOrdersData,
  getAllOrdersData,
  getDeliveredOrdersData,
  getOrderById,
  getDrawerOrder,
  getFilteredOrdersData,
  getOrderStatus,
  getVolumeKinds,
  getCreateNotification,
  setCollectedItems,
  setPackedItems,
  getProductScan,
  getUndoProductScan,
  addToCollectedItems,
  removeFromCollectedItems,
  setForceCollectEan,
  setOrderReshipments,
  getOrderReshipments,
  setModalOrder
} from '@/src/store/modules/expedition/slice';

import {
  getOrdersData as getOrdersDataRequest,
  getOrderById as getOrderByIdRequest,
  updateOrderStatus as setOrderStatusRequest,
  getVolumeKinds as getVolumeKindsRequest,
  setResolveReturn as setResolveReturnRequest,
  setRejectReturn as setRejectReturnRequest,
  getProductScan as getProductScanRequest,
  getUndoProductScan as getUndoProductScanRequest,
  getOrderReshipments as getOrderReshipmentsRequest
} from '@/src/api/expedition';

import createNotification from '@/src/api/one-signal';

import {
  getExpeditionWaitingOrdersState,
  getExpeditionViewerState,
  getExpeditionOrdersState,
  getExpeditionUserOrdersState,
  getExpeditionHubsState,
  getExpeditionReloadState,
  getAccountIdState,
  getExpeditionQueryState,
  getRouterState
} from '@/src/store/selectors';

// monitora atualização de alertas e status (GERENTE)
function* listenerAllOrdersSaga() {
  try {
    const currentOrders = yield select(getExpeditionOrdersState);
    const notDeliveredOrders = currentOrders.filter(
      item => item.status !== 'delivered'
    );
    const queryState = yield select(getExpeditionQueryState);
    const query = {
      query: {
        filters: queryState.filters,
        fields: [
          {
            key: 'orders',
            value:
              'id,status,has_return_request,has_cancelation_request,has_alert,sale_invoice_number,embark_require_invoice'
          }
        ],
        perPage: notDeliveredOrders.length + 20 // buscar pedidos Aguardando Coleta além dos que estão em expedição
        // sort: '-updated_at'
      }
    };
    const response = yield call(getOrdersDataRequest, query);
    const { recent_delivered_count } = response.data.meta;
    yield put(
      setDeliveredCount(
        queryState.filters.length === 0 ? 0 : recent_delivered_count
      )
    );
    const hasUpdate =
      checkDeliveredOrders(currentOrders, response) ||
      checkOrdersUpdate(currentOrders, response);
    const hasHubsFilter = yield select(getExpeditionHubsState);
    yield put(setReloadOrders(hasHubsFilter ? hasUpdate : false));
    yield put(setPolling('running'));
    yield delay(3000);
    const reloadOrders = yield select(getExpeditionReloadState);
    const hasSearch = hasSearchFilter(queryState.filters);
    if (
      window.location.href.includes('view=lines') &&
      !hasUpdate &&
      hasHubsFilter &&
      !hasSearch &&
      !reloadOrders
    ) {
      yield put({ type: 'LISTENER_ALL_ORDERS' });
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      console.log('listenerAllOrdersSaga', err);
      bugsnag.notify(err);
      yield put(setPolling('retry'));
      yield put({ type: 'LISTENER_ALL_ORDERS_FAILURE' });
      yield put(setPolling('error'));
    }
  }
}

// monitora devolução em pedidos entregues (GERENTE)
function* listenerDeliveredOrdersSaga() {
  try {
    const { include, fields } = linesQuery.query;
    const payload = {
      query: {
        include,
        filters: [
          { key: 'status', type: 'eq', value: 'delivered' },
          { key: 'has_return_request', type: 'eq', value: true }
        ],
        fields,
        perPage: 200
      }
    };
    const queryState = yield select(getExpeditionQueryState);
    const hubsFilterState = yield select(getExpeditionHubsState);
    if (hubsFilterState && hubsFilterState !== 'all') {
      payload.query.filters.push({
        key: 'hub_internal_id',
        type: 'match',
        value: hubsFilterState
      });
    }
    const response = yield call(getOrdersDataRequest, payload);
    const currentOrders = yield select(getExpeditionOrdersState);
    const hasUpdate = response.data.data.filter(item => {
      const currentOrder = currentOrders.find(order => order.id === item.id);
      if (currentOrder) {
        return !currentOrder.hasReturnRequest;
      }
      return true;
    });
    if (hasUpdate.length > 0) {
      console.log('delivered has return', hasUpdate);
      yield put(setReloadOrders(hubsFilterState ? hasUpdate : false));
    }
    yield put(setPolling('running'));
    yield delay(180000);
    const hasSearch = hasSearchFilter(queryState.filters);
    const reloadOrders = yield select(getExpeditionReloadState);
    if (
      window.location.href.includes('view=lines') &&
      hasUpdate.length === 0 &&
      hubsFilterState &&
      !hasSearch &&
      !reloadOrders
    ) {
      yield put({ type: 'LISTENER_DELIVERED_ORDERS' });
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      console.log('listenerDeliveredOrdersSaga', err);
      bugsnag.notify(err);
      yield put(setPolling('retry'));
      yield put({ type: 'LISTENER_DELIVERED_ORDERS_FAILURE' });
      yield put(setPolling('error'));
    }
  }
}

// monitora atualização de novos pedidos (ESTOQUISTA)
function* listenerWaitingOrdersSaga() {
  try {
    const queryState = yield select(getExpeditionQueryState);
    const query = {
      query: {
        filters: queryState.filters,
        fields: [
          {
            key: 'orders',
            value:
              'id,status,has_alert,has_return_request,has_cancelation_request,has_eta_alert,embark_require_invoice'
          }
        ],
        perPage: 100
      }
    };
    const response = yield call(getOrdersDataRequest, query);
    const ids = response.data.data.map(item => item.id);
    const listed = yield select(getExpeditionWaitingOrdersState);
    const notListed = ids.filter(item => listed.indexOf(item) < 0);
    const hasHubsFilter = yield select(getExpeditionHubsState);
    if (hasHubsFilter) yield put(setWaitingOrdersCount(notListed.length));
    const started = listed.filter(item => ids.indexOf(item) < 0);

    const orders = yield select(getExpeditionOrdersState);
    const waiting = orders ? Object.values(orders) : [];
    const hasUpdate = checkOrdersUpdate(waiting, response, true);
    yield put(setPolling('running'));
    if ((started.length > 0 || hasUpdate) && hasHubsFilter) {
      const payload = {
        query: {
          ...waitingQuery.query,
          filters: queryState.filters
        }
      };
      yield put({ type: 'GET_ORDERS_DATA', payload });
    } else {
      yield delay(1000);
      const hasSearch = hasSearchFilter(queryState.filters);
      if (
        window.location.href.includes('view=lines') &&
        hasHubsFilter &&
        !hasSearch
      ) {
        yield put({ type: 'LISTENER_WAITING_ORDERS' });
      }
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      bugsnag.notify(err);
      yield put(setPolling('retry'));
      yield put({ type: 'LISTENER_ALL_ORDERS_FAILURE' });
      yield put(setPolling('error'));
    }
  }
}

// monitora atualização de alertas e pedidos enviados (ESTOQUISTA)
function* listenerUserOrdersSaga() {
  try {
    const userOrdersState = yield select(getExpeditionUserOrdersState);
    const userOrders = userOrdersState ? Object.values(userOrdersState) : [];
    const userOrdersId = userOrders.map(item => item.id);
    const hasHubsFilter = yield select(getExpeditionHubsState);
    if (userOrdersId.length > 0) {
      const query = {
        query: {
          filters: [{ key: 'id', type: 'eq', value: userOrdersId.join() }],
          fields: [
            {
              key: 'orders',
              value:
                'id,status,has_alert,has_return_request,has_cancelation_request,has_eta_alert,sale_invoice_number,embark_require_invoice'
            }
          ],
          perPage: userOrders.length
        }
      };
      const response = yield call(getOrdersDataRequest, query);
      const hasUpdate = checkOrdersUpdate(userOrders, response, true);
      if (hasUpdate && hasHubsFilter) {
        const user = yield select(getAccountIdState);
        const userQuery = handleUserQuery(user);
        const payload = {
          query: {
            ...userQuery.query,
            perPage: userOrders.length + 20
          }
        };
        const res = yield call(getOrdersDataRequest, payload);
        const normalized = normalize(res.data, { endpoint: '/orders' });
        const jsonData = buildJsonData(normalized, 'orders');
        yield put(setUserOrders(jsonData));
      }
    }
    yield put(setUserPolling('running'));
    yield delay(3000);
    const queryState = yield select(getExpeditionQueryState);
    const hasSearch = hasSearchFilter(queryState.filters);
    if (
      window.location.href.includes('view=lines') &&
      hasHubsFilter &&
      !hasSearch
    ) {
      yield put({ type: 'LISTENER_USER_ORDERS' });
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      bugsnag.notify(err);
      yield put(setUserPolling('retry'));
      yield put({ type: 'LISTENER_ALL_ORDERS_FAILURE' });
      yield put(setUserPolling('error'));
    }
  }
}

// pedidos na tabela (GERENTE)
// pedidos aguardando coleta (ESTOQUISTA)
function* getOrdersDataSaga({ payload }) {
  try {
    if (
      window.location.href.includes('view=table') ||
      window.location.href.includes('embarques')
    )
      yield put(setPageLoading(true));
    yield put(setQuery(payload.query));
    const response = yield call(getOrdersDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/orders' });
    const jsonData = buildJsonData(normalized, 'orders');
    yield put(setOrders(jsonData));
    if (response.data.meta.stats) {
      const { count } = response.data.meta.stats.total;
      // request da tabela retorna número e request de filtro retorna objeto com cada pedido
      const countNum =
        typeof count === 'number' ? count : Object.keys(count).length;
      yield put(setCount(countNum));
    }
    if (window.location.href.includes('view=lines')) {
      yield put(setWaitingOrders(response.data.data.map(order => order.id)));
    } else {
      yield put(setPageLoading(false));
    }
    yield delay(1000);
    const hasHubsFilter = yield select(getExpeditionHubsState);
    const hasSearch = hasSearchFilter(payload.query.filters);
    if (
      window.location.href.includes('view=lines') &&
      hasHubsFilter &&
      !hasSearch
    ) {
      yield put({ type: 'LISTENER_WAITING_ORDERS' });
    }
  } catch (err) {
    yield put(setPageLoading(false));
    errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
  }
}

// pedidos iniciados em diante filtrados por usuário (ESTOQUISTA)
function* getUserOrdersDataSaga({ payload }) {
  try {
    yield put(setPageLoading(true));
    const response = yield call(getOrdersDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/orders' });
    const jsonData = buildJsonData(normalized, 'orders');
    yield put(setUserOrders(jsonData));
    yield put(setPageLoading(false));
    yield delay(3000);
    const hasHubsFilter = yield select(getExpeditionHubsState);
    const hasSearch = hasSearchFilter(payload.query.filters);
    if (
      window.location.href.includes('view=lines') &&
      hasHubsFilter &&
      !hasSearch
    ) {
      yield put({ type: 'LISTENER_USER_ORDERS' });
    }
  } catch (err) {
    yield put(setPageLoading(false));
    errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
  }
}

// todos os pedidos (HUB)
// todos os pedidos exceto os entregues (GERENTE)
function* getAllOrdersDataSaga({ payload }) {
  const viewer = yield select(getExpeditionViewerState);
  try {
    yield put(setPageLoading(viewer !== 'hub'));
    yield put(setQuery(payload.query));
    const response = yield call(getOrdersDataRequest, payload);
    if (window.location.href.includes('view=lines')) {
      const normalized = normalize(response.data, { endpoint: '/orders' });
      const jsonData = buildJsonData(normalized, 'orders');
      yield put(setOrders(jsonData));
      yield put(setPageLoading(false));
      if (viewer === 'office') {
        const { recent_delivered_count } = response.data.meta;
        yield put(setDeliveredCount(recent_delivered_count));
        yield put({ type: 'GET_DELIVERED_ORDERS_WITH_RETURN' });
      }
      if (viewer === 'hub') yield put(setPolling('running'));
      yield delay(viewer !== 'hub' ? 3000 : 5000);
      const hasHubsFilter = yield select(getExpeditionHubsState);
      const hasSearch = hasSearchFilter(payload.query.filters);
      if (
        window.location.href.includes('view=lines') &&
        viewer === 'office' &&
        hasHubsFilter &&
        !hasSearch
      ) {
        yield put({ type: 'LISTENER_ALL_ORDERS' });
      }
      if (window.location.href.includes('view=lines') && viewer === 'hub') {
        const hub = yield select(getExpeditionHubsState);
        if (hub.includes('all')) {
          yield put({ type: 'GET_ALL_ORDERS', payload: linesHubQuery });
        } else {
          const newPayload = {
            query: {
              ...linesHubQuery.query,
              filters: linesHubQuery.query.filters.filter(
                item => item.key !== 'hub_internal_id'
              )
            }
          };
          newPayload.query.filters = [
            ...newPayload.query.filters,
            { key: 'hub_internal_id', type: 'match', value: hub }
          ];
          yield put({ type: 'GET_ALL_ORDERS', payload: newPayload });
        }
      }
    }
  } catch (err) {
    if (viewer === 'hub') {
      bugsnag.notify(err);
      yield put(setPolling('retry'));
      yield put({ type: 'GET_ALL_ORDERS_FAILURE' });
      yield put(setPolling('error'));
      yield put(setPageLoading(false));
    } else {
      yield put(setPageLoading(false));
      errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
    }
  }
}

// pedidos entregues com devolução (GERENTE)
function* getDeliveredOrdersWithReturnSaga() {
  try {
    const { include, fields } = linesQuery.query;
    const payload = {
      query: {
        include,
        filters: [
          { key: 'status', type: 'eq', value: 'delivered' },
          { key: 'has_return_request', type: 'eq', value: true }
        ],
        fields,
        perPage: 200
      }
    };
    const queryState = yield select(getExpeditionQueryState);
    const hubsFilterState = yield select(getExpeditionHubsState);
    if (hubsFilterState && hubsFilterState !== 'all') {
      payload.query.filters.push({
        key: 'hub_internal_id',
        type: 'match',
        value: hubsFilterState
      });
    }
    const response = yield call(getOrdersDataRequest, payload);
    const currentOrders = yield select(getExpeditionOrdersState);
    const hasUpdate = response.data.data.filter(item => {
      const currentOrder = currentOrders.find(order => order.id === item.id);
      if (currentOrder) {
        return !currentOrder.hasReturnRequest;
      }
      return true;
    });
    if (hasUpdate.length > 0) {
      const normalized = normalize(response.data, { endpoint: '/orders' });
      const jsonData = buildJsonData(normalized, 'orders');
      const newOrders = hasUpdate.map(order =>
        jsonData.find(item => item.id === order.id)
      );
      const oldOrders = currentOrders.filter(
        order => !newOrders.find(item => item.id === order.id)
      );
      yield put(setOrders([...oldOrders, ...newOrders]));
    }
    yield put(setPolling('running'));
    yield delay(180000);
    const hasSearch = hasSearchFilter(queryState.filters);
    const reloadOrders = yield select(getExpeditionReloadState);
    if (
      window.location.href.includes('view=lines') &&
      hasUpdate.length === 0 &&
      hubsFilterState &&
      !hasSearch &&
      !reloadOrders
    ) {
      yield put({ type: 'LISTENER_DELIVERED_ORDERS' });
    }
  } catch (err) {
    if (!axios.isCancel(err)) {
      // console.log('listenerDeliveredOrdersSaga', err);
      bugsnag.notify(err);
      yield put(setPolling('retry'));
      yield put({ type: 'LISTENER_DELIVERED_ORDERS_FAILURE' });
      yield put(setPolling('error'));
    }
  }
}

// pedidos entregues (GERENTE)
function* getDeliveredOrdersDataSaga({ payload }) {
  try {
    yield put(setDeliveredLoading(true));
    const queryState = yield select(getExpeditionQueryState);
    const filters = queryState.filters.filter(
      item => item.key !== 'status' && item.key !== 'updated_at'
    );
    const { include, fields } = linesQuery.query;
    const query = {
      query: {
        include,
        filters: [
          ...filters,
          { key: 'status', type: 'eq', value: payload },
          { key: 'updated_at', type: 'gte', value: presentDay }
        ],
        fields,
        perPage: 200,
        sort: '-updated_at'
      }
    };
    const response = yield call(getOrdersDataRequest, query);
    const { recent_delivered_count } = response.data.meta;
    yield put(setDeliveredCount(recent_delivered_count));
    const currentOrders = yield select(getExpeditionOrdersState);
    const currentOrdersIds = currentOrders.map(item => item.id);
    const hasUpdate = response.data.data.filter(
      item => !currentOrdersIds.includes(item.id)
    );
    if (hasUpdate.length > 0) {
      const normalized = normalize(response.data, { endpoint: '/orders' });
      const jsonData = buildJsonData(normalized, 'orders');
      const newOrders = hasUpdate.map(order =>
        jsonData.find(item => item.id === order.id)
      );
      yield put(setOrders([...currentOrders, ...newOrders]));
    }
    yield put(setDeliveredLoading(false));
  } catch (err) {
    yield put(setDeliveredLoading(false));
    console.log(err);
  }
}

function* getOrderByIdSaga({ payload }) {
  try {
    yield put(setContentLoading(true));
    const response = yield call(getOrderByIdRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/orders' });
    const jsonData = buildJsonData(normalized, 'orders');
    yield put(setModalOrder(jsonData[0])); // atualiza estado próprio do modal, no lugar de atualizar estado geral dos pedidos (cards e listagem)
    yield put(setContentLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os dados do pedido');
  }
}

function* getOrderReshipmentsSaga({ payload }) {
  try {
    const response = yield call(getOrderReshipmentsRequest, payload);
    yield put(setOrderReshipments(response.data.data));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os dados do pedido');
  }
}

function* getDrawerOrderSaga({ payload }) {
  try {
    yield put(setContentLoading(true));
    let response;
    if (payload.id) {
      response = yield call(getOrderByIdRequest, payload);
    } else {
      response = yield call(getOrdersDataRequest, payload);
    }
    const normalized = normalize(response.data, {
      endpoint: '/orders'
    });
    const jsonData = buildJsonData(normalized, 'orders');
    if (jsonData[0]) {
      yield put(setDrawerOrder(jsonData[0]));
    } else {
      yield put(setDrawerOrder(null));
      notification.error({
        message: 'Erro',
        duration: 4,
        className: 'error',
        description: `Pedido ${payload.number} não encontrado`
      });
    }
    yield put(setContentLoading(false));
  } catch (err) {
    if (err.response?.data?.errors[0]?.code === 'not_found') {
      yield put(setDrawerOrder(null));
      errorAlert(err, `Pedido ${payload.number} não encontrado`);
    } else {
      errorAlert(err, 'Não foi possível carregar os dados do pedido');
    }
  }
}

// filtros e busca
function* getFilteredOrdersDataSaga({ payload }) {
  try {
    yield put(setPageLoading(true));
    yield put(setQuery(payload.query));
    const viewer = yield select(getExpeditionViewerState);
    if (window.location.href.includes('view=lines') && viewer === 'stockist') {
      const waitingPayload = {
        query: {
          ...payload.query,
          filters: [
            ...payload.query.filters,
            { key: 'status', type: 'eq', value: 'waiting' }
          ]
        }
      };
      const waitingRes = yield call(getOrdersDataRequest, waitingPayload);
      const waitingNorm = normalize(waitingRes.data, { endpoint: '/orders' });
      const waitingJsonData = buildJsonData(waitingNorm, 'orders');

      const userId = yield select(getAccountIdState);
      const userPayload = {
        query: {
          ...payload.query,
          filters: [
            ...payload.query.filters.filter(item => item.key !== 'status'),
            { key: 'user_id', type: 'eq', value: userId }
          ]
        }
      };
      const userRes = yield call(getOrdersDataRequest, userPayload);
      const userNorm = normalize(userRes.data, { endpoint: '/orders' });
      const userJsonData = buildJsonData(userNorm, 'orders');

      yield put(
        setFilteredOrdersData({
          orders: waitingJsonData,
          userOrders: userJsonData
        })
      );
      const hasSearch = hasSearchFilter(payload.query.filters);
      if (!hasSearch) {
        yield delay(1000);
        yield put({ type: 'LISTENER_WAITING_ORDERS' });
        yield put({ type: 'LISTENER_USER_ORDERS' });
      }
    } else {
      const response = yield call(getOrdersDataRequest, payload);
      const normalized = normalize(response.data, { endpoint: '/orders' });
      const jsonData = buildJsonData(normalized, 'orders');
      yield put(setFilteredOrdersData({ orders: jsonData }));
      if (response.data.meta.stats) {
        const { count } = response.data.meta.stats.total;
        const countNum =
          typeof count === 'number' ? count : Object.keys(count).length;
        yield put(setCount(countNum));
      }
      const hasSearch = hasSearchFilter(payload.query.filters);
      if (window.location.href.includes('view=lines') && !hasSearch) {
        yield delay(1000);
        yield put({ type: 'LISTENER_ALL_ORDERS' });
      }
    }
    yield put(setPageLoading(false));
  } catch (err) {
    yield put(setPageLoading(false));
    errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
  }
}

function* setOrderStatusSaga({ payload }) {
  try {
    const viewer = yield select(getExpeditionViewerState);
    const { status } = payload.data.attributes;
    const response = yield call(setOrderStatusRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/orders' });
    const jsonData = buildJsonData(normalized, 'orders');
    const currentOrders = yield select(getExpeditionOrdersState);
    const newOrders =
      viewer === 'stockist' && status === 'started'
        ? currentOrders.filter(item => item.id !== jsonData[0].id)
        : currentOrders.map(item => {
            if (item.id === jsonData[0].id) return jsonData[0];
            return item;
          });
    yield put(setOrders(newOrders));
    if (viewer === 'stockist') {
      const currentUserOrders = yield select(getExpeditionUserOrdersState);
      let newUserOrders;
      if (status === 'waiting') {
        newUserOrders = currentUserOrders.filter(
          item => item.id !== jsonData[0].id
        );
      } else if (!currentUserOrders.find(item => item.id === jsonData[0].id)) {
        newUserOrders = [...currentUserOrders];
        newUserOrders.push(jsonData[0]);
      } else {
        newUserOrders = currentUserOrders.map(item => {
          if (item.id === jsonData[0].id) return jsonData[0];
          return item;
        });
      }
      yield put(setUserOrders(newUserOrders));
    }
    // yield put(setOrdersData(normalized));
  } catch (err) {
    errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
  }
}

function* setVolumeKindsSaga() {
  try {
    const response = yield call(getVolumeKindsRequest);
    const volKindsData = response.data.data.map(vol => ({
      id: vol.id,
      name: vol.attributes.name,
      max_weight: vol.attributes.max_weight
    }));
    yield put(setVolumeKinds(volKindsData));
  } catch (err) {
    errorAlert(err, 'Não foi possível verificar atualizações nos pedidos');
  }
}

function* setOrderReturnSaga({ payload }) {
  try {
    const { id, data, query, action } = payload;
    if (action === 'resolve') {
      yield call(setResolveReturnRequest, data);
    } else {
      yield call(setRejectReturnRequest, data);
    }
    const response = yield call(getOrderByIdRequest, { id, query });

    const { status, number } = response.data.data.attributes;
    if (status === 'started')
      yield put(setCollectedItems({ orderId: id, orderItems: [] }));
    if (status === 'picked')
      yield put(setPackedItems({ orderId: id, volumes: [] }));

    const normalized = normalize(response.data, { endpoint: '/orders' });
    const userOrder = response.data.data.relationships.user.links.related;
    const viewer = yield select(getExpeditionViewerState);
    const jsonData = buildJsonData(normalized, 'orders');
    if (userOrder && viewer === 'stockist') {
      const currentUserOrders = yield select(getExpeditionUserOrdersState);
      const newUserArr = currentUserOrders.map(item => {
        if (item.id === jsonData[0].id) return jsonData[0];
        return item;
      });
      yield put(setUserOrders(newUserArr));
    } else {
      const currentOrders = yield select(getExpeditionOrdersState);
      const newArr = currentOrders.map(item => {
        if (item.id === jsonData[0].id) return jsonData[0];
        return item;
      });
      yield put(setOrders(newArr));
    }
    if (query.include.includes('order_items'))
      yield put(setModalOrder(jsonData[0]));
    successAlert(
      action === 'resolve' ? 'Solicitação resolvida' : 'Solicitação rejeitada'
    );
    const { location } = yield select(getRouterState);
    if (location.search.includes('order=')) {
      const params = {
        ...drawerQuery,
        number,
        id
      };
      yield put({ type: 'GET_DRAWER_ORDER', payload: params });
    }
  } catch (err) {
    const message =
      payload.action === 'resolve'
        ? 'Não foi possível verificar atualizações nos pedidos'
        : 'Não foi possível rejeitar a devolução';
    errorAlert(err, message);
  }
}

function* getProductScanSaga({ payload }) {
  try {
    yield put(setScanLoading(true));
    const response = yield call(getProductScanRequest, payload.data);
    const collected = {
      orderId: response.data.order_id,
      orderItem: {
        itemId: Number(response.data.id),
        scanned: Number(response.data.scanned_quantity),
        done:
          response.data.scanned_quantity + payload.rupture ===
          response.data.quantity - response.data.returned_quantity,
        rupture: payload.rupture,
        total: response.data.quantity - response.data.returned_quantity
      }
    };
    // yield delay(300);
    yield put(addToCollectedItems(collected));
    yield put(setScanLoading(false));
    yield put(setScanInput(''));
    return successAlert('Item coletado com sucesso');
  } catch (err) {
    yield put(setScanLoading(false));
    yield put(setScanInput(''));
    if (
      err.response?.data?.errors[0]?.title ===
      'order_item.too_many_attempts_scan'
    ) {
      // console.log(payload.ean);
      yield put(setForceCollectEan(payload.ean));
      return false;
    }
    if (
      err.response?.data?.errors[0]?.title ===
      'order_item.already_fully_scanned'
    ) {
      return notification.error({
        message: 'Erro',
        duration: 4,
        className: 'error',
        description: 'O item bipado já teve a quantidade total conferida'
      });
    }
    if (
      err.response?.data?.errors[0]?.title ===
      'order_item.doesnt_belong_to_order'
    ) {
      return notification.error({
        message: 'Produto não faz parte do pedido',
        duration: 4,
        className: 'error',
        description: 'Esse produto não foi pedido pelo cliente'
      });
    }
    return notification.error({
      message: 'Erro na leitura do código',
      duration: 4,
      className: 'error',
      description: 'Tente de novo ou digite manualmente'
    });
  }
}

function* getUndoProductScanSaga({ payload }) {
  try {
    yield put(setScanLoading(true));
    const response = yield call(getUndoProductScanRequest, payload);
    yield put(removeFromCollectedItems(response.data.id));
    yield put(setScanLoading(false));
    successAlert('Item removido da coleta');
  } catch (err) {
    yield put(setScanLoading(false));
    errorAlert(err, 'Não foi possível remover este item da coleta');
  }
}

// OneSignal - notifications
function* createNotificationSaga({ payload }) {
  try {
    const payloadObj = {
      app_id: '3d531cd4-fd91-493f-9460-98d1ec99ab56',
      included_segments: ['Subscribed Users'],
      filters: [{ field: 'tag', key: 'hub', value: payload.hub }],
      contents: {
        en: 'A new order wait to be picked',
        pt: 'Um novo pedido aguarda para ser coletado'
      },
      headings: { en: 'New Order!', pt: 'Novo Pedido! 📦' },
      url: 'https://beta.admin.zeenow.com.br/expedicao'
    };
    if (payload.ifood) {
      const icon = {
        chrome_web_icon:
          'https://is2-ssl.mzstatic.com/image/thumb/Purple115/v4/1d/53/35/1d53350a-67ae-187d-b1b3-a22969af9b87/source/256x256bb.jpg'
      };
      Object.assign(payloadObj, icon);
    }
    yield call(createNotification, payloadObj);
  } catch (err) {
    console.log(err);
  }
}

const backoffFn = i => 5000;

function* expeditionSaga() {
  yield takeEvery(getOrdersData, getOrdersDataSaga);
  yield takeEvery(getUserOrdersData, getUserOrdersDataSaga);
  yield takeLatest(
    getAllOrdersData,
    retry(getAllOrdersDataSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
  yield takeEvery(
    'GET_DELIVERED_ORDERS_WITH_RETURN',
    getDeliveredOrdersWithReturnSaga
  );
  yield takeEvery(getDeliveredOrdersData, getDeliveredOrdersDataSaga);
  yield takeEvery(getFilteredOrdersData, getFilteredOrdersDataSaga);
  yield takeEvery(getOrderStatus, setOrderStatusSaga);
  yield takeEvery(getOrderById, getOrderByIdSaga);
  yield takeEvery(getOrderReshipments, getOrderReshipmentsSaga);
  yield takeEvery(getDrawerOrder, getDrawerOrderSaga);
  yield takeEvery('GET_DRAWER_ORDER', getDrawerOrderSaga);
  yield takeEvery(getVolumeKinds, setVolumeKindsSaga);
  yield takeEvery(setOrderReturn, setOrderReturnSaga);
  yield takeEvery(getCreateNotification, createNotificationSaga);
  yield takeEvery(getProductScan, getProductScanSaga);
  yield takeEvery(getUndoProductScan, getUndoProductScanSaga);
  yield takeEvery('GET_ORDERS_DATA', getOrdersDataSaga);
  yield takeEvery('GET_ORDER_BY_ID', getOrderByIdSaga);
  yield takeLatest(
    'GET_ALL_ORDERS',
    retry(getAllOrdersDataSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
  yield takeLatest(
    'LISTENER_WAITING_ORDERS',
    retry(listenerWaitingOrdersSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
  yield takeLatest(
    'LISTENER_USER_ORDERS',
    retry(listenerUserOrdersSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
  yield takeLatest(
    'LISTENER_ALL_ORDERS',
    retry(listenerAllOrdersSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
  yield takeLatest(
    'LISTENER_DELIVERED_ORDERS',
    retry(listenerDeliveredOrdersSaga, {
      condition: /_FAILURE$/,
      backoff: backoffFn,
      retries: 12
    })
  );
}

export default expeditionSaga;
