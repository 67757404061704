import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row, Table, Button, Tabs /* Modal, Icon */ } from 'antd';
import PropTypes from 'prop-types';
import moment from '@/src/services/moment';

import formatCPF from '@/src/utils/formatCPF';
import formatCurrency from '@/src/utils/formatCurrency';
import SearchBar from '@/src/components/Searchbar/Searchbar';
import EditCouponNew from '@/src/pages/Coupons/containers/EditCouponNew/EditCoupon';
// import EditCoupon from '@/src/pages/Coupons/containers/EditCoupon/index';

import {
  archiveCoupons,
  getCouponsInPromotion,
  exportCSV,
  editCoupon,
  setQuery
} from '@/src/store/modules/coupons/slice';

const { TabPane } = Tabs;

const Coupons = props => {
  const { promotionType } = props;

  const dispatch = useDispatch();

  const { coupons, total, query, isLoading } = useSelector(
    state => state.coupons
  );

  const [selectedCoupons, setSelectedCoupons] = useState([]);
  // const [editCouponVisibility, setEditCouponVisibility] = useState(false);
  // const [couponInfo, setCouponInfo] = useState(null);
  const [tabActive, setTabActive] = useState('unarchived');
  const [keyTabActive, setKeyTabActive] = useState('not_archived');

  const couponsSelectedEdit = coupons.filter(
    cupom => cupom.id === selectedCoupons[0]
  );

  // const editCoupon = record => {
  //   setCouponInfo(record);
  //   setEditCouponVisibility(true);
  // };

  let columns = [
    {
      title: 'Código',
      dataIndex: 'attributes.code',
      key: 'code',
      sorter: true
    },
    {
      title: 'Uso total',
      dataIndex: 'attributes.total_orders_coupon_uses',
      key: 'total_orders_coupon_uses',
      sorter: true
    },
    {
      title: 'R$ total',
      dataIndex: 'attributes.total_order_value',
      key: 'total_order_value',
      sorter: true,
      render: totalValue =>
        totalValue !== null ? formatCurrency(totalValue) : '-'
    },
    {
      title: 'Uso convertido',
      dataIndex: 'attributes.total_coupon_converted',
      key: 'total_coupon_converted',
      sorter: true
    },
    {
      title: 'R$ convertido',
      dataIndex: 'attributes.total_converted_value',
      key: 'total_converted_value',
      sorter: true,
      render: totalValue =>
        totalValue !== null ? formatCurrency(totalValue) : '-'
    },
    {
      title: 'Soma R$ descontos',
      dataIndex: 'attributes.total_discount_applied',
      key: 'total_discount_applied',
      sorter: true,
      render: totalValue =>
        totalValue !== null ? formatCurrency(totalValue) : '-'
    },
    {
      title: 'Último uso',
      dataIndex: 'attributes.last_used',
      key: 'last_used',
      sorter: true,
      render: date =>
        date && date.length > 0
          ? moment(date).format('DD/MM/YYYY - HH:mm')
          : '-'
    }
    // {
    //   title: '',
    //   key: 'action',
    //   width: '70px',
    //   render: (e, record) => (
    //     <Button
    //       style={{
    //         background: 'transparent',
    //         color: '#333',
    //         border: 'none',
    //         fontSize: '1.4em'
    //       }}
    //       onClick={() => editCoupon(record)}
    //     >
    //       <Icon type="edit" theme="filled" />
    //     </Button>
    //   )
    // }
  ];
  if (promotionType === 'referral') {
    columns = [
      {
        title: 'Nome',
        dataIndex: 'attributes.referrer_name',
        key: 'referrer_name',
        sorter: true
      },
      {
        title: 'CPF',
        dataIndex: 'attributes.customer_cpf',
        key: 'customer_cpf',
        sorter: true,
        render: cpf => formatCPF(cpf)
      },
      ...columns
    ];
  }

  const rowSelection = {
    selectedRowKeys: selectedCoupons,
    onChange: selectedRowKeys => setSelectedCoupons(selectedRowKeys)
  };

  const { promoId } = useParams();

  const archiveCouponsHandler = useCallback(
    payload => {
      dispatch(archiveCoupons(payload));
    },
    [dispatch]
  );

  const getCoupons = useCallback(
    payload => {
      setSelectedCoupons([]);
      dispatch(getCouponsInPromotion(payload));
    },
    [dispatch]
  );

  const editCouponHandler = cuponValue =>
    dispatch(editCoupon({ ...cuponValue, promotion_id: promoId }));

  const handleArchiveCoupons = type => {
    setSelectedCoupons([]);
    archiveCouponsHandler({ promoId, ids: selectedCoupons, type });
  };

  useEffect(() => {
    getCoupons({
      query: {
        ...query,
        filters: {
          not_archived: true
        }
      },
      promoId
    });
    return () => {
      dispatch(
        setQuery({
          page: 1,
          perPage: 20,
          search: ''
        })
      );
    };
    // eslint-disable-next-line
  }, [promoId]);

  const handleTabChange = key => {
    setSelectedCoupons([]);
    const keyName = key === 'archived' ? key : 'unarchived';
    setTabActive(keyName);
    setKeyTabActive(key);
    getCoupons({
      query: {
        ...query,
        filters: {
          [key]: true
        }
      },
      promoId
    });
  };

  // const closeEditModal = () => setEditCouponVisibility(false);

  return (
    <>
      <Tabs
        className="color-black bordered"
        hideAdd
        style={{ marginBottom: 20 }}
        onChange={handleTabChange}
      >
        <TabPane tab="Cupons Ativos" key="not_archived" closable={false} />
        <TabPane closable={false} key="archived" tab="Cupons Arquivados" />
      </Tabs>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <Col span={12}>
          <SearchBar
            placeholder={
              promotionType === 'referral'
                ? 'Buscar por nome, cupom ou CPF'
                : 'Buscar por nome ou cupom'
            }
            populateResult={getCoupons}
            query={query}
            value={query.search}
          />
        </Col>
        <Col>
          <Button
            type="secondary"
            onClick={() =>
              dispatch(
                exportCSV(
                  keyTabActive === 'not_archived'
                    ? {
                        query: { ...query, filters: { [keyTabActive]: true } },
                        promoId
                      }
                    : {
                        query: { ...query, filters: { [keyTabActive]: true } },
                        promoId
                      }
                )
              )
            }
          >
            Exportar CSV
          </Button>
        </Col>
      </Row>
      {selectedCoupons.length === 0 ? null : (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            border: 'solid 1px #ebebeb',
            borderRadius: '1px',
            padding: '8px',
            marginTop: '1em',
            paddingLeft: 24,
            paddingRight: 24
          }}
        >
          <Col span={10}>
            <p style={{ margin: 0 }}>
              <strong>{selectedCoupons.length}</strong> Cupo
              {selectedCoupons.length <= 1
                ? 'm selecionado'
                : 'ns selecionados'}
            </p>
          </Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col style={{ paddingRight: 10 }}>
              <Button
                style={{ width: '100%' }}
                onClick={() => handleArchiveCoupons(tabActive)}
              >
                {tabActive === 'archived' ? 'Desarquivar' : 'Arquivar'}
              </Button>
            </Col>
            {promotionType === 'referral' && selectedCoupons.length === 1 ? (
              <Col>
                <EditCouponNew
                  style={{ width: '100%' }}
                  coupon={couponsSelectedEdit[0]}
                  editCouponOnSubmit={editCouponHandler}
                  loading={isLoading}
                >
                  Editar Cupom
                </EditCouponNew>
              </Col>
            ) : null}
          </Row>
        </Row>
      )}
      <Row style={{ paddingLeft: 24, paddingRight: 24 }}>
        {/* {couponInfo ? (
          <Modal
            title={`CUPOM ${couponInfo.attributes.code}`}
            visible={editCouponVisibility}
            footer={false}
            onCancel={closeEditModal}
          >
            <EditCoupon coupon={couponInfo} closeModal={closeEditModal} />
          </Modal>
        ) : null} */}

        <Col span={24}>
          {total !== 0 ? (
            <div
              className="table-results table-results__promos"
              style={{ position: 'relative', bottom: '-45px' }}
            >
              <p>
                <span>{total}</span> {total === 1 ? 'cupom' : 'cupons'}
              </p>
            </div>
          ) : (
            <br />
          )}
          <Table
            columns={columns}
            dataSource={coupons}
            size="medium"
            rowKey={record => record.id}
            rowSelection={rowSelection}
            loading={isLoading}
            className="even-gray"
            pagination={{
              position: 'both',
              pageSize: query.perPage,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
              total,
              current: query.page
            }}
            onChange={(pagination, filter, sorter) => {
              const { current, pageSize } = pagination;
              const hasSortOrder = () => {
                return `[${sorter.columnKey}]=${sorter.order.replace(
                  'end',
                  ''
                )}`;
              };
              const onChangeQuery = {
                query: {
                  ...query,
                  page: query.perPage !== pageSize ? 1 : current,
                  perPage: pageSize,
                  sort: sorter.order ? hasSortOrder() : ''
                }
              };
              getCoupons(onChangeQuery);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

Coupons.defaultProps = {
  promotionType: ''
};

Coupons.propTypes = {
  promotionType: PropTypes.string
};

export default Coupons;
