import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Breadcrumb,
  Form,
  Input,
  Switch,
  Button,
  Select,
  Menu,
  Dropdown,
  Icon,
  Card
} from 'antd';
import build from 'redux-object';

import UploadImage from '@/src/components/UploadImage';
import Audit from '@/src/components/Audit/index';

import {
  getDelivererById,
  getBlankDeliverer,
  getUpdateDeliverer,
  getCreateDeliverer
} from '@/src/store/modules/deliverers/slice';
import { getHubs } from '@/src/store/modules/hubs-data/slice';

import { formatDDDPhone } from '@/src/utils/formatPhone';

const { Option } = Select;

export const DelivererPage = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  const [hubsData, setHubsData] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  const isNewDeliverer = id === 'novo-entregador';

  const handleImageError = () => {
    setUploadImage([]);
  };

  useEffect(() => {
    if (!isNewDeliverer) {
      dispatch(getDelivererById(id));
    } else {
      dispatch(getBlankDeliverer());
    }
    dispatch(
      getHubs({
        query: {
          fields: [{ key: 'hubs', value: 'internal_id,name' }],
          sort: 'name',
          perPage: 500
        }
      })
    );

    // eslint-disable-next-line
  }, [dispatch, isNewDeliverer, id]);

  const { deliverer, isLoading } = useSelector(state => state.deliverers);

  const hubs = useSelector(state => state.hubsData.list);

  useEffect(() => {
    const hubsObj = ((hubs.meta && hubs.meta['/hubs'].data) || []).map(object =>
      build(hubs, 'hubs', object.id, { ignoreLinks: true })
    );
    setHubsData(hubsObj);
  }, [hubs]);

  useEffect(() => {
    if (Object.keys(deliverer).length > 0) {
      setFieldsValue({
        select: deliverer.attributes?.hub?.id || undefined
      });
      setUploadImage(deliverer.attributes?.image_url || []);
      setActiveState(!deliverer.attributes?.banned);
    } else {
      setUploadImage([]);
      setFieldsValue({
        select: undefined
      });
      setActiveState(false);
    }
  }, [deliverer, setFieldsValue, dispatch]);

  const handleActiveStatus = () => {
    setActiveState(prev => setActiveState(!prev));
  };

  const checkIfHasImage = () => {
    if (uploadImage.length === 0) {
      setWithoutImage(true);
      return false;
    }
    setWithoutImage(false);
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');

      let imageChange = uploadImage;

      // if (imageChange.includes('api')) {
      //   imageChange = '';
      // }

      if (imageChange.includes('data:image')) {
        imageChange = removeData(imageChange);
      }

      const payload = {
        data: {
          type: 'users',
          id: deliverer.attributes.external_id,
          attributes: {
            name: values.name,
            email: values.email,
            kind: values.kind,
            phone: values.phone,
            hubs: values.select,
            image: imageChange,
            plate: values.plate,
            banned: !values.active
          }
        }
      };

      if (isNewDeliverer) {
        dispatch(getCreateDeliverer(payload));
      } else {
        dispatch(getUpdateDeliverer(payload));
      }
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const handlePhoneNumber = value => {
    const formatedPhone = formatDDDPhone(value.target.value);
    // eslint-disable-next-line no-param-reassign
    value.target.value = formatedPhone;
  };

  return (
    <>
      <Audit
        id={id}
        title="ENTREGADOR"
        type="Deliverer"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>{id ? '' : 'Novo '}Entregador</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/entregadores" style={{ textDecoration: 'underline' }}>
            Entregadores
          </Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {id !== 'novo-entregador'
            ? deliverer?.attributes?.name || ''
            : 'Novo Entregador'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          marginTop: '40px'
        }}
      >
        <Card className="no-shadow" extra={isNewDeliverer ? '' : actionsExtra}>
          <Row
            type="flex"
            justify="space-between"
            style={{ padding: '40px 10px 20px' }}
          >
            <Col span={7}>
              <UploadImage
                setUploadImage={setUploadImage}
                uploadImage={uploadImage}
                withoutImage={withoutImage}
                handleImageError={handleImageError}
                setWithoutImage={setWithoutImage}
                isRound
                recommendedSize="516 x 360"
              />
            </Col>
            <Col span={16}>
              <Form style={{ marginTop: 10 }}>
                <Row gutter={16}>
                  <Col span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Hub associado
                    </p>
                    <Form.Item>
                      {getFieldDecorator('select', {
                        rules: [
                          {
                            required: true,
                            message: 'Selecione o Hub associado'
                          }
                        ]
                      })(
                        <Select placeholder="Hubs">
                          {hubsData?.map(item => (
                            <Option
                              key={item.internalId}
                              value={item.internalId}
                            >
                              {item.name || '-'}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Nome do entregador
                    </p>
                    <Form.Item>
                      {getFieldDecorator('name', {
                        initialValue: deliverer?.attributes?.name,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha o nome do entregador'
                          }
                        ]
                      })(<Input placeholder="Nome do entregador" />)}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Telefone
                    </p>
                    <Form.Item>
                      {getFieldDecorator('phone', {
                        initialValue: deliverer?.attributes?.phone,
                        rules: [
                          {
                            required: true,
                            message:
                              'Por favor, preencha o telefone do entregador'
                          }
                        ]
                      })(
                        <Input
                          onChange={value => handlePhoneNumber(value)}
                          placeholder="(XX) XXXXX-XXXX"
                          maxLength={15}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      E-mail
                    </p>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        initialValue: deliverer?.attributes?.email,
                        rules: [
                          {
                            type: 'email',
                            message: 'Este email não é válido!'
                          },
                          {
                            required: true,
                            message: 'Por favor, preencha o email do entregador'
                          }
                        ]
                      })(<Input placeholder="nome@email.com" />)}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Placa do veículo
                    </p>
                    <Form.Item>
                      {getFieldDecorator('plate', {
                        initialValue: deliverer?.attributes?.plate,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha a placa do entregador'
                          }
                        ]
                      })(<Input placeholder="XXX0000" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={10}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>Ativo</p>
                    {getFieldDecorator(
                      'active',
                      {}
                    )(
                      <Switch
                        checked={activeState}
                        onChange={handleActiveStatus}
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={24}>
                    <Button
                      className="ant-btn ant-btn-primary"
                      style={{ left: '80%' }}
                      onClick={onSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

DelivererPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const DelivererPageFrom = Form.create({ name: 'DelivererPage' })(DelivererPage);

export default DelivererPageFrom;
