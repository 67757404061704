import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Tabs } from 'antd';

import { getDepartments } from '@/src/store/modules/departments/slice';

import Tables from '@/src/components/Tables';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

const { TabPane } = Tabs;

function Departments() {
  const dispatch = useDispatch();
  const [arquived, setArquived] = useState(false);

  const { departments, query, isLoading } = useSelector(
    state => state.departments
  );
  const history = useHistory();
  const typeTable = 'departments';

  const changeTabs = () => {
    if (arquived) {
      setArquived(false);
    } else {
      setArquived(true);
    }
  };

  const getDepartmentsHandler = useCallback(() => {
    const queryWithFilter = arquived
      ? {
          query: {
            filters: {
              archived: true
            }
          }
        }
      : {
          query: {
            filters: {
              not_archived: true
            }
          }
        };
    dispatch(getDepartments(queryWithFilter));
    // eslint-disable-next-line
  }, [arquived]);

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    // console.log(params);
    getDepartmentsHandler({ query: params });
  }, [getDepartmentsHandler, history, arquived]);

  useEffect(() => {
    dispatch(
      getDepartments({
        query: {
          filters: {
            not_archived: true
          }
        }
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <h1 style={{ marginBottom: 5 }}>Departamentos</h1>
          <span>Seções do app</span>
        </div>
        <div>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/departamentos/novo')}
          >
            Novo departamento
          </Button>
        </div>
      </Row>
      <div className="section-wrapper">
        <Tabs onChange={changeTabs} style={{ marginBottom: 20 }} type="card">
          <TabPane tab="Ativos" key="1" />
          <TabPane tab="Arquivados" key="2" />
        </Tabs>
        <hr style={{ margin: '-20px 0 10px' }} />
        <Tables
          data={departments}
          className="even-gray"
          rowKey={record => record.id}
          contentToShow={typeTable}
          history={history}
          query={query}
          populateTables={getDepartmentsHandler}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default Departments;
