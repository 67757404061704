export const checkOrdersUpdate = (current, response, stockist = false) => {
  const orders = response.data.data;
  const check = orders.every(order => {
    const currentOrder = current.find(item => item.id === order.id);
    if (!currentOrder) {
      console.log('no current order', order.id);
      return stockist;
    }
    const {
      status,
      has_alert,
      has_return_request,
      has_cancelation_request,
      sale_invoice_number,
      embark_require_invoice
    } = order.attributes;
    const {
      hasAlert,
      hasReturnRequest,
      hasCancelationRequest,
      saleInvoiceNumber,
      embarkRequireInvoice
    } = currentOrder;
    if (has_alert !== hasAlert) {
      console.log('has alert', order.id, hasAlert, has_alert);
      return false;
    }
    if (has_return_request !== hasReturnRequest) {
      console.log('has return', order.id, hasReturnRequest, has_return_request);
      return false;
    }
    if (has_cancelation_request !== hasCancelationRequest) {
      console.log(
        'has cancelation',
        order.id,
        hasCancelationRequest,
        has_cancelation_request
      );
      return false;
    }
    if (status !== currentOrder.status) {
      console.log('status', order.id, currentOrder.status, status);
      return false;
    }
    if (embark_require_invoice !== embarkRequireInvoice) {
      console.log(
        'embark require invoice',
        order.id,
        embarkRequireInvoice,
        embark_require_invoice
      );
      return false;
    }
    if (
      status === 'packed' &&
      embark_require_invoice &&
      sale_invoice_number !== saleInvoiceNumber
    ) {
      console.log('invoice', order.id, saleInvoiceNumber, sale_invoice_number);
      return false;
    }
    return true;
  });
  // console.log('checkOrdersUpdate', check);
  return !check;
};

export const checkDeliveredOrders = (current, response) => {
  const ordersData = response.data.data;
  const dispatchedOrders = current.filter(item => item.status === 'dispatched');
  const check = dispatchedOrders.every(order => {
    const notDelivered = ordersData.find(item => item.id === order.id);
    if (!notDelivered) {
      console.log('delivered', order.id);
      return false;
    }
    return true;
  });
  // console.log('checkDeliveredOrders', check);
  return !check;
};

export const hasSearchFilter = filters => {
  const hasSearch = filters.find(item => item.key === 'search');
  // const hasFilterPills = filters.filter(
  //   item =>
  //     item.key === 'has_alert' ||
  //     item.key === 'has_return_request' ||
  //     item.key === 'has_cancelation_request' ||
  //     item.key === 'has_invoice_error'
  // );
  // if (hasSearch || hasFilterPills.length) return true;
  if (hasSearch) return true;
  return false;
};

const sortByNewest = (a, b) => {
  if (Number(a.id) > Number(b.id)) {
    return -1;
  }
  if (Number(a.id) < Number(b.id)) {
    return 1;
  }
  return 0;
};

export const handleStatusPill = data => {
  let pill = {
    status: data.status,
    steps: 1
  };
  if (data?.orderRequests?.length) {
    const arr = [...data.orderRequests];
    const sortedArr = arr.sort(sortByNewest);
    let status;
    if (sortedArr[0].requestResolved) {
      status =
        sortedArr[0].kind === 'return'
          ? 'return_request_resolved'
          : 'cancelation_request_resolved';
    } else {
      status =
        sortedArr[0].kind === 'return'
          ? 'return_request'
          : 'cancelation_request';
    }
    pill = {
      status,
      steps: 2
    };
  } else if (window.location.pathname === '/expedicao') {
    pill = {
      status: data.status,
      steps: 6
    };
  }
  return pill;
};

export const buildQueryByHub = (queryObj, accountHubs) => {
  const payload = {
    query: {
      ...queryObj.query
    }
  };
  if (accountHubs && accountHubs !== 'all') {
    payload.query.filters = [
      ...payload.query.filters,
      {
        key: 'hub_internal_id',
        type: 'match',
        value: accountHubs
      }
    ];
  }
  return payload;
};

// export default checkOrdersUpdate;
