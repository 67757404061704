import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { Search } = Input;

const InputSearch = props => {
  const { placeholder, populateResult, width, disabled } = props;

  return (
    <Search
      placeholder={placeholder}
      enterButton="publicar"
      size="small"
      style={{ width: `${width}` }}
      className="timeline-order-input"
      onSearch={e => populateResult(e)}
      disabled={disabled}
    />
  );
};

InputSearch.defaultProps = {
  placeholder: '',
  width: '',
  disabled: false
};

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  populateResult: PropTypes.func.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool
};

export default InputSearch;
