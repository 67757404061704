import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { useBreakpoint } from '@/src/context/BreakpointContext';

import './Counter.scss';

const Counter = ({
  className,
  packType,
  counterTitle,
  handleCounterChange,
  maxQty
}) => {
  const [inputValue, setInputValue] = useState(packType ? maxQty : 0);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [decrementDisabled, setDecrementDisabled] = useState(true);
  const [incrementDisabled, setIncrementDisabled] = useState(false);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (inputValue === 0) {
      setDecrementDisabled(true);
    } else {
      setDecrementDisabled(false);
    }
    if (inputValue >= maxQty || maxQty === 0) {
      setIncrementDisabled(true);
    } else {
      setIncrementDisabled(false);
    }
  }, [inputValue, maxQty]);

  useEffect(() => {
    if (decrementDisabled && incrementDisabled) {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
  }, [decrementDisabled, incrementDisabled]);

  useEffect(() => {
    if (packType) setInputValue(maxQty);
  }, [maxQty, packType]);

  const increment = () => setInputValue(prevState => prevState + 1);

  const decrement = () => setInputValue(prevState => prevState - 1);

  const handleInputChange = value => handleCounterChange(value);

  return (
    <div
      className={`counter-wrapper ${className}`}
      style={{
        display: 'flex',
        flexDirection:
          breakpoint.lg && className !== 'counter-wrapper__collect--dk'
            ? 'row'
            : 'column',
        alignItems: breakpoint.lg ? 'center' : 'flex-start'
      }}
    >
      {counterTitle && <span>{counterTitle}</span>}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <button
          className="counter-button counter-button__decrement"
          type="button"
          onClick={decrement}
          disabled={decrementDisabled}
        >
          –
        </button>
        <div style={{ height: '32px' }}>
          <Input
            placeholder={`0/${maxQty}`}
            value={`${inputValue}/${maxQty}`}
            disabled={inputDisabled}
            onChange={handleInputChange(inputValue)}
          />
        </div>
        <button
          className="counter-button"
          type="button"
          onClick={increment}
          disabled={incrementDisabled}
        >
          +
        </button>
      </div>
    </div>
  );
};

Counter.defaultProps = {
  packType: false,
  className: '',
  counterTitle: '',
  handleCounterChange: () => {}
};

Counter.propTypes = {
  className: PropTypes.string,
  counterTitle: PropTypes.string,
  handleCounterChange: PropTypes.func,
  packType: PropTypes.bool,
  maxQty: PropTypes.number.isRequired
};

export default Counter;
