import React, { useState } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import PropTypes from 'prop-types';

const EditCoupon = props => {
  const { form, coupon, editCouponOnSubmit, loading } = props;

  const { getFieldDecorator, validateFields } = form;

  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      editCouponOnSubmit({
        code: values.coupon,
        customer_id: coupon?.attributes?.customer_id
      });
      setIsVisible(false);
    });
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Editar Cupom</Button>
      <Modal
        visible={isVisible}
        title="EDITAR CUPOM"
        okText="salvar edição"
        onCancel={() => setIsVisible(false)}
        className="generate-cupons"
        onOk={onSubmit}
        okButtonProps={{
          disabled: loading,
          loading
        }}
      >
        <strong>Nome/Código</strong>
        <Form name="generate_cupons">
          <Form.Item style={{ marginBottom: 5 }}>
            {getFieldDecorator('coupon', {
              initialValue: coupon?.attributes?.code, // alterar para coupon
              rules: [
                {
                  required: true,
                  message: 'Esse campo é obrigatório'
                },
                {
                  message: 'Por favor, preencha acima de 2 caracteres',
                  min: 2
                },
                {
                  validator: (rule, value, cb) => value.length < 12,
                  message: 'Ultrapassou o número de caracteres permitidos'
                },
                {
                  pattern: /^[a-z0-9@]+$/i,
                  message: 'Utilize apenas letras e números'
                }
              ]
            })(<Input placeholder="ex.: LENUS10" />)}
          </Form.Item>
        </Form>
        <ul style={{ paddingInlineStart: 20 }}>
          <li>Utilize entre 1 a 11 caracteres</li>
          <li>Utilize letras e números</li>
        </ul>
      </Modal>
    </>
  );
};

EditCoupon.propTypes = {
  loading: PropTypes.bool.isRequired,
  coupon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  editCouponOnSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const EditCouponForm = Form.create({ name: 'edit_cupons_form' })(EditCoupon);

export default EditCouponForm;
