import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPriceIndexing } from '@/src/store/modules/price-indexing/slice';
import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import { Col, Row, Select, Input, Button, Icon, Table } from 'antd';
import filterIcon from '@/src/assets/images/filter-icon.svg';
import FilterModal from '../containers/PriceIndexingFilterModal';

import style from './PriceIndex.module.scss';

const { Option } = Select;
const { Search } = Input;

export const PriceIndexingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [priceTableSearch, setPriceTableSearch] = useState('');
  const [skusQty, setSkusQty] = useState('9000');
  const [skusAvailableType, setSkusAvailableType] = useState('sku_price_lte');
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [filters, setFilters] = useState([]);

  const {
    hubsPricesList,
    priceIndexingQuery: query,
    priceIndexingLoading: isLoading
  } = useSelector(state => {
    return state.priceIndexing;
  });

  const getPriceIndexingHandler = useCallback(
    urlQuery => {
      return dispatch(getPriceIndexing({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getPriceIndexingHandler({
      query: {
        ...params
        // hub_ids: '1,6'
      }
    });

    if (params.price_table_ids) setPriceTableSearch(params.price_table_ids);
    if (params.sku_price_lte) {
      setSkusQty(params.sku_price_lte);
      setSkusAvailableType('sku_price_lte');
    }
    if (params.sku_price_gte) {
      setSkusQty(params.sku_price_gte);
      setSkusAvailableType('sku_price_gte');
    }
  }, [history, getPriceIndexingHandler]);

  const handleExpandedRow = clickedItem => {
    const expandedRowColumn = [
      {
        title: 'Hub / Loja',
        width: '20%',
        dataIndex: '',
        key: 'hub'
        // render: record => <p>b</p>
      },
      {
        title: 'Código da tabela',
        align: 'center',
        width: '15%',
        dataIndex: 'price_table_id',
        key: 'price_table_id'
      },
      {
        title: 'Nome da tabela',
        width: '50%',
        dataIndex: 'price_table_name',
        key: 'price_table_name'
      },
      {
        title: 'SKUs',
        width: '8%',
        dataIndex: 'sku_prices',
        key: 'sku_prices'
      }
      // {
      //   title: 'Vigência',
      //   width: '27%',
      //   dataIndex: '',
      //   key: 'expanded_vigency',
      //   render: record => <p>b</p>
      // }
    ];

    if (clickedItem?.prices.length > 0) {
      return (
        <Table
          showHeader={false}
          style={{ margin: '-12px -14px ', border: 'none' }}
          columns={expandedRowColumn}
          dataSource={clickedItem?.prices}
          pagination={false}
        />
      );
    }

    return false;
  };

  const columns = [
    {
      title: 'Hub / Loja',
      dataIndex: 'hub_internal_id',
      key: 'hub',
      width: '20%'
    },
    {
      title: 'Código da Tabela',
      width: '15%',
      align: 'center',
      key: 'code',
      render: record => {
        const first = record?.prices[0] || {};
        return first.price_table_id;
      }
    },
    {
      title: 'Nome da Tabela',
      width: '50%',
      key: 'name',
      render: record => {
        const first = record?.prices[0] || {};
        return <span> {first?.price_table_name} </span>;
      }
    },
    {
      title: 'SKUs',
      width: '8%',
      key: 'sku',
      render: record => {
        const first = record?.prices[0] || {};
        return <span> {first?.sku_prices} </span>;
      }
    }
    // {
    //   title: 'Vigência',
    //   dataIndex: 'number',
    //   key: 'vigency',
    //   width: '27%',
    //   render: record => (record || '-')
    // }
  ];

  useEffect(() => {
    const hubsSelected = [];
    selectedHubs.forEach(hub => {
      if (query?.hub_ids.indexOf(hub.key) !== -1) {
        hubsSelected.push({
          label: hub.label,
          key: hub.key
        });
      }
    });
    setFilters(hubsSelected);
    // eslint-disable-next-line
  }, [query]);

  const sendSearchAndFilter = (data, isFilter) => {
    const params = buildQueryFromUrl(history);
    let newParams = data;
    if (isFilter && data) {
      // setFilters(data);
      const hubContent = data.map(param => param.key)?.join(',');
      newParams = { hub_ids: hubContent };
    }
    getPriceIndexingHandler({
      query: { ...params, ...newParams }
    });
    setShowModal(false);
  };

  const handleSearch = (newValue, itemToEdit) => {
    const otherAvalableType =
      skusAvailableType === 'sku_price_lte' ? 'sku_price_gte' : 'sku_price_lte';
    let search = {};

    let skuQtyValue;
    if (skusAvailableType) {
      if (itemToEdit === 'skuAvailable') {
        skuQtyValue = newValue;
      } else if (skusQty) {
        skuQtyValue = skusQty;
      }
    }

    search = {
      price_table_ids:
        itemToEdit === 'priceTable' ? newValue : priceTableSearch,
      [skusAvailableType || 'sku_price_gte']: skuQtyValue,
      [otherAvalableType]: undefined
    };

    sendSearchAndFilter(search, false);
  };

  const handleDeleteFilter = filterToRemove => {
    const newFilters = filters.filter(
      filterItem => filterItem.key !== filterToRemove.key
    );
    setFilters(newFilters);

    const newSelectedHubs = selectedHubs.filter(
      hubItem => hubItem.key !== filterToRemove.key
    );
    setSelectedHubs(newSelectedHubs);

    sendSearchAndFilter(newSelectedHubs, true);
  };

  const changePagination = (page, perPage) => {
    const params = buildQueryFromUrl(history);
    getPriceIndexingHandler({
      query: {
        ...params,
        page,
        perPage
      }
    });
  };

  const changePerPage = (current, perPage) => {
    const params = buildQueryFromUrl(history);
    getPriceIndexingHandler({
      query: {
        ...params,
        page: query.perPage !== perPage ? 1 : current,
        perPage
      }
    });
  };

  return (
    <>
      <FilterModal
        setShowModal={value => setShowModal(value)}
        showModal={showModal}
        handleSetFilter={data => sendSearchAndFilter(data, true)}
        selectedHubs={selectedHubs}
        setSelectedHubs={setSelectedHubs}
      />
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{ marginBottom: 20 }}
      >
        <Col>
          <h1 style={{ marginBottom: 0 }}>Indexação de Preços</h1>
        </Col>

        {/* <Col>
          <Row type="flex">
            <span>Última atualização</span>
            <Row>
              <span>DD/MM/AAAA</span>
              <span>00:00</span>
            </Row>
          </Row>
        </Col> */}
      </Row>
      <div className={style.contentBorder}>
        <Row type="flex" justify="space-between" style={{ marginBottom: 16 }}>
          <Col span={18}>
            <Col span={6} style={{ marginRight: '24px' }}>
              <p className={style.inputTitle}>Código da tabela</p>

              <Search
                allowClear
                className="price-indexing-search"
                style={{ height: '40px' }}
                placeholder="Buscar por código"
                onChange={evt => {
                  const { target } = evt;

                  setPriceTableSearch(target.value || '');
                }}
                onSearch={text => {
                  handleSearch(text, text === '' ? 'priceTable' : '');
                }}
                value={priceTableSearch}
              />
            </Col>
            <Col span={16}>
              <p className={style.inputTitle}>Quantidade de SKUs disponível</p>
              <Row type="flex" gutter={8}>
                <Col>
                  <Select
                    className={style.priceIndexSelect}
                    defaultValue="sku_price_lte"
                    style={{ width: '125px', height: 40 }}
                    onChange={evt => setSkusAvailableType(evt)}
                    value={skusAvailableType}
                    height={40}
                    placeholder="Selecione"
                  >
                    <Option key="sku_price_gte">Maior que</Option>
                    <Option key="sku_price_lte">Menor que</Option>
                  </Select>
                </Col>
                <Col>
                  <Search
                    allowClear
                    type="number"
                    className="price-indexing-search"
                    style={{ height: '40px', width: '100px' }}
                    defaultValue="9000"
                    placeholder="Quantidade"
                    value={skusQty}
                    onChange={evt => {
                      const { target } = evt;
                      setSkusQty(target.value || '');
                    }}
                    onSearch={text => {
                      handleSearch(text, text === '' ? 'skuAvailable' : '');
                    }}
                  />
                </Col>

                <Button
                  className="ant-btn ant-btn-primary"
                  style={{ height: '40px', marginLeft: 16 }}
                  onClick={() => handleSearch()}
                  // loading={isLoading}
                >
                  Buscar
                  <Icon type="search" />
                </Button>
              </Row>
            </Col>
          </Col>

          <Col>
            {/* <Button
              className="ant-btn ant-btn-secondary"
              style={{ height: '40px', marginRight: '8px' }}
              // onClick={() => handleShowModal(data, '')}
            >
              Exportar CSV
            </Button> */}

            <Button
              onClick={() => setShowModal(true)}
              size="large"
              disabled={hubsPricesList.length < 1}
              className={`filter-component__button ${style.filterButton}`}
              // className="filter-component__button"
            >
              <Row gutter={8} type="flex" justify="center" align="middle">
                <img src={filterIcon} alt="Ícone de filtro" />
              </Row>
            </Button>
          </Col>
        </Row>

        <Row>
          {filters.map(filter => (
            <span
              key={filter.key}
              style={{
                padding: 8,
                backgroundColor: '#EBEBEB',
                borderRadius: 20,
                marginRight: 15
              }}
            >
              {filter.label}
              <Button
                className="ant-btn ant-btn-link"
                onClick={() => handleDeleteFilter(filter)}
                style={{
                  textTransform: 'initial',
                  color: '#0A0A0A',
                  padding: '0',
                  marginLeft: 4
                }}
              >
                <Icon
                  type="close-circle"
                  theme="filled"
                  style={{ color: '' }}
                />
              </Button>
            </span>
          ))}
        </Row>
        <Row style={{ position: 'relative' }}>
          <div
            className={style.centered}
            style={{
              position: 'absolute',
              margin: '16px 0px',
              height: '32px'
            }}
          >
            <p style={{ margin: 0 }}>
              <span style={{ fontWeight: 'bold', marginRight: 4 }}>
                {query.count}
              </span>
              Resultados
            </p>
          </div>
          <Table
            className="price-indexing-table"
            data-test="orders-table"
            columns={columns}
            dataSource={hubsPricesList}
            expandRowByClick
            loading={isLoading}
            expandedRowRender={handleExpandedRow}
            size="medium"
            rowKey={record => record.hub_internal_id}
            pagination={{
              position: 'top',
              pageSize: query?.perPage || 20,
              showSizeChanger: true,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
              total: query.count,
              current: query.page,
              onShowSizeChange: changePerPage,
              onChange: changePagination
            }}
            style={{ paddingTop: '0' }}
          />
        </Row>
      </div>
    </>
  );
};

export default PriceIndexingPage;
