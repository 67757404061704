/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import EvolutionDiagramClientIcon from '@/src/assets/images/order/order-evo-diagram-client.png';
import EvolutionDiagramOperationsIcon from '@/src/assets/images/order/order-evo-diagram-operations.png';
import EvolutionDiagramStyles from './EvolutionDiagram.module.scss';

const EvolutionDiagram = props => {
  const {
    orderAttributes: { status, deliverer_received_by },
    orderStatus
  } = props;

  const isCanceled = status === 'canceled' ? 'canceled' : '';

  return (
    <section className={EvolutionDiagramStyles.EvolutionDiagram}>
      <div
        className={`${EvolutionDiagramStyles.EvolutionDiagram__line} ${EvolutionDiagramStyles['EvolutionDiagram__line--primary']}`}
      >
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']}>
          <Tooltip
            placement="top"
            title={`Recebido por ${deliverer_received_by || '-'}`}
          >
            <span className={EvolutionDiagramStyles['client-name']}>
              {deliverer_received_by || ''}
            </span>
          </Tooltip>
        </span>
        <div className={EvolutionDiagramStyles['EvolutionDiagram__line--icon']}>
          <span className={EvolutionDiagramStyles['line-icon']}>
            <img
              src={EvolutionDiagramClientIcon}
              alt="Order Evolution Diagram Client Icon"
            />
          </span>
          <span className={EvolutionDiagramStyles['line-name']}>Cliente</span>
        </div>
      </div>
      <div
        className={`${EvolutionDiagramStyles.EvolutionDiagram__line} ${EvolutionDiagramStyles['EvolutionDiagram__line--fill']}`}
      >
        <div
          className={`
            ${EvolutionDiagramStyles['fill-bar']}
            ${EvolutionDiagramStyles[`${orderStatus.currentStatus}`]}
            ${EvolutionDiagramStyles[`${isCanceled}`]}
          `}
        />
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.started[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.started[0] ? orderStatus.started[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.picked[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.picked[0] ? orderStatus.picked[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.packed[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.packed[0] ? orderStatus.packed[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.billed[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.billed[0] ? orderStatus.billed[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.separated[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.separated[0] ? orderStatus.separated[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.dispatched[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.dispatched[0] ? orderStatus.dispatched[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.delivering[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.delivering[0] ? orderStatus.delivering[1] : ''}
        </span>
        <span
          className={`${EvolutionDiagramStyles['position-time']} ${
            orderStatus.delivered[0] ? EvolutionDiagramStyles['is-active'] : ''
          }`}
        >
          {orderStatus.delivered[0] ? orderStatus.delivered[1] : ''}
        </span>
      </div>
      <div
        className={`${EvolutionDiagramStyles.EvolutionDiagram__line} ${EvolutionDiagramStyles['EvolutionDiagram__line--secondary']}`}
      >
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <span className={EvolutionDiagramStyles['position-dot']} />
        <div className={EvolutionDiagramStyles['EvolutionDiagram__line--icon']}>
          <span className={EvolutionDiagramStyles['line-icon']}>
            <img
              src={EvolutionDiagramOperationsIcon}
              alt="Order Evolution Diagram Ops Icon"
            />
          </span>
          <span className={EvolutionDiagramStyles['line-name']}>Operações</span>
        </div>
      </div>
    </section>
  );
};

EvolutionDiagram.propTypes = {
  orderAttributes: PropTypes.objectOf(PropTypes.any).isRequired,
  orderStatus: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EvolutionDiagram;
