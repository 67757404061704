import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form, Input, DatePicker } from 'antd';
import moment from 'moment';

import { getUpdatePriceTable } from '@/src/store/modules/price-tables/slice';

import style from '../../Policy.module.scss';

const PriceTableEdit = ({ form, showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const handleEditSubmit = () => {
    form.validateFields((err, values) => {
      if (err) return;
      const payload = {
        name: values.name,
        start_date: moment(values.start_date).format('YYYY-MM-DD HH:mm:ss Z'),
        end_date: values.end_date
          ? moment(values.end_date).format('YYYY-MM-DD HH:mm:ss Z')
          : '',
        id: showModal.id
      };
      dispatch(getUpdatePriceTable(payload));
      setShowModal({});
      form.resetFields();
    });
  };

  return (
    <Modal
      visible={!!showModal.id}
      className="centered-footer"
      title={`EDITAR ${showModal?.name?.toUpperCase() || 'TABELA'}`}
      centered
      onOk={handleEditSubmit}
      onCancel={() => {
        setShowModal({});
        form.resetFields();
      }}
    >
      <Form>
        <h3 className={style.tableEdit__label}>Nome</h3>
        <Form.Item>
          {form.getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Campo obrigatório'
              }
            ],
            initialValue: showModal?.name || undefined
          })(<Input placeholder="Exemplo: Tabela Promocional" />)}
        </Form.Item>
        <h3 className={style.tableEdit__label}>Vigência</h3>
        <p>Selecione data e horário nos campos abaixo:</p>
        <Form.Item>
          {form.getFieldDecorator('start_date', {
            rules: [
              {
                required: true,
                message: 'Campo obrigatório'
              }
            ],
            initialValue: showModal?.start_date
              ? moment(showModal.start_date)
              : undefined
          })(
            <DatePicker
              showTime
              format="DD-MM-YYYY HH:mm:ss"
              placeholder="Início"
            />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('end_date', {
            initialValue: showModal?.end_date
              ? moment(showModal.end_date)
              : undefined
          })(
            <DatePicker
              showTime
              format="DD-MM-YYYY HH:mm:ss"
              placeholder="Fim"
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

PriceTableEdit.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  showModal: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowModal: PropTypes.func.isRequired
};

const PriceTableEditForm = Form.create({ name: 'edit-table-form' })(
  PriceTableEdit
);

export default PriceTableEditForm;
