import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

const initialState = {
  list: {
    meta: {
      '/cities': {
        data: []
      }
    }
  },
  modalList: {
    meta: {
      '/cities': {
        data: []
      }
    }
  },
  isLoading: false
  // city: {},
};

const citiesListSlice = createSlice({
  name: '@citiesData',
  initialState,
  reducers: {
    resetCitiesState() {
      const state = {
        ...initialState
      };
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setCities(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setModalCities(prevState, action) {
      const state = prevState;
      state.modalList = action.payload;
      return state;
    },
    // setCitySingle(prevState, action) {
    //   const state = prevState;
    //   state.city = action.payload;
    //   return state;
    // },
    setCreateCity(prevState, action) {
      const state = prevState;
      state.list = merge(state.list, action.payload);
      state.list.meta['/cities'].data = [
        ...state.list.meta['/cities'].data,
        ...action.payload.meta['/new-city'].data
      ];
      return state;
    },
    getCities(state) {
      return state;
    },
    // getCitySingle(state) {
    //   return state;
    // },
    getCreateCity(state) {
      return state;
    }
  }
});

export const {
  resetCitiesState,
  setIsLoading,
  setCities,
  setModalCities,
  setCreateCity,
  getCities,
  getCreateCity
  // getCitySingle,
  // setCitySingle
} = citiesListSlice.actions;

export default citiesListSlice.reducer;
