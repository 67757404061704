import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale-provider/pt_BR';

import {
  apiAuth,
  apiAdmin,
  apiPortal,
  apiDispatch,
  apiDispatchFile
} from './api/config';

import useInterceptors from './hooks/useInterceptors';

import store from './store';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import bugsnag from './services/bugsnag';

const ErrorBoundary = bugsnag.getPlugin('react');

const { handleAuthToken, handleAuthError } = useInterceptors(store);

apiAuth.interceptors.request.use(async config => {
  const configuration = await handleAuthToken('auth', config);
  return configuration;
});

apiAdmin.interceptors.request.use(async config => {
  const configuration = await handleAuthToken('admin', config);
  return configuration;
});

apiPortal.interceptors.request.use(async config => {
  const configuration = await handleAuthToken('portal', config);
  return configuration;
});

apiDispatch.interceptors.request.use(async config => {
  const configuration = await handleAuthToken('dispatch', config);
  return configuration;
});

apiDispatchFile.interceptors.request.use(async config => {
  const configuration = await handleAuthToken('dispatch', config);
  return configuration;
});

apiAuth.interceptors.response.use(
  response => response,
  error => handleAuthError('auth', error)
);

apiAdmin.interceptors.response.use(
  response => response,
  error => handleAuthError('admin', error)
);

apiPortal.interceptors.response.use(
  response => response,
  error => handleAuthError('portal', error)
);

apiDispatch.interceptors.response.use(
  response => response,
  error => handleAuthError('dispatch', error)
);

apiDispatchFile.interceptors.response.use(
  response => response,
  error => handleAuthError('dispatch', error)
);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConfigProvider locale={ptBR}>
        <App />
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
