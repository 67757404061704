import React from 'react';
import { Link } from 'react-router-dom';

const departments = [
  {
    title: 'Departamento',
    align: 'left',
    dataIndex: 'department',
    render: (text, record) => (
      <Link
        to={`/departamentos/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {record.attributes.name}
      </Link>
    )
  }
];

export default departments;
