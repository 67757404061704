import { apiDispatch, apiAuth } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getAllDeliverers = payload => {
  const getUrl = dispatchQueryBuilder(payload, '/deliverers');
  return apiDispatch.get(getUrl);
};

export const getDeliverers = query => apiDispatch.get(`/deliverers?q=${query}`);

export const getDeliverer = id => apiDispatch.get(`/deliverers/${id}`);

export const getDelivererByInternalId = id =>
  apiDispatch.get(`/deliverers?filter[internal_id][match]=${id}`);

export const updateDeliverer = payload =>
  apiAuth.patch(`/users/${payload.data.id}?include=permissions`, payload);

// export const putDeliverer = params => {
//   const { payload, id } = params;
//   return new Promise((resolve, reject) => {
//     apiDispatch
//       .put(`/deliverers/${id}`, payload)
//       .then(res => resolve(res))
//       .catch(err => reject(err));
//   });
// };

// export const postDeliverer = payload => {
//   return new Promise((resolve, reject) => {
//     apiDispatch
//       .post(`/deliverers/`, payload)
//       .then(res => resolve(res))
//       .catch(err => reject(err));
//   });
// };
