import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import StatusPill from '@/src/components/StatusPill';
import { useBreakpoint } from '@/src/context/BreakpointContext';

const Header = ({
  orderNumber,
  orderAdress,
  orderValue,
  stepName,
  orderStatus,
  orderCancelation
}) => {
  const hasAlert = stepName === 'Devolução';

  let status = orderStatus;
  if (hasAlert)
    status = orderCancelation ? 'cancelation_request' : 'return_request';

  const breakpoint = useBreakpoint();

  const { contentLoading } = useSelector(state => state.expedition);

  return (
    <div className="ant-modal-title__wrapper">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Skeleton
            active
            title={false}
            loading={contentLoading}
            paragraph={{ rows: 2, width: [180, 180] }}
          >
            {hasAlert ? (
              <span style={{ textTransform: 'uppercase' }}>
                {orderCancelation ? 'Cancelamento' : stepName}
              </span>
            ) : (
              <span style={{ textTransform: 'uppercase' }}>
                {stepName} pedido #{orderNumber}
              </span>
            )}
          </Skeleton>
          {!contentLoading && (
            <div
              className={`ant-modal-title__subtitle ${hasAlert &&
                'ant-modal-title__subtitle--has-pill'}`}
            >
              {hasAlert ? (
                <>
                  <span className="ant-modal-title__info">
                    Pedido #{orderNumber}
                  </span>
                  {breakpoint.mobile && (
                    <div className="ant-modal-title__pill">
                      <StatusPill status={status} steps={hasAlert ? 2 : 6} />
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <span className="ant-modal-title__info">{orderAdress} |</span>
                  <span className="ant-modal-title__info">
                    Valor: {orderValue}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {breakpoint.lg && !contentLoading && (
          <div className="ant-modal-title__pill">
            <StatusPill status={status} steps={hasAlert ? 2 : 6} />
          </div>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  orderNumber: '',
  orderAdress: '',
  orderValue: '',
  stepName: '',
  orderStatus: '',
  orderCancelation: false
};

Header.propTypes = {
  orderNumber: PropTypes.string,
  orderAdress: PropTypes.string,
  orderValue: PropTypes.string,
  stepName: PropTypes.string,
  orderStatus: PropTypes.string,
  orderCancelation: PropTypes.bool
};

export default Header;
