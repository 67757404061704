import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from '@/src/services/moment';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  removeLocalStorageData,
  setToken,
  setRefreshToken,
  setTokenExpiration,
  decodeTokenExpiration
} from '@/src/services/auth';

import {
  resetAccountState,
  setAccount
} from '@/src/store/modules/account/slice';

import {
  loginLoading,
  loginAccountSuccess,
  loginAccountFailed,
  loginAccount,
  logoutAccount,
  newPassword
} from '@/src/store/modules/login/slice';

import { resetState as resetDispatchState } from '@/src/store/modules/expedition/slice';

import { signInPost } from '@/src/api/users';

import bugsnag from '@/src/services/bugsnag';

export function* logoutAccountSaga() {
  yield put(resetDispatchState());
  yield put(resetAccountState());
  yield call(removeLocalStorageData);
  yield put(push('/'));
}

export function* loginAccountSaga(action) {
  try {
    yield put(loginLoading());
    const payload = {
      data: {
        type: 'users',
        attributes: {
          email: action.payload.email,
          password: action.payload.password
        }
      }
    };
    const response = yield call(signInPost, payload);
    const normalized = normalize(response.data, { endpoint: '/account' });
    const parsed = (
      (normalized.meta && normalized.meta['/account'].data) ||
      []
    ).map(object =>
      build(normalized, 'users', object.id, { ignoreLinks: true })
    );
    const token = response.data.meta.auth_token;
    const refreshToken = response.data.meta.refresh_token;
    const account = parsed[0];
    bugsnag.user = {
      id: account.id,
      name: account.name,
      email: account.email
    };
    yield call(setToken, token);
    yield call(setRefreshToken, refreshToken);
    const decodeTokenExp = decodeTokenExpiration().exp;
    const tokenExp = moment(decodeTokenExp * 1000).format();
    yield call(setTokenExpiration, tokenExp);
    yield put(setAccount(account));
    yield put(loginAccountSuccess(response));
    yield put(newPassword(false));
  } catch (err) {
    if (err.response?.status === 403) {
      yield put(loginAccountFailed({ error: 'banned' }));
    } else if (err.response) {
      const error = err.response.data?.errors[0]?.detail || '';
      yield put(loginAccountFailed({ error }));
      bugsnag.notify(err);
    } else {
      yield put(loginAccountFailed({ error: 'cors' }));
    }
  }
}

function* loginSaga() {
  yield takeEvery(logoutAccount, logoutAccountSaga);
  yield takeEvery(loginAccount, loginAccountSaga);
}

export default loginSaga;
