import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';

import getFirstNameLetters from '@/src/utils/getFirstNameLetters';

import styles from './UserAvatar.module.scss';

const UserAvatar = ({ border }) => {
  const { account } = useSelector(state => state.account);
  return (
    <Avatar
      className={styles.container}
      style={{
        backgroundColor: account?.image ? 'transparent' : '#333',
        boxShadow: border ? '0 0 1px 1px #ccc' : 'none'
      }}
      src={account?.image || ''}
    >
      {account?.image ? '' : getFirstNameLetters(account?.name || '')}
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  border: false
};

UserAvatar.propTypes = {
  border: PropTypes.bool
};

export default UserAvatar;
