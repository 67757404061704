import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getOrders = payload => {
  const getUrl = queryBuilder(payload, '/orders');
  return apiPortal.get(getUrl);
};

export const getOrder = orderId => apiPortal.get(`/orders/${orderId}`);

export const cancelOrder = payload =>
  apiPortal.post(`/orders/${payload.id}/cancelation`, payload.data);

export const returnOrderItems = payload =>
  apiPortal.post(`/orders/${payload.id}/return`, payload.data);

export const getOrderTimeline = ({ object_id, object_type }) =>
  // apiPortal.get(`/timeline_events?filters[by_events][]=erp_status_changed`, {
  apiPortal.get(`/timeline_events`, {
    params: { object_id, object_type }
  });

export const getPushNotification = payload =>
  apiPortal.post(`/proactive_chat`, payload);

export const fraudOrder = payload =>
  apiPortal.patch(`/orders/${payload.id}`, { fraud_type: payload.value });

export const chargebackOrder = payload =>
  apiPortal.patch(`/orders/${payload.id}`, { fraud_type: payload.value });
