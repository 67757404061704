import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Card, Button, Skeleton } from 'antd';

import { setCurrentValues } from '@/src/store/modules/attribute-values/slice';

import AttributeCardSingle from '../AttributeCardSingle/index';

const AttributesCard = ({
  properties,
  productProperties,
  handleCheckbox,
  loading,
  editPermission
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [atributeProperties, setAttributeProperties] = useState([]);

  useEffect(() => {
    setAttributeProperties(properties);
  }, [properties]);

  const handleClick = () => {
    dispatch(setCurrentValues([{ name: '' }]));
    history.push('/atributos/novo');
  };

  return (
    <Col className="no-shadow" span={50}>
      <Card
        title="ATRIBUTOS"
        className="no-shadow attribute-cards"
        style={{ position: 'relative' }}
      >
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 4 }}
        >
          {editPermission && (
            <Button
              type="secondary"
              onClick={handleClick}
              style={{ position: 'absolute', top: '15px', right: '15px' }}
            >
              Novo Atributo
            </Button>
          )}
          {atributeProperties.length ? (
            atributeProperties.map(property => (
              <Col span={8} key={property?.id}>
                <AttributeCardSingle
                  property={property}
                  productProperties={productProperties}
                  handleCheckbox={handleCheckbox}
                  editPermission={editPermission}
                />
              </Col>
            ))
          ) : (
            <p>Não há atributos cadastrados</p>
          )}
        </Skeleton>
      </Card>
    </Col>
  );
};

AttributesCard.defaultProps = {
  properties: [],
  productProperties: [],
  handleCheckbox: () => {},
  loading: true
};

AttributesCard.propTypes = {
  properties: PropTypes.oneOfType([PropTypes.array]),
  productProperties: PropTypes.oneOfType([PropTypes.array]),
  handleCheckbox: PropTypes.func,
  loading: PropTypes.bool,
  editPermission: PropTypes.bool.isRequired
};

export default AttributesCard;
