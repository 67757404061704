import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table, List } from 'antd';
import moment from '@/src/services/moment';

import formatCurrency from '@/src/utils/formatCurrency';
import { dispatchStatus } from '@/src/utils/localeData';
import formatParityText from '@/src/utils/formatParityText';

const OrderReshipments = props => {
  const { reshipments, orderItems } = props;
  // console.log(orderItems);

  const columns = [
    {
      title: 'ID Petz',
      align: 'left',
      dataIndex: 'attributes.external_id'
    },
    // {
    //   title: 'Número',
    //   align: 'left',
    //   dataIndex: 'attributes.order_number'
    // },
    {
      title: 'Data de Criação',
      dataIndex: 'attributes.created_at',
      render: data => (data ? moment(data).format('DD/MM/YYYY HH:mm') : '-')
    },
    {
      title: 'Itens',
      dataIndex: 'attributes.order_items',
      render: data => {
        return data.length > 0 ? (
          <List
            size="small"
            className="ant-list-reshipment-items"
            dataSource={data}
            renderItem={item => {
              const orderItem = orderItems.find(
                i => i.attributes.external_reference === item.reference
              );
              const description = orderItem
                ? `${orderItem.attributes.sku_external_id ||
                    orderItem.attributes.external_reference} - ${
                    orderItem.attributes.title
                  } ${orderItem.attributes.sku_name}`
                : item.reference;
              return (
                <List.Item>
                  <span>
                    {description} | {formatParityText(item.quantity, 'unidade')}
                  </span>
                </List.Item>
              );
            }}
          />
        ) : (
          <span>Aguardando faturamento</span>
        );
      }
    },
    {
      title: 'Total',
      align: 'left',
      dataIndex: 'attributes.total',
      render: record =>
        record ? formatCurrency(record, true) : 'Aguardando faturamento'
    },
    {
      title: 'Status',
      dataIndex: 'attributes.status',
      render: data => dispatchStatus[data] || data || '-'
    }
  ];

  return (
    <section>
      <Card className="no-shadow" title="Reenvios" style={{ width: '100%' }}>
        <Table
          // loading={loading}
          pagination={false}
          dataSource={reshipments}
          columns={columns}
          rowKey={record => `${columns}-${record.id || record.number}`}
        />
      </Card>
    </section>
  );
};

OrderReshipments.propTypes = {
  reshipments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderItems: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired
};

export default React.memo(OrderReshipments);
