import { createSlice } from '@reduxjs/toolkit';
import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  highlights: [],
  highlight: [],
  isLoading: false,
  query: {
    page: 1,
    perPage: 20,
    url: '',
    filters: {
      not_archived: true
    }
  },
  count: 0
};

const highlightsSlice = createSlice({
  name: '@highlights',
  initialState,
  reducers: {
    setHighlights(prevState, action) {
      const state = prevState;
      state.highlights = action.payload.highlights;
      state.count = action.payload.count;
      state.isLoading = false;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      return state;
    },
    setHighlight(prevState, action) {
      const state = prevState;
      state.highlight = action.payload;
      state.isLoading = false;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getHighlights(state) {
      return state;
    },
    getHighlight(state) {
      return state;
    },
    getCreateHighlight(state) {
      return state;
    },
    updateHighlight(state) {
      return state;
    },
    archiveHighlight(state) {
      return state;
    },
    unarchiveHighlight(state) {
      return state;
    },
    getOrderByDragNDrop(state) {
      return state;
    }
  }
});

export const {
  setHighlights,
  setLoading,
  setHighlight,
  setPagination,
  setSort,
  getHighlights,
  getHighlight,
  getCreateHighlight,
  updateHighlight,
  archiveHighlight,
  unarchiveHighlight,
  getOrderByDragNDrop
} = highlightsSlice.actions;

export default highlightsSlice.reducer;
