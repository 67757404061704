import React from 'react';

import StatusPill from '@/src/components/StatusPill';
import { handleStatusPill } from '@/src/utils/dispatchUtils';

import moment from '@/src/services/moment';
import formatCurrency from '@/src/utils/formatCurrency';

const expedition = [
  {
    title: 'Pedido',
    key: 'number',
    render: data => (
      <span style={{ textDecoration: 'underline' }}>{data.number}</span>
    ),
    sorter: true
  },
  {
    title: 'Data de Criação',
    key: 'created_at',
    sorter: true,
    render: data => (
      <span>{moment(data.createdAt).format('DD/MM/YYYY HH:mm')}</span>
    )
  },
  {
    title: 'Última Atualização',
    key: 'updated_at',
    sorter: true,
    render: data => (
      <span>{moment(data.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
    )
  },
  {
    title: 'Cliente',
    key: 'customer',
    render: data => <span>{data.customerName}</span>,
    sorter: (a, b) => {
      const customerA = a.customerName || '';
      const customerB = b.customerName || '';
      return customerA.localeCompare(customerB);
    }
  },
  {
    title: 'Canal',
    key: 'sale_channel',
    render: data => <span>{data.saleChannelName || 'Zee.Now'}</span>,
    sorter: (a, b) => {
      const saleChannelA = a.saleChannelName || '';
      const saleChannelB = b.saleChannelName || '';
      return saleChannelA.localeCompare(saleChannelB);
    }
  },
  {
    title: 'Hub',
    key: 'hub',
    render: data => <span>{data.hubName}</span>,
    sorter: (a, b) => {
      const hubA = a.hubName || '';
      const hubB = b.hubName || '';
      return hubA.localeCompare(hubB);
    }
  },
  {
    title: 'Estoquista',
    key: 'user',
    render: data => <span>{data.userName || '–'}</span>,
    sorter: (a, b) => {
      const userA = a.userName || '';
      const userB = b.userName || '';
      return userA.localeCompare(userB);
    }
  },
  {
    title: 'Total',
    key: 'total',
    sorter: true,
    render: data => <span>{formatCurrency(data.total, true)}</span>
  },
  {
    title: 'Status',
    key: 'status',
    sorter: true,
    align: 'center',
    width: 160,
    render: data => (
      <StatusPill
        status={handleStatusPill(data).status}
        steps={handleStatusPill(data).steps}
        centered
      />
    )
  }
];

export default expedition;
