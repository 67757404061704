import React from 'react';

import Routes from '@/src/routes/routes';
import '@/src/styles/global.scss';

const App = () => {
  return <Routes />;
};

export default App;
