import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  aisles: [],
  count: 0,
  aisle: [],
  products: [],
  productsCount: 0,
  productsQuery: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  addedProducts: [],
  isLoading: false,
  isEditLoading: false
};

const aisleSlice = createSlice({
  name: '@aisles',
  initialState,
  reducers: {
    setAisles(prevState, action) {
      const state = prevState;
      state.aisles = action.payload.aisles;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.isLoading = false;
      state.count = action.payload.count;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setProductsByCategories(prevState, action) {
      const state = prevState;
      state.products = action.payload.products;
      state.productsCount = action.payload.count || state.productsCount;
      state.productsQuery = action.payload.query || state.productsQuery;
      return state;
    },
    getCategories(state) {
      return state;
    },
    getProductsByCategory(state) {
      return state;
    },
    setAddProductsToSave(prevState, action) {
      const state = prevState;
      state.addedProducts = action.payload;
      return state;
    },
    setOrderByCSVAllAisles(prevState, action) {
      const state = prevState;
      state.aisles = action.payload;
      return state;
    },
    setDeleteProducts(prevState, action) {
      const state = prevState;
      state.addedProducts = action.payload;
      return state;
    },
    setEditAisle(prevState, action) {
      const state = prevState;
      state.aisle = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setIsEditLoading(prevState, action) {
      const state = prevState;
      state.isEditLoading = action.payload;
      return state;
    },
    setOrderAllAisles(prevState, action) {
      const state = prevState;
      state.aisles = action.payload;
      return state;
    },
    getAddProductsToSave(state) {
      return state;
    },
    getDeleteProducts(state) {
      return state;
    },
    getCreateAisle(state) {
      return state;
    },
    getEditAisle(state) {
      return state;
    },
    getUpdateAisle(state) {
      return state;
    },
    inactiveAisle(state) {
      return state;
    },
    activeAisle(state) {
      return state;
    },
    archiveAisle(state) {
      return state;
    },
    unarchiveAisle(state) {
      return state;
    },
    getAisles(state) {
      return state;
    },
    sendCSVOrderBy(state) {
      return state;
    },
    exportCSVAisle(state) {
      return state;
    },
    orderAllAisles(state) {
      return state;
    }
  }
});

export const {
  setProductsByCategories,
  getCategories,
  getProductsByCategory,
  getAddProductsToSave,
  setAddProductsToSave,
  setEditAisle,
  setIsLoading,
  setDeleteProducts,
  setAisles,
  setOrderByCSVAllAisles,
  getDeleteProducts,
  getCreateAisle,
  getEditAisle,
  getUpdateAisle,
  inactiveAisle,
  activeAisle,
  archiveAisle,
  unarchiveAisle,
  getAisles,
  setPagination,
  setSort,
  setIsEditLoading,
  sendCSVOrderBy,
  exportCSVAisle,
  orderAllAisles,
  setOrderAllAisles
} = aisleSlice.actions;

export default aisleSlice.reducer;
