import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

import Tables from '@/src/components/Tables/index';

const LastOrders = props => {
  const {
    lastOrdersCustomer,
    isLoading,
    count,
    query,
    getLastOrdersHandler,
    setPaginationHandler,
    setSortHandler
  } = props;

  return (
    <Card className="no-shadow" title={<h2>Últimos Pedidos</h2>}>
      <Tables
        className="table-LastOrders"
        contentToShow="customerInfo"
        data={lastOrdersCustomer}
        loading={isLoading}
        count={count}
        visiblePaginationUp
        showExpandedRow={false}
        query={query}
        populateTables={getLastOrdersHandler}
        setPaginationState={setPaginationHandler}
        setSortState={setSortHandler}
      />
    </Card>
  );
};

LastOrders.defaultProps = {
  query: {
    page: 1,
    perPage: 20,
    daysAgo: '30',
    search: '',
    sort: '',
    url: '',
    filters: {}
  }
};

LastOrders.propTypes = {
  lastOrdersCustomer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    daysAgo: PropTypes.string,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.shape({})
  }),
  getLastOrdersHandler: PropTypes.func.isRequired,
  setPaginationHandler: PropTypes.func.isRequired,
  setSortHandler: PropTypes.func.isRequired
};

export default LastOrders;
