import { apiPortal, apiAuth, apiWarehousePetz } from '@/src/api/config';
import queryBuilder, { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

const getAuditLog = payload => {
  const { auditable_id, auditable_type } = payload;
  if (auditable_type === 'User') {
    const url = `${dispatchQueryBuilder(
      payload,
      '/audits'
    )}&filter[by_auditable_type]=${auditable_type}&filter[by_auditable_id]=${auditable_id}&sort=-created_at`;
    return apiAuth.get(url);
  }
  if (auditable_type === 'SkuPetz') {
    const url = `${queryBuilder(payload, `/api/skus/${auditable_id}/events`)}`;
    return apiWarehousePetz.get(url);
  }
  const url = `${queryBuilder(
    payload,
    '/audits'
  )}&auditable_type=${auditable_type}&auditable_id=${auditable_id}&sort[created_at]=desc`;
  return apiPortal.get(url);
};

export default getAuditLog;
