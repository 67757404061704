import React from 'react';
import { Link } from 'react-router-dom';
import formatCPF from '@/src/utils/formatCPF';
import moment from '@/src/services/moment';
import formatCurrency from '@/src/utils/formatCurrency';

const customers = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    render: (text, record) => (
      <Link
        to={`/clientes/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {text}
      </Link>
    )
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'attributes.name',
    key: 'name',
    sorter: true
  },
  {
    title: 'CPF',
    dataIndex: 'attributes.document',
    key: 'cpf',
    render: cpf => formatCPF(cpf)
  },
  {
    title: 'E-mail',
    dataIndex: 'attributes.email',
    key: 'email'
  },
  {
    title: 'Hub',
    dataIndex: 'attributes.hub',
    key: 'hub',
    render: hub => hub || '-'
  },
  {
    title: 'Qnt Pedidos',
    dataIndex: 'attributes.lifetime_orders_count',
    key: 'lifetime_orders_count',
    sorter: true
  },
  {
    title: 'Valor Gasto',
    dataIndex: 'attributes.lifetime_total',
    key: 'lifetime_total',
    sorter: true,
    render: totalValue => formatCurrency(totalValue)
  },
  {
    title: 'Cadastro em',
    dataIndex: 'attributes.created_at',
    key: 'created_at',
    sorter: true,
    render: createdAt => moment(createdAt).format('DD/MM/YYYY - HH:mm:ss')
  }
];

export default customers;
