const mediaQueries = {
  mobile: '(max-width: 991px)',
  // ant design breakpoints
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1600px)',
  fullhd: '(min-width: 1920px)'
};

export default mediaQueries;
