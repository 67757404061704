import { useSelector } from 'react-redux';

const usePermissions = () => {
  const { permissions } = useSelector(state => state.account);

  // método para verificar se usuário tem permissão na rota e se o módulo deve aparecer no menu. pode ser permissão de edição ou leitura
  const routePermission = module => {
    if (module === 'password') return true;
    const permission = permissions.find(item => item.name === module);
    return permission?.kind === 'edit' || permission?.kind === 'read' || false;
  };

  // método para verificar se usuário tem permissão de edição no módulo
  const editPermission = module => {
    const permission = permissions.find(item => item.name === module);
    return permission?.kind === 'edit' || false;
  };
  return { routePermission, editPermission };
};

export default usePermissions;
