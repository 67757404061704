import { takeEvery, put, call } from 'redux-saga/effects';
import bugsnag from '@/src/services/bugsnag';

// /tags

import {
  getTags as getTagsRequest,
  addTag as addTagRequest,
  removeTag as removeTagRequest
} from '@/src/api/tags';
import {
  setTags,
  setIsLoading,
  getTags,
  addTag,
  removeTag,
  setTagsToFilters
} from '@/src/store/modules/tags/slice';

import { errorAlert, successAlert } from '@/src/utils/notifications';

export function* getTagsSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getTagsRequest, payload.taggable_type);
    const tagFilter = response.data.data.filter(data => data.type === 'tag');
    const tagsFilter = tagFilter.map(tagName => tagName.attributes.name);
    yield put(setTagsToFilters(tagsFilter));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    if (err.response.status === 409) {
      errorAlert(err);
    } else {
      bugsnag.notify(err);
    }
  }
}

export function* addTagSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(addTagRequest, payload);
    const responseAddTag = response.data.data.map(tags => tags.attributes.name);
    yield put(setTags(responseAddTag));
    yield put(setIsLoading(false));
    successAlert('Nova tag adicionada');
  } catch (err) {
    yield put(setIsLoading(false));
    if (err.response.status === 409) {
      errorAlert(err);
    } else {
      bugsnag.notify(err);
    }
  }
}

export function* removeTagSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(removeTagRequest, payload);
    const responseRemoveTag = response.data.data.map(
      tags => tags.attributes.name
    );
    yield put(setTags(responseRemoveTag));
    yield put(setIsLoading(false));
    successAlert('Tag removida');
  } catch (err) {
    yield put(setIsLoading(false));
    if (err.response.status === 409) {
      errorAlert(err);
    } else {
      bugsnag.notify(err);
    }
  }
}

function* TagsSaga() {
  yield takeEvery(getTags, getTagsSaga);
  yield takeEvery(addTag, addTagSaga);
  yield takeEvery(removeTag, removeTagSaga);
}

export default TagsSaga;
