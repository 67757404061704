import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  productsList: [],
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

const allProductsSlice = createSlice({
  name: '@allProducts',
  initialState,
  reducers: {
    setAllProducts(prevState, action) {
      const state = prevState;
      state.productsList = action.payload.productsList;
      state.count = action.payload.count;
      if (action.payload.query) {
        state.query = {
          page: Number(action.payload.query.page),
          perPage: action.payload.query.perPage || state.query.perPage,
          search: action.payload.query.search || '',
          sort: action.payload.query.sort || '',
          filters: action.payload.query.filters || {},
          url: `?page=${action.payload.query.page || '1'}&per_page=${action
            .payload.query.perPage || state.query.perPage}${
            action.payload.query.search
              ? `&q=${action.payload.query.search}`
              : ''
          }${
            action.payload.query.sort ? `&sort${action.payload.query.sort}` : ''
          }${
            action.payload.query.filters
              ? buildQueryStringFromObj(action.payload.query.filters)
              : ''
          }`
        };
      }
      state.isLoading = false;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    getAllProducts(state) {
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    exportCSV(state) {
      return state;
    },
    exportCSVProductsSort(state) {
      return state;
    },
    importCSVProductsSort(state) {
      return state;
    },
    importBuyAndEarnPromotions(state) {
      return state;
    }
  }
});

export const {
  setAllProducts,
  setLoading,
  getAllProducts,
  setPagination,
  exportCSV,
  exportCSVProductsSort,
  importCSVProductsSort,
  importBuyAndEarnPromotions
} = allProductsSlice.actions;

export default allProductsSlice.reducer;
