const formatParityText = (value, text) => {
  if (value <= 0) {
    return `não há ${text}s`;
  }
  if (value === 1) {
    return `${value} ${text}`;
  }
  return `${value} ${text}s`;
};

export default formatParityText;
