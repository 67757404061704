import { takeEvery, put, call } from 'redux-saga/effects';

import getPetBreedsRequest from '@/src/api/pet-breeds';
import {
  setDogBreeds,
  setCatBreeds,
  getPetBreeds,
  setIsLoading
} from '@/src/store/modules/pet-breeds/slice';

import { errorAlert } from '@/src/utils/notifications';

function* getPetBreedsSaga() {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getPetBreedsRequest);
    yield put(setDogBreeds(response.data.dogs));
    yield put(setCatBreeds(response.data.cats));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* customerSaga() {
  yield takeEvery(getPetBreeds, getPetBreedsSaga);
}

export default customerSaga;
