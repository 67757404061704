import { takeEvery, put, call, select, delay } from 'redux-saga/effects';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import normalize from 'json-api-normalizer';

import {
  setBreeds,
  setBreed,
  setLoading,
  setLoadingBtn,
  getBreeds,
  getBreed,
  createBreed,
  updateBreed,
  updateBreedAccessoryType
} from '@/src/store/modules/breeds/slice';

import { setAccessories } from '@/src/store/modules/accessories/slice';

import {
  getBreedsList as getBreedsListRequest,
  getBreedData as getBreedDataRequest,
  createBreed as createBreedRequest,
  updateBreed as updateBreedRequest
} from '@/src/api/breeds';

import {
  createBreedAccessoryType as createBreedAccessoryTypeRequest,
  updateBreedAccessoryType as updateBreedAccessoryTypeRequest
} from '@/src/api/breeds-accessory-types';

import { getAccessoriesList as getAccessoriesListRequest } from '@/src/api/accessories';

import { getBreedsQueryState } from '@/src/store/selectors';

function* getBreedsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getBreedsListRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/breed',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    // console.log(response, 'breeds');
    const count = response.data.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(setBreeds({ breeds: normalized, count, query: payload.query }));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar as raças');
  }
}

function* getBreedSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getBreedDataRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/breed',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    yield put(setBreed(normalized));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados da raça');
  }
}

function* createBreedSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(createBreedRequest, payload);
    const query = yield select(getBreedsQueryState);
    const response = yield call(getBreedsListRequest, { query });
    const normalized = normalize(response.data, {
      endpoint: '/breed',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    const count = response.data.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(setBreeds({ breeds: normalized, count, query }));
    yield put(setLoading(false));
    successAlert('Nova raça criada');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar a raça');
  }
}

function* updateBreedSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(updateBreedRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/breed',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    yield put(setBreed(normalized));
    yield put(setLoading(false));
    successAlert('Os dados da raça foram atualizados');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi atualizar os dados da raça');
  }
}

function* updateBreedAccessoryTypeSaga({ payload }) {
  try {
    const query = {
      page: 1,
      perPage: 1000,
      search: ''
    };
    yield put(setLoadingBtn(true));
    if (payload.create.length) {
      yield call(createBreedAccessoryTypeRequest, { data: payload.create });
    }
    if (payload.update.length) {
      yield call(updateBreedAccessoryTypeRequest, { data: payload.update });
      if (window.location.pathname.includes('racas')) {
        const response = yield call(getAccessoriesListRequest, { query });
        const normalized = normalize(response.data, {
          endpoint: '/accessory_type',
          camelizeKeys: false,
          camelizeTypeValues: false
        });
        const count =
          response.data.meta?.count > 0 ? response.data.meta.count : 0;
        yield put(
          setAccessories({
            accessories: normalized,
            count,
            query
          })
        );
      } else {
        const response = yield call(getBreedsListRequest, { query });
        const normalized = normalize(response.data, {
          endpoint: '/breed',
          camelizeKeys: false,
          camelizeTypeValues: false
        });
        const count =
          response.data.meta?.count > 0 ? response.data.meta.count : 0;
        yield put(setBreeds({ breeds: normalized, count, query }));
      }
    }
    yield delay(500);
    yield put(setLoadingBtn(false));
    successAlert('Sugestões de tamanho atualizadas');
  } catch (err) {
    // console.log(err);
    yield put(setLoadingBtn(false));
    const { errors } = err.response.data;
    let message = 'Não foi atualizar a sugestão de tamanho';
    if (errors && errors[0]?.detail?.includes('error Combinação')) {
      message = err.response.data.errors[0].detail.replace('error ', '');
    }
    errorAlert(err, message);
  }
}

function* breedsSaga() {
  yield takeEvery(getBreeds, getBreedsSaga);
  yield takeEvery(getBreed, getBreedSaga);
  yield takeEvery(createBreed, createBreedSaga);
  yield takeEvery(updateBreed, updateBreedSaga);
  yield takeEvery(updateBreedAccessoryType, updateBreedAccessoryTypeSaga);
}

export default breedsSaga;
