import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Select, Tag, Icon, Spin } from 'antd';

import {
  getMapDeliverers,
  getMapDeliverer
} from '@/src/store/modules/deliverers/slice';
import {
  GoogleMap,
  Marker,
  OverlayView,
  useLoadScript
} from '@react-google-maps/api';
import app, { appLogin } from '@/src/services/firebase';
import deliveringMarker from '@/src/assets/images/deliverer/map-pin-delivering.png';
import lastDeliveryMarker from '@/src/assets/images/deliverer/map-pin-last-delivery.png';
import noDeliveriesMarker from '@/src/assets/images/deliverer/map-pin-no-deliveries.png';
import mapStyles from './mapStyles';

import styles from './DeliverersMap.module.scss';

const { Option } = Select;

const DeliverersMap = props => {
  const {
    hubs,
    allHubs
    // handleDeliverersData
  } = props;

  // tag pills
  const [selectedPills, setSelectedPills] = useState(['all']);
  const [allDeliverersTag, setAllDeliveresTag] = useState(true);
  const [deliveringTag, setDeliveringTag] = useState(false);
  const [lastDeliveryTag, setLastDeliveryTag] = useState(false);
  const [tagsValues, setTagsValues] = useState({
    // noDeliveries: 0,
    allDeliverers: 0,
    delivering: 0,
    lastDelivery: 0
  });
  // hub
  const [selectedHubData, setSelectedHubData] = useState([]);
  // deliverers
  const [mapMarkers, setMapMarkers] = useState([]);
  // map
  const [mapInstance, setMapInstance] = useState(null);
  // overlays
  // const [showMarkerOverlays, setShowMarkerOverlays] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayData, setOverlayData] = useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  const [selectValue, setSelectValue] = useState();

  const dispatch = useDispatch();

  const emptyMapPosition = [
    {
      lat: -23.55227546805608,
      lng: -46.55987969010442
    },
    {
      lat: -22.942459335330152,
      lng: -43.21469551232561
    }
  ];

  const { mapDeliverers, query, mapDeliverer, isLoading } = useSelector(
    state => state.deliverers
  );

  const getMapDeliverersList = useCallback(
    urlQuery => {
      const mapQuery = {
        query: {
          ...urlQuery.query,
          filters: [
            ...urlQuery.query.filters,
            { key: 'banned', type: 'eq', value: false }
          ]
        }
      };
      dispatch(getMapDeliverers(mapQuery));
    },
    [dispatch]
  );

  useEffect(() => {
    if (hubs[0]) {
      if (hubs.length === 1) setSelectValue(hubs[0].id);
      const params = {
        ...query,
        perPage: 1000
      };
      if (!allHubs) {
        const hubsFilter = hubs.map(item => item.id).join(',');
        params.filters = [{ key: 'by_hub', type: 'match', value: hubsFilter }];
      }
      getMapDeliverersList({ query: params });
    }
    // eslint-disable-next-line
  }, [hubs]);

  useEffect(() => {
    // console.log(mapDeliverers);
    setSelectedHubData(mapDeliverers);
  }, [mapDeliverers]);

  const handleClickTag = value => {
    setOverlayData({});
    if (
      value === 'all' ||
      (value === 'delivering' && deliveringTag) ||
      (value === 'last-deliver' && lastDeliveryTag)
    ) {
      setSelectedPills(['all']);
      setAllDeliveresTag(true);
      setDeliveringTag(false);
      setLastDeliveryTag(false);
    } else if (value === 'delivering' && !deliveringTag) {
      setSelectedPills(['delivering']);

      setAllDeliveresTag(false);
      setLastDeliveryTag(false);
      setDeliveringTag(true);
    } else if (value === 'last-deliver' && !lastDeliveryTag) {
      setSelectedPills(['last-deliver']);

      setAllDeliveresTag(false);
      setDeliveringTag(false);
      setLastDeliveryTag(!lastDeliveryTag);
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    language: 'pt-br',
    preventGoogleFontsLoading: true
  });

  useEffect(() => {
    appLogin();
  }, []);

  const filterMapMarkersByPills = (deliverersObj, statusArray) => {
    let newObj = {};
    if (statusArray.includes('all')) {
      newObj = deliverersObj;
    }
    if (statusArray.includes('delivering')) {
      Object.keys(deliverersObj).forEach(obj => {
        if (deliverersObj[obj].deliveries > 1) newObj[obj] = deliverersObj[obj];
      });
    }
    if (statusArray.includes('last-deliver')) {
      Object.keys(deliverersObj).forEach(obj => {
        if (deliverersObj[obj].deliveries === 1)
          newObj[obj] = deliverersObj[obj];
      });
    }
    return newObj;
  };

  useEffect(() => {
    if (overlayData[0] && mapMarkers) {
      const currentMarker = mapMarkers.filter(
        item => item.props.children.key === overlayData[0]
      );
      setCurrentPosition(currentMarker[0].props.children.props.position);
    }
  }, [mapMarkers, overlayData]);

  const getMapDelivererInfo = useCallback(
    id => {
      dispatch(getMapDeliverer(id));
    },
    [dispatch]
  );

  const handleMarkerOverlayVisibility = async markerInfo => {
    if (markerInfo) {
      setShowOverlay(true);
      getMapDelivererInfo(markerInfo[0]);
      setOverlayData(markerInfo);
    } else {
      setShowOverlay(false);
    }
  };

  const updateMapPosition = pos => {
    const currentZoom = mapInstance.getZoom();
    const bounds = new window.google.maps.LatLngBounds();
    if (pos?.length > 0) {
      pos.forEach(p => {
        bounds.extend(p);
      });
    } else {
      bounds.extend(pos);
    }
    mapInstance.fitBounds(bounds);
    mapInstance.setZoom(currentZoom);
  };

  useEffect(() => {
    if (mapDeliverer?.id && overlayData[0]) {
      let statusName = '';
      if (overlayData[1].deliveries === 1) {
        statusName = 'Última entrega';
      } else if (overlayData[1].deliveries > 1) {
        statusName = 'Entregando';
      } else {
        statusName = 'Sem entrega';
      }
      let orderNumber = '-';
      if (mapDeliverer.attributes.deliveries_in_progress.length > 0) {
        orderNumber =
          mapDeliverer.attributes.deliveries_in_progress[0].order_number;
      }

      const newOverlayData = {
        delivererId: overlayData[0],
        latitude: overlayData[1].latitude,
        longitude: overlayData[1].longitude,
        delivererName: mapDeliverer.attributes.name || '',
        delivererImage: mapDeliverer.attributes.image_url || '',
        currentOrder: orderNumber,
        orderQty: overlayData[1].deliveries || 0,
        status: statusName
      };

      const pos = {
        lat: overlayData[1].latitude,
        lng: overlayData[1].longitude
      };

      setOverlayData(newOverlayData);
      setShowOverlay(true);
      updateMapPosition(pos);
    }
    // eslint-disable-next-line
  }, [mapDeliverer, mapDeliverers]);

  const getDelivererNewPosition = () => {
    const markerToUpdate = mapMarkers.find(
      marker => marker.key === `marker-${overlayData.delivererId}`
    );

    if (markerToUpdate !== undefined) {
      const { position } = markerToUpdate.props.children.props;
      if (position) {
        const updatedOverlayData = overlayData;
        updatedOverlayData.latitude = position.lat;
        updatedOverlayData.longitude = position.lng;
        setOverlayData(updatedOverlayData);
        updateMapPosition(position);
      }
    }
  };

  useEffect(() => {
    if (showOverlay) {
      getDelivererNewPosition();
    }
    // eslint-disable-next-line
  }, [mapMarkers]);

  const filterMapMarkersByHub = (deliverersObj, hubData) => {
    const returnObj = {};
    const hubDeliverersIdArray = hubData.map(
      deliverer => deliverer.attributes.internal_id
    );
    Object.keys(deliverersObj).forEach(el => {
      if (hubDeliverersIdArray.some(hubArrayeEl => hubArrayeEl === el))
        returnObj[el] = deliverersObj[el];
    });
    return returnObj;
  };

  useEffect(() => {
    if (mapInstance === null) return;
    const ref = app.database().ref(`deliverers/`);
    let updateZoom = true;

    ref.on('value', snapshot => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        if (!selectedPills) return;

        const updatedMapMarkers = [];

        const updatedTagsValues = {
          allDeliverers: 0,
          delivering: 0,
          lastDelivery: 0
          // noDeliveries: 0
        };

        const deliverersFilteredByPills =
          selectedPills === 'all'
            ? val
            : filterMapMarkersByPills(val, selectedPills);

        const deliverersFilteredByHubAndPills = selectedHubData
          ? filterMapMarkersByHub(deliverersFilteredByPills, selectedHubData)
          : deliverersFilteredByPills;

        const filteredByHub = selectedHubData
          ? filterMapMarkersByHub(val, selectedHubData)
          : val;

        Object.entries(filteredByHub).forEach(marker => {
          // if (Number(marker[1].deliveries) === 0) {
          //   updatedTagsValues.noDeliveries += 1;
          // } else
          if (Number(marker[1].deliveries) === 1) {
            updatedTagsValues.lastDelivery += 1;
          } else if (Number(marker[1].deliveries) > 1) {
            updatedTagsValues.delivering += 1;
          }
        });
        updatedTagsValues.allDeliverers = Object.keys(filteredByHub).length;

        const newMapMarkers = Object.entries(
          deliverersFilteredByHubAndPills
        ).map((value, index) => {
          const id = value[0];
          const pos = {
            lat: value[1].latitude,
            lng: value[1].longitude
          };
          const { deliveries } = value[1];

          let delivererMarker = noDeliveriesMarker;

          if (Number(deliveries) > 1) {
            delivererMarker = deliveringMarker;
          }

          if (Number(deliveries) === 1) {
            delivererMarker = lastDeliveryMarker;
          }

          const markerOverlayKey = `marker-${id}`;
          updatedMapMarkers.push({ id, pos });
          return (
            <div key={markerOverlayKey}>
              <Marker
                key={id}
                position={pos}
                icon={delivererMarker}
                zIndex={index}
                onClick={() => handleMarkerOverlayVisibility(value)}
              />
            </div>
          );
        });

        // empty data, show default position
        if (newMapMarkers.length < 1) {
          updateZoom = false;
          mapInstance.setZoom(8);
          updateMapPosition(emptyMapPosition);
          setOverlayData({});
        }

        if (updateZoom) {
          const bounds = new window.google.maps.LatLngBounds();

          updatedMapMarkers.map(place => {
            bounds.extend(place.pos);
            return place.id;
          });
          mapInstance.fitBounds(bounds);
          updateZoom = false;
        }

        setMapMarkers(newMapMarkers);
        setTagsValues(updatedTagsValues);
      } else {
        updateZoom = false;
        mapInstance.setZoom(8);
        updateMapPosition(emptyMapPosition);
        setOverlayData({});
      }
    });

    // ref.on('child_added', snapshot => {
    //   handleDeliverersData(prev => {
    //     const deliverers = prev.map(deliverer => {
    //       const data = {
    //         ...deliverer,
    //         attributes: { ...deliverer.attributes }
    //       };
    //       // if (deliverer.id === snapshot.key) data.attributes.status = 'delivering';
    //       return data;
    //     });
    //     return deliverers;
    //   });
    // });

    // // handle other status than delivering (like last delivery or returning)
    // // ref.on('child_changed', snapshot => {
    // //   handleDeliverersData(prev => {
    // //     const deliverers = prev.map(deliverer => {
    // //       const data = deliverer;
    // //       if (deliverer.id === snapshot.key) data.attributes.status = 1;
    // //       return deliverer;
    // //     });
    // //     return deliverers;
    // //   });
    // // });

    // ref.on('child_removed', snapshot => {
    //   handleDeliverersData(prev => {
    //     const deliverers = prev.map(deliverer => {
    //       const data = deliverer;
    //       // if (deliverer.id === snapshot.key) data.attributes.status = 'waiting';
    //       return data;
    //     });
    //     return deliverers;
    //   });
    // });

    // eslint-disable-next-line consistent-return
    return () => {
      ref.off();
    };

    // eslint-disable-next-line
  }, [app, mapInstance, selectedPills, mapDeliverers, selectedHubData]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (isLoaded) {
    return (
      <>
        <Col span={4}>
          <Select
            allowClear
            size="large"
            placeholder="Hubs"
            style={{ width: '100%' }}
            value={selectValue}
            onChange={value => {
              setSelectValue(value);
              setShowOverlay(false);
              const params = {
                ...query,
                perPage: 1000,
                filters: [
                  {
                    key: 'by_hub',
                    type: 'match',
                    value: value || hubs.map(item => item.id).join(',')
                  }
                ]
              };
              getMapDeliverersList({ query: params });
            }}
          >
            {hubs?.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name || '-'}
              </Option>
            ))}
            {/* {renderDropdownItens(hubs, 'hubs')} */}
          </Select>
        </Col>
        <Col
          span={20}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Tag
            className={`chips deliverers-chips${
              allDeliverersTag ? ' deliverers-chips__selected' : ''
            }`}
            style={{
              marginLeft: '16px'
            }}
            key="0"
            onClick={() => handleClickTag('all')}
          >
            {`Todos (${tagsValues.allDeliverers})`}
            {allDeliverersTag && (
              <span className="deliverers-chips__close">x</span>
            )}
          </Tag>
          <Tag
            className={`chips deliverers-chips${
              deliveringTag ? ' deliverers-chips__selected' : ''
            }`}
            key="1"
            onClick={() => handleClickTag('delivering')}
          >
            <span className="deliverers-chips__legend delivering" />
            {`Entregando (${tagsValues.delivering})`}
            {deliveringTag && (
              <span className="deliverers-chips__close">x</span>
            )}
          </Tag>
          <Tag
            className={`chips deliverers-chips${
              lastDeliveryTag ? ' deliverers-chips__selected' : ''
            }`}
            key="2"
            onClick={() => handleClickTag('last-deliver')}
          >
            <span className="deliverers-chips__legend last-deliver" />
            {`Última entrega (${tagsValues.lastDelivery})`}
            {lastDeliveryTag && (
              <span className="deliverers-chips__close">x</span>
            )}
          </Tag>
          {/* <Tag
            className={`chips deliverers-chips${
              returningTag ? ' deliverers-chips__selected' : ''
            }`}
            key="3"
            onClick={() => handleClickTag('returning')}
          >
            <span className="deliverers-chips__legend returning" />
            {`Voltando pro hub (${tagsValues.returning})`}
            {returningTag && <span className="deliverers-chips__close">x</span>}
          </Tag> */}
        </Col>
        <GoogleMap
          id="order-map"
          mapContainerStyle={{
            height: '400px',
            width: '100%',
            borderRadius: '8px 0 0 8px',
            marginTop: '20px'
          }}
          zoom={16}
          options={mapStyles}
          onLoad={map => setMapInstance(map)}
          onClick={() => handleMarkerOverlayVisibility()}
          onDrag={() => setShowOverlay(false)}
        >
          {mapMarkers}

          {showOverlay && (
            <OverlayView
              key={`overlay-marker-${overlayData?.delivererId}`}
              position={{
                lat: currentPosition?.lat,
                lng: currentPosition?.lng
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={() => {
                return { x: -168, y: -198 };
              }}
            >
              <Spin spinning={isLoading}>
                <div
                  className={
                    isLoading ||
                    overlayData?.orderQty === 0 ||
                    !overlayData?.orderQty
                      ? styles['deliverers-map-overlay__gray']
                      : styles[
                          overlayData?.orderQty > 1
                            ? 'deliverers-map-overlay'
                            : 'deliverers-map-overlay__last'
                        ]
                  }
                >
                  <div className={styles['deliverers-map-overlay-image']}>
                    {overlayData?.delivererImage ? (
                      <img
                        style={{ objectFit: 'cover' }}
                        src={overlayData?.delivererImage}
                        onError={() =>
                          setOverlayData({ ...overlayData, delivererImage: '' })
                        }
                        alt={`Entregador ${overlayData?.delivererName}`}
                      />
                    ) : (
                      <Icon
                        style={{ fontSize: '24px', opacity: '0.2' }}
                        type="camera"
                        theme="filled"
                      />
                    )}
                  </div>
                  <div className={styles['deliverers-map-overlay-data']}>
                    <Link
                      to={`/entregadores/${overlayData?.delivererId}`}
                      className={styles['deliverers-map-overlay-data-text']}
                    >
                      Nome:
                      <span style={{ textDecoration: 'underline' }}>
                        {overlayData?.delivererName}
                      </span>
                    </Link>
                    <p className={styles['deliverers-map-overlay-data-text']}>
                      Pedido atual: <span>{overlayData?.currentOrder}</span>
                    </p>
                    <p className={styles['deliverers-map-overlay-data-text']}>
                      Qtd. de ent. restantes:
                      <span>{overlayData?.orderQty}</span>
                    </p>
                    <p className={styles['deliverers-map-overlay-data-status']}>
                      <span
                        className={
                          overlayData?.status && overlayData?.orderQty > 0
                            ? styles[
                                overlayData?.orderQty > 1
                                  ? 'status-delivering'
                                  : 'status-last-delivery'
                              ]
                            : styles['status-gray']
                        }
                      >
                        {overlayData?.status || 'Carregando'}
                      </span>
                    </p>
                  </div>
                </div>
              </Spin>
            </OverlayView>
          )}
        </GoogleMap>
      </>
    );
  }
  return 'waiting';
};

DeliverersMap.defaultProps = {
  hubs: [],
  allHubs: true
};

DeliverersMap.propTypes = {
  hubs: PropTypes.arrayOf(PropTypes.object),
  allHubs: PropTypes.bool
};

export default DeliverersMap;
