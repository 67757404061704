import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';

import DevolutionItem from '@/src/components/Expedition/Modal/DevolutionTable/DevolutionItem';

import { useBreakpoint } from '@/src/context/BreakpointContext';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const DevolutionTable = ({ setReturnBtnDisabled }) => {
  const [returnedQty, setReturnedQty] = useState([]);

  const {
    returnedItems,
    contentLoading,
    modalData: { order }
  } = useSelector(state => state.expedition);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (order?.orderRequests?.length > 0) {
      const returnRequest = order.orderRequests.find(
        item => !item.requestResolved
      );
      const returnedQtyArr =
        returnRequest?.returnedItems?.map(item => item.returnedQuantity) || [];
      setReturnedQty(
        returnedQtyArr.length > 0 ? returnedQtyArr?.reduce(reducer) : []
      );
    }
  }, [order]);

  useEffect(() => {
    const selectedQtyArr = returnedItems.orderItems.map(
      item => item.quantityStock + item.quantityLoss
    );
    const selectedQty =
      selectedQtyArr.length > 0 && selectedQtyArr.reduce(reducer);
    setReturnBtnDisabled(returnedQty !== selectedQty);
  }, [returnedItems, returnedQty, setReturnBtnDisabled]);

  return (
    <Spin spinning={contentLoading}>
      <div className="modal-table__wrapper">
        {breakpoint.lg && (
          <div
            style={{
              backgroundColor: '#EBEBEB',
              borderRadius: '8px 8px 0 0',
              fontWeight: 'bold',
              color: '#0A0A0A',
              textAlign: 'center'
            }}
          >
            <Row>
              <Col span={9}>
                <div className="modal-table__header-cell">SKU</div>
              </Col>
              <Col span={4}>
                <div className="modal-table__header-cell">EAN</div>
              </Col>
              <Col span={3}>
                <div className="modal-table__header-cell">Qnt Devolvida</div>
              </Col>
              <Col span={4}>
                <div className="modal-table__header-cell">
                  Devolver ao estoque
                </div>
              </Col>
              <Col span={4}>
                <div className="modal-table__header-cell">Declarar perda</div>
              </Col>
            </Row>
          </div>
        )}
        {contentLoading ? (
          <>
            <DevolutionItem />
          </>
        ) : (
          order?.orderRequests
            ?.find(item => !item.requestResolved)
            ?.returnedItems?.map(product => {
              return (
                <DevolutionItem
                  key={product.id}
                  itemId={product.id}
                  sku={
                    product.orderItem.skuExternalId || product.orderItem.skuCode
                  }
                  ean={product.orderItem.skuEan}
                  productName={product.orderItem.skuName}
                  productImg={product.orderItem.skuImage || ''}
                  requestId={
                    order.orderRequests.find(item => !item.requestResolved)?.id
                  }
                  totalProductQuantity={product.returnedQuantity}
                  isGift={product.orderItem.gift}
                />
              );
            })
        )}
      </div>
    </Spin>
  );
};

DevolutionTable.defaultProps = {
  setReturnBtnDisabled: () => {}
};

DevolutionTable.propTypes = {
  setReturnBtnDisabled: PropTypes.func
};

export default DevolutionTable;
