import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Upload, message, DatePicker } from 'antd';
import moment from '@/src/services/moment';

import { importBuyAndEarnPromotions } from '@/src/store/modules/products-all/slice';

const ModalBuyAndEarn = ({ visible, setVisible }) => {
  const [file, setFile] = useState([]);
  const [startsAt, setStartsAt] = useState('');
  const [endsAt, setEndsAt] = useState('');

  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();

  const conditions = ['.csv'];
  const checkFileType = fileType =>
    conditions.some(el => fileType.name.includes(el));

  const beforeUpload = selectedFile => {
    if (!checkFileType(selectedFile)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setBtnDisabled(true);
      return false;
    }
    setFile([selectedFile]);
    if (startsAt !== '' && endsAt !== '') setBtnDisabled(false);
    return false;
  };

  const handleImportFile = useCallback(
    (start, end) => {
      if (start !== '' && end !== '') {
        dispatch(
          importBuyAndEarnPromotions({
            file,
            starts_at: start,
            ends_at: end
          })
        );
        setVisible(false);
        setBtnDisabled(false);
        // setStartsAt('');
        // setEndsAt('');
        setFile([]);
      }
    },
    // eslint-disable-next-line
    [file]
  );

  const removeFile = () => {
    setFile([]);
    setBtnDisabled(visible);
  };

  useEffect(() => {
    if (file.length === 0 || startsAt === '' || endsAt === '')
      setBtnDisabled(true);
    else setBtnDisabled(false);
  }, [file, startsAt, endsAt]);

  return (
    <Modal
      title="PROMOÇÕES - COMPRE E GANHE"
      visible={visible}
      onCancel={() => setVisible(false)}
      className="modal-buy-and-earn"
      destroyOnClose
      footer={[
        <Button
          key="submit"
          onClick={() => handleImportFile(startsAt, endsAt)}
          className="ant-btn ant-btn-primary"
          disabled={btnDisabled}
        >
          importar
        </Button>
      ]}
    >
      <Row>
        <Col style={{ marginBottom: 20 }}>
          <p style={{ marginBottom: 0, textAlign: 'center' }}>
            Para inserir em massa os produtos que entrarão na promoção compre e
            ganhe, anexe a planilha .csv contendo as informações em duas
            colunas, <br /> com os títulos:
            <strong> EAN_PARTICIPANTE, EAN_BRINDE </strong>
          </p>
        </Col>
        <Col
          style={{
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <p style={{ marginBottom: '8px' }}>
            E defina a data de início e de fim para essa promoção:
          </p>
          <DatePicker.RangePicker
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('11:59:59', 'HH:mm:ss')
              ]
            }}
            separator="-"
            format="DD/MM/YY HH:mm:ss"
            onChange={evt => {
              setStartsAt(
                evt[0] !== undefined
                  ? moment(evt[0])?.format('YYYY-MM-DD HH:mm:ss Z')
                  : ''
              );
              setEndsAt(
                evt[1] !== undefined
                  ? moment(evt[1])?.format('YYYY-MM-DD HH:mm:ss Z')
                  : ''
              );
            }}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv"
            onRemove={removeFile}
            fileList={file}
          >
            <Button className="ant-btn-secondary-copy">Escolher Arquivo</Button>
          </Upload>
          {file.length === 0 && (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          )}
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '11px'
          }}
        >
          <span style={{ textAlign: 'center' }}>
            Obs.: O arquivo .csv necessita possuir o ponto e vírgula como
            separador <br /> e a primeira linha com os títulos das colunas.
          </span>
        </Col>
      </Row>
    </Modal>
  );
};

ModalBuyAndEarn.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default ModalBuyAndEarn;
