import { takeEvery, put, call } from 'redux-saga/effects';

import {
  getProducts as getProductsRequest,
  getSalechannels as getSalechannelsRequest
} from '@/src/api/products';

import {
  setProducts,
  setIsLoading,
  setQuery,
  setSalechannels,
  getProducts,
  getSalechannels
} from '@/src/store/modules/products/slice';

import { errorAlert } from '@/src/utils/notifications';

function* getProductsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getProductsRequest, payload);
    yield put(setProducts(response.data));
    yield put(setQuery(payload.query));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getSalechannelsSaga({ payload }) {
  try {
    const response = yield call(getSalechannelsRequest, payload);
    yield put(setSalechannels(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* productsSaga() {
  yield takeEvery(getProducts, getProductsSaga);
  yield takeEvery(getSalechannels, getSalechannelsSaga);
}

export default productsSaga;
