import { useDispatch } from 'react-redux';
import { getUpdateProduct } from '@/src/store/modules/product-details/slice';

const useSaveProductEdits = () => {
  const dispatch = useDispatch();
  const removeData = dataString =>
    dataString.replace(/data:image\/[\w]+;base64,/g, '');

  // eslint-disable-next-line
  const formatImageUpload = (image, index = false) => {
    if (image.filename) {
      return {
        image: removeData(image.url),
        filename: image.filename,
        sort: index + 1
      };
    }
    if (index !== false) {
      return {
        id: image.id,
        sort: index + 1
      };
    }
  };

  const saveAllEdits = (
    id,
    newProductValues,
    thumbnail,
    carrousselImages,
    specialContentImages,
    ifoodImages,
    imagesToDelete,
    propertiesSelected,
    propertiesUnselected,
    skuChanges,
    hubSkus
  ) => {
    const newThumb = thumbnail?.length ? formatImageUpload(thumbnail[0]) : {};

    const carroussel = carrousselImages?.length
      ? carrousselImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const specialcontent = specialContentImages?.length
      ? specialContentImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const ifood = ifoodImages?.length
      ? ifoodImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const manufacturer_id = newProductValues.manufacturer_id.key;
    const aisle_ids = newProductValues.aisle_ids.map(aisle => Number(aisle));

    window.scrollTo(0, 0);
    dispatch(
      getUpdateProduct({
        ...newProductValues,
        id,
        thumb: newThumb,
        images: carroussel,
        experience_images: specialcontent,
        ifood_images: ifood,
        image_ids: imagesToDelete,
        selected_property_value_ids: propertiesSelected,
        unselected_property_value_ids: propertiesUnselected,
        // later remove this line
        is_there_any_breed_suggestion: false,
        skus: skuChanges,
        hub_skus_attributes: hubSkus,
        manufacturer_id,
        aisle_ids
      })
    );
  };

  return { saveAllEdits };
};

export default useSaveProductEdits;
