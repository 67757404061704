import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Tooltip,
  Form,
  Icon,
  Input,
  Select,
  Breadcrumb,
  Button,
  Card
} from 'antd';
import PropTypes from 'prop-types';

import UploadImage from '@/src/components/UploadImage';
import Attributes from '@/src/components/Attributes';

import {
  getCatalogAttributes,
  getDepartments,
  getCreateCategory
} from '@/src/store/modules/catalog-categories/slice';

const { Option } = Select;

const NewCategory = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  // const [uploadWebImage, setUploadWebImage] = useState([]);
  // const [withoutWebImage, setWithoutWebImage] = useState(false);
  const [propertiesValues, setPropertiesValues] = useState([]);

  const dispatch = useDispatch();

  const { attributes, departments, isLoading, updated } = useSelector(
    state => state.catalogCategories
  );

  const checkIfHasImage = () => {
    setWithoutImage(uploadImage.length === 0);
    // setWithoutWebImage(uploadWebImage.length === 0);
    // if (uploadImage.length === 0 || uploadWebImage.length === 0) return false;
    if (uploadImage.length === 0) return false;
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const imageNew = uploadImage.replace(/^data:.+;base64,/, '');
      // const webImageNew = uploadWebImage.replace(/^data:.+;base64,/, '');
      const payload = {
        department_id: values.department_id,
        name: values.name,
        description: values.description,
        search_metadata: values.search_metadata || '',
        image: imageNew,
        // web_image: webImageNew,
        title: values.title || '',
        meta_title: values.meta_title || '',
        meta_description: values.meta_description || '',
        properties: propertiesValues
      };
      dispatch(getCreateCategory(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  // const takeAttributes = attributes_id => {
  //   dispatch(
  //     getCatalogAttributes({
  //       query: {
  //         perPage: 20,
  //         page: 1,
  //         search: '',
  //         value: '',
  //         filters: {
  //           by_department: attributes_id
  //         }
  //       }
  //     })
  //   );
  // };

  const populateProperties = values => {
    setPropertiesValues(values || []);
  };

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getCatalogAttributes());
  }, [dispatch]);

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Nova Categoria</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/categorias">Categorias</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Nova categoria</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card className="no-shadow" title="DETALHES DO CATEGORIA">
          <Form>
            <Row>
              <Col span={12}>
                <div style={{ width: '80%' }}>
                  <Row>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                        Nome do Departamento
                      </p>
                      <Tooltip
                        placement="right"
                        title="Nome do departamento que esta categoria pertence"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('department_id', {
                        rules: [
                          {
                            required: true,
                            message: 'Selecione um departamento'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione um departamento"
                          // onChange={value => takeAttributes(value)}
                        >
                          {departments?.map(department => (
                            <Option key={department.attributes.id}>
                              {department.attributes.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                        Nome da Categoria
                      </p>
                      <Tooltip
                        placement="right"
                        title="Nome da categoria que irá aparecer no app."
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha o nome da categoria'
                          }
                        ]
                      })(<Input placeholder="Nome da categoria" />)}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                        Descrição
                      </p>
                      <Tooltip
                        placement="right"
                        title="Descrição da categoria que irá aparecer no app."
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('description', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha uma descrição'
                          }
                        ]
                      })(<Input placeholder="Escreva um descrição" />)}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                        Palavras-chave
                      </p>
                      <Tooltip
                        placement="right"
                        title="Palavras que facilitam encontrar categoria através da busca."
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('search_metadata')(
                        <Input.TextArea placeholder="Ex.: alimentos, ração" />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <h2 style={{ marginBottom: 16 }}>SEO (WEB)</h2>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 4
                        }}
                      >
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                          Title
                        </p>
                        <Tooltip
                          placement="right"
                          title="Título da vitrine exibido na página da categoria. Deve conter nome da categoria e do departamento"
                        >
                          <Icon style={{ marginLeft: 6 }} type="info-circle" />
                        </Tooltip>
                      </div>
                      <Form.Item style={{ marginBottom: 2 }}>
                        {getFieldDecorator('title')(
                          <Input placeholder="Exemplo: Alimentos para Cães" />
                        )}
                      </Form.Item>
                      <span style={{ display: 'block', marginBottom: 24 }}>
                        *Caso não preenchido, será o nome da categoria
                      </span>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 4
                        }}
                      >
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                          Meta Title
                        </p>
                        <Tooltip
                          placement="right"
                          title="Título da vitrine exibido em ferramentas de busca"
                        >
                          <Icon style={{ marginLeft: 6 }} type="info-circle" />
                        </Tooltip>
                      </div>
                      <Form.Item>
                        {getFieldDecorator('meta_title')(
                          <Input placeholder="Exemplo: Zee.Now - Nome da categoria" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 4
                        }}
                      >
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                          Meta Description
                        </p>
                        <Tooltip
                          placement="right"
                          title="Descrição da vitrine exibida em ferramentas de busca"
                        >
                          <Icon style={{ marginLeft: 6 }} type="info-circle" />
                        </Tooltip>
                      </div>
                      <Form.Item>
                        {getFieldDecorator('meta_description')(
                          <Input.TextArea placeholder="Breve descrição da categoria" />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>ATRIBUTOS</h2>
                      <span>
                        Selecione o(s) atributo(s) disponíveis para essa
                        categoria.
                      </span>
                    </Col>
                    <Attributes
                      populateProperties={populateProperties}
                      attributesList={attributes}
                      loading={isLoading}
                    />
                  </Row>
                  <Button
                    className="ant-btn ant-btn-primary"
                    style={{ marginTop: 25 }}
                    onClick={onSubmit}
                    loading={updated}
                  >
                    Salvar
                  </Button>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ width: '80%' }}>
                  <Row style={{ marginBottom: 24 }}>
                    <p style={{ marginBottom: 8, fontWeight: 'bold' }}>
                      Imagem Categoria
                    </p>
                    <UploadImage
                      setUploadImage={setUploadImage}
                      uploadImage={uploadImage}
                      withoutImage={withoutImage}
                      setWithoutImage={setWithoutImage}
                      recommendedSize="400 x 400"
                    />
                  </Row>
                  {/* <Row>
                    <p style={{ marginBottom: 8, fontWeight: 'bold' }}>
                      Imagem Web (Menu)
                    </p>
                    <UploadImage
                      setUploadImage={setUploadWebImage}
                      uploadImage={uploadWebImage}
                      withoutImage={withoutWebImage}
                      setWithoutImage={setWithoutWebImage}
                      recommendedSize="400 x 400"
                    />
                  </Row> */}
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

NewCategory.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const NewCategoryForm = Form.create({ name: 'NewCategory' })(NewCategory);

export default NewCategoryForm;
