import { apiPortal } from '@/src/api/config';
import urlBuilderTimeline from '@/src/utils/urlBuilderTimeline';

export const getTimelineEvents = ({ filters, object_id, object_type }) => {
  const url = urlBuilderTimeline(
    '/timeline_events',
    filters,
    object_id,
    object_type
  );
  return apiPortal.get(url);
};

export const commentTimelineEvents = ({ value, object_id, object_type }) =>
  apiPortal.post('/timeline_events/comments', {
    value,
    object_id,
    object_type
  });

export const deleteCommentTimelineEvents = ({ id, object_id, object_type }) =>
  apiPortal.delete(`/timeline_events/comments/${id}`, {
    params: { object_id, object_type }
  });
