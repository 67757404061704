import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';

import {
  setGatewayStores,
  setGatewayStore,
  setIndexLoading,
  setShowLoading,
  getGatewayStores,
  getGatewayStore,
  createGatewayStore,
  updateGatewayStore
} from '@/src/store/modules/gateway-stores/slice';

import {
  getGatewayStores as getGatewayStoresRequest,
  getGatewayStore as getGatewayStoreRequest,
  updateGatewayStore as updateGatewayStoreRequest,
  createGatewayStore as createGatewayStoreRequest
} from '@/src/api/gateway-stores';

import {
  getGatewayStoresQueryState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getGatewayStoresSaga({ payload }) {
  try {
    yield put(setIndexLoading(true));
    const response = yield call(getGatewayStoresRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/gateway_store',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    const count = response.data?.meta?.count || 0;
    yield put(
      setGatewayStores({
        data: normalized,
        query: payload.query,
        count
      })
    );
    const query = yield select(getGatewayStoresQueryState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== query.url) {
      yield put(push(query.url));
    }
    yield put(setIndexLoading(false));
  } catch (err) {
    yield put(setIndexLoading(false));
    errorAlert(err);
  }
}

function* getGatewayStoreSaga({ payload }) {
  try {
    yield put(setShowLoading(true));
    const response = yield call(getGatewayStoreRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/gateway_store',
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    yield put(setGatewayStore(normalized));
    yield put(setShowLoading(false));
  } catch (err) {
    yield put(setShowLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do fabricante');
  }
}

function* createGatewayStoreSaga({ payload }) {
  try {
    yield call(createGatewayStoreRequest, payload);
    successAlert('A loja foi adicionada com sucesso');
    const query = yield select(getGatewayStoresQueryState);
    yield put({ type: 'GET_GATEWAY_STORES', payload: { query } });
  } catch (err) {
    errorAlert(err, 'Não foi possível criar a loja');
  }
}

function* updateGatewayStoreSaga({ payload }) {
  try {
    yield call(updateGatewayStoreRequest, payload);
    successAlert('Os dados da loja foram atualizados');
    const query = yield select(getGatewayStoresQueryState);
    yield put({ type: 'GET_GATEWAY_STORES', payload: { query } });
  } catch (err) {
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

function* manufacturersSaga() {
  yield takeEvery(getGatewayStores, getGatewayStoresSaga);
  yield takeEvery('GET_GATEWAY_STORES', getGatewayStoresSaga);
  yield takeEvery(getGatewayStore, getGatewayStoreSaga);
  yield takeEvery(createGatewayStore, createGatewayStoreSaga);
  yield takeEvery(updateGatewayStore, updateGatewayStoreSaga);
}

export default manufacturersSaga;
