import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import {
  setHighlights,
  setLoading,
  setHighlight,
  getHighlights,
  getHighlight,
  getCreateHighlight,
  updateHighlight,
  archiveHighlight,
  unarchiveHighlight,
  getOrderByDragNDrop
} from '@/src/store/modules/highlights/slice';
import {
  getHighlightsList as getHighlightsRequest,
  getCreateHighlight as getCreateHighlightRequest,
  getHighlightData as getHighlightDataRequest,
  updateHighlight as updateHighlightRequest,
  archiveHighlight as archiveHighlightRequest,
  unarchiveHighlight as unarchiveHighlightRequest,
  orderByDragNDrop as orderByDragNDropRequest
} from '@/src/api/highlights';

import {
  getHighlightsQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import bugsnag from '@/src/services/bugsnag';

function* getHighlightsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getHighlightsRequest, payload);
    const count = response.data.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(
      setHighlights({
        highlights: response.data.data,
        count,
        query: payload.query
      })
    );
    const queryUrl = yield select(getHighlightsQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os destaques');
  }
}

function* getCreateHighlightSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(getCreateHighlightRequest, payload);
    yield put(setLoading(false));
    yield put(push('/destaques'));
    successAlert('Novo destaque criado');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar o destaque');
  }
}

function* getHighlightSaga({ payload }) {
  try {
    const response = yield call(getHighlightDataRequest, payload);
    yield put(setHighlight(response.data.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do destaque');
  }
}

function* updateHighlightSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(updateHighlightRequest, payload);
    yield put(push('/destaques'));
    successAlert('Os dados do destaque foram atualizados');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

function* archiveHighlightSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(archiveHighlightRequest, payload);
    yield put(push('/destaques'));
    successAlert('O destaque foi arquivado');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível arquivar o destaque');
  }
}

function* unarchiveHighlightSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(unarchiveHighlightRequest, payload);
    yield put(push('/destaques'));
    successAlert('O destaque foi desarquivado');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível desarquivar o destaque');
  }
}

function* getOrderByDragNDropSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(orderByDragNDropRequest, payload);
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    bugsnag.notify(err);
    // errorAlert(err, 'Não foi possível mover o destaque');
  }
}

function* highlightsSaga() {
  yield takeEvery(getHighlights, getHighlightsSaga);
  yield takeEvery(getCreateHighlight, getCreateHighlightSaga);
  yield takeEvery(getHighlight, getHighlightSaga);
  yield takeEvery(updateHighlight, updateHighlightSaga);
  yield takeEvery(archiveHighlight, archiveHighlightSaga);
  yield takeEvery(unarchiveHighlight, unarchiveHighlightSaga);
  yield takeEvery(getOrderByDragNDrop, getOrderByDragNDropSaga);
}

export default highlightsSaga;
