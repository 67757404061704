import { createSlice } from '@reduxjs/toolkit';
// import merge from 'lodash/merge';

const initialState = {
  list: [],
  region: {},
  isLoading: false
};

const regionsSlice = createSlice({
  name: '@regionsData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setRegions(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setRegionSingle(prevState, action) {
      const state = prevState;
      state.region = action.payload;
      return state;
    },
    setCreateRegion(prevState, action) {
      const state = prevState;
      state.list = [...state.list, ...action.payload];
      // state.list.meta['/regions'].data = [
      //   ...state.list.meta['/regions'].data,
      //   ...action.payload.meta['/new-region'].data
      // ];
      return state;
    },
    getRegions(state) {
      return state;
    },
    getRegionSingle(state) {
      return state;
    },
    getSwitchZipcodeAssociation(state) {
      return state;
    },
    getCreateRegion(state) {
      return state;
    },
    deleteZipcode(state) {
      return state;
    },
    dowloadZipcodes(state) {
      return state;
    },
    uploadZipcodes(state) {
      return state;
    }
  }
});

export const {
  setIsLoading,
  setRegions,
  setRegionSingle,
  setCreateRegion,
  getRegions,
  getRegionSingle,
  getSwitchZipcodeAssociation,
  getCreateRegion,
  deleteZipcode,
  dowloadZipcodes,
  uploadZipcodes
} = regionsSlice.actions;

export default regionsSlice.reducer;
