import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Row, Col } from 'antd';

const LoginForm = props => {
  const { form, handleSubmit } = props;

  const { loginData, loginFailed, isLoading, newPassword } = useSelector(
    state => state.login
  );

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        return handleSubmit(values.email, values.password);
      }
      return false;
    });
  };

  const handleLoginError = err => {
    let message;
    switch (err) {
      case 'unauthorized':
        message = 'Sua sessão expirou. Faça login novamente para continuar.';
        break;
      case 'cors':
        message = 'Ocorreu um erro de conexão. Tente novamente.';
        break;
      case 'banned':
        message = 'Usuário banido. Entre em contato com o administrador.';
        break;
      default:
        message = 'E-mail ou senha incorretos! Tente novamente.';
    }
    return message;
  };

  return (
    <Form onSubmit={onSubmit} className="login-form">
      {loginFailed && (
        <>
          <Alert
            message={handleLoginError(loginData?.error)}
            type="error"
            showIcon
          />
          <br />
        </>
      )}
      {newPassword && (
        <>
          <Alert
            message="Senha alterada com sucesso. Inicie uma nova sessão."
            type="success"
            showIcon
          />
          <br />
        </>
      )}
      <Row type="flex" justify="center" align="middle">
        <Col span={24} lg={18}>
          <Form.Item>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, preencher este campo'
                },
                {
                  type: 'email',
                  message: 'E-mail deve ser válido'
                }
              ]
            })(<Input placeholder="E-mail" data-test="email-input" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={24} lg={18}>
          <Form.Item>
            {form.getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Por favor, preencher este campo' }
              ]
            })(
              <Input.Password
                type="password"
                data-test="password-input"
                placeholder="Senha"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              type="primary"
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              data-test="login-form-submit"
            >
              Entrar
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Row type="flex" justify="center" style={{ marginBottom: 4 }}>
        <Link to="/resetar-senha">Esqueci a senha</Link>
      </Row>
    </Form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const WrappedNormalLoginForm = Form.create({ name: 'login-form' })(LoginForm);

export default WrappedNormalLoginForm;
