export const shippingsQuery = {
  query: {
    include:
      'delivery_company,delivery_kind,deliverer,deliveries.order.hub,deliveries.order.volumes,deliveries.order.customer',
    fields: [
      {
        key: 'orders',
        value: 'id,total,number,hub'
      },
      { key: 'hubs', value: 'name' },
      // { key: 'delivery_companies', value: 'delivery_kind' },
      // { key: 'deliverers', value: 'name,motorcycle,plate' },
      { key: 'delivery_kinds', value: 'name' },
      { key: 'customers', value: 'name' },
      { key: 'volumes', value: 'id' }
    ],
    filters: [],
    perPage: 20,
    page: 1,
    // sort: 'name',
    stats: [{ key: 'total', value: 'count' }]
  }
};

export const ordersQuery = {
  query: {
    filters: [{ key: 'status', type: 'eq', value: 'separated' }],
    fields: [
      {
        key: 'orders',
        value: 'id,total,number,has_delivery,volume_count,customer_name'
      }
    ],
    perPage: 100,
    sort: '-updated_at'
  }
};

export const deliveriesQuery = {
  query: {
    include:
      'delivery_company,delivery_kind,deliveries.order.volumes,deliveries.order.customer',
    fields: [
      {
        key: 'orders',
        value: 'id,total,number,has_delivery'
      },
      { key: 'delivery_companies', value: 'id,name' },
      { key: 'delivery_kinds', value: 'id,name' },
      { key: 'customers', value: 'name' },
      { key: 'volumes', value: 'id' },
      { key: 'deliveries', value: 'id,order' }
    ]
  }
};
