import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Breadcrumb,
  Button,
  Card,
  Tooltip,
  Icon
} from 'antd';

import PropTypes from 'prop-types';

import { getCreateAttribute } from '@/src/store/modules/attributes/slice';

import { setCurrentValues } from '@/src/store/modules/attribute-values/slice';

import AttributesValue from '@/src/components/AttributesValue';

const NewAttribute = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const attributeValuesRef = useRef([{}]);

  const [loading, setLoading] = useState(false);

  const { currentValues } = useSelector(state => state.attributeValues);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentValues([{}]));
  }, [dispatch]);

  const handleSubmitWithAllValues = formValues => {
    const newValues = attributeValuesRef.current
      .filter(item => item)
      .map(item => item.state.value);
    const payload = {
      name: formValues.name,
      values: newValues
    };
    dispatch(getCreateAttribute(payload));
  };

  const handleAttributeValues = () => {
    const newValues = attributeValuesRef.current
      .filter(item => item)
      .map(item => {
        return { name: item.state.value };
      });
    dispatch(setCurrentValues(newValues));
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (attributeValuesRef) handleAttributeValues();
      if (err) {
        return;
      }
      if (!err) {
        let isValidated = true;
        if (!attributeValuesRef) return;
        attributeValuesRef.current.forEach(attribute => {
          const el = attribute;
          if (el && el.state.value === '') {
            el.input.classList.add('input-alert');
            isValidated = false;
          }
        });
        if (isValidated) {
          setLoading(true);
          handleSubmitWithAllValues(values);
        }
      }
    });
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Novo Atributo</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/atributos">Atributos</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo Atributo</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card className="no-shadow" title="DETALHES DO ATRIBUTO">
          <Form style={{ marginTop: 16 }}>
            <Row>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 4
                }}
              >
                <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                  Nome do atributo
                </p>
                <Tooltip
                  placement="right"
                  title="Nome do atributo que irá aparecer no app."
                >
                  <Icon style={{ marginLeft: 6 }} type="info-circle" />
                </Tooltip>
              </div>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, preencha o nome do atributo'
                      }
                    ]
                  })(<Input placeholder="Digitar nome" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Valores</p>
              <AttributesValue
                attributes={currentValues}
                attributeValuesRef={attributeValuesRef}
              />
            </Row>
            <Button
              className="ant-btn ant-btn-primary"
              style={{ marginTop: 25 }}
              onClick={onSubmit}
              loading={loading}
            >
              Salvar
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
};

NewAttribute.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const NewAttributeForm = Form.create({ name: 'NewAttribute' })(NewAttribute);

export default NewAttributeForm;
