import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

import BUGSNAG_KEY from '@/src/globals/bugsnagKey';

const pjson = require('@/package.json');

const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_KEY,
  appVersion: pjson.version,
  notifyReleaseStages: ['production']
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
