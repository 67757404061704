import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  departments: [],
  query: {
    filters: {},
    url: ''
  },
  isLoading: false
};

const departmentsSlice = createSlice({
  name: '@departments',
  initialState,
  reducers: {
    setDepartments(prevState, action) {
      const state = prevState;
      state.departments = action.payload.departments;
      if (action.payload.query) {
        state.query = {
          filters: action.payload.query.filters || {},
          url: `?${
            action.payload.query.filters
              ? buildQueryStringFromObj(action.payload.query.filters).replace(
                  '&',
                  ''
                )
              : ''
          }`
        };
      }
      state.isLoading = false;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    getDepartments(state) {
      return state;
    }
  }
});

export const {
  setDepartments,
  setLoading,
  getDepartments
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
