import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Pagination, Card, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';

import PromotionCard from '@/src/components/Promotions/PromotionCard/PromotionCard';
import SearchBar from '@/src/components/Searchbar/Searchbar';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
import FilterMenu from '@/src/pages/Filters/index';
// import SaveFilters from '@/src/components/Filters/SaveFilters/index';
// import BookmarksNav from '@/src/components/Filters/BookmarksNav/index';

import {
  getPromotions,
  setPromotionsSelectedFilters,
  archivePromotion,
  unarchivePromotion,
  duplicatePromotion,
  exportCSV
} from '@/src/store/modules/promotions/slice';

// import {
//   saveAccountBookmark,
//   removeAccountBookmark
// } from '@/src/store/modules/account/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

const Promotions = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    promotions,
    query,
    total,
    isLoading,
    tagsPromotions
    // selectedFilters
  } = useSelector(state => state.promotions);

  const {
    // bookmarks,
    // loadingBookmarks,
    permissions
  } = useSelector(state => state.account);

  // const saveAccountBookmarkHandler = bookmark =>
  //   dispatch(saveAccountBookmark(bookmark));
  // const removeAccountBookmarkHandler = bookmark =>
  //   dispatch(removeAccountBookmark(bookmark));
  const setSelectedFiltersHandler = filters =>
    dispatch(setPromotionsSelectedFilters(filters));
  const archivePromotionHandler = promotion =>
    dispatch(archivePromotion(promotion));
  const unarchivePromotionHandler = promotion =>
    dispatch(unarchivePromotion(promotion));
  const duplicatePromotionHandler = promotion =>
    dispatch(duplicatePromotion(promotion));
  const getPromotionsHandler = useCallback(
    urlQuery => {
      dispatch(getPromotions({ ...urlQuery }));
    },
    [dispatch]
  );

  const exportCSVHandler = () => {
    const params = buildQueryFromUrl(history);
    dispatch(exportCSV({ query: params }));
  };

  const handleChangeTab = key => {
    if (query.filters[key]) return;
    const newQuery = {
      query: {
        ...query,
        filters: {
          ...query.filters
        }
      }
    };
    delete newQuery.query.filters.not_archived;
    delete newQuery.query.filters.archived;
    newQuery.query.filters = {
      ...newQuery.query.filters,
      [key]: true
    };
    getPromotionsHandler(newQuery);
  };

  const loadingElement = (
    <Col style={{ marginTop: '2em' }} span={8}>
      <Card style={{ boxShadow: 'none', paddingTop: '10px' }} loading />
    </Col>
  );

  const loadingCards = (
    <>
      {loadingElement}
      {loadingElement}
      {loadingElement}
      {loadingElement}
      {loadingElement}
      {loadingElement}
    </>
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    let updatedQuery = params;
    if (!params?.filters?.not_archived)
      updatedQuery = {
        ...params,
        filters: {
          not_archived: true
        }
      };
    getPromotionsHandler({ query: updatedQuery });
  }, [history, getPromotionsHandler]);

  const goToPromo = id => {
    history.push(`/promocoes/${id}`);
  };

  const listPromos = () => {
    return promotions.length > 0 ? (
      promotions.map(promo => {
        const { id } = promo;
        // Take tags' ids
        const idTagsInPromotion = promo.relationships?.tags?.data.map(data =>
          Number(data.id)
        );
        // take tags in promotions
        const tag = tagsPromotions.map(tags => tags);
        /**
         * Return tags that has promotion id is equal in tags in promotion
         * Pass tags referent in your perspective promotion to promotioncard
         */
        const eachTags = idTagsInPromotion.map(tagsInPromotion =>
          tag.filter(tags => tags.id === tagsInPromotion)
        );
        return (
          <Col span={8} key={id}>
            <PromotionCard
              permissions={permissions}
              promoId={id}
              goToPromo={goToPromo}
              archivePromotion={archivePromotionHandler}
              unarchivePromotion={unarchivePromotionHandler}
              duplicatePromotion={duplicatePromotionHandler}
              promotion={promo.attributes}
              tag={eachTags}
            />
          </Col>
        );
      })
    ) : (
      <h2 style={{ textAlign: 'center' }}>Nenhuma promoção foi encontrada</h2>
    );
  };

  // const promotionsBookmarks = bookmarks.filter(
  //   bookmark => bookmark.attributes.scope === 'Promotions'
  // );

  const pageChange = (page, pageSize) => {
    const pageChangeQuery = {
      ...query,
      page: query.perPage !== pageSize ? 1 : page,
      perPage: pageSize
    };
    getPromotionsHandler({ query: pageChangeQuery });
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle" className="mb-20">
        <Col>
          <h1 className="m-0">Promoções</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => history.push('/promocoes/nova')}
          >
            Nova Promoção
          </Button>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '8px 16px 16px'
        }}
      >
        <Row>
          <Col span={24}>
            <Tabs
              onChange={handleChangeTab}
              style={{ marginBottom: 20 }}
              type="card"
            >
              <Tabs.TabPane tab="Ativas" key="not_archived" />
              <Tabs.TabPane tab="Arquivadas" key="archived" />
            </Tabs>
            {/* <BookmarksNav
              scope="Promotions"
              accountBookmarks={promotionsBookmarks}
              populateItems={getPromotionsHandler}
              removeAccountBookmark={removeAccountBookmarkHandler}
              query={query}
              saveAccountBookmark={saveAccountBookmarkHandler}
              selectedFilters={selectedFilters}
            >
              <Tabs.TabPane
                tab={<span>Arquivadas</span>}
                key="archived"
                closable={false}
              />
            </BookmarksNav> */}
          </Col>
        </Row>
        <Row type="flex" justify="space-between" gutter={[8, 8]}>
          <Col span={16}>
            <SearchBar
              placeholder="Buscar por promoção ou cupom"
              populateResult={getPromotionsHandler}
              query={query}
              value={query.search}
            />
          </Col>
          <div style={{ display: 'flex' }}>
            <Col>
              <Row type="flex" justify="end">
                <FilterMenu filterContent="promotions" />
              </Row>
            </Col>
            <Col>
              <Button
                onClick={exportCSVHandler}
                size="large"
                className="ant-btn-secondary"
              >
                exportar csv
              </Button>
            </Col>
          </div>
        </Row>
        <Row type="flex">
          {/* {selectedFilters ? (
            <Col>
              <section className="mt-20 mb-20">
                <SaveFilters
                  scope="Promotions"
                  accountBookmarks={bookmarks}
                  query={query}
                  loadingBookmarks={loadingBookmarks}
                  saveAccountBookmark={saveAccountBookmarkHandler}
                />
              </section>
            </Col>
          ) : null} */}
          <Col>
            <section className="mt-20 mb-20">
              <FilteredTags
                query={query}
                populateTable={getPromotionsHandler}
                setSelectedFilters={setSelectedFiltersHandler}
              />
            </section>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" style={{ marginTop: '20px' }}>
          <Col span={12}>
            <div className="table-results table-results__promos">
              <p>
                <span>{total}</span>{' '}
                {total === 1 ? 'promoção criada' : 'promoções criadas'}
              </p>
            </div>
          </Col>
          <Col span={12}>
            <Pagination
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              showSizeChanger
              pageSize={query.perPage}
              pageSizeOptions={['10', '20', '30', '50', '100']}
              onShowSizeChange={pageChange}
              defaultCurrent={1}
              current={query.page}
              total={total}
              onChange={pageChange}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ minHeight: '200px' }}>
          {isLoading ? loadingCards : listPromos()}
        </Row>
      </div>
    </>
  );
};

export default Promotions;
