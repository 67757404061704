import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import moment from '@/src/services/moment';

import ifoodLogo from '@/src/assets/images/dispatch/ifood-logo.png';
import styles from './styles';

const VolumeTag = ({ order, qrCode }) => {
  const ifoodOrder = order.saleChannelName?.toLowerCase() === 'ifood';
  return (
    <Document>
      {qrCode.map((item, index) => (
        <Page size="A4" key={`order-${order.number}-vol-${index + 1}`}>
          <View style={ifoodOrder ? styles.ifoodTag : styles.tag}>
            {ifoodOrder ? (
              <View style={styles.ifoodHeader}>
                <Text>ZEE.NOW + IFOOD</Text>
                <Image style={styles.logo} src={ifoodLogo} />
              </View>
            ) : (
              <Text style={styles.title}>ZEE.NOW</Text>
            )}
            <View style={styles.content}>
              <Text style={styles.subtitle}>PEDIDO: {order.number || '-'}</Text>
              <View style={styles.flexSpaceRow}>
                <Text style={styles.subtitleSm}>
                  {order.createdAt
                    ? moment(order.createdAt).format('DD/MM/YYYY - HH:mm')
                    : 'Horário: -'}
                </Text>
                <Text style={styles.subtitleSm}>
                  VOLUME {index + 1}/{qrCode.length}
                </Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.text}>
                  {order.customerName?.toUpperCase() || 'Cliente: -'}
                </Text>
                <Text style={styles.text}>
                  {order.addressStreet?.toUpperCase() || ''}
                  {order.addressStreet &&
                  (order.addressNumber || order.addressComplement)
                    ? ', '
                    : ''}
                  {order.addressNumber?.toUpperCase() || ''}
                  {order.addressNumber && order.addressComplement ? ', ' : ''}
                  {order.addressComplement?.toUpperCase()}
                </Text>
                <Text style={styles.text}>
                  {order.addressDistrict?.toUpperCase() || ''}
                </Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.text}>
                  {order.addressCity?.toUpperCase() || ''}
                  {order.addressCity && order.addressState ? ' - ' : ''}
                  {order.addressState?.toUpperCase() || ''}
                </Text>
                {order.addressZipcode && (
                  <Text style={styles.text}>CEP: {order.addressZipcode}</Text>
                )}
              </View>
              {order.pickupCode && order.pickupCode !== '' && (
                <View style={styles.contentRow}>
                  <Text style={styles.textSmall}>
                    CÓDIGO ENTREGA: {order.pickupCode}
                  </Text>
                </View>
              )}
              {order.remarks !== '' && (
                <View style={styles.contentRow}>
                  <Text style={styles.textSmall}>Obs: {order.remarks}</Text>
                </View>
              )}
              <View style={styles.flexCenter}>
                <Image style={styles.qrcode} src={item} />
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

VolumeTag.defaultProps = {
  order: {},
  qrCode: []
};

VolumeTag.propTypes = {
  order: PropTypes.objectOf(PropTypes.any),
  qrCode: PropTypes.arrayOf(PropTypes.any)
};

export default VolumeTag;
