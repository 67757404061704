import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getProductsByCategory as getProductsByCategoryRequest,
  createAisle,
  updateAisle as updateAisleResquest,
  getEditAisle as getEditAisleRequest,
  archiveAisle as archiveAisleResquest,
  unarchiveAisle as unarchiveAisleResquest,
  getAisles as getAislesRequest,
  inactiveAisle as inactiveAisleRequest,
  activeAisle as activeAisleRequest,
  orderAllAisles as orderAllAislesRequest
} from '@/src/api/aisles';

import importCSVAllAisles from '@/src/api/import-file';
import { exportCSVAisle as exportCSVAisleRequest } from '@/src/api/export-file';

import {
  setProductsByCategories,
  getProductsByCategory,
  getAddProductsToSave,
  setEditAisle,
  setAddProductsToSave,
  setIsLoading,
  getDeleteProducts,
  setDeleteProducts,
  setAisles,
  getCreateAisle,
  getEditAisle,
  unarchiveAisle,
  archiveAisle,
  getAisles,
  getUpdateAisle,
  setIsEditLoading,
  sendCSVOrderBy,
  exportCSVAisle,
  inactiveAisle,
  activeAisle,
  setOrderByCSVAllAisles,
  orderAllAisles,
  setOrderAllAisles
} from '@/src/store/modules/aisles/slice';

import {
  getProductsAisle,
  getAddedProductsAisle,
  getAislesQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getProcutsByCategoriesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const addedProducts = yield select(getAddedProductsAisle);
    const response = yield call(getProductsByCategoryRequest, payload);
    // const products = response.data.data.map(elements => ({
    //   ...elements,
    //   addChecked: 'active'
    // }));
    const products = yield response.data.data.map(elements => {
      let productsChanges;
      if (
        addedProducts.length > 0 &&
        addedProducts.some(el => el.id === elements.id)
      ) {
        productsChanges = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: true }
        };
      } else {
        productsChanges = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: false }
        };
      }
      return productsChanges;
    });
    yield put(
      setProductsByCategories({
        products,
        count: response.data.meta.count,
        query: payload.query
      })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* getAddProcutsSaga({ payload }) {
  try {
    const productsState = yield select(getProductsAisle);
    const addedProductsState = yield select(getAddedProductsAisle);
    /**
     * add payload in state
     * add checked flag in payload
     * update addedProducts' state
     */
    let addProductCheckedFromModal;
    let changeProductCheckedFromState;
    let updateProductsChecked;
    if (addedProductsState.length === 0) {
      addProductCheckedFromModal = yield payload.map(elements => ({
        ...elements,
        attributes: { ...elements.attributes, present_on_aisle: true }
      }));
    } else {
      let newArray;
      changeProductCheckedFromState = yield payload.map(elements => {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: true }
        };
        return newArray;
      });
      updateProductsChecked = yield [
        ...addedProductsState,
        ...changeProductCheckedFromState
      ];
    }

    // change state products.addChecked with new values
    const changeCheckedProductsState = yield productsState.map(elements => {
      let newArray;
      if (payload.includes(elements)) {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: true }
        };
      } else if (elements.attributes.present_on_aisle !== true) {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: false }
        };
      } else {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: true }
        };
      }
      return newArray;
    });
    yield put(
      setProductsByCategories({ products: changeCheckedProductsState })
    );
    yield put(
      setAddProductsToSave(
        addedProductsState.length === 0
          ? addProductCheckedFromModal
          : updateProductsChecked
      )
    );
  } catch (err) {
    errorAlert(err);
  }
}

function* getDeleteProcutsSaga({ payload }) {
  try {
    const productsState = yield select(getProductsAisle);
    const addedProductsState = yield select(getAddedProductsAisle);

    // updated addedProduct's state without value deleted
    const deleteProducts = addedProductsState.filter(
      el => !payload.includes(el)
    );
    yield put(setDeleteProducts(deleteProducts));

    // change attribute 'present_on_aisle' to save on products' state
    const changeCheckedProductsState = yield productsState.map(elements => {
      let newArray;
      if (payload.some(el => elements.id === el.id)) {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: false }
        };
      } else if (elements.attributes.present_on_aisle !== false) {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: true }
        };
      } else {
        newArray = {
          ...elements,
          attributes: { ...elements.attributes, present_on_aisle: false }
        };
      }
      return newArray;
    });

    yield put(
      setProductsByCategories({ products: changeCheckedProductsState })
    );
  } catch (err) {
    errorAlert(err);
  }
}

function* getCreateSaga({ payload }) {
  try {
    yield call(createAisle, payload);
    successAlert('Corredor criado com sucesso');
    yield put(push('/corredores'));
  } catch (err) {
    errorAlert(err);
  }
}

function* getEditAisleSaga({ payload }) {
  try {
    yield put(setIsEditLoading(true));
    const response = yield call(getEditAisleRequest, payload);
    const editData = response.data.data;
    const products = response.data.included;
    yield put(setEditAisle(editData));
    yield put(setAddProductsToSave(products));
    yield put(setIsEditLoading(false));
  } catch (err) {
    yield put(setIsEditLoading(false));
    errorAlert(err);
  }
}

function* getUpdateAisleSaga({ payload }) {
  try {
    yield call(updateAisleResquest, payload);
    successAlert('Corredor editado com sucesso');
    yield put(push('/corredores'));
  } catch (err) {
    errorAlert(err);
  }
}

function* unarchiveAisleSaga({ payload }) {
  try {
    yield call(unarchiveAisleResquest, payload);
    successAlert('Corredor desarquivado com sucesso');
  } catch (err) {
    errorAlert(err);
  }
}

function* archiveAisleSaga({ payload }) {
  try {
    yield call(archiveAisleResquest, payload);
    successAlert('Corredor arquivado com sucesso');
  } catch (err) {
    errorAlert(err);
  }
}

function* inactiveAisleSaga({ payload }) {
  try {
    yield call(inactiveAisleRequest, payload);
    successAlert('A visibilidade da categoria foi atualizada');
  } catch (err) {
    errorAlert(err, 'Não foi possível atualizar a visibilidade da categoria');
  }
}

function* activeAisleSaga({ payload }) {
  try {
    yield call(activeAisleRequest, payload);
    successAlert('A visibilidade da categoria foi atualizada');
  } catch (err) {
    errorAlert(err, 'Não foi possível atualizar a visibilidade da categoria');
  }
}

function* getAislesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getAislesRequest, payload);
    const count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    yield put(
      setAisles({ aisles: response.data.data, count, query: payload.query })
    );
    const queryUrl = yield select(getAislesQueryUrlState);
    const routerState = yield select(getRouterState);
    if (
      routerState.location.search !== queryUrl &&
      routerState.location.pathname.includes('/corredores')
    ) {
      yield put(push(queryUrl));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* sendCSVOrderBySaga({ payload }) {
  const { id, csv, type, category } = payload;
  try {
    yield put(setIsLoading(true));
    const data = new FormData();
    data.append('file', csv[0]);
    if (category) {
      data.append('category_id', category);
    }
    const response = yield call(importCSVAllAisles, type, id, data);
    if (category) {
      yield put(setOrderByCSVAllAisles(response.data.data));
    } else {
      yield put(setAddProductsToSave(response.data.data));
    }
    yield successAlert(
      `${
        type === 'edit'
          ? 'Os produtos foram ordenados.'
          : 'A ordenação foi atualizada. '
      }`
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* exportCSVAisleSaga({ payload }) {
  try {
    const response = yield call(exportCSVAisleRequest, payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* orderAllAislesSaga({ payload }) {
  const { data, category } = payload;

  try {
    yield put(setIsLoading(true));
    const ids = data.map(value => value.id);
    yield call(orderAllAislesRequest, category, ids);
    yield put(setOrderAllAisles(data));
    yield successAlert('A ordernação foi atualizada.');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* AislesSaga() {
  yield takeEvery(getProductsByCategory, getProcutsByCategoriesSaga);
  yield takeEvery(getAddProductsToSave, getAddProcutsSaga);
  yield takeEvery(getDeleteProducts, getDeleteProcutsSaga);
  yield takeEvery(getCreateAisle, getCreateSaga);
  yield takeEvery(getEditAisle, getEditAisleSaga);
  yield takeEvery(getUpdateAisle, getUpdateAisleSaga);
  yield takeEvery(unarchiveAisle, unarchiveAisleSaga);
  yield takeEvery(archiveAisle, archiveAisleSaga);
  yield takeEvery(inactiveAisle, inactiveAisleSaga);
  yield takeEvery(activeAisle, activeAisleSaga);
  yield takeEvery(getAisles, getAislesSaga);
  yield takeEvery(sendCSVOrderBy, sendCSVOrderBySaga);
  yield takeEvery(exportCSVAisle, exportCSVAisleSaga);
  yield takeEvery(orderAllAisles, orderAllAislesSaga);
}

export default AislesSaga;
