import moment from 'moment';

export const isValidHour = hour => {
  if (!hour) return true;
  const hours = hour.split(':')[0];
  const minutes = hour.split(':')[1];
  return parseInt(hours, 10) <= 23 && parseInt(minutes, 10) <= 59;
};

export const elapsedTime = value => {
  const createdAt = moment().subtract(value, 'minutes');
  return createdAt.format();
};
