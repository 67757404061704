import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import './SquareButton.scss';

const SquareButton = ({
  backgroundColor,
  border,
  iconType,
  size,
  iconSize,
  disabled,
  onClick
}) => {
  return (
    <>
      <button
        style={{
          backgroundColor: `${backgroundColor}`,
          border: `${border}`,
          width: `${size}`,
          height: `${size}`
        }}
        type="submit"
        disabled={disabled}
        className="pack-square-btn"
        onClick={onClick}
        aria-label={iconType}
      >
        <Icon
          type={iconType}
          style={{ color: '#333', fontSize: `${iconSize}`, fontWeigth: 'bold' }}
        />
      </button>
    </>
  );
};

SquareButton.defaultProps = {
  disabled: false,
  onClick: () => {}
};

SquareButton.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  border: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  iconSize: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default SquareButton;
