import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Tooltip,
  Form,
  Icon,
  Input,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Menu
} from 'antd';
import PropTypes from 'prop-types';

import Audit from '@/src/components/Audit/index';

import {
  getDepartmentAttributes,
  getEdit,
  getUpdateDepartment,
  getArchiveAndUnarchive,
  setIsNewAsFalse
} from '@/src/store/modules/catalog-departments/slice';

const EditDepartment = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const [showAudit, setShowAudit] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { editDepartment, isLoading } = useSelector(
    state => state.catalogDepartments
  );

  const archiveDepartmentHandle = () => {
    dispatch(getArchiveAndUnarchive({ id, type: 'archive' }));
  };

  const unarchiveDepartmentHandle = () => {
    dispatch(getArchiveAndUnarchive({ id, type: 'unarchive' }));
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      {editDepartment?.data?.attributes?.archived_at ? (
        <Menu.Item key="2" onClick={unarchiveDepartmentHandle}>
          Desarquivar
        </Menu.Item>
      ) : (
        <Menu.Item key="1" onClick={archiveDepartmentHandle}>
          Arquivar
        </Menu.Item>
      )}
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const handleSubmitWithAllValues = values => {
    const payload = {
      id,
      data: {
        name: values.name,
        search_metadata: values.search_metadata || '',
        title: values.title || '',
        meta_title: values.meta_title || '',
        meta_description: values.meta_description || ''
      }
    };
    dispatch(getUpdateDepartment(payload));
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  useEffect(() => {
    dispatch(getEdit(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (editDepartment?.data?.attributes?.id)
      dispatch(getDepartmentAttributes(editDepartment.data.attributes.id));
  }, [dispatch, editDepartment]);

  useEffect(() => {
    dispatch(setIsNewAsFalse());
  }, [dispatch]);

  return (
    <>
      <Audit
        id={id}
        title="DEPARTAMENTO"
        type="Category"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar {editDepartment?.data?.attributes?.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/departamentos">Departamento</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar departamento</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DO DEPARTAMENTO"
          extra={actionsExtra}
          loading={isLoading}
        >
          <Col span={12}>
            <Form style={{ marginTop: 10 }}>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Nome do departamento
                    </p>
                    <Tooltip
                      placement="right"
                      title="Nome do departamento que aparecerá no app e site"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      initialValue: editDepartment?.data?.attributes?.name,
                      rules: [
                        {
                          required: true,
                          message: 'Campo obrigatório'
                        }
                      ]
                    })(<Input placeholder="Nome do departamento" />)}
                  </Form.Item>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Palavras-chave
                    </p>
                    <Tooltip
                      placement="right"
                      title="Palavras que facilitam encontrar departamento através da busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('search_metadata', {
                      initialValue:
                        editDepartment?.data?.attributes?.search_metadata
                    })(
                      <Input.TextArea placeholder="Escreva as palavras-chave" />
                    )}
                  </Form.Item>
                </Col>
                <h2 style={{ marginBottom: 16 }}>SEO (WEB)</h2>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Title</p>
                    <Tooltip
                      placement="right"
                      title="Título exibido na página do departamento"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 2 }}>
                    {getFieldDecorator('title', {
                      initialValue: editDepartment?.data?.attributes?.title
                    })(<Input placeholder="Título da vitrine" />)}
                  </Form.Item>
                  <span style={{ display: 'block', marginBottom: 24 }}>
                    *Caso não preenchido, será o nome do departamento
                  </span>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da página exibido em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_title', {
                      initialValue: editDepartment?.data?.attributes?.meta_title
                    })(
                      <Input placeholder="Exemplo: Zee.Now - Nome do departamento" />
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Description
                    </p>
                    <Tooltip
                      placement="right"
                      title="Descrição da página exibida em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_description', {
                      initialValue:
                        editDepartment?.data?.attributes?.meta_description
                    })(
                      <Input.TextArea placeholder="Breve descrição do departamento" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Button
              className="ant-btn ant-btn-primary"
              style={{ marginTop: 25 }}
              onClick={onSubmit}
              loading={isLoading}
            >
              Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  );
};

EditDepartment.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewDepartmentFrom = Form.create({ name: 'EditDepartment' })(
  EditDepartment
);

export default NewDepartmentFrom;
