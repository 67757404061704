import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { Row, Col, Breadcrumb, Form, Card, Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import HubMain from '@/src/components/Hubs/HubMain';
import CoverageMain from '@/src/components/Hubs/Coverage';

import { getHubSingle } from '@/src/store/modules/hubs-data/slice';

const { TabPane } = Tabs;

const EditHub = props => {
  const dispatch = useDispatch();
  const [hubData, setHubData] = useState({});
  const [hubActiveTab, setHubActiveTab] = useState(true);
  const { hub } = useSelector(state => state.hubsData);
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getHubSingle({
        id,
        query: {
          include:
            'country,state,state.cities,city,district,districts,districts.city,districts.hub',
          fields: [
            { key: 'countries', value: 'name,id' },
            { key: 'states', value: 'code,id' },
            { key: 'cities', value: 'name,id' },
            { key: 'districts', value: 'name,id' }
            // { key: 'regions', value: 'name,id' }
          ]
        }
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    const hubObj = build(hub, 'hubs', id, { ignoreLinks: true }) || {};
    setHubData(hubObj);
  }, [hub, id]);

  const renderBreadCrumb = () => {
    return (
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/hubs">Hubs</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{hubData?.name}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>{hubData?.name}</h1>
        </Col>
      </Row>
      {renderBreadCrumb()}
      <section
        style={{
          background: '#ebebeb',
          padding: '8px',
          borderRadius: '8px',
          marginBottom: '5%'
        }}
      >
        <Card className="no-shadow p-0 card-no-header">
          <Tabs
            type="card"
            defaultActiveKey="hub"
            onChange={() => setHubActiveTab(prev => !prev)}
          >
            <TabPane tab="Hub" key="hub">
              {hubActiveTab && <HubMain hubData={hubData} />}
            </TabPane>
            <TabPane tab="Cobertura" key="coverage">
              {!hubActiveTab && <CoverageMain hubData={hubData} />}
            </TabPane>
          </Tabs>
        </Card>
      </section>
    </>
  );
};

EditHub.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const EditHubForm = Form.create({ name: 'EditHub' })(EditHub);

export default EditHubForm;
