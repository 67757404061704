import React from 'react';
import { Icon } from 'antd';

const modalGather = [
  {
    title: 'Foto',
    dataIndex: '',
    key: '1',
    render: () => (
      <Icon
        type="camera"
        style={{ color: '#B3B3B3', fontSize: '25px' }}
        theme="filled"
      />
    )
  },
  {
    title: 'SKU',
    dataIndex: '',
    key: '2',
    render: data => <span>{data.sku}</span>
  },
  {
    title: 'EAN',
    dataIndex: '',
    key: '3',
    render: data => <span>{data.ean}</span>
  },
  {
    title: 'Nome produto',
    dataIndex: '',
    key: '4',
    render: data => <span>{data.nome}</span>
  },
  {
    title: 'Qnt',
    dataIndex: '',
    key: '5',
    render: data => <span>0{data.qnt}</span>
  },
  {
    title: 'Alerta',
    dataIndex: '',
    key: '6',
    render: data => (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #333',
          borderRadius: '4px',
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icon type="warning" style={{ color: '#333', fontSize: '15px' }} />
      </div>
    )
  }
];

export default modalGather;
