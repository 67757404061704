import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getHighlightsList = payload => {
  const url = queryBuilder(payload, '/highlights');
  return apiPortal.get(url);
};

export const getCreateHighlight = payload =>
  apiPortal.post('/highlights', payload);

export const getHighlightData = id => apiPortal.get(`/highlights/${id}`);

export const updateHighlight = payload =>
  apiPortal.patch(`/highlights/${payload.id}`, payload.data);

export const orderByDragNDrop = payload => {
  return apiPortal.put(`/highlights_sorting`, { ids: payload });
};

export const archiveHighlight = payload =>
  apiPortal.post('/highlights_archivation', payload);

export const unarchiveHighlight = payload =>
  apiPortal.delete('/highlights_archivation', {
    data: { ids: payload.ids }
  });
