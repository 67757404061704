import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getManufacturers = payload => {
  const getUrl = queryBuilder(payload, '/manufacturers');
  return apiPortal.get(getUrl);
};

export const getManufacturer = id => apiPortal.get(`/manufacturers/${id}`);

export const createManufacturer = payload =>
  apiPortal.post('/manufacturers', payload);

export const updateManufacturer = payload =>
  apiPortal.put(`/manufacturers/${payload.id}`, payload.data);

export const orderByDragNDrop = payload =>
  apiPortal.put(`/manufacturers_sorting/${payload}`, {
    ids: payload
  });
