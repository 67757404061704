import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getAisles = payload => {
  const getUrl = queryBuilder(payload, '/aisles');
  return apiPortal.get(getUrl);
};

// export const getCategoriesAisles = () => apiPortal.get('/categories');

export const getProductsByCategory = payload => {
  const getUrl = payload.query
    ? queryBuilder(payload, `/categories/${payload.category}/products`)
    : `/categories/${payload.category}/products`;
  return apiPortal.get(getUrl);
};

export const createAisle = payload => apiPortal.post('/aisles', payload);

export const getEditAisle = payload =>
  apiPortal.get(`/aisles/${payload.id}`, payload);

export const updateAisle = payload =>
  apiPortal.patch(`/aisles/${payload.id}`, payload);

export const archiveAisle = payload =>
  apiPortal.post('/aisles_archivation', { ids: [payload] });

export const unarchiveAisle = payload =>
  apiPortal.delete('/aisles_archivation', { data: { ids: [payload] } });

export const inactiveAisle = id =>
  apiPortal.patch(`/aisles/${id}`, { active: false });

export const activeAisle = id =>
  apiPortal.patch(`/aisles/${id}`, { active: true });

export const orderAllAisles = (category, ids) =>
  apiPortal.patch('/aisles_sorting', { ids, category_id: category });
