import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setDeliverers,
  setDeliverer,
  getDeliverers,
  getDelivererById,
  getMapDeliverers,
  setMapDeliverers,
  getMapDeliverer,
  setMapDeliverer,
  getBlankDeliverer,
  setLoading,
  getUpdateDeliverer
  // getCreateDeliverer
} from '@/src/store/modules/deliverers/slice';
import {
  getDeliverersQueryUrlState,
  getRouterState
} from '@/src/store/selectors';
import {
  getAllDeliverers,
  getDeliverer as getDelivererByIdRequest,
  getDelivererByInternalId as getDelivererByInternalIdRequest,
  updateDeliverer
  // postDeliverer
} from '@/src/api/deliverers';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getDeliverersSaga(params) {
  const { payload } = params;
  try {
    yield put(setLoading(true));
    const newPayload = payload;

    // if (payload.query.sort === '') {
    //   newPayload.query.sort = '[name]=asc';
    // }

    const response = yield call(getAllDeliverers, newPayload);

    const count = response.data?.meta?.stats?.total?.count || 0;
    yield put(
      setDeliverers({
        deliverers: response.data.data,
        count,
        // total,
        query: newPayload.query
      })
    );
    const queryUrl = yield select(getDeliverersQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* getMapDeliverersSaga(params) {
  const { payload } = params;
  try {
    const response = yield call(getAllDeliverers, payload);
    yield put(setMapDeliverers(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getDelivererByIdSaga(params) {
  const { payload } = params;
  try {
    const response = yield call(getDelivererByIdRequest, payload);
    yield put(setDeliverer(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getMapDelivererSaga(params) {
  const { payload } = params;
  try {
    yield put(setLoading(true));
    const response = yield call(getDelivererByInternalIdRequest, payload);
    yield put(setMapDeliverer(response.data.data[0]));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* getBlankDelivererSaga() {
  yield put(setDeliverer({}));
}

function* getUpdateDelivererSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(updateDeliverer, payload);
    const { status } = response;
    if (status === 200) {
      yield put(setLoading(false));
      yield put(push('/entregadores'));
      successAlert('Os dados do entregador foram atualizados');
    }
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

// function* getCreateDelivererSaga(params) {
//   const { payload } = params;
//   try {
//     yield put(setLoading(true));
//     const response = yield call(postDeliverer, payload);
//     const { status } = response;
//     if (status === 200) {
//       yield delay(2000);
//       yield put(setLoading(false));
//       yield put(push('/entregadores'));
//       yield put(setCreated(true));
//       yield delay(2000);
//       yield put(setCreated(false));
//     }
//   } catch (err) {
//     yield put(setLoading(false));
//     errorAlert(err);
//   }
// }

function* deliverersSaga() {
  yield takeEvery(getDeliverers, getDeliverersSaga);
  yield takeEvery(getDelivererById, getDelivererByIdSaga);
  yield takeEvery(getMapDeliverers, getMapDeliverersSaga);
  yield takeEvery(getMapDeliverer, getMapDelivererSaga);
  yield takeEvery(getBlankDeliverer, getBlankDelivererSaga);
  yield takeEvery(getUpdateDeliverer, getUpdateDelivererSaga);
  // yield takeEvery(getCreateDeliverer, getCreateDelivererSaga);
}

export default deliverersSaga;
