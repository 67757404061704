import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Spin } from 'antd';
import handleDispatchTag from '@/src/utils/handlePDF';
import useDispatchModal from '@/src/hooks/useDispatchModal';
import RejectReturnModal from '@/src/components/Expedition/Modal/RejectReturn';

const Footer = ({
  orderStatus,
  orderCanceled,
  orderReturned,
  handleClick,
  handleUndoStatus,
  nextStep,
  order
}) => {
  const [undoBtn, setUndoBtn] = useState(true);
  const [centeredButton, setCenteredButton] = useState(false);
  const [centeredContent, setCenteredContent] = useState('');
  const [buttonContent, setButtonContent] = useState('');
  const [tagLoading, setTagLoading] = useState(false);
  const [justifyContent, setJustifyContent] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [border, setBorder] = useState('1px solid #ebebeb');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [rejectReturnModal, setRejectReturnModal] = useState(false);

  const { handleShowModal } = useDispatchModal();

  useEffect(() => {
    setButtonContent(nextStep);
    if (orderReturned || orderCanceled) {
      setUndoBtn(false);
      setCenteredButton(true);
      setCenteredContent(
        orderReturned ? 'Concluir Devolução' : 'Concluir Cancelamento'
      );
      setJustifyContent('center');
      setBackgroundColor('#000');
      setBorder('none');
    } else if (orderStatus === 'separated') {
      setUndoBtn(false);
      setCenteredButton(true);
      setCenteredContent('Imprimir Etiqueta');
      setJustifyContent('center');
      setBackgroundColor('#33d6ef');
    } else if (orderStatus === 'waiting') {
      setUndoBtn(false);
      setCenteredButton(false);
      setJustifyContent('flex-end');
      setBackgroundColor('#fff');
    } else {
      setUndoBtn(true);
      setCenteredButton(false);
      setJustifyContent('space-between');
      setBackgroundColor('#fff');
      if (orderStatus === 'packed')
        setBtnDisabled(!order.saleInvoiceNumber && order.embarkRequireInvoice);
    }
    const requestResolved = order.orderRequests?.filter(
      item => item.requestResolved && !item.rejected
    );
    if (requestResolved.length && !orderReturned && !orderCanceled)
      setBorder(
        orderStatus === 'separated' ? '2px solid #33d6ef' : '2px solid #10ce9f'
      );
  }, [
    nextStep,
    orderStatus,
    orderReturned,
    orderCanceled,
    order.orderRequests,
    order.saleInvoiceNumber,
    order.embarkRequireInvoice
  ]);

  const handleOnClick = () => {
    if (
      orderReturned ||
      orderCanceled ||
      orderStatus === 'started' ||
      orderStatus === 'picked'
    ) {
      return handleShowModal(order, nextStep);
    }
    if (orderStatus === 'separated') {
      return handleDispatchTag(order, setTagLoading);
    }
    return handleClick(orderStatus);
  };

  const handleStatus = () => {
    return handleUndoStatus(orderStatus);
  };

  if (orderReturned || orderCanceled) {
    return (
      <>
        <RejectReturnModal
          order={order}
          visible={rejectReturnModal}
          setVisible={setRejectReturnModal}
        />
        <div
          className="card-single__footer no-border order-request"
          style={{
            backgroundColor,
            justifyContent,
            border,
            color: '#fff'
          }}
        >
          <Button
            className="ant-btn-expedition"
            onClick={() => setRejectReturnModal(true)}
          >
            Rejeitar {orderReturned ? 'Devolução' : 'Cancelamento'}
          </Button>
          <Button className="ant-btn-expedition" onClick={handleOnClick}>
            Concluir {orderReturned ? 'Devolução' : 'Cancelamento'}
          </Button>
        </div>
      </>
    );
  }

  return (
    <div
      className={
        centeredButton ? 'card-single__footer no-border' : 'card-single__footer'
      }
      style={{
        padding: centeredButton ? 0 : '10px',
        backgroundColor,
        justifyContent,
        border,
        color: '#000'
      }}
    >
      {undoBtn && (
        <Button onClick={handleStatus} className="ant-btn-expedition undo">
          <Icon
            type="left"
            style={{
              color: '#333',
              fontSize: '12px'
            }}
          />
          Desfazer
        </Button>
      )}
      {tagLoading ? (
        <div className="card-single__footer--loading">
          <Spin
            style={{ display: 'flex', marginLeft: 8 }}
            indicator={
              <Icon
                type="loading"
                style={{ fontSize: 20, color: '#000' }}
                spin
              />
            }
          />
        </div>
      ) : (
        <Button
          onClick={handleOnClick}
          className="ant-btn-expedition"
          disabled={btnDisabled}
        >
          {centeredButton ? centeredContent : buttonContent}
          {!centeredButton && (
            <Icon
              type="right"
              style={{
                color: '#333',
                fontSize: '12px'
              }}
            />
          )}
        </Button>
      )}
    </div>
  );
};

Footer.defaultProps = {
  orderCanceled: false,
  orderReturned: false,
  handleClick: () => {},
  handleUndoStatus: () => {},
  order: {}
};

Footer.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  orderCanceled: PropTypes.bool,
  orderReturned: PropTypes.bool,
  handleClick: PropTypes.func,
  handleUndoStatus: PropTypes.func,
  nextStep: PropTypes.string.isRequired,
  order: PropTypes.objectOf(PropTypes.any)
};

export default Footer;
