import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Select, Button, Form } from 'antd';
import PropTypes from 'prop-types';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
import ModalProductsSort from '@/src/components/Products/ModalCSV/index';
import ModalHubSkus from '@/src/components/Products/ModalHubSkus';
import ModalBuyAndEarn from '@/src/components/Products/ModalBuyAndEarn';

import {
  setPagination,
  exportCSV
} from '@/src/store/modules/products-all/slice';
import { getDepartments } from '@/src/store/modules/departments/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getHubs } from '@/src/store/modules/hubs/slice';
import { getManufacturers } from '@/src/store/modules/manufacturers/slice';
import { getAttributes } from '@/src/store/modules/attributes/slice';
import { getUpdateProduct } from '@/src/store/modules/product-details/slice';

import useProductsFilter from '@/src/hooks/useProductsFilter';
import FilterMenu from '@/src/pages/Filters/index';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const { OptGroup, Option } = Select;
export const ProductsPage = ({ form }) => {
  const { getFieldDecorator, setFieldsValue, resetFields } = form;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    hubFilter,
    // setHubFilter,
    handleProductsFilter,
    getProductsHandler
  } = useProductsFilter();
  const [tableData, setTableData] = useState([]);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [showSortModal, setShowSortModal] = useState(false);
  const [showHubSkusModal, setShowHubSkusModal] = useState(false);
  const [showBuyAndEarn, setShowBuyAndEarn] = useState(false);

  const { count, productsList, query, isLoading } = useSelector(
    state => state.allProducts
  );
  const { categories } = useSelector(state => state.categories);
  const { departments } = useSelector(state => state.departments);
  const { manufacturers } = useSelector(state => state.manufacturers);
  const { hubs } = useSelector(state => state.hubs);

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const generalQuery = {
    query: {
      sort: '',
      page: 1,
      perPage: 500,
      search: ''
    }
  };

  useEffect(() => {
    if (query.filters) {
      const queryObj = {
        filters: { ...query.filters }
      };
      Object.keys(query.filters).forEach(key => {
        if (
          key === 'by_hub' ||
          key === 'active' ||
          key === 'not_active' ||
          key === 'by_manufacturer' ||
          key === 'by_category'
        ) {
          delete queryObj.filters[key];
        }
      });
      setFilteredQuery(queryObj);
    }
  }, [query]);

  useEffect(() => {
    const data = productsList?.map(item => ({
      ...item,
      hubsSelected: hubFilter,
      changeVisibility: value => {
        dispatch(getUpdateProduct(value));
      },
      editPermission: catalogPermission
    }));
    setTableData(data);
    // eslint-disable-next-line
  }, [dispatch, productsList, hubFilter, getProductsHandler, history]);

  // useEffect(() => {
  //   const queries = new URLSearchParams(history.location.search);
  //   [...queries.entries()].map(pair => {
  //     if (pair[0].indexOf('by_hub') >= 0) console.log(hubFilter, 'hubFilter');
  //     setHubFilter([...hubFilter, parseInt(pair[1], 10)]);

  //     return false;
  //   });
  //   // eslint-disable-next-line
  // }, [history]);

  useEffect(() => {
    dispatch(getCategories(generalQuery));
    dispatch(getDepartments(generalQuery));
    dispatch(getManufacturers(generalQuery));
    dispatch(getHubs(generalQuery));
    dispatch(getAttributes(generalQuery));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getProductsHandler({ query: params });
  }, [getProductsHandler, history]);

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination({ ...payload }));
    },
    [dispatch]
  );

  const renderDropdownItens = (type, items, Grouplabel) => {
    // const isCategory = type === 'categorias';

    const unsorted = items?.length > 0 ? [...items] : [];
    const sorted = unsorted.sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name)
    );

    const structure = sorted.map(item => {
      const { name, internal_id } = item.attributes;
      const id = type === 'hubs' ? internal_id : item.attributes.id;
      // const label = isCategory
      //   ? `${name} para ${department_name.toLowerCase()}`
      //   : name;

      return (
        <Option key={id} value={id}>
          {name}
        </Option>
      );
    });

    if (type === 'categorias') {
      return <OptGroup label={Grouplabel}>{structure}</OptGroup>;
    }

    return structure;
  };

  useEffect(() => {
    if (query?.filters?.active) {
      setFieldsValue({ status: 'active' });
    } else if (query?.filters?.not_active) {
      setFieldsValue({ status: 'disabled' });
    } else {
      resetFields('status');
    }
    if (query?.filters?.by_manufacturer) {
      setFieldsValue({ manufacturer: Number(query?.filters?.by_manufacturer) });
    } else {
      resetFields('manufacturer');
    }
    if (query?.filters?.by_category) {
      setFieldsValue({ category: Number(query?.filters?.by_category) });
    } else {
      resetFields('category');
    }
  }, [query, setFieldsValue, resetFields]);

  const handleExportCSV = () => {
    const params = buildQueryFromUrl(history);
    dispatch(exportCSV({ query: params }));
  };

  return (
    <>
      <ModalProductsSort
        showModal={showSortModal}
        setShowModal={setShowSortModal}
      />
      <ModalHubSkus
        visible={showHubSkusModal}
        setVisible={setShowHubSkusModal}
      />
      <ModalBuyAndEarn
        visible={showBuyAndEarn}
        setVisible={setShowBuyAndEarn}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1 className="mb-20">Produtos</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <div className="filters_search">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              marginBottom: 16
            }}
          >
            <Searchbar
              placeholder="Buscar produto por nome"
              populateResult={getProductsHandler}
              query={query}
              value={query.search}
            />
            {catalogPermission && (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button
                  onClick={handleExportCSV}
                  size="large"
                  className="ant-btn-secondary"
                  style={{ padding: '0 8px' }}
                >
                  exportar csv
                </Button>
                <Button
                  onClick={() => setShowSortModal(true)}
                  size="large"
                  className="ant-btn-secondary"
                  style={{ padding: '0 8px' }}
                >
                  ordenar por planilha
                </Button>
                <Button
                  onClick={() => setShowHubSkusModal(true)}
                  size="large"
                  className="ant-btn-secondary"
                  style={{ padding: '0 8px' }}
                >
                  estoque por hub
                </Button>
                <Button
                  onClick={() => setShowBuyAndEarn(true)}
                  size="large"
                  className="ant-btn-secondary"
                  style={{ padding: '0 8px' }}
                >
                  compre e ganhe
                </Button>
              </div>
            )}
          </div>
          <Form style={{ display: 'flex', gap: 8 }}>
            <Row gutter={8} style={{ flexGrow: 1 }}>
              <Col span={6}>
                <Select
                  size="large"
                  placeholder="Hubs"
                  mode="multiple"
                  value={hubFilter}
                  onChange={value => handleProductsFilter('hubs', value)}
                  filterOption={handleSelectSearch}
                >
                  {renderDropdownItens('hubs', hubs)}
                </Select>
              </Col>
              <Col span={6}>
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator('status')(
                    <Select
                      size="large"
                      placeholder="Status"
                      onChange={value => handleProductsFilter('status', value)}
                      style={{ width: '100%' }}
                    >
                      <Option value="">Todos os Status</Option>
                      <Option value="active">Ativos</Option>
                      <Option value="disabled">Inativos</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator('manufacturer')(
                    <Select
                      size="large"
                      placeholder="Marca"
                      onChange={value =>
                        handleProductsFilter('manufacturer', value)
                      }
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={handleSelectSearch}
                    >
                      <Option value="">Todas as Marcas</Option>
                      {renderDropdownItens('marcas', manufacturers)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator('category')(
                    <Select
                      size="large"
                      placeholder="Categoria"
                      onChange={value =>
                        handleProductsFilter('category', value)
                      }
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={handleSelectSearch}
                    >
                      <Option value="">Todas as categorias</Option>
                      {departments.map(item => {
                        const categoriesArr = categories
                          .filter(
                            obj =>
                              obj.attributes.department_id === Number(item.id)
                          )
                          .sort((a, b) =>
                            a.attributes.name.localeCompare(b.attributes.name)
                          );
                        return (
                          <OptGroup
                            label={item.attributes.name}
                            key={`department-${item.id}`}
                          >
                            {categoriesArr?.map(obj => {
                              return (
                                <Option
                                  key={obj.attributes.id}
                                  value={obj.attributes.id}
                                >
                                  {obj.attributes.name} para{' '}
                                  {item.attributes.name}
                                </Option>
                              );
                            })}
                          </OptGroup>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <div>
              <FilterMenu filterContent="products" />
            </div>
          </Form>
          {filteredQuery.filters && (
            <FilteredTags
              query={filteredQuery}
              populateTable={getProductsHandler}
              setSelectedFilters={() => true}
              noMargin
            />
          )}
        </div>
        <Tables
          data={tableData}
          className="even-gray"
          count={count}
          visiblePaginationUp
          visiblePaginationDown
          contentToShow="products"
          history={history}
          query={query}
          isLoading={isLoading}
          setPaginationState={setPaginationHandler}
          populateTables={getProductsHandler}
        />
      </div>
    </>
  );
};

ProductsPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    setFieldsValue: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired
};

const ProductsPageForm = Form.create({ name: 'ProductsPage' })(ProductsPage);

export default ProductsPageForm;
