import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Modal,
  Row,
  Col,
  Select,
  Form,
  Divider,
  Button,
  Icon
} from 'antd';
import build from 'redux-object';

import { getCities, getCreateCity } from '@/src/store/modules/cities/slice';
import { getCreateDistrict } from '@/src/store/modules/districts/slice';

const { Option } = Select;

const citiesQuery = {
  perPage: 1000,
  include: 'districts,districts.hub',
  fields: [
    { key: 'cities', value: 'name,id' },
    { key: 'districts', value: 'name,id,hub' },
    { key: 'hubs', value: 'name,id' }
  ],
  sort: 'name'
};

const ModalNewCityOrDistrict = props => {
  const { content, setContent, form, formValues, statesList, newHub } = props;

  let title;
  let subtitle;

  switch (content) {
    case 'city':
      title = 'Nova Cidade';
      subtitle =
        'Insira o nome da nova cidade e selecione o estado a que ela pertence.';
      break;
    case 'district':
      title = 'Novo Bairro';
      subtitle =
        'Insira o nome do novo bairro e selecione o estado e cidade a que ele pertence.';
      break;
    default:
      title = '';
  }

  const newCity = content === 'city';

  const [citiesList, setCitiesList] = useState([]);

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const citiesModalList = useSelector(state => state.cities.modalList);

  useEffect(() => {
    if (formValues.state) {
      dispatch(
        getCities({
          query: {
            ...citiesQuery,
            filters: [
              {
                key: 'state_id',
                type: 'eq',
                value: formValues.state
              }
            ]
          },
          modal: true
        })
      );
    }
    // eslint-disable-next-line
  }, [content]);

  useEffect(() => {
    const citiesArr = (
      (citiesModalList.meta && citiesModalList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesModalList, 'cities', object.id, { ignoreLinks: true })
    );
    setCitiesList(citiesArr);
  }, [citiesModalList]);

  const handleCloseModal = () => {
    setContent('');
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) return;
      const relationships = newCity
        ? {
            state: {
              data: {
                type: 'states',
                id: values.state,
                method: 'update'
              }
            }
          }
        : {
            city: {
              data: {
                type: 'cities',
                id: values.city,
                method: 'update'
              }
            }
          };
      const payload = {
        data: {
          type: newCity ? 'cities' : 'districts',
          attributes: {
            name: values.name
          },
          relationships
        }
      };
      if (newCity) {
        dispatch(getCreateCity(payload));
      } else {
        dispatch(getCreateDistrict(payload));
      }
      handleCloseModal();
    });
  };

  const handleAddCity = () => {
    form.setFieldsValue({
      name: '',
      city: undefined
    });
    setContent('city');
  };

  const handleSelectState = value => {
    form.setFieldsValue({
      city: undefined
    });
    dispatch(
      getCities({
        query: {
          ...citiesQuery,
          filters: [
            {
              key: 'state_id',
              type: 'eq',
              value
            }
          ]
        },
        modal: true
      })
    );
  };

  return (
    <Modal
      title={<h2>{title}</h2>}
      okText="Salvar"
      visible={content !== ''}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      destroyOnClose
      bodyStyle={newHub ? { height: 365 } : {}}
    >
      <Form>
        <p style={{ marginBottom: 32 }}>
          {subtitle}
          {/* {secondModal &&
            ' Ao terminar, você retornará ao formulário anterior para finalizar o cadastro. Os campos já preenchidos estão guardados.'} */}
        </p>
        <Row>
          <Col span={24}>
            <h3>{title.split(' ')[1]}</h3>
            <Form.Item>
              {form.getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, preencha este campo'
                  }
                ]
              })(<Input placeholder="Escreva o nome" ref={inputRef} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <h3>Estado</h3>
            <Form.Item>
              {form.getFieldDecorator('state', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, preencha este campo'
                  }
                ],
                initialValue: formValues?.state || undefined
              })(
                <Select placeholder="Selecione" onChange={handleSelectState}>
                  {statesList.map(item => (
                    <Option key={item.code} value={item.id}>
                      {item.code}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          {!newCity && (
            <Col span={12}>
              <h3>Cidade</h3>
              <Form.Item>
                {form.getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ],
                  initialValue:
                    formValues.state === form.getFieldValue('state')
                      ? formValues.city
                      : undefined
                })(
                  <Select
                    placeholder="Selecione"
                    disabled={!form.getFieldValue('state')}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '0' }} />
                        <Button
                          className="ant-btn-select"
                          onMouseDown={e => e.preventDefault()}
                          onClick={handleAddCity}
                        >
                          <Icon type="plus-circle" /> Cadastrar Cidade
                        </Button>
                      </div>
                    )}
                  >
                    {citiesList.map(item => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
ModalNewCityOrDistrict.defaultProps = {
  newHub: false
};

ModalNewCityOrDistrict.propTypes = {
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  statesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  newHub: PropTypes.bool,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const ModalNewCityOrDistrictForm = Form.create({ name: 'new-hub-form' })(
  ModalNewCityOrDistrict
);

export default ModalNewCityOrDistrictForm;
