import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Collapse, Skeleton } from 'antd';

import ListTile from '@/src/components/Expedition/ListTile';
import Timeline from '@/src/components/Order/Timeline/Timeline';
// import CollapseItem from '@/src/components/Expedition/Drawer/CollapseItem';
import CustomDrawer from '@/src/components/CustomDrawer';

import formatCurrency from '@/src/utils/formatCurrency';
import formatParityText from '@/src/utils/formatParityText';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import {
  commentOrderTimeline,
  setOrderTimeline
} from '@/src/store/modules/timeline-events/slice';

// import { getOrderReshipments } from '@/src/store/modules/expedition/slice';

import styles from './Drawer.module.scss';

const { Panel } = Collapse;

const Drawer = ({ visible, onClose, order }) => {
  const dispatch = useDispatch();
  const [returnedItems, setReturnedItems] = useState([]);
  const { orderItems } = order;
  const items = orderItems?.map(item => {
    return {
      ...item,
      status: order.status
    };
  });
  const { editPermission } = usePermissions();

  const { contentLoading } = useSelector(state => state.expedition);
  const { orderTimeline, isLoading } = useSelector(state => state.timeline);
  const { account } = useSelector(state => state.account);

  useEffect(() => {
    const hasReturn = order.orderItems?.filter(
      item => item.returnedQuantity > 0
    );
    if (hasReturn?.length > 0) setReturnedItems(hasReturn);
    // if (order.number) dispatch(getOrderReshipments(order.number));
    // eslint-disable-next-line
  }, [order]);

  const commentHandler = commmentText => {
    const payload = {
      data: {
        attributes: {
          description: commmentText,
          order_id: Number(order?.id)
        },
        type: 'comments'
      }
    };
    dispatch(commentOrderTimeline(payload));
  };

  const [orderTrackings, setOrderTrackings] = useState([]);

  useEffect(() => {
    if (order?.trackings) {
      const trackingData = order.trackings.map(item => {
        let value = item.summary || item.event;
        if (item.event === 'comment') value = item.description;
        return {
          attributes: {
            event: item.description.includes('order.')
              ? item.description
              : item.event,
            id: item.id,
            value,
            agent_type: null,
            agent_name: item.user,
            agent_id: null,
            updated_at: item.updatedAt,
            created_at: item.createdAt,
            object_type: 'Order',
            object_id: item.id
          }
        };
      });
      setOrderTrackings(trackingData);
    }
  }, [order]);

  useEffect(() => {
    if (orderTrackings.length > 0) {
      dispatch(setOrderTimeline(orderTrackings));
    }
  }, [orderTrackings, dispatch]);

  return (
    <CustomDrawer
      visible={visible}
      title={order.number ? `Pedido #${order.number}` : 'Pedido'}
      data={order}
      onClose={onClose}
      headerTable="expedition"
      hasAlert={returnedItems.length > 0 || order.hasAlert}
      loading={contentLoading}
    >
      <CustomDrawer.Box>
        <Skeleton
          active
          title={false}
          loading={contentLoading}
          paragraph={{ rows: 3, width: [180, 180, 180] }}
        >
          {order.status !== 'waiting' && (
            <ListTile typeContent="user" orderData={order} />
          )}
          <ListTile
            typeContent="customer"
            orderData={order}
            hasOrdersCount={order.customerTodayOrdersCount > 1}
          />
          <ListTile typeContent="address" orderData={order} />
          <ListTile typeContent="nfe" orderData={order} />
          <ListTile typeContent="comment" orderData={order} />
          {order.scheduled && (
            <ListTile typeContent="scheduled" orderData={order} />
          )}
        </Skeleton>
      </CustomDrawer.Box>
      <CustomDrawer.Table
        data={items}
        columns="orderItems"
        className="even-gray"
        loading={contentLoading}
      />
      <div className={styles['Drawer__table--summary']}>
        <Row className={styles['Drawer__table--row']}>
          <Col>Subtotal:</Col>
          <Col>
            {contentLoading ? '' : formatCurrency(order.subtotal, true)}
          </Col>
        </Row>
        {/* <Row className={styles['Drawer__table--row']}>
          <Col>Crédito:</Col>
          <Col>
            {contentLoading ? '' : formatCurrency(order.walletDiscount, true)}
          </Col>
        </Row>
        <Row className={styles['Drawer__table--row']}>
          <Col>Cupom:</Col>
          <Col>{contentLoading ? '' : formatCurrency(0, true)}</Col>
        </Row> */}
        <Row className={styles['Drawer__table--row']}>
          <Col>Desconto:</Col>
          <Col>
            {contentLoading ? '' : formatCurrency(order.discount, true)}
          </Col>
        </Row>
        <Row className={styles['Drawer__table--row']}>
          <Col>Frete:</Col>
          <Col>
            {contentLoading ? '' : formatCurrency(order.shipping, true)}
          </Col>
        </Row>
        <Row className={styles['Drawer__table--row']}>
          <Col>Taxa de serviço:</Col>
          <Col>
            {contentLoading ? '' : formatCurrency(order.serviceFee, true)}
          </Col>
        </Row>
        <Row className={`${styles['Drawer__table--row']} text-bold`}>
          <Col>Total:</Col>
          <Col>{contentLoading ? '' : formatCurrency(order.total, true)}</Col>
        </Row>
      </div>
      {returnedItems.length > 0 || order?.orderInvoices?.length > 0 ? (
        <Collapse
          ghost
          defaultActiveKey={['1']}
          expandIconPosition="right"
          bordered={false}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #EBEBEB',
            padding: '0',
            marginBottom: '24px',
            boxShadow: 'none'
          }}
        >
          <Panel
            ghost
            key="1"
            header="Devoluções"
            extra={formatParityText(returnedItems.length, 'produto')}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
              margin: '0',
              boxShadow: 'none'
            }}
          >
            {returnedItems?.map(item => (
              <div
                key={`${item.skuExternalId || item.skuCode}-${
                  item.returnedQuantity
                }`}
              >
                <CustomDrawer.Table
                  data={[item]}
                  columns="returnedItems"
                  className="no-header"
                  loading={contentLoading}
                />
              </div>
            ))}
            {order.orderInvoices.length > 0 && (
              <div style={{ marginTop: 10 }}>
                <span style={{ margin: '8px 0', display: 'block' }}>
                  Notas de devolução:{' '}
                </span>
                {order.orderInvoices?.map(item => {
                  const orderItem = {
                    status: order.status,
                    saleInvoiceNumber: item.number,
                    saleInvoicePdf: item.pdfUrl
                  };
                  return (
                    <ListTile
                      typeContent="nfe"
                      orderData={orderItem}
                      key={item.id}
                    />
                  );
                })}
              </div>
            )}
          </Panel>
        </Collapse>
      ) : (
        <CustomDrawer.Box>
          <span style={{ marginLeft: 8 }} className="text-bold">
            Não há devoluções
          </span>
        </CustomDrawer.Box>
      )}
      {/* {reshipments.length > 0 ? (
        <Collapse
          ghost
          defaultActiveKey={['1']}
          expandIconPosition="right"
          bordered={false}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #EBEBEB',
            padding: '0',
            marginBottom: '24px',
            boxShadow: 'none'
          }}
        >
          <Panel
            ghost
            key="1"
            header="Reenvios"
            extra={formatParityText(reshipments.length, 'reenvio')}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
              margin: '0',
              boxShadow: 'none'
            }}
          >
            <CustomDrawer.Table
              data={reshipments}
              columns="orderReshipments"
              loading={contentLoading} // adicionar um específico?
            />
          </Panel>
        </Collapse>
      ) : (
        <CustomDrawer.Box>
          <span style={{ marginLeft: 8 }} className="text-bold">
            Não há reenvios
          </span>
        </CustomDrawer.Box>
      )} */}
      {order.id && (
        <Timeline
          order={order}
          orderId={order?.id}
          commentHandlerTimeline={commentHandler}
          timelineEvents={orderTimeline}
          isLoading={isLoading}
          dispatchModule
          editPermission={
            account.kind === 'tv'
              ? !editPermission(modules.DISPATCH_ORDERS_VIEW)
              : true
          }
        />
      )}
    </CustomDrawer>
  );
};

Drawer.defaultProps = {
  order: {}
};

Drawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any)
};

export default Drawer;
