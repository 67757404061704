import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import Tags from '@/src/components/Tags/index';

const CustomersTags = props => {
  const { removeTags, addTags, tags, editPermission } = props;

  return (
    <Card className="no-shadow" title={<h2>Tags</h2>}>
      <Tags
        removeTags={removeTags}
        addTags={addTags}
        tags={tags}
        newTag={editPermission}
        closable={editPermission}
      />
    </Card>
  );
};

CustomersTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  removeTags: PropTypes.func.isRequired,
  addTags: PropTypes.func.isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default CustomersTags;
