import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import HubsSuspension from '@/src/components/Hubs/HubsSuspension';
import EstimatedTime from '@/src/components/Hubs/EstimatedTime';
import Zipcodes from '@/src/components/Hubs/Regions/Zipcodes';
// import Regions from '@/src/components/Hubs/Regions';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const { TabPane } = Tabs;

const CoverageMain = ({ hubData }) => {
  const [activeTab, setActiveTab] = useState('regioes');

  const { editPermission } = usePermissions();
  const hubsPermission = editPermission(modules.DISPATCH_HUBS);

  return (
    <Tabs
      defaultActiveKey="regioes"
      onChange={key => setActiveTab(key)}
      style={{ paddingTop: 4, borderTop: '1px solid #ebebeb' }}
      type="card"
    >
      <TabPane key="regioes" tab="Regiões">
        {activeTab === 'regioes' && (
          // <Regions hubData={hubData} editPermission={hubsPermission} />
          <Zipcodes hubData={hubData} editPermission={hubsPermission} />
        )}
      </TabPane>
      <TabPane key="eta" tab="ETA">
        {activeTab === 'eta' && (
          <EstimatedTime editPermission={hubsPermission} />
        )}
      </TabPane>
      <TabPane key="suspensao" tab="Suspensão">
        {activeTab === 'suspensao' && (
          <HubsSuspension hubData={hubData} editPermission={hubsPermission} />
        )}
      </TabPane>
    </Tabs>
  );
};

CoverageMain.defaultProps = {
  hubData: {}
};

CoverageMain.propTypes = {
  hubData: PropTypes.objectOf(PropTypes.any)
};

export default CoverageMain;
