import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import bugsnag from '@/src/services/bugsnag';

import {
  getRecoverPassword,
  getResetPassword,
  setAccountEmail,
  setErrMessage,
  setLoading
} from '@/src/store/modules/forgot-password/slice';

import { newPassword } from '@/src/store/modules/login/slice';

import {
  recoverPassword as recoverPasswordRequest,
  resetPassword as resetPasswordRequest
} from '@/src/api/users';

import { successAlert } from '@/src/utils/notifications';

function* recoverPasswordAccountSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(recoverPasswordRequest, payload);
    if (window.location.pathname.includes('usuarios')) {
      successAlert('E-mail de alteração de senha enviado');
    } else {
      yield put(setAccountEmail(payload.data.attributes.email));
    }
    yield put(setErrMessage(''));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    yield put(setAccountEmail(''));
    yield put(
      setErrMessage(
        err.response.data.errors ? err.response.data.errors[0].title : ''
      )
    );
    bugsnag.notify(err);
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(resetPasswordRequest, payload);
    yield put(setErrMessage(''));
    yield put(setAccountEmail(''));
    yield put(setLoading(false));
    yield put(newPassword(true));
    yield put(push('/'));
  } catch (err) {
    // console.log(err.response);
    yield put(setLoading(false));
    yield put(setErrMessage(err.response.statusText));
    bugsnag.notify(err);
  }
}

function* forgotPasswordSaga() {
  yield takeEvery(getRecoverPassword, recoverPasswordAccountSaga);
  yield takeEvery(getResetPassword, resetPasswordSaga);
}

export default forgotPasswordSaga;
