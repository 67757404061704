import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  useLoadScript
} from '@react-google-maps/api';
import delivererMarker from '@/src/assets/images/order/pin-zee-man.png';
import hubMarker from '@/src/assets/images/order/pin-hub.png';
import customerMarker from '@/src/assets/images/order/pin-cliente.png';
import app, { appLogin } from '@/src/services/firebase';
import svgToBase64 from '@/src/utils/svgToBase64';
import svg from '@/src/components/Order/otherOrdersMarker';
import { setETA, setOrdersETA } from '@/src/store/modules/order-details/slice';
import mapStyles from './mapStyles';

const OrderMap = props => {
  const { orderAttributes } = props;
  // console.log(orderAttributes);

  const dispatch = useDispatch();

  const setETAHandler = useCallback(
    payload => {
      dispatch(setETA(payload));
    },
    [dispatch]
  );

  const setOrdersETAHandler = useCallback(
    payload => {
      dispatch(setOrdersETA(payload));
    },
    [dispatch]
  );

  const [response, setResponse] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [delivererPosition, setDelivererPosition] = useState({});
  const hubLat = Number(orderAttributes.hub_latitude);
  const hubLng = Number(orderAttributes.hub_longitude);
  const customerLat = Number(orderAttributes.address_latitude);
  const customerLng = Number(orderAttributes.address_longitude);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    language: 'pt-br',
    preventGoogleFontsLoading: true
  });

  const otherOrdersMarker = index => svgToBase64(svg(index));

  const orderTrip = useSelector(state => state.order.orderTrip);
  // console.log(orderTrip);

  useEffect(() => {
    appLogin();
  }, []);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setCenter({
        lat: customerLat || 0,
        lng: customerLng || 0
      });
    }
  }, [mapInstance, customerLat, customerLng]);

  const getWaypoints = useCallback(
    (all = true) => {
      let filteredOrders;
      if (all) {
        // console.log(orderTrip);
        filteredOrders =
          orderTrip?.deliverer?.deliveriesInProgress
            .filter(delivery => delivery.orderNumber !== orderAttributes.number)
            .filter(delivery => delivery.status !== 'delivered')
            .map(delivery => {
              const newObj = {
                location: `${delivery.latitude}, ${delivery.longitude}`
              };
              return newObj;
            }) || [];
        return filteredOrders;
      }
      filteredOrders =
        orderTrip?.deliverer?.deliveriesInProgress
          .filter(delivery => delivery.orderNumber !== orderAttributes.number)
          .map(delivery => {
            const newObj = {
              location: `${delivery.latitude}, ${delivery.longitude}`
            };
            return newObj;
          }) || [];
      return filteredOrders;
    },
    [orderTrip, orderAttributes.number]
  );

  const getETA = useCallback(
    legsObj => {
      const totalDuration = legsObj.reduce((acc, leg) => {
        const duration = leg.duration.value;
        return acc + duration;
      }, 0);
      const ordersETA = legsObj.map((leg, index) => {
        let duration = leg.duration.value;
        if (index !== 0) duration += legsObj[index - 1].duration.value;
        return Math.ceil(duration / 60);
      });
      setETAHandler(`${Math.ceil(totalDuration / 60)}`);
      setOrdersETAHandler(ordersETA);
    },
    [setETAHandler, setOrdersETAHandler]
  );

  const throttledSetResponse = useRef(
    throttle(2500, (responseApi, status) => {
      if (responseApi && status === 'OK') {
        setResponse(responseApi);
        getETA(responseApi.routes[0].legs);
      }
    })
  ).current;

  const directionsCallback = useCallback(
    (responseApi, status) => {
      throttledSetResponse(responseApi, status);
    },
    [throttledSetResponse]
  );

  useEffect(() => {
    return () => throttledSetResponse.cancel();
  }, [throttledSetResponse]);

  const buildDelivererTrack = useCallback(() => {
    if (
      (orderAttributes.status === 'delivering' ||
        orderAttributes.status === 'delivered') &&
      delivererPosition.longitude &&
      delivererPosition.latitude &&
      customerLat &&
      customerLng &&
      hubLat &&
      hubLng
    ) {
      return (
        <>
          <Marker
            position={{
              lat: delivererPosition.latitude,
              lng: delivererPosition.longitude
            }}
            icon={delivererMarker}
          />
          {getWaypoints(false).map((order, index) => {
            return (
              <Marker
                key={index} //eslint-disable-line
                position={{
                  lat: Number(order.location.split(',')[0]),
                  lng: Number(order.location.split(',')[1])
                }}
                icon={otherOrdersMarker(index + 1)}
              />
            );
          })}
          <DirectionsService
            options={{
              destination: `${customerLat},${customerLng}`,
              origin: `${delivererPosition.latitude},${delivererPosition.longitude}`,
              travelMode: 'DRIVING',
              waypoints: getWaypoints()
            }}
            callback={directionsCallback}
          />
          {response && (
            <DirectionsRenderer
              options={{
                directions: response,
                suppressInfoWindows: true,
                polylineOptions: {
                  strokeColor: '#33d6ef',
                  strokeWeight: 6
                },
                suppressMarkers: true,
                preserveViewport: true
              }}
            />
          )}
        </>
      );
    }
    return '';
  }, [
    directionsCallback,
    orderAttributes.status,
    delivererPosition.longitude,
    delivererPosition.latitude,
    customerLng,
    customerLat,
    hubLng,
    hubLat,
    getWaypoints,
    response
  ]);

  const buildDeliveredMap = () => {
    if (
      orderAttributes.status === 'delivered' &&
      customerLat &&
      customerLng &&
      hubLat &&
      hubLng
    ) {
      // linha 222 a 247 para enquadrar mapa durante testes
      // let zoomToAllMarkers = true;
      // if (zoomToAllMarkers) {
      //   const updatedMapMarkers = [
      //     {
      //       id: 'customer',
      //       pos: {
      //         lat: customerLat,
      //         lng: customerLng
      //       }
      //     },
      //     {
      //       id: 'hub',
      //       pos: {
      //         lat: hubLat,
      //         lng: hubLng
      //       }
      //     }
      //   ];
      //   const bounds = new window.google.maps.LatLngBounds();
      //   updatedMapMarkers.map(place => {
      //     bounds.extend(place.pos);
      //     return place.id;
      //   });
      //   mapInstance.fitBounds(bounds);
      //   zoomToAllMarkers = false;
      // }
      return (
        <>
          {getWaypoints(false).map((order, index) => {
            return (
              <Marker
                key={order.location}
                position={{
                  lat: Number(order.location.split(',')[0]),
                  lng: Number(order.location.split(',')[1])
                }}
                icon={otherOrdersMarker(index + 1)}
              />
            );
          })}
        </>
      );
    }
    return '';
  };

  useEffect(() => {
    const ref = app
      .database()
      .ref(`deliverers/${orderAttributes.deliverer_id}`);
    // .ref(`deliverers/1372`);
    let zoomToAllMarkers = true;
    ref.on('value', snapshot => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        setDelivererPosition(val);
        if (zoomToAllMarkers && val.latitude && val.longitude) {
          const updatedMapMarkers = [
            {
              id: 'customer',
              pos: {
                lat: customerLat,
                lng: customerLng
              }
            },
            {
              id: 'hub',
              pos: {
                lat: hubLat,
                lng: hubLng
              }
            },
            {
              id: 'deliverer',
              pos: {
                lat: val.latitude,
                lng: val.longitude
              }
            }
          ];
          const bounds = new window.google.maps.LatLngBounds();
          updatedMapMarkers.map(place => {
            bounds.extend(place.pos);
            return place.id;
          });
          mapInstance.fitBounds(bounds);
          zoomToAllMarkers = false;
        }
      }
    });
    return () => {
      ref.off();
    };
  }, [
    orderAttributes.deliverer_id,
    mapInstance,
    customerLat,
    customerLng,
    hubLat,
    hubLng
  ]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (isLoaded) {
    return (
      <>
        <GoogleMap
          id="order-map"
          mapContainerStyle={{
            height: '400px',
            width: '70%',
            borderRadius: '8px 0 0 8px'
          }}
          zoom={16}
          options={mapStyles}
          onLoad={map => setMapInstance(map)}
        >
          <Marker
            position={{
              lat: customerLat,
              lng: customerLng
            }}
            icon={customerMarker}
          />
          <Marker
            position={{
              lat: hubLat,
              lng: hubLng
            }}
            icon={hubMarker}
          />

          {buildDelivererTrack()}
          {buildDeliveredMap()}
        </GoogleMap>
      </>
    );
  }

  return 'waiting';
};

OrderMap.propTypes = {
  orderAttributes: PropTypes.shape({
    hub_latitude: PropTypes.string,
    hub_longitude: PropTypes.string,
    address_latitude: PropTypes.string,
    address_longitude: PropTypes.string,
    status: PropTypes.string,
    id: PropTypes.number,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deliverer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default React.memo(OrderMap);
