import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form, Row, Select, Spin } from 'antd';
import { debounce } from 'throttle-debounce';

import { getProducts } from '@/src/store/modules/products/slice';
import {
  getSelectProduct,
  createGift,
  updateGift
} from '@/src/store/modules/product-details/slice';

const { Option } = Select;

const GiftModal = ({
  productName,
  skus,
  showModal,
  setShowModal,
  editModal,
  setEditModal,
  form
}) => {
  const dispatch = useDispatch();

  const { products, isLoading } = useSelector(state => state.products);
  const { selectProduct } = useSelector(state => state.productDetails);

  const [giftSkus, setGiftSkus] = useState([]);

  const delayedProductsQuery = useRef(
    debounce(300, value =>
      dispatch(getProducts({ query: { search: value, perPage: 100 } }))
    )
  ).current;

  const handleProductSelect = value => {
    if (value) dispatch(getSelectProduct(value.key || value));
    if (giftSkus.length) {
      form.setFieldsValue({
        gift_id: undefined
      });
    }
  };

  useEffect(() => {
    if (editModal.id) {
      const {
        requirementable_id,
        requirementable_name,
        gift_product_id,
        gift_product_name,
        gift_id,
        gift_name
      } = editModal;
      dispatch(getProducts({ query: { search: gift_product_name } }));
      form.setFieldsValue({
        requirementable_id: {
          key: requirementable_id.toString(),
          label: requirementable_name
        },
        gift_product_id: {
          key: gift_product_id.toString(),
          label: gift_product_name
        },
        gift_id: {
          key: gift_id.toString(),
          label: gift_name
        }
      });
    }
    // eslint-disable-next-line
  }, [editModal]);

  useEffect(() => {
    if (editModal.id && !giftSkus.length) {
      handleProductSelect(editModal.gift_product_id);
    }
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    setGiftSkus(selectProduct?.skus || []);
  }, [selectProduct]);

  const handleProductSearch = value => {
    if (value) delayedProductsQuery(value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditModal({});
    setGiftSkus([]);
    form.setFieldsValue({
      requirementable_id: undefined,
      gift_product_id: undefined,
      gift_id: undefined
    });
  };

  const handleModalOk = () => {
    form.validateFields((err, values) => {
      if (err) return;
      const { requirementable_id, gift_id } = values;
      const payload = {
        requirementable_id: Number(requirementable_id.key),
        requirementable_type: 'Sku',
        gift_id: Number(gift_id.key)
      };
      if (editModal.id) {
        payload.id = editModal.id;
        dispatch(updateGift(payload));
      } else {
        dispatch(createGift(payload));
      }
      handleCloseModal();
    });
  };

  return (
    <Modal
      title="ADICIONAR"
      okText="Confirmar"
      centered
      className="centered-footer"
      onOk={handleModalOk}
      onCancel={handleCloseModal}
      visible={showModal}
      bodyStyle={{ padding: '16px 24px 36px', height: 320 }}
    >
      <Form>
        <Row>
          <span style={{ marginBottom: 8, display: 'block' }}>
            Selecione o SKU participante
          </span>
          <Form.Item>
            {form.getFieldDecorator('requirementable_id', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, preencha este campo'
                }
              ]
            })(
              <Select labelInValue dropdownMatchSelectWidth={false}>
                {skus
                  ?.filter(
                    sku => !sku.gift || sku.id === editModal.requirementable_id
                  )
                  .map(sku => (
                    <Option key={sku.id}>
                      {productName} - {sku.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Row>
        {(editModal.id || form.getFieldValue('requirementable_id')) && (
          <Row>
            <span style={{ marginBottom: 8, display: 'block' }}>
              Selecione o produto que será enviado para o cliente
            </span>
            <Form.Item>
              {form.getFieldDecorator('gift_product_id', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, preencha este campo'
                  }
                ]
              })(
                <Select
                  labelInValue
                  showSearch
                  placeholder="Buscar produto"
                  notFoundContent={isLoading ? <Spin size="small" /> : null}
                  showArrow={false}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  dropdownMatchSelectWidth={false}
                  onSearch={handleProductSearch}
                  onChange={handleProductSelect}
                  loading={isLoading}
                >
                  {products.map(product => (
                    <Option key={product.id}>{product.attributes.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>
        )}
        {(editModal.id || form.getFieldValue('gift_product_id')) && (
          <Row>
            <span style={{ marginBottom: 8, display: 'block' }}>
              Selecione o SKU do produto enviado para o cliente
            </span>
            <Form.Item>
              {form.getFieldDecorator('gift_id', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, preencha este campo'
                  }
                ]
              })(
                <Select
                  labelInValue
                  placeholder="Selecionar sku"
                  dropdownMatchSelectWidth={false}
                  loading={isLoading}
                >
                  {giftSkus.map(item => (
                    <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

GiftModal.propTypes = {
  productName: PropTypes.string.isRequired,
  skus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  editModal: PropTypes.objectOf(PropTypes.any).isRequired,
  setEditModal: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const GiftModalForm = Form.create({ name: 'product-gift-form' })(GiftModal);

export default GiftModalForm;
