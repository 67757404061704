import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './StatusPill.module.scss';

const StatusPill = ({ status, centered, steps }) => {
  const [pill, setPill] = useState({});

  const stepsArr = Array.from({ length: steps }, (_, i) => i + 1);

  const { pathname } = window.location;

  let waitingStatus;
  switch (pathname) {
    case '/expedicao':
      waitingStatus = 'Aguardando coleta';
      break;
    case '/embarques':
      waitingStatus = 'Aguardando saída';
      break;
    case '/entregadores':
      waitingStatus = 'Sem entrega';
      break;
    default:
      waitingStatus = 'Aguardando';
  }

  const content = {
    waiting: {
      text: waitingStatus,
      color: pathname === '/pedidos' ? '#ebebeb' : '#737373',
      step: pathname === '/expedicao' ? 1 : false
    },
    started: {
      text: 'Iniciado',
      color: '#737373',
      step: 2
    },
    picked: { text: 'Coletado', color: '#737373', step: 3 },
    packed: {
      text: 'Empacotado',
      color: '#737373',
      step: 4
    },
    billed: { text: 'Faturado', color: '#737373', step: 5 },
    separated: {
      text: 'Embarque',
      color: '#737373',
      step: 6
    },
    dispatched: { text: 'Em rota', color: '#0a0a0a' },
    delivered: { text: 'Entregue', color: '#50ebc5' },
    canceled: { text: 'Cancelado', color: '#ff365c' },
    'Em preparação': {
      text: 'Em preparação',
      color: 'rgba(120, 80, 185, 0.6)'
    },
    Concluído: { text: 'Concluído', color: '#50EBC5' },
    inactive: { text: 'Inativo', color: '#ff365c' },
    offline: { text: 'Inativo', color: '#ff365c' },
    active: { text: 'Ativo', color: '#50ebc5' },
    integrated: { text: 'Integrado', color: '#B3B3B3' },
    sleeping: { text: 'Dormindo', color: '#DBDBDB' },
    completed: { text: 'Concluído', color: '#737373', steps: 2 },
    default: { text: status, color: '#DBDBDB' },
    return_request: { text: 'Devolução pendente', color: '#af98d6', step: 1 },
    return_request_resolved: {
      text: 'Devolução concluída',
      color: '#af98d6',
      step: 2
    },
    cancelation_request: {
      text: 'Cancelamento pendente',
      color: '#fc97aa',
      step: 1
    },
    cancelation_request_resolved: {
      text: 'Cancelamento concluído',
      color: '#fc97aa',
      step: 2
    },
    processing: {
      text: 'Preparando',
      color: '#737373',
      step: 1
    },
    handled: {
      text: 'Saiu para entrega',
      color: '#737373',
      step: 2
    },
    delivering: {
      text: 'Chegando',
      color: pathname === '/expedicao' ? '#0a0a0a' : '#737373',
      step: pathname === '/expedicao' ? null : 3
    }
  };

  useEffect(() => {
    setPill(content[status] || content.default);
    // eslint-disable-next-line
  }, [status]);

  return (
    <div
      className={styles.wrap}
      style={{ width: centered ? 'auto' : 'fit-content' }}
    >
      <span className={styles.text}>{pill.text}</span>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {pill.step ? (
          stepsArr.map((item, index) => (
            <div
              key={`${status}-step-${index + 1}`}
              className={styles.steps}
              style={{
                margin: '0 1px',
                width: 100 / steps - 2,
                backgroundColor: pill.step < item ? '#ebebeb' : pill.color
              }}
            />
          ))
        ) : (
          <div
            className={`${styles.steps} ${styles.single}`}
            style={{
              margin: '0 1px',
              width: 98,
              backgroundColor: pill.color
            }}
          />
        )}
      </div>
    </div>
  );
};

StatusPill.defaultProps = {
  status: null,
  centered: false,
  steps: 1
};

StatusPill.propTypes = {
  status: PropTypes.string,
  centered: PropTypes.bool,
  steps: PropTypes.number
};

export default StatusPill;
