import { takeEvery, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';

import {
  setPromotions,
  setPromoIsLoading,
  changePromotionInState,
  getPromotions,
  createPromotion,
  archivePromotion,
  unarchivePromotion,
  duplicatePromotion,
  exportCSV
} from '@/src/store/modules/promotions/slice';
import {
  getPromotions as getPromotionsRequest,
  createPromotion as createPromotionRequest,
  archivePromotion as archivePromotionRequest,
  unarchivePromotion as unarchivePromotionRequest,
  duplicatePromotion as duplicatePromotionRequest
} from '@/src/api/promotions';

import { exportCSVPromotions as exportCSVPromotionsRequest } from '@/src/api/export-file';

import {
  getPromotionsQueryUrlState,
  getRouterState,
  getPromotionsInState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getPromotionsSaga(params) {
  const { payload } = params;
  try {
    yield put(setPromoIsLoading(true));
    const response = yield call(getPromotionsRequest, payload);
    // get all tags ids from promotion here
    const ids = [];
    response.data.data.map(data =>
      data.relationships.tags.data.forEach(tags => ids.push(tags.id))
    );
    // get all tags here
    const tags = response.data.included.map(tagId => tagId.attributes);

    yield put(
      setPromotions({
        promotions: response.data.data,
        query: payload.query,
        total: response.data.meta.count,
        promotionsTagsIds: ids,
        tagsPromotions: tags
      })
    );
    const queryUrl = yield select(getPromotionsQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
  } catch (err) {
    errorAlert(err);
  }
}

function* createPromotionSaga(params) {
  const { payload } = params;
  try {
    yield put(setPromoIsLoading(true));
    const response = yield call(createPromotionRequest, payload);

    yield put(push(`/promocoes/${response.data.data.id}`));

    yield notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'A promoção foi criada.'
    });
  } catch (err) {
    yield put(setPromoIsLoading(false));
    errorAlert(err);
  }
}

function* archivePromotionSaga(params) {
  const { payload } = params;
  try {
    const response = yield call(archivePromotionRequest, payload);
    const promotionArchived = response.data.data;
    const promotionsArray = yield select(getPromotionsInState);
    const updatedArray = promotionsArray.map(promotion => {
      if (promotionArchived.id === promotion.id) {
        return promotionArchived;
      }
      return promotion;
    });
    yield put(
      changePromotionInState({
        promotions: updatedArray
      })
    );
    successAlert('A promoção foi arquivada.');
  } catch (err) {
    errorAlert(err);
  }
}

function* unarchivePromotionSaga(params) {
  const { payload } = params;
  try {
    const response = yield call(unarchivePromotionRequest, payload);
    const promotionDearchived = response.data.data;
    const promotionsArray = yield select(getPromotionsInState);
    const updatedArray = promotionsArray.filter(
      promotion => promotionDearchived.id !== promotion.id
    );
    yield put(
      changePromotionInState({
        promotions: updatedArray
      })
    );
    successAlert('A promoção foi desarquivada.');
  } catch (err) {
    errorAlert(err);
  }
}

function* duplicatePromotionSaga(params) {
  const { payload } = params;
  try {
    const response = yield call(duplicatePromotionRequest, payload);
    const promotionDuplicated = response.data.data;
    const promotionsArray = yield select(getPromotionsInState);
    const updatedArray = [promotionDuplicated, ...promotionsArray];
    yield put(
      changePromotionInState({
        promotions: updatedArray
      })
    );
    notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'A promoção foi duplicada.'
    });
  } catch (err) {
    errorAlert(err);
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setPromoIsLoading(true));
    const response = yield call(exportCSVPromotionsRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setPromoIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setPromoIsLoading(false));
}

function* promotionsSaga() {
  yield takeEvery(getPromotions, getPromotionsSaga);
  yield takeEvery(createPromotion, createPromotionSaga);
  yield takeEvery(archivePromotion, archivePromotionSaga);
  yield takeEvery(unarchivePromotion, unarchivePromotionSaga);
  yield takeEvery(duplicatePromotion, duplicatePromotionSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
}

export default promotionsSaga;
