import { translatedFiltersToEnglish } from '@/src/utils/localeData';

const urlBuilderTimeline = (endpoint, filters, id, type) => {
  const orderId = `?object_id=${id}`;
  const orderType = `&object_type=${type}`;
  let url = '';
  filters.forEach(filter => {
    if (filter === 'Notificação push') {
      url += `&filters[by_events][]=push_notification`;
    } else if (filter === 'Chat proativo') {
      url += `&filters[by_events][]=proactive_chat`;
    } else if (filter === 'Chat entregador') {
      url += `&filters[by_events][]=deliverer_chat`;
    } else if (filter === 'Chat zendesk') {
      url += `&filters[by_events][]=zendesk_chat`;
    } else if (filter === 'Status ERP') {
      url += `&filters[by_events][]=erp_status_changed`;
    } else if (filter === 'Alerta ERP') {
      url += `&filters[by_events][]=alert`;
    } else if (filter === 'Email via sistema') {
      url += `&filters[by_events][]=email_sent`;
    } else {
      url += `&filters[by_events][]=${translatedFiltersToEnglish[filter]}`;
    }
  });
  const getUrl = `${endpoint}${orderId}${orderType}${url}`;
  return getUrl;
};

export default urlBuilderTimeline;
