import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

import formatCurrency from '@/src/utils/formatCurrency';

const ReferralDetails = props => {
  const { metadata } = props;

  const {
    count,
    discount_value,
    minimum_order_value,
    reward_value,
    total
  } = metadata;

  return (
    <Card className="no-shadow no-padding">
      <h3
        style={{
          fontSize: '2.3em',
          fontWeight: 'bold',
          margin: '24px',
          lineHeight: 1
        }}
      >
        {formatCurrency(total)}
      </h3>
      <hr style={{ margin: '20px 0' }} />
      <section
        style={{
          margin: '10px 24px',
          marginBottom: 0,
          lineHeight: 1
        }}
      >
        <p>
          <strong>
            {count} Referral{count > 1 ? 's' : ''}
          </strong>
        </p>
        <p>Valor: {formatCurrency(reward_value)}</p>
        <p>Desconto: {formatCurrency(discount_value)}</p>
        <p style={{ margin: 0 }}>
          Valor mínimo de compra: {formatCurrency(minimum_order_value)}
        </p>
      </section>
    </Card>
  );
};

ReferralDetails.defaultProps = {
  metadata: {
    count: 0,
    discount_value: 0,
    minimum_order_value: 0,
    reward_value: 0,
    total: 0
  }
};

ReferralDetails.propTypes = {
  metadata: PropTypes.shape({
    count: PropTypes.number,
    discount_value: PropTypes.number,
    minimum_order_value: PropTypes.number,
    reward_value: PropTypes.number,
    total: PropTypes.number
  })
};

export default ReferralDetails;
