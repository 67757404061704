import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Col, Row, Card, Spin, Menu, Dropdown, Icon, Breadcrumb } from 'antd';

import { getPromotion } from '@/src/store/modules/promotion-details/slice';
import {
  createNewCoupon,
  generateCoupons
} from '@/src/store/modules/coupons/slice';

import { addTag, removeTag } from '@/src/store/modules/tags/slice';

import NewCoupon from '@/src/pages/Coupons/containers/NewCoupon/NewCoupon';
import GenerateCupons from '@/src/pages/Coupons/containers/GenerateCoupons/GenerateCoupons';
import Coupons from '@/src/pages/Coupons';
import PromotionForm from '@/src/pages/Promotions/containers/PromotionForm';
import Tags from '@/src/components/Tags/index';
import Audit from '@/src/components/Audit/index';

import isImmutablePromo from '@/src/utils/isImmutablePromo';

const Promotion = () => {
  const dispatch = useDispatch();

  const [showAudit, setShowAudit] = useState(false);

  const { promotion, isLoading } = useSelector(state => state.promotion);

  const isLoadingCupons = useSelector(state => state.coupons.isLoading);

  const tags = useSelector(state => state.tags.tags);

  const { promoId } = useParams();

  const createCoupon = payload => dispatch(createNewCoupon(payload));

  useEffect(() => {
    dispatch(getPromotion({ promoId, type: 'get' }));
  }, [promoId, dispatch]);

  const generateCouponsHanlder = value => {
    dispatch(generateCoupons({ quantity: value, promoId }));
  };
  const addTagHandler = name =>
    dispatch(
      addTag({
        taggable_type: 'Promotion',
        taggable_id: promoId,
        name
      })
    );

  const removeTagHandler = name =>
    dispatch(
      removeTag({
        taggable_type: 'Promotion',
        taggable_id: promoId,
        name
      })
    );

  const actions = (
    <Menu>
      <Menu.Item key="3" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const userActions = (
    <Dropdown overlay={actions}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={promoId}
        title="PROMOÇÃO"
        type="Promotion"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" align="middle" justify="space-between" gutter={[8, 8]}>
        <Col span={17}>
          <h1>Promoção {promotion.name}</h1>
        </Col>
        <div style={{ display: 'flex' }}>
          <Col>
            <GenerateCupons
              disabled={isImmutablePromo(promotion.promotion_type)}
              generateCouponsSubmit={generateCouponsHanlder}
            />
          </Col>
          <Col>
            <NewCoupon
              disabled={isImmutablePromo(promotion.promotion_type)}
              createNewCoupon={createCoupon}
              promoId={promoId}
              isLoadingCupons={isLoadingCupons}
            />
          </Col>
        </div>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/promocoes">Promoções</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar Promoção</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          padding: '12px',
          background: '#ebebeb'
        }}
      >
        <Row>
          <Card
            className="no-shadow"
            title="DADOS PROMOÇÃO"
            extra={userActions}
          >
            <Spin spinning={isLoading}>
              <PromotionForm editPromo promoId={promoId} />
            </Spin>
          </Card>
          <Card
            className="no-shadow tags-card"
            style={{ margin: '8px 0', display: 'block' }}
            bodyStyle={{ padding: 0 }}
            title={<h2>Tags</h2>}
            extra={
              <Tags
                removeTags={removeTagHandler}
                addTags={addTagHandler}
                tags={tags}
                newTag
                closable
              />
            }
          />
          <Card className="no-shadow no-padding" title="CUPONS">
            <Coupons promotionType={promotion.promotion_type} />
          </Card>
        </Row>
      </div>
    </>
  );
};

export default Promotion;
