import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Upload } from 'antd';

import styles from '@/src/components/UploadImage/UploadImage.module.scss';

const SingleUpload = ({
  uploadImage,
  withoutImage,
  handleBeforeUpload,
  handleUploadChange,
  handleDeleteImage,
  handleImageError,
  isRound,
  isSmall,
  recommendedSize,
  editPermission
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isRound && 'center'
      }}
    >
      {uploadImage?.length ? (
        <>
          <div
            className={
              isRound ? styles['image-upload-content-holder-round'] : ''
            }
          >
            <img
              src={uploadImage}
              alt="products"
              onError={handleImageError}
              className={`${
                isRound
                  ? styles['image-upload-content-round']
                  : styles['image-upload-content']
              } ${isSmall ? styles['image-upload-content-small'] : ''}`}
            />
          </div>
          {editPermission && (
            <Button
              className="base"
              style={{ textAlign: 'initial', marginTop: 8 }}
              type="link"
              onClick={handleDeleteImage}
            >
              <Icon type="delete" />
              Remover imagem
            </Button>
          )}
        </>
      ) : (
        <>
          <div
            className={`${
              isRound
                ? styles['image-upload-default-round']
                : styles['image-upload-default']
            } ${isSmall ? styles['image-upload-small'] : ''}`}
          >
            <Icon
              style={{ fontSize: '50px', opacity: '0.2' }}
              type="camera"
              theme="filled"
            />
          </div>
          {editPermission && (
            <>
              <Upload
                className="upload-photos-category"
                accept="image/*"
                listType="picture"
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                onChange={handleUploadChange}
              >
                <Button>
                  <Icon type="upload" />
                  Upload Image
                </Button>
              </Upload>
              <span style={{ fontSize: 12, marginTop: 5 }}>
                {`Tamanho ideal: ${recommendedSize}`}
              </span>
            </>
          )}
        </>
      )}
      {withoutImage && (
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 10
          }}
        >
          {editPermission ? 'Por favor, adicione uma imagem' : 'Sem imagem'}
        </p>
      )}
    </div>
  );
};

SingleUpload.defaultProps = {
  isRound: false,
  isSmall: false,
  recommendedSize: '561 x 360',
  handleImageError: () => {},
  editPermission: true
};

SingleUpload.propTypes = {
  handleBeforeUpload: PropTypes.func.isRequired,
  handleUploadChange: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleImageError: PropTypes.func,
  withoutImage: PropTypes.bool.isRequired,
  uploadImage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.string
  ]).isRequired,
  isRound: PropTypes.bool,
  isSmall: PropTypes.bool,
  recommendedSize: PropTypes.string,
  editPermission: PropTypes.bool
};

export default SingleUpload;
