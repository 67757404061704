import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  DatePicker,
  Row,
  Form,
  Checkbox,
  Col,
  InputNumber,
  Icon,
  Radio,
  Select
} from 'antd';

import moment from '@/src/services/moment';

import { getTags } from '@/src/store/modules/tags/slice';

import formatCurrency from '@/src/utils/formatCurrency';
import percentageFormatter from '@/src/utils/percentageFormatter';
import getNumberFromString from '@/src/utils/getNumberFromString';

const { Panel } = Collapse;
const { Option } = Select;

const PromosFilters = props => {
  const { setPromosFilters, promosFilters } = props;

  const dispatch = useDispatch();

  const populateTags = () => dispatch(getTags({ taggable_type: 'promotion' }));

  const query = useSelector(state => state.promotions.query);
  const tagsToFilters = useSelector(state => state.tags.tagsFilters);

  const [statusObj, setStatusObj] = useState({});

  const formatPromoStatus = (array, filters) => {
    const entries = array.map(item => [item, true]);
    const obj = Object.fromEntries(entries);
    setStatusObj(obj);
    setPromosFilters({
      ...filters,
      ...obj
    });
  };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'MIN_FIXED_VALUE':
        setPromosFilters({
          ...promosFilters,
          min_fixed_value: value
        });
        break;
      case 'MAX_FIXED_VALUE':
        setPromosFilters({
          ...promosFilters,
          max_fixed_value: value
        });
        break;
      case 'MIN_PERCENTAGE_VALUE':
        setPromosFilters({
          ...promosFilters,
          min_percentage_value: value
        });
        break;
      case 'MAX_PERCENTAGE_VALUE':
        setPromosFilters({
          ...promosFilters,
          max_percentage_value: value
        });
        break;
      case 'DISCOUNT_TYPE':
        setPromosFilters({
          ...promosFilters,
          discount_type: value.target.value
        });
        break;
      case 'START_AT':
        setPromosFilters({
          ...promosFilters,
          start_at: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'END_AT':
        setPromosFilters({
          ...promosFilters,
          end_at: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'BY_STATUS':
        formatPromoStatus(value, promosFilters);
        break;
      case 'TAGS':
        setPromosFilters({
          ...promosFilters,
          by_tag: value ? value.map(tag => tag.replace(/\s/g, '_')) : ''
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const statusArr = [];
    if (query.filters.active) statusArr.push('active');
    if (query.filters.expiring) statusArr.push('expiring');
    if (query.filters.expired) statusArr.push('expired');
    formatPromoStatus(statusArr, query.filters);
    // eslint-disable-next-line
  }, [query]);

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
      >
        <Panel header="Vigência" key="1">
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  promosFilters.start_at ? moment(promosFilters.start_at) : null
                }
                format="DD/MM/YY"
                placeholder="Data Início"
                onChange={evt => handleFilterSelect(evt, 'START_AT')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  promosFilters.end_at ? moment(promosFilters.end_at) : null
                }
                format="DD/MM/YY"
                placeholder="Data Fim"
                onChange={evt => handleFilterSelect(evt, 'END_AT')}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Valor" key="2">
          <Row>
            <Col>
              <p>
                <strong>Tipo de Desconto</strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Radio.Group
                buttonStyle="solid"
                onChange={evt => handleFilterSelect(evt, 'DISCOUNT_TYPE')}
                value={promosFilters.discount_type}
              >
                <Radio.Button value="fixed_value">Valor Fixo</Radio.Button>
                <Radio.Button value="percentage">Porcentagem</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <br />
          {promosFilters.discount_type === 'fixed_value' ? (
            <>
              <Row>
                <Col>
                  <p>
                    <strong>Valor fixo</strong>
                  </p>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item>
                    <InputNumber
                      placeholder="Mínimo"
                      value={promosFilters.min_fixed_value || ''}
                      min={0}
                      step={1}
                      onChange={evt =>
                        handleFilterSelect(evt, 'MIN_FIXED_VALUE')
                      }
                      formatter={value => formatCurrency(value, false)}
                      parser={value => getNumberFromString(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputNumber
                      placeholder="Máximo"
                      value={promosFilters.max_fixed_value || ''}
                      min={0}
                      step={1}
                      onChange={evt =>
                        handleFilterSelect(evt, 'MAX_FIXED_VALUE')
                      }
                      formatter={value => formatCurrency(value, false)}
                      parser={value => getNumberFromString(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
          {promosFilters.discount_type === 'percentage' ? (
            <>
              <Row>
                <Col>
                  <p>
                    <strong>Porcentagem</strong>
                  </p>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item>
                    <InputNumber
                      placeholder="Mínimo"
                      value={promosFilters.min_percentage_value || ''}
                      min={0}
                      step={1}
                      formatter={value => percentageFormatter(value)}
                      onChange={evt =>
                        handleFilterSelect(evt, 'MIN_PERCENTAGE_VALUE')
                      }
                      parser={value => getNumberFromString(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputNumber
                      placeholder="Máximo"
                      value={promosFilters.max_percentage_value || ''}
                      min={0}
                      step={1}
                      formatter={value => percentageFormatter(value)}
                      onChange={evt =>
                        handleFilterSelect(evt, 'MAX_PERCENTAGE_VALUE')
                      }
                      parser={value => getNumberFromString(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
        </Panel>
        <Panel header="Status" key="4">
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={evt => handleFilterSelect(evt, 'BY_STATUS')}
            value={Object.keys(statusObj)}
          >
            <Row>
              <Col span={12}>
                <Checkbox value="active">Ativa</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="expired">Expirada</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Checkbox value="expiring">A Vencer</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Panel>
        <Panel header="Tags" key="5">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              onFocus={populateTags}
              placeholder="Selecione a tag"
              value={promosFilters.by_tag}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'TAGS')}
            >
              {tagsToFilters.map(tags => {
                return <Option key={tags}>{tags}</Option>;
              })}
            </Select>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

PromosFilters.defaultProps = {
  query: {
    page: '1',
    search: '',
    sort: '',
    url: '',
    filters: []
  },
  setPromosFilters: () => {},
  promosFilters: {
    start_at: null,
    end_at: null,
    min_fixed_value: '',
    max_fixed_value: '',
    min_percentage_value: '',
    max_percentage_value: '',
    discount_type: null,
    active: false,
    expired: false,
    expiring: false,
    archived: false
  }
};
PromosFilters.propTypes = {
  promosFilters: PropTypes.shape({
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    min_fixed_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_fixed_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min_percentage_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    max_percentage_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    discount_type: PropTypes.string,
    active: PropTypes.bool,
    expired: PropTypes.bool,
    expiring: PropTypes.bool,
    archived: PropTypes.bool,
    by_tag: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  }),
  query: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})])
  }),
  setPromosFilters: PropTypes.func
};

export default React.memo(PromosFilters);
