import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tag, Tooltip, Input } from 'antd';

const Tags = props => {
  const {
    removeTags,
    addTags,
    tags,
    closable,
    preventClosing,
    newTag,
    plusTag,
    valueTagsPlus,
    restValueTagsPlus,
    module
  } = props;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleClose = (evt, tag) => {
    if (preventClosing) evt.preventDefault();
    removeTags(tag);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags?.indexOf(inputValue) === -1) {
      addTags(inputValue);
    }
    setInputVisible(false);
    setInputValue('');
  };

  return (
    <Row type="flex" style={{ lineHeight: 1 }}>
      <Col>
        {tags?.map(tag => {
          const isLongTags = tag.length > 8;
          const tagElem = (
            <Tag
              key={tag}
              style={{ borderColor: '#b3b3b3', marginBottom: 8 }}
              closable={closable}
              onClose={evt => handleClose(evt, tag)}
            >
              {isLongTags ? `${tag.slice(0, 8)}...` : tag}
            </Tag>
          );
          return isLongTags ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {newTag && !inputVisible && (
          <Tag
            style={{
              borderStyle: 'dashed',
              borderColor: '#b3b3b3',
              cursor: 'pointer',
              marginBottom: 8
            }}
            onClick={() => setInputVisible(true)}
          >
            {`+ Nova ${module === 'product' ? 'Palavra' : 'Tag'}`}
          </Tag>
        )}
        {inputVisible && (
          <Input
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            ref={input => {
              if (input !== null) input.focus();
            }}
          />
        )}
        {plusTag && restValueTagsPlus.length > 0 ? (
          <Tooltip title={restValueTagsPlus} key={restValueTagsPlus}>
            <Tag style={{ borderColor: '#b3b3b3' }}> + {valueTagsPlus}</Tag>
          </Tooltip>
        ) : null}
      </Col>
    </Row>
  );
};

Tags.defaultProps = {
  closable: false,
  preventClosing: false,
  newTag: false,
  plusTag: false,
  valueTagsPlus: 0,
  restValueTagsPlus: [],
  module: '',
  removeTags: () => {},
  addTags: () => {}
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  removeTags: PropTypes.func,
  addTags: PropTypes.func,
  closable: PropTypes.bool,
  preventClosing: PropTypes.bool,
  newTag: PropTypes.bool,
  plusTag: PropTypes.bool,
  valueTagsPlus: PropTypes.number,
  restValueTagsPlus: PropTypes.arrayOf(PropTypes.any),
  module: PropTypes.string
};

export default Tags;
