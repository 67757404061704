import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Icon, Skeleton, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import {
  getOrdersData,
  getDeliveredOrdersData
} from '@/src/store/modules/expedition/slice';

import formatParityText from '@/src/utils/formatParityText';
import { presentDayFormatted, lastDayFormatted } from '@/src/utils/dateUtils';
import { waitingQuery } from '@/src/globals/dispatchQueries';

import { useBreakpoint } from '@/src/context/BreakpointContext';

import './Line.scss';

const { Panel } = Collapse;

const Line = props => {
  const { title, reload, orders, loading, filtersApplied, children } = props;

  const {
    // dispatchedCount,
    deliveredCount,
    deliveredLoading,
    waitingOrdersCount,
    viewer
  } = useSelector(state => state.expedition);

  let ordersCount;
  // if (title === 'Em rota') {
  //   ordersCount =
  //     dispatchedCount === 1 ? '1 pedido' : `${dispatchedCount} pedidos`;
  // } else
  if (title === 'Entregue') {
    ordersCount =
      deliveredCount === 1 ? '1 pedido' : `${deliveredCount} pedidos`;
  } else {
    ordersCount = formatParityText(orders.length, 'pedido');
  }

  const breakpoint = useBreakpoint();

  const renderButtonContent = () => {
    let buttonContent = '';
    if (waitingOrdersCount === 1) {
      buttonContent = `Atualizar (${waitingOrdersCount}) novo pedido`;
    } else {
      buttonContent = `Atualizar (${waitingOrdersCount}) novos pedidos`;
    }
    if (waitingOrdersCount === 0) {
      buttonContent = `Não há novos pedidos`;
    }
    return buttonContent;
  };

  const dispatch = useDispatch();

  const handleReloadOrders = () => {
    dispatch(getOrdersData(waitingQuery));
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ width: '100%' }}>
        {(title === 'Em rota' || title === 'Entregue') && breakpoint.lg ? (
          <Skeleton
            title={false}
            loading={loading}
            active
            paragraph={{ rows: 1, width: '100%' }}
            className="line-title__skeleton"
          >
            <Collapse
              defaultActiveKey={filtersApplied ? ['1'] : []}
              bordered={false}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <Icon type="caret-down" rotate={isActive ? 180 : 0} />
              )}
              className="line-collapse"
              onChange={value => {
                if (title === 'Em rota' || value.length === 0) return;
                dispatch(getDeliveredOrdersData('delivered'));
              }}
            >
              <Panel
                header={title}
                extra={
                  <Tooltip
                    placement="top"
                    title={
                      title === 'Em rota'
                        ? `${lastDayFormatted} a ${presentDayFormatted}`
                        : presentDayFormatted
                    }
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{ordersCount}</span>
                    </div>
                  </Tooltip>
                }
                key="1"
              >
                <Skeleton
                  title={false}
                  loading={title === 'Entregue' ? deliveredLoading : false}
                  active
                  paragraph={{ rows: 1, width: '100%' }}
                  className="line-content__skeleton"
                >
                  <div
                    style={{
                      backgroundColor: '#e1e1e1',
                      borderRadius: '8px',
                      height: 'calc(100% - 50px)',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'center',
                      minHeight:
                        viewer === 'hub' ||
                        (breakpoint.mobile && filtersApplied)
                          ? 'unset'
                          : '48vh'
                    }}
                  >
                    {children}
                  </div>
                </Skeleton>
              </Panel>
            </Collapse>
          </Skeleton>
        ) : (
          <>
            <Skeleton
              title={false}
              loading={loading}
              active
              paragraph={{ rows: 1, width: '100%' }}
              className="line-title__skeleton"
            >
              <div
                style={{
                  display: 'flex',
                  height: '34px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '2.5px solid  #b3b3b3',
                  paddingBottom: '4.5px',
                  marginBottom: '16px'
                }}
              >
                <span className="line-title">{title.toUpperCase()}</span>
                <Tooltip
                  placement="top"
                  title={`${lastDayFormatted} a ${presentDayFormatted}`}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{ordersCount}</span>
                  </div>
                </Tooltip>
              </div>
            </Skeleton>
            <Skeleton
              title={false}
              loading={loading}
              active
              paragraph={{ rows: 1, width: '100%' }}
              className="line-content__skeleton"
            >
              <div
                style={{
                  backgroundColor: '#e1e1e1',
                  borderRadius: '8px',
                  height: 'calc(100% - 50px)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'center',
                  minHeight:
                    viewer === 'hub' || (breakpoint.mobile && filtersApplied)
                      ? 'unset'
                      : '48vh'
                }}
              >
                {reload && !filtersApplied && (
                  <Button
                    className="reload_button--mobile"
                    disabled={waitingOrdersCount <= 0}
                    onClick={handleReloadOrders}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                    >
                      <path
                        fillRule="evenodd"
                        fill="#ffff"
                        d="M19.476 11.646c.276-.016.512.195.528.47.002.033 0 .066-.004.098-.412 2.958-1.203 5.015-2.374 6.173-3.338 3.3-8.692 3.3-12.03 0L3.86 20.175c-.193.198-.51.203-.707.01-.097-.094-.152-.223-.152-.358v-5.04c0-.275.224-.5.5-.5h4.765c.276 0 .5.225.5.5 0 .131-.05.256-.142.35L6.842 16.97c2.596 2.567 6.8 2.662 9.396.095.93-.924 1.55-2.568 1.86-4.933.032-.239.228-.42.468-.435l.91-.051zm-1.954-5.033l1.737-1.788c.193-.198.51-.203.707-.01.097.094.152.223.152.358v5.04c0 .275-.224.5-.5.5h-4.765c-.276 0-.5-.225-.5-.5 0-.131.05-.256.142-.35l1.781-1.833c-2.596-2.567-6.8-2.662-9.396-.095-.93.924-1.55 2.568-1.86 4.933-.032.239-.228.42-.468.435l-.91.051c-.276.016-.512-.195-.528-.47-.002-.033 0-.066.004-.098.412-2.958 1.203-5.015 2.374-6.173 3.338-3.3 8.692-3.3 12.03 0z"
                        transform="translate(-67 -302) translate(16 228) translate(40 64) translate(8 6)"
                      />
                    </svg>
                    {renderButtonContent()}
                  </Button>
                )}
                {children}
              </div>
            </Skeleton>
          </>
        )}
      </div>
    </div>
  );
};

Line.defaultProps = {
  filtersApplied: false
};

Line.propTypes = {
  title: PropTypes.string.isRequired,
  reload: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Line;
