import React from 'react';
import { Row, Col } from 'antd';

const PackHeader = () => {
  return (
    <div
      style={{
        backgroundColor: '#EBEBEB',
        borderRadius: '8px 8px 0 0',
        fontWeight: 'bold',
        color: '#0A0A0A',
        textAlign: 'center'
      }}
    >
      <Row>
        <Col span={3} lg={2}>
          <div className="modal-table__header-cell">Foto</div>
        </Col>
        <Col span={3} lg={4}>
          <div className="modal-table__header-cell">SKU</div>
        </Col>
        <Col span={3} lg={4}>
          <div className="modal-table__header-cell">EAN</div>
        </Col>
        <Col span={6}>
          <div className="modal-table__header-cell">Nome produto</div>
        </Col>
        <Col span={2}>
          <div className="modal-table__header-cell">Qnt</div>
        </Col>
        <Col span={6}>
          <div className="modal-table__header-cell">
            Qnt de Volume para Empacotar
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PackHeader;
