import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  policies: [],
  policy: {},
  policyPriceTables: [],
  hubs: [],
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

const policiesSlice = createSlice({
  name: '@policies',
  initialState,
  reducers: {
    setPolicies(prevState, action) {
      const state = prevState;
      const { total } = action.payload;

      state.policies = action.payload.policies;
      state.isLoading = false;
      state.total = total;

      state.query = {
        page: Number(action.payload.query.page) || 1,
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }`
      };

      return state;
    },
    setPolicy(prevState, action) {
      const state = prevState;
      state.policy = action.payload;
      state.isLoading = false;
      return state;
    },
    setPolicyPriceTables(prevState, action) {
      const state = prevState;
      state.policyPriceTables = action.payload.policyPriceTables;
      return state;
    },
    setPolicyPriceTable(prevState, action) {
      const { data } = action.payload.data;
      const state = prevState;
      state.policyPriceTables = state.policyPriceTables.map(item => {
        if (Number(item.id) === Number(data.id)) return data;
        return item;
      });
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getPolicies(state) {
      return state;
    },
    getPolicy(state) {
      return state;
    },
    getPolicyPriceTables(state) {
      return state;
    },
    getCreateNewPolicy(state) {
      return state;
    },
    getUpdatePolicy(state) {
      return state;
    }
  }
});

export const {
  setPolicies,
  setPolicy,
  setPolicyPriceTables,
  setPolicyPriceTable,
  setLoading,
  setPagination,
  setSort,
  getPolicies,
  getPolicy,
  getPolicyPriceTables,
  getCreateNewPolicy,
  getUpdatePolicy
} = policiesSlice.actions;

export default policiesSlice.reducer;
