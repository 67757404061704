import { notification } from 'antd';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import bugsnag from '@/src/services/bugsnag';
import {
  addToWallet as addToWalletRequest,
  removeFromWallet as removeFromWalletRequest
} from '@/src/api/wallet';
import {
  setWallet,
  setIsLoading,
  addToWallet,
  removeFromWallet
} from '@/src/store/modules/wallet/slice';
import { getCustomerWalletTransactionsSaga } from '@/src/store/modules/customers/saga';

import { getCustomerIdState } from '@/src/store/selectors';

import { errorAlert } from '@/src/utils/notifications';

export function* addToWalletSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(addToWalletRequest, payload);
    yield put(setWallet(response.data.data));
    const id = yield select(getCustomerIdState);
    const params = {
      id,
      query: {
        perPage: 5,
        page: 1
      }
    };
    yield call(getCustomerWalletTransactionsSaga, { payload: params });
    yield put(setIsLoading(false));
    notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'O crédito foi adicionado na carteira.'
    });
  } catch (err) {
    yield put(setIsLoading(false));
    if (err.response.status === 409) {
      errorAlert(err);
    } else {
      bugsnag.notify(err);
    }
  }
}

export function* removeFromWalletSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(removeFromWalletRequest, payload);
    const id = yield select(getCustomerIdState);
    const params = {
      id,
      query: {
        perPage: 5,
        page: 1
      }
    };
    yield call(getCustomerWalletTransactionsSaga, { payload: params });
    yield put(setWallet(response.data.data));
    yield put(setIsLoading(false));
    notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'O crédito foi removido da carteira.'
    });
  } catch (err) {
    yield put(setIsLoading(false));
    if (err.response.status === 409) {
      errorAlert(err);
    } else {
      bugsnag.notify(err);
    }
  }
}

function* userSaga() {
  yield takeEvery(addToWallet, addToWalletSaga);
  yield takeEvery(removeFromWallet, removeFromWalletSaga);
}

export default userSaga;
