import axios from 'axios';

export const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiAdmin = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// mock PORTAL
// export const apiPortalMock = axios.create({
//   baseURL: 'http://portal-stg.mocklab.io',
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

// mock
export const apiMock = axios.create({
  baseURL: 'https://646631ad228bd07b355e8ec5.mockapi.io/api/',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiPortal = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiCatalog = axios.create({
  baseURL: process.env.REACT_APP_CATALOG_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiWarehousePetz = axios.create({
  baseURL: process.env.REACT_APP_WAREHOUSE_PETZ_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiDispatch = axios.create({
  baseURL: process.env.REACT_APP_DISPATCH_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiDispatchFile = axios.create({
  baseURL: process.env.REACT_APP_DISPATCH_URL,
  headers: {
    'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001'
  }
});

export const apiOneSignal = axios.create({
  baseURL: 'https://onesignal.com/api/v1',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic OWVjYzlmMDUtNzkxNi00NjQ5LWJjOWMtMTM3YzNkNWY1NWRl'
  }
});
