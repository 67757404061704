import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  getTimelineEvents as getTimelineEventsRequest,
  commentTimelineEvents as commentTimelineEventsRequest,
  deleteCommentTimelineEvents as deleteCommentTimelineEventsRequest
} from '@/src/api/timeline-events';

import { commentOrderTimeline as commentOrderTimelineRequest } from '@/src/api/expedition';

import { getTimelineEvents, getOrderTimeline } from '@/src/store/selectors';

import {
  getTimeline,
  setIsLoading,
  setTimelineEvents,
  commentTimeline,
  deleteCommentTimeline,
  updatesTimeline,
  commentOrderTimeline,
  setOrderTimeline
} from '@/src/store/modules/timeline-events/slice';

import { errorAlert } from '@/src/utils/notifications';

function* timelineSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getTimelineEventsRequest, payload);
    yield put(setTimelineEvents(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* commentTimelineSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(commentTimelineEventsRequest, payload);
    yield put(setTimelineEvents(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* deleteCommentTimelineSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(deleteCommentTimelineEventsRequest, payload);
    yield put(setTimelineEvents(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* updatesTimelineSaga({ payload }) {
  try {
    const response = yield call(getTimelineEventsRequest, payload);
    const responseToCheckUpdates = response.data.data;
    const timelineStore = yield select(getTimelineEvents);
    if (responseToCheckUpdates.length !== timelineStore.length) {
      yield put(setTimelineEvents(response.data.data));
    }
  } catch (err) {
    errorAlert(err);
  }
}

function* commentOrderTimelineSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(commentOrderTimelineRequest, payload);
    const orderTimeline = yield select(getOrderTimeline);
    const eventInfo = response.data.data;
    const newTimeline = [
      {
        attributes: {
          agent_id: null,
          agent_name: eventInfo.attributes.user || '',
          agent_type: null,
          created_at: eventInfo.attributes.created_at,
          event: eventInfo.attributes.event,
          id: eventInfo.id,
          object_id: eventInfo.id,
          object_type: 'Order',
          updated_at: eventInfo.attributes.updated_at,
          value: eventInfo.attributes.description
        }
      },
      ...orderTimeline
    ];
    yield put(setOrderTimeline(newTimeline));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* timelineEventsSaga() {
  yield takeEvery(getTimeline, timelineSaga);
  yield takeEvery(commentTimeline, commentTimelineSaga);
  yield takeEvery(deleteCommentTimeline, deleteCommentTimelineSaga);
  yield takeEvery(updatesTimeline, updatesTimelineSaga);
  yield takeEvery(commentOrderTimeline, commentOrderTimelineSaga);
}

export default timelineEventsSaga;
