import React from 'react';
import { Link } from 'react-router-dom';

const skusPetz = [
  {
    title: 'ID (Portal)',
    dataIndex: 'id',
    key: 'id',
    render: data => (
      <Link
        to={`/skus-petz/${data}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {data}
      </Link>
    )
  },
  {
    title: 'EAN',
    dataIndex: 'ean',
    key: 'ean'
  },
  {
    title: 'Código SKU Petz',
    dataIndex: 'external_id',
    key: 'external_id'
  },
  {
    title: 'Código SKU Zee.Now',
    dataIndex: 'reference',
    key: 'reference'
  },
  {
    title: 'Código Produto Zee.Now',
    dataIndex: 'product_reference',
    key: 'product_reference'
  },
  {
    title: 'Tamanho',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Peso',
    dataIndex: 'weight',
    key: 'weight'
  }
];

export default skusPetz;
