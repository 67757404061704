import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Row, Col } from 'antd';

import { getChangePassword } from '@/src/store/modules/change-password/slice';

const ChangePasswordForm = ({ form }) => {
  const [confirmDirty, setConfirmDirty] = useState(false);

  const { errMessage, loading } = useSelector(state => state.changePassword);

  const regex = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/;

  const dispatch = useDispatch();

  const handleSubmit = evt => {
    evt.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const payload = {
          data: {
            type: 'users',
            attributes: {
              current_password: values.currentPassword,
              new_password: values.newPassword,
              password_confirmation: values.confirmPassword
            }
          }
        };
        dispatch(getChangePassword(payload));
      }
      return false;
    });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Senha diferente da anterior');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {errMessage && (
        <>
          <Alert
            message="Dados incorretos. Tente novamente"
            type="error"
            showIcon
            style={{ width: '80%', margin: '0 auto' }}
          />
          <br />
        </>
      )}
      <Row type="flex" justify="center" align="middle">
        <Col span={24} md={12}>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('currentPassword', {
              rules: [
                { required: true, message: 'Campo obrigatório' }
                // {
                //   pattern: regex,
                //   message: 'Faltam requisitos'
                // },
                // {
                //   validator: validateToNextPassword
                // }
              ]
            })(
              <Input.Password
                placeholder="Senha atual"
                autoComplete="current-password"
              />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('newPassword', {
              rules: [
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: regex,
                  message: 'Faltam requisitos'
                },
                {
                  validator: validateToNextPassword
                }
              ]
            })(
              <Input.Password
                placeholder="Nova senha"
                autoComplete="new-password"
              />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Campo obrigatório' },
                { validator: compareToFirstPassword }
              ]
            })(
              <Input.Password
                placeholder="Confirmar nova senha"
                onBlur={handleConfirmBlur}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              htmlType="submit"
              data-test="login-form-submit"
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const WrappedChangePasswordForm = Form.create({
  name: 'change-password-form'
})(ChangePasswordForm);

export default WrappedChangePasswordForm;
