import { useDispatch } from 'react-redux';

import {
  getOrderById,
  setCollectedItems,
  setPackedItems,
  setReturnedItems,
  setModalVisibility,
  setModalStep
} from '@/src/store/modules/expedition/slice';

const useDispatchModal = () => {
  const dispatch = useDispatch();

  const handleModalData = (order, getOrder = true) => {
    let include = 'order_items';
    let fields = [];
    const ordersFields =
      'id,has_return_request,has_cancelation_request,status,number';
    const orderItemsFields =
      'id,quantity,sku_external_id,sku_code,sku_ean,sku_name,sku_image,gift';

    if (order.hasReturnRequest || order.hasCancelationRequest) {
      include = `${include},order_requests,order_requests.returned_items.order_item`;
      fields = [
        { key: 'orders', value: ordersFields },
        { key: 'order_items', value: orderItemsFields },
        { key: 'order_requests', value: 'id,request_resolved' },
        { key: 'returned_items', value: 'id,returned_quantity' }
      ];
      const payload = {
        requestId: '',
        orderItems: []
      };
      dispatch(setReturnedItems(payload));
      if (order.status !== 'started' && order.status !== 'picked') {
        dispatch(setModalVisibility(false));
      }
    } else if (order.status === 'started') {
      fields = [
        {
          key: 'orders',
          value: `${ordersFields},total,address_district,address_street,address_number`
        },
        {
          key: 'order_items',
          value: `${orderItemsFields},returned_quantity,scanned_quantity,order_id`
        }
      ];
      const payload = {
        orderId: order.id,
        orderItems: []
      };
      dispatch(setCollectedItems(payload));
      dispatch(setModalStep('Coletar'));
    } else if (order.status === 'picked') {
      fields = [
        {
          key: 'orders',
          value: `${ordersFields},total,address_district,address_street,address_number`
        },
        {
          key: 'order_items',
          value: `${orderItemsFields},returned_quantity`
        }
      ];
      const payload = {
        orderId: order.id,
        volumes: []
      };
      dispatch(setPackedItems(payload));
      dispatch(setModalStep('Empacotar'));
    } else {
      dispatch(setModalVisibility(false));
      return;
    }
    if (getOrder) {
      dispatch(
        getOrderById({
          id: order.id,
          query: {
            include,
            fields
          }
        })
      );
    }
  };

  const handleShowModal = (order, step) => {
    handleModalData(order);
    dispatch(
      setModalStep(
        order.hasReturnRequest || order.hasCancelationRequest
          ? 'Devolução'
          : step
      )
    );
    dispatch(setModalVisibility(true));
  };

  return { handleShowModal, handleModalData };
};

export default useDispatchModal;
