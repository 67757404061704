import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  Timeline,
  Row,
  Col,
  Spin,
  Button,
  Select,
  Icon,
  Tooltip
} from 'antd';
import moment from '@/src/services/moment';

import {
  getTimeline,
  setTimelineEvents
  // updatesTimeline
} from '@/src/store/modules/timeline-events/slice';

import InputSearch from '@/src/components/InputSearch/InputSearch';
import UserAvatar from '@/src/components/Users/UserAvatar';
// import DropDownComment from './components/DropDownComment/dropDownComment';

import operation from '@/src/assets/images/timeline/ic-operacoes.svg';
// import printed from '@/src/assets/images/timeline/oetp-1.svg';
// import changedAddress from '@/src/assets/images/timeline/oetp-6.svg';
import email from '@/src/assets/images/timeline/oetp3_2x.png';
import pushNotification from '@/src/assets/images/timeline/oetp2.svg';
import customerWithDeliveryman from '@/src/assets/images/timeline/customerWithDeliveryman.png';
import deliverymanWithCustomer from '@/src/assets/images/timeline/entregador.png';
import alertIcon from '@/src/assets/images/timeline/oetp5.svg';
import callByApi from '@/src/assets/images/timeline/oetp7.svg';
import callByZendesk from '@/src/assets/images/timeline/oetp8.svg';
import comment from '@/src/assets/images/timeline/comment.png';
import proactive_chat from '@/src/assets/images/timeline/proactive_chat.svg';
import warning from '@/src/assets/images/timeline/warning-full.svg';
import check from '@/src/assets/images/timeline/ic-check.svg';
import callByCx from '@/src/assets/images/timeline/chat-cx.svg';

const { Option } = Select;

const OrderTimeline = props => {
  const {
    orderId,
    commentHandlerTimeline,
    // deleteCommentHandlerTimeline,
    timelineEvents,
    isLoading,
    dispatchModule,
    editPermission
  } = props;

  const [filters, setFilters] = useState([
    'Status ERP',
    'Notificação push',
    'Email via sistema',
    'Alerta ERP',
    'Ligação',
    'Chat proativo',
    'Chat entregador',
    'Chat zendesk',
    'Comentários',
    'Pedido com incidência',
    'Incidência resolvida',
    'Item devolvido'
  ]);
  const [filtersTags, setFiltersTags] = useState([]);
  const [events, setEvents] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const orderedEvents = [...timelineEvents].sort((a, b) => {
      if (moment(a.attributes.created_at).isBefore(b.attributes.created_at))
        return -1;
      if (moment(a.attributes.created_at).isAfter(b.attributes.created_at))
        return 1;
      return 0;
    });
    setEvents(orderedEvents);
  }, [timelineEvents]);

  useEffect(() => {
    return () => {
      dispatch(setTimelineEvents([]));
    };
    // eslint-disable-next-line
  }, []);

  const legendElements = [
    {
      title: 'Status ERP',
      img: operation
    },
    {
      title: 'Alerta ERP',
      img: alertIcon
    },
    {
      title: 'Comentário',
      img: comment
    },
    {
      title: 'Chat entregador',
      img: deliverymanWithCustomer
    },
    {
      title: 'Chat proativo',
      img: proactive_chat
    },
    {
      title: 'Email via sistema',
      img: email
    },
    {
      title: 'Ligação',
      img: callByApi
    },
    {
      title: 'Notificação push',
      img: pushNotification
    },
    {
      title: 'Chat usuário',
      img: customerWithDeliveryman
    },
    {
      title: 'Chat Zendesk',
      img: callByZendesk
    },
    {
      title: 'Pedido com incidência',
      img: warning
    },
    {
      title: 'Incidência resolvida',
      img: check
    }
  ];

  const translatedStatus = {
    waiting: 'Aguardando',
    started: 'Iniciado',
    picked: 'Coletado',
    packed: 'Empacotado',
    nfe_issued: 'Faturado',
    billed: 'Faturado',
    label_generated: 'Etiqueta gerada',
    separated: 'Separado',
    transporting: 'Em trânsito',
    dispatched: 'Em trânsito',
    delivering: 'Chegando',
    delivered: 'Entregue',
    confirmed: 'Confirmado',
    canceled: 'Cancelado',
    returned: 'Retornado',
    return_request: 'Solicitação de devolução',
    order_return_request_resolved: 'Devolução resolvida',
    cancelation_request: 'Solicitação de cancelamento',
    order_cancelation_request_resolved: 'Cancelamento resolvido',
    order_request_rejected: 'Solicitação rejeitada',
    order_alert: 'Pedido com incidência',
    order_alert_resolved: 'Incidência resolvida',
    added_to_embark: 'Adicionado ao embarque',
    removed_from_embark: 'Removido do embarque',
    embark_destroyed: 'Embarque deletado/destruído',
    picked_with_rupture: 'Coletado com ruptura'
  };

  const translatedFilters = {
    erp_status_changed: 'Status ERP',
    alert: 'Alerta ERP',
    push_notification: 'Notificação push',
    email_sent: 'Email via sistema',
    phone_call: 'Ligação',
    proactive_chat: 'Chat proativo',
    deliverer_chat: 'Chat entregador',
    zendesk_chat: 'Chat zendesk',
    comment: 'Comentários',
    order_alert: 'Pedido com incidência',
    order_alert_resolved: 'Incidência resolvida',
    returned_item: 'Item devolvido'
  };

  const checkTypeImgIcon = (value, type) => {
    let img;
    switch (value) {
      case 'status':
        img = operation;
        break;
      case 'push_notification':
        img = pushNotification;
        break;
      case 'email_sent':
        img = email;
        break;
      case 'deliverer_chat':
        if (type === 'customer') {
          img = customerWithDeliveryman;
        } else {
          img = deliverymanWithCustomer;
        }
        break;
      case 'alert':
        img = alertIcon;
        break;
      case 'phone_call':
        img = callByApi;
        break;
      case 'zendesk_chat':
        img = callByZendesk;
        break;
      case 'comment':
        img = comment;
        break;
      case 'proactive_chat':
        img = proactive_chat;
        break;
      case 'order_alert':
        img = warning;
        break;
      case 'order_alert_in_progress':
        img = callByCx;
        break;
      case 'order_alert_resolved':
        img = check;
        break;
      default:
        img = operation;
        break;
    }
    return img;
  };

  const deleteTags = value => {
    const hasInFiltersTags = filtersTags.includes(value);

    let checkTypeFilters;
    switch (value) {
      case 'Status ERP':
        checkTypeFilters = false;
        break;
      case 'Notificação push':
        checkTypeFilters = false;
        break;
      case 'Email via sistema':
        checkTypeFilters = false;
        break;
      case 'Alerta ERP':
        checkTypeFilters = false;
        break;
      case 'Ligação':
        checkTypeFilters = false;
        break;
      case 'Chat proativo':
        checkTypeFilters = false;
        break;
      case 'Chat entregador':
        checkTypeFilters = false;
        break;
      case 'Chat zendesk':
        checkTypeFilters = false;
        break;
      case 'Comentários':
        checkTypeFilters = false;
        break;
      case 'Pedido com incidência':
        checkTypeFilters = false;
        break;
      case 'Incidência resolvida':
        checkTypeFilters = false;
        break;
      case 'Item devolvido':
        checkTypeFilters = false;
        break;
      default:
        checkTypeFilters = true;
        break;
    }

    if (dispatchModule) {
      const deleteFiltersTag = filtersTags.filter(
        item => !value.includes(item)
      );
      setFiltersTags(deleteFiltersTag);
      if (deleteFiltersTag.length === 0) setEvents(timelineEvents);
    } else if (!checkTypeFilters && !hasInFiltersTags) {
      const deleteFilters = filters.filter(item => !value.includes(item));
      setFilters(deleteFilters);
    } else if (checkTypeFilters && hasInFiltersTags) {
      const deleteFiltersTag = filtersTags.filter(
        item => !value.includes(item)
      );
      setFiltersTags(deleteFiltersTag);
      const deleteFilters = filters.filter(item => !value.includes(item));
      setFilters(deleteFilters);
    } else {
      const deleteFiltersTag = filtersTags.filter(
        item => !value.includes(item)
      );
      setFiltersTags(deleteFiltersTag);
    }
  };

  const checkIfExistInFilters = value => {
    const valueTranslated = value.includes('order.')
      ? 'Status ERP'
      : translatedFilters[value];
    const include = filters.includes(valueTranslated);
    const includedInFiltersTags = filtersTags.includes(valueTranslated);
    if (!include) {
      setFilters([...filters, valueTranslated]);
      setFiltersTags([...filtersTags, valueTranslated]);
    } else if (!includedInFiltersTags) {
      setFiltersTags([...filtersTags, valueTranslated]);
    }
  };

  const commentHandler = e => commentHandlerTimeline(e);

  // const deleteCommentHandler = id => deleteCommentHandlerTimeline(id);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (!dispatchModule) {
  //       dispatch(
  //         updatesTimeline({
  //           filters: filtersTags,
  //           object_id: orderId,
  //           object_type: 'Order'
  //         })
  //       );
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, 5000);
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line
  // }, [filtersTags, dispatch, orderId]);

  useEffect(() => {
    if (dispatchModule && filtersTags.length > 0) {
      setEvents(() => {
        return timelineEvents.filter(item => {
          const valueTranslated = item.attributes.event.includes('order.')
            ? 'Status ERP'
            : translatedFilters[item.attributes.event];
          return filtersTags.includes(valueTranslated);
        });
      });
    } else if (!dispatchModule) {
      dispatch(
        getTimeline({
          filters: filtersTags,
          object_id: orderId,
          object_type: 'Order'
        })
      );
    }
    // eslint-disable-next-line
  }, [filtersTags, dispatch, orderId]);

  const legendTimeline = legendElements.map(element => (
    <Row key={element.title}>
      <Col style={{ margin: '0 5px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <img
            style={{ height: 18, width: 18 }}
            src={element.img}
            alt="Status"
          />
          <p style={{ color: '#333333', marginBottom: 0, marginLeft: 5 }}>
            {element.title}
          </p>
        </div>
      </Col>
    </Row>
  ));

  const handleEventLabel = timeline => {
    let label;
    if (translatedStatus[timeline.attributes.value]) {
      label = translatedStatus[timeline.attributes.value];
    } else {
      // tratar eventos com método de pagamento
      label = timeline.attributes.value
        .replace('credit_card', 'cartão de crédito')
        .replace('pix', 'Pix');
    }
    return label;
  };

  return (
    <Card
      className="no-shadow card-timeline "
      title={dispatchModule ? 'Histórico' : 'Timeline do pedido'}
      style={{ width: '100%', marginTop: 10, paddingLeft: 0 }}
    >
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 15
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              color: '#333333',
              marginRight: 12,
              marginBottom: 0
            }}
          >
            Filtros:
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Select
              mode="multiple"
              placeholder="Selecione o filtro da Timeline"
              onFocus={() => filters}
              style={{
                flexGrow: 1,
                marginRight: 16,
                maxWidth: '220px'
              }}
              onSelect={value => setFiltersTags(prev => [...prev, value])}
              onDeselect={value => deleteTags(value)}
              value={filtersTags}
            >
              {filters.map(filter => {
                return (
                  <Option key={filter} value={filter}>
                    {filter}
                  </Option>
                );
              })}
            </Select>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong style={{ color: '#333333', marginBottom: 0 }}>
                Legenda
              </strong>
              <Tooltip
                placement="bottom"
                title={legendTimeline}
                overlayClassName="timeline-legend"
              >
                <Icon style={{ marginLeft: 6 }} type="info-circle" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
            marginBottom: 24
          }}
        >
          <UserAvatar border />
          <div style={{ flexGrow: 1, marginLeft: 12 }}>
            <InputSearch
              placeholder="Comente sobre o pedido"
              populateResult={commentHandler}
              disabled={!editPermission}
            />
          </div>
        </div>
        <Spin spinning={isLoading}>
          <Timeline
            className={`timeline-order ${dispatchModule ? 'dispatch' : ''}`}
          >
            {events?.length > 0 ? (
              events.map(timeline => (
                <Timeline.Item key={timeline.attributes.id}>
                  <Row type="flex" justify="space-between">
                    <Col span={1} style={{ marginRight: 15 }}>
                      <Button
                        type="link"
                        className="order-timeline"
                        style={{ marginBottom: 10 }}
                        onClick={() =>
                          checkIfExistInFilters(timeline.attributes.event)
                        }
                      >
                        <img
                          style={{ height: 22, width: 22 }}
                          src={checkTypeImgIcon(
                            timeline.attributes.event,
                            timeline.attributes.agent_type
                          )}
                          alt=""
                        />
                      </Button>
                    </Col>
                    <Col span={17}>
                      {timeline.attributes.event === 'comment' ||
                      timeline.attributes.event === 'push_notification' ||
                      timeline.attributes.event === 'email_sent' ? (
                        <>
                          {/* <DropDownComment
                            type={timeline.attributes.event}
                            id={timeline.attributes.id}
                            comment={timeline.attributes.value}
                            deleteComment={deleteCommentHandler}
                            commentBy={timeline.attributes.agent_name}
                            date={moment(
                              timeline.attributes.created_at
                            ).calendar()}
                          /> */}
                          {timeline.attributes.event === 'comment' ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <span className="timeline-order__event">
                                {timeline.attributes.value}
                              </span>
                              <span style={{ fontSize: 13 }}>
                                Comentado por {timeline.attributes.agent_name}
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <span className="timeline-order__event">
                                {timeline.attributes.value}
                              </span>
                              {timeline.attributes.agent_name === null ||
                              timeline.attributes.agent_name === undefined ? (
                                <span style={{ fontSize: 13 }}>
                                  Feito por Sistema
                                </span>
                              ) : (
                                <span style={{ fontSize: 13 }}>
                                  Feito por {timeline.attributes.agent_name}
                                </span>
                              )}
                            </div>
                          )}
                          {!dispatchModule && (
                            <div
                              style={{
                                fontSize: 'smaller',
                                textAlign: 'initial'
                              }}
                            >
                              {moment(timeline.attributes.created_at).calendar(
                                null,
                                {
                                  sameDay: '[Hoje]',
                                  nextDay: '[Amanhã]',
                                  nextWeek: 'dddd',
                                  lastDay: '[Ontem]',
                                  lastWeek: '[Última] dddd',
                                  sameElse: 'DD/MM/YYYY'
                                }
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {dispatchModule ? (
                            <>
                              <pre className="timeline-order__event">
                                {translatedStatus[timeline.attributes.value]
                                  ? translatedStatus[timeline.attributes.value]
                                  : timeline.attributes.value}
                              </pre>
                              <span style={{ fontSize: 13 }}>
                                Atualizado por {timeline.attributes.agent_name}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="timeline-order__event">
                                {handleEventLabel(timeline)}
                              </span>
                              <div style={{ fontSize: 'smaller' }}>
                                {moment(
                                  timeline.attributes.created_at
                                ).calendar(null, {
                                  sameDay: '[Hoje]',
                                  nextDay: '[Amanhã]',
                                  nextWeek: 'dddd',
                                  lastDay: '[Ontem]',
                                  lastWeek: '[Última] dddd',
                                  sameElse: 'DD/MM/YYYY'
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col span={dispatchModule ? 4 : 3}>
                      {dispatchModule ? (
                        <span>
                          {moment(timeline.attributes.created_at).format(
                            'DD/MM/YY'
                          )}{' '}
                          às{' '}
                          {moment(timeline.attributes.created_at).format(
                            'HH:mm'
                          )}
                        </span>
                      ) : (
                        <span style={{ fontWeight: 'bold', color: '#0a0a0a' }}>
                          {moment(timeline.attributes.created_at).format(
                            'HH:mm'
                          )}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Timeline.Item>
              ))
            ) : (
              <div>Não há nenhum evento desse pedido.</div>
            )}
          </Timeline>
        </Spin>
      </>
    </Card>
  );
};

OrderTimeline.defaultProps = {
  dispatchModule: false,
  editPermission: true
};

OrderTimeline.propTypes = {
  orderId: PropTypes.string.isRequired,
  commentHandlerTimeline: PropTypes.func.isRequired,
  // deleteCommentHandlerTimeline: PropTypes.func.isRequired,
  timelineEvents: PropTypes.oneOfType(
    [PropTypes.array, PropTypes.shape({})],
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        id: PropTypes.number,
        event: PropTypes.string,
        value: PropTypes.string,
        summary: PropTypes.string,
        object_id: PropTypes.number,
        object_type: PropTypes.string,
        agent_id: PropTypes.number,
        agent_type: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        agent_name: PropTypes.string
      })
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  dispatchModule: PropTypes.bool,
  editPermission: PropTypes.bool
};

export default OrderTimeline;
