import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import PromotionForm from '@/src/pages/Promotions/containers/PromotionForm';

const NewPromotion = () => {
  return (
    <>
      <h1>Nova promoção</h1>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/promocoes">Promoções</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Nova Promoção</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '24px 16px'
        }}
      >
        <PromotionForm />
      </div>
    </>
  );
};

export default NewPromotion;
