import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Card,
  Select,
  Switch,
  Tooltip,
  Icon,
  Upload,
  message,
  Modal,
  Avatar
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import {
  getAssemblings,
  updateAssembling,
  orderByDragNDrop,
  exportCSVAssemblings,
  importCSVAssemblings
} from '@/src/store/modules/assemblings/slice';
import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import ModalCSV from '@/src/components/ModalCSV';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

import './listAssemblings.scss';

const { Option } = Select;
const { confirm } = Modal;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

function ListAssemblings() {
  const [data, setData] = useState([]);
  const [editDrag, setEditDrag] = useState(false);
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [CSVFile, setCSVFile] = useState([]);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [CSVFileFail, setCSVFileFail] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const { assemblings, count, query, isLoading } = useSelector(
    state => state.assemblings
  );

  const typeTable = 'ListAssemblings';

  const displayMode = {
    grid: 'Grid',
    lane: 'Corredor'
  };

  const columns = [
    {
      title: 'Imagem',
      align: 'center',
      dataIndex: 'attributes.image',
      render: (text, record) => (
        <Link
          to={`/colecoes/${record.id}`}
          style={{
            border: '0',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          <Avatar
            shape="square"
            size={60}
            src={text}
            icon="camera"
            className="img-placeholder"
          />
        </Link>
      )
    },
    {
      title: 'Nome',
      dataIndex: 'attributes.name',
      key: 'name',
      render: (text, record) => (
        <Link
          to={`/colecoes/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: 'Qtd. Produtos',
      align: 'center',
      key: 'products_count',
      dataIndex: 'attributes.products_count'
    },
    {
      title: 'Modo de Visualização',
      align: 'center',
      key: 'display_mode',
      dataIndex: 'attributes.display_mode',
      render: text => displayMode[text]
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Visibilidade</span>
          <Tooltip
            placement="top"
            overlayClassName="tooltip-table"
            title="Este controle torna a coleção visível no aplicativo"
          >
            <Icon type="info-circle" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      align: 'center',
      dataIndex: 'attributes',
      key: 'visibility',
      render: (text, record) => (
        <>
          {record.attributes.archived_at !== null ? (
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="É preciso desarquivar a coleção para alterar a visibilidade"
            >
              <Switch
                disabled={
                  record.attributes.archived_at !== null || !catalogPermission
                }
              />
            </Tooltip>
          ) : (
            <Switch
              defaultChecked={record.attributes.active}
              onChange={active =>
                dispatch(updateAssembling({ id: record.id, active }))
              }
              disabled={!catalogPermission}
            />
          )}
        </>
      )
    }
  ];
  if (editDrag) {
    const sortCol = {
      dataIndex: 'sort',
      width: 30,
      render: () =>
        editDrag ? <img src={dragIcon} alt="" style={{ fontSize: 20 }} /> : null
    };
    columns.splice(0, 0, sortCol);
  }

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setCSVFile([file]);
      setCSVFileFail(true);
      return false;
    }
    setCSVFileFail(false);
    setCSVFile([file]);
    return false;
  };

  const removeFile = () => {
    setUploadButtonDisabled(false);
    setTimeout(() => {
      setCSVFile([]);
    }, 400);
    setCSVFileFail(true);
  };

  const importCSV = useCallback(() => {
    dispatch(importCSVAssemblings(CSVFile[0]));
    setShowModalCSV(false);
  }, [dispatch, CSVFile]);

  const contentModalCSV = (
    <>
      <Row
        style={{
          borderBottom: '3px ##E2E2E2 solid',
          borderTop: '3px ##E2E2E2 solid'
        }}
      >
        <Col style={{ marginBottom: 10 }}>
          <p>
            Para começar a ordenação de coleções, sugerimos exportar a planilha
            modelo para editar e subir o arquivo novamente.
          </p>
        </Col>
        <Col style={{ marginBottom: 10 }}>
          <strong>
            Importante: sempre que uma planilha for atualizada, ela irá
            sobrescrever todas as informações existentes.
          </strong>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', marginRight: 5, color: '#2595D1' }}
            type="vertical-align-bottom"
          />
          <Button
            onClick={() => dispatch(exportCSVAssemblings())}
            type="link"
            className="order-timeline"
          >
            <strong style={{ textDecoration: 'underline', color: '#2595D1' }}>
              Exportar planilha de coleções
            </strong>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 25 }}>
          <p style={{ marginBottom: 0 }}>
            Caso já possua o arquivo de coleções que deseja atualizar, anexe a
            planilha abaixo.
          </p>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeFile}
          >
            <Button
              disabled={uploadButtonDisabled}
              className="ant-btn-secondary-copy"
            >
              Escolher Arquivo
            </Button>
          </Upload>
          {CSVFile.length === 0 ? (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          ) : null}
        </Col>
        {/* {CSVFileFail && (
          <p style={{ marginTop: 5, textAlign: 'center', color: '#d03232' }}>
            Apenas arquivos do tipo CSV são aceitos
          </p>
        )} */}
        {/* <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: '20px', color: '#000' }}
            type="question-circle"
          />
          <p style={{ marginBottom: 0, marginLeft: 8 }}>
            Não conhece as regras de importação?{' '}
            <Button type="link" className="order-timeline">
              <strong style={{ textDecoration: 'underline' }}>
                Clique aqui
              </strong>
            </Button>
          </p>
        </Col> */}
      </Row>
    </>
  );

  const getAssemblingsHandler = useCallback(
    urlQuery => {
      dispatch(getAssemblings({ ...urlQuery }));
    },
    [dispatch]
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [data]
  );

  const saveAllConfirm = () => {
    confirm({
      title: 'SALVAR ALTERAÇÕES',
      okText: 'Salvar',
      centered: true,
      content:
        'Tem certeza que deseja salvar as alterações? Todas as mudanças feitas serão espelhadas no aplicativo.',
      onOk() {
        dispatch(
          orderByDragNDrop({
            ids: data.map(item => Number(item.id))
          })
        );
        setEditDrag(false);
      },
      onCancel() {}
    });
  };

  const OrderOnApp = () => {
    if (assemblings.length > 1) {
      setEditDrag(true);
    } else {
      setEditDrag(false);
    }
  };

  const OrderOnAppCancel = useCallback(() => {
    if (assemblings !== data) {
      setData(assemblings);
    }
    setEditDrag(false);
  }, [assemblings, data]);

  useEffect(() => {
    if (CSVFile.length === 1) {
      setUploadButtonDisabled(true);
    } else {
      setUploadButtonDisabled(false);
    }
  }, [CSVFile.length]);

  useEffect(() => {
    setData(assemblings);
    // eslint-disable-next-line
  }, [assemblings]);

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getAssemblingsHandler({ query: params });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ModalCSV
        titleModal="CONFIGURAR COLEÇÕES POR PLANILHA"
        isVisible={showModalCSV}
        setIsVisibleModalCSV={() => setShowModalCSV(false)}
        components={contentModalCSV}
        buttonDisabled={CSVFileFail}
        CSVFileHandle={importCSV}
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <h1 style={{ marginBottom: 5 }}>Coleções</h1>
          <span>Subdivisões de cada coleção</span>
        </div>
        {catalogPermission && (
          <div>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={() => history.push('/colecoes/nova')}
            >
              Nova coleção
            </Button>
          </div>
        )}
      </Row>
      <section
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px'
        }}
      >
        <Card
          className="no-shadow"
          style={{ paddingTop: 16, minHeight: '48px' }}
        >
          <Searchbar
            placeholder="Buscar por nome da coleção"
            populateResult={getAssemblingsHandler}
            query={query}
            value={query.search}
          />
          <Row
            type="flex"
            align="middle"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col style={{ width: '18%', marginRight: 20 }}>
              <Select
                size="large"
                defaultValue="all"
                onChange={e =>
                  getAssemblingsHandler({
                    query: {
                      ...query,
                      filters: e === 'all' ? {} : { [e]: true }
                    }
                  })
                }
                style={{ width: '100%' }}
              >
                <Option value="all">Todos</Option>
                <Option value="active">Ativos</Option>
                <Option value="inactive">Inativos</Option>
              </Select>
            </Col>
            {catalogPermission && (
              <>
                {editDrag ? (
                  <>
                    <Col style={{ marginRight: 20 }}>
                      <Button onClick={() => saveAllConfirm()}>
                        Salvar no app
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="ant-btn-secondary-copy"
                        onClick={OrderOnAppCancel}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col style={{ marginRight: 20 }}>
                      {assemblings.length === 1 ? (
                        <Tooltip
                          placement="bottom"
                          title="Deve haver mais de uma coleção para fazer a ordenação"
                          overlayClassName="tooltip-buttons"
                        >
                          <Button
                            disabled={assemblings.length === 1}
                            onClick={OrderOnApp}
                          >
                            Ordenar no app
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button onClick={OrderOnApp}>Ordenar no app</Button>
                      )}
                    </Col>
                    <Col>
                      {assemblings.length === 1 ? (
                        <Tooltip
                          placement="bottom"
                          title="Deve haver mais de uma coleção para fazer a ordenação"
                          overlayClassName="tooltip-buttons"
                        >
                          <Button
                            className="ant-btn-secondary-copy"
                            disabled={assemblings.length === 1}
                            onClick={() => setShowModalCSV(true)}
                          >
                            Ordenar por planilha
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          className="ant-btn-secondary-copy"
                          onClick={() => setShowModalCSV(true)}
                        >
                          Ordenar por planilha
                        </Button>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>

          <DndProvider manager={manager.current.dragDropManager}>
            <Tables
              data={data}
              className="even-gray"
              columnsDefaults={columns}
              count={count}
              visiblePaginationUp
              visiblePaginationDown
              rowKey={record => record.id}
              contentToShow={typeTable}
              history={history}
              query={query}
              isLoading={isLoading}
              populateTables={getAssemblingsHandler}
              components={editDrag ? components : {}}
              onRow={
                editDrag
                  ? (record, index) => ({
                      index,
                      moveRow
                    })
                  : null
              }
            />
          </DndProvider>
        </Card>
      </section>
    </>
  );
}

export default ListAssemblings;
