import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Checkbox, Badge, Avatar, Icon, Modal, Input } from 'antd';

import SquareButton from '@/src/components/Expedition/Modal/SquareButton';
import Counter from '@/src/components/Expedition/Modal/Counter';

import {
  addToCollectedItems,
  removeFromCollectedItems,
  getProductScan,
  getUndoProductScan
} from '@/src/store/modules/expedition/slice';

import { useBreakpoint } from '@/src/context/BreakpointContext';

const crypto = require('crypto');

const CollectItem = ({
  id,
  sku,
  ean,
  productName,
  productImg,
  totalProductQty,
  scannedQty,
  orderId,
  isGift
}) => {
  const [orderItem, setOrderItem] = useState({});

  const [showAlertButton, setShowAlertButton] = useState(true);
  const [showManualCollectModal, setShowManualCollectModal] = useState(false);

  const [manualCollectCounter, setManualCollectCounter] = useState(0);
  const [manualCollectEan, setManualCollectEan] = useState('');

  const [ruptureCounter, setRuptureCounter] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const { collectedItems } = useSelector(state => state.expedition);

  const breakpoint = useBreakpoint();

  const dispatch = useDispatch();

  const scanInputRef = useCallback(node => {
    if (node !== null) {
      node.input.setAttribute('inputmode', 'none');
      node.input.focus();
    }
  }, []);

  const buildEncryptedData = (eanCode, quantity) => {
    const encryptedData = crypto.publicEncrypt(
      {
        key: process.env.REACT_APP_RSA_KEY,
        padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: 'sha256'
      },
      // We convert the data string to a buffer using `Buffer.from`
      Buffer.from(eanCode)
    );

    // The encrypted data is in the form of bytes, so we print it in base64 format
    // so that it's displayed in a more readable form
    // console.log('encypted data: ', encryptedData.toString('base64'));

    const payload = {
      data: {
        order_id: Number(orderId),
        barcode: encryptedData.toString('base64'),
        quantity,
        is_gift: isGift
      },
      ean: eanCode,
      rupture: orderItem.rupture
    };
    return payload;
  };

  useEffect(() => {
    if (id) {
      const payload = {
        orderId,
        orderItem: {
          itemId: Number(id),
          scanned: scannedQty,
          done: scannedQty === totalProductQty,
          rupture: 0,
          total: totalProductQty
        }
      };
      // console.log(payload);
      dispatch(addToCollectedItems(payload));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const hasItem = collectedItems.orderItems.find(
      item => Number(item.itemId) === Number(id)
    );
    if (hasItem) {
      setOrderItem(hasItem);
    } else {
      setOrderItem({
        itemId: Number(id),
        scanned: 0,
        done: false,
        rupture: 0,
        total: totalProductQty
      });
    }
    // eslint-disable-next-line
  }, [collectedItems]);

  const triggerAlert = () => setShowAlertButton(false);

  const closeButton = () => {
    setShowAlertButton(true);
    const payload = {
      orderId,
      orderItem: {
        ...orderItem,
        rupture: 0,
        done: orderItem.total === orderItem.scanned
      }
    };
    return dispatch(addToCollectedItems(payload));
  };

  const doneChoose = () => {
    setShowAlertButton(true);
    const payload = {
      orderId,
      orderItem: breakpoint.mobile
        ? {
            ...orderItem,
            rupture: ruptureCounter,
            done: orderItem.total === orderItem.scanned + ruptureCounter
          }
        : {
            ...orderItem,
            rupture: ruptureCounter,
            scanned:
              orderItem.total > ruptureCounter
                ? orderItem.total - ruptureCounter
                : 0,
            done: true
          }
    };
    return dispatch(addToCollectedItems(payload));
  };

  const handleCounterChange = count => setRuptureCounter(count);

  const handleManualCollect = () => {
    if (!manualCollectEan) return;
    const payload = buildEncryptedData(manualCollectEan, manualCollectCounter);
    dispatch(getProductScan(payload));
    setShowManualCollectModal(false);
    setManualCollectCounter(0);
    setManualCollectEan('');
  };

  const onCheckChange = value => {
    if (value.target.checked && id) {
      setBtnDisabled(true);
      const payload = {
        orderId,
        orderItem: {
          ...orderItem,
          scanned: orderItem.total - orderItem.rupture,
          done: true
        }
      };
      return dispatch(addToCollectedItems(payload));
    }
    if (value.target.checked === false) {
      setBtnDisabled(false);
      return dispatch(removeFromCollectedItems(Number(id)));
    }
    return true;
  };

  const handleUndoProductScan = () => {
    if (orderItem.scanned > 0) {
      const payload = buildEncryptedData(ean, orderItem.scanned);
      dispatch(getUndoProductScan(payload.data)); // já remove do collectedItems
    } else {
      dispatch(removeFromCollectedItems(Number(id)));
    }
  };

  return (
    <>
      <Modal
        title="OPÇÕES"
        destroyOnClose
        visible={showManualCollectModal}
        onCancel={() => {
          setShowManualCollectModal(false);
          setManualCollectCounter(0);
        }}
        onOk={handleManualCollect}
        okButtonProps={{
          disabled: manualCollectCounter === 0 || !manualCollectEan
        }}
        className="centered-footer"
      >
        <Row type="flex" justify="center" align="middle">
          <Col span={6}>
            <Badge
              style={{ width: 20, height: 20 }}
              count={
                isGift ? (
                  <Icon
                    type="gift"
                    theme="filled"
                    className="dispatch-gift dispatch-gift__collect"
                    style={{ top: 4 }}
                  />
                ) : (
                  0
                )
              }
            >
              <Avatar
                style={
                  breakpoint.lg
                    ? {}
                    : {
                        width: '100%',
                        height: 'auto'
                      }
                }
                shape="square"
                size={breakpoint.mobile ? '100%' : 55}
                src={productImg}
                icon="camera"
                className="img-placeholder"
              />
            </Badge>
          </Col>
          <Col
            span={18}
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span style={{ textAlign: 'left' }}>
              {sku ? `${sku} - ${productName}` : productName}
            </span>
            <span style={{ textAlign: 'left', fontSize: 12 }}>EAN: {ean}</span>
          </Col>
        </Row>
        <Row style={{ margin: '24px 0' }}>
          <Counter
            counterTitle="Quantas unidades quer coletar?"
            handleCounterChange={count => setManualCollectCounter(count)}
            maxQty={orderItem.total - (orderItem.scanned + orderItem.rupture)}
            className="counter-manual-collect"
          />
        </Row>
        <Row>
          <span
            style={{ display: 'block', textAlign: 'center', marginBottom: 8 }}
          >
            Faça a coleta digitando o EAN
          </span>
          <Input
            ref={scanInputRef}
            allowClear
            onChange={evt => setManualCollectEan(evt.target.value)}
          />
        </Row>
      </Modal>
      <div
        className="modal-table__row collect-table"
        style={{
          border:
            !showAlertButton || orderItem.rupture > 0 ? '2px solid #FDAB02' : ''
        }}
      >
        <Row justify="center" align="middle">
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Col
              span={breakpoint.lg ? 4 : 6}
              className={breakpoint.lg ? 'modal-table__col--responsive' : ''}
            >
              <Badge
                style={breakpoint.lg ? {} : { width: 20, height: 20 }}
                count={
                  isGift ? (
                    <Icon
                      type="gift"
                      theme="filled"
                      className={`dispatch-gift ${
                        breakpoint.mobile ? 'dispatch-gift__collect' : ''
                      }`}
                    />
                  ) : (
                    0
                  )
                }
              >
                <Avatar
                  style={
                    breakpoint.lg
                      ? {}
                      : {
                          width: '100%',
                          height: 'auto'
                        }
                  }
                  shape="square"
                  size={breakpoint.mobile ? '100%' : 55}
                  src={productImg}
                  icon="camera"
                  className="img-placeholder"
                />
              </Badge>
            </Col>
            {breakpoint.lg ? (
              <>
                <Col span={3}>
                  <span>{sku}</span>
                </Col>
                <Col span={5}>
                  <span>{ean}</span>
                </Col>
                <Col span={7} style={{ textAlign: 'left' }}>
                  <span>{productName}</span>
                </Col>
              </>
            ) : (
              <Col
                span={10}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span style={{ textAlign: 'left' }}>
                  {sku ? `${sku} - ${productName}` : productName}
                </span>
                <span style={{ textAlign: 'left', fontSize: 12 }}>
                  EAN: {ean}
                </span>
                <span style={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Coletado: {orderItem.scanned}/
                  {orderItem.total - orderItem.rupture}
                </span>
                {!showAlertButton && (
                  <Counter
                    counterTitle="Qtd em falta:"
                    handleCounterChange={handleCounterChange}
                    maxQty={orderItem.total - orderItem.scanned}
                  />
                )}
                {orderItem.rupture > 0 && (
                  <span
                    style={{
                      color: '#333',
                      fontWeight: 'bold',
                      textAlign: 'left'
                    }}
                  >
                    ({orderItem.rupture} em ruptura)
                  </span>
                )}
              </Col>
            )}
            {breakpoint.lg ? (
              <Col
                span={!showAlertButton || orderItem.rupture > 0 ? 0 : 2}
                className="modal-table__col--responsive"
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {showAlertButton && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <span
                        style={
                          orderItem.done && orderItem.rupture > 0
                            ? {
                                color: '#333',
                                fontWeight: 'bold'
                              }
                            : {}
                        }
                      >
                        {orderItem.total - orderItem.rupture}
                      </span>
                      {orderItem.rupture === 0 && (
                        <Checkbox onChange={onCheckChange} />
                      )}
                    </div>
                  )}
                  {orderItem.rupture > 0 && (
                    <span style={{ color: '#333', fontWeight: 'bold' }}>
                      ({orderItem.rupture} em ruptura)
                    </span>
                  )}
                </div>
                {!showAlertButton && (
                  <Counter
                    counterTitle="Qtd em falta"
                    handleCounterChange={handleCounterChange}
                    maxQty={orderItem.total}
                    className="counter-wrapper__collect--dk"
                  />
                )}
              </Col>
            ) : (
              <Col span={4}>
                {orderItem.scanned + orderItem.rupture === orderItem.total ? (
                  <Icon
                    type="check-circle"
                    theme="filled"
                    style={{ color: '#50ebc5', fontSize: 24 }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <SquareButton
                      onClick={() => setShowManualCollectModal(true)}
                      backgroundColor="#fff"
                      border="1px solid #333"
                      iconType="ellipsis"
                      size="32px"
                      iconSize="15px"
                      disabled={!showAlertButton}
                    />
                    <span style={{ fontSize: 9 }}>Opções</span>
                  </div>
                )}
              </Col>
            )}
            {orderItem.scanned + orderItem.rupture === orderItem.total &&
            breakpoint.mobile ? (
              <Col span={4}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <SquareButton
                    onClick={handleUndoProductScan}
                    backgroundColor="#fff"
                    border="1px solid #333"
                    iconType="rollback"
                    size="32px"
                    iconSize="15px"
                  />
                  <span style={{ fontSize: 9 }}>Desfazer</span>
                </div>
              </Col>
            ) : (
              <Col span={breakpoint.lg ? 3 : 4}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  {showAlertButton ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <SquareButton
                        onClick={triggerAlert}
                        backgroundColor="#FFF"
                        border="1px solid #333"
                        iconType="warning"
                        size="32px"
                        iconSize="15px"
                        disabled={btnDisabled}
                      />
                      {breakpoint.mobile && (
                        <span style={{ fontSize: 9 }}>Alerta</span>
                      )}
                    </div>
                  ) : (
                    <div className="modal-table__alert">
                      <SquareButton
                        onClick={doneChoose}
                        backgroundColor="#FDAB02"
                        border="none"
                        iconType="check"
                        size="32px"
                        iconSize="15px"
                        disabled={btnDisabled}
                      />
                      <SquareButton
                        onClick={closeButton}
                        backgroundColor="#fff"
                        border="1px solid #333"
                        iconType="close"
                        size="32px"
                        iconSize="15px"
                        disabled={btnDisabled}
                      />
                    </div>
                  )}
                </div>
              </Col>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

CollectItem.defaultProps = {
  id: '',
  sku: '',
  ean: '',
  productName: '',
  productImg: '',
  totalProductQty: 0,
  scannedQty: 0,
  orderId: null,
  isGift: false
};

CollectItem.propTypes = {
  id: PropTypes.string,
  sku: PropTypes.string,
  ean: PropTypes.string,
  productName: PropTypes.string,
  productImg: PropTypes.string,
  totalProductQty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scannedQty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isGift: PropTypes.bool
};

export default CollectItem;
