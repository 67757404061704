import { createSlice } from '@reduxjs/toolkit';
import * as modules from '@/src/globals/permissionsModules';

const initialState = {
  account: null,
  permissions: [],
  isAuthenticated: false,
  // bookmarks: [],
  loadingBookmarks: false
};

const accountSlice = createSlice({
  name: '@account',
  initialState,
  reducers: {
    setAccount(prevState, action) {
      const state = prevState;
      const { permissions, ...data } = action.payload;
      state.account = data;
      state.isAuthenticated = true;
      const userPermissions = modules.data.map(item => {
        const list = permissions?.map(permission => permission.fullName) || [];
        let kind;
        if (list.includes(item.permission)) {
          kind = 'edit';
        } else if (
          item.readBy.filter(permission => list.includes(permission)).length
        ) {
          kind = 'read';
        } else {
          kind = 'none';
        }
        return {
          name: item.permission,
          kind
        };
      });
      state.permissions = userPermissions;
      return state;
    },
    getAccount(state) {
      return state;
    },
    // getAccountBookmarks(state) {
    //   return state;
    // },
    // saveAccountBookmark(state) {
    //   return state;
    // },
    // removeAccountBookmark(state) {
    //   return state;
    // },
    resetAccountState(prevState) {
      const state = prevState;
      state.account = null;
      state.permissions = [];
      state.isAuthenticated = false;
      return state;
    }
    // setAccountBookmarks(prevState, action) {
    //   const state = prevState;
    //   state.bookmarks = action.payload;
    //   return state;
    // },
    // loadingAccountBookmark(prevState, action) {
    //   const state = prevState;
    //   state.loadingBookmarks = action.payload;
    //   return state;
    // }
  }
});

export const {
  getAccount,
  setAccount,
  resetAccountState
  // getAccountBookmarks,
  // setAccountBookmarks,
  // saveAccountBookmark,
  // removeAccountBookmark,
  // loadingAccountBookmark
} = accountSlice.actions;

export default accountSlice.reducer;
