import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse, Row, Icon, Select } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const EmbarksFilters = props => {
  const { setEmbarksFilters, embarksFilters } = props;

  const { query } = useSelector(state => state.shipping);

  const deliveryCompanies = useSelector(
    state => state.shipping.deliveryCompaniesList
  );

  useEffect(() => {
    setEmbarksFilters(query.filters);
    // eslint-disable-next-line
  }, [query.filters]);

  const handleFilters = (value, key, type, tag = false) => {
    setEmbarksFilters(prev => {
      const prevFilters = prev.filter(item => {
        if (!item.value || item.value === '') return false;
        if (item.key === key) {
          return item.type !== type;
        }
        return true;
      });

      let filters;
      if (!value || value === '' || value.length === 0) {
        filters = [...prevFilters];
      } else {
        filters = [
          ...prevFilters,
          {
            key,
            type,
            value: typeof value === 'object' ? value.join() : value,
            tag
          }
        ];
      }

      return filters;
    });
  };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'BY_DELIVERY_COMPANY':
        handleFilters(value, 'delivery_company_id', 'eq');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
        defaultActiveKey={1}
      >
        <Panel header="Transportadora" key="1">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Selecione as transportadoras"
              value={
                embarksFilters
                  .find(item => item.key === 'delivery_company_id')
                  ?.value.split(',') || []
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'BY_DELIVERY_COMPANY')}
            >
              {deliveryCompanies.map(item => {
                return <Option key={item.id}>{item.attributes.name}</Option>;
              })}
            </Select>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

EmbarksFilters.defaultProps = {
  embarksFilters: [],
  setEmbarksFilters: () => {}
};
EmbarksFilters.propTypes = {
  embarksFilters: PropTypes.arrayOf(PropTypes.shape({})),
  setEmbarksFilters: PropTypes.func
};

export default React.memo(EmbarksFilters);
