export default function formatStringToSearch(string) {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export const handleSelectSearch = (input, option) => {
  const { children, label } = option.props;
  if (label) return false;
  const search = input.toLowerCase();
  const str = typeof children === 'string' ? children : children.join('');
  const parsed = str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return parsed.includes(search) || str.toLowerCase().includes(search);
};
