import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  suspension: {},
  isLoading: false
};

const suspensionsSlice = createSlice({
  name: '@suspensionData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setSuspensions(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setSuspensionSingle(prevState, action) {
      const state = prevState;
      state.suspension = action.payload;
      return state;
    },
    getSuspensions(state) {
      return state;
    },
    getSuspensionSingle(state) {
      return state;
    },
    getUpdateSuspension(state) {
      return state;
    },
    getCreateSuspension(state) {
      return state;
    },
    getDeleteSuspension(state) {
      return state;
    }
  }
});

export const {
  setIsLoading,
  setSuspensions,
  setSuspensionSingle,
  getSuspensions,
  getSuspensionSingle,
  getUpdateSuspension,
  getCreateSuspension,
  getDeleteSuspension
} = suspensionsSlice.actions;

export default suspensionsSlice.reducer;
