import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import {
  setIsLoading,
  setZipcodes,
  getZipcodes
} from '@/src/store/modules/zipcodes/slice';

import { getZipcodes as getZipcodesRequest } from '@/src/api/zipcodes';

import { errorAlert } from '@/src/utils/notifications';

function* getZipcodesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getZipcodesRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/zipcodes' });
    yield put(setZipcodes(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar a lista de CEPs');
  }
}

function* regionsListSaga() {
  yield takeEvery(getZipcodes, getZipcodesSaga);
}

export default regionsListSaga;
