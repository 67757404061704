import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getBreedsList = payload => {
  const getUrl = queryBuilder(payload, '/breeds');
  return apiPortal.get(getUrl);
};

export const createBreed = payload => apiPortal.post('/breeds', payload);

export const getBreedData = id => apiPortal.get(`/breeds/${id}`);

export const updateBreed = payload =>
  apiPortal.put(`/breeds/${payload.id}`, payload);
