import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Select } from 'antd';
import build from 'redux-object';

import { getDeliverers, setSort } from '@/src/store/modules/deliverers/slice';
import { getHubs } from '@/src/store/modules/hubs-data/slice';

// import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import Tables from '@/src/components/Tables';

import Searchbar from '@/src/components/Searchbar/Searchbar';

import DeliverersMap from '@/src/components/Deliverers/DeliverersMap';

const { Option } = Select;

export const DeliverersPage = () => {
  const [hubsData, setHubsData] = useState([]);
  const [selectValue, setSelectValue] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const getDeliverersList = useCallback(
    urlQuery => {
      dispatch(getDeliverers({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    // dispatch(getHubs());
    dispatch(
      getHubs({
        query: {
          fields: [{ key: 'hubs', value: 'internal_id,name' }],
          sort: 'name',
          perPage: 500
        }
      })
    );
    // eslint-disable-next-line
  }, []);

  const { deliverers, query, count, isLoading, total } = useSelector(
    state => state.deliverers
  );

  const hubs = useSelector(state => state.hubsData.list);

  const { account } = useSelector(state => state.account);

  useEffect(() => {
    if (account.id && hubs.hubs) {
      // console.log(hubs);
      const allHubsArr = (
        (hubs.meta && hubs.meta['/hubs'].data) ||
        []
      ).map(object => build(hubs, 'hubs', object.id, { ignoreLinks: true }));
      const userHubsIds = account.hubs.split(',');
      const params = {
        sort: 'name',
        stats: [{ key: 'total', value: 'count' }]
      };
      if (account.hubs === 'all' || !account.hubs) {
        setHubsData(allHubsArr);
      } else {
        const userHubsArr = allHubsArr.filter(item =>
          userHubsIds.includes(item.internalId)
        );
        setHubsData(userHubsArr);
        if (userHubsArr.length === 1) setSelectValue([userHubsArr[0].id]);
        const hubsFilter = userHubsArr.map(item => item.id).join(',');
        params.filters = [{ key: 'by_hub', type: 'match', value: hubsFilter }];
      }
      getDeliverersList({ query: params });
    }
    // eslint-disable-next-line
  }, [hubs, account]);

  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={16}>
          <h1>Entregadores</h1>
        </Col>
        {/* <Col span={6} align="right">
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/entregadores/novo')}
          >
            Novo Entregador
          </Button>
        </Col> */}
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '24px 14px',
          marginTop: '20px'
        }}
      >
        <Row type="flex" justify="space-between">
          <DeliverersMap hubs={hubsData} allHubs={account.hubs === 'all'} />
        </Row>
        <Row type="flex" justify="space-between" style={{ marginTop: '20px' }}>
          <Col span={24}>
            <h2>Lista de Entregadores</h2>
          </Col>
          <Col span={6}>
            <Select
              size="large"
              placeholder="Hubs"
              className="select-search"
              mode="multiple"
              value={selectValue}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              onChange={value => {
                setSelectValue(value);
                const params = {
                  ...query,
                  filters: value.length
                    ? [{ key: 'by_hub', type: 'match', value }]
                    : []
                };
                getDeliverersList({ query: params });
              }}
            >
              {hubsData?.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name || '-'}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={18} className="deliverers-searchbar">
            <Searchbar
              placeholder="Buscar por nome"
              populateResult={getDeliverersList}
              query={query}
              dispatchModule
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Tables
              data={deliverers}
              contentToShow="deliverers"
              className="even-gray"
              rowKey={record => record.id}
              history={history}
              query={query}
              count={count}
              total={total}
              visiblePaginationUp
              visiblePaginationDown
              showCountFilters
              setSortState={setSortHandler}
              populateTables={getDeliverersList}
              isLoading={isLoading}
              showCountText={count === 1 ? 'entregador' : 'entregadores'}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeliverersPage;
