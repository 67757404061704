import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  departments: [],
  department_id: '',
  attributes: [],
  category_id: undefined,
  categories: [],
  editCategory: [],
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  count: 0,
  isLoading: false,
  updated: false,
  pageLoading: false,
  archived: false
};

const catalogCategoriesSlice = createSlice({
  name: '@catalog-categories',
  initialState,
  reducers: {
    setCatalogAttributes(prevState, action) {
      const state = prevState;
      state.attributes = action.payload.attributes;
      state.isLoading = false;
      state.category_id = action.payload.category_id;
      return state;
    },
    setDepartments(prevState, action) {
      const state = prevState;
      state.departments = action.payload.departments;
      state.isLoading = false;
      return state;
    },
    setDepartmentId(prevState, action) {
      const state = prevState;
      state.department_id = action.payload;
      return state;
    },
    setCreateCategory(prevState, action) {
      const state = prevState;
      state.created = action.payload;
      return state;
    },
    setUpdatedCategory(prevState, action) {
      const state = prevState;
      state.updated = action.payload;
      return state;
    },
    setArchivedAndUnarchived(prevState, action) {
      const state = prevState;
      state.archived = action.payload;
      return state;
    },
    setAllCategories(prevState, action) {
      const state = prevState;
      state.categories = action.payload.categories;
      state.count = action.payload.count;
      if (action.payload.query) {
        state.query = {
          page: Number(action.payload.query.page),
          perPage: action.payload.query.perPage || state.query.perPage,
          search: action.payload.query.search || '',
          sort: action.payload.query.sort || '',
          filters: action.payload.query.filters || {},
          url: `?page=${action.payload.query.page || '1'}&per_page=${action
            .payload.query.perPage || state.query.perPage}${
            action.payload.query.search !== ''
              ? `&q=${action.payload.query.search}`
              : ''
          }${
            action.payload.query.sort !== ''
              ? `&sort${action.payload.query.sort}`
              : ''
          }${
            action.payload.query.filters
              ? buildQueryStringFromObj(action.payload.query.filters)
              : ''
          }`
        };
      }
      state.isLoading = false;
      return state;
    },
    setEditCategory(prevState, action) {
      const state = prevState;
      state.editCategory = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setPageLoading(prevState, action) {
      const state = prevState;
      state.pageLoading = action.payload;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getCatalogAttributes(state) {
      return state;
    },
    getDepartments(state) {
      return state;
    },
    getCreateCategory(state) {
      return state;
    },
    getAllCategories(state) {
      return state;
    },
    inactiveOrActiveCategory(state) {
      return state;
    },
    getEdit(state) {
      return state;
    },
    getArchiveandUnarchive(state) {
      return state;
    },
    getOrderByDragNDrop(state) {
      return state;
    },
    getUpdateCategory(state) {
      return state;
    }
  }
});

export const {
  setCatalogAttributes,
  setDepartments,
  setDepartmentId,
  setCreateCategory,
  setAllCategories,
  setLoading,
  setEditCategory,
  setPageLoading,
  setPagination,
  setSort,
  setArchivedAndUnarchived,
  setUpdatedCategory,
  getCatalogAttributes,
  getDepartments,
  getCreateCategory,
  getAllCategories,
  inactiveOrActiveCategory,
  getEdit,
  getArchiveandUnarchive,
  getOrderByDragNDrop,
  getUpdateCategory
} = catalogCategoriesSlice.actions;

export default catalogCategoriesSlice.reducer;
