import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Row } from 'antd';

import { useBreakpoint } from '@/src/context/BreakpointContext';

const NotFoundPage = () => {
  const breakpoint = useBreakpoint();

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        flexDirection: 'column'
      }}
    >
      <Result
        status="404"
        title="Oops..."
        subTitle="A página que você procura não existe!"
      />
      {breakpoint.mobile && (
        <Link to="/expedicao">
          <h2
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
              padding: 24,
              marginBottom: 0
            }}
          >
            Acessar Expedição
          </h2>
        </Link>
      )}
    </Row>
  );
};

export default NotFoundPage;
