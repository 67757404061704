import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wallet: {},
  isLoadingWallet: true,
  walletTransactions: {
    data: [],
    page: 1,
    count: 0
  }
};

const walletSlice = createSlice({
  name: '@wallet',
  initialState,
  reducers: {
    setWallet(prevState, action) {
      const state = prevState;
      state.wallet = action.payload;
      return state;
    },
    setWalletTransactions(prevState, action) {
      const state = prevState;
      state.walletTransactions = {
        data: action.payload.data,
        page: action.payload.page,
        count: action.payload.meta.count
      };
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoadingWallet = action.payload;
      return state;
    },
    getWallet(state) {
      return state;
    },
    addToWallet(state) {
      return state;
    },
    removeFromWallet(state) {
      return state;
    }
  }
});

export const {
  setWallet,
  setWalletTransactions,
  setIsLoading,
  getWallet,
  addToWallet,
  removeFromWallet
} = walletSlice.actions;

export default walletSlice.reducer;
