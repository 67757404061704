import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getDistrictsData = payload => {
  const url = dispatchQueryBuilder(payload, '/districts');
  return apiDispatch.get(url);
};

// export const getDistrictSingleData = id => {
//   return apiDispatch.get(`/districts/${id}`);
// };

export const getCreateDistrictData = payload => {
  return apiDispatch.post('/districts', payload);
};
