import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setCatalogAttributes,
  setDepartments,
  setDepartmentId,
  setAllCategories,
  setPageLoading,
  setLoading,
  setEditCategory,
  setUpdatedCategory,
  getCatalogAttributes,
  getDepartments,
  getCreateCategory,
  getAllCategories,
  inactiveOrActiveCategory,
  getEdit,
  getArchiveandUnarchive,
  getOrderByDragNDrop,
  getUpdateCategory
} from '@/src/store/modules/catalog-categories/slice';

import {
  getCatalogAttributes as getCatalogAttributesRequest,
  getDepartments as getDepartmentsRequest,
  getCreateCategory as getCreateCategoryRequest,
  getAllCategories as getAllCategoriesRequest,
  inactiveOrActiveCategory as inactiveOrActiveCategoryRequest,
  getEdit as getEditRequest,
  archiveandUnarchive as archiveandUnarchiveRequest,
  orderByDragNDrop as orderByDragNDropRequest,
  updateCategory as updateCategoryRequest
} from '@/src/api/catalog-categories';

import {
  getAllCategoriesQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getCatalogAttributesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getCatalogAttributesRequest, payload);
    const { data } = response.data;
    const catalogAttributes = {
      attributes: data,
      category_id: payload
    };
    yield put(setCatalogAttributes(catalogAttributes));
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os atributos');
  }
}

function* getDepartmentsSaga() {
  try {
    const response = yield call(getDepartmentsRequest);
    yield put(
      setDepartments({
        departments: response.data.data
      })
    );
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os departamentos');
  }
}

function* getCreateCategorySaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(getCreateCategoryRequest, payload);
    yield put(setDepartmentId(payload.department_id));
    yield put(setLoading(false));
    yield put(push('/categorias'));
    successAlert(`Nova categoria criada`);
  } catch (err) {
    errorAlert(err, 'Não foi possível criar a categoria');
  }
}

function* getAllCategoriesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAllCategoriesRequest, payload);

    const count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    const routerState = yield select(getRouterState);
    yield put(
      setAllCategories({
        categories: response.data.data,
        count,
        query: routerState.location.pathname.includes('/categorias')
          ? payload.query
          : null
      })
    );
    const queryUrl = yield select(getAllCategoriesQueryUrlState);
    if (
      routerState.location.search !== queryUrl &&
      routerState.location.pathname.includes('/categorias')
    ) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar as categorias');
  }
}

function* inactiveOrActiveCategorySaga({ payload }) {
  try {
    yield call(inactiveOrActiveCategoryRequest, payload);
    successAlert('A visibilidade da categoria foi atualizada');
  } catch (err) {
    errorAlert(err, 'Não foi possível atualizar a visibilidade da categoria');
  }
}

function* getEditSaga({ payload }) {
  try {
    yield put(setPageLoading(true));
    const response = yield call(getEditRequest, payload);
    yield put(setEditCategory(response.data));
    yield put(setPageLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os dados da categoria');
  }
}

function* getArchiveandUnarchiveSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(archiveandUnarchiveRequest, payload);
    yield put(setLoading(false));
    yield put(push('/categorias'));
    const actionType =
      payload.type === 'archive' ? 'arquivada' : 'desarquivada';
    successAlert(`Categoria ${actionType}`);
  } catch (err) {
    const actionType = payload.type === 'archive' ? 'arquivar' : 'desarquivar';
    errorAlert(err, `Não foi possível ${actionType} a categoria`);
  }
}

function* getOrderByDragNDropSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(orderByDragNDropRequest, payload);
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível mover a categoria');
  }
}

function* updateCategorySaga({ payload }) {
  try {
    yield put(setUpdatedCategory(true));
    yield call(updateCategoryRequest, payload);
    yield put(setDepartmentId(payload.data.department_id.toString()));
    yield put(push('/categorias'));
    successAlert('Os dados da categoria foram atualizados');
    yield put(setUpdatedCategory(false));
  } catch (err) {
    yield put(setUpdatedCategory(false));
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

function* catalogCategoriesSaga() {
  yield takeEvery(getCatalogAttributes, getCatalogAttributesSaga);
  yield takeEvery(getDepartments, getDepartmentsSaga);
  yield takeEvery(getCreateCategory, getCreateCategorySaga);
  yield takeEvery(getAllCategories, getAllCategoriesSaga);
  yield takeEvery(inactiveOrActiveCategory, inactiveOrActiveCategorySaga);
  yield takeEvery(getEdit, getEditSaga);
  yield takeEvery('GET_EDIT_CATEGORY', getEditSaga);
  yield takeEvery(getArchiveandUnarchive, getArchiveandUnarchiveSaga);
  yield takeEvery(getOrderByDragNDrop, getOrderByDragNDropSaga);
  yield takeEvery(getUpdateCategory, updateCategorySaga);
}

export default catalogCategoriesSaga;
