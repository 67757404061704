import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Row, Col } from 'antd';

import { getRecoverPassword } from '@/src/store/modules/forgot-password/slice';

const RecoverPasswordForm = ({ form }) => {
  const { errMessage, isLoading } = useSelector(state => state.forgotPassword);

  const dispatch = useDispatch();

  const handleSubmit = evt => {
    evt.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const payload = {
          data: {
            type: 'users',
            attributes: {
              email: values.email
            }
          }
        };
        dispatch(getRecoverPassword(payload));
      }
      return false;
    });
  };

  const handleErrMessage = () => {
    if (errMessage.includes('email_not_registered'))
      return 'Usuário não encontrado';
    return errMessage;
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {errMessage && (
        <>
          <Alert message={handleErrMessage()} type="error" showIcon />
          <br />
        </>
      )}
      <Row type="flex" justify="center" align="middle">
        <Col span={24} lg={18}>
          <Form.Item hasFeedback>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Campo obrigatório'
                },
                { type: 'email', message: 'Digite um e-mail válido' }
              ]
            })(<Input placeholder="E-mail" type="email" />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              type="primary"
              loading={isLoading}
              htmlType="submit"
              data-test="login-form-submit"
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Row type="flex" justify="center">
        <Link to="/">Voltar para Login</Link>
      </Row>
    </Form>
  );
};

RecoverPasswordForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const WrappedRecoverPasswordForm = Form.create({
  name: 'forgot-password-form'
})(RecoverPasswordForm);

export default WrappedRecoverPasswordForm;
