import {
  takeEvery,
  takeLatest,
  put,
  call,
  delay,
  all
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';

import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  getShippingsData as getShippingsRequest,
  getShippingById as getShippingByIdRequest,
  getDeliveryCompaniesData as getDeliveryCompaniesRequest,
  getDeliverersData as getDeliverersRequest,
  getDeliveryKindsData as getDeliveryKindsRequest,
  addNewDeliveryCompany as addNewDeliveryCompanyRequest,
  deleteShipping as deleteShippingRequest,
  createShipping as createShippingRequest,
  updateShipping as updateShippingRequest
} from '@/src/api/shipping';

import {
  setShippings,
  setShipping,
  setReadyOrders,
  setDeliveryCompanies,
  setNewDeliveryCompany,
  setDeliveryKinds,
  setCount,
  setQuery,
  setCompanyId,
  getShippings,
  // getShippingById,
  getReadyOrders,
  updateShipping,
  createShipping,
  deleteShipping,
  getDeliveryCompanies,
  getDeliverers,
  getDeliveryKinds,
  setIsLoading
} from '@/src/store/modules/shipping/slice';

import { getOrdersData as getOrdersDataRequest } from '@/src/api/expedition';

import buildJsonData from '@/src/utils/buildJsonData';
import { ordersQuery, shippingsQuery } from '@/src/globals/shippingQueries';

function* getShippingsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getShippingsRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/embarks'
    });
    yield put(setQuery(payload.query));
    if (response.data.meta?.stats) {
      const { count } = response.data.meta?.stats.total;
      const countNum =
        typeof count === 'number' ? count : Object.keys(count).length;
      yield put(setCount(countNum));
    }
    yield put(setShippings(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar a lista de embarques');
  }
}

function* getReadyOrdersSaga({ payload }) {
  try {
    const { id, query } = payload;
    yield put(setIsLoading(true));
    const [shippingResponse, ordersResponse] = yield all([
      call(getShippingByIdRequest, { id, query }),
      call(getOrdersDataRequest, ordersQuery)
    ]);
    const normalizedOrders = normalize(ordersResponse.data, {
      endpoint: '/orders'
    });
    const ordersObj = buildJsonData(normalizedOrders, 'orders');
    const normalizedShipping = normalize(shippingResponse.data, {
      endpoint: '/embarks'
    });
    const shippingObj = buildJsonData(normalizedShipping, 'embarks')[0];
    yield put(setIsLoading(false));
    yield put(setShipping(shippingObj));
    const finalArray = ordersObj?.filter(item => item.hasDelivery === false);
    const pushDeliveriesToFinalArray = () => {
      return shippingObj?.deliveries?.forEach(item =>
        finalArray.push(item.order)
      );
    };
    pushDeliveriesToFinalArray();
    yield put(setReadyOrders(finalArray));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar os pedidos');
  }
}

// function* getShippingByIdSaga({ payload }) {
//   console.log(payload, 'payload');
//   try {
//     const { id, include } = payload;
//     yield put(setIsLoading(true));
//     const response = yield call(getShippingByIdRequest, { id, include });
//     const normalized = normalize(response.data, {
//       endpoint: '/embarks'
//     });
//     yield put(setShipping(normalized));
//     yield put(setIsLoading(false));
//   } catch (err) {
//     errorNotification(err);
//   }
// }

function* updateShippingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(updateShippingRequest, payload);
    yield put(push('/embarques'));
    yield delay(1000);
    successAlert('O embarque foi editado com sucesso');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível atualizar o embarque');
  }
}

function* createShippingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(createShippingRequest, payload);
    yield put(push('/embarques'));
    yield delay(1000);
    successAlert('O embarque foi criado com sucesso');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível criar o embarque');
  }
}

function* deleteShippingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(deleteShippingRequest, payload);
    yield put({ type: 'GET_SHIPPINGS_DATA', payload: shippingsQuery });
    successAlert('O embarque foi deletado com sucesso');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível deletar o embarque');
  }
}

function* getDeliveryCompaniesSaga() {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getDeliveryCompaniesRequest);
    yield put(setDeliveryCompanies(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar as transportadoras');
  }
}

function* addNewDeliveryCompanySaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(addNewDeliveryCompanyRequest, payload);
    yield put({ type: 'GET_DELIVERY_COMPANIES' });
    successAlert('A transportadora foi criada com sucesso');
    yield put(setCompanyId(response.data.data.id));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível adicionar uma nova transportadora');
  }
}

function* getDeliverersSaga() {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getDeliverersRequest);
    yield put(setDeliveryKinds(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar as entregas');
  }
}

function* getDeliveryKindsSaga() {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getDeliveryKindsRequest);
    yield put(setDeliveryKinds(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregas os tipos de fretes');
  }
}

function* customerSaga() {
  yield takeEvery(getShippings, getShippingsSaga);
  yield takeEvery(getReadyOrders, getReadyOrdersSaga);
  // yield takeEvery(getShippingById, getShippingByIdSaga);
  yield takeEvery(updateShipping, updateShippingSaga);
  yield takeEvery(createShipping, createShippingSaga);
  yield takeEvery(deleteShipping, deleteShippingSaga);
  yield takeEvery(getDeliveryCompanies, getDeliveryCompaniesSaga);
  yield takeEvery(setNewDeliveryCompany, addNewDeliveryCompanySaga);
  yield takeEvery(getDeliverers, getDeliverersSaga);
  yield takeEvery(getDeliveryKinds, getDeliveryKindsSaga);
  yield takeLatest('GET_SHIPPINGS_DATA', getShippingsSaga);
  yield takeLatest('GET_DELIVERY_COMPANIES', getDeliveryCompaniesSaga);
}

export default customerSaga;
