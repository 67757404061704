const REFERRAL_STATUS_INFO = {
  pending: {
    key: 0,
    translation: 'processando'
  },
  broken_contract: {
    key: 1,
    translation: 'cancelado'
  },
  canceled: {
    key: 2,
    translation: 'cancelado'
  },
  credited: {
    key: 3,
    translation: 'sucesso'
  },
  banned: {
    key: 4,
    translation: 'indicador banido'
  },
  withdrawn: {
    key: 5,
    translation: 'usado'
  },
  invalidated: {
    key: 5,
    translation: 'cancelado'
  },
  null: {
    key: null,
    translation: 'sem status'
  }
};

export default REFERRAL_STATUS_INFO;
