import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Form, Select } from 'antd';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';

import {
  getSkusPetzList
  // exportCSV
} from '@/src/store/modules/skus-petz/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

const { Option } = Select;
export const AllPriceTablesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { skusPetz, count, query, isLoading } = useSelector(
    state => state.skusPetz
  );

  const getSkusPetzHandler = useCallback(
    urlQuery => {
      dispatch(getSkusPetzList({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    // console.log('skuspetz', skusPetz);
    const params = buildQueryFromUrl(history);
    getSkusPetzHandler({ query: params });
    // eslint-disable-next-line
  }, []);

  // const handleExportCSV = () => {
  //   const params = buildQueryFromUrl(history);
  //   console.log(params)
  //   dispatch(exportCSV({ query: params }));
  // };

  const handleReferenceFilter = (value, type) => {
    // type: reference or product_reference
    if (value !== undefined || Object.values(query.filters).length > 0) {
      getSkusPetzHandler({
        query: {
          ...query,
          filters: { ...query.filters, [type]: value }
        }
      });
    }

    if (value === undefined && Object.values(query.filters).length < 1) {
      const params = buildQueryFromUrl(history);
      getSkusPetzHandler({ query: params });
    }
  };

  return (
    <>
      <Row
        type="flex"
        justify="space-between"
        style={{ marginBottom: '0.5em' }}
      >
        <Col>
          <h1 style={{ marginBottom: 0 }}>SKUs Petz</h1>
        </Col>
        <Col>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/skus-petz/novo')}
          >
            Novo SKU
          </Button>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <Row style={{ marginBottom: 20 }}>
          <Searchbar
            placeholder="Buscar código de referência"
            populateResult={getSkusPetzHandler}
            query={query}
            // value={priceTablesQuery.search}
          />
        </Row>
        <Form>
          <Row
            type="flex"
            align="middle"
            style={{ margin: '15px 0 0 0', alignItems: 'start' }}
          >
            <Col className="dropdown-column">
              <Form.Item>
                {/* {getFieldDecorator('category')( */}
                <Select
                  size="large"
                  placeholder="Cód. SKU Zee.Now"
                  value={query?.filters?.reference}
                  onChange={value => handleReferenceFilter(value, 'reference')}
                  style={{ width: '100%' }}
                  allowClear
                >
                  <Option value="">Todas as opções</Option>
                  <Option value="true">Com referência</Option>
                  <Option value="false">Sem referência</Option>
                </Select>
                {/* )} */}
              </Form.Item>
            </Col>
            <Col className="dropdown-column">
              <Form.Item>
                {/* {getFieldDecorator('category')( */}
                <Select
                  size="large"
                  placeholder="ID Produto Zee.Now"
                  onChange={value =>
                    handleReferenceFilter(value, 'product_reference')
                  }
                  style={{ width: '100%' }}
                  allowClear
                >
                  <Option value="">Todas as opções</Option>
                  <Option value="true">Com referência</Option>
                  <Option value="false">Sem referência</Option>
                </Select>
                {/* )} */}
              </Form.Item>
            </Col>
            {/* <Button
              onClick={handleExportCSV}
              size="large"
              className="ant-btn-secondary"
            >
              exportar csv
            </Button> */}
          </Row>
        </Form>

        <Tables
          data={skusPetz}
          className="even-gray"
          contentToShow="skusPetz"
          count={count}
          query={query}
          visiblePaginationUp
          visiblePaginationDown
          rowKey={record => record.id}
          history={history}
          isLoading={isLoading}
          populateTables={getSkusPetzHandler}
          rowClick={id => history.push(`/skus-petz/${id}`)}
        />
      </div>
    </>
  );
};

export default AllPriceTablesPage;
