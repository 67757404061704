import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Divider, Spin } from 'antd';

import CollectTable from '@/src/components/Expedition/Modal/CollectTable';
import DevolutionTable from '@/src/components/Expedition/Modal/DevolutionTable';
import Header from '@/src/components/Expedition/Modal/Header';
import PackTable from '@/src/components/Expedition/Modal/PackTable';
import ProgressBar from '@/src/components/Expedition/ProgressBar';

import { useBreakpoint } from '@/src/context/BreakpointContext';
import useDispatchModal from '@/src/hooks/useDispatchModal';

import {
  getOrderStatus,
  setOrderReturn,
  setModalVisibility
} from '@/src/store/modules/expedition/slice';

import formatCurrency from '@/src/utils/formatCurrency';
import { linesQuery, tableQuery } from '@/src/globals/dispatchQueries';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const OrderModal = ({ handleSlider, tableView }) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const [modalActionDisabled, setModalActionDisabled] = useState(true);

  const {
    collectedItems,
    packedItems,
    returnedItems,
    contentLoading,
    modalData: { step, visibility, order }
  } = useSelector(state => state.expedition);

  // console.log('Order', order);

  const { handleModalData } = useDispatchModal();

  const changeStatus = payload => {
    const { status } = payload.data.attributes;
    handleSlider(status);
    if (breakpoint.lg) dispatch(setModalVisibility(false));
    dispatch(getOrderStatus(payload));
  };

  const handleCancel = () => dispatch(setModalVisibility(false));

  const handleModalAction = () => {
    // console.log('handleModalAction');
    const defaultQuery = tableView ? tableQuery : linesQuery;
    const { include, fields, extra_fields } = defaultQuery.query;
    const query = {
      include,
      fields,
      extra_fields
    };

    // devolução/cancelamento
    if (order.hasReturnRequest || order.hasCancelationRequest) {
      const includedReturnedItems = returnedItems.orderItems.map(item => {
        return {
          type: 'returned_items',
          id: Number(item.itemId),
          attributes: {
            restock_quantity: Number(item.quantityStock),
            lost_quantity: Number(item.quantityLoss)
          }
        };
      });
      const payload = {
        data: {
          data: {
            type: 'order_requests',
            id: returnedItems.requestId,
            attributes: {
              request_resolved: true
            },
            relationships: {
              returned_items: {
                data: returnedItems.orderItems.map(item => {
                  return {
                    type: 'returned_items',
                    id: Number(item.itemId),
                    method: 'update'
                  };
                })
              }
            }
          },
          included: [...includedReturnedItems]
        },
        id: order.id,
        query,
        action: 'resolve'
      };
      const totalItems = order.orderItems
        ?.map(item => item.quantity)
        ?.reduce(reducer);
      const totalReturnedItems = returnedItems.orderItems
        ?.map(item => item.quantityLoss + item.quantityStock)
        ?.reduce(reducer);
      const hasItemsToDispatch = totalItems > totalReturnedItems;
      const showDifferentModal =
        order.status === 'started' || order.status === 'picked';
      if (hasItemsToDispatch && showDifferentModal && !tableView) {
        handleModalData(
          {
            ...order,
            hasReturnRequest: false,
            hasCancelationRequest: false
          },
          false
        );
        payload.query.include = `${include},order_items`;
        payload.query.fields = [
          ...payload.query.fields,
          {
            key: 'order_items',
            value: `id,quantity,sku_external_id,sku_code,sku_ean,sku_name,sku_image,gift,returned_quantity${
              order.status === 'started' ? ',scanned_quantity,order_id' : ''
            }`
          }
        ];
      } else {
        dispatch(setModalVisibility(false));
      }
      dispatch(setOrderReturn(payload));
    }

    // coleta
    else if (step === 'Coletar') {
      const hasRupture = collectedItems.orderItems.filter(
        item => item.rupture > 0
      );
      if (hasRupture.length > 0) {
        dispatch(setModalVisibility(false));
        const includedCollectedItems = hasRupture.map(item => {
          return {
            type: 'order_items',
            id: Number(item.itemId),
            attributes: {
              missing_quantity: Number(item.rupture)
            }
          };
        });
        const payload = {
          data: {
            type: 'orders',
            id: Number(order.id),
            attributes: {
              status: 'picked'
            },
            relationships: {
              order_items: {
                data: hasRupture.map(item => ({
                  type: 'order_items',
                  id: item.itemId,
                  method: 'update'
                }))
              }
            }
          },
          included: [...includedCollectedItems],
          query
        };
        changeStatus(payload);
      } else {
        handleModalData({ ...order, status: 'picked' });
        const payload = {
          data: {
            type: 'orders',
            id: Number(order.id),
            attributes: {
              status: 'picked'
            }
          },
          query
        };
        changeStatus(payload);
      }
    }

    // empacotar
    else if (step === 'Empacotar') {
      let includedVolItems = [];
      const filterEmptyVol = packedItems.volumes.filter(vol => {
        const volItems = vol.volumeItems.filter(item => item.productQty > 0);
        return volItems.length > 0;
      });
      const includedVol = filterEmptyVol.map(vol => {
        const volumeItems = vol.volumeItems
          .filter(item => item.productQty > 0)
          .map(item => {
            return {
              'temp-id': item.packedItemId,
              type: 'volume_items',
              attributes: {
                order_item_id: item.productId,
                quantity: item.productQty
              }
            };
          });
        includedVolItems =
          volumeItems.length > 0
            ? [...includedVolItems, ...volumeItems]
            : [...includedVolItems];
        return {
          'temp-id': vol.id,
          type: 'volumes',
          relationships: {
            volume_items: {
              data: vol.volumeItems
                .filter(item => item.productQty > 0)
                .map(volItem => ({
                  'temp-id': volItem.packedItemId,
                  method: 'create',
                  type: 'volume_items'
                }))
            },
            volume_kind: {
              data: {
                id: Number(vol.volumeKind),
                type: 'volume_kinds'
              }
            }
          }
        };
      });
      const volumes = packedItems.volumes.filter(
        vol => vol.volumeItems.filter(item => item.productQty > 0).length > 0
      );
      const payload = {
        data: {
          type: 'orders',
          id: Number(packedItems.orderId),
          attributes: { status: 'packed' },
          relationships: {
            volumes: {
              data: volumes.map(item => ({
                'temp-id': item.id,
                method: 'create',
                type: 'volumes'
              }))
            }
          }
        },
        included: [...includedVol, ...includedVolItems],
        query
      };
      dispatch(setModalVisibility(false));
      changeStatus(payload);
    }
  };

  return (
    <Modal
      className="ant-modal-expedition"
      title={
        <Header
          orderStatus={order.status}
          stepName={step}
          orderNumber={order.number}
          orderValue={formatCurrency(order.total, true)}
          orderAdress={`${order.addressDistrict} - ${order.addressStreet} ${order.addressNumber}`}
          orderCancelation={order.hasCancelationRequest}
        />
      }
      visible={visibility}
      onCancel={handleCancel}
      bodyStyle={{ backgroundColor: '#fff' }}
      width={1000}
      footer={
        <Button
          onClick={handleModalAction}
          className="ant-btn-primary"
          disabled={modalActionDisabled}
        >
          {order.hasReturnRequest || order.hasCancelationRequest
            ? 'Salvar'
            : step}
        </Button>
      }
    >
      <Spin spinning={contentLoading}>
        <Divider style={{ margin: '0', height: '2px', top: '0' }} />
        <div
          className={`modal-table__content
        ${
          order.hasReturnRequest || order.hasCancelationRequest
            ? 'modal-table__content--no-margin-mo'
            : ''
        }`}
        >
          {order.hasReturnRequest || order.hasCancelationRequest ? (
            <DevolutionTable setReturnBtnDisabled={setModalActionDisabled} />
          ) : (
            <>
              <ProgressBar stepName={step} progressModal />
              {step === 'Coletar' && (
                <CollectTable setCollectBtnDisabled={setModalActionDisabled} />
              )}
              {step === 'Empacotar' && (
                <PackTable setPackBtnDisabled={setModalActionDisabled} />
              )}
            </>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

OrderModal.defaultProps = {
  tableView: false
};

OrderModal.propTypes = {
  handleSlider: PropTypes.func.isRequired,
  tableView: PropTypes.bool
};

export default OrderModal;
