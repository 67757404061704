import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';

import UploadImage from '@/src/components/UploadImage/index';
import Tags from '@/src/components/Tags/index';

import { createBreed, updateBreed } from '@/src/store/modules/breeds/slice';
import {
  createAccessory,
  updateAccessory
} from '@/src/store/modules/accessories/slice';

// const { Option } = Select;

const ActionModal = props => {
  const dispatch = useDispatch();
  const { visible, setVisible, type, action, form, data, list } = props;
  const { validateFields, setFieldsValue } = form;
  const [uploadImage, setUploadImage] = useState('');
  const [tags, setTags] = useState([]);
  const [tagsAlert, setTagsAlert] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setUploadImage(data?.image && action === 'edit' ? data.image : '');
    setFieldsValue({
      en_name: action === 'edit' ? data.en_name : '',
      pt_name: action === 'edit' ? data.pt_name : ''
    });
    setTags(data?.tags?.length ? data.tags.map(item => item.name) : []);
    // eslint-disable-next-line
  }, [data]);

  const [withoutImage, setWithoutImage] = useState(false);
  const translate = {
    breeds: 'raça',
    accessories: 'produto'
  };
  const text = {
    add: {
      breeds: `Nova ${translate[type]}`,
      accessories: `Novo ${translate[type].slice(0, 7)}`
    },
    edit: {
      breeds: `Editar ${translate[type]}`,
      accessories: `Editar ${translate[type].slice(0, 7)}`
    }
  };

  const checkIfHasImage = () => {
    if (uploadImage.length === 0) {
      setWithoutImage(true);
      return false;
    }
    setWithoutImage(false);
    return true;
  };

  const handleSubmitWithAllValues = values => {
    // console.log(values, 'values');
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');
      let imageChange = '';
      if (uploadImage.includes('data:image')) {
        imageChange = removeData(uploadImage);
      }

      const payload = {
        pt_name: values.pt_name,
        en_name: values.en_name,
        full_name: values.en_name
      };

      if (imageChange !== '') payload.image = imageChange;

      if (type === 'accessories') payload.tags = tags;

      if (action === 'edit') payload.id = data.id;

      if (type === 'accessories' && action === 'edit') {
        dispatch(updateAccessory(payload));
      } else if (type === 'accessories' && action === 'add') {
        dispatch(createAccessory(payload));
      } else if (type === 'breeds' && action === 'edit') {
        dispatch(updateBreed(payload));
      } else if (type === 'breeds' && action === 'add') {
        dispatch(createBreed(payload));
      }
      setVisible(false);
      setUploadImage('');
      setFieldsValue({
        en_name: '',
        pt_name: ''
      });
      setTags([]);
    }
  };

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  const handleRemoveTag = value => {
    const tagSelected = list.filter(breed => {
      const breedAccessoryType = breed.breeds_accessory_types?.find(
        item => item.accessory_type_id === Number(id)
      );
      if (breedAccessoryType?.pt_size === value) return true;
      return false;
    });
    if (tagSelected.length) {
      setTagsAlert(true);
    } else {
      setTagsAlert(false);
      setTags(prev => prev.filter(item => item !== value));
    }
  };

  const handleAddTag = value => {
    setTags(prev => [...prev, value]);
  };

  return (
    <>
      <Modal
        title={<h2 style={{ textAlign: 'center' }}>{text[action][type]}</h2>}
        visible={visible}
        okText="Salvar"
        className="centered-footer modal-sizes"
        onCancel={() => {
          setVisible(false);
          setTagsAlert(false);
        }}
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
        width={400}
        bodyStyle={{ padding: '0 36px 24px' }}
        onOk={handleSubmit}
      >
        {action === 'add' && (
          <p style={{ textAlign: 'center' }}>
            {`Qual o nome ${
              translate[type] === 'raça'
                ? `da ${translate[type]}`
                : `do ${translate[type]}`
            } que deseja adicionar?`}
          </p>
        )}
        <Form>
          <Row type="flex" justify="space-between">
            <Col span={8}>
              <UploadImage
                isMiniature
                setUploadImage={setUploadImage}
                uploadImage={uploadImage}
                withoutImage={withoutImage}
                setWithoutImage={setWithoutImage}
              />
            </Col>
            <Col span={16}>
              <Form.Item>
                {form.getFieldDecorator('pt_name', {
                  initialValue: action === 'edit' ? data.pt_name : '',
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ]
                })(<Input placeholder="Nome em português" />)}
              </Form.Item>
              <Form.Item>
                {form.getFieldDecorator('en_name', {
                  initialValue: action === 'edit' ? data.en_name : '',
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ]
                })(<Input placeholder="Nome em inglês" />)}
              </Form.Item>
            </Col>
          </Row>
          {type === 'accessories' && (
            <>
              <p style={{ textAlign: 'center' }}>
                Insira abaixo as tags de tamanhos
              </p>
              <Tags
                removeTags={handleRemoveTag}
                addTags={handleAddTag}
                tags={tags}
                newTag
                closable
                preventClosing
              />
              {tagsAlert && (
                <span
                  style={{
                    color: '#f5222d',
                    textAlign: 'center',
                    display: 'block',
                    marginTop: 8
                  }}
                >
                  O tamanho que você deseja apagar está associado a uma ou mais
                  raças. Altere a sugestão de tamanho e salve a edição antes de
                  remover a tag.
                </span>
              )}
              {/* <Form.Item>
                {form.getFieldDecorator('sizes', {
                  // initialValue: data.tags ? data.tags
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, preencha este campo'
                    }
                  ]
                })(
                  <Select
                    mode="tags"
                    style={{ width: '100%', marginBottom: '10px' }}
                    placeholder="Escreva aqui todos os tamanhos disponíveis.
                    Ex.: PP, M, G."
                    filterOption={false}
                  />
                )}
              </Form.Item> */}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

ActionModal.defaultProps = {
  visible: false,
  setVisible: () => {},
  data: {},
  list: []
};

ActionModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  list: PropTypes.arrayOf(PropTypes.any),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const AddModalForm = Form.create({ name: 'add-modal' })(ActionModal);

export default AddModalForm;
