import { apiPortal } from '@/src/api/config';

export const createBreedAccessoryType = payload =>
  apiPortal.post('/breeds_accessory_types', payload);

export const updateBreedAccessoryType = payload =>
  apiPortal.put('/breeds_accessory_types', payload);

// export const deleteBreedAccessoryType = payload =>
//   apiPortal.delete(`/breeds_accessory_types/${payload}`);
