import { takeEvery, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  setCouponsInPromotion,
  setCouponsIsLoading,
  updateCouponsInPromotion,
  getCouponsInPromotion,
  createNewCoupon,
  archiveCoupons,
  editCoupon,
  generateCoupons,
  exportCSV
} from '@/src/store/modules/coupons/slice';
import {
  getCouponsInPromotion as getCouponsInPromotionRequest,
  createCoupon,
  archiveCoupons as archiveCouponsRequest,
  unarchiveCoupons as unarchiveCouponsRequest,
  editCoupon as editCouponRequest,
  generateCoupons as generateCouponsRequest
} from '@/src/api/promotions';

import { exportCSVCoupons as exportCSVCouponsRequest } from '@/src/api/export-file';

import { getCouponsState, getPromotionId } from '@/src/store/selectors';

import compareArrObjGetNewArr from '@/src/utils/compareArrObjReturnArr';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getCouponsInPromotionSaga({ payload }) {
  try {
    const promoId = yield select(getPromotionId);
    const addedPromoId = payload.promoId ? payload : { ...payload, promoId };
    yield put(setCouponsIsLoading(true));
    const response = yield call(getCouponsInPromotionRequest, addedPromoId);
    yield put(
      setCouponsInPromotion({
        coupons: response.data.data,
        query: payload.query,
        total: response.data.meta.count
      })
    );
  } catch (err) {
    yield put(setCouponsIsLoading(false));
    errorAlert(err);
  }
}

function* createCouponSaga({ payload }) {
  try {
    yield put(setCouponsIsLoading(true));
    const response = yield call(createCoupon, payload);
    yield put(
      setCouponsInPromotion({
        coupons: response.data.data,
        query: {
          page: 1,
          perPage: 20,
          search: ''
        },
        total: response.data.meta.count
      })
    );
    yield notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'O cupom foi criado.'
    });
    yield put(setCouponsIsLoading(false));
  } catch (err) {
    yield put(setCouponsIsLoading(false));
    errorAlert(err);
  }
}

function* archiveCouponsSaga({ payload }) {
  const { type } = payload;
  try {
    yield put(setCouponsIsLoading(true));
    const endpointType =
      type === 'archived' ? unarchiveCouponsRequest : archiveCouponsRequest;
    const response = yield call(endpointType, payload);
    const archivedCoupons = response.data.data;
    const couponsList = yield select(getCouponsState);
    const updatedList = yield compareArrObjGetNewArr(
      couponsList,
      archivedCoupons,
      'id'
    );
    yield put(
      updateCouponsInPromotion({
        coupons: updatedList
      })
    );
    const action = type === 'archived' ? 'desarquivados' : 'arquivados';
    successAlert(`Os cupons foram ${action}.`);
  } catch (err) {
    yield put(setCouponsIsLoading(false));
    errorAlert(err);
  }
}

function* editCouponSaga({ payload }) {
  try {
    yield put(setCouponsIsLoading(true));
    const response = yield call(editCouponRequest, payload);
    yield put(
      setCouponsInPromotion({
        coupons: response.data.data,
        query: {
          page: 1,
          perPage: 20,
          search: ''
        },
        total: response.data.meta.count
      })
    );
    yield notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'O cupom foi atualizado.'
    });
    yield put(setCouponsIsLoading(false));
  } catch (err) {
    yield put(setCouponsIsLoading(false));
    errorAlert(err);
  }
}

function* generateCouponsSaga({ payload }) {
  try {
    yield put(setCouponsIsLoading(true));
    const response = yield call(generateCouponsRequest, payload);
    yield put(
      setCouponsInPromotion({
        coupons: response.data.data,
        query: {
          page: 1,
          perPage: 20,
          search: ''
        },
        total: response.data.meta.count
      })
    );
    yield notification.success({
      message: 'Sucesso!',
      duration: 4,
      className: 'success',
      description: 'Os cupons foram gerados.'
    });
  } catch (err) {
    yield put(setCouponsIsLoading(false));
    errorAlert(err);
  }
}
export function* exportCSVSaga(action) {
  try {
    yield put(setCouponsIsLoading(true));
    const response = yield call(exportCSVCouponsRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setCouponsIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setCouponsIsLoading(false));
}

function* couponsSaga() {
  yield takeEvery(getCouponsInPromotion, getCouponsInPromotionSaga);
  yield takeEvery(createNewCoupon, createCouponSaga);
  yield takeEvery(archiveCoupons, archiveCouponsSaga);
  yield takeEvery(editCoupon, editCouponSaga);
  yield takeEvery(generateCoupons, generateCouponsSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
}

export default couponsSaga;
