import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  categoriesDogs: [],
  categoriesCats: [],
  isLoading: false
};

const categoriesSlice = createSlice({
  name: '@categories',
  initialState,
  reducers: {
    setCategories(prevState, action) {
      const state = prevState;
      const { categories, cats, dogs } = action.payload;
      state.categories = categories;
      state.categoriesCats = cats;
      state.categoriesDogs = dogs;
      return state;
    },
    getCategories(state) {
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    }
  }
});

export const {
  setCategories,
  getCategories,
  setIsLoading
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
