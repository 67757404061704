import jwt from 'jsonwebtoken';

export const TOKEN_KEY = 'USER_TOKEN';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const TOKEN_EXP_KEY = 'TOKEN_EXP';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
export const getTokenExpiration = () => localStorage.getItem(TOKEN_EXP_KEY);

export const decodeTokenExpiration = () => jwt.decode(getToken());

export const removeLocalStorageData = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(TOKEN_EXP_KEY);
  localStorage.removeItem('USER_ID');
};

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);
export const setRefreshToken = token =>
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
export const setTokenExpiration = date =>
  localStorage.setItem(TOKEN_EXP_KEY, date);
