import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Icon,
  Divider,
  TimePicker
} from 'antd';
import moment from 'moment';
import build from 'redux-object';
import { debounce } from 'throttle-debounce';

import { getCountries } from '@/src/store/modules/countries/slice';
import { getStates } from '@/src/store/modules/states/slice';
import { getCities } from '@/src/store/modules/cities/slice';
import { getDistricts } from '@/src/store/modules/districts/slice';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import ModalNewCityOrDistrict from '@/src/components/Hubs/Modals/NewCityOrDistrict';

const { Option } = Select;

const NewHub = ({ form }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [modalContent, setModalContent] = useState('');

  const dispatch = useDispatch();

  const countriesList = useSelector(state => state.countries.list);
  const statesList = useSelector(state => state.states.list);
  const citiesList = useSelector(state => state.cities.list);
  const { list: districtsList, isLoading: loadingDistricts } = useSelector(
    state => state.districts
  );

  useEffect(() => {
    dispatch(getCountries());
    dispatch(
      getStates({
        query: {
          perPage: 100,
          filters: [
            {
              key: 'country_id',
              type: 'eq',
              value: 1
            }
          ],
          fields: [{ key: 'states', value: 'code,id' }],
          sort: 'name'
        }
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const countriesObj = (
      (countriesList.meta && countriesList.meta['/countries'].data) ||
      []
    ).map(object =>
      build(countriesList, 'countries', object.id, { ignoreLinks: true })
    );
    setCountries(countriesObj);
    form.setFieldsValue({
      country: countriesObj[0]?.id || undefined
    });
    // eslint-disable-next-line
  }, [countriesList]);

  useEffect(() => {
    const statesObj = (
      (statesList.meta && statesList.meta['/states'].data) ||
      []
    ).map(object =>
      build(statesList, 'states', object.id, { ignoreLinks: true })
    );
    setStates(statesObj);
  }, [statesList]);

  useEffect(() => {
    const citiesObj = (
      (citiesList.meta && citiesList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesList, 'cities', object.id, { ignoreLinks: true })
    );
    setCities(citiesObj);
    setDistricts([]);
  }, [citiesList]);

  useEffect(() => {
    const districtsObj = (
      (districtsList.meta && districtsList.meta['/districts'].data) ||
      []
    ).map(object =>
      build(districtsList, 'districts', object.id, { ignoreLinks: true })
    );
    setDistricts(districtsObj);
  }, [districtsList]);

  const handleSelectCountry = value => {
    form.setFieldsValue({
      state: undefined,
      city: undefined,
      district: undefined
    });
    setStates([]);
    setCities([]);
    setDistricts([]);
    dispatch(
      getStates({
        query: {
          page: 1,
          perPage: 100,
          fields: [{ key: 'states', value: 'code,id' }],
          filters: [
            {
              key: 'country_id',
              type: 'eq',
              value
            }
          ],
          sort: 'name'
        }
      })
    );
  };

  const handleSelectState = value => {
    form.setFieldsValue({
      city: undefined,
      district: undefined
    });
    setCities([]);
    setDistricts([]);
    dispatch(
      getCities({
        query: {
          page: 1,
          perPage: 100,
          fields: [{ key: 'cities', value: 'name,id' }],
          filters: [
            {
              key: 'state_id',
              type: 'eq',
              value
            }
          ],
          sort: 'name'
        }
      })
    );
  };

  const handleSelectCity = value => {
    form.setFieldsValue({
      district: undefined
    });
    setDistricts([]);
    // dispatch(
    //   getDistricts({
    //     query: {
    //       page: 1,
    //       perPage: 100,
    //       // search: '',
    //       fields: [{ key: 'districts', value: 'name,id' }],
    //       filters: [
    //         {
    //           key: 'city_id',
    //           type: 'eq',
    //           value
    //         }
    //       ],
    //       sort: 'name'
    //     }
    //   })
    // );
  };

  const handleAddCity = () => {
    form.setFieldsValue({
      city: undefined
    });
    setModalContent('city');
  };

  const handleAddDistrict = () => {
    form.setFieldsValue({
      district: undefined
    });
    setModalContent('district');
  };

  const delayedDistrictQuery = useRef(
    debounce(200, value => {
      dispatch(
        getDistricts({
          query: {
            page: 1,
            perPage: 100,
            fields: [{ key: 'districts', value: 'name,id' }],
            filters: [
              {
                key: 'city_id',
                type: 'eq',
                value: form.getFieldValue('city')
              },
              {
                key: 'name',
                type: 'match',
                value
              }
            ],
            sort: 'name'
          }
        })
      );
    })
  ).current;

  const handleDistrictSearch = value => {
    if (value) {
      delayedDistrictQuery(value);
    } else {
      setDistricts([]);
    }
  };

  const format = 'HH:mm';

  return (
    <>
      {modalContent !== '' && (
        <ModalNewCityOrDistrict
          content={modalContent}
          setContent={setModalContent}
          formValues={form.getFieldsValue()}
          statesList={states}
          newHub
        />
      )}
      <Form>
        <p>Cadastre abaixo um novo hub</p>
        <Row type="flex" gutter={8}>
          <Col span={16}>
            <h3>Nome ERP</h3>
            <Form.Item>
              {form.getFieldDecorator('external_name', {
                rules: [
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]
              })(<Input placeholder="Nome" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <h3>ID da Filial</h3>
            <Form.Item>
              {form.getFieldDecorator('external_id')(
                <Input placeholder="ID" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h3>Endereço</h3>
          <span>(Bairro, Rua e Número opcionais)</span>
          <Row type="flex" gutter={8}>
            <Col span={5}>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator('country', {
                  rules: [
                    {
                      required: true
                    }
                  ]
                })(
                  <Select
                    placeholder="País"
                    onChange={handleSelectCountry}
                    dropdownMatchSelectWidth={false}
                  >
                    {countries.map(item => (
                      <Option key={item.code} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]
                })(
                  <Select
                    placeholder="UF"
                    onChange={handleSelectState}
                    disabled={!form.getFieldValue('country')}
                    dropdownMatchSelectWidth={false}
                    showSearch
                    filterOption={handleSelectSearch}
                  >
                    {states.map(item => (
                      <Option key={item.code} value={item.id}>
                        {item.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]
                })(
                  <Select
                    placeholder="Cidade"
                    showSearch
                    filterOption={handleSelectSearch}
                    onChange={handleSelectCity}
                    disabled={!form.getFieldValue('state')}
                    dropdownMatchSelectWidth={false}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '0' }} />
                        <Button
                          className="ant-btn-select"
                          onMouseDown={e => e.preventDefault()}
                          onClick={handleAddCity}
                        >
                          <Icon type="plus-circle" /> Cadastrar Cidade
                        </Button>
                      </div>
                    )}
                  >
                    {cities.map(item => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item style={{ marginBottom: '8px' }}>
                {form.getFieldDecorator(
                  'district',
                  {}
                )(
                  <Select
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    placeholder="Bairro"
                    showSearch
                    onSearch={handleDistrictSearch}
                    loading={loadingDistricts}
                    notFoundContent="Digite nome para buscar"
                    disabled={!form.getFieldValue('city')}
                    dropdownMatchSelectWidth={false}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '0' }} />
                        <Button
                          className="ant-btn-select"
                          onMouseDown={e => e.preventDefault()}
                          onClick={handleAddDistrict}
                        >
                          <Icon type="plus-circle" /> Cadastrar Bairro
                        </Button>
                      </div>
                    )}
                  >
                    {districts.map(item => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={8}>
            <Col span={5}>
              <Form.Item>
                {form.getFieldDecorator('zipcode', {
                  validateFirst: true,
                  validateTrigger: 'onSubmit',
                  rules: [
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    },
                    {
                      validator: (rule, value, cb) =>
                        value.length === 8 && /^\d+$/.test(value),
                      message: 'Deve conter 8 números'
                    }
                  ]
                })(<Input placeholder="CEP" />)}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item>
                {form.getFieldDecorator(
                  'street',
                  {}
                )(<Input placeholder="Rua" />)}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                {form.getFieldDecorator(
                  'number',
                  {}
                )(<Input placeholder="Número" />)}
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col span={12}>
            <h3>Horário de Funcionamento</h3>
            <Row type="flex" gutter={8}>
              <Col>
                <Form.Item>
                  {form.getFieldDecorator('opens_at', {
                    rules: [
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      }
                    ]
                  })(
                    <TimePicker
                      placeholder="Abertura"
                      defaultOpenValue={moment('00:00', format)}
                      format={format}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  {form.getFieldDecorator('closes_at', {
                    rules: [
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      }
                    ]
                  })(
                    <TimePicker
                      placeholder="Fechamento"
                      defaultOpenValue={moment('00:00', format)}
                      format={format}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <h3>Modelo de Hub</h3>
            <Form.Item>
              {form.getFieldDecorator('workflow', {
                rules: [
                  {
                    required: true
                  }
                ]
              })(
                <Select
                  placeholder="Selecione"
                  // onChange={handleSelectCountry}
                  dropdownMatchSelectWidth={false}
                >
                  <Option key="workflow-conventional" value="conventional">
                    Convencional
                  </Option>
                  <Option key="workflow-petz" value="petz">
                    Petz
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

NewHub.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
  // states: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const NewHubForm = Form.create({ name: 'new-hub-form' })(NewHub);

export default NewHubForm;
