import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Card, Checkbox, Button, Input, Icon } from 'antd';
import Add from '@/src/assets/images/add.svg';

import {
  // getAttribute,
  getUpdateAttribute
} from '@/src/store/modules/attributes/slice';

const AttributeCardSingle = ({
  property,
  productProperties,
  handleCheckbox,
  editPermission
}) => {
  // console.log(property);
  // console.log(productProperties);
  const dispatch = useDispatch();
  // const attributeValues = property.attributes.allowed_values;
  // const { attributeData } = useSelector(state => state.attributes);
  const { attributeData } = useSelector(state => state.attributes);

  const [attributeValues, setAttributesValues] = useState([]);
  const [showNewValue, setShowNewValue] = useState(false);
  const inputNewValue = useRef();

  useEffect(() => {
    setAttributesValues(property.attributes.allowed_values);
  }, [property.attributes.allowed_values]);
  // useEffect(() => {
  //   if (property.id) dispatch(getAttribute(property.id));
  // }, [dispatch, property.id]);

  useEffect(() => {
    const { attributes } = attributeData;
    if (Number(property?.id) === Number(attributes?.id))
      setAttributesValues(attributes.allowed_values);
    // eslint-disable-next-line
  }, [attributeData]);

  const handleNewItem = () => {
    const newValues = [];
    const label = inputNewValue.current.state.value;

    attributeValues.forEach(attribute => {
      newValues.push(attribute?.value);
    });

    newValues.push(label);
    inputNewValue.current.handleReset();

    dispatch(
      getUpdateAttribute({
        id: property?.id,
        data: {
          name: property?.attributes?.name,
          values: newValues
        },
        callFromAttributeSingleCard: true
      })
    );
    setShowNewValue(false);
  };

  const renderValues = renderItem => {
    const { id, value } = renderItem;
    let selectID = id;
    // https://github.com/facebook/react/issues/13477
    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
    const isSelected = productProperties.some(item => {
      if (item.id === renderItem.id) {
        if (item.selected_id) {
          selectID = item.id;
          return true;
        }
      }
      return false;
    });

    return (
      <Row
        key={`${id}-${isSelected ? 'sel' : 'notsel'}-${
          productProperties.length
        }`}
      >
        <Checkbox
          defaultChecked={isSelected}
          onChange={() => handleCheckbox(isSelected, selectID)}
          style={{ margin: '5px 0' }}
          disabled={!editPermission}
        >
          {value || `Attributo ${id}`}
        </Checkbox>
      </Row>
    );
  };

  if (!attributeValues)
    return (
      <Card
        title={property?.name}
        size="small"
        className="no-shadow normal-title attribute-card"
      >
        Hello
      </Card>
    );

  return (
    <Card
      title={property?.attributes?.name}
      size="small"
      className="no-shadow normal-title attribute-card"
    >
      {attributeValues?.map(item => renderValues(item))}
      {editPermission && (
        <Row style={{ margin: '5px 0' }}>
          {showNewValue ? (
            <div className="new-value-attributes">
              <Input
                placeholder="Novo Valor"
                ref={inputNewValue}
                onPressEnter={() => handleNewItem(property)}
              />
              <Button
                className="add-new-attribute"
                onClick={() => handleNewItem(property)}
              >
                <Icon type="check" />
              </Button>
              <Button
                className="cancel-new-attribute"
                onClick={() => setShowNewValue(false)}
              >
                <Icon type="close" />
              </Button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  background: 'none',
                  fontWeight: 'bold',
                  color: 'rgb(0, 0, 0)',
                  padding: '0',
                  cursor: 'pointer'
                }}
                type="button"
                onClick={() => setShowNewValue(true)}
              >
                <img style={{ marginRight: 6, width: 16 }} src={Add} alt="+" />
                <span>Novo Valor</span>
              </button>
            </div>
          )}
        </Row>
      )}
    </Card>
  );
};

AttributeCardSingle.defaultProps = {
  property: [],
  productProperties: [],
  handleCheckbox: () => {}
};

AttributeCardSingle.propTypes = {
  property: PropTypes.oneOfType([PropTypes.object]),
  productProperties: PropTypes.oneOfType([PropTypes.array]),
  handleCheckbox: PropTypes.func,
  editPermission: PropTypes.bool.isRequired
};

export default AttributeCardSingle;
