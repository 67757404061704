import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getStatesData = payload => {
  const url = dispatchQueryBuilder(payload, '/states');
  return apiDispatch.get(url);
};

export const getStatesSingleData = payload => {
  const url = dispatchQueryBuilder(payload, `/states/${payload.id}`);
  return apiDispatch.get(url);
};
