import { takeEvery, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import moment from '@/src/services/moment';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  getOrder as getOrderRequest,
  getOrderTimeline as getOrderTimelineRequest,
  cancelOrder as cancelOrderRequest,
  returnOrderItems as returnOrderItemsRequest,
  getPushNotification as getPushNotificationRequest,
  fraudOrder as fraudOrderRequest,
  chargebackOrder as chargebackOrderRequest
} from '@/src/api/orders';

// import getTripRequest from '@/src/api/embarks';
import { getShippingById as getShippingRequest } from '@/src/api/shipping';

import {
  setOrderLoading,
  setOrderResumeLoading,
  setOrderDetailsLoading,
  setOrder,
  setOrderTimeline,
  setOrderTrip,
  returnItens,
  cancelOrder,
  getOrderTimeline,
  getOrder,
  getPushNotification,
  fraudOrder,
  setFraudOrder,
  chargeBackFraudOrder
} from '@/src/store/modules/order-details/slice';

import { setTimelineEvents } from '@/src/store/modules/timeline-events/slice';

import {
  getOrderStatusState,
  getOrderTimelineState
} from '@/src/store/selectors';

import { errorAlert } from '@/src/utils/notifications';
import bugsnag from '@/src/services/bugsnag';

import { setTags } from '@/src/store/modules/tags/slice';

// const prepareTripObj = (trip, orderId) => {
//   console.log(trip);
//   const deliveriesInOrder = trip.data.data.attributes.ordered_deliveries;
//   const deliveriesInTrip = trip.data.data.attributes.deliveries.data;
//   const orderedDeliveries = deliveriesInOrder.map(delivery => {
//     let obj = {};
//     deliveriesInTrip.forEach(d => {
//       if (Number(d.id) === delivery) obj = d;
//     });
//     return obj;
//   });
//   const cleanOrdered = [];
//   for (let i = 0; orderedDeliveries.length > i; i += 1) {
//     cleanOrdered.push(orderedDeliveries[i]);
//     if (orderedDeliveries[i].attributes.order_id === orderId) break;
//   }
//   return cleanOrdered;
// };

function* getOrderDetailsSaga({ payload }) {
  try {
    yield put(setOrderLoading(true));
    const response = yield call(getOrderRequest, payload);
    const order = response.data;
    const tag = response.data.included.filter(
      included => included.type === 'tag'
    );
    const tags = tag.map(tagName => tagName.attributes.name);
    yield put(setTags(tags));
    yield put(setOrder({ order }));
    if (order.data.attributes.embark_id) {
      const tripParams = {
        id: order.data.attributes.embark_id,
        query: {
          include:
            'deliverer,deliveries,deliveries.order,deliveries.order.customer,deliveries.order.address',
          fields: [
            {
              key: 'deliverers',
              value: 'deliveries_in_progress'
            },
            {
              key: 'deliveries',
              value: 'order,id,status'
            },
            {
              key: 'orders',
              value: 'number,storefront_id'
            },
            {
              key: 'customers',
              value: 'name'
            },
            {
              key: 'addresses',
              value: 'street,number,complement,district,city'
            }
          ]
        }
      };
      const tripResponse = yield call(getShippingRequest, tripParams);
      const normalized = normalize(tripResponse.data, {
        endpoint: '/embarks'
      });
      const tripArr = (
        (normalized.meta && normalized.meta['/embarks'].data) ||
        []
      ).map(object =>
        build(normalized, 'embarks', object.id, { ignoreLinks: true })
      );
      yield put(
        // setOrderTrip(prepareTripObj(tripArr[0], order.data.attributes.id))
        setOrderTrip(tripArr[0])
      );
    }
    yield put(setOrderLoading(false));
  } catch (err) {
    yield put(setOrderLoading(false));
    errorAlert(err);
  }
}

function* getOrderTimelineSaga({ payload }) {
  try {
    const response = yield call(getOrderTimelineRequest, payload);
    const timelineObject = response.data.data;
    const stateOrderStatus = yield select(getOrderStatusState);
    const stateOrderTimeline = yield select(getOrderTimelineState);
    const orderStatus = {
      ...stateOrderStatus
    };
    let orderTimeline;
    let currentStatus;
    if (timelineObject.length > 0) {
      orderTimeline = timelineObject
        .filter(item => item.attributes.event === 'erp_status_changed')
        .sort((a, b) => a.id - b.id)
        .map(item => {
          const {
            attributes: { id, value, created_at }
          } = item;
          const timelineItem = { id, status: value, timestamp: created_at };
          const statusValue = value;
          // const isTrue = true;
          // if (value === 'unpicked') {
          //   statusValue = 'picked';
          //   isTrue = false;
          // } else if (value === 'unpacked') {
          //   statusValue = 'packed';
          //   isTrue = false;
          // } else if (value === 'undo_label_generated') {
          //   statusValue = 'label_generated';
          //   isTrue = false;
          // } else {
          //   statusValue = value;
          // }
          orderStatus[statusValue] = [true, moment(created_at).format('HH:mm')];
          return timelineItem;
        });
      currentStatus = orderTimeline[orderTimeline.length - 1].status;
    } else {
      orderTimeline = [{}];
      currentStatus = '';
    }
    // if (currentStatus === 'unpicked') {
    //   currentStatus = 'confirmed';
    // } else if (currentStatus === 'unpacked') {
    //   currentStatus = 'picked';
    // } else if (currentStatus === 'undo_label_generated') {
    //   currentStatus = 'nfe_issued';
    // }

    orderStatus.currentStatus = currentStatus;
    if (stateOrderTimeline.length !== orderTimeline.length)
      yield getOrderDetailsSaga({ payload: payload.object_id });
    yield put(setOrderTimeline({ orderTimeline, orderStatus }));
  } catch (err) {
    bugsnag.notify(err);
  }
}

function* cancelOrderSaga({ payload }) {
  try {
    yield put(setOrderResumeLoading(true));
    const response = yield call(cancelOrderRequest, payload);
    const order = response.data;
    yield put(setOrder({ order }));
    yield put(setOrderResumeLoading(false));
  } catch (err) {
    yield put(setOrderResumeLoading(false));
    errorAlert(err);
  }
}

function* returnItensSaga({ payload }) {
  try {
    yield put(setOrderResumeLoading(true));
    const response = yield call(returnOrderItemsRequest, payload);
    const order = response.data;
    yield put(setOrder({ order }));
    const timelineEvents = order.included.filter(
      item => item.type === 'timeline_event'
    );
    if (timelineEvents.length) {
      yield put(setTimelineEvents(timelineEvents));
    }
    notification.success({
      message: `Sucesso!`,
      duration: 4,
      className: 'success',
      description: 'Devolução realizada'
    });
    yield put(setOrderResumeLoading(false));
  } catch (err) {
    yield put(setOrderResumeLoading(false));
    errorAlert(err);
  }
}

function* pushNotificationSaga({ payload }) {
  try {
    yield call(getPushNotificationRequest, payload);
    if (payload.type === 'push') {
      notification.success({
        message: `Sucesso!`,
        duration: 4,
        className: 'success',
        description: 'Notificação push enviada!'
      });
    } else {
      notification.success({
        message: `Sucesso!`,
        duration: 4,
        className: 'success',
        description: 'Notificação enviada para Whatsapp!'
      });
    }
  } catch (err) {
    errorAlert(err);
  }
}

function* fraudOrderSaga({ payload }) {
  try {
    yield put(setOrderDetailsLoading(true));
    const response = yield call(fraudOrderRequest, payload);
    yield put(
      setFraudOrder({
        fraud: true,
        fraud_type: response.data.data.attributes.fraud_type
      })
    );
    yield put(setOrderDetailsLoading(false));
  } catch (err) {
    yield put(setOrderDetailsLoading(false));
    errorAlert(err);
  }
}

function* chargeBackFraudOrderSaga({ payload }) {
  try {
    yield put(setOrderDetailsLoading(true));
    const response = yield call(chargebackOrderRequest, payload);
    yield put(
      setFraudOrder({
        fraud: true,
        fraud_type: response.data.data.attributes.fraud_type
      })
    );
    yield put(setOrderDetailsLoading(false));
  } catch (err) {
    yield put(setOrderDetailsLoading(false));
    errorAlert(err);
  }
}

function* orderDetailsSaga() {
  yield takeEvery(getOrder, getOrderDetailsSaga);
  yield takeEvery(getOrderTimeline, getOrderTimelineSaga);
  yield takeEvery(cancelOrder, cancelOrderSaga);
  yield takeEvery(returnItens, returnItensSaga);
  yield takeEvery(getPushNotification, pushNotificationSaga);
  yield takeEvery(fraudOrder, fraudOrderSaga);
  yield takeEvery(chargeBackFraudOrder, chargeBackFraudOrderSaga);
}

export default orderDetailsSaga;
