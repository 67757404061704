import React from 'react';
import moment from '@/src/services/moment';

const logUser = [
  {
    title: 'Usuário',
    dataIndex: 'attributes.auditor_name',
    key: 'auditor_name',
    sorter: true,
    render: (text, record) => (
      <>
        <strong>{text}</strong>
        <br />
        {record?.attributes?.auditor_email}
      </>
    )
  },
  {
    title: 'Ação',
    dataIndex: 'attributes.action',
    key: 'action',
    sorter: true
  },
  {
    title: 'Valor',
    dataIndex: 'attributes.last_value',
    key: 'last_value',
    sorter: true,
    render: (text, record) => (
      <span>
        <span>
          {record?.attributes?.column_name}: <strong>{text}</strong> para
          <br />
        </span>
        <span>
          {record?.attributes?.column_name}:{' '}
          <strong>{record?.attributes?.new_value}</strong>
        </span>
      </span>
    )
  },
  {
    title: 'Data',
    dataIndex: 'attributes.created_at',
    key: 'created_at',
    sorter: true,
    render: date => {
      return date ? moment(date).format('DD/MM/YYYY - HH:mm:ss') : '-';
    }
  }
];

export default logUser;
