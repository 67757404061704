import React from 'react';
import { Card, Tabs, Spin } from 'antd';
import PropTypes from 'prop-types';

import {
  ComposedChart,
  Line,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

import moment from '@/src/services/moment';
import formatCurrency from '@/src/utils/formatCurrency';
import valueFormatterK from '@/src/utils/valueFormatterK';

const { TabPane } = Tabs;

const ReferralMetrics = props => {
  const { graphs, isLoading } = props;

  const itemStyle = {
    color: '#333333!important'
  };

  const labelStyle = {
    fontWeight: 'bold'
  };

  return (
    <Tabs type="card" className="card-tabs">
      <TabPane tab="Receita + Conversão" key="revenue">
        <Card className="light-shadow no-padding pb-5">
          {isLoading ? (
            <Spin
              style={{
                background: 'rgba(255, 255, 255, 0.8)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          ) : null}
          <ResponsiveContainer width="90%" height={200}>
            <ComposedChart data={graphs.data}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="date"
                tickFormatter={date => moment(date).format('DD MMM')}
                minTickGap={80}
              />
              <YAxis
                dataKey="revenue"
                axisLine={false}
                minTickGap={10}
                tickFormatter={valueFormatterK}
                yAxisId="left"
              />
              <YAxis
                axisLine={false}
                yAxisId="right"
                orientation="right"
                dataKey="conversion"
              />
              <Tooltip
                formatter={(value, name) => {
                  if (name === 'revenue')
                    return [`${formatCurrency(value)}`, 'Receita '];
                  return [value, 'Conversões '];
                }}
                labelFormatter={date => moment(date).format('LL')}
                itemStyle={itemStyle}
                labelStyle={labelStyle}
              />
              <Legend
                formatter={value => {
                  if (value === 'revenue') return 'Receita';
                  return 'Conversão';
                }}
              />
              <Bar dataKey="revenue" yAxisId="left" fill="#7cebfc" />
              <Line
                type="monotone"
                yAxisId="right"
                dataKey="conversion"
                stroke="#fdab02"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </TabPane>
    </Tabs>
  );
};

ReferralMetrics.defaultProps = {
  graphs: {
    data: []
  },
  isLoading: false
};

ReferralMetrics.propTypes = {
  graphs: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isLoading: PropTypes.bool
};

export default ReferralMetrics;
