import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  DatePicker,
  Row,
  Col,
  Icon,
  Radio,
  Select,
  Form,
  Switch,
  InputNumber
} from 'antd';

import { getPetBreeds } from '@/src/store/modules/pet-breeds/slice';
// import { getTags } from '@/src/store/modules/tags/slice';

import formatCurrency from '@/src/utils/formatCurrency';
import getNumberFromString from '@/src/utils/getNumberFromString';
import moment from '@/src/services/moment';

const { Panel } = Collapse;
const { Option } = Select;

const CustomersFilters = props => {
  const { customersFilters, setCustomersFilters } = props;

  const [breedCat, setBreedCat] = useState(false);
  const [breedDog, setBreedDog] = useState(false);

  const { query } = useSelector(state => state.customers);
  const { catBreeds, dogBreeds } = useSelector(state => state.petbreeds);
  // const tagsToFilters = useSelector(state => state.tags.tagsFilters);

  const dispatch = useDispatch();

  const populatePets = () => dispatch(getPetBreeds());
  // const populateTags = () => dispatch(getTags({ taggable_type: 'customer' }));

  const mounthOptions = [
    { mounth: 'Janeiro', value: '1' },
    { mounth: 'Fevereiro', value: '2' },
    { mounth: 'Março', value: '3' },
    { mounth: 'Abril', value: '4' },
    { mounth: 'Maio', value: '5' },
    { mounth: 'Junho', value: '6' },
    { mounth: 'Julho', value: '7' },
    { mounth: 'Agosto', value: '8' },
    { mounth: 'Setembro', value: '9' },
    { mounth: 'Outubro', value: '10' },
    { mounth: 'Novembro', value: '11' },
    { mounth: 'Dezembro', value: '12' }
  ];

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'MAX_VALUE':
        setCustomersFilters({
          ...customersFilters,
          max_converted_value: value
        });
        break;
      case 'MIN_VALUE':
        setCustomersFilters({
          ...customersFilters,
          min_converted_value: value
        });
        break;
      case 'QTD_MAX_VALUE':
        setCustomersFilters({
          ...customersFilters,
          more_orders_than: value
        });
        break;
      case 'QTD_MIN_VALUE':
        setCustomersFilters({
          ...customersFilters,
          less_orders_than: value
        });
        break;
      case 'MALE_GENDER':
        setCustomersFilters({
          ...customersFilters,
          by_gender: value
        });
        break;
      case 'FEMALE_GENDER':
        setCustomersFilters({
          ...customersFilters,
          by_gender: value
        });
        break;
      case 'START_DATE':
        setCustomersFilters({
          ...customersFilters,
          start_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'END_DATE':
        setCustomersFilters({
          ...customersFilters,
          end_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'BANNED':
        setCustomersFilters({
          ...customersFilters,
          banned: value
        });
        break;
      case 'CITY_NAME':
        setCustomersFilters({
          ...customersFilters,
          city_name: value
        });
        break;
      case 'STATE_NAME':
        setCustomersFilters({
          ...customersFilters,
          state_name: value
        });
        break;
      case 'BIRTH_PET_DATE':
        setCustomersFilters({
          ...customersFilters,
          pet_month: value
        });
        break;
      case 'DOG_SPECIES':
        setCustomersFilters({
          ...customersFilters,
          pet_species: value
        });
        setBreedCat(false);
        setBreedDog(true);
        break;
      case 'CAT_SPECIES':
        setCustomersFilters({
          ...customersFilters,
          pet_species: value
        });
        setBreedDog(false);
        setBreedCat(true);
        break;
      case 'ANIMAL_MALE':
        setCustomersFilters({
          ...customersFilters,
          pet_gender: value
        });
        break;
      case 'ANIMAL_FEMALE':
        setCustomersFilters({
          ...customersFilters,
          pet_gender: value
        });
        break;
      case 'BREED_DOG':
        if (typeof value === 'object') {
          setCustomersFilters({
            ...customersFilters,
            pet_breed: value.map(breeds => breeds.replace(/\s/g, '_'))
          });
        } else {
          setCustomersFilters({
            ...customersFilters,
            pet_breed: value.map(breeds => breeds.replace(/\s/g, '_'))
          });
        }
        break;
      case 'BREED_CAT':
        setCustomersFilters({
          ...customersFilters,
          pet_breed: value
            ? value.map(breeds => breeds.replace(/\s/g, '_'))
            : ''
        });
        break;
      case 'TAGS':
        setCustomersFilters({
          ...customersFilters,
          by_tag: value ? value.map(tag => tag.replace(/\s/g, '_')) : ''
        });
        break;
      default:
        console.warn('Don`t match any filter type:', type); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    setCustomersFilters(query.filters);
  }, [setCustomersFilters, query]);

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
      >
        <Panel header="Detalhes Cliente" key="1">
          <Row gutter={10}>
            <Col span={20}>Valor Gasto</Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Valor mínimo"
                  value={customersFilters.min_converted_value}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'MIN_VALUE')}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Valor máximo"
                  value={customersFilters.max_converted_value}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'MAX_VALUE')}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={20}>Quantidade Pedidos</Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Qnt mínima"
                  value={customersFilters.more_orders_than}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'QTD_MAX_VALUE')}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Qnt máxima"
                  value={customersFilters.less_orders_than}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'QTD_MIN_VALUE')}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Row gutter={[8, 8]}>
              <Col span={24}>Gênero</Col>
            </Row>
            <Radio.Group
              buttonStyle="solid"
              defaultChecked={customersFilters?.by_gender}
              value={customersFilters?.by_gender}
            >
              <Col span={12}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="male"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'MALE_GENDER')
                  }
                >
                  Masculino
                </Radio.Button>
              </Col>
              <Col span={12}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="female"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'FEMALE_GENDER')
                  }
                >
                  Feminino
                </Radio.Button>
              </Col>
            </Radio.Group>
          </Row>
          <Row gutter={10}>
            <Col span={20} style={{ margin: '8px 0' }}>
              Data Cadastro
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  customersFilters.start_date
                    ? moment(customersFilters.start_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'START_DATE')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  customersFilters.end_date
                    ? moment(customersFilters.end_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'END_DATE')}
              />
            </Col>
          </Row>
          <Row>
            <Form.Item className="grey-border-t">
              <Col span={20}>Banido</Col>
              <Col span={4}>
                <Switch
                  defaultChecked={Boolean(customersFilters.banned)}
                  checked={Boolean(customersFilters.banned)}
                  onChange={evt => handleFilterSelect(evt, 'BANNED')}
                />
              </Col>
            </Form.Item>
          </Row>
        </Panel>
        {/* <Panel header="Endereço" key="2">
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input
                placeholder="Cidade"
                allowClear
                defaultValue={customersFilters.city_name || ''}
                onChange={evt =>
                  handleFilterSelect(evt.target.value, 'CITY_NAME')
                }
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input
                placeholder="Estado"
                allowClear
                defaultValue={customersFilters.state_name || ''}
                onChange={evt =>
                  handleFilterSelect(evt.target.value, 'STATE_NAME')
                }
              />
            </Col>
          </Row>
        </Panel> */}
        <Panel header="Pets" key="4">
          <Row gutter={[8, 8]}>
            <Col span={24}>Mês Aniversário</Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione o mês"
                defaultValue={customersFilters?.pet_month}
                value={customersFilters?.pet_month}
                onChange={evt => handleFilterSelect(evt, 'BIRTH_PET_DATE')}
              >
                {mounthOptions.map(mounths => {
                  return <Option key={mounths.value}>{mounths.mounth}</Option>;
                })}
              </Select>
              {/* <DatePicker
                value={
                  customersFilters.pet_month
                    ? moment(customersFilters.pet_month)
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'BIRTH_PET_DATE')}
              /> */}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>Espécie</Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Radio.Group
              buttonStyle="solid"
              defaultChecked={customersFilters?.pet_species}
              value={customersFilters?.pet_species}
            >
              <Col span={14}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="cachorro"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'DOG_SPECIES')
                  }
                >
                  Cachorro
                </Radio.Button>
              </Col>
              <Col span={10}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="gato"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'CAT_SPECIES')
                  }
                >
                  Gato
                </Radio.Button>
              </Col>
            </Radio.Group>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>Gênero</Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Radio.Group
              buttonStyle="solid"
              defaultChecked={customersFilters?.pet_gender}
              value={customersFilters?.pet_gender}
            >
              <Col span={12}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="m"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'ANIMAL_MALE')
                  }
                >
                  Macho
                </Radio.Button>
              </Col>
              <Col span={12}>
                <Radio.Button
                  className="ant-btn-filter"
                  value="f"
                  onClick={evt =>
                    handleFilterSelect(evt.target.value, 'ANIMAL_FEMALE')
                  }
                >
                  Fêmea
                </Radio.Button>
              </Col>
            </Radio.Group>
          </Row>
          {breedDog || breedCat ? (
            <Row gutter={[8, 8]}>
              <Col span={24}>Raças</Col>
            </Row>
          ) : null}
          <Row>
            {breedDog ? (
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecione a raça"
                onFocus={populatePets}
                defaultValue={customersFilters?.pet_breed?.map(item =>
                  item.replace('_', ' ')
                )}
                value={customersFilters?.pet_breed?.map(item =>
                  item.replace('_', ' ')
                )}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={evt => handleFilterSelect(evt, 'BREED_DOG')}
              >
                {dogBreeds.map(dog => {
                  return <Option key={dog}>{dog}</Option>;
                })}
              </Select>
            ) : null}
            {breedCat ? (
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecione a raça"
                onFocus={populatePets}
                defaultValue={customersFilters?.pet_breed?.map(item =>
                  item.replace('_', ' ')
                )}
                value={customersFilters?.pet_breed?.map(item =>
                  item.replace('_', ' ')
                )}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={evt => handleFilterSelect(evt, 'BREED_CAT')}
              >
                {catBreeds.map(cat => {
                  return <Option key={cat}>{cat}</Option>;
                })}
              </Select>
            ) : null}
          </Row>
        </Panel>
        {/* <Panel header="Tags" key="5">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              onFocus={populateTags}
              placeholder="Selecione a tag"
              defaultValue={customersFilters.tags_customer_default}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'TAGS')}
            >
              {tagsToFilters.map(tags => {
                return <Option key={tags}>{tags}</Option>;
              })}
            </Select>
          </Row>
        </Panel> */}
      </Collapse>
    </>
  );
};

CustomersFilters.defaultProps = {
  setCustomersFilters: () => {},
  customersFilters: {
    max_converted_value: null,
    min_converted_value: null,
    more_orders_than: '',
    less_orders_than: '',
    gender: '',
    start_date: null,
    end_date: null,
    banned: false,
    city_name: '',
    state_name: '',
    pet_month: null,
    pet_species: '',
    pet_breed: []
  }
};
CustomersFilters.propTypes = {
  setCustomersFilters: PropTypes.func,
  customersFilters: PropTypes.shape({
    max_converted_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    min_converted_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    more_orders_than: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    less_orders_than: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gender: PropTypes.string,
    by_gender: PropTypes.string,
    start_date: PropTypes.any,
    end_date: PropTypes.any,
    banned: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    city_name: PropTypes.string,
    state_name: PropTypes.string,
    pet_month: PropTypes.any,
    pet_species: PropTypes.string,
    pet_gender: PropTypes.string,
    pet_breed: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    pet_breed_default: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tags_customer_default: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  })
};

export default React.memo(CustomersFilters);
