import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import ChangePasswordForm from '@/src/pages/ChangePassword/Form/ChangePasswordForm';

import { useBreakpoint } from '@/src/context/BreakpointContext';

export const ChangePasswordPage = () => {
  const breakpoint = useBreakpoint();

  return (
    <>
      {breakpoint.mobile && (
        <div style={{ margin: '-4px 0 12px' }}>
          <Icon type="left" />
          <Link to="/expedicao" style={{ color: 'inherit', marginLeft: 8 }}>
            Voltar para Expedição
          </Link>
        </div>
      )}
      <Row>
        <Col span={20}>
          <h1 className="mb-20">Alterar senha</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '32px 16px 8px',
          minHeight: '48px'
        }}
      >
        <Row>
          <Col span={24} md={{ span: 12, offset: 6 }}>
            <p style={{ textAlign: 'center' }}>
              Digite sua senha atual e a nova senha.
            </p>
            <p style={{ textAlign: 'center' }}>
              Ela deve conter no mínimo 8 caracteres, com pelo menos uma letra
              maiúscula, uma letra minúscula, um número e um caractere especial.{' '}
            </p>
            <p style={{ textAlign: 'center', marginBottom: 24 }}>
              Ao confirmar, você será redirecionado para iniciar uma nova sessão
              com a senha atualizada.
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24} md={{ span: 12, offset: 6 }}>
            <ChangePasswordForm />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChangePasswordPage;
