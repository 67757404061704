import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import { getPolicies } from '@/src/store/modules/policies/slice';

export const PricesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { policies, total, query, isLoading } = useSelector(state => {
    return state.policies;
  });

  const getPoliciesHandler = useCallback(
    urlQuery => {
      dispatch(getPolicies({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getPoliciesHandler({ query: params });
  }, [getPoliciesHandler, history]);

  const columns = [
    {
      title: 'Nome',
      align: 'left',
      dataIndex: 'attributes.name',
      key: 'name',
      render: (text, record) => (
        <Link
          to={`/politicas/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {text || '-'}
        </Link>
      )
    },
    {
      title: 'Tabela de Preços',
      align: 'left',
      dataIndex: 'attributes.price_tables',
      render: text => text || '-'
    },
    {
      title: 'Hubs',
      align: 'left',
      dataIndex: 'attributes.hub',
      render: text => text || '-'
    }
  ];

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>Políticas Comerciais</h1>
        </Col>
        <Col>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/politicas/nova')}
          >
            Nova política
          </Button>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <Row style={{ marginBottom: 20 }}>
          <Searchbar
            placeholder="Buscar por nome"
            populateResult={getPoliciesHandler}
            query={query}
            value={query.search}
          />
        </Row>

        <Tables
          data={policies}
          className="even-gray"
          columnsDefaults={columns}
          count={total}
          query={query}
          visiblePaginationUp
          visiblePaginationDown
          rowKey={record => record.id}
          history={history}
          isLoading={isLoading}
          populateTables={getPoliciesHandler}
        />
      </div>
    </>
  );
};

export default PricesPage;
