import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ConfirmModal = ({ visible, setVisible, data }) => {
  return (
    <Modal
      title={<h2 style={{ textAlign: 'center' }}>{data?.title}</h2>}
      okText="Salvar"
      visible={visible}
      onOk={data?.onOk}
      onCancel={() => setVisible(false)}
      className="centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      width={400}
      bodyStyle={{ padding: '0 40px 20px 40px' }}
    >
      <p style={{ textAlign: 'center' }}>{data?.body}</p>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  data: {}
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any)
};

export default ConfirmModal;
