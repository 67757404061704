import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  // userError: false,
  editUserIsLoading: false,
  userIsLoading: false,
  users: [],
  userPermissions: [],
  // userResetPasswordSuccess: false,
  count: 0,
  query: {
    filters: [],
    page: 1,
    perPage: 20,
    search: '',
    url: ''
  },
  // usersError: false,
  // createUsersIsLoading: false,
  usersIsLoading: false,
  permissionsList: []
};

const userSlice = createSlice({
  name: '@users',
  initialState,
  reducers: {
    setUser(prevState, action) {
      const state = prevState;
      state.user = action.payload;
      return state;
    },
    setUserPermissions(prevState, action) {
      const state = prevState;
      state.userPermissions = action.payload;
      return state;
    },
    // setUserFailed(prevState, action) {
    //   const state = prevState;
    //   state.error = action.payload;
    //   return state;
    // },
    setUserIsLoading(prevState, action) {
      const state = prevState;
      state.userIsLoading = action.payload;
      return state;
    },
    // setUserResetPasswordSuccess(prevState, action) {
    //   const state = prevState;
    //   state.userResetPasswordSuccess = action.payload;
    //   return state;
    // },
    setUsers(prevState, action) {
      const state = prevState;
      state.users = action.payload.users;
      // state.count = action.payload.count;
      state.query = {
        filters: action.payload.query.filters,
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }`
      };
      state.usersIsLoading = false;
      return state;
    },
    setCount(prevState, action) {
      const state = prevState;
      state.count = action.payload;
      return state;
    },
    setUsersIsLoading(prevState, action) {
      const state = prevState;
      state.usersIsLoading = action.payload;
      return state;
    },
    // setUsersFailed(prevState, action) {
    //   const state = prevState;
    //   state.usersError = action.payload;
    //   return state;
    // },
    setEditUserIsLoading(prevState, action) {
      const state = prevState;
      state.editUserIsLoading = action.payload;
      return state;
    },
    setPermissionsList(prevState, action) {
      const state = prevState;
      state.permissionsList = action.payload;
      return state;
    },
    // setUsersNew(prevState, action) {
    //   const state = prevState;
    //   state.newUser = action.payload;
    //   return state;
    // },
    setPagination(state) {
      return state;
    },
    setSort(state) {
      return state;
    },
    getUser(state) {
      return state;
    },
    getUsers(state) {
      return state;
    },
    // deactivateUser(state) {
    //   return state;
    // },
    // activateUser(state) {
    //   return state;
    // },
    createUser(state) {
      return state;
    },
    // dispatchResetUserPassword(state) {
    //   return state;
    // },
    updateUser(state) {
      return state;
    },
    updateUserBan(state) {
      return state;
    },
    getPermissionsList(state) {
      return state;
    }
  }
});

export const {
  setUser,
  setUserPermissions,
  // setUserFailed,
  setUserIsLoading,
  setUsers,
  setCount,
  // setUsersFailed,
  setEditUserIsLoading,
  // setUsersNew,
  setPagination,
  setSort,
  setPermissionsList,
  getUser,
  // deactivateUser,
  // activateUser,
  getUsers,
  createUse,
  setUsersIsLoading,
  createUser,
  // dispatchResetUserPassword,
  updateUser,
  updateUserBan,
  getPermissionsList
  // setUserResetPasswordSuccess
} = userSlice.actions;

export default userSlice.reducer;
