import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setDepartmentsAttributes,
  setEditDepartment,
  setIsNewAsFalse,
  setLoading,
  getDepartmentAttributes,
  getCreateDepartment,
  getEdit,
  getUpdateDepartment,
  getArchiveAndUnarchive
} from '@/src/store/modules/catalog-departments/slice';

import {
  getDepartmentsAttributes as getDepartmentsAttributesRequest,
  getCreateDepartment as getCreateDepartmentRequest,
  getEdit as getEditRequest,
  updateDepartment as updateDepartmentRequest,
  archiveAndUnarchive as archiveAndUnarchiveRequest
} from '@/src/api/catalog-departments';

// import { getAttributesDepartment } from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getDepartmentAttributesSaga({ payload }) {
  try {
    const response = yield call(getDepartmentsAttributesRequest, payload);
    yield put(setDepartmentsAttributes(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getCreateDepartmentSaga({ payload }) {
  try {
    // const attributes = yield select(getAttributesDepartment);
    // const changeAttributes = yield attributes.filter(value =>
    //   payload.properties.includes(value.id)
    // );
    // yield put(setUpdateAttributesOnNewPage(changeAttributes));
    yield call(getCreateDepartmentRequest, payload);
    yield put(setIsNewAsFalse());
    yield put(push('/departamentos'));
    successAlert('Novo departamento criado');
  } catch (err) {
    errorAlert(err);
  }
}

function* updateDepartmentSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(updateDepartmentRequest, payload);

    successAlert('Os dados do departamento foram atualizados');
    yield put(push('/departamentos'));
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* getEditSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getEditRequest, payload);
    yield put(setEditDepartment(response.data));
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* getArchiveAndUnarchiveSaga({ payload }) {
  try {
    yield call(archiveAndUnarchiveRequest, payload);
    yield put(push('/departamentos'));
    successAlert(
      payload.type === 'archive'
        ? 'Departamento arquivado'
        : 'Departamento desarquivado'
    );
  } catch (err) {
    errorAlert(err);
  }
}

function* catalogDepartmentsSaga() {
  yield takeEvery(getDepartmentAttributes, getDepartmentAttributesSaga);
  yield takeEvery(getCreateDepartment, getCreateDepartmentSaga);
  yield takeEvery(getEdit, getEditSaga);
  yield takeEvery(getUpdateDepartment, updateDepartmentSaga);
  yield takeEvery(getArchiveAndUnarchive, getArchiveAndUnarchiveSaga);
}

export default catalogDepartmentsSaga;
