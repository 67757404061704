import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

import Permissions from '@/src/pages/Users/components/Permissions';
import UserForm from '@/src/pages/Users/components/UserForm';

import {
  createUser,
  setUserPermissions
} from '@/src/store/modules/users/slice';

const NewUser = ({ form }) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const dispatch = useDispatch();

  const { validateFields, getFieldValue } = form;

  const { editUserIsLoading: isLoading } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(setUserPermissions([]));
  }, [dispatch]);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) return;
      const payload = {
        data: {
          type: 'users',
          attributes: {
            name: values.name,
            email: values.email,
            kind: values.kind,
            phone: values.phone || '',
            occupation: values.occupation || '',
            vehicle: values.vehicle || '',
            plate: values.plate || '',
            document: values.document || '',
            hubs: values.hubs?.length ? values.hubs.join(',') : '',
            image: values.image || ''
          }
        }
      };
      if (selectedPermissions.length) {
        const data = selectedPermissions.map(item => {
          const id = item.split('/')[1];
          return {
            type: 'permissions',
            id: Number(id),
            method: 'update'
          };
        });
        payload.data.relationships = {
          permissions: {
            data
          }
        };
      }
      dispatch(createUser(payload));
    });
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Criação de Usuário</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/usuarios">Usuários</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo usuário</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          padding: '7px',
          marginTop: '10px',
          background: '#ebebeb'
        }}
      >
        <Row>
          <Col span={8}>
            <Card className="no-shadow" title="Dados">
              <UserForm form={form} permissions={selectedPermissions} newUser />
              <Row type="flex" justify="end" style={{ marginTop: 16 }}>
                <Col>
                  <Button onClick={onSubmit} loading={isLoading} type="primary">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={16}>
            <Permissions
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
              kind={getFieldValue('kind')}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

NewUser.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const NewUserForm = Form.create({ name: 'new_user_form' })(NewUser);

export default NewUserForm;
