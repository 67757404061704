import React from 'react';
import { Link } from 'react-router-dom';

import StatusPill from '@/src/components/StatusPill';

const deliverers = [
  {
    title: 'Nome',
    dataIndex: 'attributes.name',
    key: 'name',
    sorter: true,
    render: (text, record) => (
      <Link
        to={`/entregadores/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {text}
      </Link>
    )
  },
  {
    title: 'E-mail',
    // align: 'center',
    key: 'email',
    dataIndex: 'attributes',
    render: record => <span>{record.email || '-'}</span>
  },
  {
    title: 'Telefone',
    align: 'center',
    key: '3',
    dataIndex: 'attributes',
    render: record => <span>{record.phone || '-'}</span>
  },
  {
    title: 'Hub',
    align: 'center',
    key: '4',
    dataIndex: 'attributes',
    render: record => <span>{record.hub?.name || '-'}</span>
  },
  {
    title: 'Status',
    align: 'center',
    key: '5',
    dataIndex: 'attributes',
    width: '150px',
    render: record => (
      <StatusPill
        status={record.status === 'delivering' ? 'dispatched' : record.status}
        centered
      />
    )
  }
];

export default deliverers;
