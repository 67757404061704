import React, { useEffect, useState } from 'react';
import {
  Modal,
  Select,
  Divider,
  Button,
  Form,
  Icon,
  Input,
  Row,
  Col
} from 'antd';
import PropTypes from 'prop-types';
import MaskedInput from 'antd-mask-input';
import { isValidHour } from '@/src/utils/formatTime';

import styles from './ModalCoverage.module.scss';

const { Option } = Select;
const { Item } = Form;

const weekdays = [
  { name: 'Segunda', id: 'monday' },
  { name: 'Terça', id: 'tuesday' },
  { name: 'Quarta', id: 'wednesday' },
  { name: 'Quinta', id: 'thursday' },
  { name: 'Sexta', id: 'friday' },
  { name: 'Sábado', id: 'saturday' },
  { name: 'Domingo', id: 'sunday' }
];

const ModalCoverage = props => {
  const {
    form,
    type,
    data,
    visibility,
    handleVisibility,
    regions,
    content,
    submitData
  } = props;
  const { getFieldDecorator, setFieldsValue, validateFields } = form;
  const [inputRow, setInputRow] = useState([0]);
  const [addMoreBtn, setAddMoreBtn] = useState(content === 'suspensions');

  useEffect(() => {
    const etaValues = {
      id: data?.id || undefined,
      region_0: data?.regionID || undefined,
      weekdays_0: data?.weekday || undefined,
      starts_at_0: data?.starts_at || undefined,
      ends_at_0: data?.ends_at || undefined,
      eta_min_0: data?.eta_min || undefined,
      eta_max_0: data?.eta_max || undefined
    };

    const suspensionValues = {
      id: data?.id || undefined,
      region_0: data?.regionID || undefined,
      suspended_since_0: data?.suspendedSince || undefined,
      suspended_until_0: data?.suspendedUntil || undefined,
      title_0: data?.suspensionTitle || undefined,
      description_0: data?.suspensionDescription || undefined
    };

    setFieldsValue(content === 'eta_schedules' ? etaValues : suspensionValues);

    setAddMoreBtn(content === 'suspensions');

    return setInputRow([0]);
  }, [data, setFieldsValue, content]);

  const renderModalInput = (title, value, placeholder, mask, validateFunc) => {
    let component;
    if (mask) {
      component = form.getFieldDecorator(value, {
        rules: [
          { required: true, message: 'Campo obrigatório' },
          { validator: validateFunc, message: 'Entrada inválida' }
        ]
      })(<MaskedInput mask={mask} name={title} placeholder={placeholder} />);
    } else if (value.includes('description')) {
      component = form.getFieldDecorator(value, {
        rules: [{ required: true, message: 'Campo obrigatório' }]
      })(<Input.TextArea name={title} placeholder={placeholder} rows={1} />);
    } else {
      component = form.getFieldDecorator(value, {
        rules: [{ required: true, message: 'Campo obrigatório' }]
      })(
        <Input
          name={title}
          placeholder={placeholder}
          maxLength={value.includes('eta') ? 3 : null}
        />
      );
    }
    return (
      <Item>
        <strong>{title}</strong>
        {component}
      </Item>
    );
  };

  const renderModalSelect = (title, value, placeholder, list) => {
    return (
      <Item>
        <strong>{title}</strong>
        {form.getFieldDecorator(value, {
          rules: [{ required: true, message: 'Campo obrigatório' }]
        })(
          <Select placeholder={placeholder} allowClear>
            {list.map(item => {
              return (
                <Option key={item.name} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        )}
      </Item>
    );
  };

  const convertObjectArray = formValues => {
    // Quantity of elements, less the id with is necessary only in edition
    // divided by quantity of columns
    const keys = Object.keys(formValues);
    const columnsQty = content === 'eta_schedules' ? 6 : 5;
    const rowsQty = (keys.length - 1) / columnsQty;
    const finalArray = [];

    for (let x = 0; x < rowsQty; x += 1) {
      const formated =
        content === 'eta_schedules'
          ? {
              region: formValues[`region_${x}`],
              weekdays: formValues[`weekdays_${x}`],
              starts_at: formValues[`starts_at_${x}`],
              ends_at: formValues[`ends_at_${x}`],
              eta_min: formValues[`eta_min_${x}`],
              eta_max: formValues[`eta_max_${x}`]
            }
          : {
              region: formValues[`region_${x}`],
              suspended_since: formValues[`suspended_since_${x}`],
              suspended_until: formValues[`suspended_until_${x}`],
              title: formValues[`title_${x}`],
              description: formValues[`description_${x}`]
            };

      finalArray.push(formated);
    }
    return finalArray;
  };

  const onSubmit = evt => {
    evt.preventDefault();

    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        if (type === 'editar') {
          if (content === 'eta_schedules') {
            submitData([
              {
                id: values.id,
                region: values.region_0,
                weekdays: values.weekdays_0 || '',
                starts_at: values.starts_at_0,
                ends_at: values.ends_at_0,
                eta_min: values.eta_min_0,
                eta_max: values.eta_max_0
              }
            ]);
          } else
            submitData([
              {
                id: values.id,
                region: values.region_0,
                suspended_since: values.suspended_since_0,
                suspended_until: values.suspended_until_0,
                title: values.title_0,
                description: values.description_0
              }
            ]);
        } else submitData(convertObjectArray(values));
      }
    });
  };

  const handleSetInputRow = () => {
    if (inputRow.length <= 6) setInputRow([...inputRow, inputRow.length]);
  };

  return (
    <Modal
      className={`${styles.modalCoverage} ant-modal-hubs right-footer`}
      width={1100}
      onCancel={handleVisibility}
      visible={visibility}
      footer={
        <Button type="primary" onClick={onSubmit}>
          Salvar
        </Button>
      }
    >
      <div className={styles.modalHeader}>
        <h2>
          {type}{' '}
          {content === 'eta_schedules'
            ? 'tempo de entrega estimado'
            : 'suspensão por região'}
        </h2>
      </div>
      <Form>
        {inputRow.map(item =>
          content === 'eta_schedules' ? (
            <Row type="flex" gutter={8}>
              {getFieldDecorator('id', {})(<Input hidden />)}
              <Col span={6}>
                {renderModalSelect(
                  'Região',
                  `region_${item}`,
                  'Selecionar Região',
                  regions
                )}
              </Col>
              <Col span={6}>
                <Item>
                  <strong>Dia da semana</strong>
                  {form.getFieldDecorator(
                    `weekdays_${item}`,
                    {}
                  )(
                    <Select
                      placeholder="Selecionar dia"
                      allowClear
                      onChange={value =>
                        setAddMoreBtn(value ? true : undefined)
                      }
                    >
                      {weekdays.map(day => {
                        return (
                          <Option key={day.name} value={day.id}>
                            {day.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={3}>
                <Item>
                  <strong>A partir de</strong>
                  {form.getFieldDecorator(`starts_at_${item}`, {
                    rules: [
                      {
                        validator: (rules, values) => isValidHour(values),
                        message: 'Entrada inválida'
                      }
                    ]
                  })(
                    <MaskedInput
                      mask="11:11"
                      name="A partir de"
                      placeholder="00:00"
                    />
                  )}
                </Item>
              </Col>
              <Col span={3}>
                <Item>
                  <strong>Até as</strong>
                  {form.getFieldDecorator(`ends_at_${item}`, {
                    rules: [
                      {
                        validator: (rules, values) => isValidHour(values),
                        message: 'Entrada inválida'
                      }
                    ]
                  })(
                    <MaskedInput
                      mask="11:11"
                      name="Até as de"
                      placeholder="00:00"
                    />
                  )}
                </Item>
              </Col>
              <Col span={3}>
                {renderModalInput('ETA Mínimo', `eta_min_${item}`, '0')}
              </Col>
              <Col span={3}>
                {renderModalInput('ETA Máximo', `eta_max_${item}`, '0')}
              </Col>
            </Row>
          ) : (
            <Row type="flex" gutter={8}>
              {getFieldDecorator('id', {})(<Input hidden />)}
              <Col span={5}>
                {renderModalSelect(
                  'Região',
                  `region_${item}`,
                  'Selecionar Região',
                  regions
                )}
              </Col>
              <Col span={3}>
                {renderModalInput(
                  'A partir de',
                  `suspended_since_${item}`,
                  '00:00',
                  '11:11',
                  (rules, values) => isValidHour(values)
                )}
              </Col>
              <Col span={3}>
                {renderModalInput(
                  'Até as',
                  `suspended_until_${item}`,
                  '00:00',
                  '11:11',
                  (rules, values) => {
                    return isValidHour(values);
                  }
                )}
              </Col>
              <Col span={5}>
                {renderModalInput('Título', `title_${item}`, 'Lorem ipsum')}
              </Col>
              <Col span={8}>
                {renderModalInput(
                  'Descrição',
                  `description_${item}`,
                  'Lorem ipsum'
                )}
              </Col>
            </Row>
          )
        )}
        {type === 'adicionar' && addMoreBtn && (
          <>
            <Divider style={{ margin: '16px 0' }} />
            <button
              type="button"
              className={styles.modalButton}
              onClick={handleSetInputRow}
            >
              <Icon type="plus-circle" className={styles.modalButton__icon} />{' '}
              <strong>
                {content === 'eta_schedules'
                  ? 'Adicionar novo ETA'
                  : 'Adicionar nova suspensão'}
              </strong>
            </button>
          </>
        )}
      </Form>
    </Modal>
  );
};

const WrappedModalCoverage = Form.create({ name: 'NewCoverageForm' })(
  ModalCoverage
);

ModalCoverage.defaultProps = {
  form: {
    getFieldDecorator: () => {},
    validateFields: () => {},
    setFieldsValue: () => {}
  },
  type: 'adicionar',
  content: '',
  data: {},
  regions: [],
  visibility: false,
  handleVisibility: () => {},
  submitData: () => {}
};

ModalCoverage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }),
  type: PropTypes.string,
  content: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  regions: PropTypes.arrayOf(PropTypes.any),
  visibility: PropTypes.bool,
  handleVisibility: PropTypes.func,
  submitData: PropTypes.func
};

export default WrappedModalCoverage;
