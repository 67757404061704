import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  eta: {},
  isLoading: false
};

const etasSlice = createSlice({
  name: '@etasData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setEtas(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setEtaSingle(prevState, action) {
      const state = prevState;
      state.eta = action.payload;
      return state;
    },
    getEtas(state) {
      return state;
    },
    getEtaSingle(state) {
      return state;
    },
    getUpdateEta(state) {
      return state;
    },
    getCreateEta(state) {
      return state;
    },
    getDeleteEta(state) {
      return state;
    }
  }
});

export const {
  setIsLoading,
  setEtas,
  setEtaSingle,
  getEtas,
  getEtaSingle,
  getUpdateEta,
  getCreateEta,
  getDeleteEta
} = etasSlice.actions;

export default etasSlice.reducer;
