import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from '@/src/store/rootReducers';
import rootSaga from '@/src/store/rootSagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: [routerMiddleware(history), sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

if (window.Cypress) window.__store__ = store; //eslint-disable-line

export default store;
