import { createSlice } from '@reduxjs/toolkit';
import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  assemblings: [],
  assembling: [],
  assemblingProducts: [],
  isLoading: false,
  query: {
    page: 1,
    perPage: 20,
    url: ''
    // filters: {
    //   not_archived: true
    // }
  },
  count: 0
};

const assemblingsSlice = createSlice({
  name: '@assemblings',
  initialState,
  reducers: {
    setAssemblings(prevState, action) {
      const state = prevState;
      state.assemblings = action.payload.assemblings;
      state.count = action.payload.count;
      state.query = {
        page: action.payload.query?.page || state.query.page,
        perPage: action.payload.query?.perPage || state.query.perPage,
        filters: action.payload.query?.filters || {},
        url: `?page=${action.payload.query?.page || '1'}&per_page=${action
          .payload.query?.perPage || state.query.perPage}${
          action.payload.query?.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      return state;
    },
    setAssembling(prevState, action) {
      const state = prevState;
      state.assembling = action.payload.attributes;
      return state;
    },
    setAssemblingProducts(prevState, action) {
      const state = prevState;
      state.assemblingProducts = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    getAssemblings(state) {
      return state;
    },
    getAssembling(state) {
      return state;
    },
    getAssemblingProducts(state) {
      return state;
    },
    createAssembling(state) {
      return state;
    },
    updateAssembling(state) {
      return state;
    },
    archiveAssembling(state) {
      return state;
    },
    unarchiveAssembling(state) {
      return state;
    },
    orderByDragNDrop(state) {
      return state;
    },
    exportCSVAssemblings(state) {
      return state;
    },
    importCSVAssemblings(state) {
      return state;
    },
    exportCSVAssemblingProducts(state) {
      return state;
    },
    importCSVAssemblingProducts(state) {
      return state;
    }
  }
});

export const {
  setAssemblings,
  setLoading,
  setAssembling,
  setAssemblingProducts,
  getAssemblings,
  getAssembling,
  getAssemblingProducts,
  createAssembling,
  updateAssembling,
  archiveAssembling,
  unarchiveAssembling,
  orderByDragNDrop,
  exportCSVAssemblings,
  importCSVAssemblings,
  exportCSVAssemblingProducts,
  importCSVAssemblingProducts
} = assemblingsSlice.actions;

export default assemblingsSlice.reducer;
