import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tooltip, Switch, Avatar } from 'antd';

import TablePriceStock from '@/src/components/TablePriceStock';
import formatCurrency from '@/src/utils/formatCurrency';

import PrecoBtn from '@/src/assets/images/preco_btn.svg';
import hubBtn from '@/src/assets/images/hub_btn.svg';

const products = [
  {
    title: 'Imagem',
    dataIndex: '',
    align: 'center',
    key: '1',
    render: data => (
      <Link
        to={`/produtos/${data.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        <Avatar
          shape="square"
          size={60}
          src={data.image}
          alt={data.name}
          icon="camera"
          className="img-placeholder"
        />
      </Link>
    )
  },
  {
    title: 'Título',
    dataIndex: '',
    key: '2',
    width: 450,
    render: data => (
      <Link
        to={`/produtos/${data.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {data.name || ''}
      </Link>
    )
  },
  {
    title: 'SKUs',
    align: 'left',
    key: '3',
    dataIndex: '',
    render: data => {
      const { skus, hubsSelected } = data;
      const hasHubsSelected = hubsSelected.length > 0;
      const skuData = skus.map(sku => {
        const stockDetails = [];
        const priceDetails = [];

        sku.hubSkus.forEach(item => {
          if (
            hasHubsSelected &&
            hubsSelected.some(hubSelected => item.hubInternalId === hubSelected)
          ) {
            stockDetails.push({
              key: item.hubInternalId,
              availablequantity: item?.availableQuantity,
              hubname: item?.hubName,
              reservedquantity: item?.reservedQuantity,
              stockquantity: item?.quantity
            });

            if (sku.pricesHubs) {
              const pricesHub = sku.pricesHubs.find(
                hub => hub.hubInternalId === item.hubInternalId
              );
              // const priceTable = sku.priceTable.find(
              //   table => table.id === pricesHub?.price[0]?.priceTableId
              // );
              priceDetails.push({
                key: item.hubInternalId,
                hubname: `${item?.hubName} (${pricesHub?.price[0]
                  ?.priceTableName || 'sem tabela definida'})`,
                listprice:
                  formatCurrency(pricesHub?.price[0]?.listPrice) || '-',
                saleprice: formatCurrency(pricesHub?.price[0]?.salePrice) || '-'
              });
            }
          }
        });

        return {
          code: sku.externalReference || 'sem código',
          erp_name: sku.externalTitle || sku.name || '<nome vazio>',
          internal_id: sku.internalId,
          priceDetails,
          stockDetails
        };
      });

      return (
        <>
          {skuData.map((skuItem, index) => {
            return (
              <div
                key={skuItem.internal_id}
                style={{ display: 'flex', marginTop: index === 0 ? 0 : 8 }}
              >
                <span>{skuItem.erp_name}</span>
                <span style={{ margin: '0 4px' }}>({skuItem.code})</span>
                {skuItem.priceDetails.length > 0 && (
                  <div className="skudetails-btn price-details-table">
                    <img width="30" src={PrecoBtn} alt="Ícone de Preço" />
                    <TablePriceStock data={skuItem.priceDetails} type="price" />
                  </div>
                )}
                {skuItem.stockDetails.length > 0 && (
                  <div className="skudetails-btn stock-details-table">
                    <img width="30" src={hubBtn} alt="Ícone de Hub" />
                    <TablePriceStock data={skuItem.stockDetails} type="stock" />
                  </div>
                )}
              </div>
            );
          })}
        </>
      );
    }
  },
  {
    title: 'Disponíveis',
    align: 'center',
    key: '4',
    dataIndex: '',
    render: data =>
      data?.skus?.map((sku, index) => {
        const hubsQty = sku.hubSkus.map(item => item.availableQuantity);
        const totalQty =
          hubsQty.length > 0
            ? hubsQty.reduce((total, current) => total + current)
            : '-';
        return (
          <span
            style={{ display: 'block', marginTop: index === 0 ? 0 : 8 }}
            key={sku.id}
          >
            {totalQty}
          </span>
        );
      })
  },
  {
    title: 'Reservados',
    align: 'center',
    key: '5',
    dataIndex: '',
    render: data =>
      data?.skus?.map((sku, index) => {
        const hubsQty = sku.hubSkus.map(item => item.reservedQuantity);
        const totalQty =
          hubsQty.length > 0
            ? hubsQty.reduce((total, current) => total + current)
            : '-';
        return (
          <span
            style={{ display: 'block', marginTop: index === 0 ? 0 : 8 }}
            key={sku.id}
          >
            {totalQty}
          </span>
        );
      })
  },
  {
    title: 'Estoque Total',
    align: 'center',
    key: '6',
    dataIndex: '',
    render: data =>
      data?.skus?.map((sku, index) => {
        const hubsQty = sku.hubSkus.map(item => item.quantity);
        const totalQty =
          hubsQty.length > 0
            ? hubsQty.reduce((total, current) => total + current)
            : '-';
        return (
          <span
            style={{ display: 'block', marginTop: index === 0 ? 0 : 8 }}
            key={sku.id}
          >
            {totalQty}
          </span>
        );
      })
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>Status</span>
        <Tooltip
          placement="top"
          overlayClassName="tooltip-table"
          title="Este controle torna o produto visível no aplicativo."
        >
          <Icon type="info-circle" style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    ),
    align: 'center',
    dataIndex: '',
    key: '7',
    render: data => {
      return (
        <>
          {data.active !== null ? (
            <Tooltip placement="top" overlayClassName="tooltip-table" title="">
              <Switch
                onChange={e => {
                  data.changeVisibility({
                    id: data.id.toString(),
                    active: e,
                    // later remove this line
                    is_there_any_breed_suggestion: false
                  });
                }}
                defaultChecked={data.active}
                disabled={!data.editPermission}
              />
            </Tooltip>
          ) : (
            <Switch disabled={data.active === null || !data.editPermission} />
          )}
        </>
      );
    }
  }
];

export default products;
