import React, { useState } from 'react';
import { Form, Button, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';

const GenerateCoupons = props => {
  const { form, generateCouponsSubmit, disabled } = props;
  const { getFieldDecorator, validateFields } = form;

  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      generateCouponsSubmit(values.cupons);
      setIsVisible(false);
    });
  };

  return (
    <>
      <Button
        disabled={disabled}
        type="secondary"
        onClick={() => setIsVisible(true)}
      >
        gerar Cupons
      </Button>
      <Modal
        visible={isVisible}
        title="GERAR CUPONS"
        okText="Gerar"
        onCancel={() => setIsVisible(false)}
        className="generate-cupons"
        onOk={onSubmit}
      >
        <p>Quantos cupons deseja gerar?</p>
        <Form name="generate_cupons">
          <Form.Item style={{ marginBottom: 5 }}>
            {getFieldDecorator('cupons', {
              rules: [
                {
                  required: true,
                  message:
                    'Por favor, insira o número de cupons à serem gerados'
                },
                {
                  pattern: /^[0-9]+$/i,
                  message: 'Utilize apenas números'
                },
                {
                  validator: (rule, value, cb) => value < 501,
                  message: 'Por favor, insira um número menor que 500'
                }
              ]
            })(<InputNumber placeholder="ex.: 250" min={1} max={500} />)}
          </Form.Item>
        </Form>
        <ul style={{ paddingInlineStart: 20 }}>
          <li>Máximo 500 cupons</li>
        </ul>
      </Modal>
    </>
  );
};

GenerateCoupons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  generateCouponsSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const GenerateCouponsForm = Form.create({ name: 'generate_cupons_form' })(
  GenerateCoupons
);

export default GenerateCouponsForm;
