import { apiWarehousePetz } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getSkusPetzList = payload => {
  const url = queryBuilder(payload, '/api/skus');
  return apiWarehousePetz.get(url);
};

export const getSkuPetz = id => apiWarehousePetz.get(`/api/skus/${id}`);

export const createSkuPetz = payload =>
  apiWarehousePetz.post('/api/skus', payload);

export const updateSkuPetz = payload =>
  apiWarehousePetz.patch(`/api/skus/${payload.id}`, payload);
