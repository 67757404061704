import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryList = {};
    const keys = Object.keys(queries);

    const handleQueryListener = () => {
      // cria novo obj executando o método matchMedia para cada query
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = mediaQueryList[media].matches;
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach(media => {
        // obj que armazena informações sobre media queries aplicadas ao documento, com o método matchMedia no obj window
        mediaQueryList[media] = window.matchMedia(queries[media]);

        // obj com valores booleanos que validam aplicação da query ao documento
        matches[media] = mediaQueryList[media].matches;
      });
      setQueryMatch(matches);

      // monitora obj MediaQueryList e dispara evento quando a validação (bool) de uma query no documento é alterada
      keys.forEach(media =>
        mediaQueryList[media].addEventListener('change', handleQueryListener)
      );
    }

    // executado quando componente dentro do provider é desmontado
    return () => {
      keys.forEach(media =>
        mediaQueryList[media].removeEventListener('change', handleQueryListener)
      );
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

BreakpointProvider.defaultProps = {
  children: []
};

BreakpointProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  queries: PropTypes.objectOf(PropTypes.string).isRequired
};

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
}

export { useBreakpoint, BreakpointProvider };
