import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spin, Avatar } from 'antd';
import moment from '@/src/services/moment';

import handleDispatchTag from '@/src/utils/handlePDF';
// import formatParityText from '@/src/utils/formatParityText';

// import { ReactComponent as DeliveredIcon } from '@/src/assets/images/dispatch/delivered.svg';
// import { ReactComponent as DispatchedIcon } from '@/src/assets/images/dispatch/dispatched.svg';
import { ReactComponent as HouseIcon } from '@/src/assets/images/dispatch/house.svg';
import { ReactComponent as ClockIcon } from '@/src/assets/images/dispatch/clock.svg';
import { ReactComponent as CommentIcon } from '@/src/assets/images/dispatch/comment.svg';

import styles from './ListTile.module.scss';

const ListTile = ({ typeContent, orderData, card, hasOrdersCount }) => {
  const [tagLoading, setTagLoading] = useState(false);
  const [content, setContent] = useState({});

  const types = {
    tag: {
      text: 'Ver etiqueta',
      iconType: 'qrcode',
      iconColor: '#000',
      iconSize: '13px'
    },
    nfeNull: {
      text: 'Nota não gerada',
      iconType: 'stop',
      iconColor: '#B3B3B3',
      fontSize: card ? '12px' : '13px'
    },
    nfeDone: {
      text: orderData.saleInvoiceNumber,
      link: orderData.saleInvoicePdf,
      iconType: 'check-circle',
      iconTheme: 'filled',
      iconColor: '#50EBC5',
      fontSize: card ? '12px' : '13px'
    },
    nfeLoading: {
      text: 'Processando nota',
      iconType: 'sync',
      iconTheme: 'outlined',
      iconColor: '#B3B3B3',
      fontSize: card ? '12px' : '13px'
    },
    address: {
      text: orderData.addressStreet
        ? `${orderData.addressStreet}, ${
            orderData.addressNumber
          }, ${orderData.addressComplement || 'sem complemento'} | ${
            orderData.addressDistrict
          } - ${orderData.addressCity} - ${orderData.addressState} - CEP: ${
            orderData.addressZipcode
          }`
        : 'Sem endereço cadastrado',
      customIcon: <HouseIcon />
    },
    scheduled: {
      text: orderData.deliverAt
        ? `Pedido agendado: ${moment(orderData.deliverAt).format(
            'DD/MM | HH:mm'
          )}`
        : '-',
      customIcon: <ClockIcon />
    },
    // volume: {
    //   text: formatParityText(orderData.volumeCount || 0, 'volume'),
    //   iconType: 'shopping'
    // },
    user: {
      text: orderData.userName
        ? `Estoquista: ${orderData.userName}`
        : 'Sem estoquista',
      img: orderData.userImageUrl
    },
    customer: {
      text: orderData.customerName
        ? `Cliente: ${orderData.customerName}`
        : 'Cliente sem nome cadastrado'
    },
    comment: {
      text: orderData.remarks || 'Não há observações no pedido',
      fontColor: '#737373',
      fontSize: '12px',
      customIcon: <CommentIcon />
    }
    // dispatched: {
    //   text: `pedido ${
    //     orderData.status === 'dispatched' || orderData.status === 'delivering'
    //       ? 'em rota'
    //       : 'entregue'
    //   }`,
    //   customIcon:
    //     orderData.status === 'dispatched' ||
    //     orderData.status === 'delivering' ? (
    //       <DispatchedIcon />
    //     ) : (
    //       <DeliveredIcon />
    //     )
    //   // img:
    //   //   orderData.status === 'dispatched' || orderData.status === 'delivering'
    //   //     ? dispatchedIcon
    //   //     : deliveredIcon
    // }
  };

  useEffect(() => {
    let type = typeContent;
    if (type === 'nfe') {
      if (
        orderData.status === 'waiting' ||
        orderData.status === 'started' ||
        orderData.status === 'picked' ||
        orderData.status === 'packed'
      ) {
        type = 'nfeNull';
      } else if (orderData.status === 'canceled') {
        type =
          orderData.saleInvoiceNumber && orderData.saleInvoicePdf
            ? 'nfeDone'
            : 'nfeNull';
      } else if (orderData.saleInvoiceNumber && orderData.saleInvoicePdf) {
        type = 'nfeDone';
      } else {
        type = 'nfeLoading';
      }
    }
    const obj = types[type];
    setContent(obj);
    // eslint-disable-next-line
  }, [typeContent, orderData]);

  return (
    <div className={styles.ListTile__container}>
      {content.iconType && (
        <Icon
          type={content.iconType || 'loading'}
          theme={content.iconTheme}
          style={{
            color: content.iconColor,
            fontSize: `${content.iconSize || '16px'}`
          }}
        />
      )}
      {(typeContent === 'user' || typeContent === 'customer') && (
        <Avatar
          size={16}
          icon="user"
          src={content.img}
          className={styles[`ListTile__container--${typeContent}`]}
        />
      )}
      {content.customIcon || ''}
      {content.link && (
        <a href={content.link} rel="noopener noreferrer" target="_blank">
          {content.text}
        </a>
      )}
      {!content.link && typeContent !== 'tag' && (
        <span
          style={{
            fontSize: `${content.fontSize || '13px'}`,
            color: `${content.fontColor || '#333'}`,
            marginLeft: `${card ? '8px' : '6px'}`,
            position: hasOrdersCount ? 'relative' : ''
          }}
        >
          {content.text}
          {hasOrdersCount && (
            <span
              style={{
                fontSize: 13,
                color: '#333',
                backgroundColor:
                  orderData.customerTodayOrdersCount === 2
                    ? '#fde6b6'
                    : '#fbb9c5',
                padding: '4px 8px',
                borderRadius: 20,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                marginLeft: 10,
                width: 'max-content'
              }}
            >
              {orderData.customerTodayOrdersCount}º pedido no dia
            </span>
          )}
        </span>
      )}
      {typeContent === 'tag' && (
        <>
          <button
            type="button"
            onClick={() => handleDispatchTag(orderData, setTagLoading)}
          >
            {content.text}
          </button>
          {tagLoading && (
            <Spin
              style={{ display: 'flex', marginLeft: 8 }}
              indicator={
                <Icon
                  type="loading"
                  style={{ fontSize: 20, color: '#333333' }}
                  spin
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

ListTile.defaultProps = {
  orderData: {},
  card: false,
  hasOrdersCount: false
};

ListTile.propTypes = {
  typeContent: PropTypes.string.isRequired,
  orderData: PropTypes.objectOf(PropTypes.any),
  card: PropTypes.bool,
  hasOrdersCount: PropTypes.bool
};

export default ListTile;
