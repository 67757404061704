import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  setProductsLoading,
  setPriceTablesLoading,
  setPriceTablesSelectLoading,
  setPriceTables,
  setPriceTablesSelect,
  setPriceTable,
  setPriceTableProducts,
  getPriceTables,
  getPriceTablesSelect,
  getPriceTableProducts,
  getUpdatePriceTable,
  exportCSVPriceTable
} from '@/src/store/modules/price-tables/slice';

import { setPolicyPriceTable } from '@/src/store/modules/policies/slice';

import {
  getPriceTablesRequest,
  getPriceTableProductsRequest,
  updatePriceTableRequest
} from '@/src/api/policies';

import { exportCSVPriceTable as exportCSVPriceTableRequest } from '@/src/api/export-file';

import {
  getPriceTablesQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getPriceTablesSaga({ payload }) {
  try {
    yield put(setPriceTablesLoading(true));
    const response = yield call(getPriceTablesRequest, payload);
    yield put(
      setPriceTables({
        priceTables: response.data.data,
        query: payload.query,
        count: response.data.meta.count
      })
    );

    const queryUrl = yield select(getPriceTablesQueryUrlState);
    const routerState = yield select(getRouterState);
    // console.log(routerState);
    if (
      routerState.location.pathname.includes('tabelas') &&
      routerState.location.search !== queryUrl
    ) {
      yield put(push(queryUrl));
    }

    yield put(setPriceTablesLoading(false));
  } catch (err) {
    yield put(setPriceTablesLoading(false));
    errorAlert(err, 'Não foi possível carregar a lista de tabelas de preço');
  }
}

function* getPriceTablesSelectSaga({ payload }) {
  try {
    yield put(setPriceTablesSelectLoading(true));
    const response = yield call(getPriceTablesRequest, payload);
    yield put(setPriceTablesSelect(response.data.data));
    yield put(setPriceTablesSelectLoading(false));
  } catch (err) {
    // console.log(err);
    yield put(setPriceTablesSelectLoading(false));
    errorAlert(err);
  }
}

function* getPriceTableProductsSaga({ payload }) {
  try {
    yield put(setProductsLoading(true));
    const response = yield call(getPriceTableProductsRequest, payload);
    yield put(
      setPriceTableProducts({ ...response.data, query: payload.query })
    );
    yield put(setProductsLoading(false));
  } catch (err) {
    yield put(setProductsLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados da ptabela de preços');
  }
}

function* getUpdatePriceTableSaga({ payload }) {
  try {
    yield put(setPriceTablesLoading(true));
    const response = yield call(updatePriceTableRequest, payload);
    yield put(setPriceTable(response));
    yield put(setPolicyPriceTable(response));
    yield put(setPriceTablesLoading(false));
    successAlert('Os dados da tabela de preços foram atualizados');
  } catch (err) {
    yield put(setPriceTablesLoading(false));
    errorAlert(err, 'Não foi possível atualizar a tabela de preços');
  }
}

function* exportCSVPriceTableSaga({ payload }) {
  try {
    const response = yield call(exportCSVPriceTableRequest, payload.key);
    const label = payload.label
      .normalize('NFD')
      .replace(/\s/g, '')
      .replace(/[\u0300-\u036f]/g, '');
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${label}.csv`;
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    // console.log(err);
    errorAlert(err);
  }
}

function* policiesSaga() {
  yield takeEvery(getPriceTables, getPriceTablesSaga);
  yield takeEvery(getPriceTablesSelect, getPriceTablesSelectSaga);
  yield takeEvery(getPriceTableProducts, getPriceTableProductsSaga);
  yield takeEvery(getUpdatePriceTable, getUpdatePriceTableSaga);
  yield takeEvery(exportCSVPriceTable, exportCSVPriceTableSaga);
}

export default policiesSaga;
