import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setIsLoading,
  setSuspensions,
  setSuspensionSingle,
  getSuspensions,
  getSuspensionSingle,
  getUpdateSuspension,
  getCreateSuspension,
  getDeleteSuspension
} from '@/src/store/modules/suspensions/slice';

import {
  getSuspensionsData,
  getSuspensionSingleData,
  setUpdateSuspensionData,
  setCreateSuspensionData,
  setDeleteSuspensionData
} from '@/src/api/suspensions';

function* setSuspensionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getSuspensionsData, payload);
    const normalized = normalize(response.data, {
      endpoint: '/SuspensionSchedules'
    });
    yield put(setSuspensions(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setSuspensionSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getSuspensionSingleData, payload);
    yield put(setSuspensionSingle(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setUpdateSuspensionSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(setUpdateSuspensionData, payload);
    yield put(setSuspensionSingle(response.data.data));
    yield put(setIsLoading(false));
    successAlert('Os dados de Suspensão foram atualizados');
  } catch (err) {
    errorAlert(err);
  }
}

function* setCreateSuspensionSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(setCreateSuspensionData, payload);
    yield put(setSuspensionSingle(response.data.data));
    yield put(setIsLoading(false));
    successAlert('Nova Suspensão cadastrada');
  } catch (err) {
    errorAlert(err);
  }
}

function* setDeleteSuspensionSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(setDeleteSuspensionData, payload);
    yield put(setIsLoading(false));
    successAlert('Suspensão removida');
  } catch (err) {
    errorAlert(err);
  }
}

function* SuspensionsListSaga() {
  yield takeEvery(getSuspensions, setSuspensionsSaga);
  yield takeEvery(getSuspensionSingle, setSuspensionSingleSaga);
  yield takeEvery(getUpdateSuspension, setUpdateSuspensionSaga);
  yield takeEvery(getCreateSuspension, setCreateSuspensionSaga);
  yield takeEvery(getDeleteSuspension, setDeleteSuspensionSaga);
}

export default SuspensionsListSaga;
