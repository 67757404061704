import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  manufacturers: [],
  manufacturer: {},
  query: {
    page: 1,
    perPage: 20,
    search: '',
    url: ''
  },
  count: 0,
  isLoading: false
};

const manufacturersSlice = createSlice({
  name: '@manufacturers',
  initialState,
  reducers: {
    setManufacturers(prevState, action) {
      const state = prevState;
      state.manufacturers = action.payload.data;
      state.count = action.payload.count;
      if (action.payload.query) {
        state.query = {
          page: Number(action.payload.query.page),
          perPage: action.payload.query.perPage || state.query.perPage,
          search: action.payload.query.search || '',
          url: `?page=${action.payload.query.page || '1'}&per_page=${action
            .payload.query.perPage || state.query.perPage}${
            action.payload.query.search !== ''
              ? `&q=${action.payload.query.search}`
              : ''
          }`
        };
      }
      return state;
    },
    setManufacturer(prevState, action) {
      const state = prevState;
      state.manufacturer = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getManufacturers(state) {
      return state;
    },
    getManufacturer(state) {
      return state;
    },
    createManufacturer(state) {
      return state;
    },
    updateManufacturer(state) {
      return state;
    },
    orderByDragNDrop(state) {
      return state;
    }
  }
});

export const {
  setManufacturers,
  setManufacturer,
  setIsLoading,
  setPagination,
  setSort,
  getManufacturers,
  getManufacturer,
  createManufacturer,
  updateManufacturer,
  orderByDragNDrop
} = manufacturersSlice.actions;

export default manufacturersSlice.reducer;
