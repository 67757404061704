import { apiPortal } from '@/src/api/config';

import queryBuilder from '@/src/utils/queryBuilder';

export const getRecommendations = payload => {
  const getUrl = queryBuilder(payload, '/recommendations');
  return apiPortal.get(getUrl);
};

export const getRecommendation = id => {
  return apiPortal.get(`/recommendations/${id}`);
};

export const createRecommendation = payload => {
  return apiPortal.post('/recommendations', payload);
};

export const updateRecommendation = payload => {
  return apiPortal.put(`/recommendations/${payload.id}`, payload);
};

export const updateRecommendationSort = payload => {
  return apiPortal.put(`/recommendations_sortings/${payload.id}`, payload);
};
