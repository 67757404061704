import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setIsLoading,
  setRegions,
  setRegionSingle,
  setCreateRegion,
  getRegions,
  getRegionSingle,
  getSwitchZipcodeAssociation,
  getCreateRegion,
  deleteZipcode,
  dowloadZipcodes,
  uploadZipcodes
} from '@/src/store/modules/regions/slice';

import { setHubSingle } from '@/src/store/modules/hubs-data/slice';

import { getHubSingleData as getHubSingleDataRequest } from '@/src/api/hubs-data';

import { setIsLoading as setZipcodesLoading } from '@/src/store/modules/zipcodes/slice';

import {
  getRegionsData as getRegionsDataRequest,
  getRegionSingleData as getRegionSingleDataRequest,
  updateRegion as updateRegionRequest,
  getCreateRegion as getCreateRegionRequest,
  downloadZipcodes as downloadZipcodesRequest,
  uploadZipcodes as uploadZipcodesRequest
} from '@/src/api/regions';

import { deleteZipcode as deleteZipcodeRequest } from '@/src/api/zipcodes';

const hubPayload = hubId => {
  return {
    id: hubId,
    query: {
      include: 'state,state.cities,city,regions,regions.zipcodes',
      fields: [
        { key: 'hubs', value: 'name,id,state,city,regions' },
        { key: 'states', value: 'code,id' },
        { key: 'cities', value: 'name,id' },
        { key: 'regions', value: 'name,id' },
        { key: 'zipcodes', value: 'code,id' }
      ]
    }
  };
};

function* setRegionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield put(setZipcodesLoading(true));
    const response = yield call(getRegionsDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/regions' });
    const parsed = (
      (normalized?.meta && normalized?.meta['/regions'].data) ||
      []
    ).map(object =>
      build(normalized, 'regions', object.id, { ignoreLinks: true })
    );
    const regionsArr = [...parsed].sort((a, b) => {
      if (a.city?.name === b.city?.name) return a.name.localeCompare(b.name);
      return a.city?.name.localeCompare(b.city?.name);
    });
    yield put(setRegions(regionsArr));
    yield put(setZipcodesLoading(false));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* setRegionSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getRegionSingleDataRequest, payload);
    yield put(setRegionSingle(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* setSwitchZipcodeAssociationSaga({ payload }) {
  try {
    yield put(setZipcodesLoading(true));
    yield call(updateRegionRequest, payload.dissociate);
    yield call(updateRegionRequest, payload.associate);
    const response = yield call(
      getHubSingleDataRequest,
      hubPayload(payload.hubId)
    );
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setZipcodesLoading(false));
    successAlert('A região do CEP foi atualizada');
  } catch (err) {
    errorAlert(err);
  }
}

function* setCreateRegionSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCreateRegionRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/new-region' });
    const parsed = (
      (normalized?.meta && normalized?.meta['/new-region'].data) ||
      []
    ).map(object =>
      build(normalized, 'regions', object.id, { ignoreLinks: true })
    );
    // console.log(parsed);
    yield put(setCreateRegion(parsed));
    yield put(setIsLoading(false));
    successAlert('Nova região cadastrada');
  } catch (err) {
    // console.log(err);
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* deleteZipcodeSaga({ payload }) {
  try {
    yield put(setZipcodesLoading(true));
    yield call(deleteZipcodeRequest, payload.code);
    const response = yield call(
      getHubSingleDataRequest,
      hubPayload(payload.hub)
    );
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setZipcodesLoading(false));
    successAlert('CEP removido');
  } catch (err) {
    errorAlert(err);
  }
}

function* dowloadZipcodesSaga({ payload }) {
  try {
    const response = yield call(downloadZipcodesRequest, payload.key);
    const label = payload.label
      .normalize('NFD')
      .replace(/\s/g, '')
      .replace(/[\u0300-\u036f]/g, '');
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${label}.csv`;
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    errorAlert(err);
  }
}

function* uploadZipcodesSaga({ payload }) {
  const { id, csv, hubid } = payload;

  try {
    yield put(setZipcodesLoading(true));
    const data = new FormData();
    data.append('coverage_file', csv);
    yield call(uploadZipcodesRequest, { id, data });

    const response = yield call(getHubSingleDataRequest, hubPayload(hubid));
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setZipcodesLoading(false));
    successAlert('A lista de CEPs foi atualizada');
  } catch (err) {
    errorAlert(err);
  }
}

function* regionsListSaga() {
  yield takeEvery(getRegions, setRegionsSaga);
  yield takeEvery(getRegionSingle, setRegionSingleSaga);
  yield takeEvery(getSwitchZipcodeAssociation, setSwitchZipcodeAssociationSaga);
  yield takeEvery(getCreateRegion, setCreateRegionSaga);
  yield takeEvery(deleteZipcode, deleteZipcodeSaga);
  yield takeEvery(dowloadZipcodes, dowloadZipcodesSaga);
  yield takeEvery(uploadZipcodes, uploadZipcodesSaga);
}

export default regionsListSaga;
