import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attributes: [],
  editDepartment: [],
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false,
  isNew: false
};

const catalogDepartmentsSlice = createSlice({
  name: '@catalog-departments',
  initialState,
  reducers: {
    setDepartmentsAttributes(prevState, action) {
      const state = prevState;
      state.attributes = action.payload;
      state.isLoading = false;
      return state;
    },
    setEditDepartment(prevState, action) {
      const state = prevState;
      state.editDepartment = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setUpdateAttributesOnEditPage(prevState, action) {
      const state = prevState;
      state.editDepartment.included = action.payload;
      state.isNew = false;
      return state;
    },
    setUpdateAttributesOnNewPage(prevState, action) {
      const state = prevState;
      state.editDepartment = { included: action.payload };
      state.isNew = true;
      return state;
    },
    setIsNewAsFalse(prevState) {
      const state = prevState;
      state.isNew = false;
      return state;
    },
    getDepartmentAttributes(state) {
      return state;
    },
    getCreateDepartment(state) {
      return state;
    },
    getEdit(state) {
      return state;
    },
    getUpdateDepartment(state) {
      return state;
    },
    getArchiveAndUnarchive(state) {
      return state;
    }
  }
});

export const {
  setDepartmentsAttributes,
  setEditDepartment,
  setLoading,
  setPagination,
  setSort,
  setUpdateAttributesOnEditPage,
  setUpdateAttributesOnNewPage,
  setIsNewAsFalse,
  getDepartmentAttributes,
  getCreateDepartment,
  getEdit,
  getUpdateDepartment,
  getArchiveAndUnarchive,
  inactiveOrActiveDepartment
} = catalogDepartmentsSlice.actions;

export default catalogDepartmentsSlice.reducer;
