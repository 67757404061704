import { createSlice } from '@reduxjs/toolkit';
// import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  accessories: [],
  accessory: [],
  loading: false,
  query: {
    page: 1,
    perPage: 1000,
    search: ''
    // sort: ''
    // url: '',
    // filters: {}
  },
  count: 0
};

const accessoriesSlice = createSlice({
  name: '@accessories',
  initialState,
  reducers: {
    setAccessories(prevState, action) {
      const state = prevState;
      state.accessories = action.payload.accessories;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || ''
        // sort: action.payload.query.sort || ''
        // filters: action.payload.query.filters || {},
        // url: `?page=${action.payload.query.page || '1'}&per_page=${action
        //   .payload.query.perPage || state.query.perPage}${
        //   action.payload.query.search ? `&q=${action.payload.query.search}` : ''
        // }${
        //   action.payload.query.sort ? `&sort${action.payload.query.sort}` : ''
        // }${
        //   action.payload.query.filters
        //     ? buildQueryStringFromObj(action.payload.query.filters)
        //     : ''
        // }`
      };
      state.count = action.payload.count;
      return state;
    },
    setAccessory(prevState, action) {
      const state = prevState;
      state.accessory = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.loading = action.payload;
    },
    getAccessories(state) {
      return state;
    },
    getAccessory(state) {
      return state;
    },
    createAccessory(state) {
      return state;
    },
    updateAccessory(state) {
      return state;
    }
  }
});

export const {
  setAccessories,
  setAccessory,
  setLoading,
  getAccessories,
  getAccessory,
  createAccessory,
  updateAccessory
} = accessoriesSlice.actions;

export default accessoriesSlice.reducer;
