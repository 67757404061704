import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auditLog: [],
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    url: ''
  },
  isLoading: false
};

const auditLogSlice = createSlice({
  name: '@auditLog',
  initialState,
  reducers: {
    setAuditLog(prevState, action) {
      const state = prevState;
      state.auditLog = action.payload.auditLog;
      state.query = action.payload.query;
      state.count = action.payload.count;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    getAuditLog(state) {
      return state;
    }
  }
});

export const { getAuditLog, setIsLoading, setAuditLog } = auditLogSlice.actions;

export default auditLogSlice.reducer;
