import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getHubsData = payload => {
  const url = payload ? dispatchQueryBuilder(payload, '/hubs') : '/hubs';
  return apiDispatch.get(url);
};

export const getHubSingleData = payload => {
  const url = dispatchQueryBuilder(payload, `/hubs/${payload.id}`);
  return apiDispatch.get(url);
};

export const updateHub = payload =>
  apiDispatch.patch(
    `/hubs/${payload.data.id}?include=country,state,state.cities,city,district,districts,districts.city,districts.hub,regions`,
    payload
  );

export const getCreateHub = payload => apiDispatch.post('/hubs', payload);
