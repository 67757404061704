import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from '@/src/services/moment';

const ElapsedTime = ({ createdAt }) => {
  const [elapsedTime, setElapsedTime] = useState(
    moment().diff(moment(createdAt), 'seconds')
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(prevState => prevState + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span>
      {elapsedTime >= 86400
        ? moment.duration(elapsedTime, 'seconds').humanize()
        : moment.utc(elapsedTime * 1000).format('HH:mm:ss')}
    </span>
  );
};

ElapsedTime.propTypes = {
  createdAt: PropTypes.string.isRequired
};

export default ElapsedTime;
