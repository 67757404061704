import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getPromotions = payload => {
  const getUrl = queryBuilder(payload, '/promotions');
  return apiPortal.get(getUrl);
};

export const getCoupons = value => apiPortal.get(`/coupons?q=${value}`);

export const archivePromotion = payload =>
  apiPortal.post('/promotions_archivation/', { id: payload.promoId });

export const unarchivePromotion = payload =>
  apiPortal.delete(`/promotions_archivation/${payload.promoId}`);

export const createPromotion = payload =>
  apiPortal.post('/promotions', payload);

export const duplicatePromotion = payload =>
  apiPortal.post('/promotions_duplication', { id: payload.promoId });

export const getPromotion = payload =>
  apiPortal.get(`/promotions/${payload.promoId}`);
export const updatePromotion = payload =>
  apiPortal.patch(`/promotions/${payload.promoId}`, payload.promoObj);

export const getCouponsInPromotion = payload => {
  const url = `/promotions/${payload.promoId}/coupons`;
  const getUrl = queryBuilder(payload, url);
  return apiPortal.get(getUrl);
};
export const createCoupon = payload =>
  apiPortal.post(`/promotions/${payload.promotion_id}/coupons`, payload);

export const archiveCoupons = payload =>
  apiPortal.post(`/promotions/${payload.promoId}/coupons_archivation`, {
    ids: payload.ids
  });

export const unarchiveCoupons = payload =>
  apiPortal.delete(`/promotions/${payload.promoId}/coupons_archivation`, {
    data: { ids: payload.ids }
  });

export const generateCoupons = payload =>
  apiPortal.post(`/promotions/${payload.promoId}/coupons_mass_creation`, {
    quantity: payload.quantity
  });

/**
 * editCopoun old
 */
// export const editCoupon = payload =>
//   apiPortal.patch(`/promotions/${payload.promoId}/coupons/${payload.couponId}`, {
//     title: payload.title,
//     description: payload.description,
//     image: payload.image
//   });

export const editCoupon = payload =>
  apiPortal.post('/referral_coupon', payload);
