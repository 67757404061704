import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getOrdersData = payload => {
  const url = dispatchQueryBuilder(payload, '/orders');
  return apiDispatch.get(url);
};

export const getOrderById = payload => {
  // const url = `/orders/${payload.id}?extra_fields[orders]=customer_today_orders_count&include=volumes,volumes.volume_kind,volumes.volume_items,order_requests,order_requests.returned_items.order_item,order_items,trackings,order_invoices`;
  const url = dispatchQueryBuilder(payload, `/orders/${payload.id}`);
  return apiDispatch.get(url);
};

export const updateOrderStatus = payload => {
  // const url = `/orders/${payload.data.id}?extra_fields[orders]=customer_today_orders_count&include=volumes,volumes.volume_kind,volumes.volume_items,order_requests,order_requests.returned_items.order_item,order_items,trackings,order_invoices`;
  const url = dispatchQueryBuilder(payload, `/orders/${payload.data.id}`);
  return apiDispatch.patch(url, payload);
};

export const getVolumeKinds = () => apiDispatch.get(`/volume_kinds`);

export const setResolveReturn = payload =>
  apiDispatch.patch(`/orders/returns/resolve`, payload);

export const setRejectReturn = payload =>
  apiDispatch.patch(`/orders/returns/reject`, payload);

export const getHubsData = () => apiDispatch.get(`/hubs`);

export const commentOrderTimeline = payload => {
  return apiDispatch.post(`/orders/comments`, payload);
};

export const getProductScan = payload =>
  apiDispatch.post('/orders/products/scan', payload);

export const getUndoProductScan = payload =>
  apiDispatch.put('/orders/products/scan', payload);

export const getOrderReshipments = payload =>
  apiDispatch.get(`/reshipments?filter[order_number]=${payload}`);
