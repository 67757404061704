import { takeEvery, put, call } from 'redux-saga/effects';

import {
  setHubs,
  getHubs,
  setIsLoading,
  exportCSVHubSkus
} from '@/src/store/modules/hubs/slice';

import getHubsRequest from '@/src/api/hubs';
import { exportCSVHubSkus as exportCSVHubSkusRequest } from '@/src/api/export-file';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getHubsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getHubsRequest, payload);
    yield put(setHubs(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* exportCSVHubSkusSaga({ payload }) {
  try {
    yield call(exportCSVHubSkusRequest, payload);
    successAlert('Após processamento, o arquivo será enviado por e-mail');
  } catch (err) {
    // console.log(err);
    errorAlert(err);
  }
}

function* hubsSaga() {
  yield takeEvery(getHubs, getHubsSaga);
  yield takeEvery(exportCSVHubSkus, exportCSVHubSkusSaga);
}

export default hubsSaga;
