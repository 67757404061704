import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  Menu,
  Dropdown,
  Icon
} from 'antd';
import PropTypes from 'prop-types';

import {
  getProduct,
  setHubSkus
} from '@/src/store/modules/product-details/slice';
import { setAllProducts } from '@/src/store/modules/products-all/slice';
import { getCatalogAttributes } from '@/src/store/modules/catalog-categories/slice';
import ProductDetailsForm from '@/src/components/Products/ProductDetailsForm';
import SeoForm from '@/src/components/Products/SeoForm';
import AttributesCard from '@/src/components/Products/AttributesCard';
import SkuCard from '@/src/components/Products/SkuCard';
import GiftCard from '@/src/components/Products/GiftCard/index';
import UploadImage from '@/src/components/UploadImage/index';
import Audit from '@/src/components/Audit/index';
import useSaveProductEdits from '@/src/hooks/useSaveProductEdit';
import { handleProperties } from '@/src/utils/products';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const EditProduct = ({ form }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields
  } = form;

  const [pageTitle, setPageTitle] = useState('');
  const [thumbnail, setThumbnail] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const [specialContentImages, setSpecialContentImages] = useState([]);
  const [ifoodImagesArr, setIfoodImagesArr] = useState([]);
  const [availableProperties, setAvailableProperties] = useState([]); // atributos associados a categoria (principal e secundária) do produto
  const [productProperties, setProductProperties] = useState([]);
  const [propertiesSelected, setPropertiesSelected] = useState([]);
  const [propertiesUnselected, setPropertiesUnselected] = useState([]);
  const [skuChanges, setSkuChanges] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [showAudit, setShowAudit] = useState(false);

  // salechannel x sku visibility
  const [salechannel, setSalechannel] = useState([]);

  const { saveAllEdits } = useSaveProductEdits();
  const { editPermission } = usePermissions();

  const catalogPermission = editPermission(modules.CATALOG);

  const { product, isLoading, updated, hubSkus } = useSelector(
    state => state.productDetails
  );
  const { attributes } = useSelector(state => state.catalogCategories);

  useEffect(() => {
    dispatch(getProduct(id));
    dispatch(
      setAllProducts({
        productsList: [],
        count: 0
      })
    );
    return () => {
      dispatch(setHubSkus([]));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (product.id) {
      const {
        images,
        experienceImages,
        ifoodImages,
        categoryId,
        extraCategoriesIds,
        // properties,
        // selectedValues,
        // productProperties,
        thumb,
        title
      } = product;
      if (categoryId) {
        const extraCategories =
          extraCategoriesIds?.length > 0 ? extraCategoriesIds : [];
        dispatch(
          getCatalogAttributes({
            query: {
              filters: {
                by_categories: [categoryId, ...extraCategories]
              }
            }
          })
        );
      }

      setPageTitle(title);
      setThumbnail(thumb.url ? [thumb] : []);
      setCarouselImages(images);
      setSpecialContentImages(experienceImages);
      setIfoodImagesArr(ifoodImages);
    }
    // eslint-disable-next-line
  }, [product]);

  // const handleThumbnailChange = image => {
  //   setThumbnail(image.length ? image : []);
  //   // if (image.length) return setThumbnail([image[image.length - 1]]);
  //   // return setThumbnail([]);
  // };

  // const handleCarouselChange = image => {
  //   setCarouselImages(image.length ? image : []);

  //   // if (image.length) return setCarouselImages(image);
  //   // return setCarouselImages([]);
  // };

  // const handleSpecialContent = image => {
  //   setSpecialContentImages(image.length ? image : []);

  //   // if (image.length) return setSpecialContentImages(image);
  //   // return setSpecialContentImages([]);
  // };

  // const handleIfoodImages = image => {
  //   setIfoodImagesArr(image.length ? image : []);

  //   // if (image.length) return setIfoodImagesArr(image);
  //   // return setIfoodImagesArr([]);
  // };

  const handleDeleteImage = imageId => {
    if (imageId) {
      const imagesIds = imagesToDelete;
      imagesIds.push(imageId.toString());
      setImagesToDelete(imagesIds);
    }
  };

  const handleAttributesCheckbox = (itemChecked, itemID) => {
    if (itemChecked) {
      const newSelected = propertiesSelected.filter(item => item !== itemID);
      const newUnselected = propertiesUnselected.includes(itemID)
        ? propertiesUnselected
        : [...propertiesUnselected, itemID];
      setPropertiesSelected(newSelected);
      setPropertiesUnselected(newUnselected);
    } else {
      const newSelected = propertiesSelected.includes(itemID)
        ? propertiesSelected
        : [...propertiesSelected, itemID];
      const newUnselected = propertiesUnselected.filter(
        item => item !== itemID
      );
      setPropertiesSelected(newSelected);
      setPropertiesUnselected(newUnselected);
    }
  };

  useEffect(() => {
    // console.log(catalogCategories);
    // const { attributes } = catalogCategories;
    if (attributes?.length > 0) {
      setAvailableProperties(attributes);
      const productAttr = handleProperties(product, attributes);
      setProductProperties(productAttr);
      const selectedProperties = productAttr
        .filter(item => item.selected_id)
        .map(item => item.id);
      setPropertiesSelected(selectedProperties);

      // const propertiesList = attributes.map(attribute => {
      //   return {
      //     id: attribute.attributes.id,
      //     name: attribute.attributes.name
      //   };
      // });
      // if (propertiesList.length) {
      //   setAvailableProperties(propertiesList);
      // }
      // check if catalogCategory id is the same as product (after categories dropdown onChange it should be different)
      // if (
      //   product &&
      //   product?.attributes &&
      //   category_id &&
      //   product?.categoryId !== category_id
      // ) {
      //   setPropertiesSelected([]);
      //   setPropertiesUnselected(propertiesSelected);
      //   setProductProperties([]);
      // }
    }
    // eslint-disable-next-line
  }, [attributes]);

  const changeAttributes = value => {
    dispatch(
      getCatalogAttributes({
        query: {
          filters: {
            by_categories: value
          }
        }
      })
    );
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const changeSKUName = (skuName, skuId) => {
    let newSkuData = skuChanges;

    if (newSkuData.length) {
      if (newSkuData.some(sku => sku.id === skuId))
        newSkuData = newSkuData.filter(sku => sku.id !== skuId);

      newSkuData.push({ id: skuId, name: skuName });
    } else {
      newSkuData.push({ id: skuId, name: skuName });
    }

    setSkuChanges(newSkuData);
  };

  const handleModalOk = () => {
    validateFields((err, values) => {
      if (err) setShowModal(false);
      if (!err) {
        setPageTitle(values.title);
        const allValues = { ...values, search_metadata: tags.join(',') };
        saveAllEdits(
          id,
          allValues,
          thumbnail,
          carouselImages,
          specialContentImages,
          ifoodImagesArr,
          imagesToDelete,
          propertiesSelected,
          propertiesUnselected,
          skuChanges,
          hubSkus
        );
        setShowModal(false);
      }
    });
  };

  const handleImageError = e => {
    e.persist();
    if (e.type === 'error') {
      e.target.alt = '';
    }
  };

  return (
    <>
      <Audit
        id={id}
        title="PRODUTO"
        type="Product"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Modal
        title="SALVAR ALTERAÇÕES NO APP"
        okText="Confirmar"
        centered
        className="centered-footer"
        onOk={handleModalOk}
        onCancel={() => setShowModal(false)}
        visible={showModal}
        bodyStyle={{ padding: '16px 24px 36px' }}
        width={400}
      >
        <span>
          Tem certeza que deseja salvar as alterações? Todas as mudanças feitas
          serão espelhadas no app.
        </span>
      </Modal>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>{pageTitle}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/produtos">Produtos</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Form>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '16px',
              border: '7px solid #ebebeb',
              backgroundColor: '#ebebeb',
              marginBottom: '60px'
            }}
          >
            <Row
              gutter={7}
              className="two-columns card-margin form-edit-produto"
            >
              <Col
                className="no-shadow"
                span={12}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Card
                  className="no-shadow box card-margin"
                  title="IMAGEM THUMB"
                  loading={isLoading}
                >
                  <span className="card-subtitle">
                    *Tamanho recomendado: 400px x 400px
                  </span>
                  <UploadImage
                    isMultiple
                    type="thumbnail"
                    size="400x400"
                    uploadImage={thumbnail}
                    setUploadImage={setThumbnail}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
                <Card
                  className="no-shadow box card-margin"
                  title="IMAGENS CARROSSEL"
                  loading={isLoading}
                >
                  <span className="card-subtitle">
                    *Tamanho recomendado: 1000px x 1000px
                  </span>
                  <UploadImage
                    isMultiple
                    type="carousel"
                    size="1000x1000"
                    uploadImage={carouselImages}
                    setUploadImage={setCarouselImages}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
                <Card
                  className="no-shadow box card-margin"
                  title="IMAGENS CONTEÚDO ESPECIAL"
                  loading={isLoading}
                >
                  <span className="card-subtitle">
                    *Tamanho recomendado: altura livre x 800px
                  </span>
                  <UploadImage
                    isMultiple
                    type="special_content"
                    size="altura livre x 800"
                    uploadImage={specialContentImages}
                    setUploadImage={setSpecialContentImages}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
                <Card
                  className="no-shadow box card-margin"
                  title="IMAGEM IFOOD"
                  loading={isLoading}
                >
                  <span className="card-subtitle">
                    *Tamanho obrigatório: 1060px x 800px
                  </span>
                  <UploadImage
                    isMultiple
                    type="ifood"
                    size="1060x800"
                    uploadImage={ifoodImagesArr}
                    setUploadImage={setIfoodImagesArr}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
                <Card
                  className="no-shadow box"
                  title="SEO (WEB)"
                  loading={isLoading}
                  style={{ flexGrow: 1 }}
                >
                  <SeoForm
                    content={product}
                    getFieldDecorator={getFieldDecorator}
                    setFieldsValue={setFieldsValue}
                    editPermission={catalogPermission}
                  />
                </Card>
              </Col>
              <Col className="no-shadow" span={12}>
                <Card
                  className="no-shadow box"
                  title="DETALHES"
                  bordered={false}
                  loading={isLoading}
                  extra={catalogPermission && actionsExtra}
                  style={{ height: '100%', flexGrow: 1 }}
                >
                  <ProductDetailsForm
                    content={product}
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    changeAttributes={changeAttributes}
                    editPermission={catalogPermission}
                    tags={tags}
                    setTags={setTags}
                    setSalechannel={setSalechannel}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} className="card-margin">
              <AttributesCard
                properties={availableProperties}
                productProperties={productProperties}
                handleCheckbox={handleAttributesCheckbox}
                loading={isLoading}
                editPermission={catalogPermission}
              />
            </Row>
            <Row gutter={16}>
              {product?.skus?.map(sku => {
                return (
                  <SkuCard
                    data={sku}
                    key={sku.id}
                    changeSKUName={changeSKUName}
                    loading={isLoading}
                    editPermission={catalogPermission}
                    salechannel={salechannel}
                  />
                );
              })}
            </Row>
            <Row gutter={16}>
              <GiftCard product={product} loading={isLoading} />
            </Row>
          </div>
          {catalogPermission && (
            <footer className="footer-form-save">
              <Button
                className="ant-btn ant-btn-primary"
                style={{ left: '80%' }}
                onClick={() => setShowModal(true)}
                loading={updated}
              >
                Salvar Alterações
              </Button>
            </footer>
          )}
        </Form>
      </div>
    </>
  );
};

EditProduct.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewProductFrom = Form.create({ name: 'EditProduct' })(EditProduct);

export default NewProductFrom;
