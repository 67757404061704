import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getGatewayStores = payload => {
  const getUrl = queryBuilder(payload, '/gateway_stores');
  return apiPortal.get(getUrl);
};

export const getGatewayStore = id => apiPortal.get(`/gateway_stores/${id}`);

export const createGatewayStore = payload =>
  apiPortal.post('/gateway_stores', payload);

export const updateGatewayStore = payload =>
  apiPortal.put(`/gateway_stores/${payload.id}`, payload.data);
