import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountEmail: '',
  errMessage: '',
  isLoading: false
};

const forgotPasswordSlice = createSlice({
  name: '@forgotPassword',
  initialState,
  reducers: {
    getRecoverPassword(state) {
      return state;
    },
    getResetPassword(state) {
      return state;
    },
    setAccountEmail(prevState, action) {
      const state = prevState;
      state.accountEmail = action.payload;
      return state;
    },
    setErrMessage(prevState, action) {
      const state = prevState;
      state.errMessage = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    }
  }
});

export const {
  getRecoverPassword,
  getResetPassword,
  setAccountEmail,
  setErrMessage,
  setLoading
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
