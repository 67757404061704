import { apiAuth, apiPortal } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

// export const getUserBookmarks = userId => api.get(`/users/${userId}/bookmarks`);

// export const saveUserBookmark = newBookmark =>
//   api.post(`/users/${newBookmark.user_id}/bookmarks`, newBookmark);

// export const removeUserBookmark = deleteBookmark =>
//   api.delete(
//     `/users/${deleteBookmark.user_id}/bookmarks/${deleteBookmark.bookmark_id}`
//   );

export const dispatchResetPassword = payload =>
  apiPortal.post('/send_password_reset_email', payload);

export const signInPost = payload => {
  const header = {
    headers: {
      'grant-type': 'password',
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID
    }
  };
  return apiAuth.post('auth/signin', payload, header);
};

export const getUser = userId =>
  apiAuth.get(`/users/${userId}?include=permissions`);

export const recoverPassword = payload =>
  apiAuth.post('/auth/recover_password', payload);

export const resetPassword = payload =>
  apiAuth.post('/auth/reset_password', payload);

export const changePassword = payload =>
  apiAuth.patch('/auth/change_password', payload);

export const getUsers = payload => {
  const url = dispatchQueryBuilder(payload, '/users');
  return apiAuth.get(url);
};

export const createUser = user => apiAuth.post('/users', user);

export const updateUser = payload =>
  apiAuth.patch(`/users/${payload.data.id}?include=permissions`, payload);

export const getPermissions = () => apiAuth.get('/permissions?page[size]=50');
