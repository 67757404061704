import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  state: {},
  isLoading: false
};

const statesSlice = createSlice({
  name: '@statesData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setStates(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setStateSingle(prevState, action) {
      const state = prevState;
      state.state = action.payload;
      return state;
    },
    getStates(state) {
      return state;
    },
    getStateSingle(state) {
      return state;
    }
  }
});

export const {
  setIsLoading,
  setStates,
  setStateSingle,
  getStates,
  getStateSingle
} = statesSlice.actions;

export default statesSlice.reducer;
