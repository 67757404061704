import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Card, Button, Modal, Table, Tooltip } from 'antd';

import { deleteGift as deleteGiftRequest } from '@/src/store/modules/product-details/slice';

import { ReactComponent as EditIcon } from '@/src/assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '@/src/assets/images/delete.svg';

import GiftModal from './GiftModal';

const GiftCard = ({ product }) => {
  const dispatch = useDispatch();

  const { gifts, isLoadingGifts } = useSelector(state => state.productDetails);

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState({});
  const [deleteGift, setDeleteGift] = useState('');

  const handleModalOk = () => {
    dispatch(deleteGiftRequest(deleteGift));
    setDeleteGift('');
  };

  const columns = [
    {
      title: 'Sku participante',
      width: '45%',
      dataIndex: 'requirementable_name',
      key: 'requirementable_name',
      render: data => (
        <span>
          {product.title} - {data}
        </span>
      )
    },
    {
      title: 'Item enviado',
      width: '45%',
      key: 'gift',
      render: data => (
        <span>
          {data.gift_product_name} - {data.gift_name}
        </span>
      )
    },
    {
      key: 'action',
      width: '10%',
      render: data => (
        <div className="tableEditDelete">
          <button
            type="button"
            className="tableEditDelete__edit"
            onClick={() => {
              setEditModal(data);
              setShowModal(true);
            }}
            aria-label="Editar"
          >
            <EditIcon />
          </button>
          <button
            type="button"
            className="tableEditDelete__delete"
            onClick={() => setDeleteGift(data.id)}
            aria-label="Deletar"
          >
            <DeleteIcon />
          </button>
        </div>
      )
    }
  ];

  return (
    <Col className="no-shadow">
      <Card
        className="no-shadow box"
        title="AÇÃO COMPRE E GANHE"
        bordered={false}
      >
        <Modal
          title="Excluir"
          okText="Excluir"
          centered
          className="centered-footer"
          onOk={handleModalOk}
          onCancel={() => setDeleteGift('')}
          visible={deleteGift !== ''}
          bodyStyle={{ padding: '16px 24px 36px' }}
        >
          Tem certeza que deseja excluir o item?
        </Modal>
        <GiftModal
          productName={product.title}
          skus={product?.skus}
          showModal={showModal}
          setShowModal={setShowModal}
          editModal={editModal}
          setEditModal={setEditModal}
        />
        {product?.skus?.filter(sku => !sku.gift).length > 0 ? (
          <Button type="primary" onClick={() => setShowModal(true)}>
            Adicionar
          </Button>
        ) : (
          <Tooltip
            placement="right"
            title="Todos os skus já estão associados à ação. Você pode editar ou deletar um dos itens na tabela"
          >
            <Button type="primary" disabled>
              Adicionar
            </Button>
          </Tooltip>
        )}
        {!!gifts.length && (
          <Table
            loading={isLoadingGifts}
            style={{ marginTop: 20 }}
            columns={columns}
            dataSource={gifts}
            pagination={false}
          />
        )}
      </Card>
    </Col>
  );
};

GiftCard.defaultProps = {
  product: {}
};

GiftCard.propTypes = {
  product: PropTypes.objectOf(PropTypes.any)
};

export default GiftCard;
