import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  isLoading: false
};

const zipcodesSlice = createSlice({
  name: '@zipcodesData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setZipcodes(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    getZipcodes(state) {
      return state;
    }
  }
});

export const { setIsLoading, setZipcodes, getZipcodes } = zipcodesSlice.actions;

export default zipcodesSlice.reducer;
