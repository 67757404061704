import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  referrals: [],
  graphs: {},
  isLoadingGraphs: false,
  metadata: {
    count: 0,
    discount_value: 0,
    minimum_value: 0,
    reward_value: 0,
    total: 0
  },
  total: 0,
  count: 0,
  selectedFilters: false,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

const referralsSlice = createSlice({
  name: '@referrals',
  initialState,
  reducers: {
    getReferrals(state) {
      return state;
    },
    updateReferrals(state) {
      return state;
    },
    setReferrals(prevState, action) {
      const state = prevState;
      state.referrals = action.payload.referrals;
      state.total = action.payload.total;
      state.count = action.payload.count;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    setReferralsMetadata(prevState, action) {
      const state = prevState;
      state.metadata = action.payload.metadata;
      return state;
    },
    getReferralsMetadata(state) {
      return state;
    },
    getReferralsGraphs(state) {
      return state;
    },
    setReferralsGraphs(prevState, action) {
      const state = prevState;
      state.graphs = action.payload.graphs;
      state.isLoadingGraphs = false;
      return state;
    },
    setReferralsIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setReferralsGraphsIsLoading(prevState) {
      const state = prevState;
      state.isLoadingGraphs = true;
      return state;
    },
    invalidateReferral(state) {
      return state;
    },
    banishReferral(state) {
      return state;
    },
    setPagination(state) {
      return state;
    },
    setSort(state) {
      return state;
    }
  }
});

export const {
  banishReferral,
  invalidateReferral,
  setReferralsGraphsIsLoading,
  setReferralsIsLoading,
  setReferralsGraphs,
  getReferralsGraphs,
  getReferralsMetadata,
  setReferralsMetadata,
  setReferrals,
  updateReferrals,
  getReferrals,
  setPagination,
  setSort
} = referralsSlice.actions;

export default referralsSlice.reducer;
