import React from 'react';
import { Tooltip } from 'antd';

const logSkuPetz = [
  {
    title: 'UUID',
    dataIndex: 'handle',
    width: '100px',
    render: data => (
      <Tooltip title={data}>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block'
          }}
        >
          {data}
        </span>
      </Tooltip>
    )
  },
  {
    title: 'Ação',
    dataIndex: 'name'
  },
  {
    title: 'Processamento',
    dataIndex: 'processed_at'
  },
  {
    title: 'Criação',
    dataIndex: 'created_at'
  },
  {
    title: 'Atualização',
    dataIndex: 'updated_at'
  },
  {
    title: 'ID Hub',
    dataIndex: 'store_id',
    width: '100px'
  },
  {
    title: 'ID Produto',
    dataIndex: 'product_id'
  },
  {
    title: 'Payload',
    key: 'payload',
    render: (data, record) => (
      <button
        type="button"
        style={{
          padding: 0,
          border: 'none',
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          cursor: 'pointer',
          margin: '4px 0'
        }}
        onClick={() => record.handlePetzEventModal(data.payload)}
      >
        Ver evento original
      </button>
    )
  }
];

export default logSkuPetz;
