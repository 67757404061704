import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Audit from '@/src/components/Audit/index';
import { Table, Modal, Avatar, Dropdown, Menu, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import Searchbar from '@/src/components/Searchbar/Searchbar';

import formatCurrency from '@/src/utils/formatCurrency';

import { getPriceTableProducts } from '@/src/store/modules/price-tables/slice';

const PriceTable = ({ showModal, setShowModal, tableData, setTableData }) => {
  const dispatch = useDispatch();

  const [productsData, setProductsData] = useState([]);
  const [productSearch, setProductSearch] = useState('');

  const [showAudit, setShowAudit] = useState(false);

  const {
    priceTableProducts,
    productsCount,
    productsQuery,
    productsLoading
  } = useSelector(state => {
    return state.priceTables;
  });

  useEffect(() => {
    setProductsData(priceTableProducts);
  }, [priceTableProducts]);

  const handleCloseTable = () => {
    setShowModal(false);
    setTableData({});
    setProductsData([]);
    setProductSearch('');
  };

  const handleProductSearch = e => {
    const { search } = e.query;
    setProductSearch(search);
    dispatch(
      getPriceTableProducts({
        query: {
          page: 1,
          perPage: 10,
          filters: search ? { by_product_name: search } : {},
          price_table_id: tableData.id
        }
      })
    );
  };

  const columns = [
    {
      title: 'Foto',
      align: 'center',
      dataIndex: 'attributes.thumb',
      width: 80,
      render: (value, record) => {
        return (
          <Avatar
            shape="square"
            size={40}
            src={value}
            alt={record.attributes.name}
            icon="camera"
            className="img-placeholder"
          />
        );
      }
    },
    {
      title: 'Name',
      align: 'left',
      dataIndex: 'attributes.name'
    },
    {
      title: 'Preço Base',
      align: 'center',
      dataIndex: 'attributes.list_price',
      render: value => formatCurrency(value),
      width: 150
    },
    {
      title: 'Preço Promocional',
      align: 'center',
      dataIndex: 'attributes.sale_price',
      render: value => formatCurrency(value),
      width: 150
    },
    {
      title: 'ID SKU',
      align: 'center',
      dataIndex: 'attributes.sku_id',
      width: 80
    }
  ];

  const buildTableValidity = () => {
    const { start_date, end_date } = tableData;
    const startDate = start_date
      ? moment(start_date).format('DD/MM/YYYY (HH:mm)')
      : '';
    const endDate = end_date
      ? moment(end_date).format('DD/MM/YYYY (HH:mm)')
      : 'Tabela Padrão';
    const validity = startDate ? `${startDate} - ${endDate}` : endDate;
    return <p>{validity}</p>;
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );
  const userActions = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <Modal
      visible={showModal}
      className="ant-modal-price-tables"
      title={`${tableData.name || 'Tabela'}${
        tableData.id ? ` - ID ${tableData.id}` : ''
      }`}
      centered
      width={900}
      bodyStyle={{ height: 650 }}
      onCancel={handleCloseTable}
      footer={null}
    >
      {tableData.validityFormated && <p>{tableData.validityFormated}</p>}
      {buildTableValidity()}
      <Row type="flex" align="end">
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            marginBottom: '8px'
          }}
        >
          {userActions}
        </div>
      </Row>
      <Audit
        id={tableData.id || ''}
        title="TABELAS"
        type="PriceTable"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Searchbar
        placeholder="Buscar nome do produto"
        populateResult={e => handleProductSearch(e)}
        query={{ search: productSearch }}
        value={productSearch}
      />
      <Table
        columns={columns}
        dataSource={productsData}
        className="even-gray"
        size="middle"
        rowKey={record => record.id}
        // history={history}
        query={productsQuery}
        loading={productsLoading}
        style={{ marginTop: '30px' }}
        count={productsCount}
        pagination={{
          total: productsCount,
          onChange: e => {
            dispatch(
              getPriceTableProducts({
                query: {
                  ...productsQuery,
                  page: e
                }
              })
            );
          }
        }}
        scroll={{ y: 410 }}
      />
    </Modal>
  );
};

// PriceTable.defaultProps = {
//   tableData: {
//     id: '',
//     name: '',
//     validity: ''
//   }
// };

PriceTable.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  tableData: PropTypes.objectOf(PropTypes.any).isRequired,
  setTableData: PropTypes.func.isRequired
};

export default PriceTable;
