import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import moment from '@/src/services/moment';

const ProgressBar = ({ stepName, progressModal, orderEta, orderStatus }) => {
  const [percent, setPercent] = useState(0);
  const [strokeColor, setStrokeColor] = useState('#333333');

  const { pageLoading } = useSelector(state => state.expedition);

  const handlePercentageByETA = useCallback(
    order => {
      if (orderStatus === 'delivered') {
        setStrokeColor('#50ebc5');
      } else if (order.hasEtaAlert) {
        setStrokeColor('#FF365C');
      } else if (orderStatus === 'dispatched' || orderStatus === 'delivering') {
        setStrokeColor('#b3b3b3');
      } else {
        setStrokeColor('#333333');
      }
      // setStrokeColor(order.hasEtaAlert ? '#FF365C' : '#61edae');
      const diff = moment().diff(moment(order.createdAt), 'minutes');
      const progress = (diff * 100) / order.etaMaxValue;
      setPercent(progress);
    },
    [orderStatus]
  );

  const handlePercentageByStep = useCallback(step => {
    switch (step) {
      case 'Iniciar':
        setPercent(15);
        break;
      case 'Coletar':
        setPercent(35);
        break;
      case 'Empacotar' || 'Devolver':
        setPercent(67);
        break;
      case 'Pronto':
        setPercent(100);
        break;
      default:
        setPercent(0);
    }
  }, []);

  useEffect(() => {
    if (progressModal) {
      handlePercentageByStep(stepName);
    } else {
      handlePercentageByETA(orderEta);
    }
  }, [
    handlePercentageByETA,
    handlePercentageByStep,
    progressModal,
    stepName,
    orderEta
  ]);

  return (
    <div style={{ margin: progressModal ? '0 12px' : '0px' }}>
      <Progress
        percent={percent}
        showInfo={false}
        strokeColor={pageLoading ? '#b3b3b3' : strokeColor}
        // trailColor="#D8D8D8"
        style={{ margin: '0px', padding: '0px' }}
        className={
          orderStatus === 'dispatched' ||
          orderStatus === 'delivering' ||
          orderStatus === 'delivered'
            ? 'dark-trail'
            : 'light-trail'
        }
      />
      {progressModal && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Estoquista</span>
          <span>Coletar</span>
          <span>Empacotar</span>
          <span>Pronto</span>
        </div>
      )}
    </div>
  );
};

ProgressBar.defaultProps = {
  orderEta: {},
  orderStatus: '#333333'
};

ProgressBar.propTypes = {
  stepName: PropTypes.string.isRequired,
  progressModal: PropTypes.bool.isRequired,
  orderEta: PropTypes.objectOf(PropTypes.any),
  orderStatus: PropTypes.string
};

export default ProgressBar;
