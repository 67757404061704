import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';
import moment from '@/src/services/moment';

import formatCurrency from '@/src/utils/formatCurrency';

const Access = props => {
  const { metadata } = props;

  const {
    average_ticket_price,
    last_active,
    months_ago,
    orders_count,
    session_count,
    total_revenue
  } = metadata;

  const [lastActive, setLastActive] = useState('-');

  useEffect(() => {
    if (last_active === 0) {
      setLastActive('nenhum acesso');
    } else if (last_active) {
      const date = moment(last_active)
        .fromNow(true)
        .replace('um ', '1 ')
        .replace('uma ', '1 ');
      setLastActive(date);
    }
  }, [last_active]);

  return (
    <Card className="car-access no-shadow">
      <Row type="flex" style={{ margin: '21px' }}>
        <Col span={6}>
          <p style={{ marginBottom: '3px' }}>Último Acesso</p>
          <p
            style={{
              marginBottom: '3px',
              fontSize: '22px',
              color: '#50ebc5',
              fontWeight: '700'
            }}
          >
            {lastActive}
          </p>
          <p style={{ marginBottom: '3px' }}>Cadastro há {months_ago} meses</p>
          <p style={{ marginBottom: '3px' }}>
            <strong>{session_count}</strong> acessos totais
          </p>
          {/* <p style={{ marginBottom: '3px' }}>
            <strong>56</strong> eventos
          </p> */}
        </Col>
        {/* <Col>
          <p style={{ marginBottom: '3px' }}>Última Notificação Recebida</p>
          <p
            style={{
              marginBottom: '3px',
              fontSize: '22px',
              color: '#723aeb',
              fontWeight: '700'
            }}
          >
            10 dias
          </p>
          <p style={{ marginBottom: '3px' }}>
            <strong>23</strong> Receita Total
          </p>
          <p style={{ marginBottom: '3px' }}>
            <strong>6</strong> aberturas
          </p>
          <p style={{ marginBottom: '3px' }}>
            <strong>13</strong> cliques
          </p>
          <p style={{ marginBottom: '3px' }}>
            <strong>1</strong> conversão
          </p>
        </Col> */}
        <Col>
          <p style={{ marginBottom: '3px' }}>Receita Total</p>
          <p
            style={{
              marginBottom: '3px',
              fontSize: '22px',
              color: '#28a8bc',
              fontWeight: '700'
            }}
          >
            {formatCurrency(total_revenue)}
          </p>
          <p style={{ marginBottom: '3px' }}>
            <strong>{orders_count}</strong>{' '}
            {orders_count === 1 || orders_count === 0 ? 'compra' : 'compras'}
          </p>
          <p style={{ marginBottom: '3px' }}>
            Ticket médio de{' '}
            <strong>{formatCurrency(average_ticket_price)}</strong>
          </p>
        </Col>
      </Row>
    </Card>
  );
};

Access.propTypes = {
  metadata: PropTypes.shape({
    average_ticket_price: PropTypes.number,
    last_active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    months_ago: PropTypes.number,
    orders_count: PropTypes.number,
    session_count: PropTypes.number,
    total_revenue: PropTypes.number
  }).isRequired
};

export default Access;
