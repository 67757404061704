import React from 'react';
import { Card, /* Button */ Avatar, Row, Col /* Icon */ } from 'antd';
import PropTypes from 'prop-types';

import moment from '@/src/services/moment';

const Pets = props => {
  const { pets } = props;

  return (
    <Card
      className="no-shadow"
      title={<h2>Pets</h2>}
      // extra={<Button type="primary">Adicionar Pet</Button>}
    >
      {pets.length > 0 ? (
        <>
          {pets.map(pet => (
            <div key={pet.id}>
              <Row type="flex">
                <Col>
                  <Avatar
                    src={pet?.attributes?.image ? pet?.attributes?.image : null}
                    size="large"
                  />
                </Col>
                <Col span={16}>
                  <section style={{ marginLeft: 10 }}>
                    <p style={{ marginBottom: '3px' }}>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {pet?.attributes?.name}
                      </strong>
                    </p>
                    <p style={{ marginBottom: '3px' }}>
                      {pet?.attributes?.species.toLowerCase() === 'gato'
                        ? 'Gato'
                        : 'Cachorro'}
                    </p>
                    <p style={{ marginBottom: '3px' }}>
                      {pet?.attributes?.breed}
                    </p>
                    <p style={{ marginBottom: '3px' }}>
                      {pet?.attributes?.gender === 'm' ? 'Macho' : 'Fêmea'}
                    </p>
                    <p style={{ marginBottom: '3px' }}>
                      {pet?.attributes?.birthday &&
                        moment(pet?.attributes?.birthday).format('MMMM YYYY')}
                    </p>
                  </section>
                </Col>
                <Col offset={3}>
                  {/* <Button type="link" style={{ color: '#737373', padding: 0 }}>
                    <Icon style={{ marginLeft: '4px' }} type="edit" theme="filled" />
                  </Button> */}
                </Col>
              </Row>
              <hr
                key={pet.type}
                style={{ marginTop: '8px', marginBottom: '8px' }}
              />
            </div>
          ))}
        </>
      ) : (
        <p>Esse cliente não tem pet</p>
      )}
    </Card>
  );
};

Pets.defaultProps = {
  pets: []
};

Pets.propTypes = {
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        gender: PropTypes.string,
        breed: PropTypes.string,
        species: PropTypes.string,
        birthday: PropTypes.string,
        image: PropTypes.string
      })
    })
  )
};

export default Pets;
