import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priceTables: [],
  priceTableProducts: [],
  priceTablesSelect: [],
  priceTablesCount: 0,
  productsCount: 0,
  productsQuery: {
    page: 1,
    perPage: 10
  },
  productsLoading: false,
  priceTablesQuery: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  priceTablesLoading: false,
  priceTablesSelectLoading: false
};

const priceTablesSlice = createSlice({
  name: '@priceTables',
  initialState,
  reducers: {
    setPriceTablesLoading(prevState, action) {
      const state = prevState;
      state.priceTablesLoading = action.payload;
      return state;
    },
    setPriceTablesSelectLoading(prevState, action) {
      const state = prevState;
      state.priceTablesSelectLoading = action.payload;
      return state;
    },
    setProductsLoading(prevState, action) {
      const state = prevState;
      state.productsLoading = action.payload;
      return state;
    },
    setPriceTables(prevState, action) {
      const state = prevState;

      state.priceTables = action.payload.priceTables;
      state.isLoading = false;
      state.priceTablesCount = action.payload.count;

      state.priceTablesQuery = {
        page: Number(action.payload.query.page) || 1,
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search ? `&q=${action.payload.query.search}` : ''
        }`
      };

      return state;
    },
    setPriceTablesSelect(prevState, action) {
      const state = prevState;
      state.priceTablesSelect = action.payload;
      return state;
    },
    setPriceTable(prevState, action) {
      const { data } = action.payload.data;
      const state = prevState;
      state.priceTables = state.priceTables.map(item => {
        if (Number(item.id) === Number(data.id)) return data;
        return item;
      });
      return state;
    },
    setPriceTableProducts(prevState, action) {
      // console.log(action.payload.query);
      const state = prevState;
      state.priceTableProducts = action.payload.data;
      state.productsCount = action.payload.meta.count;
      state.productsQuery = action.payload.query;
    },
    getPriceTables(state) {
      return state;
    },
    getPriceTablesSelect(state) {
      return state;
    },
    getPriceTableProducts(state) {
      return state;
    },
    getUpdatePriceTable(state) {
      return state;
    },
    exportCSVPriceTable(state) {
      return state;
    }
  }
});

export const {
  setProductsLoading,
  setPriceTablesLoading,
  setPriceTablesSelectLoading,
  setPriceTables,
  setPriceTablesSelect,
  setPriceTable,
  setPriceTableProducts,
  getPriceTables,
  getPriceTablesSelect,
  getPriceTableProducts,
  getUpdatePriceTable,
  exportCSVPriceTable
} = priceTablesSlice.actions;

export default priceTablesSlice.reducer;
