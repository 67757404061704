import React from 'react';
import moment from '@/src/services/moment';

import ListTile from '@/src/components/Expedition/ListTile';

import formatCurrency from '@/src/utils/formatCurrency';

const shipping = data => {
  const tableData = {
    'Data do Embarque': moment(data.createdAt).format('DD/MM/YYYY - HH:mm:ss'),
    Transportadora: data.deliveryCompany.name,
    'Tipo de Frete': data.deliveryKind?.name || '-',
    'ID do Embarque': data.externalId,
    Hub: data.deliveries[0]?.order?.hub?.name || '-',
    'Valor total': data.deliveries[0]?.order?.total
      ? formatCurrency(data.deliveries[0].order.total, true)
      : '-'
  };
  if (!data.externalId) delete tableData['ID do Embarque'];
  return tableData;
};

const expedition = data => {
  return {
    'Data do pedido': moment(data.createdAt).format('DD/MM/YYYY - HH:mm:ss'),
    'Canal de venda': data.saleChannelName || 'Zee.Now',
    Hub: data.hubName,
    Etiqueta:
      data.status === 'separated' ||
      data.status === 'dispatched' ||
      data.status === 'delivering' ||
      data.status === 'delivered' ? (
        <ListTile typeContent="tag" orderData={data} />
      ) : (
        'Sem etiqueta'
      ),
    'Valor total': formatCurrency(data.total, true)
  };
};

const handleHeaderTable = (data, table) => {
  let columns;

  switch (table) {
    case 'shipping':
      columns = shipping(data);
      break;
    case 'expedition':
      columns = expedition(data);
      break;
    default:
      columns = {};
      break;
  }

  return Object.entries(columns);
};

export default handleHeaderTable;
