import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  attributes: [],
  attributeData: [],
  query: {
    // page: 1,
    // perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

const attributesSlice = createSlice({
  name: '@attributes',
  initialState,
  reducers: {
    setAttributes(prevState, action) {
      const state = prevState;
      state.attributes = action.payload.attributes;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}${
          action.payload.query.search ? `&q=${action.payload.query.search}` : ''
        }${
          action.payload.query.sort ? `&sort${action.payload.query.sort}` : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    setCreateAttribute(prevState, action) {
      const state = prevState;
      state.created = action.payload;
      return state;
    },
    setAttributeData(prevState, action) {
      const state = prevState;
      state.attributeData = action.payload;
    },
    // setCurrentValues(prevState, action) {
    //   const state = prevState;
    //   state.currentValues = action.payload;
    // },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    getAttributes(state) {
      return state;
    },
    getAttribute(state) {
      return state;
    },
    getCreateAttribute(state) {
      return state;
    },
    getUpdateAttribute(state) {
      return state;
    },
    getArchiveAndUnarchive(state) {
      return state;
    }
  }
});

export const {
  setAttributes,
  setCreateAttribute,
  setAttributeData,
  setLoading,
  setSort,
  setPagination,
  getAttributes,
  getAttribute,
  getCreateAttribute,
  getUpdateAttribute,
  getArchiveAndUnarchive
} = attributesSlice.actions;

export default attributesSlice.reducer;
