import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getPoliciesRequest = payload => {
  const getUrl = queryBuilder(payload, '/pricing_rules');
  return apiPortal.get(getUrl);
};

export const getPolicyRequest = payload => {
  return apiPortal.get(`/pricing_rules/${payload}`);
};

export const createPolicyRequest = payload => {
  return apiPortal.post(`/pricing_rules`, payload);
};

export const getPriceTablesRequest = payload => {
  const getUrl = queryBuilder(payload, '/price_tables');
  return apiPortal.get(getUrl);
};

export const updatePriceTableRequest = payload => {
  return apiPortal.patch(`/price_tables/${payload.id}`, payload);
};

export const getPriceTableProductsRequest = payload => {
  const getUrl = queryBuilder(payload, '/prices');
  return apiPortal.get(getUrl);
};

export const getPolicyPriceTablesRequest = payload => {
  const getUrl = queryBuilder(
    payload,
    `/pricing_rules/${payload.id}/price_tables`
  );
  return apiPortal.get(getUrl);
};

// export const getPolicyHubsRequest = payload => {
//   const getUrl = queryBuilder(payload, `/pricing_rules/${payload.id}/hubs`);
//   return apiPortal.get(getUrl);
// };

export const associatePolicyHub = payload => {
  return apiPortal.post(`/pricing_rules/${payload.policyId}/hubs`, {
    hub_id: payload.hubId
  });
};

export const removePolicyHubAssociation = payload => {
  return apiPortal.delete(
    `/pricing_rules/${payload.policyId}/hubs/${payload.hubId}`
  );
};

export const updatePolicyRequest = payload => {
  return apiPortal.patch(`/pricing_rules/${payload.id}`, payload);
};
