import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errMessage: '',
  loading: false
};

const changePasswordSlice = createSlice({
  name: '@changePassword',
  initialState,
  reducers: {
    getChangePassword(state) {
      return state;
    },
    setErrMessage(prevState, action) {
      const state = prevState;
      state.errMessage = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.loading = action.payload;
      return state;
    }
  }
});

export const {
  getChangePassword,
  setErrMessage,
  setLoading
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
