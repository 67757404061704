import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton, Collapse, Icon } from 'antd';
import moment from '@/src/services/moment';

import ListTile from '@/src/components/Expedition/ListTile';
import Footer from '@/src/components/Expedition/Card/Footer';
import ProgressBar from '@/src/components/Expedition/ProgressBar';
import ElapsedTime from '@/src/components/Expedition/ElapsedTime';

import { useBreakpoint } from '@/src/context/BreakpointContext';

import formatCurrency from '@/src/utils/formatCurrency';
import handleDispatchTag from '@/src/utils/handlePDF';
import formatParityText from '@/src/utils/formatParityText';

import { getOrderStatus } from '@/src/store/modules/expedition/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';
import { dispatchStatus } from '@/src/utils/localeData';
import { linesQuery } from '@/src/globals/dispatchQueries';

import { ReactComponent as IfoodIcon } from '@/src/assets/images/dispatch/ifood-icon.svg';
import { ReactComponent as UserIcon } from '@/src/assets/images/dispatch/user.svg';
import { ReactComponent as HubIcon } from '@/src/assets/images/dispatch/hub.svg';
import { ReactComponent as DelivererIcon } from '@/src/assets/images/dispatch/deliverer.svg';
import { ReactComponent as AddressIcon } from '@/src/assets/images/dispatch/address.svg';
import { ReactComponent as ShoppingCartIcon } from '@/src/assets/images/dispatch/shopping-cart.svg';
import { ReactComponent as ClockIcon } from '@/src/assets/images/dispatch/clock.svg';
import './style.scss';

const { Panel } = Collapse;

const Card = React.forwardRef((props, ref) => {
  const { showHub, nextStep, order, handleSlider, handleShowDrawer } = props;
  const [showBtn, setShowBtn] = useState(false);
  const [border, setBorder] = useState('1px solid #ebebeb');
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [summaryCard, setSummaryCard] = useState(false);

  const { pageLoading, viewer } = useSelector(state => state.expedition);
  const { account } = useSelector(state => state.account);

  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const { editPermission } = usePermissions();

  const pickPackPermission = editPermission(modules.DISPATCH_ORDERS_PICKPACK);

  const orderEta = {
    createdAt: order.createdAt,
    hasEtaAlert: order.hasEtaAlert,
    // etaMinValue: order.etaMinValue,
    etaMaxValue: order.etaMaxValue
  };

  const { include, fields, extra_fields } = linesQuery.query;
  const query = {
    include,
    fields,
    extra_fields
  };

  useEffect(() => {
    const dispatched =
      order.status === 'dispatched' ||
      order.status === 'delivering' ||
      order.status === 'delivered';
    const requestResolved = order.orderRequests?.filter(
      item => item.requestResolved && !item.rejected
    );
    setSummaryCard(
      dispatched && !order.hasReturnRequest && !order.hasCancelationRequest
    );
    if (order.hasCancelationRequest) {
      setBorder('2px solid #ff365c');
      setBackgroundColor('#fbb9c5');
      setShowBtn(true);
    } else if (order.hasReturnRequest) {
      setBorder('2px solid #7850ba');
      setBackgroundColor('#d4c4f1');
      setShowBtn(true);
    } else if (order.hasAlert) {
      setBorder('2px solid #fdab02');
      setBackgroundColor('#fde6b6');
      setShowBtn(false);
    } else if (dispatched) {
      setBorder('1px solid #ebebeb');
      setBackgroundColor('#f8f8f8');
      setShowBtn(false);
    } else if (requestResolved.length) {
      setBorder('2px solid #10ce9f');
      setBackgroundColor('#cbf2e2');
      setShowBtn(true);
    } else {
      setBorder('1px solid #ebebeb');
      setBackgroundColor('#fff');
      setShowBtn(true);
    }

    if (!pickPackPermission) setShowBtn(false);
    // eslint-disable-next-line
  }, [order, viewer]);

  const changeStatus = (payload, undo = false) => {
    const { status } = payload.data.attributes;
    handleSlider(status, undo);
    dispatch(getOrderStatus(payload));
  };

  const handleClick = status => {
    const payload = {
      data: {
        type: 'orders',
        id: Number(order.id),
        attributes: {
          status: ''
        }
      },
      query
    };
    switch (status) {
      case 'waiting':
        payload.data.attributes.status = 'started';
        payload.data.relationships = {
          user: {
            data: {
              type: 'users',
              id: Number(account.id),
              method: 'update'
            }
          }
        };
        break;
      case 'packed':
        payload.data.attributes.status = 'billed';
        break;
      case 'billed':
        if (breakpoint.mobile) handleDispatchTag(order);
        payload.data.attributes.status = 'separated';
        break;
      default:
        payload.data.attributes.status = '';
    }
    return changeStatus(payload);
  };

  const handleUndoStatus = status => {
    const payload = {
      data: {
        type: 'orders',
        id: Number(order.id),
        attributes: {
          status: ''
        }
      },
      query
    };
    switch (status) {
      case 'started':
        payload.data.attributes.status = 'waiting';
        payload.data.relationships = {
          user: {
            data: {
              type: 'users',
              id: Number(account.id),
              method: 'disassociate'
            }
          }
        };
        break;
      case 'picked':
        payload.data.attributes.status = 'started';
        break;
      case 'packed':
        payload.data.attributes.status = 'picked';
        break;
      case 'billed':
        payload.data.attributes.status = 'packed';
        break;
      case 'separated':
        payload.data.attributes.status = 'billed';
        break;
      default:
        payload.data.attributes.status = '';
    }
    return changeStatus(payload, true);
  };

  // const zeeManLabel = (
  //   <div className="card-logo">
  //     <img src={helmet} alt="logo capacete" />
  //     <span className="card-logo__label">zee.man</span>
  //   </div>
  // );

  const handleImgError = event => {
    event.persist();
    event.target.classList.add('hidden');
    // console.log(event);
    // if (event.type === 'error') {
    //   // eslint-disable-next-line no-param-reassign
    //   event.target.src = imgDefault;
    //   // eslint-disable-next-line no-param-reassign
    //   event.target.style.width = '24px';
    //   // eslint-disable-next-line no-param-reassign
    //   event.target.style.height = '24px';
    // }
  };

  const orderDetailsCollapse = (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <Icon type="caret-down" rotate={isActive ? 180 : 0} />
      )}
      className="card-details"
    >
      <Panel header="Detalhes do Pedido" key="1">
        <div className="card-details__content-box">
          <div className="card-section">
            <AddressIcon />
            <div className="card-section__address">
              <span>
                {order.addressDistrict} - {order.addressStreet}{' '}
                {order.addressNumber}
              </span>
              <span>
                {order.itemCount} {order.itemCount > 1 ? 'itens' : 'item'} |{' '}
                {formatCurrency(order.total, true)}
              </span>
            </div>
          </div>
          <div className="card-section space-between">
            <div className="card-section__volumes">
              <ShoppingCartIcon />
              <span>{formatParityText(order.volumeCount || 0, 'volume')}</span>
            </div>
            <div className="card-section__nfe">
              <ListTile typeContent="nfe" orderData={order} card />
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  );

  return (
    <div
      id={order.id}
      className={`card-single ${summaryCard ? 'summary-card' : ''} ${
        order.hasCancelationRequest || order.hasReturnRequest
          ? 'has-request'
          : ''
      }`}
      data-line={order.status}
      ref={ref}
    >
      <div
        style={{ border }}
        className={`card-single__content ${
          (order.hasAlert && !order.hasCancelationRequest) ||
          (!pickPackPermission &&
            (order.hasCancelationRequest || order.hasReturnRequest))
            ? 'no-footer'
            : ''
        }`}
      >
        <div className="card-section" style={{ backgroundColor }}>
          <button
            className={`card-section__drawer-btn ${
              viewer === 'hub' ? 'cursor-none' : ''
            }`}
            onClick={() => handleShowDrawer(order.number, order.id)}
            type="button"
            disabled={breakpoint.mobile || viewer === 'hub'}
          >
            <span className="card-section__order-number">{order.number}</span>
            <span className="card-section__date">
              {order.createdAt
                ? moment(order.createdAt).format('DD/MM/YY · HH:mm')
                : '-'}
            </span>
          </button>
        </div>
        {showHub && (
          <div className="card-section">
            <HubIcon />
            <span className="card-section__hub--text">{order.hubName}</span>
          </div>
        )}
        {order.etaMaxValue !== 0 && (
          <div className="card-section">
            <div className="card-section__eta">
              <div className="card-section__eta--text">
                <span>
                  {order.status === 'dispatched' ||
                  order.status === 'delivering' ||
                  order.status === 'delivered'
                    ? dispatchStatus[order.status]
                    : 'Tempo de preparação'}
                </span>
                {!summaryCard && <ElapsedTime createdAt={order.createdAt} />}
              </div>
              <ProgressBar
                stepName={nextStep}
                progressModal={false}
                orderEta={orderEta}
                orderStatus={order.status}
                className="card-section__eta--progress-bar"
              />
            </div>
            {order.saleChannelName?.toLowerCase() === 'ifood' && (
              <IfoodIcon className="card-section__eta--sale-channel" />
            )}
          </div>
        )}
        {order.scheduled && (
          <div className="card-section">
            <div className="card-section__scheduled">
              <div className="card-section__scheduled--text">
                <span>Pedido agendado</span>
                <span>
                  {order.deliverAt
                    ? moment(order.deliverAt).format('DD/MM | HH:mm')
                    : '-'}
                </span>
              </div>
              <ClockIcon />
            </div>
          </div>
        )}
        {order.status !== 'waiting' && (
          <div className="card-section">
            <UserIcon />
            <div className="card-section__user">
              {order.userImageUrl && (
                <img
                  className="card-section__user--img"
                  alt={order.userName}
                  src={order.userImageUrl}
                  onError={event => handleImgError(event)}
                />
              )}
              <div className="card-section__user--text">
                <span>Responsável</span>
                <span>{order.userName}</span>
              </div>
            </div>
          </div>
        )}
        {summaryCard && order.delivererName && (
          <div className="card-section">
            <DelivererIcon />
            <div className="card-section__user">
              {order.delivererImageUrl && (
                <img
                  className="card-section__user--img"
                  alt={order.delivererName}
                  src={order.delivererImageUrl}
                  onError={event => handleImgError(event)}
                />
              )}
              <div className="card-section__user--text">
                <span>Entregador</span>
                <span>{order.delivererName || '-'}</span>
              </div>
            </div>
          </div>
        )}
        {!summaryCard && (
          <div className="card-section">
            <AddressIcon />
            <div className="card-section__address">
              <Skeleton
                title={false}
                loading={pageLoading}
                paragraph={{ rows: 3 }}
              >
                <span>
                  {order.addressDistrict} - {order.addressStreet}{' '}
                  {order.addressNumber}
                </span>
                <span>
                  {order.itemCount} {order.itemCount > 1 ? 'itens' : 'item'} |{' '}
                  {formatCurrency(order.total, true)}
                </span>
              </Skeleton>
            </div>
          </div>
        )}
        {(order.status === 'packed' ||
          order.status === 'billed' ||
          order.status === 'separated') && (
          <div className="card-section space-between">
            <div className="card-section__volumes">
              <ShoppingCartIcon />
              <span>{formatParityText(order.volumeCount || 0, 'volume')}</span>
            </div>
            {(order.status === 'billed' || order.status === 'separated') && (
              <div className="card-section__nfe">
                <ListTile typeContent="nfe" orderData={order} card />
              </div>
            )}
          </div>
        )}
        {order.customerTodayOrdersCount > 1 && (
          <div
            className="card-section"
            style={{
              color: '#333',
              borderBottom:
                order.hasReturnRequest || order.hasCancelationRequest
                  ? 'none'
                  : '',
              backgroundColor:
                order.customerTodayOrdersCount === 2 ? '#fde6b6' : '#fbb9c5'
            }}
          >
            <span>
              {order.customerTodayOrdersCount}º pedido do cliente no dia
            </span>
          </div>
        )}
        {order.hasAlert && !order.hasCancelationRequest && (
          <div className="card-section card-section__alert">
            <span>
              Pedido em alerta de items.
              <br /> Por favor, aguarde CX resolver com o cliente.
            </span>
          </div>
        )}
      </div>
      {showBtn && (
        <Footer
          handleClick={handleClick}
          handleUndoStatus={handleUndoStatus}
          orderStatus={order.status}
          nextStep={nextStep}
          orderCanceled={order.hasCancelationRequest}
          orderReturned={order.hasReturnRequest}
          order={order}
        />
      )}
      {summaryCard && orderDetailsCollapse}
    </div>
  );
});

Card.propTypes = {
  showHub: PropTypes.bool.isRequired,
  nextStep: PropTypes.string.isRequired,
  handleSlider: PropTypes.func.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Card;
