import React from 'react';
import { Link } from 'react-router-dom';
import StatusPill from '@/src/components/StatusPill';
import formatCurrency from '@/src/utils/formatCurrency';
import moment from '@/src/services/moment';

const customers = [
  {
    title: '# do Pedido',
    dataIndex: 'attributes.number',
    key: 'number',
    render: (text, record) => (
      <Link
        to={`/pedidos/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {text}
      </Link>
    )
  },
  {
    title: 'Data',
    dataIndex: 'attributes.created_at',
    key: 'created_at',
    sorter: true,
    render: createdAt => moment(createdAt).format('DD/MM/YYYY - HH:mm:ss')
  },
  {
    title: 'Hub',
    dataIndex: 'attributes.hub_name',
    key: 'hub_name',
    sorter: true,
    width: '100px'
  },
  {
    title: 'Total de itens',
    dataIndex: 'attributes.items_count',
    key: 'items_count',
    sorter: true,
    width: '100px'
  },
  {
    title: 'Valor Gasto',
    dataIndex: 'attributes.total',
    key: 'total',
    sorter: true,
    width: '100px',
    render: value => formatCurrency(value)
  },
  {
    title: 'Status',
    dataIndex: 'attributes.status',
    key: 'status',
    sorter: true,
    render: status => <StatusPill status={status} centered steps={3} />
  }
];

export default customers;
