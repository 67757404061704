import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Breadcrumb,
  Col,
  Card,
  Form,
  Button,
  Switch,
  Input,
  Tooltip,
  Icon
} from 'antd';
import PropTypes from 'prop-types';

import UploadImage from '@/src/components/UploadImage';

import { createManufacturer } from '@/src/store/modules/manufacturers/slice';

const NewManufacturer = props => {
  const dispatch = useDispatch();
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);

  const { isLoading } = useSelector(state => state.manufacturers);

  const checkIfHasImage = () => {
    if (uploadImage.length === 0) {
      setWithoutImage(true);
      return false;
    }
    setWithoutImage(false);
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');

      let image = '';

      if (uploadImage.includes('data:image')) {
        image = removeData(uploadImage);
      }

      const payload = {
        name: values.name,
        highlight: values.highlight,
        search_metadata: values.search_metadata || '',
        title: values.title || '',
        meta_title: values.meta_title || '',
        meta_description: values.meta_description || '',
        image
      };

      dispatch(createManufacturer(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  return (
    <>
      <Row>
        <h1>Novo Fabricante</h1>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/fabricantes">Fabricantes</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo Fabricante</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb'
        }}
      >
        <Card className="no-shadow" title="DETALHES DO FABRICANTE">
          <Row>
            <Col span={10}>
              <Form style={{ marginRight: 42 }}>
                <Row type="flex" align="middle" style={{ marginBottom: 24 }}>
                  <p
                    style={{
                      margin: '0 12px 0 0',
                      fontWeight: 'bold',
                      color: 'rgba(0, 0, 0, 0.85)'
                    }}
                  >
                    Exibir
                  </p>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('highlight', {
                      valuePropName: 'checked',
                      initialValue: false
                    })(<Switch />)}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="Nome" colon={false}>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor, preencha este campo'
                        }
                      ]
                    })(<Input placeholder="Nome do fabricante" />)}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="Keywords (separadas por vírgula)"
                    colon={false}
                  >
                    {getFieldDecorator('search_metadata')(
                      <Input.TextArea placeholder="Exemplo: zeedog,zeenow" />
                    )}
                  </Form.Item>
                </Row>
                <h2 style={{ marginBottom: 16 }}>SEO (WEB)</h2>
                <Row>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8,
                      color: 'rgba(0, 0, 0, 0.85)'
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Title</p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido na página do fabricante"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('title')(
                      <Input placeholder="Título da vitrine" />
                    )}
                  </Form.Item>
                  <span style={{ display: 'block', marginBottom: 24 }}>
                    *Caso não preenchido, será o nome do fabricante
                  </span>
                </Row>
                <Row>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8,
                      color: 'rgba(0, 0, 0, 0.85)'
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_title')(
                      <Input placeholder="Exemplo: Zee.Now - Nome do fabricante" />
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8,
                      color: 'rgba(0, 0, 0, 0.85)'
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Meta Description
                    </p>
                    <Tooltip
                      placement="right"
                      title="Descrição da vitrine exibida em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_description')(
                      <Input.TextArea placeholder="Breve descrição do fabricante" />
                    )}
                  </Form.Item>
                </Row>
              </Form>
              <Button
                className="ant-btn ant-btn-primary"
                onClick={onSubmit}
                loading={isLoading}
              >
                Salvar
              </Button>
            </Col>
            <Col span={4}>
              <UploadImage
                setUploadImage={setUploadImage}
                uploadImage={uploadImage || []}
                withoutImage={withoutImage}
                setWithoutImage={setWithoutImage}
                recommendedSize="250 x 141"
                isSmall
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

NewManufacturer.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const NewManufacturerForm = Form.create({ name: 'NewManufacturer' })(
  NewManufacturer
);

export default NewManufacturerForm;
