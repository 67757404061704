import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Badge, Avatar, Icon } from 'antd';

import { useBreakpoint } from '@/src/context/BreakpointContext';

const PackagedItem = ({
  productId,
  productSku,
  productName,
  productImg,
  productTotalQty,
  handleRemoveItem,
  volume,
  isGift
}) => {
  const breakpoint = useBreakpoint();

  const removeItem = () => {
    handleRemoveItem(productId, volume, productTotalQty);
  };

  const dissociateBtn = (
    <div style={breakpoint.lg ? { textAlign: 'right' } : { textAlign: 'left' }}>
      <button
        type="button"
        style={{
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          padding: '0'
        }}
        onClick={removeItem}
      >
        <span
          style={{
            color: '#333',
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }}
        >
          Desassociar
        </span>
      </button>
    </div>
  );

  return (
    <>
      <Row justify="center" align="middle">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#EFEFEF',
            borderRadius: '4px',
            borderBottom: '1px solid #fff',
            padding: '12px',
            textAlign: 'center'
          }}
        >
          <Col span={4} lg={2}>
            <Badge
              count={
                isGift ? (
                  <Icon type="gift" theme="filled" className="dispatch-gift" />
                ) : (
                  0
                )
              }
            >
              <Avatar
                shape="square"
                size={breakpoint.mobile ? 40 : 55}
                src={productImg}
                icon="camera"
                className="img-placeholder"
              />
            </Badge>
          </Col>
          {breakpoint.lg ? (
            <>
              <Col span={4} style={{ padding: '0 4px' }}>
                <span>{productSku}</span>
              </Col>
              <Col span={12} style={{ padding: '0 8px', textAlign: 'left' }}>
                <span>{productName}</span>
              </Col>
              <Col span={2} style={{ padding: '0 4px' }}>
                <span>{productTotalQty}</span>
              </Col>
              <Col span={4} style={{ padding: '0 4px' }}>
                {dissociateBtn}
              </Col>
            </>
          ) : (
            <Col span={20} style={{ marginLeft: 8 }}>
              <p
                style={{ margin: '4px 0', lineHeight: 1.2, textAlign: 'left' }}
              >
                {productSku} - {productName}
              </p>
              <p
                style={{ margin: '4px 0', lineHeight: 1.2, textAlign: 'left' }}
              >
                Quantidade: {productTotalQty}
              </p>
              {dissociateBtn}
            </Col>
          )}
        </div>
      </Row>
    </>
  );
};

PackagedItem.propTypes = {
  productId: PropTypes.number.isRequired,
  productSku: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productImg: PropTypes.string.isRequired,
  productTotalQty: PropTypes.number.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  volume: PropTypes.string.isRequired,
  isGift: PropTypes.bool.isRequired
};

export default PackagedItem;
