import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

const initialState = {
  list: [],
  select: [],
  hub: {},
  isLoading: false,
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    sort: '',
    url: '',
    filters: [],
    fields: [],
    include: ''
  }
};

const hubsListSlice = createSlice({
  name: '@hubsData',
  initialState,
  reducers: {
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setHubs(prevState, action) {
      const state = prevState;
      state.list = action.payload;
      return state;
    },
    setHubsSelect(prevState, action) {
      const state = prevState;
      state.select = action.payload;
      return state;
    },
    setHubSingle(prevState, action) {
      const state = prevState;
      state.hub = action.payload;
    },
    setCreateHub(prevState, action) {
      const state = prevState;
      state.list = merge(state.list, action.payload);
      state.list.meta['/hubs'].data = [
        ...state.list.meta['/hubs'].data,
        ...action.payload.meta['/new-hub'].data
      ];
      return state;
    },
    getHubs(state) {
      return state;
    },
    getHubSingle(state) {
      return state;
    },
    getHubsSelect(state) {
      return state;
    },
    getUpdateHub(state) {
      return state;
    },
    getSwitchHubAssociation(state) {
      return state;
    },
    getCreateHub(state) {
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setCount(prevState, action) {
      const state = prevState;
      state.count = action.payload;
      return state;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = action.payload;
      return state;
    }
  }
});

export const {
  setIsLoading,
  setHubs,
  setHubsUpdate,
  setHubSingle,
  setCreateHub,
  getHubs,
  getHubSingle,
  getUpdateHub,
  getSwitchHubAssociation,
  getCreateHub,
  setPagination,
  setSort,
  setCount,
  setQuery,
  getHubsSelect,
  setHubsSelect
} = hubsListSlice.actions;

export default hubsListSlice.reducer;
