import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Icon, Avatar, Button, Skeleton } from 'antd';

import ListItems from '@/src/components/Sizes/ListItems/index';
import ConfirmModal from '@/src/components/Sizes/Modals/ConfirmModal';
import ActionModal from '@/src/components/Sizes/Modals/ActionModal';

import {
  getBreed,
  getBreeds,
  updateBreedAccessoryType
} from '@/src/store/modules/breeds/slice';
import {
  getAccessory,
  getAccessories
} from '@/src/store/modules/accessories/slice';

import buildJsonData from '@/src/utils/buildJsonData';

const EditSizes = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [data, setData] = useState();
  const [list, setList] = useState([]);
  const [select, setSelect] = useState([]);
  const [sizesPayload, setSizesPayload] = useState({
    create: [],
    update: []
  });

  const handleSaveSizes = () => {
    dispatch(updateBreedAccessoryType(sizesPayload));
    setSizesPayload({
      create: [],
      update: []
    });
  };

  const confirmModalData = {
    title: 'Tamanhos Faltando',
    body: `Os campos de tamanho não foram todos preenchidos. Deseja salvar mesmo assim ou continuar preenchendo?`,
    onOk: () => {
      handleSaveSizes();
      setShowConfirmModal(false);
    }
  };

  const query = {
    page: 1,
    perPage: 1000,
    search: ''
  };

  const { breed, breeds, loading: breedsLoading, loadingBtn } = useSelector(
    state => state.breeds
  );
  const { accessory, accessories, loading: accessoriesLoading } = useSelector(
    state => state.accessories
  );
  const { location } = useSelector(state => state.router);

  useEffect(() => {
    if (location.pathname.includes('racas')) {
      dispatch(getBreed(id));
      dispatch(getAccessories({ query }));
    } else {
      dispatch(getAccessory(id));
      dispatch(getBreeds({ query }));
    }
    // eslint-disable-next-line
  }, [id, location]);

  useEffect(() => {
    if (location.pathname.includes('racas')) {
      const breedData = buildJsonData(breed, 'breed');
      setData(breedData[0]);
    } else {
      const accessoryData = buildJsonData(accessory, 'accessory_type');
      setData(accessoryData[0]);
    }
    // eslint-disable-next-line
  }, [breed, accessory]);

  useEffect(() => {
    // console.log(data);
    setSelect(data?.tags?.map(item => item.name) || []);
  }, [data, location]);

  useEffect(() => {
    if (location.pathname.includes('racas')) {
      const accessoriesData = buildJsonData(accessories, 'accessory_type');
      setList(accessoriesData);
    } else {
      const breedsData = buildJsonData(breeds, 'breed');
      setList(breedsData);
    }
    // eslint-disable-next-line
  }, [breeds, accessories]);

  const handleConfirmModal = () => {
    const selected = document.querySelectorAll(
      '.sizes-list .ant-select-selection-selected-value'
    );
    if (selected.length === list.length) {
      handleSaveSizes();
    } else {
      setShowConfirmModal(true);
    }
  };

  return (
    <>
      <ActionModal
        visible={showAddModal}
        setVisible={setShowAddModal}
        // title={title}
        action="edit"
        type={location.pathname.includes('racas') ? 'breeds' : 'accessories'}
        data={data}
        list={list}
      />
      <ConfirmModal
        data={confirmModalData}
        visible={showConfirmModal}
        setVisible={setShowConfirmModal}
      />
      <Row type="flex" align="middle" justify="space-between">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/tamanhos">
            <Icon
              type="left"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#000'
              }}
            />
          </Link>
          <div
            style={{
              marginLeft: '10px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Avatar
              shape="square"
              size={60}
              src={breedsLoading || accessoriesLoading ? '' : data?.image}
              icon={
                <Icon
                  style={{ fontSize: '25px' }}
                  type="camera"
                  theme="filled"
                />
              }
            />
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Skeleton
                  active
                  title={false}
                  loading={
                    location.pathname.includes('racas')
                      ? breedsLoading
                      : accessoriesLoading
                  }
                  paragraph={{ rows: 1, width: 100 }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                      color: '#333',
                      textTransform: 'capitalize',
                      maxWidth: 800
                    }}
                  >
                    {data?.pt_name}
                  </span>
                </Skeleton>
                <Icon
                  type="edit"
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#000',
                    marginLeft: '10px'
                  }}
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                />
              </div>
              <Skeleton
                active
                title={false}
                loading={
                  location.pathname.includes('racas')
                    ? breedsLoading
                    : accessoriesLoading
                }
                paragraph={{ rows: 1, width: 100 }}
              >
                <span style={{ textTransform: 'capitalize', maxWidth: 800 }}>
                  {data?.en_name}
                </span>
              </Skeleton>
            </div>
          </div>
        </div>
        <Button
          className="ant-btn ant-btn-primary"
          onClick={handleConfirmModal}
          loading={loadingBtn}
        >
          Salvar
        </Button>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '0px solid #ebebeb',
          background: '#ebebeb',
          padding: '16px 10px',
          minHeight: '66vh',
          maxHeight: '66vh',
          marginTop: '30px'
        }}
      >
        <ListItems
          data={list}
          title=""
          kind={location.pathname.includes('racas') ? 'acessorios' : 'racas'}
          select={select}
          handleSizes={setSizesPayload}
        />
      </div>
    </>
  );
};

export default EditSizes;
