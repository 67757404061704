import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';
import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setIsLoading,
  setEtas,
  setEtaSingle,
  getEtas,
  getEtaSingle,
  getUpdateEta,
  getCreateEta,
  getDeleteEta
} from '@/src/store/modules/etas/slice';

import {
  getEtasData,
  getEtaSingleData,
  setUpdateEtaData,
  setCreateEtaData,
  setDeleteEtaData
} from '@/src/api/etas';

function* setEtasSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getEtasData, payload);
    const normalized = normalize(response.data, { endpoint: '/etaSchedules' });
    yield put(setEtas(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setEtaSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getEtaSingleData, payload);
    yield put(setEtaSingle(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setUpdateEtaSaga({ payload }) {
  try {
    const response = yield call(setUpdateEtaData, payload);
    yield put(setEtaSingle(response.data.data));
    successAlert('Os dados do ETA foram atualizados');
  } catch (err) {
    errorAlert(err);
  }
}

function* setCreateEtaSaga({ payload }) {
  try {
    const response = yield call(setCreateEtaData, payload);
    yield put(setEtaSingle(response.data.data));
    successAlert('Novo ETA cadastrado');
  } catch (err) {
    errorAlert(err);
  }
}

function* setDeleteEtaSaga({ payload }) {
  try {
    yield call(setDeleteEtaData, payload);
    successAlert('ETA removido');
  } catch (err) {
    errorAlert(err);
  }
}

function* etasListSaga() {
  yield takeEvery(getEtas, setEtasSaga);
  yield takeEvery(getEtaSingle, setEtaSingleSaga);
  yield takeEvery(getUpdateEta, setUpdateEtaSaga);
  yield takeEvery(getCreateEta, setCreateEtaSaga);
  yield takeEvery(getDeleteEta, setDeleteEtaSaga);
}

export default etasListSaga;
