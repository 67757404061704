import React from 'react';
import { Link } from 'react-router-dom';

import StatusPill from '@/src/components/StatusPill';

const hubsList = [
  {
    title: 'Nome',
    align: 'left',
    key: 'name',
    render: data => (
      <Link
        to={`/hubs/${data.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {data.name || data.externalName}
      </Link>
    ),
    sorter: true
  },
  {
    title: 'Aberto às',
    align: 'center',
    key: 'opens_at',
    render: data => <>{data.opensAt}</>,
    sorter: true
  },
  {
    title: 'ETA (min-máx)',
    align: 'center',
    key: 'eta_min-max',
    render: data => {
      return (
        <>
          {data.etaMin} - {data.etaMax}
        </>
      );
    }
  },
  {
    title: 'Endereço',
    align: 'left',
    key: 'street',
    render: data => {
      return (
        <>
          {data.street}
          {data.street && data.number ? ', ' : ''}
          {data.number}
        </>
      );
    },
    sorter: true
  },
  // {
  //   title: 'Regiões',
  //   align: 'center',
  //   key: 'regions',
  //   render: data => {
  //     return <>{data.regions?.length}</>;
  //   },
  //   sorter: (a, b) => a.regions.length - b.regions.length
  // },
  // {
  //   title: '# Pedidos',
  //   align: 'center',
  //   key: 'orders',
  //   render: data => {
  //     return <>{data.orders?.length}</>;
  //   },
  //   sorter: (a, b) => a.orders.length - b.orders.length
  // },
  // {
  //   title: 'Entregadores',
  //   align: 'center',
  //   key: 'deliverers',
  //   render: data => {
  //     return <>{data.deliverers?.length}</>;
  //   },
  //   sorter: (a, b) => a.orders.length - b.orders.length
  // },
  {
    title: 'Situação',
    align: 'left',
    key: 'external_status',
    render: data => {
      return <StatusPill status={data.externalStatus} centered />;
    },
    sorter: true
  }
];

export default hubsList;
