import { takeEvery, put, call } from 'redux-saga/effects';
import { errorAlert } from '@/src/utils/notifications';
import {
  setCategories,
  getCategories,
  setIsLoading
} from '@/src/store/modules/categories/slice';
import getCategoriesRequest from '@/src/api/categories';

function* getCategoriesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCategoriesRequest, payload);
    const categoriesCats = yield response.data.data.filter(
      cat => cat.attributes?.department_name?.toUpperCase() === 'GATOS'
    );
    const categoriesDogs = yield response.data.data.filter(
      dog => dog.attributes?.department_name?.toUpperCase() === 'CÃES'
    );
    // const otherCategories = yield response.data.data.filter(
    //   categories =>
    //     categories.attributes?.department_name?.toUpperCase() !== 'CÃES' &&
    //     categories.attributes?.department_name?.toUpperCase() !== 'GATOS'
    // );
    yield put(
      setCategories({
        // response.data.data
        // categories: otherCategories,
        categories: response.data.data,
        cats: categoriesCats,
        dogs: categoriesDogs
      })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar as categorias');
  }
}

function* categoriesSaga() {
  yield takeEvery(getCategories, getCategoriesSaga);
}

export default categoriesSaga;
