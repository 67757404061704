import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import {
  setIsLoading,
  setCountries,
  getCountries
} from '@/src/store/modules/countries/slice';

import { getCountries as getCountriesRequest } from '@/src/api/countries';

import { errorAlert } from '@/src/utils/notifications';

function* getCountriesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCountriesRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/countries' });
    yield put(setCountries(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar a lista de países');
  }
}

function* countriesSaga() {
  yield takeEvery(getCountries, getCountriesSaga);
}

export default countriesSaga;
