import React from 'react';
import { pdf } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import VolumeTag from '@/src/components/Expedition/VolumeTag';

const handleDispatchTag = (order, setLoading = false) => {
  if (setLoading) setLoading(true);
  const ifoodOrder = order.saleChannelName?.toLowerCase() === 'ifood';
  let ifoodLocalizer;
  if (ifoodOrder) {
    const orderNameArray = order.number.split('-');
    ifoodLocalizer = orderNameArray[orderNameArray.length - 1];
  }
  const qrCodeOptions = {
    margin: 0,
    version: 4,
    width: 1000
  };

  const qrCodeArr = order.volumesIds.map(vol => {
    if (ifoodOrder) return QRCode.toDataURL(ifoodLocalizer, qrCodeOptions);
    const json = Number(vol);
    return QRCode.toDataURL(JSON.stringify(json), qrCodeOptions);
  });

  Promise.all(qrCodeArr)
    .then(res => pdf(<VolumeTag order={order} qrCode={res} />).toBlob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
      if (setLoading) setLoading(false);
    });
};

export default handleDispatchTag;
