import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getChangePassword,
  setErrMessage,
  setLoading
} from '@/src/store/modules/change-password/slice';

import { logoutAccount, newPassword } from '@/src/store/modules/login/slice';

import { changePassword as changePasswordRequest } from '@/src/api/users';

import bugsnag from '@/src/services/bugsnag';

export function* changePasswordAccountSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(changePasswordRequest, payload);
    yield put(setErrMessage(''));
    yield put(setLoading(false));
    yield put(logoutAccount());
    yield put(newPassword(true));
    yield put(push('/'));
  } catch (err) {
    yield put(setLoading(false));
    yield put(setErrMessage(err.response.statusText));
    bugsnag.notify(err);
  }
}

function* changePasswordSaga() {
  yield takeEvery(getChangePassword, changePasswordAccountSaga);
}

export default changePasswordSaga;
