const compareArrObjGetNewArr = (arr1, arr2, prop) => {
  const result = arr1
    .filter(o1 => {
      // filter out (!) items in result2
      return !arr2.some(o2 => {
        return o1[prop] === o2[prop]; // assumes unique id
      });
    })
    .map(o => o);
  return result;
};

export default compareArrObjGetNewArr;
