import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import {
  setIsLoading,
  setCities,
  setModalCities,
  setCreateCity,
  getCities,
  getCreateCity
  // setCitySingle,
  // getCitySingle
} from '@/src/store/modules/cities/slice';

import {
  getCitiesData as getCitiesDataRequest,
  getCreateCity as getCreateCityRequest
  // getCitySingleData as getCitySingleDataRequest
} from '@/src/api/cities';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getCitiesSaga({ payload }) {
  try {
    if (!payload.modal) yield put(setIsLoading(true));
    const response = yield call(getCitiesDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/cities' });
    if (payload.modal) {
      yield put(setModalCities(normalized));
    } else {
      yield put(setCities(normalized));
    }
    if (!payload.modal) yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar a lista de cidades');
  }
}

// function* getCitySingleSaga({ payload }) {
//   try {
//     yield put(setIsLoading(true));
//     const response = yield call(getCitySingleDataRequest, payload);
//     const normalized = normalize(response.data, { endpoint: '/citysingle' });
//     yield put(setCitySingle(normalized));
//     yield put(setIsLoading(false));
//   } catch (err) {
//     errorAlert(err, 'Não foi possível carregar os dados da cidade');
//   }
// }

function* getCreateCitySaga({ payload }) {
  try {
    const response = yield call(getCreateCityRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/new-city' });
    yield put(setCreateCity(normalized));
    successAlert('Nova cidade cadastrada');
  } catch (err) {
    errorAlert(err);
  }
}

function* hubsListSaga() {
  yield takeEvery(getCities, getCitiesSaga);
  yield takeEvery(getCreateCity, getCreateCitySaga);
  // yield takeEvery(getCitySingle, getCitySingleSaga);
}

export default hubsListSaga;
