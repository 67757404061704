import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coupons: [],
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    search: ''
  },
  isLoading: false
};

const couponsSlice = createSlice({
  name: '@coupons',
  initialState,
  reducers: {
    setCouponsInPromotion(prevState, action) {
      const state = prevState;
      const { coupons } = action.payload;
      const { total } = action.payload;
      state.coupons = coupons;
      state.total = total;
      state.query = {
        page: Number(action.payload.query.page) || 1,
        perPage: action.payload.query.perPage || 20,
        search: action.payload.query.search || '',
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || 20}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    updateCouponsInPromotion(prevState, action) {
      const state = prevState;
      state.coupons = action.payload.coupons;
      state.isLoading = false;
      return state;
    },
    setCouponsIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = action.payload;
      return state;
    },
    editCoupon(state) {
      return state;
    },
    archiveCoupons(state) {
      return state;
    },
    createNewCoupon(state) {
      return state;
    },
    getCouponsInPromotion(state) {
      return state;
    },
    generateCoupons(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    }
  }
});

export const {
  getCouponsInPromotion,
  createNewCoupon,
  archiveCoupons,
  editCoupon,
  setCouponsInPromotion,
  setCouponsIsLoading,
  updateCouponsInPromotion,
  generateCoupons,
  exportCSV,
  setQuery
} = couponsSlice.actions;

export default couponsSlice.reducer;
