import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';

import {
  setIsLoading,
  setStates,
  setStateSingle,
  getStates,
  getStateSingle
} from '@/src/store/modules/states/slice';

import { getStatesData, getStatesSingleData } from '@/src/api/states';

import { errorAlert } from '@/src/utils/notifications';

function* setStatesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getStatesData, payload);
    const normalized = normalize(response.data, { endpoint: '/states' });
    yield put(setStates(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar a lista de estados');
  }
}

function* setStateSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getStatesSingleData, payload);
    const normalized = normalize(response.data, { endpoint: '/statesingle' });
    yield put(setStateSingle(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os dados do estado');
  }
}

function* stateListSaga() {
  yield takeEvery(getStates, setStatesSaga);
  yield takeEvery(getStateSingle, setStateSingleSaga);
}

export default stateListSaga;
