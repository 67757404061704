import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import Header from './Header';
import Table from './Table';
import Box from './Box';

const CustomDrawer = props => {
  const {
    visible,
    hasAlert,
    data,
    title,
    headerTable,
    onClose,
    children,
    loading
  } = props;
  return (
    <Drawer
      visible={visible}
      title={
        <Header
          data={data}
          title={title}
          table={headerTable}
          hasAlert={hasAlert}
          loading={loading}
        />
      }
      width={950}
      placement="right"
      closable
      onClose={onClose}
      headerStyle={{
        background: '#fff',
        borderBottom: 'none',
        boxShadow: 'none'
      }}
      bodyStyle={{ padding: '16px 24px' }}
      className="ant-drawer-custom"
    >
      {children}
    </Drawer>
  );
};

CustomDrawer.defaultProps = {
  hasAlert: false,
  loading: false,
  data: {}
};

CustomDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  hasAlert: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  headerTable: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  loading: PropTypes.bool
};

CustomDrawer.Box = Box;
CustomDrawer.Table = Table;

export default CustomDrawer;
