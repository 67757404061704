import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Select, Badge, Avatar, Icon } from 'antd';

import SquareButton from '@/src/components/Expedition/Modal/SquareButton';
import Counter from '@/src/components/Expedition/Modal/Counter';

import { addToPackedItems } from '@/src/store/modules/expedition/slice';

import titleCase from '@/src/utils/titleCase';
import { useBreakpoint } from '@/src/context/BreakpointContext';

import './PackItem.scss';

const { Option, OptGroup } = Select;

const PackItem = ({
  productId,
  productName,
  productSku,
  productEan,
  productTotalQty,
  productImg,
  handleChange,
  volumesData,
  volumeKinds,
  existingVol,
  isGift
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [totalValue, setTotalValue] = useState(productTotalQty);

  const breakpoint = useBreakpoint();

  const dispatch = useDispatch();

  useEffect(() => {
    if (totalValue === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [totalValue]);

  const handleSelectChange = value => {
    const volumeKind = value.split('-')[0] || value;
    const volumeKindId = volumeKinds.find(item => item.name === volumeKind).id;
    const volumeKindQty = volumesData.volumes.filter(
      item =>
        item.volumeKind === volumeKindId &&
        item.volumeItems.filter(product => product.productQty > 0).length > 0
    ).length;
    const volToUpdate =
      volumesData.volumes.find(item => item.id === value) || false;
    const volumeId = volToUpdate.id || `${value}-${volumeKindQty + 1}`;
    const payload = {
      operation: 'add',
      volumes: [
        {
          id: volumeId,
          volumeItems: [
            {
              packedItemId: `${volumeId}_product-${productId}`,
              productId: Number(productId),
              productQty: Number(totalValue)
            }
          ],
          volumeKind: volumeKindId
        }
      ]
    };
    dispatch(addToPackedItems(payload));
    handleChange(productId, totalValue);
  };

  const handleCounterChange = finalValue => {
    setTotalValue(finalValue);
  };

  const counterWrapper = (
    <div className="modal-table__counter">
      <div>
        {breakpoint.mobile && (
          <span style={{ fontWeight: 'bold' }}>Qnt para empacotar</span>
        )}
        <Counter
          counterTitle=""
          handleCounterChange={handleCounterChange}
          maxQty={productTotalQty}
          packType
        />
      </div>
      <div style={{ margin: '0 20px', position: 'relative' }}>
        <SquareButton
          backgroundColor="#fff"
          border="1px solid #333"
          iconType="dropbox"
          size="40px"
          iconSize="24px"
          disabled={buttonDisabled}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            opacity: 0
          }}
        >
          <Select
            onSelect={handleSelectChange}
            className="volumes-select"
            dropdownMatchSelectWidth={false}
            dropdownClassName="volumes-select__dropdown"
            disabled={buttonDisabled}
            defaultActiveFirstOption={false}
          >
            {existingVol !== 0 && (
              <OptGroup label="Volumes existentes">
                {volumesData?.volumes?.map(item => {
                  const hasItems = item.volumeItems?.filter(
                    product => product.productQty > 0
                  );
                  if (hasItems.length > 0) {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        className="volumes-select__dropdown--option"
                      >
                        {titleCase(item.id.replace('_', ' ').replace('-', ' '))}
                      </Option>
                    );
                  }
                  return '';
                })}
              </OptGroup>
            )}
            <OptGroup label="Volumes">
              {volumeKinds?.map(item => (
                <Option
                  value={item.name}
                  key={item.name}
                  className="volumes-select__dropdown--option"
                >
                  {titleCase(item.name.replace('_', ' '))}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </div>
      </div>
    </div>
  );

  return (
    <div className="modal-table__row pack-table">
      <Row justify="center" align="middle">
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Col span={6} lg={2}>
            <Badge
              count={
                isGift ? (
                  <Icon type="gift" theme="filled" className="dispatch-gift" />
                ) : (
                  0
                )
              }
            >
              <Avatar
                shape="square"
                size={breakpoint.mobile ? 40 : 55}
                src={productImg}
                icon="camera"
                className="img-placeholder"
              />
            </Badge>
          </Col>
          {breakpoint.lg ? (
            <>
              <Col span={4}>
                <span>{productSku}</span>
              </Col>
              <Col span={3} lg={4}>
                <span>{productEan}</span>
              </Col>
              <Col span={6} style={{ textAlign: 'left' }}>
                <span>{productName}</span>
              </Col>
              <Col span={2}>
                <span>{productTotalQty}</span>
              </Col>
              <Col span={6}>{counterWrapper}</Col>
            </>
          ) : (
            <Col span={18} style={{ textAlign: 'left' }}>
              <span style={{ display: 'block' }}>
                {productSku} - {productName}
              </span>
              <span style={{ display: 'block' }}>EAN: {productEan}</span>
              <span style={{ display: 'block' }}>
                Quantidade: {productTotalQty}
              </span>
              {counterWrapper}
            </Col>
          )}
        </div>
      </Row>
    </div>
  );
};

PackItem.defaultProps = {
  productId: '',
  productSku: '',
  productEan: '',
  productName: '',
  productTotalQty: 0,
  productImg: '',
  handleChange: () => {},
  volumesData: {},
  volumeKinds: [],
  existingVol: 0,
  isGift: false
};

PackItem.propTypes = {
  productId: PropTypes.string,
  productSku: PropTypes.string,
  productEan: PropTypes.string,
  productName: PropTypes.string,
  productTotalQty: PropTypes.number,
  productImg: PropTypes.string,
  handleChange: PropTypes.func,
  volumesData: PropTypes.objectOf(PropTypes.any),
  volumeKinds: PropTypes.arrayOf(PropTypes.shape({})),
  existingVol: PropTypes.number,
  isGift: PropTypes.bool
};

export default PackItem;
