import { notification } from 'antd';
import { takeEvery, put, call } from 'redux-saga/effects';
import bugsnag from '@/src/services/bugsnag';
import getAuditLogRequest from '@/src/api/audit-log';
import {
  getAuditLog,
  setIsLoading,
  setAuditLog
} from '@/src/store/modules/audit-log/slice';

import { errorAlert } from '@/src/utils/notifications';

function* getLogsUserSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getAuditLogRequest, payload);
    let count;
    if (payload.auditable_type === 'SkuPetz') {
      count = response.data.pagy.count;
    } else {
      count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    }
    yield put(
      setAuditLog({ auditLog: response.data.data, count, query: payload.query })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(setAuditLog({ auditLog: [], count: 0, query: payload.query }));
    if (err.response.status === 404) {
      bugsnag.notify(err);
      notification.warning({
        message: 'Atenção',
        duration: 3,
        className: 'warning',
        description: 'Não há registro de atualizações nesta página'
      });
    } else {
      errorAlert(err);
    }
  }
}

function* userSaga() {
  yield takeEvery(getAuditLog, getLogsUserSaga);
}

export default userSaga;
