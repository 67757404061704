import { takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert } from '@/src/utils/notifications';

import {
  getPriceIndexing,
  setPriceIndexing,
  setPriceIndexingLoading
} from '@/src/store/modules/price-indexing/slice';
import { getListPriceInfoRequest } from '@/src/api/price-indexing';

import {
  getPriceIndexingUrlState,
  getRouterState
} from '@/src/store/selectors';

function* getCatalogPricesInfoSaga({ payload }) {
  console.log('payload', payload);
  try {
    yield put(setPriceIndexingLoading(true));
    const response = yield call(getListPriceInfoRequest, payload);
    const { query } = payload;

    yield put(
      setPriceIndexing({
        priceIndexing: response?.data?.results,
        priceIndexingQuery: {
          perPage: response?.data?.meta.per_page,
          page: response?.data?.meta.page,
          count: response?.data?.meta.count,
          price_table_ids: query?.price_table_ids,
          sku_price_lte: query?.sku_price_lte,
          sku_price_gte: query?.sku_price_gte,
          hub_ids: query?.hub_ids
        }
      })
    );
    const queryUrl = yield select(getPriceIndexingUrlState);
    const routerState = yield select(getRouterState);

    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }

    yield put(setPriceIndexingLoading(false));
  } catch (err) {
    errorAlert(err, `Ocorreu um erro. Tente novamente.`);
    yield put(setPriceIndexingLoading(false));
    // yield put();
  }
}

function* priceIndexingSaga() {
  yield takeEvery(getPriceIndexing, getCatalogPricesInfoSaga);
}

export default priceIndexingSaga;
