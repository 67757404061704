import React, { useState, useEffect, useCallback } from 'react';
import { Input, Row, Col, Button, Select, Icon, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import build from 'redux-object';
import PropTypes from 'prop-types';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import ModalCoverage from '@/src/components/Hubs/ModalCoverage';

import { getRegions } from '@/src/store/modules/regions/slice';
import { getUpdateHub } from '@/src/store/modules/hubs-data/slice';
import {
  getCreateEta,
  getUpdateEta,
  getDeleteEta
} from '@/src/store/modules/etas/slice';

import { translateWeekdays } from '@/src/utils/localeData';

import Edit from '@/src/assets/images/edit.svg';
import Delete from '@/src/assets/images/delete.svg';

import styles from './EstimatedTime.module.scss';

const { Option } = Select;

const EstimatedTime = props => {
  const { editPermission } = props;

  const dispatch = useDispatch();
  const [etaMin, setEtaMin] = useState('');
  const [etaMax, setEtaMax] = useState('');
  const [defaultEtaMin, setDefaultEtaMin] = useState('');
  const [defaultEtaMax, setDefaultEtaMax] = useState('');
  const [etaRemark, setEtaRemark] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalRemarkVisibility, setModalRemarkVisibility] = useState(false);
  const [modalCancelVisibility, setModalCancelVisibility] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalType, setModalType] = useState('adicionar');
  const [tableData, setTableData] = useState([]);
  const [selectValue, setSelectValue] = useState('all');
  const [estimatedDelete, setEstimatedDelete] = useState();
  const [allRegions, setAllRegions] = useState([]);
  const [firstBuild, setFirstBuild] = useState(true);

  const { id } = useParams();
  const { list: regions, isLoading } = useSelector(state => state.regions);
  const { hub } = useSelector(state => state.hubsData);
  const { eta } = useSelector(state => state.etas);

  const queryDefault = {
    include: 'eta_schedules',
    perPage: 1000,
    filters: [
      {
        key: 'hub_id',
        type: 'eq',
        value: id
      }
    ]
  };

  useEffect(() => {
    dispatch(getRegions({ query: { ...queryDefault } }));
    // eslint-disable-next-line
  }, [dispatch, id, eta]);

  useEffect(() => {
    const hubObj = build(hub, 'hubs', id, { ignoreLinks: true }) || {};
    setEtaMin(hubObj.etaMin);
    setEtaMax(hubObj.etaMax);
    setDefaultEtaMin(hubObj.etaMin);
    setDefaultEtaMax(hubObj.etaMax);
  }, [hub, id]);

  const getEtaListHandler = useCallback(
    payload => {
      dispatch(getRegions({ ...payload }));
    },
    [dispatch]
  );

  const changeTableValue = data => {
    setModalData(data);
    setModalType('editar');
    setModalVisibility(true);
  };

  const deleteTableValue = etaID => {
    setEstimatedDelete(etaID);
    setModalCancelVisibility(true);
  };

  const handleDeleteValue = () => {
    setModalCancelVisibility(false);
    dispatch(getDeleteEta(estimatedDelete));

    setTimeout(() => {
      getEtaListHandler({ query: { ...queryDefault } });
    }, 1000);
  };

  const setRegionTableData = value => {
    const schedules = [];
    const regionsArr = value || regions;
    regionsArr.forEach(region => {
      return region?.etaSchedules?.forEach((schedule, index) => {
        schedules.push({
          id: schedule.id,
          regionID: region.id,
          name: region.name,
          changeValue: changeTableValue,
          deleteValue: () => deleteTableValue(schedule.id),
          schedule
        });
      });
    });
    return schedules;
  };

  useEffect(() => {
    const data = setRegionTableData();
    setTableData(data);

    if (regions.length && firstBuild) {
      setAllRegions(regions);
      setFirstBuild(false);
    }

    // eslint-disable-next-line
  }, [dispatch, regions]);

  const newTableValue = () => {
    setModalData({});
    setModalType('adicionar');
    setModalVisibility(!modalVisibility);
  };

  const handleChangeInput = (setValue, evt) => {
    setValue(evt.target.value);
  };

  const renderInput = (placeholder, value, setValue) => {
    return (
      <>
        <Input
          size="large"
          value={value}
          onChange={evt => handleChangeInput(setValue, evt)}
          disabled={!editPermission}
          maxLength={3}
        />
        <span className={styles.formInput__label}>{placeholder}</span>
      </>
    );
  };

  const onSubmit = evt => {
    const data = {
      data: {
        type: 'hubs',
        id,
        attributes: {
          eta_min: etaMin,
          eta_max: etaMax,
          eta_remark: etaRemark
        }
      }
    };

    setEtaRemark('');
    setModalRemarkVisibility(false);
    return dispatch(getUpdateHub(data));
  };

  const handleModalVisibility = () => setModalVisibility(!modalVisibility);

  const handleFilterRegions = evt => {
    setSelectValue(evt);

    if (evt === 'all') return setTableData(setRegionTableData(regions));

    const filteredData = regions.filter(data => data.id === evt);

    return setTableData(setRegionTableData(filteredData));
  };

  const submitData = values => {
    const data = {
      data: {
        type: 'eta_schedules',
        relationships: {
          region: {
            data: {
              type: 'regions',
              method: 'update'
            }
          }
        }
      }
    };

    if (modalType === 'editar') {
      dispatch(
        getUpdateEta({
          data: {
            ...data.data,
            id: values[0].id,
            attributes: {
              eta_min: values[0].eta_min,
              eta_max: values[0].eta_max,
              starts_at: values[0].starts_at,
              ends_at: values[0].ends_at,
              weekday: values[0].weekdays,
              eta_remark: values[0].eta_remark
            },
            relationships: {
              region: {
                data: {
                  ...data.data.relationships.region.data,
                  id: values[0].region
                }
              }
            }
          }
        })
      );
    } else {
      values.forEach(item => {
        dispatch(
          getCreateEta({
            data: {
              ...data.data,
              attributes: {
                eta_min: item.eta_min,
                eta_max: item.eta_max,
                starts_at: item.starts_at,
                ends_at: item.ends_at,
                weekday: item.weekdays
              },
              relationships: {
                region: {
                  data: {
                    ...data.data.relationships.region.data,
                    id: item.region
                  }
                }
              }
            }
          })
        );
      });
    }

    return setModalVisibility(false);
  };

  const handleSearchRegion = useCallback(
    urlQuery => {
      let queryHandle = urlQuery;
      setSelectValue('all');

      if (queryHandle.query.search) {
        queryHandle = {
          query: {
            ...queryHandle.query,
            filters: [
              ...queryHandle.query.filters,
              {
                key: 'name',
                type: 'match',
                value: urlQuery.query.search.toLowerCase()
              }
            ]
          }
        };
        delete queryHandle.query.search;
        dispatch(getRegions(queryHandle));
      } else {
        dispatch(getRegions({ query: { ...queryDefault } }));
      }
    },
    [dispatch, queryDefault]
  );

  const columns = [
    {
      title: 'Região',
      align: 'left',
      sorter: true,
      dataIndex: 'name'
    },
    {
      title: 'Dia da Semana',
      align: 'left',
      key: 'schedule.weekday',
      render: data => {
        if (data.schedule) {
          const { weekday } = data.schedule;
          return <span>{translateWeekdays[weekday] || '-'}</span>;
        }
        return null;
      },
      sorter: (a, b) => {
        if (
          translateWeekdays[a.schedule.weekday] <
          translateWeekdays[b.schedule.weekday]
        )
          return -1;
        if (
          translateWeekdays[a.schedule.weekday] >
          translateWeekdays[b.schedule.weekday]
        )
          return 1;
        return 0;
      }
    },
    {
      title: 'Abre às',
      align: 'left',
      key: 'schedule.starts_at',
      render: data => <>{data.schedule.startsAt || '-'}</>,
      sorter: (a, b) =>
        Number(a.schedule.startsAt.replace(':', '')) -
        Number(b.schedule.startsAt.replace(':', ''))
    },
    {
      title: 'Fecha às',
      align: 'left',
      key: 'schedule.ends_at',
      render: data => <>{data.schedule.endsAt || '-'}</>,
      sorter: (a, b) =>
        Number(a.schedule.endsAt.replace(':', '')) -
        Number(b.schedule.endsAt.replace(':', ''))
    },
    {
      title: 'ETA (min-máx)',
      align: 'left',
      key: 'etaMin-etaMax',
      colSpan: 2,
      render: data => {
        return (
          <span>
            {data.schedule.etaMin} - {data.schedule.etaMax}
          </span>
        );
      }
    },
    {
      title: '',
      align: 'right',
      key: 'actions',
      colSpan: 0,
      render: data => {
        if (!editPermission) return null;
        const editData = () => {
          const inputValues = {
            id: data.schedule.id,
            region: data?.name,
            regionID: data?.regionID,
            weekday: data.schedule.weekday,
            starts_at: data.schedule.startsAt,
            ends_at: data.schedule.endsAt,
            eta_min: data.schedule.etaMin,
            eta_max: data.schedule.etaMax
          };
          data.changeValue(inputValues);
        };

        return (
          <>
            <button
              type="button"
              className="tableEditDelete__edit"
              onClick={editData}
            >
              <img src={Edit} alt="Editar" />
            </button>
            <button
              type="button"
              className="tableEditDelete__delete"
              onClick={() => data?.deleteValue(id)}
            >
              <img src={Delete} alt="Deletar" />
            </button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <ModalCoverage
        type={modalType}
        handleVisibility={handleModalVisibility}
        visibility={modalVisibility}
        data={modalData}
        regions={regions}
        content="eta_schedules"
        submitData={submitData}
      />
      <Modal
        width={400}
        onCancel={() => setModalCancelVisibility(false)}
        visible={modalCancelVisibility}
        onOk={handleDeleteValue}
        className={`${styles.modalDelete} modalCenteredButtons`}
      >
        <h2 className={styles.modalDelete__title}>Remover ETA</h2>
        <p className={styles.modalDelete__text}>
          Deseja realmente remover o ETA?
        </p>
      </Modal>
      <Modal
        okText="Salvar"
        width={400}
        onCancel={() => setModalRemarkVisibility(false)}
        visible={modalRemarkVisibility}
        okButtonProps={{ disabled: etaRemark === '' }}
        onOk={onSubmit}
        className={`${styles.modalDelete} modalCenteredButtons`}
      >
        <h2 className={styles.modalDelete__title}>Editar ETA</h2>
        <p className={styles.modalDelete__text}>
          Para salvar o novo ETA, descreva o motivo da edição:
        </p>
        <Input.TextArea
          name="Observação"
          style={{ borderRadius: '0px', height: '100%' }}
          rows={2}
          onChange={e => handleChangeInput(setEtaRemark, e)}
          value={etaRemark}
        />
      </Modal>
      <Row className={`${styles.coverageWrapper} ${styles.sectionColumn}`}>
        <h3>Estimativa de tempo de entrega (ETA)</h3>
        <Col>
          <div className={styles.defaultETA}>
            <div className={styles.formInput}>
              {renderInput('ETA Mínimo Padrão', etaMin, setEtaMin)}
            </div>
            <div className={styles.formInput}>
              {renderInput('ETA Máximo Padrão', etaMax, setEtaMax)}
            </div>
            {editPermission && (
              <Button
                disabled={
                  etaMin === '' ||
                  etaMax === '' ||
                  (defaultEtaMax === etaMax && defaultEtaMin === etaMin)
                }
                className="ant-btn ant-btn-primary"
                onClick={() => setModalRemarkVisibility(true)}
              >
                Salvar
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <hr />
      <Row
        className={`search-with-select
          ${styles.sectionColumn}
          ${styles.searchRegion}
        `}
      >
        <Select
          size="large"
          value={selectValue}
          className={styles.searchRegion__selectRegion}
          onChange={handleFilterRegions}
        >
          <Option key={0} value="all">
            Todas as regiões
          </Option>
          {allRegions.map(item => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <Searchbar
          placeholder="Buscar por região"
          populateResult={handleSearchRegion}
          query={queryDefault}
        />
        {editPermission && (
          <button
            type="button"
            className={styles.buttonModalCreate}
            onClick={newTableValue}
            aria-label="Adicionar ETA"
          >
            <Icon
              type="plus-circle"
              style={{ fontSize: '30px', color: '#333', display: 'block' }}
            />
          </button>
        )}
      </Row>
      <Row className={styles.sectionColumn}>
        <Tables
          data={tableData}
          query={queryDefault}
          populateTables={getEtaListHandler}
          className="even-gray"
          rowKey={record => record.schedule.id}
          contentToShow="etaTable"
          isLoading={isLoading}
          columnsDefaults={columns}
        />
      </Row>
    </>
  );
};

EstimatedTime.propTypes = {
  editPermission: PropTypes.bool.isRequired
};

export default EstimatedTime;
