import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Select, Row, Col, Skeleton } from 'antd';

import { getGatewayStore } from '@/src/store/modules/gateway-stores/slice';

import { getHubs } from '@/src/store/modules/hubs/slice';

import styles from './StoreModal.module.scss';

const { Option } = Select;

const StoreModal = ({ form, content, data }) => {
  const { showLoading } = useSelector(state => state.gatewayStores);
  const { hubs } = useSelector(state => state.hubs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHubs());
    if (content !== 'new') dispatch(getGatewayStore(content));
    // eslint-disable-next-line
  }, []);

  const formItemConfig = attr => {
    const config = {
      rules: [
        {
          required: true,
          message: 'Por favor, preencha este campo'
        }
      ],
      initialValue: content === 'new' ? undefined : attr
    };
    return config;
  };

  return (
    <Form>
      {content === 'new' && <p>Cadastre abaixo uma nova loja</p>}
      <Row type="flex" gutter={8}>
        <Col span={14}>
          <h3>Nome Loja</h3>
          <Skeleton
            active
            loading={content === 'new' ? false : showLoading}
            paragraph={false}
            className={styles.loading}
          >
            <Form.Item>
              {form.getFieldDecorator(
                'name',
                formItemConfig(data?.name)
              )(<Input placeholder="Ex. Barra 1" />)}
            </Form.Item>
          </Skeleton>
        </Col>
        <Col span={10}>
          <h3>Hub</h3>
          <Skeleton
            active
            loading={content === 'new' ? false : showLoading}
            paragraph={false}
            className={styles.loading}
          >
            <Form.Item style={{ marginBottom: '8px' }}>
              {form.getFieldDecorator(
                'hub_id',
                formItemConfig(data?.hub_id)
              )(
                <Select
                  placeholder="Selecione o Hub"
                  dropdownMatchSelectWidth={false}
                >
                  {hubs.map(item => (
                    <Option
                      key={item.attributes.name}
                      value={item.attributes.id}
                    >
                      {item.attributes.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Skeleton>
        </Col>
      </Row>
      <Row>
        <h3>Secret Key (Mundipagg)</h3>
        <Skeleton
          active
          loading={content === 'new' ? false : showLoading}
          paragraph={false}
          className={styles.loading}
        >
          <Form.Item>
            {form.getFieldDecorator(
              'secret_key',
              formItemConfig(data?.secret_key)
            )(<Input placeholder="Ex. sk_6qNQ4VdTMTmlwolz" />)}
          </Form.Item>
        </Skeleton>
      </Row>
      <Row>
        <h3>Public Key (Mundipagg)</h3>
        <Skeleton
          active
          loading={content === 'new' ? false : showLoading}
          paragraph={false}
          className={styles.loading}
        >
          <Form.Item>
            {form.getFieldDecorator(
              'public_key',
              formItemConfig(data?.public_key)
            )(<Input placeholder="Ex. pk_0nAVw85hRujKyQza" />)}
          </Form.Item>
        </Skeleton>
      </Row>
      <Row>
        <h3>Recipient ID (Pagar.me)</h3>
        <Skeleton
          active
          loading={content === 'new' ? false : showLoading}
          paragraph={false}
          className={styles.loading}
        >
          <Form.Item>
            {form.getFieldDecorator(
              'recipient_id',
              formItemConfig(data?.recipient_id)
            )(<Input placeholder="Ex. re_ckx4xkvle0f9w0p9t84tn2vsk" />)}
          </Form.Item>
        </Skeleton>
      </Row>
    </Form>
  );
};

StoreModal.defaultProps = {
  data: {}
};

StoreModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func
  }).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.objectOf(PropTypes.any)
};

const StoreModalForm = Form.create({ name: 'store-modal-form' })(StoreModal);

export default StoreModalForm;
