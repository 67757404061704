import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Select, Input, Button, Modal, Avatar } from 'antd';

import {
  getShippings,
  getDeliveryCompanies,
  deleteShipping
} from '@/src/store/modules/shipping/slice';

import { getHubsSelect } from '@/src/store/modules/hubs-data/slice';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import CustomDrawer from '@/src/components/CustomDrawer';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
import FilterMenu from '@/src/pages/Filters/index';

import buildJsonData from '@/src/utils/buildJsonData';
import { shippingsQuery } from '@/src/globals/shippingQueries';

import styles from './Shipping.module.scss';

const { Option } = Select;

function Shipping() {
  const dispatch = useDispatch();
  const history = useHistory();
  const typeTable = 'shipping';
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shippings, setShippings] = useState([]);
  const [shipping, setShipping] = useState({});
  const [shippingId, setShippingId] = useState('');

  const { shippingsList, isLoading, query, count } = useSelector(
    state => state.shipping
  );
  const { select } = useSelector(state => state.hubsData);

  useEffect(() => {
    dispatch(
      getHubsSelect({
        query: {
          fields: [{ key: 'hubs', value: 'id,internal_id,name' }],
          sort: 'name',
          perPage: 500
        }
      })
    );
    dispatch(getDeliveryCompanies());
  }, [dispatch]);

  const getShippingsHandler = useCallback(
    urlQuery => {
      dispatch(getShippings(urlQuery));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getShippings(shippingsQuery));
  }, [dispatch]);

  const handleShowDrawer = shippingObj => {
    setShowDrawer(true);
    setShipping(shippingObj);
  };

  const handleShowModal = itemId => {
    setShowModal(true);
    setShippingId(itemId);
  };

  useEffect(() => {
    const shippingsObj = buildJsonData(shippingsList, 'embarks');
    const tableData = shippingsObj.map(item => {
      return {
        ...item,
        handleShowModal
      };
    });
    setShippings(tableData);
  }, [shippingsList]);

  const handleDeleteShipping = () => {
    dispatch(deleteShipping(shippingId));
    setShowModal(false);
  };

  const handleSearchSelect = value => {
    getShippingsHandler({
      query: {
        ...query,
        page: 1,
        filters: value ? [{ key: 'source_hub', type: 'eq', value }] : []
      }
    });
  };

  return (
    <>
      <Modal
        title="Excluir Embarque"
        visible={showModal}
        okText="Excluir"
        onOk={() => handleDeleteShipping()}
        onCancel={() => setShowModal(false)}
        className={`${styles.shippingList__modal} centered-footer`}
      >
        <p>
          Tem certeza que deseja excluir o embarque ID {shippingId}? Não será
          possível recuperar informações após a exclusão.
        </p>
      </Modal>
      {showDrawer && (
        <CustomDrawer
          visible={showDrawer}
          data={shipping}
          onClose={() => setShowDrawer(false)}
          title={`Embarque #${shipping.id}`}
          headerTable="shipping"
        >
          {shipping.deliverer && (
            <CustomDrawer.Box>
              <Row
                type="flex"
                align="middle"
                gutter={16}
                className={styles['shippingList__drawer--user']}
              >
                <Col>
                  <Avatar icon="user" src={shipping.deliverer.imageUrl} />
                </Col>
                <Col>
                  <h3>{shipping.deliverer?.name || '-'}</h3>
                  <p>Veículo: {shipping.deliverer?.vehicle || '-'}</p>
                  <p>Placa: {shipping.deliverer?.plate || '-'}</p>
                </Col>
              </Row>
            </CustomDrawer.Box>
          )}
          <CustomDrawer.Table
            data={shipping.deliveries}
            columns="shippingOrders"
          />
        </CustomDrawer>
      )}
      <Row type="flex" justify="space-between" className="mb-20">
        <h1 className="m-0">Embarques</h1>
        <Button
          className="ant-btn-primary"
          onClick={() => history.push('/embarques/novo')}
        >
          Novo embarque
        </Button>
      </Row>
      <div className="section-wrapper shipping">
        <Row type="flex" align="middle" gutter={8} style={{ marginBottom: 20 }}>
          <Col style={{ flexGrow: 1 }}>
            <Input.Group compact>
              <Select
                size="large"
                placeholder="Todos os hubs"
                onChange={handleSearchSelect}
                allowClear
              >
                {select.map(item => (
                  <Option
                    key={`${item.attributes.name}-${item.id}`}
                    value={item.id}
                  >
                    {item.attributes.name}
                  </Option>
                ))}
              </Select>
              <Searchbar
                placeholder="Buscar por número"
                populateResult={getShippingsHandler}
                query={query}
                dispatchModule
              />
            </Input.Group>
          </Col>
          <Col>
            <FilterMenu filterContent="embarks" />
          </Col>
        </Row>
        <Row type="flex">
          <FilteredTags
            query={query}
            populateTable={getShippingsHandler}
            noMargin
          />
        </Row>
        <Tables
          data={shippings}
          className={`${styles.shippingList__table} even-gray`}
          rowKey={record => record.id}
          contentToShow={typeTable}
          history={history}
          isLoading={isLoading}
          populateTables={getShippingsHandler}
          query={query}
          visiblePaginationUp
          visiblePaginationDown
          count={count}
          rowClick={id => {
            const shippingObj = shippings.find(item => item.id === id);
            handleShowDrawer(shippingObj);
          }}
        />
      </div>
    </>
  );
}

export default Shipping;
