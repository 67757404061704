import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import bugsnag from '@/src/services/bugsnag';

import {
  setIsLoading,
  setHubs,
  setHubSingle,
  setCreateHub,
  getHubs,
  getHubSingle,
  getHubsSelect,
  getUpdateHub,
  getSwitchHubAssociation,
  getCreateHub,
  setCount,
  setQuery,
  setHubsSelect
} from '@/src/store/modules/hubs-data/slice';

import {
  getHubsData as getHubsDataRequest,
  getHubSingleData as getHubSingleDataRequest,
  updateHub as updateHubRequest,
  getCreateHub as getCreateHubRequest
} from '@/src/api/hubs-data';

function* getHubsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getHubsDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/hubs' });
    yield put(setQuery(payload.query));
    if (response.data.meta.stats) {
      const { count } = response.data.meta.stats.total;
      // request da tabela retorna número e request de filtro retorna objeto com cada pedido
      const countNum =
        typeof count === 'number' ? count : Object.keys(count).length;
      yield put(setCount(countNum));
    }
    yield put(setHubs(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar a lista de hubs');
  }
}

function* getHubsSelectSaga({ payload }) {
  try {
    const response = yield call(getHubsDataRequest, payload);
    yield put(setHubsSelect(response.data.data));
  } catch (err) {
    bugsnag.notify(err);
  }
}

function* getHubSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getHubSingleDataRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do hub');
  }
}

function* setUpdateHubSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(updateHubRequest, payload);
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setIsLoading(false));
    successAlert('Os dados do hub foram atualizados');
  } catch (err) {
    yield put(setIsLoading(false));
    // errorAlert(err, 'Não foi possível atualizar os dados do hub');
    errorAlert(err);
  }
}

function* setSwitchDistrictHubSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(updateHubRequest, payload.dissociate);
    const response = yield call(updateHubRequest, payload.associate);
    const normalized = normalize(response.data, { endpoint: '/hubsingle' });
    yield put(setHubSingle(normalized));
    yield put(setIsLoading(false));
    successAlert('Os dados do hub foram atualizados');
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível atualizar os dados do hub');
  }
}

function* getCreateHubSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCreateHubRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/new-hub'
    });
    yield put(setCreateHub(normalized));
    yield put(setIsLoading(false));
    successAlert('Novo hub cadastrado');
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err, 'Não foi possível cadastrar um novo hub');
  }
}

function* hubsListSaga() {
  yield takeEvery(getHubs, getHubsSaga);
  yield takeEvery(getHubSingle, getHubSingleSaga);
  yield takeEvery(getUpdateHub, setUpdateHubSaga);
  yield takeEvery(getSwitchHubAssociation, setSwitchDistrictHubSaga);
  yield takeEvery(getCreateHub, getCreateHubSaga);
  yield takeEvery(getHubsSelect, getHubsSelectSaga);
}

export default hubsListSaga;
