import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button } from 'antd';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import { getAttributes } from '@/src/store/modules/attributes/slice';

function Attributes() {
  const dispatch = useDispatch();
  // const [category] = useState('all');
  const [attributesData, setAttributesData] = useState([]);

  const { attributes, query, isLoading } = useSelector(
    state => state.attributes
  );

  useEffect(() => {
    const attrData = attributes.map(attr => attr.attributes);
    setAttributesData(attrData);
  }, [attributes]);

  const history = useHistory();

  const typeTable = 'attributes';

  // const queryBuilderWithFilter = useCallback(
  //   queryBuilder => {
  //     const queryHandle = {
  //       ...queryBuilder.query,
  //       filters: {
  //         by_category: category
  //       }
  //     };
  //     if (queryHandle.filters.by_category === 'all') {
  //       delete queryHandle.filters.by_category;
  //     }
  //     if (Object.keys(queryHandle.filters).length === 0) {
  //       delete queryHandle.filters;
  //     }
  //     return queryHandle;
  //   },
  //   [category]
  // );

  const getAttributesHandler = useCallback(
    urlQuery => {
      // const queryHandle = queryBuilderWithFilter(urlQuery);
      dispatch(getAttributes(urlQuery));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getAttributesHandler({ query: params });
  }, [getAttributesHandler, history]);

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <h1 style={{ marginBottom: 10 }}>Atributos</h1>
        </div>
        <div>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/atributos/novo')}
          >
            Novo atributo
          </Button>
        </div>
      </Row>
      <div className="section-wrapper">
        <Searchbar
          placeholder="Nome do atributo/valor"
          populateResult={getAttributesHandler}
          query={query}
          value={query.search}
        />
        <Tables
          data={attributesData}
          className="even-gray"
          rowKey={record => record.id}
          contentToShow={typeTable}
          history={history}
          query={query}
          populateTables={getAttributesHandler}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default Attributes;
