import moment from '@/src/services/moment';

export const presentDay = moment()
  .startOf('date')
  .format();

export const presentDayFormatted = moment()
  .startOf('date')
  .format('DD/MM/YY');

export const lastDay = moment(presentDay)
  .subtract(1, 'd')
  .format();

export const lastDayFormatted = moment(presentDay)
  .subtract(1, 'd')
  .format('DD/MM/YY');
