import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breeds: [],
  breed: [],
  loading: false,
  loadingBtn: false,
  query: {
    page: 1,
    perPage: 1000,
    search: ''
  },
  count: 0
};

const breedsSlice = createSlice({
  name: '@breeds',
  initialState,
  reducers: {
    setBreeds(prevState, action) {
      const state = prevState;
      state.breeds = action.payload.breeds;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || ''
      };
      state.count = action.payload.count;
      return state;
    },
    setBreed(prevState, action) {
      const state = prevState;
      state.breed = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.loading = action.payload;
    },
    setLoadingBtn(prevState, action) {
      const state = prevState;
      state.loadingBtn = action.payload;
    },
    getBreeds(state) {
      return state;
    },
    getBreed(state) {
      return state;
    },
    createBreed(state) {
      return state;
    },
    updateBreed(state) {
      return state;
    },
    updateBreedAccessoryType(state) {
      return state;
    }
  }
});

export const {
  setBreeds,
  setBreed,
  setLoading,
  setLoadingBtn,
  getBreeds,
  getBreed,
  createBreed,
  updateBreed,
  updateBreedAccessoryType
} = breedsSlice.actions;

export default breedsSlice.reducer;
