import React from 'react';
import { Link } from 'react-router-dom';
import StatusPill from '@/src/components/StatusPill';

const attributes = [
  {
    title: 'Nome',
    align: 'left',
    dataIndex: 'name',
    render: (text, record) => (
      <Link
        to={`/atributos/${record.id}`}
        style={{
          border: '0',
          textDecoration: 'underline',
          cursor: 'pointer',
          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {text}
      </Link>
    ),
    width: '20%'
  },
  {
    title: 'Valores',
    align: 'left',
    dataIndex: 'allowed_values',
    render: text => <>{text.map(item => item.value).join(', ')}</>
  },
  {
    title: 'Status',
    align: 'center',
    width: '10%',
    dataIndex: 'archived_at',
    render: text => {
      const status = text ? 'inactive' : 'active';
      return <StatusPill status={status} centered />;
    }
  }
];

export default attributes;
