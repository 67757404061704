import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Form,
  Icon,
  Card,
  List,
  Button,
  Spin,
  Modal,
  Tooltip
} from 'antd';
import PropTypes from 'prop-types';
import build from 'redux-object';

import ArrowAssing from '@/src/assets/images/arrow-assign.svg';
import ArrowUnAssing from '@/src/assets/images/arrow-unassign.svg';
import SwitchHub from '@/src/assets/images/switch-hub.svg';

import {
  getUpdateHub,
  getSwitchHubAssociation
} from '@/src/store/modules/hubs-data/slice';

import { getCities } from '@/src/store/modules/cities/slice';

import formatStringToSearch from '@/src/utils/searchUtils';

import styles from './HubDistrict.module.scss';

const { Search } = Input;

const HubDistrict = ({ hubData, editPermission, handleModal }) => {
  const [districts, setDistricts] = useState([]);
  const [citiesDistricts, setCitiesDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [switchModalData, setSwitchModalData] = useState({});
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [associatedDistricts, setAssociatedDistricts] = useState([]);

  const dispatch = useDispatch();

  const { isLoading } = useSelector(state => state.cities);
  const districtsList = useSelector(state => state.districts.list);
  const citiesList = useSelector(state => state.cities.list);

  useEffect(() => {
    const stateDistricts = cities
      .filter(item => item.districts)
      .map(item => {
        return item.districts
          ?.filter(
            district => !district.hub || district.hub.name !== hubData.name
          )
          .map(district => ({ ...district, city: item.name }));
      });
    setCitiesDistricts(stateDistricts);
    setDistricts(stateDistricts);
  }, [cities, hubData.name]);

  useEffect(() => {
    setAssociatedDistricts(hubData.districts);
  }, [hubData.districts]);

  useEffect(() => {
    if (
      hubData.state?.id &&
      districtsList.meta &&
      districtsList.meta['/new-district']
    ) {
      dispatch(
        getCities({
          query: {
            perPage: 1000,
            include: 'districts,districts.hub',
            fields: [
              { key: 'cities', value: 'name,id' },
              { key: 'districts', value: 'name,id,hub' },
              { key: 'hubs', value: 'name,id' }
            ],
            filters: [
              {
                key: 'state_id',
                type: 'eq',
                value: hubData.state.id
              }
            ],
            sort: 'name'
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [districtsList]);

  useEffect(() => {
    const citiesArr = (
      (citiesList.meta && citiesList.meta['/cities'].data) ||
      []
    ).map(object =>
      build(citiesList, 'cities', object.id, { ignoreLinks: true })
    );
    setCities(citiesArr);
  }, [citiesList]);

  const districtsQtyByCity = cities.map(
    item =>
      item.districts?.filter(
        district => !district.hub || district.hub.name !== hubData.name
      ).length || 0
  );

  const totalDistrictsQty =
    districtsQtyByCity.length > 0
      ? districtsQtyByCity.reduce(
          (accumulator, current) => accumulator + current
        )
      : 0;

  const handleSearch = (value, type) => {
    const search = formatStringToSearch(value);
    if (type === 'all') {
      const districtsFiltered = citiesDistricts.map(city =>
        city.filter(
          cityDistrict =>
            formatStringToSearch(cityDistrict.name).indexOf(search) >= 0 ||
            formatStringToSearch(cityDistrict.city).indexOf(search) >= 0
        )
      );
      setDistricts(districtsFiltered);
    } else {
      const newAssociates = hubData.districts.filter(
        associated =>
          formatStringToSearch(associated.name).indexOf(search) >= 0 ||
          formatStringToSearch(associated.city.name).indexOf(search) >= 0
      );
      setAssociatedDistricts(newAssociates);
    }
  };

  const renderAddNewDistrict = (
    <Tooltip title="Cadastrar novo bairro">
      <Button
        className="ant-btn-link p-0"
        onClick={() => handleModal('district')}
      >
        <Icon type="plus-circle" />
      </Button>
    </Tooltip>
  );

  const handleSwitchDistrictHubBtn = district => {
    setSwitchModalData({
      districtId: district.id,
      districtName: district.name,
      dissociateHub: district.hub.id
    });
    setShowSwitchModal(true);
  };

  const handleSwitchDistrictHub = () => {
    const dissociate = {
      data: {
        type: 'hubs',
        id: switchModalData.dissociateHub,
        relationships: {
          districts: {
            data: [
              {
                id: switchModalData.districtId,
                method: 'disassociate',
                type: 'districts'
              }
            ]
          }
        }
      }
    };
    const associate = {
      data: {
        type: 'hubs',
        id: hubData.id,
        relationships: {
          districts: {
            data: [
              {
                id: switchModalData.districtId,
                method: 'update',
                type: 'districts'
              }
            ]
          }
        }
      }
    };
    dispatch(getSwitchHubAssociation({ dissociate, associate }));
    setShowSwitchModal(false);
  };

  const handleAssociateDistrict = districtId => {
    const payload = {
      data: {
        type: 'hubs',
        id: hubData.id,
        relationships: {
          districts: {
            data: [
              {
                id: districtId,
                method: 'update',
                type: 'districts'
              }
            ]
          }
        }
      }
    };
    dispatch(getUpdateHub(payload));
  };

  const handleDissociateDistrict = districtId => {
    const payload = {
      data: {
        type: 'hubs',
        id: hubData.id,
        relationships: {
          districts: {
            data: [
              {
                id: districtId,
                method: 'disassociate',
                type: 'districts'
              }
            ]
          }
        }
      }
    };
    dispatch(getUpdateHub(payload));
  };

  const handleShowAssociateBtn = district => {
    if (editPermission) {
      return (
        <Tooltip title={`Associar bairro ao ${hubData.name}`}>
          <Button
            className="ant-btn-link p-0"
            onClick={() => handleAssociateDistrict(district.id)}
          >
            <img src={ArrowAssing} alt="Botão de associar" />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Row>
      <Modal
        title={<h2>Trocar de Hub</h2>}
        okText="Salvar"
        visible={showSwitchModal}
        onOk={handleSwitchDistrictHub}
        onCancel={() => setShowSwitchModal(false)}
        className="ant-modal-hubs centered-footer"
        cancelButtonProps={{ className: 'ant-btn-secondary' }}
      >
        <p>
          Ao continuar com esta ação, você irá associar o bairro{' '}
          {switchModalData.districtName} ao {hubData.name}. Tem certeza que
          deseja fazer esta mudança?
        </p>
      </Modal>
      <Card
        className="no-shadow p-0"
        style={{ borderTop: '1px solid #e8e8e8', borderRadius: '0px' }}
      >
        <Col className={styles.sectionColumn}>
          <Row>
            <h3>Seleção de bairros para {hubData.name}</h3>
            <p>Lista de bairros para acesso rápido ao aplicativo</p>
          </Row>
          <Row>
            <Col span={12}>
              <Card
                title={`Cidades/Bairros (${totalDistrictsQty})`}
                extra={editPermission && renderAddNewDistrict}
                className={`no-shadow small-title ${styles.listCard}`}
              >
                <Search
                  placeholder="Buscar nome de cidade ou bairro"
                  size="large"
                  className="ant-searchbar ant-searchbar-list"
                  onSearch={e => handleSearch(e, 'all')}
                  allowClear
                />
                <Spin spinning={isLoading}>
                  <List className={styles.listCard__wrap}>
                    {districts.map(item =>
                      item.map(district => (
                        <List.Item
                          className={`${styles.listCard__item} ${
                            district.hub
                              ? styles['listCard__item--associated']
                              : ''
                          }`}
                          key={district.id}
                        >
                          <p>
                            {district.city}/{district.name}
                          </p>
                          {district.hub ? (
                            <div
                              className={styles['listCard__item--disclaimer']}
                            >
                              <strong>
                                (associado ao hub {district.hub?.name})
                              </strong>
                              {editPermission && (
                                <Tooltip title={`Alterar para ${hubData.name}`}>
                                  <Button
                                    className="ant-btn-link p-0"
                                    onClick={() =>
                                      handleSwitchDistrictHubBtn(district)
                                    }
                                  >
                                    <img
                                      src={SwitchHub}
                                      alt="Botão de trocar"
                                    />
                                  </Button>
                                </Tooltip>
                              )}
                            </div>
                          ) : (
                            handleShowAssociateBtn(district)
                            //   <Button
                            //   className="ant-btn-link p-0"
                            //   onClick={() =>
                            //     handleAssociateDistrict(district.id)
                            //   }
                            // >
                            //   <img src={ArrowAssing} alt="Switch Button" />
                            // </Button>
                          )}
                        </List.Item>
                      ))
                    )}
                  </List>
                </Spin>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title={`Bairros associados ao hub ${hubData?.name || ''}`}
                extra={
                  <h4 style={{ cursor: 'text' }}>
                    {associatedDistricts?.length === 1
                      ? '1 bairro associado'
                      : `${associatedDistricts?.length} bairros associados`}
                  </h4>
                }
                className={`no-shadow small-title ${styles.listCard}`}
              >
                <Search
                  placeholder="Buscar nome da cidade ou bairro"
                  size="large"
                  className="ant-searchbar ant-searchbar-list"
                  onSearch={e => handleSearch(e, 'associated')}
                  allowClear
                />
                <List className={styles.listCard__wrap}>
                  {associatedDistricts?.map(item => {
                    return (
                      <List.Item
                        className={styles.listCard__item}
                        key={item.id}
                      >
                        <p>
                          {item.city.name}/{item.name}
                        </p>
                        {editPermission && (
                          <Tooltip
                            title={`Desassociar bairro do ${hubData.name}`}
                          >
                            <Button
                              className="ant-btn-link p-0"
                              onClick={() => handleDissociateDistrict(item.id)}
                            >
                              <img
                                src={ArrowUnAssing}
                                alt="Botão de desassociar"
                              />
                            </Button>
                          </Tooltip>
                        )}
                      </List.Item>
                    );
                  })}
                </List>
              </Card>
            </Col>
          </Row>
        </Col>
      </Card>
    </Row>
  );
};

HubDistrict.defaultProps = {
  hubData: {},
  form: {
    getFieldDecorator: () => {},
    validateFields: () => {},
    setFieldsValue: () => {}
  }
};

HubDistrict.propTypes = {
  hubData: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }),
  editPermission: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired
};

const HubDistrictForm = Form.create({ name: 'HubDistrict' })(HubDistrict);

export default HubDistrictForm;
