import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin, Row, Col, Alert } from 'antd';

import moment from '@/src/services/moment';
import CustomerQuickInfo from '@/src/components/Order/CustomerQuickInfo/CustomerQuickInfo';
import EvolutionDiagram from '@/src/components/Order/EvolutionDiagram/EvolutionDiagram';
import OrderResume from '@/src/components/Order/OrderResume/OrderResume';
import OrderDetails from '@/src/components/Order/OrderDetails/OrderDetails';
import OrderReshipments from '@/src/components/Order/OrderReshipments/OrderReshipments';
import Timeline from '@/src/components/Order/Timeline/Timeline';
import CustomerDetails from '@/src/components/Order/CustomerDetails/CustomerDetails';
import StatusAndDeliverer from '@/src/pages/Order/containers/StatusAndDeliverer/index';
import OrderStyles from '@/src/pages/Order/Order.module.scss';
import Map from '@/src/pages/Order/containers/Map/index';

import makeCall from '@/src/utils/makeCallToCustomer';

import {
  getOrder,
  getOrderTimeline,
  resetOrder,
  cancelOrder,
  returnItens,
  getPushNotification,
  fraudOrder,
  chargeBackFraudOrder
} from '@/src/store/modules/order-details/slice';

import { getOrderReshipments } from '@/src/store/modules/expedition/slice';

import {
  commentTimeline,
  deleteCommentTimeline
} from '@/src/store/modules/timeline-events/slice';

import { addTag, removeTag } from '@/src/store/modules/tags/slice';

import useInterval from '@/src/hooks/useInterval';
import usePermissions from '@/src/hooks/usePermissions';

import * as modules from '@/src/globals/permissionsModules';

const Order = props => {
  const { match } = props;

  const dispatch = useDispatch();

  const { orderId } = useParams();

  const {
    order,
    customer,
    // delivery,
    // deliverer,
    order_item,
    order_alert,
    parcel,
    order_item_parcel,
    pet,
    // setPagination,
    // setSort,
    orderStatus,
    fraud,
    fraud_type,
    orderLoading,
    orderResumeLoading,
    orderDetailsLoading
  } = useSelector(state => state.order);

  const { reshipments } = useSelector(state => state.expedition);

  const { id: customer_id } = useSelector(state => state.order.customer);

  const tags = useSelector(state => state.tags.tags);

  const { timelineEvents, isLoading: isLoadingTimeline } = useSelector(
    state => state.timeline
  );

  const { editPermission } = usePermissions();
  const ordersPermission = editPermission(modules.ORDERS);

  const { attributes } = order.data;

  const getOrderTimelineHandler = useCallback(
    payload => {
      dispatch(
        getOrderTimeline({
          object_id: payload,
          object_type: 'Order'
        })
      );
    },
    [dispatch]
  );
  const resetOrderHandler = useCallback(() => {
    dispatch(resetOrder());
  }, [dispatch]);

  const cancelOrderHandler = useCallback(
    payload => {
      dispatch(cancelOrder(payload));
    },
    [dispatch]
  );

  const returnItensHandler = useCallback(
    payload => {
      dispatch(returnItens(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getOrder(match.params.orderId));
    getOrderTimelineHandler(match.params.orderId);
    return () => {
      resetOrderHandler();
    };
    // eslint-disable-next-line
  }, [resetOrderHandler, getOrderTimelineHandler, match]);

  useEffect(() => {
    if (order?.data?.attributes?.number) {
      dispatch(getOrderReshipments(order.data.attributes.number));
    }
    // eslint-disable-next-line
  }, [order.data.attributes.number]);

  useInterval(
    () => {
      getOrderTimelineHandler(match.params.orderId);
    },
    attributes.status === 'delivered' || attributes.status === 'canceled'
      ? null
      : 5000
  );

  const handlePushNotification = type => {
    switch (type) {
      case 'whatsapp':
        dispatch(getPushNotification({ customer_id, type, order_id: orderId }));
        break;
      case 'push':
        dispatch(getPushNotification({ customer_id, type, order_id: orderId }));
        break;
      default:
    }
  };

  const addTagHandler = name =>
    dispatch(
      addTag({
        taggable_type: 'Customer',
        taggable_id: customer_id,
        name
      })
    );

  const removeTagHandler = name =>
    dispatch(
      removeTag({
        taggable_type: 'Customer',
        taggable_id: customer_id,
        name
      })
    );

  const commentHandler = comment =>
    dispatch(
      commentTimeline({
        value: comment,
        object_id: orderId,
        object_type: 'Order'
      })
    );

  const deleteCommentHandler = id =>
    dispatch(
      deleteCommentTimeline({ id, object_id: orderId, object_type: 'Order' })
    );

  const handleFraud = () =>
    dispatch(fraudOrder({ id: orderId, value: 'manual_analysis' }));

  const handleFraudChargeback = () =>
    dispatch(chargeBackFraudOrder({ id: orderId, value: 'chargeback' }));

  return (
    <>
      <Spin spinning={orderLoading}>
        {/* <Row type="flex" justify="space-between" align="middle">
          <Col span={20}>
            <h1>Pedido</h1>
          </Col>
        </Row>
        <Breadcrumb separator="" style={{ marginBottom: 20 }}>
          <Breadcrumb.Item>
            <Link to="/pedidos">Pedidos</Link>
            <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Pedido #{attributes.number}</Breadcrumb.Item>
        </Breadcrumb> */}
        {fraud && (
          <Alert
            style={{ width: '100%', marginBottom: '10px' }}
            message={
              fraud_type === 'manual_analysis' ||
              attributes.fraud_type === 'manual_analysis'
                ? 'PEDIDO FRAUDULENTO (análise manual)'
                : 'PEDIDO FRAUDULENTO (chargeback)'
            }
            type="error"
            showIcon
          />
        )}
        <section className={OrderStyles.Order}>
          <h1 className={OrderStyles['Order__order-info']}>
            Pedido / #{attributes.number}
            <span style={{ marginLeft: 5 }}>/ {attributes.hub_name}</span>
            <span className={OrderStyles['Order__order-info--date']}>
              / {moment(attributes.created_at).format('DD-MM-YYYY / HH:mm:ss')}
            </span>
          </h1>
          <Map
            orderAttributes={attributes}
            customer={customer}
            // deliverer={deliverer}
          />
          <StatusAndDeliverer
            orderAttributes={attributes}
            // customer={customer}
            // deliverer={deliverer}
          />
        </section>
        <CustomerQuickInfo customer={customer} makeCall={makeCall} />
        <EvolutionDiagram
          orderAttributes={attributes}
          orderStatus={orderStatus}
        />
        <Row className={OrderStyles.container}>
          {reshipments.length > 0 && (
            <Row style={{ marginBottom: 8 }}>
              <OrderReshipments
                reshipments={reshipments}
                orderItems={order_item}
              />
            </Row>
          )}
          <Row type="flex" gutter={8} justify="space-between">
            <Col span={8}>
              <Spin
                spinning={orderResumeLoading}
                wrapperClassName="order-resume-loading"
              >
                <OrderResume
                  order={order}
                  orderItem={order_item}
                  orderAlert={order_alert}
                  parcel={parcel}
                  orderItemParcel={order_item_parcel}
                  cancelOrder={cancelOrderHandler}
                  dispatchReturnItens={returnItensHandler}
                  editPermission={ordersPermission}
                />
              </Spin>
            </Col>
            <Col span={8}>
              <Spin spinning={orderDetailsLoading}>
                <OrderDetails
                  order={order.data.attributes}
                  handleFraud={handleFraud}
                  handleFraudChargeback={handleFraudChargeback}
                  fraud={fraud}
                  editPermission={ordersPermission}
                />
              </Spin>
              <Timeline
                commentHandlerTimeline={commentHandler}
                deleteCommentHandlerTimeline={deleteCommentHandler}
                order={order}
                isLoading={isLoadingTimeline}
                timelineEvents={timelineEvents}
                orderId={orderId}
                editPermission={ordersPermission}
              />
            </Col>
            <Col span={8}>
              <CustomerDetails
                customer={customer.attributes}
                makeCall={makeCall}
                pet={pet}
                pushNotificationFunction={handlePushNotification}
                addTags={addTagHandler}
                removeTags={removeTagHandler}
                tags={tags}
                editPermission={ordersPermission}
              />
            </Col>
          </Row>
        </Row>
      </Spin>
    </>
  );
};

Order.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string
    })
  }).isRequired
};

export default Order;
