import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Button,
  Modal,
  Form,
  Radio,
  InputNumber,
  Dropdown,
  Menu,
  Icon,
  Timeline,
  Pagination,
  Row,
  Col,
  Spin
} from 'antd';
import PropTypes from 'prop-types';
import formatCurrency from '@/src/utils/formatCurrency';
import getNumberFromString from '@/src/utils/getNumberFromString';
import moment from '@/src/services/moment';

import Audit from '@/src/components/Audit/index';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import { getCustomerWalletTransactions } from '@/src/store/modules/customers/slice';

const Wallet = props => {
  const { form, removeFromWallet, addToWallet, customer } = props;

  const dispatch = useDispatch();

  const { attributes } = customer;

  const [isVisible, setVisible] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  const [auditConfig, setAuditConfig] = useState({
    id: '',
    title: '',
    type: ''
  });

  const { isLoading: isLoadingCustomer } = useSelector(
    state => state.customers
  );
  const { wallet, walletTransactions, isLoadingWallet } = useSelector(
    state => state.wallet
  );

  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
    resetFields
  } = form;

  const { editPermission } = usePermissions();

  const customersPermission = editPermission(modules.CUSTOMERS);
  const creditsPermission = editPermission(modules.CREDITS);

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (values.type === 'add') {
          addToWallet(wallet.id, values.amount);
        }
        if (values.type === 'remove') {
          removeFromWallet(wallet.id, values.amount);
        }
        resetFields();
        setVisible(false);
      }
      return false;
    });
  };

  const handleShowAudit = type => {
    setAuditConfig({
      id: type === 'Wallet' ? wallet.id : walletTransactions.data[0].id,
      type,
      title: type === 'Wallet' ? 'CARTEIRA' : 'TRANSAÇÃO'
    });
    setShowAudit(true);
  };

  const walletActions = (
    <Menu>
      <Menu.Item key="0" onClick={() => handleShowAudit('Wallet')}>
        Histórico de atualizações da carteira
      </Menu.Item>
      {walletTransactions.data.length && (
        <Menu.Item key="1" onClick={() => handleShowAudit('WalletTransaction')}>
          Histórico de atualizações da última transação
        </Menu.Item>
      )}
    </Menu>
  );

  const handleWalletPagination = page => {
    dispatch(
      getCustomerWalletTransactions({
        id: customer.id,
        query: {
          perPage: 5,
          page
        }
      })
    );
  };

  return (
    <>
      <Audit
        id={auditConfig.id}
        title={auditConfig.title}
        type={auditConfig.type}
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Modal
        className="ant-modal-customer"
        title="ALTERAR VALOR CRÉDITOS"
        visible={isVisible}
        onCancel={() => {
          setVisible(false);
          resetFields();
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isLoadingWallet}
            onClick={handleSubmit}
            disabled={!(getFieldValue('type') && getFieldValue('amount'))}
          >
            SALVAR ALTERAÇÃO
          </Button>
        ]}
      >
        <Form layout="horizontal">
          <p style={{ fontWeight: 'bold' }}>O que deseja fazer?</p>
          <Form.Item>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: 'Tipo de transação é obrigatório'
                }
              ]
            })(
              <Radio.Group
                buttonStyle="solid"
                onChange={() => {
                  if (getFieldValue('type')) setFieldsValue({ amount: '' });
                }}
              >
                {creditsPermission && (
                  <Radio.Button
                    style={{ margin: 5 }}
                    className="ant-btn-filter"
                    value="add"
                  >
                    Adicionar
                  </Radio.Button>
                )}
                {creditsPermission && (
                  <Radio.Button
                    style={{ margin: 5 }}
                    className="ant-btn-filter"
                    value="remove"
                  >
                    Retirar
                  </Radio.Button>
                )}
              </Radio.Group>
            )}
          </Form.Item>
          <p style={{ fontWeight: 'bold' }}>Valor de créditos</p>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('amount', {
              rules: [
                {
                  validator: (rule, value, cb) => value > 0,
                  message: 'Valor deve ser maior que zero'
                },
                {
                  validator: (rule, value, cb) => {
                    const maxValue =
                      getFieldValue('type') === 'remove'
                        ? wallet?.attributes?.balance
                        : 60000;
                    return value <= maxValue;
                  },
                  message:
                    getFieldValue('type') === 'remove'
                      ? `Valor de retirada deve ser igual ou menor que saldo da carteira`
                      : 'Valor deve ser igual ou menor que R$ 600'
                }
              ]
            })(
              <InputNumber
                placeholder="0,00"
                style={{ width: 350, marginBottom: 3 }}
                formatter={value => formatCurrency(value, false)}
                parser={value => getNumberFromString(value)}
                // disabled={!getFieldValue('type')}
              />
            )}
          </Form.Item>
          <p style={{ marginTop: 8 }}>
            Saldo de créditos: {formatCurrency(wallet?.attributes?.balance)}
          </p>
        </Form>
      </Modal>
      <Card
        className="car-wallet no-shadow"
        title={<h2>Créditos</h2>}
        extra={
          !isLoadingCustomer &&
          wallet.id &&
          creditsPermission &&
          customersPermission && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Dropdown overlay={walletActions} trigger={['click']}>
                <span>
                  <Icon
                    type="ellipsis"
                    key="ellipsis"
                    style={{ fontSize: '30px' }}
                  />
                </span>
              </Dropdown>
              <Button
                disabled={
                  attributes?.banned || attributes?.banned_from_referral
                }
                type="primary"
                onClick={() => setVisible(true)}
                style={{ marginLeft: 16 }}
              >
                Alterar Valor
              </Button>
            </div>
          )
        }
      >
        {isLoadingCustomer && (
          <div
            style={{
              textAlign: 'center',
              padding: 30
            }}
          >
            <Spin />
          </div>
        )}
        {!isLoadingCustomer && !wallet.id && (
          <p style={{ margin: '16px 0' }}>
            Usuário não possui função <strong>carteira</strong> ativada
          </p>
        )}
        {!isLoadingCustomer && wallet.id && (
          <>
            <h1 style={{ marginBottom: '10px', fontSize: '24px' }}>
              {formatCurrency(wallet?.attributes?.balance)}
            </h1>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col>
                <h4 style={{ marginBottom: 0 }}>
                  <strong>Histórico de movimentações</strong>
                </h4>
              </Col>
              <Col>
                <Pagination
                  total={walletTransactions.count}
                  current={walletTransactions.page}
                  pageSize={5}
                  onChange={handleWalletPagination}
                />
              </Col>
            </Row>
            <Timeline className="timeline-wallet">
              {isLoadingWallet && (
                <div
                  style={{
                    textAlign: 'center',
                    padding: 30
                  }}
                >
                  <Spin />
                </div>
              )}
              {!isLoadingWallet && !walletTransactions.data.length && (
                <p style={{ marginBottom: 0 }}>
                  Nenhuma transação efetuada na <strong>carteira</strong> ainda.
                </p>
              )}
              {!isLoadingWallet &&
                walletTransactions.data.length &&
                walletTransactions.data.map(item => {
                  const credit = item.attributes.transaction_type === 'credit';
                  return (
                    <Timeline.Item
                      color={credit ? 'green' : 'red'}
                      key={`wallet-transaction-${item.id}`}
                    >
                      <h3>{credit ? 'Entrada' : 'Saída'}</h3>
                      <span>
                        {moment(item.attributes.created_at).format(
                          'DD/MM/YYYY'
                        )}
                      </span>
                      <p>
                        {!credit && '- '}
                        {formatCurrency(item.attributes.transaction_value)}
                      </p>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </>
        )}
      </Card>
    </>
  );
};

Wallet.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  removeFromWallet: PropTypes.func.isRequired,
  addToWallet: PropTypes.func.isRequired
  // editPermission: PropTypes.bool.isRequired
};

const WalletForm = Form.create({ name: 'wallet_customer_form' })(Wallet);

export default WalletForm;
