import * as firebase from 'firebase/app';

import 'firebase/database';
import 'firebase/auth';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyDOPPlIEtdhiyvxQBlPyr5IwMbbIlRk67c',
  authDomain: 'zee-now-220318.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_URL,
  projectId: 'zee-now-220318',
  storageBucket: 'zee-now-220318.appspot.com',
  messagingSenderId: '363580785943',
  appId: '1:363580785943:web:09bcab17b0c27077'
});

export const appLogin = () =>
  app
    .auth()
    .signInWithEmailAndPassword('chat@zeenow.com', 'UYgWsUR1}N:Kj}XV9}yA')
    .catch(error => {
      console.log(error);
    });

export default app;
