export default id => `<svg width="21px" height="31px" viewBox="0 0 21 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
            <path d="M10.5,30.2085865 C17.5,20.9355221 21,14.3659932 21,10.5 C21,4.70101013 16.2989899,1.77635684e-15 10.5,1.77635684e-15 C4.70101013,1.77635684e-15 0,4.70101013 0,10.5 C0,14.3659932 3.5,20.9355221 10.5,30.2085865 Z" id="Oval" fill="#000000"></path>
            <circle id="Oval" fill="#FFFFFF" cx="10.5" cy="10.5" r="7.5"></circle>
            <text  x="50%" y="35%" text-anchor="middle" font-family="sans-serif" stroke="#000" fill="#000" font-size="10px" dy=".3em">${id}</text>
        </g>
    </g>
</svg>
`;
