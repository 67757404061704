export const getOS = userAgent => {
  let os = 'No OS detection';
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    os = 'iOS';
  } else if (/Android/i.test(userAgent)) {
    os = 'Android';
  } else if (/Macintosh/i.test(userAgent)) {
    os = 'Mac OS';
  } else if (/Windows/i.test(userAgent)) {
    os = 'Windows';
  } else if (/Linux/i.test(userAgent)) {
    os = 'Linux';
  }
  return os;
};

const getBrowser = userAgent => {
  let browser = 'No browser detection';
  if (userAgent.indexOf('edge') > -1) {
    browser = 'MS Edge';
  } else if (userAgent.indexOf('edg/') > -1) {
    browser = 'Edge (chromium based)';
  } else if (userAgent.indexOf('chrome') > -1) {
    browser = 'Chrome';
  } else if (userAgent.indexOf('firefox') > -1) {
    browser = 'Mozilla Firefox';
  } else if (userAgent.indexOf('safari') > -1) {
    browser = 'Safari';
  }
  return browser;
};

// client: navigator.userAgent
const getDeviceData = userAgent => {
  const os = getOS(userAgent.toLowerCase());
  const browser = getBrowser(userAgent.toLowerCase());
  return {
    os,
    browser
  };
};

export default getDeviceData;
