import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Upload } from 'antd';

const MiniatureUpload = ({
  uploadImage,
  withoutImage,
  handleBeforeUpload,
  handleUploadChange,
  handleImageError
}) => {
  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={handleBeforeUpload}
        onChange={handleUploadChange}
      >
        {uploadImage ? (
          <img
            src={uploadImage}
            alt="avatar"
            style={{ width: '100%' }}
            onError={handleImageError}
          />
        ) : (
          <Icon
            style={{ fontSize: '30px', color: '#ccc' }}
            type="camera"
            theme="filled"
          />
        )}
      </Upload>
      {withoutImage && (
        <span
          style={{
            color: '#f5222d',
            fontSize: 10,
            lineHeight: 1.2,
            display: 'block',
            width: 100
          }}
        >
          Por favor, adicione uma imagem
        </span>
      )}
    </>
  );
};

MiniatureUpload.defaultProps = {
  handleImageError: () => {}
};

MiniatureUpload.propTypes = {
  handleBeforeUpload: PropTypes.func.isRequired,
  handleUploadChange: PropTypes.func.isRequired,
  handleImageError: PropTypes.func,
  withoutImage: PropTypes.bool.isRequired,
  uploadImage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.string
  ]).isRequired
};

export default MiniatureUpload;
