import { Drawer, Button, Collapse, Select, Icon, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getHubs } from '@/src/store/modules/hubs/slice';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import style from '../../PriceIndexing/PriceIndex.module.scss';

const { Panel } = Collapse;
const { Option } = Select;

const PriceIndexFilterModal = ({
  showModal,
  setShowModal,
  handleSetFilter,
  selectedHubs,
  setSelectedHubs
}) => {
  const dispatch = useDispatch();

  // const [selectedHubs, setSelectedHubs] = useState([]);

  const { hubs, isLoading: hubsLoading } = useSelector(state => state.hubs);

  const populateHubs = () => dispatch(getHubs());

  return (
    <Drawer
      headerStyle={{
        backgroundColor: 'white',
        padding: '16px',
        fontWeight: 'bold',
        borderBottomWidth: '1px'
      }}
      bodyStyle={{ overflow: 'auto' }}
      width={386}
      visible={showModal}
      onClose={() => setShowModal(false)}
      title={
        <h3
          style={{ fontWeight: 'bold', textTransform: 'capitalize', margin: 0 }}
        >
          Filtros
        </h3>
      }
      placement="right"
    >
      <Collapse
        expandIcon={() => <Icon type="caret-down" />}
        className={style.modalCollapse}
        expandIconPosition="right"
        bordered={false}
      >
        <Panel header="Hubs">
          <div className={style.modalSelectContainer}>
            <Select
              mode="multiple"
              size="large"
              style={{ width: '100%' }}
              placeholder="Selecione os hubs"
              onFocus={populateHubs}
              defaultValue={selectedHubs}
              value={selectedHubs || []}
              filterOption={handleSelectSearch}
              labelInValue
              onChange={evt => {
                setSelectedHubs(evt);
              }}
              notFoundContent={hubsLoading ? <Spin size="small" /> : null}
            >
              {[...hubs]
                .sort((a, b) =>
                  a.attributes.name.localeCompare(b.attributes.name)
                )
                .map(hub => {
                  return (
                    <Option key={hub.attributes.internal_id}>
                      {hub.attributes.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </Panel>
      </Collapse>

      <div className={style.modalFooterButtons}>
        <Button
          onClick={() => {
            setSelectedHubs([]);
            setShowModal(false);
          }}
          style={{ fontSize: 12, marginRight: '8px' }}
          className="ant-btn ant-btn-secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            handleSetFilter(selectedHubs);
          }}
          style={{ width: 131, fontSize: 12 }}
          className="ant-btn ant-btn-primary"
        >
          Aplicar filtros
        </Button>
      </div>
    </Drawer>
  );
};

PriceIndexFilterModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  selectedHubs: PropTypes.any.isRequired,
  setSelectedHubs: PropTypes.func.isRequired
};

export default PriceIndexFilterModal;
