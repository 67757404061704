import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import { getAuditLog } from '@/src/store/modules/audit-log/slice';

import Tables from '@/src/components/Tables/index';

const Audit = ({ id, title, type, visible, setVisible }) => {
  const modalTitle = `HISTÓRICO DE ATUALIZAÇÕES - ${title}`;
  const tableType = type === 'SkuPetz' ? 'logSkuPetz' : 'logUser';

  const dispatch = useDispatch();

  const { isLoading, auditLog, query, count } = useSelector(
    state => state.auditLog
  );

  const [petzData, setPetzData] = useState([]);
  const [petzEvent, setPetzEvent] = useState({});

  const getUserLogsHandler = useCallback(
    urlQuery => {
      const auditQuery = {
        ...urlQuery,
        auditable_id: id,
        auditable_type: type
      };
      if (type === 'Property') auditQuery.query['include[]'] = 'allowed_values';
      dispatch(getAuditLog(auditQuery));
    },
    [dispatch, id, type]
  );

  useEffect(() => {
    if (visible) {
      getUserLogsHandler({
        query: {
          page: 1,
          perPage: 20,
          search: '',
          url: ''
        }
      });
    }
    // eslint-disable-next-line
  }, [visible]);

  const handlePetzEventModal = data => setPetzEvent(data);

  useEffect(() => {
    if (type !== 'SkuPetz') return;
    const data = auditLog.map(item => ({
      ...item,
      handlePetzEventModal
    }));
    setPetzData(data);
    // eslint-disable-next-line
  }, [auditLog]);

  return (
    <>
      {type === 'SkuPetz' && (
        <Modal
          title="PAYLOAD EVENTO ORIGINAL - PETZ"
          visible={!!petzEvent.data}
          onOk={() => setPetzEvent({})}
          onCancel={() => setPetzEvent({})}
          width={900}
          bodyStyle={{ maxHeight: 600, overflow: 'scroll' }}
          centered
          footer={null}
        >
          <pre style={{ alignItems: 'left' }}>
            {JSON.stringify(petzEvent, null, 4)}
          </pre>
        </Modal>
      )}
      <Modal
        title={modalTitle}
        width={type === 'SkuPetz' ? 1200 : 900}
        visible={visible}
        className="user-logTable"
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Tables
          className="table-padding-logUser"
          contentToShow={tableType}
          visiblePaginationUp
          isLoading={isLoading}
          data={type === 'SkuPetz' ? petzData : auditLog}
          hasScroll
          count={count}
          query={query}
          populateTables={getUserLogsHandler}
          showCountFilters={type === 'SkuPetz'}
          showCountText={type === 'SkuPetz' ? 'eventos' : ''}
        />
      </Modal>
    </>
  );
};

Audit.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default Audit;
