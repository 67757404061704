import React from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';

const ModalCSV = props => {
  const {
    titleModal,
    // IsLoading,
    isVisible,
    setIsVisibleModalCSV,
    components,
    buttonDisabled,
    CSVFileHandle
  } = props;

  return (
    <Modal
      title={titleModal}
      // width={760}
      visible={isVisible}
      className="modal-csv-import-export "
      onCancel={setIsVisibleModalCSV}
      onOk={() => {}}
      footer={[
        <Button
          key="submit"
          onClick={CSVFileHandle}
          className="ant-btn ant-btn-primary"
          disabled={buttonDisabled}
        >
          importar
        </Button>
      ]}
    >
      {components}
    </Modal>
  );
};

ModalCSV.defaultProps = {
  isVisible: false,
  buttonDisabled: false
};

ModalCSV.propTypes = {
  titleModal: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  components: PropTypes.element.isRequired,
  setIsVisibleModalCSV: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  CSVFileHandle: PropTypes.func.isRequired
};

export default ModalCSV;
