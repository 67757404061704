import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { Search } = Input;

const Searchbar = props => {
  const {
    placeholder,
    populateResult,
    query,
    value,
    style,
    dispatchModule,
    handleClearResults,
    disabled,
    enterButton
  } = props;

  const [inputValue, setInputValue] = useState('');

  const { account } = useSelector(state => state.account);

  useEffect(() => {
    setInputValue(value);
    return () => setInputValue('');
  }, [value]);

  const handleSearch = e => {
    if (
      !e &&
      window.location.href.includes('expedicao?view=lines') &&
      account.kind === 'office'
    ) {
      handleClearResults();
      return;
    }
    const searchTerm = e.trim();
    let searchQuery = {
      query: {
        ...query,
        page: 1,
        perPage: query?.perPage,
        search: searchTerm,
        sort: query?.sort,
        filters: query?.filters
      }
    };
    if (dispatchModule) {
      let filters;
      if (e) {
        filters =
          window.location.href.includes('expedicao?view=lines') &&
          account.kind === 'office'
            ? [
                ...query?.filters.filter(
                  item => item.key === 'hub_internal_id'
                ),
                {
                  key: 'search',
                  type: 'match',
                  value: searchTerm
                }
              ]
            : [
                ...query?.filters.filter(item => item.key !== 'search'),
                {
                  key: 'search',
                  type: 'match',
                  value: searchTerm
                }
              ];
      } else {
        filters = [...query?.filters.filter(item => item.key !== 'search')];
      }
      searchQuery = {
        query: {
          ...query,
          page: 1,
          perPage: query?.perPage || 20,
          sort: query?.sort,
          filters
          // stats: [{ key: 'total', value: 'count' }]
        }
      };
    }
    populateResult(searchQuery);
  };

  return (
    <Search
      placeholder={placeholder}
      style={style}
      enterButton={enterButton}
      size="large"
      className="ant-searchbar"
      value={inputValue || ''}
      onSearch={e => handleSearch(e)}
      onChange={e => setInputValue(e.target.value)}
      allowClear
      disabled={disabled}
    />
  );
};

Searchbar.defaultProps = {
  placeholder: '',
  value: '',
  style: null,
  dispatchModule: false,
  disabled: false,
  enterButton: null,
  handleClearResults: () => {}
};

Searchbar.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  populateResult: PropTypes.func.isRequired,
  value: PropTypes.string,
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
  }).isRequired,
  dispatchModule: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClearResults: PropTypes.func,
  enterButton: PropTypes.bool
};

export default Searchbar;
