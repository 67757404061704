import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Breadcrumb,
  Input,
  Form,
  Tooltip,
  Icon,
  Card,
  Button,
  Dropdown,
  Menu
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import AttributesValue from '@/src/components/AttributesValue';
import Audit from '@/src/components/Audit/index';

import {
  getAttribute,
  getUpdateAttribute,
  getArchiveAndUnarchive
} from '@/src/store/modules/attributes/slice';

import { setCurrentValues } from '@/src/store/modules/attribute-values/slice';

const EditAttribute = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const { attributeData } = useSelector(state => state.attributes);

  const { currentValues } = useSelector(state => state.attributeValues);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showAudit, setShowAudit] = useState(false);

  const { id } = useParams();

  const attributesArray = attributeData?.attributes?.allowed_values
    ? attributeData?.attributes?.allowed_values.map(val => {
        return {
          name: val.value
        };
      })
    : [{}];

  const attributeValuesRef = useRef(attributesArray);

  useEffect(() => {
    dispatch(getAttribute(id));
  }, [dispatch, id]);

  const handleSubmitWithAllValues = formValues => {
    const newValues = attributeValuesRef.current
      .filter(item => item)
      .map(item => item.state.value);
    const payload = {
      name: formValues.name,
      values: newValues
    };
    dispatch(
      getUpdateAttribute({
        id,
        data: payload
      })
    );
  };

  const handleAttributeValues = () => {
    const newValues = attributeValuesRef.current
      .filter(item => item)
      .map(item => {
        return { name: item.state.value };
      });
    dispatch(setCurrentValues(newValues));
  };

  const onSubmit = () => {
    if (attributeValuesRef) handleAttributeValues();
    let isValidated = true;
    attributeValuesRef.current.forEach(attribute => {
      const el = attribute;
      if (el && el.state.value === '') {
        el.input.classList.add('input-alert');
        isValidated = false;
      }
    });
    if (isValidated) {
      setLoading(true);
      validateFields((err, values) => {
        if (err) {
          return;
        }
        if (!err) {
          handleSubmitWithAllValues(values);
        }
      });
    }
  };

  const archiveAttributeHandle = () => {
    dispatch(getArchiveAndUnarchive({ id, type: 'archive' }));
  };

  const unarchiveAttributeHandle = () => {
    dispatch(getArchiveAndUnarchive({ id, type: 'unarchive' }));
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      {attributeData?.attributes?.archived_at ? (
        <Menu.Item key="2" onClick={unarchiveAttributeHandle}>
          Desarquivar
        </Menu.Item>
      ) : (
        <Menu.Item key="1" onClick={archiveAttributeHandle}>
          Arquivar
        </Menu.Item>
      )}
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={id}
        title="ATRIBUTO"
        type="Property"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar {attributeData?.attributes?.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/atributos">Atributos</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar atributo</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DO ATRIBUTO"
          extra={actionsExtra}
          // loading={isLoading}
        >
          <Form style={{ marginTop: 10 }}>
            <Row>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 4
                }}
              >
                <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                  Nome do atributo
                </p>
                <Tooltip
                  placement="right"
                  title="Nome do atributo que irá aparecer no app."
                >
                  <Icon style={{ marginLeft: 6 }} type="info-circle" />
                </Tooltip>
              </div>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('name', {
                    initialValue: attributeData?.attributes?.name,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, preencha o nome do atributo'
                      }
                    ]
                  })(<Input placeholder="Nome do atributo" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Valores</p>
              {currentValues[0].name && (
                <AttributesValue
                  editPage
                  attributes={currentValues}
                  attributeValuesRef={attributeValuesRef}
                />
              )}
            </Row>
          </Form>
          <Button
            className="ant-btn ant-btn-primary"
            style={{ marginTop: 25 }}
            onClick={onSubmit}
            loading={loading}
          >
            Salvar
          </Button>
        </Card>
      </div>
    </>
  );
};

EditAttribute.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const EditAttributeFrom = Form.create({ name: 'EditAttribute' })(EditAttribute);

export default EditAttributeFrom;
