import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setAttributes,
  setAttributeData,
  setLoading,
  getAttributes,
  getAttribute,
  getCreateAttribute,
  getUpdateAttribute,
  getArchiveAndUnarchive
} from '@/src/store/modules/attributes/slice';

import { setCurrentValues } from '@/src/store/modules/attribute-values/slice';

// import {
//   getAttributesQueryUrlState,
//   getRouterState
// } from '@/src/store/selectors';

import {
  getAttributes as getAttributesRequest,
  getSingleAttribute as getSingleAttributeRequest,
  getCreateAttribute as getCreateAttributeRequest,
  getEditSingleAttribute as getEditSingleAttributeRequest,
  archiveAndUnarchive as archiveAndUnarchiveRequest
} from '@/src/api/attributes';

import { errorAlert, successAlert } from '@/src/utils/notifications';

function* getSingleAttributeSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getSingleAttributeRequest, payload);
    yield put(setAttributeData(response.data.data));
    const currentValues = response.data.data.attributes.allowed_values
      ? response.data.data.attributes.allowed_values.map(val => {
          return {
            name: val.value
          };
        })
      : [{}];
    yield put(setCurrentValues(currentValues));
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err, 'Não foi possível carregar os dados do atributo');
  }
}

function* getAttributesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAttributesRequest, payload);
    yield put(
      setAttributes({ attributes: response.data.data, query: payload.query })
    );
    // const queryUrl = yield select(getAttributesQueryUrlState);
    // const routerState = yield select(getRouterState);
    // const routerLocation = routerState.location;
    // if (
    //   routerLocation.search !== queryUrl &&
    //   routerLocation.pathname.indexOf('atributos') >= 0
    // ) {
    //   yield put(push(queryUrl));
    // }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar a lista de atributos');
  }
}

function* getCreateAttributeSaga({ payload }) {
  try {
    yield call(getCreateAttributeRequest, payload);
    yield put(push('/atributos'));
    successAlert('Novo atributo criado');
    yield put(setCurrentValues([{}]));
  } catch (err) {
    errorAlert(err, 'Não foi possível criar o atributo');
  }
}

function* getUpdateAttributeSaga({ payload }) {
  try {
    const response = yield call(getEditSingleAttributeRequest, payload);
    yield put({
      type: '@attributes/setAttributeData',
      payload: response.data.data
    });
    const currentValues = response.data.data.attributes.allowed_values
      ? response.data.data.attributes.allowed_values.map(val => {
          return {
            name: val.value
          };
        })
      : [{}];
    yield put(setCurrentValues(currentValues));
    if (!payload.callFromAttributeSingleCard) {
      yield put(push('/atributos'));
      successAlert('Os dados do atributo foram atualizados');
    } else {
      successAlert('Novo valor de atributo criado');
    }
  } catch (err) {
    errorAlert(err, 'Não foi possível atualizar os dados do atributo');
  }
}

function* getArchiveAndUnarchiveSaga({ payload }) {
  try {
    yield call(archiveAndUnarchiveRequest, payload);
    yield put(push('/atributos'));
    successAlert(
      `Atributo ${payload.type === 'archive' ? 'arquivado' : 'desarquivado'}`
    );
  } catch (err) {
    errorAlert(
      err,
      `Não foi possível ${
        payload.type === 'archive' ? 'arquivar' : 'desarquivar'
      } o atributo`
    );
  }
}

function* attributesSaga() {
  yield takeEvery(getAttribute, getSingleAttributeSaga);
  yield takeEvery(getAttributes, getAttributesSaga);
  yield takeEvery(getCreateAttribute, getCreateAttributeSaga);
  yield takeEvery(getUpdateAttribute, getUpdateAttributeSaga);
  yield takeEvery(getArchiveAndUnarchive, getArchiveAndUnarchiveSaga);
}

export default attributesSaga;
