import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Select, Switch, Icon, Row, Button, Tabs, Avatar } from 'antd';

import {
  getAllCategories,
  inactiveOrActiveCategory,
  setPagination,
  setSort,
  getOrderByDragNDrop,
  getDepartments,
  setDepartmentId
} from '@/src/store/modules/catalog-categories/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import Tables from '@/src/components/Tables';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

const { Option } = Select;
const { TabPane } = Tabs;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

function Categories() {
  const [editDrag, setEditDrag] = useState(false);
  const [data, setData] = useState([]);

  const {
    categories,
    isLoading,
    count,
    query,
    departments,
    department_id
  } = useSelector(state => state.catalogCategories);

  const typeTable = 'AllCategories';

  const history = useHistory();
  const dispatch = useDispatch();

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  let columns = [
    {
      title: 'Imagem',
      align: 'center',
      dataIndex: 'attributes.image',
      render: record => (
        <Avatar
          shape="square"
          size={60}
          src={record}
          icon="camera"
          className="img-placeholder"
        />
      )
    },
    {
      title: 'Nome',
      align: 'center',
      dataIndex: 'attributes.name',
      render: (text, record) => (
        <Link
          to={`/categorias/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: 'Descrição',
      align: 'center',
      dataIndex: 'attributes.description'
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Visibilidade</span>
          <Tooltip
            placement="top"
            overlayClassName="tooltip-table"
            title="Este controle torna o corredor visível no aplicativo."
          >
            <Icon type="info-circle" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      align: 'center',
      dataIndex: 'attributes',
      key: '4',
      render: record => (
        <Switch
          defaultChecked={record.status === 'enabled'}
          onChange={() =>
            dispatch(
              inactiveOrActiveCategory({
                id: record.id,
                status: record.status === 'enabled' ? 'disabled' : 'enabled'
              })
            )
          }
          disabled={!catalogPermission}
        />
      )
    }
  ];

  if (editDrag && catalogPermission) {
    columns = [
      {
        dataIndex: 'sort',
        width: 30,
        render: () => <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
      },
      {
        title: 'Imagem',
        align: 'center',
        dataIndex: 'attributes.image',
        render: record => (
          <Avatar
            shape="square"
            size={60}
            src={record}
            icon="camera"
            className="img-placeholder"
          />
        )
      },
      {
        title: 'Nome',
        align: 'center',
        dataIndex: 'attributes.name',
        render: (text, record) => (
          <Link
            to={`/categorias/${record.id}`}
            style={{
              border: '0',
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            {text}
          </Link>
        )
      },
      {
        title: 'Descrição',
        align: 'center',
        dataIndex: 'attributes.description'
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Visibilidade</span>
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="Este controle torna a categoria visível no aplicativo."
            >
              <Icon type="info-circle" style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        ),
        align: 'center',
        dataIndex: 'attributes',
        key: '4',
        render: record => (
          <>
            {record.archived_at !== null ? (
              <Tooltip
                placement="top"
                overlayClassName="tooltip-table"
                title="É preciso desarquivar a categoria para torna-la visível no app."
              >
                <Switch
                  disabled={record.archived_at !== null || !catalogPermission}
                />
              </Tooltip>
            ) : (
              <Switch
                defaultChecked={record.status === 'enabled'}
                onChange={() =>
                  dispatch(
                    inactiveOrActiveCategory({
                      id: record.id,
                      status:
                        record.status === 'enabled' ? 'disabled' : 'enabled'
                    })
                  )
                }
                disabled={!catalogPermission}
              />
            )}
          </>
        )
      }
    ];
  }

  const changeTabs = key => dispatch(setDepartmentId(key));

  const getCategoriesHandler = useCallback(
    urlQuery => {
      const queryWithFilter = {
        query: {
          ...urlQuery.query,
          filters: { by_department: department_id }
        }
      };
      dispatch(getAllCategories(queryWithFilter));
    },
    [dispatch, department_id]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    if (params?.filters?.by_department?.length) {
      dispatch(setDepartmentId(params.filters.by_department[0]));
    }
    dispatch(getDepartments());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!department_id && departments.length)
      dispatch(setDepartmentId(departments[0].id));
    // eslint-disable-next-line
  }, [departments, department_id]);

  useEffect(() => {
    if (department_id) {
      const params = buildQueryFromUrl(history);
      getCategoriesHandler({ query: params });
    }
    // eslint-disable-next-line
  }, [department_id]);

  useEffect(() => {
    setData(categories);
    if (categories?.length > 1) {
      setEditDrag(true);
    } else {
      setEditDrag(false);
    }
    // eslint-disable-next-line
  }, [categories]);

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination({ ...payload }));
    },
    [dispatch]
  );

  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      const arr = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      });
      setData(arr);
      dispatch(getOrderByDragNDrop(arr.map(item => item.id)));
    },
    // eslint-disable-next-line
    [data]
  );

  const handleVisibilitySelectChange = value => {
    if (value !== undefined) {
      dispatch(
        getAllCategories({
          query: {
            ...query,
            filters: { ...query.filters, status: value }
          }
        })
      );
    } else {
      const params = buildQueryFromUrl(history);
      getCategoriesHandler({ query: params });
    }
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <h1 style={{ marginBottom: 5 }}>Categorias</h1>
          <span>Setores de cada departamento</span>
        </div>
        {catalogPermission && (
          <div>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={() => history.push('/categorias/nova')}
            >
              Nova categoria
            </Button>
          </div>
        )}
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <Tabs
          activeKey={department_id}
          onChange={changeTabs}
          style={{ marginBottom: 20 }}
          type="card"
          // tabBarExtraContent={catalogPermission && actionsExtra}
        >
          {departments.map(item => (
            <TabPane tab={item.attributes.name} key={item.id} />
          ))}
        </Tabs>
        <hr style={{ margin: '-20px 0 30px' }} />
        <Select
          size="large"
          placeholder="Visibilidade"
          style={{ width: '15%' }}
          value={query?.filters?.status}
          // defaultValue="active"
          onChange={handleVisibilitySelectChange}
          allowClear
        >
          <Option value="enabled">Ativo</Option>
          <Option value="disabled">Inativo</Option>
        </Select>
        <DndProvider manager={manager.current.dragDropManager}>
          <Tables
            data={data}
            className="even-gray"
            columnsDefaults={columns}
            count={count}
            visiblePaginationUp
            // visiblePaginationDown
            rowKey={record => record.id}
            contentToShow={typeTable}
            history={history}
            query={query}
            isLoading={isLoading}
            setPaginationState={setPaginationHandler}
            setSortState={setSortHandler}
            populateTables={getCategoriesHandler}
            components={editDrag && catalogPermission ? components : {}}
            onRow={
              editDrag && catalogPermission
                ? (record, index) => ({
                    index,
                    moveRow
                  })
                : null
            }
          />
        </DndProvider>
      </div>
    </>
  );
}

export default Categories;
